<template>
  <!-- 发布职位 -->
  <div>
    <div v-if="show_pc" v-loading.fullscreen.lock="loading">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <!-- 职位名称 -->
        <el-form-item label="职位名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入职位名称"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 工作地点 -->
        <el-form-item label="工作地点" prop="site">
          <el-input
            v-model="ruleForm.site"
            placeholder="请输入工作地点"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 职位描述 -->
        <el-form-item label="职位描述" prop="desc">
          <!-- <el-input type="textarea" v-model="ruleForm.desc" rows="6" placeholder="请输入职位描述，格式如下
      岗位职责：
      1.xxxxxxxxxx
      2xxxxxxxxxx.
      任职资格:"></el-input> -->
          <div class="text" style="height: 314px; width: 100%">
            <quill-editor
              class="quill"
              v-model="ruleForm.desc"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              :disabled="editDisabled"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
              style="height: 80%"
            >
            </quill-editor>
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :file-list="richImg"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
            >
            </el-upload>
            <!-- 视频上传事件，实现视频上传 -->
            <el-upload
              id="uploadFileVideo"
              :action="uploadUrl"
              :file-list="richImg"
              :show-file-list="false"
              :on-success="videoUploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
            >
            </el-upload>
          </div>
          <!-- <div style="height: 314px">
            <quill-editor
              class="quill"
              v-model="ruleForm.desc"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              :disabled="editDisabled"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
              style="height: 80%"
            >
            </quill-editor>
          </div> -->
        </el-form-item>

        <!-- 经验要求 -->
        <el-form-item label="经验要求" prop="exp">
          <el-input
            v-model="ruleForm.exp"
            placeholder="请输入经验要求"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 学历要求 -->
        <el-form-item label="学历要求" prop="education">
          <el-input
            v-model="ruleForm.education"
            placeholder="请输入学历要求"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 薪资范围 -->
        <el-form-item label="薪资范围" prop="moneyScope">
          <div style="display: flex; align-items: center">
            <span class="money1"
              ><input
                type="numder"
                v-model="ruleForm.moneyMin"
                placeholder="最低薪资"
                :disabled="editDisabled"
              /><span style="border: 0">K</span></span
            >
            <span style="margin: 0 16px; line-height: 28px">至</span>
            <span class="money1"
              ><input
                type="numder"
                v-model="ruleForm.moneyMax"
                placeholder="最高薪资"
                :disabled="editDisabled"
              /><span style="border: 0">K</span></span
            >
          </div>
        </el-form-item>

        <!-- 职位类别 -->
        <el-form-item label="职位类别" prop="post">
          <el-input
            v-model="ruleForm.post"
            placeholder="请输入职位类别"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 所属部门 -->
        <el-form-item label="所属部门" prop="branch">
          <el-input
            v-model="ruleForm.branch"
            placeholder="请输入所属部门"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 汇报对象 -->
        <el-form-item label="汇报对象" prop="obj">
          <el-input
            v-model="ruleForm.obj"
            placeholder="请输入汇报对象"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 招聘人数 -->
        <el-form-item label="招聘人数" prop="num">
          <el-input
            v-model="ruleForm.num"
            placeholder="请输入招聘人数"
            type="number"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 海外经历 -->
        <el-form-item label="海外经历" prop="haiwai">
          <el-input
            v-model="ruleForm.haiwai"
            placeholder="请输入海外经历"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 年龄要求 -->
        <el-form-item label="年龄要求" prop="age">
          <el-input
            v-model="ruleForm.age"
            placeholder="请输入年龄要求"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 语言要求 -->
        <el-form-item
          label="语言要求"
          prop="language"
          style="position: relative"
        >
          <el-input
            v-model="ruleForm.language"
            placeholder="请输入语言要求"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 是否收费 -->
        <el-form-item label="是否统招" prop="resource">
          <el-radio-group v-model="ruleForm.resource" :disabled="editDisabled">
            <el-radio label="否"></el-radio>
            <el-radio label="是"></el-radio>
            <span></span>
          </el-radio-group>
        </el-form-item>

        <!-- 提交 -->
        <el-form-item>
          <el-button
            type="primary"
            style="
              margin-left: 0px;
              display: inline-block;
              background-color: #1677ff;
              width: 104px;
              border-radius: 0;
              height: 34px;
              line-height: 4px;
            "
            :disabled="editDisabled"
            @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc" id="mobile">
      <Header></Header>
      <div style="width: 100%; height: 90px"></div>
      <div
        class="release_bg"
        :style="{
          backgroundImage:
            'url(https://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20210204/153945_458907.png)',
        }"
        style="margin-bottom: 30px"
      >
        <div style="width: 100%; height: 50px"></div>
        <div style="padding: 10px" v-loading.fullscreen.lock="loading">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="84px"
            style="background-color: #fff; padding: 20px 10px"
          >
            <!-- 职位名称 -->
            <el-form-item label="职位名称" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入职位名称"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 工作地点 -->
            <el-form-item label="工作地点" prop="site">
              <el-input
                v-model="ruleForm.site"
                placeholder="请输入工作地点"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 职位描述 -->
            <el-form-item label="职位描述" prop="desc">
              <div class="text" style="height: 300px; margin: 10px 0 30px 0">
                <quill-editor
                  class="quill"
                  v-model="ruleForm.desc"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  :disabled="editDisabled"
                  @focus="onEditorFocus($event)"
                  @change="onEditorChange($event)"
                  style="height: 80%; text-align: left; line-height: 0"
                >
                </quill-editor>
                <div v-html="ruleForm.desc">{{ ruleForm.desc }}</div>
              </div>

              <!-- <div style="height: 314px">
          <quill-editor class="quill" v-model="ruleForm.desc" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)" @change="onEditorChange($event)" style="height: 80%" >
          </quill-editor>
        </div> -->
            </el-form-item>

            <!-- 经验要求 -->
            <el-form-item label="经验要求" prop="exp">
              <el-input
                v-model="ruleForm.exp"
                placeholder="请输入经验要求"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 学历要求 -->
            <el-form-item label="学历要求" prop="education">
              <el-input
                v-model="ruleForm.education"
                placeholder="请输入学历要求"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 薪资范围 -->
            <el-form-item label="薪资范围" prop="moneyScope">
              <div
                style="
                  display: flex;
                  align-items: center;
                  position: relative;
                  margin-top: 5px;
                "
              >
                <span class="money1"
                  ><input
                    type="numder"
                    style="width: 100%"
                    v-model="ruleForm.moneyMin"
                    placeholder="最低薪资"
                    :disabled="editDisabled"
                  /><span style="border: 0; position: absolute; right: 156px"
                    >K</span
                  ></span
                >
                <span style="margin: 0 16px; line-height: 28px">至</span>
                <span class="money1"
                  ><input
                    type="numder"
                    style="width: 100%"
                    v-model="ruleForm.moneyMax"
                    placeholder="最高薪资"
                    :disabled="editDisabled"
                  /><span style="border: 0; position: absolute; right: 8px"
                    >K</span
                  ></span
                >
              </div>
            </el-form-item>

            <!-- 职位类别 -->
            <el-form-item label="职位类别" prop="post">
              <el-input
                v-model="ruleForm.post"
                placeholder="请输入职位类别"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 所属部门 -->
            <el-form-item label="所属部门" prop="branch">
              <el-input
                v-model="ruleForm.branch"
                placeholder="请输入所属部门"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 汇报对象 -->
            <el-form-item label="汇报对象" prop="obj">
              <el-input
                v-model="ruleForm.obj"
                placeholder="请输入汇报对象"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 招聘人数 -->
            <el-form-item label="招聘人数" prop="num">
              <el-input
                v-model="ruleForm.num"
                placeholder="请输入招聘人数"
                type="number"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 海外经历 -->
            <el-form-item label="海外经历" prop="haiwai">
              <el-input
                v-model="ruleForm.haiwai"
                placeholder="请输入海外经历"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 年龄要求 -->
            <el-form-item label="年龄要求" prop="age">
              <el-input
                v-model="ruleForm.age"
                placeholder="请输入年龄要求"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 语言要求 -->
            <el-form-item
              label="语言要求"
              prop="language"
              style="position: relative"
            >
              <el-input
                v-model="ruleForm.language"
                placeholder="请输入语言要求"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 是否收费 -->
            <el-form-item label="是否统招" prop="resource">
              <el-radio-group
                v-model="ruleForm.resource"
                :disabled="editDisabled"
              >
                <el-radio label="否"></el-radio>
                <el-radio label="是"></el-radio>
                <span></span>
              </el-radio-group>
            </el-form-item>

            <!-- 提交 -->
            <el-form-item>
              <el-button
                type="primary"
                class="btn"
                @click="submitForm('ruleForm')"
                :disabled="editDisabled"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
          <div style="width: 100%; height: 60px"></div>
        </div>
      </div>
    </div>
    <div id="inputVideoSize">
      <div class="videoWidth">
        <span class="videoText">视频宽度</span>
        <el-input v-model="videoWidth" placeholder="请输入视频宽度"></el-input>
      </div>
      <div class="videoHeight">
        <span class="videoText">视频高度</span>
        <el-input v-model="videoHeight" placeholder="请输入视频高度"></el-input>
      </div>
      <div class="btns">
        <el-button @click="ClickClose">关闭</el-button>
        <el-button type="primary" @click="ClickVideo">确定</el-button>
      </div>
    </div>
    <!-- 视频上传弹框 -->
    <el-dialog
      title="视频上传"
      :visible.sync="dialogVideo"
      width="30%"
      :show-close="false"
    >
      <!-- action必选参数, 上传的地址 -->
      <el-upload
        class="avatar-uploader el-upload--text"
        action
        :http-request="handleUpload"
        :show-file-list="false"
        :on-success="handleVideoSuccess"
        :before-upload="beforeUploadVideo"
        :on-progress="uploadVideoProcess"
      >
        <video
          v-if="video_url != '' && videoFlag == false"
          :src="video_url"
          style="width: 100%; height: 200px"
          controls="controls"
        >
          您的浏览器不支持视频播放
        </video>
        <i
          v-else-if="video_url == '' && videoFlag == false"
          class="el-icon-plus avatar-uploader-icon"
        ></i>
        <el-progress
          v-if="videoFlag == true"
          type="circle"
          :percentage="videoUploadPercent"
          style="margin-top: 30px"
        ></el-progress>
      </el-upload>

      <P class="text" style="margin-top: 20px"
        >请保证视频格式正确，且不超过5M</P
      >

      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px;
            border: 0;
          "
          >视频宽度</span
        ><el-input v-model="video_width" placeholder="视频宽度"></el-input>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px;
            border: 0;
          "
          >视频宽度</span
        >
        <el-input v-model="video_height" placeholder="视频高度"></el-input>
      </div>

      <span slot="footer" class="dialog-footer" style="border: 0">
        <el-button @click="clickCancel">取 消</el-button>
        <el-button type="primary" @click="toUploadVideo">确 定</el-button>
      </span>
    </el-dialog>
    <div id="test" @click="test"></div>
  </div>
</template>
<script>
import Header from "../header.vue";
import VideoBlot from "../../utils/video";
import * as Quill from "quill";
import { client, getFileNameUUID } from "../../utils/ali-oss";
Quill.register(VideoBlot);
export default {
  components: {
    Header,
  },
  data() {
    var moneyScope = (rule, value, callback) => {
      if (
        parseInt(this.ruleForm.moneyMin) >= parseInt(this.ruleForm.moneyMax)
      ) {
        callback(new Error("最低薪资必须低于最高薪资"));
        return;
      }
      callback();
    };
    return {
      video_width: "100%",
      video_height: "100px",
      video_url: "",
      videoFlag: false,
      videoUploadPercent: 0,
      select_index: 0,
      dialogVideo: false,
      // 视频宽
      videoWidth: "",
      // 视频高
      videoHeight: "",
      uploadUrl: "",
      richImg: [],
      editorOption: {
        placeholder: "",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              // ['blockquote', 'code-block'],     //引用，代码块
              [
                { header: 1 },
                {
                  header: 2,
                },
              ], // 标题，键值对的形式；1、2表示字体大小
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ], //列表
              //[{ script: "sub" }, { script: "super" }], // 上下标
              [
                {
                  indent: "-1",
                },
                {
                  indent: "+1",
                },
              ], // 缩进
              [
                {
                  direction: "rtl",
                },
              ], // 文本方向
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
              // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
              // [{ 'align': [] }],    //对齐方式
              ["clean"], //清除字体样式
              ["image", "video"], //上传图片、上传视频
            ],
            handlers: {
              video: function (val) {
                if (val) {
                  document.querySelector("#test").click();

                  // 劫持原来的视频点击按钮事件
                  // document.querySelector("#inputVideoSize").style.display =
                  //   "block";
                } else {
                  this.quill.format("video", false);
                }
              },
              image: function (value) {
                if (value) {
                  // upload点击上传事件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      chargeList: ["积分", "余额"], // 支付方式
      //   上传图片路径
      dialogImageUrl: "",
      //   是否显示弹出框
      dialogVisible: false,

      ruleForm: {
        name: "",
        title: "",
        region: "",
        site: "",
        exp: "",
        education: "",
        resource: "否",
        desc: "",
        post: "",
        branch: "",
        obj: "",
        num: "",
        haiwai: "",
        age: "",
        language: "",
        charge: "",
        money: "",
        moneyMin: "",
        moneyMax: "",
      },
      rules: {
        moneyScope: [
          {
            // required: true,
            trigger: "blur",
            validator: moneyScope,
          },
        ],

        resource: [
          {
            required: true,
            // validator: charge,
            trigger: "change",
          },
        ],

        name: [
          {
            required: true,
            message: "请输入职位名称",
            trigger: "blur",
          },
        ],
        site: [
          {
            required: true,
            message: "请输入工作地点",
            trigger: "blur",
          },
        ],
        desc: [
          {
            required: true,
            message: "请输入职位描述",
            trigger: "blur",
          },
        ],
        exp: [
          {
            required: true,
            message: "请输入经验要求",
            trigger: "blur",
          },
        ],
        post: [
          {
            required: true,
            message: "请输入职位类别",
            trigger: "blur",
          },
        ],
        branch: [
          {
            required: true,
            message: "请输入所属部门",
            trigger: "blur",
          },
        ],
        obj: [
          {
            required: true,
            message: "请输入汇报对象",
            trigger: "blur",
          },
        ],
        num: [
          {
            required: true,
            message: "请输入招聘人数",
            trigger: "blur",
          },
        ],
        haiwai: [
          {
            required: true,
            message: "请输入海外经历",
            trigger: "blur",
          },
        ],
        age: [
          {
            required: true,
            message: "请输入年龄要求",
            trigger: "blur",
          },
        ],
        language: [
          {
            required: true,
            message: "请输入语言要求",
            trigger: "blur",
          },
        ],
      },
      user: "",
      show_pc: true,
      loading: false,
      editDisabled: false,
    };
  },

  created() {
    this.uploadUrl = this.$store.state.uploadUrl;
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;

    //获取编辑内容详情
    this.tid = this.$route.query.tid;
    console.log(this.tid);
    if (this.tid) {
      this.loading = true;
      this.editDisabled = this.$store.state.editDisabled;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/recruit_detail", {
          tid: this.tid,
        })
        .then((data) => {
          if (data.code == 200) {
            // this.isTid=true;   //设置为编辑状态
            console.log(data);
            let ruleForm = this.ruleForm;
            ruleForm.name = data.data.title;
            // ruleForm.charge = data.data.unit
            ruleForm.resource = data.data.is_enroll == 1 ? "否" : "是";
            ruleForm.money = data.data.price;
            ruleForm.site = data.data.work_address;
            ruleForm.exp = data.data.exp_desc;
            ruleForm.education = data.data.edut_desc;
            ruleForm.moneyMin = data.data.salary_min;
            ruleForm.moneyMax = data.data.salary_max;
            ruleForm.post = data.data.work_cate;
            ruleForm.branch = data.data.department;
            ruleForm.obj = data.data.report_to;
            ruleForm.num = data.data.people_num;
            ruleForm.haiwai = data.data.overseas_desc;
            ruleForm.age = data.data.age_desc;
            ruleForm.language = data.data.lng_desc;
            ruleForm.desc = data.data.description;
            this.editDisabled = data.data.status == 1 ? true : false;
            this.loading = false;
          }
        })
        .catch((error) => {});
      return;
    }
  },

  methods: {
    /**
     * 自定义上传方法
     */
    handleUpload(option) {
      console.log("oss上传");
      console.log(option);
      let that = this;
      async function multipartUpload() {
        const fileName = getFileNameUUID();
        //定义唯一的文件名，打印出来的uid其实就是时间戳
        //client 是第一步中的 client
        client()
          .multipartUpload("xinshui/" + fileName, option.file, {
            progress: function (p) {
              //获取进度条的值
              // console.log(p)
              that.videoFlag = true;
              // console.log(file.percentage)
              var pro = p * 100;
              that.videoUploadPercent = pro.toFixed(0) * 1;
            },
          })
          .then((result) => {
            //下面是如果对返回结果再进行处理，根据项目需要
            // https://xinshuibucket.oss-cn-beijing.aliyuncs.com/1612356926822_9ac83937
            that.videoFlag = false;
            that.videoUploadPercent = 0;
            that.video_url =
              "https://xinshuibucket.oss-cn-beijing.aliyuncs.com/" +
              result.name;
          })
          .catch((err) => {
            console.log("err:", err);
            this.$message.error("视频上传失败，请重新上传！");
          });
      }
      multipartUpload();
    },
    // 视频上传成功
    handleVideoSuccess(res, file) {
      //获取上传图片地址
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      console.log(res);
      if (res.code == 200) {
        this.video_url = res.data.file_url;
      } else {
        this.$message.error("视频上传失败，请重新上传！");
      }
    },

    // 视频上传之前
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 5;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过5MB哦!");
        return false;
      }
    },

    // 视频上传进度
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      // console.log(file.percentage)
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },

    // 确定添加视频
    toUploadVideo() {
      var quill = this.$refs.myQuillEditor.quill;

      this.dialogVideo = false;

      // return false;
      console.log(this.select_index);
      quill.insertEmbed(this.select_index, "simpleVideo", {
        url: this.video_url,
        controls: "controls",
        width: this.video_width,
        height: this.video_height,
      });
    },
    // 取消
    clickCancel() {
      this.dialogVideo = false;
      this.video_url = "";
    },
    test() {
      // 记录此时的位置
      var quill = this.$refs.myQuillEditor.quill;
      this.select_index = quill.getSelection().index;
      this.video_url = "";

      // 展示视频弹框
      this.dialogVideo = true;
    },

    // 图片处理
    uploadSuccess(res) {
      console.log("成功");
      console.log(res.data.file_url, "res");
      var dt = {};
      dt.url = res.data.file_url;
      let quill = this.$refs.myQuillEditor.quill;

      console.log(quill);
      // 如果上传成功
      if (res.code === 200 && dt.url !== null) {
        console.log("插入图片");
        console.log(dt.url);

        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  dt.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", dt.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      // loading加载隐藏
      this.quillUpdateImg = false;
    },
    // 视频上传成功函数
    videoUploadSuccess(res) {
      var dt = {};
      dt.url = res.data.file_url;

      let quill = this.$refs.myQuillEditor.quill;
      if (res.code === 200 && dt.url !== null) {
        let length = quill.getSelection().index;
        quill.insertEmbed(length, "simpleVideo", {
          url: dt.url,
          controls: "controls",
          width: this.videoWidth + "px",
          height: this.videoHeight + "px",
        });

        quill.setSelection(length + 1);
      } else {
        this.$message.error("视频插入失败");
      }
    },
    //视频上传事件
    ClickVideo() {
      window.document.querySelector("#uploadFileVideo input").click();
    },
    // 关闭
    ClickClose() {
      document.querySelector("#inputVideoSize").style.display = "none";
    },

    // 上传失败钩子函数
    uploadError(res) {
      console.log("失败");
      console.log(res);
    },
    // 上传前钩子函数
    beforeUpload() {},

    submitForm(formName) {
      console.log(this.ruleForm);

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/index.php/api/v1_0_0.recruit/publish_recruit", {
              tid: this.tid,

              publish_data: {
                title: this.ruleForm.name, //职位名称
                department: this.ruleForm.branch, //部门
                work_address: this.ruleForm.site, //工作地点
                description: this.ruleForm.desc, //职位描述
                exp_desc: this.ruleForm.exp, //经验描述
                edut_desc: this.ruleForm.education, //教育描述
                salary_min: this.ruleForm.moneyMin * 100, //最低薪资 int
                salary_max: this.ruleForm.moneyMax * 100, //最高薪资 int
                people_num: this.ruleForm.num, //招聘人数
                is_enroll: this.ruleForm.resource == "否" ? 1 : 2, //是否统招
                overseas_desc: this.ruleForm.haiwai, //海外经历描述
                age_desc: this.ruleForm.age, //年龄描述
                lng_desc: this.ruleForm.language, //外语描述
                work_cate: this.ruleForm.post, //职位类型
                report_to: this.ruleForm.obj, //汇报对象
              },
            })
            .then((data) => {
              console.log(data);
              this.$message.success(data.msg);
              this.$router.push({
                path: "/personal/content",
                query: { tabs: "招聘" },
              });
              return;
            })
            .catch((error) => {});
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    onEditorReady(editor) {}, // 准备编辑器
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {}, // 内容改变事件
    // clearInput() {
    //   this.ruleForm = {
    //     title: "",
    //     region: "",
    //     site: "",
    //     exp: "",
    //     education: '',
    //     type: [],
    //     resource: "否",
    //     desc: "",
    //     post: '',
    //     branch: '',
    //     obj: '',
    //     num: '',
    //     haiwai: '',
    //     age: '',
    //     language: '',
    //     charge: '',
    //     money: '',
    //     moneyMin: '',
    //     moneyMax: '',
    //   }
    // }
  },
};
</script>
<style lang='less' scoped>
#mobile {
  #quillBox {
    /deep/ .el-form-item__error {
      padding-top: 35px !important;
    }
  }
  /deep/.el-input__icon {
    line-height: 33px;
  }
  /deep/.el-form-item__label {
    padding-right: 15px;
  }
  /deep/.el-form-item__error {
    padding-top: 3px;
  }

  /deep/.el-input {
    margin-top: 8px;
  }

  .release_bg {
    width: 100%;
    height: 180px;

    background: #f1f5f6;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    /* padding: 120px 0 50px; */
  }

  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    /* line-height: 44px; */
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

span {
  display: inline-block;
  height: 24px;
}

.money {
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid #e5e6ea;
  padding: 0 10px;
  margin-left: 20px;
  height: 32px;
  line-height: 32px;

  input {
    width: 74px;
    /* height: 34px; */
    background: #ffffff;
    outline: none;
    border: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #4f5764;
  }
}

.money1 {
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid #e5e6ea;
  padding: 0 10px;
  /* margin-left: 20px; */
  height: 32px;
  line-height: 32px;

  input {
    width: 74px;
    /* height: 34px; */
    background: #ffffff;
    outline: none;
    border: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #4f5764;
  }
}
#inputVideoSize {
  width: 500px;
  height: 200px;
  background-color: #ccc;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display: none;

  .videoWidth,
  .videoHeight {
    display: flex;
    align-items: center;
    height: 34px;
    .videoText {
      display: inline-block;
      width: 100px;
    }
  }
  .btns {
    width: 100%;
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
