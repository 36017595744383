<template>
  <!-- 关于我们 联系我们 商务合作 -->
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <Header></Header>
    <div class="we_box">
      <div class="we_bg" :style="{ backgroundImage: 'url(' + mgUrl + ')' }">
        <div class="we">
          <div class="top">
            <ul>
              <li
                v-for="(item, index) in weList"
                :key="index"
                :class="{ color: i == index }"
                @click="ClickWeList(index)"
              >
                <div v-if="i !== index"><img :src="item.img1" alt="" /></div>
                <div v-if="i === index"><img :src="item.img2" alt="" /></div>

                <p>{{ item.title }}</p>
              </li>
            </ul>
          </div>
          <div class="triangle">
            <ul>
              <li
                v-for="(item, index) in weList.length"
                :key="index"
                :class="{ triangle_color: i == index }"
              >
                <p></p>
              </li>
            </ul>
          </div>

          <!-- 内容区域-->
          <div class="about_us">
            <template>
              <div v-html="content">
                {{ content }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./header";
import Footer from "./footer";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // 背景图片
      // 背景图片
      mgUrl:
        "https://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20210204/153945_458907.png",

      weList: [
        {
          img1: require("../assets/icon/关于我们@2x.png"),
          img2: require("../assets/icon/关于我们2@2x.png"),
          title: "关于我们",
        },
        {
          img1: require("../assets/icon/联系我们@2x.png"),
          img2: require("../assets/icon/联系我们2@2x.png"),
          title: "联系我们",
        },
        {
          img1: require("../assets/icon/商务合作@2x.png"),
          img2: require("../assets/icon/商务合作2@2x.png"),
          title: "商务合作",
        },
      ],

      // 默认选中为第一个
      i: 0,

      // 标题
      title: "",
      // 内容
      content: "",
      fullscreenLoading: false,
    };
  },
  created() {
    this.i = ~~this.$route.query.i;
    console.log(this.i);

    this.getInfo();
  },
  methods: {
    getInfo() {
      this.fullscreenLoading = true;
      this.$http
        .post("/index.php/api/v1_0_0.ordinary/get_content", {
          type: this.i + 1,
          noeNeed: true,
        })
        .then((res) => {
          this.fullscreenLoading = false;

          console.log(res);
          this.content = res.data.content;
          this.title = res.data.title;
        })
        .catch((error) => {});
    },
    ClickWeList(i) {
      this.i = i;
      this.$http
        .post("/index.php/api/v1_0_0.ordinary/get_content", {
          type: this.i + 1,
          noeNeed: true,
        })
        .then((res) => {
          console.log(res);
          this.content = res.data.content;
          this.title = res.data.title;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang='less' scoped>
.we_box {
  .we_bg {
    width: 100%;
    height: auto;
    background: #f1f5f6;
    background-size: 100% 270px;
    background-repeat: no-repeat;
    padding: 220px 0 50px;
    .we {
      margin: 0 auto;
      width: 930px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .top {
        width: 100%;
        ul {
          display: flex;
          box-shadow: 0px 8px 8px -2px #e5e5e5;
          .color {
            background: linear-gradient(270deg, #1677ff 0%, #31afff 100%);
            p {
              color: #ffffff;
            }
          }

          li {
            flex: 1;
            height: 108px;
            background: ;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            div {
              width: 34px;
              height: 34px;
              margin-bottom: 10px;
              img {
                width: 100%;
                height: 100%;
                // background-color: pink;
              }
            }
            p {
              font-size: 18px;
              font-weight: 500;
              color: #545a5f;
              line-height: 25px;
            }
          }
        }
      }
      .triangle {
        width: 100%;
        ul {
          display: flex;
          .triangle_color {
            p {
              border-color: transparent transparent #2da7ff transparent;
              transform: rotate(180deg);
            }
          }
          li {
            flex: 1;
            height: 40px;
            display: flex;
            justify-content: center;

            p {
              width: 0;
              height: 0;
              border-width: 1.5rem;
              border-style: solid;
              border-color: transparent transparent #fff transparent;
            }
          }
        }
      }
      // 关于我们
      .about_us {
        // background: linear-gradient(270deg, #1677ff 0%, #31afff 100%);
        border-radius: 2px;
        padding: 35px 21px 62px;
        div {
          p {
            text-align: center !important;
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            line-height: 34px;
            img {
              display: inline-block;
              margin: 0 auto;
            }
          }
        }
      }
      // 联系我们
      .contact_us {
        background: linear-gradient(270deg, #1677ff 0%, #31afff 100%);
        border-radius: 2px;
        padding: 35px 21px 62px;
        p {
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          line-height: 34px;
        }
      }

      // 商务合作
      .cooperation {
        background: linear-gradient(270deg, #1677ff 0%, #31afff 100%);
        border-radius: 2px;
        padding: 35px 21px 62px;
        p {
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          line-height: 34px;
        }
      }
    }
  }
}
</style>
