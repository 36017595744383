<template>
  <div>
    <!-- 活动详情页面 -->
    <Header :indexi="4"></Header>

    <!-- pc端 -->
    <div v-if="show_pc" class="pc">
      <!-- 活动头部图片 -->
      <div class="igm">
        <img :src="headerImg" alt="" />
      </div>

      <div class="activity">
        <!-- 搜索 -->
        <div class="activity_search">
          <div class="top">
            <input v-model="keyword" placeholder="搜索活动" />
            <button @click="ClickSearch">搜索</button>
          </div>
        </div>
        <!-- 筛选 -->
        <div class="activity_screen">
          <div class="buttom">
            <div v-for="(item, index) in stateList" :key="index">
              <p>{{ item.name }}</p>
              <ul>
                <li
                  v-for="(item1, index1) in item.list"
                  :key="index1"
                  :class="{
                    color:
                      item.fields == 'status'
                        ? status == item1.value
                          ? true
                          : false
                        : item.fields == 'cid'
                        ? cid == item1.value
                          ? true
                          : false
                        : item.fields == 'proince'
                        ? proince == item1.value
                          ? true
                          : false
                        : item.fields == 'time'
                        ? time == item1.value
                          ? true
                          : false
                        : false,
                  }"
                >
                  <span @click="ClickFormatList(item.fields, item1.value)">
                    {{ item1.title }}
                  </span>
                </li>
              </ul>
            </div>

            <p @click="ClickClear">
              <i class="el-icon-delete"></i> 清空所有筛选条件
            </p>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="activity_content" v-loading="fullscreenLoading">
          <div v-if="!activityList.length">没有更多了...</div>
          <ul>
            <li
              style="position: relative"
              v-for="(item, index) in activityList"
              :key="index"
              @click="ClickactivityList(item)"
            >
              <div>
                <img :src="item.cover" alt="" />
              </div>
              <div>
                <p>{{ item.title }}</p>
                <p>
                  <span v-html="item.description">{{ item.description }}</span>
                </p>
              </div>
              <div>
                <p>
                  <img src="../assets/icon/类型@2x.png" alt="" />
                  <span>{{ item.cate_name }}</span>
                  <img src="../assets/icon/人数@2x.png" alt="" />
                  <span>{{ item.people_count }}</span>
                </p>
                <p v-if="2 == item.is_pay">
                  <span>
                    {{ item.price }}
                  </span>
                  <span style="font-size: 12px">{{ item.unit }}</span>
                </p>
                <p v-if="1 == item.is_pay" style="color: #1677ff">免费</p>
              </div>
              <div class="tip">{{ item.sign_str }}</div>
            </li>
          </ul>
        </div>

        <!-- 分页 -->
        <!-- 底部分页 -->
        <div class="page" v-if="total > 9">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.pagenum"
            :page-size="queryInfo.pagesize"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="hd_md">
        <!-- 活动头部图片 -->
        <div class="header">
          <wc-swiper>
            <wc-slide v-for="(item, key) in activityList" :key="key">
              <div style="height: 188px" @click="ClickBanner(item.target_url)">
                <img
                  style="display: inline-block; height: 188px; width: 100%"
                  :src="item.cover"
                  alt=""
                />
              </div>
            </wc-slide>
          </wc-swiper>
        </div>

        <!-- 搜索 -->
        <div class="activity_search">
          <div class="top">
            <input v-model="keyword" placeholder="搜索职位、公司" />
            <button @click="ClickSearch">搜索</button>
          </div>
        </div>
        <!-- 筛选 -->
        <div class="activity_screen">
          <div class="buttom">
            <div v-for="(item, index) in stateList" :key="index">
              <p>{{ item.name }}</p>
              <ul>
                <li
                  v-for="(item1, index1) in item.list"
                  :key="index1"
                  :class="{
                    color:
                      item.fields == 'status'
                        ? status == item1.value
                          ? true
                          : false
                        : item.fields == 'cid'
                        ? cid == item1.value
                          ? true
                          : false
                        : item.fields == 'proince'
                        ? proince == item1.value
                          ? true
                          : false
                        : item.fields == 'time'
                        ? time == item1.value
                          ? true
                          : false
                        : false,
                  }"
                >
                  <span @click="ClickFormatList(item.fields, item1.value)">
                    {{ item1.title }}
                  </span>
                </li>
              </ul>
            </div>

            <p @click="ClickClear">
              <i class="el-icon-delete"></i> 清空所有筛选条件
            </p>
          </div>
        </div>

        <!-- 内容 -->
        <div class="activity">
          <!-- 活动内容 -->
          <div class="activity_content">
            <ul>
              <li
                style="position: relative"
                v-for="(item, index) in activityList"
                :key="item.id"
                @click="ClickactivityList(item)"
              >
                <div>
                  <img :src="item.cover" alt="" />
                </div>
                <div>
                  <p>{{ item.title }}</p>
                  <p v-html="item.description">{{ item.description }}</p>
                </div>
                <div>
                  <p>
                    <img src="../assets/icon/类型@2x.png" alt="" />
                    <span>{{ item.cate_name }}</span>
                    <img src="../assets/icon/人数@2x.png" alt="" />
                    <span>{{ item.people_count }}人报名</span>
                  </p>
                  <p v-if="item.is_pay == 2">
                    {{ item.price }}<span> {{ item.unit }}</span>
                    <span></span>
                  </p>
                  <p v-if="item.is_pay == 1">免费</p>
                </div>
                <div class="tip">{{ item.sign_str }}</div>
              </li>
            </ul>
          </div>
          <!-- 加载更多 -->
          <div
            class="more"
            v-if="activityList.length !== total"
            @click="ClickMore"
          >
            加载更多
          </div>
          <div class="more" v-if="activityList.length == total">
            没有更多了...
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";

import Footer from "../components/footer";
export default {
  components: {
    Header,
    Footer,
  },
  metaInfo: {
    title: "新税网 - 活动",
    meta: [
      // {
      //   name: "description",
      //   content: "这是活动页面",
      // },
      {
        name: "keywords",
        content: "新税网-活动",
      },
    ],
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,

      // 头部图片
      headerImg: "",
      // 搜索输入框
      keyword: "",
      // 筛选列表
      // 城市
      cityList: "",
      // 状态
      stateList: "",
      // 时间
      timeList: "",
      // 分类
      classList: "",
      // 状态
      status: 0,
      cid: 0,
      proince: 0,

      // 城市分类默认选中样式
      city: 0,
      // 格式分类的默认选中
      format: 0,
      // 时间分类默认选中
      time: 0,
      // 分类 默认选中
      classification: 0,

      activityList: [],
      // 查询分页表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 9,
      },
      // 总条数
      total: 0,
      fullscreenLoading: false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 获取城市级联选择框
    // this.getCityList();
    // 获取筛选分类列表
    this.getScreenList();

    // 活动列表数据
    this.activityLists();
  },
  methods: {
    // 获取筛选分类列表
    getScreenList() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.activity/get_screen";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.stateList = data;
      }

      this.$http
        .post("/index.php/api/v1_0_0.activity/get_screen", {})
        .then((res) => {
          this.stateList = res.data;
          this.$storage.set(storageKey, res.data);
          // this.timeList = res.data[1];
          // this.classList = res.data[2];
          // this.status = res.data[0].fields;
          // this.time = res.data[1].fields;
          // this.cid = res.data[2].fields;
        })
        .catch((error) => {});
    },
    ClickSearch() {
      if (!this.keyword) {
        this.$message.error("请输入内容");
        return false;
      }
      this.activityLists();
    },

    // 清除所有筛选
    ClickClear() {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = 10;
      this.keyword = "";
      this.cid = 0;
      this.status = 0;
      this.proince = 0;
      this.time = 0;
      this.activityLists();
    },

    // 获取活动列表
    activityLists() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.activity/get_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.total = data.total_count;
        this.activityList = data.list;
        this.headerImg = data.back_url;
      }

      this.fullscreenLoading = true;
      this.$http
        .post("/index.php/api/v1_0_0.activity/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          cid: this.cid,
          status: this.status,
          proince: this.proince,
          time: this.time,
          keyword: this.keyword,
        })
        .then((res) => {
          this.total = res.data.total_count;
          this.activityList = res.data.list;
          this.headerImg = res.data.back_url;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {})
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    ClickFormatList(fields, value) {
      if (fields == "status") {
        this.status = value;
      }
      if (fields == "cid") {
        this.cid = value;
      }
      if (fields == "proince") {
        this.proince = value;
      }
      if (fields == "time") {
        this.time = value;
      }
      this.activityLists();
    },

    // 点击活动详情列表
    ClickactivityList(item) {
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }

      this.$router.push({
        path: "/hd/activityDetails",
        query: { id: item.id },
      });
      // console.log(this.user_id, this.user_token);
      // console.log(id);
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.activityLists();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.activityLists();
    },
    ClickMore() {
      this.queryInfo.pagenum++;
      this.$http
        .post("/index.php/api/v1_0_0.activity/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          cid: this.cid,
          status: this.status,
          proince: this.proince,
          time: this.time,
          keyword: this.keyword,
        })
        .then((res) => {
          this.total = res.data.total_count;
          res.data.list.forEach((item) => {
            this.activityList.push(item);
          });
          this.headerImg = res.data.back_url;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang='less' scoped>
.tip {
  position: absolute;
  left: 25px;
  top: 15px;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #f51a75;
}
.pc {
  .igm {
    width: 100%;
    height: 300px;
    line-height: 250px;
    font-size: 35px;
    font-weight: 500;
    color: #ffffff;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  .activity {
    .activity_search {
      width: 930px;
      // padding: 0 19%;
      margin: 30px auto;
      .top {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        input {
          width: 100%;
          height: 52px;
          border: 0;
          outline: none;
          background: #ffffff;
          border-radius: 8px 0px 0px 8px;
          border: 1px solid #f0f0f0;
          font-size: 14px;
          padding-left: 30px;
          box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
        }
        button {
          width: 140px;
          height: 54px;
          background: #1677ff;
          border-radius: 0px 8px 8px 0px;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          border: 0;
          cursor: pointer;
        }
      }
    }
    .activity_screen {
      width: 1200px;
      margin: 0 auto;
      text-align: left;
      // margin: 0 10.5%;

      border-bottom: 1px solid #ece6e6;

      .buttom {
        // height: 205px;
        background: #ffffff;
        border-radius: 5px;
        margin-top: 32px;
        padding: 5px 5px;
        div {
          display: flex;
          margin-top: 17px;
          p {
            // width: 62px;
            font-size: 16px;
            font-weight: 500;
            color: #424b60;
            line-height: 22px;
            white-space: nowrap;
          }
          ul {
            display: flex;
            justify-content: start;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            .color {
              color: #1677ff !important;
            }
            li {
              text-align: left;
              padding: 0 15px 15px;
              font-size: 14px;
              font-weight: 400;
              color: #787e8f;
              line-height: 20px;
              span {
                cursor: pointer;
              }
            }
          }
        }
        p {
          text-align: left;
          font-size: 12px;
          font-weight: 400;
          color: #c5cacf;
          line-height: 17px;
          padding: 0px 15px;
          margin-bottom: 15px;
          cursor: pointer;
        }
      }
    }
    .activity_content {
      width: 1200px;
      margin: 0 auto;
      // margin: 0 10.5%;
      padding: 20px 0;
      ul {
        display: flex;
        justify-content: start;
        width: 100%;
        flex-wrap: wrap;
        li {
          width: 33.33%;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 0 10px;
          cursor: pointer;
          div:nth-child(1) {
            width: 100%;
            height: 230px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              // background-color: pink;
            }
          }
          div:nth-child(2) {
            border-left: 1px solid #f6f0f0;
            border-right: 1px solid #f6f0f0;

            text-align: left;
            padding: 10px 10px 0;
            p:nth-child(1) {
              font-size: 16px;
              font-weight: 700;
              color: #333333;
              line-height: 25px;
              text-stroke: 1px #979797;
              white-space: nowrap; //强制一行显示
              overflow: hidden;
              text-overflow: ellipsis;
            }
            p:nth-child(2) {
              height: 54px;

              span {
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                color: #8a919e;
                line-height: 20px;
                overflow: hidden; //强制多行显示
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
            }
          }
          div:nth-child(3) {
            border-left: 1px solid #f6f0f0;
            border-right: 1px solid #f6f0f0;
            height: 41px;
            background: #f5f8ff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            p:nth-child(1) {
              display: flex;
              align-items: center;
              img {
                display: inline-block;
                width: 13px;
                height: 13px;
                // background-color: pink;
                margin-right: 6px;
              }
              span {
                font-size: 12px;
                font-weight: 400;
                color: #9798ac;
                line-height: 17px;
                margin-right: 20px;
              }
            }
            p:nth-child(2) {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #f51a75;
              line-height: 22px;
            }
          }
        }
      }
    }
    .page {
      text-align: center;
      padding: 0px 0 30px 0;
    }
  }
}

// 移动端
.hd_md {
  .header {
    margin-top: 60px;

    /deep/.wc-pagination {
      bottom: 0;
      // justify-content: flex-end;

      padding-right: 8px;
      box-sizing: border-box;
    }
    /deep/.wc-dot {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      margin: 0 2px;

      background: #ffffff;
      opacity: 0.5;
    }
    /deep/ .wc-dot-active {
      background-color: #fff;
      width: 10px;
      opacity: 1;
    }
  }
  .activity_search {
    padding: 20px;
    margin: 0;
    .top {
      display: flex;
      align-items: center;
      input {
        width: 255px;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #f0f0f0;
        padding: 10px;
        box-sizing: border-box;
        outline: none;
        border: 0;
      }
      button {
        width: 80px;
        height: 40px;
        background: #1677ff;
        border-radius: 0px 4px 4px 0px;
        border: 0;
        outline: none;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        box-sizing: border-box;
      }
    }
  }
  .activity_screen {
    padding: 6px 15px;
    margin: 0;
    border: 0;
    .buttom {
      margin: 0;
      div {
        display: flex;
        ul {
          margin: 0 0 22px 20px;
          display: flex;
          flex-wrap: wrap;
          .color {
            color: #1677ff !important;
          }
          li {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #787e8f;
            line-height: 25px;
            padding: 0;
            margin-right: 20px;
          }
        }
        p {
          padding: 0;

          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #424b60;
          line-height: 25px;
          white-space: nowrap;
        }
      }
      p {
        text-align: left;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c5cacf;
        margin-bottom: 14px;
      }
    }
  }
  .activity {
    .activity_content {
      margin: 0;
      padding: 0 10px;
      box-sizing: border-box;

      ul {
        li {
          margin: 0;
          width: 100%;
          margin-bottom: 10px;
          div:nth-child(1) {
            width: 100%;
            height: 223px;
            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
          div:nth-child(2) {
            border-left: 1px solid #f6f0f0;
            border-right: 1px solid #f6f0f0;

            box-sizing: border-box;
            text-align: left;
            padding: 10px 0 10px 10px;
            p:nth-child(1) {
              font-size: 15px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 700;

              // font-weight: 500;
              color: #333333;
              line-height: 20px;
            }
            p:nth-child(2) {
              margin-top: 5px;

              height: 42px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #8a919e;
              line-height: 20px;
              overflow: hidden; //强制多行显示
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          div:nth-child(3) {
            border: 1px solid #f6f0f0;

            box-sizing: border-box;
            padding: 0;
            width: 100%;
            height: 40px;
            background: #f5f8ff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;

            p:nth-child(1) {
              display: flex;
              align-items: center;
              img {
                display: inline-block;
                width: 13px;
                height: 13px;
              }
              span {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9798ac;
                margin: 0 15px 0 5px;
              }
            }
            p:nth-child(2) {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #f51a75;
              line-height: 17px;
              // margin-right: 10px;
            }
          }
        }
      }
    }
    .more {
      width: 128px;
      height: 36px;
      line-height: 36px;
      background: #ffffff;
      margin: 20px auto;
      border: 2px solid #e5e6ea;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #555c69;
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 180px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>
