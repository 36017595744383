<template>
  <div>
    <!-- 底部组件 -->
    <!--  pc端  -->
    <div v-if="show_pc" class="boxTop">
      <div class="footer">
        <!-- 主体内容 -->
        <div class="box">
          <!-- 左侧  -->
          <div class="content">
            <!-- 关于我们 -->
            <div class="we">
              <p style="font-size: 18px; margin-bottom: 25px; color: #666">
                关于我们
              </p>
              <ul>
                <li
                  v-for="(item, index) in weList"
                  :key="index"
                  @click="ClickWeList(index)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <!-- 合作伙伴 -->
            <div class="cooperat">
              <div style="font-size: 18px; margin-bottom: 25px; color: #666">
                合作伙伴
              </div>
              <ul>
                <li v-for="(item, index) in cooperationbList" :key="index">
                  <div class="partner" @click="ClickPartner(item.url)">
                    <img :src="item.logo" alt="" />
                  </div>
                </li>
              </ul>
            </div>
            <!-- logo -->
            <div class="logo" @click="ClickLogo">
              <!-- <img :src="info.logo" alt="" /> -->
            </div>
          </div>
          <!-- 右侧 -->
          <div class="wx">
            <div class="code">
              <img :src="info.qrcode" alt="" />
            </div>
            <p>微信公众号</p>
          </div>
        </div>
        <!-- 备案区域 -->
        <div class="record" @click="ClickRecord(info.copy_right_url)">
          <p>{{ info.copy_right }}</p>
        </div>
      </div>
    </div>

    <!-- 手机端 -->
    <div v-if="!show_pc">
      <div class="footer_mb">
        <div class="top">
          <ul>
            <li
              v-for="(item, index) in weList"
              :key="index"
              @click="ClickWeList(index)"
            >
              {{ item }}
            </li>
          </ul>
          <!-- <div class="img" @click="ClickLogo">
            <img :src="info.logo" alt="" />
          </div> -->
        </div>
        <div class="center">
          <p>合作伙伴：</p>
          <ul>
            <li v-for="(item, index) in cooperationbList" :key="index">
              <div class="partner" @click="ClickPartner(item.url)">
                <img :src="item.logo" alt="" />
              </div>
            </li>
          </ul>
        </div>
        <div class="bottom">
          <p>{{ info.copy_right }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 关于我们
      weList: ["关于我们", "联系我们", "商务合作"],
      // 合作伙伴
      cooperationbList: [],
      // 信息
      info: "",
      // streamUrl:'webrtc://pull.xinshui.xinzhidi.cn/live/55'
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 合作伙伴
    this.$http
      .get("/index.php/api/v1_0_0.ordinary/get_partner", { notNeed: true })
      .then((res) => {
        this.cooperationbList = res.data.list;
        // console.log(res.data.list);
      })
      .catch((error) => {});
    // 获取官网信息
    this.$http
      .get("/index.php/api/v1_0_0.ordinary/get_web_info", { notNeed: true })
      .then((res) => {
        this.info = res.data;
        console.log("底部");
        this.$store.state.wap_publish_img = res.data.wap_publish_img;
        // console.log(res.data.wap_publish_img);
      })
      .catch((error) => {});
  },
  methods: {
    // 点击关于我们的函数
    ClickWeList(i) {
      this.$router.push({ path: "/we", query: { i: i } });
      location.reload();
    },

    // 点击合作伙伴logo
    ClickPartner(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    // 点击官网logo
    ClickLogo() {
      this.$router.push("/");
    },

    // 备案号跳转
    ClickRecord(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>
<style lang='less' scoped>
.boxTop {
  .footer {
    width: 100%;
    height: 320px;
    background-color: #21282c;
    padding: 50px 0 15px 0;
    box-sizing: border-box;
    position: relative;
    .box {
      width: 1200px;
      margin: 0 auto;
    }
    .content {
      width: 70%;
      text-align: left;
      display: inline-block;
      color: #adaeaf;
      font-size: 1.13rem;
      .we {
        ul {
          li {
            display: flex;
            align-items: center;
            border-right: 1px solid #adaeaf;
            display: inline-block;
            // height: 18px;
            line-height: 100%;
            padding: 0 18px;
            cursor: pointer;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #adaeaf;
          }
          li:nth-child(1) {
            padding-left: 0;
          }
          li:nth-child(3) {
            border: 0;
          }
        }
      }
      .cooperat {
        margin: 40px 0 0 0;
        display: flex;
        flex-direction: column;
        ul {
          display: flex;
          align-items: center;

          li {
            display: inline-block;

            margin-right: 36px;
            cursor: pointer;
            .partner {
              /*width: 132px;*/
              /*height: 31px;*/
              // background-color: #fff;
              font-size: 14px;
              color: #737b82;
              img {
                display: block;
                /*width: 100%;*/
                height: 31px;
              }
            }
          }
        }
      }
      .logo {
        width: 119px;
        // height: 57px;
        // background-color: #fff;
        margin: 40px 0 0 0;
        overflow: hidden;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .wx {
      width: 30%;
      float: right;
      text-align: center;
      .code {
        width: 162px;
        height: 162px;
        margin: 0 0 0 55%;
        background-color: #fff;
        border-radius: 1px;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      p {
        width: 158px;
        height: 30px;
        line-height: 30px;
        margin: 8px 0 8px 55.5%;
        color: #83888c;
        background-color: #2a3343;
        border-radius: 1px;
      }
    }
    .record {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 15px;
      cursor: pointer;
      p {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #737b82;
        line-height: 20px;
      }
    }
  }
}
// 手机样式
.footer_mb {
  background-color: #21282c;
  height: 149px;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 24px 11px 10px;
  position: relative;

  .top {
    height: 33px;
    display: flex;
    justify-content: space-between;
    ul {
      text-align: left;
      margin-top: 6px;
      li {
        display: flex;
        align-items: center;
        border-right: 1px solid #adaeaf;
        display: inline-block;
        line-height: 100%;
        padding: 0 10px;
        cursor: pointer;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #adaeaf;
      }
      li:nth-child(1) {
        padding-left: 0;
      }
      li:nth-child(3) {
        border: 0;
      }
    }
    img {
      width: 56px;
      height: 33px;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .center {
    p {
      text-align: left;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #7b7f83;
      line-height: 17px;
      margin-bottom: 10px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        display: inline-block;
        .partner {
          width: 86px;
          height: 19px;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }
      li:nth-child(4) .partner {
        width: 42px;
        height: 20px;
      }
    }
  }
  .bottom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 11px;

    p {
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #737b82;
      white-space: nowrap;
      padding-right: 5px;
      // line-height: 17px;
    }
  }
}
</style>
