import Vue from 'vue'
import VueRouter from 'vue-router'
// 微信登录
import indexLoginWx from '../views/wechat/wx'
// 登录
import Login from '../components/users/login'
// 注册
import Register from '../components/users/register'
// 忘记密码
import ForgetPassword from '../components/users/forgetPassword'
// 绑定手机号码
import BindPhone from '../components/users/bindPhone'


import blackPop from '../components/globalVue/blackPop.vue'

// 关于我们 联系我们 商务合作
import We from '../components/We'

// 个人信息中心
import Personal from '../components/personal/personal'
// 个人中心
import User from '../components/personal/users/user'
//消息通知
import Infos from '../components/personal/infos/infos'
// 内容管理
import Content from '../components/personal/contents/content'
// 我的参与
import Join from '../components/personal/joins/join'
// 我的收藏
import Collect from '../components/personal/collects/collect'
// 财务管理
import Financial from '../components/personal/financials/financial'
// 邀请好友
import Invite from '../components/personal/invite/invite'
// 修改密码
import UpdatePwd from '../components/personal/updatePwd/updatePwd'

// 首页
import Index from '../views/index.vue'


// 资讯
import Zx from '../views/zx.vue'
// 资讯详情
import Details from '../views/zxSubpage/details.vue'
// 资讯推广页
import Extension from '../views/zxSubpage/extension'
// 接受邀请页面
import SendInvite from '../views/sendInvite'

// 专栏
import Zl from '../views/zl.vue'
// 专栏老师列表
import TeacherList from '../views/zlSubpage/teacherList'


// 薪税课堂
import Xskt from '../views/xskt'
// 薪税课堂详情
import SalaryClassroom from '../views/xsktSubpage/salaryClassroom'
// 课堂详情开课
import ClassOpen from '../views/xsktSubpage/classOpen'


// 活动
import Hd from '../views/hd'
// 活动详情
import ActivityDetails from '../views/hdSubpage/activityDetails'


// 文库
import Wk from '../views/wk'
// 文库详情
import LibraryDetails from '../views/wkSubpage/libraryDetails'


// 招聘页面
import Zp from '../views/zp'
// 招聘搜索页面
import RecruitSearch from '../views/zpSubpage/recruitSearch'

// 招聘详情页面
import RecruitDetails from '../views/zpSubpage/recruitDetails'


// 精选企业页面
import Jxqy from '../views/jxqy'
// 企业介绍招聘页面
import Recruit from '../views/zpSubpage/recruit'

//专题页面
import Zt from '../views/zt'
// 专题详情页面
import SpecialDetails from '../views/ztSubpage/specialDetails'



// 发布模块
import Release from '../views/releases/release'
// 寻求报道
import Seek from '../components/release/seek'
// 发布活动
import Activity from '../components/release/activity'
// 发布课程
import Course from '../components/release/course'
// 发布资讯
import Info from '../components/release/info'
// 发布文库
import Library from '../components/release/library'
// 发布职位
import Postion from '../components/release/position'

// 企业认证
import Company from '../views/attestation/company/company'
// 作家认证
import Writer from '../views/attestation/writer/writer'
import { isPc, isWeixin } from '../utils'



Vue.use(VueRouter)
const routes = [{
  path: "/release",
  name: 'Seek',
  redirect: '/release/seek' // 重定向到寻求报道
},
{
  path: '/release', //发布模块
  name: 'Release',
  component: Release,
  children: [
    {
      path: "/release/seek", //寻求报道
      name: 'Seek',
      component: Seek,
    },
    {
      path: "/release/activity", //发布活动
      name: 'Activity',
      component: Activity,
    },
    {
      path: "/release/course", //发布课程
      name: 'Course',
      component: Course,
    },
    {
      path: "/release/library", //发布资讯
      name: 'Library',
      component: Library,
    },
    {
      path: "/release/info", //发布文库
      name: 'Info',
      component: Info,
    },
    {
      path: "/release/postion", //发布职业
      name: 'Postion',
      component: Postion,
    }
  ]
},
//移动端发布
{
  path: "/release/seekMobile", //寻求报道
  name: 'Seek',
  component: Seek,
},
{
  path: "/release/activityMobile", //发布活动
  name: 'Activity',
  component: Activity,
},
{
  path: "/release/courseMobile", //发布课程
  name: 'Course',
  component: Course,
},
{
  path: "/release/libraryMobile", //发布资讯
  name: 'Library',
  component: Library,
},
{
  path: "/release/infoMobile", //发布文库
  name: 'Info',
  component: Info,
},
{
  path: "/release/postionMobile", //发布职业
  name: 'Postion',
  component: Postion,
},

{
  path: '/sendInvite', //邀请
  name: 'SendInvite',
  component: SendInvite
},
{
  path: '/login', //登录
  name: 'Login',
  component: Login
},
{
  path: '/register', //注册
  name: 'Register',
  component: Register
},
{
  path: '/forgetPassword', //忘记密码
  name: 'ForgetPassword',
  component: ForgetPassword
},
{
  path: '/bindPhone', //绑定手机号
  name: 'BindPhone',
  component: BindPhone
},
{
  path: '/we', //关于我们 联系我们 商务合作
  name: 'We',
  component: We
},

{
  path: '/personal', //点击用户头像跳转该页面重定向到 个人中心
  name: 'User',
  redirect: '/personal/user'
},


{
  path: '/personal', //点击用户头像跳转该页面
  name: 'Personal',
  component: Personal,
  children: [
    {
      path: '/personal/user', //个人中心
      name: 'User',
      component: User
    },
    {
      path: '/personal/infos', //消息通知
      name: 'Infos',
      component: Infos
    },
    {
      path: '/personal/content', //内容管理
      name: 'Content',
      component: Content
    },
    {
      path: '/personal/join', //我的参与
      name: 'Join',
      component: Join
    },
    {
      path: '/personal/collect', //我的收藏
      name: 'Collect',
      component: Collect
    },
    {
      path: '/personal/financial', //财务管理
      name: 'Financial',
      component: Financial
    },
    {
      path: '/personal/invite', //邀请好友
      name: 'Invite',
      component: Invite
    },
    {
      path: '/personal/updatePwd', //修改密码
      name: 'UpdatePwd',
      component: UpdatePwd
    },
  ]
},
//移动端个人中心
{
  path: '/personal/userMobile', //个人中心
  name: 'User',
  component: User
},
{
  path: '/personal/infosMobile', //个人中心
  name: 'Infos',
  component: Infos
},
{
  path: '/personal/contentMobile', //内容管理
  name: 'Content',
  component: Content
},
{
  path: '/personal/joinMobile', //我的参与
  name: 'Join',
  component: Join
},
{
  path: '/personal/collectMobile', //我的收藏
  name: 'Collect',
  component: Collect
},
{
  path: '/personal/financialMobile', //财务管理
  name: 'Financial',
  component: Financial
},
{
  path: '/personal/inviteMobile', //邀请好友
  name: 'Invite',
  component: Invite
},
{
  path: '/personal/updatePwdMobile', //修改密码
  name: 'UpdatePwd',
  component: UpdatePwd
},



{
  path: '/', //首页
  component: Index
},
{
  path: '/index', //首页
  name: 'Index',
  component: Index

},
{
  path: '/index/recruit', //企业介绍招聘资质页面
  name: 'Recruit',
  component: Recruit,
},
{
  path: '/index/recruitDetails', //招聘详情页面
  name: 'RecruitDetails',
  component: RecruitDetails,
},
{
  path: '/zx', //资讯页面
  name: 'Zx',
  component: Zx,
},
{
  path: '/zx/details', //资讯详情页
  name: 'Details',
  component: Details,
},
{
  path: '/zx/details/extension', //资讯推广页
  name: 'Extension',
  component: Extension,
},
{
  path: '/zl', //专栏页面
  name: 'Zl',
  component: Zl
},
{
  path: '/zl/teacherList', //专栏老师列表页面
  name: 'TeacherList',
  component: TeacherList,
},
{
  path: '/zl/teacherList/details', //专栏文章详情
  name: 'Details',
  component: Details,
},
{
  path: '/xskt', //新税课堂页面
  name: 'Xskt',
  component: Xskt
},
{
  path: '/xskt/salaryClassroom', //活动详情页
  name: 'SalaryClassroom',
  component: SalaryClassroom,
},
{
  path: '/xskt/classOpen', //课堂详情开课页面
  name: 'ClassOpen',
  component: ClassOpen,
},


{
  path: '/hd', //活动页面
  name: 'Hd',
  component: Hd,

},
{
  path: '/hd/activityDetails', //活动详情页
  name: 'ActivityDetails',
  component: ActivityDetails,
},
{
  path: '/hd/salaryClassroom', // 薪税课堂详情页
  name: 'SalaryClassroom',
  component: SalaryClassroom,
},




{
  path: '/wk', //文库页面
  name: 'Wk',
  component: Wk
},
{
  path: '/wk/libraryDetails', //文库详情页
  name: 'LibraryDetails',
  component: LibraryDetails,
},
{
  path: '/zp', //招聘页面
  name: 'Zp',
  component: Zp
},
{
  path: '/zp/recruitSearch', //招聘搜索页面
  name: 'RecruitSearch',
  component: RecruitSearch,
},
{
  path: '/zp/recruitSearch/recruitDetails', //招聘详情页面
  name: 'RecruitDetails',
  component: RecruitDetails,
},
{
  path: '/zp/recruit', //企业介绍招聘页面
  name: 'Recruit',
  component: Recruit,
},
{
  path: '/zp/recruitDetails', //招聘详情页面
  name: 'RecruitDetails',
  component: RecruitDetails,
},
{
  path: '/zp/recruit/recruitDetails', //招聘详情页面
  name: 'RecruitDetails',
  component: RecruitDetails,
},


{
  path: '/jxqy', //精选企业页面
  name: 'Jxqy',
  component: Jxqy
},
{
  path: '/jxqy/recruit', //精选企业详情页面
  name: 'Recruit',
  component: Recruit
},

{
  path: '/zt', //专题页面
  name: 'Zt',
  component: Zt
},
{
  path: '/zt/specialDetails', //专题页面
  name: 'SpecialDetails',
  component: SpecialDetails
},








{
  path: '/recruitDetails', //招聘详情页面
  name: 'RecruitDetails',
  component: RecruitDetails,
  RecruitDetails
},
{
  path: '/recruit', //企业介绍招聘页面
  name: 'Recruit',
  component: Recruit,
},
{
  path: '/attestation/company', //企业认证
  name: 'Company',
  component: Company
},
{
  path: '/attestation/writer', //作家认证
  name: 'Writer',
  component: Writer
},

// 微信登录页面
{
  path: '/index/login/wx/:user_id?/:user_token?',
  name: 'indexLoginWx',
  component: indexLoginWx
}, //登录页面
{
  path: '/blackPop', //作家认证
  name: 'blackPop',
  component: blackPop
},

]
const router = new VueRouter({
  routes
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 页面跳转固定在最顶部
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // // safari
  // window.pageYOffset = 0
  next()
});

// 导航守卫
router.beforeEach((to, from, next) => {

  const _isPc = isPc();
  console.log('是否pc端')
  console.log(_isPc)

  // if (!_isPc) {
  //   // 登录守护者(可以不用验证登录的页面 必须验证的页面)
  //   let arr = ['indexLoginWx']; // 可以不用验证登录的页面
  //   console.log(to.name)
  //   if (arr.indexOf(to.name) !== -1) {
  //     next();
  //   } else {
  //     let user_id = localStorage.getItem('user_id')
  //     let user_token = localStorage.getItem('user_token')
  //
  //     const _isWechat = isWeixin();
  //
  //     console.log('是否微信端')
  //     console.log(_isWechat)
  //
  //     if (_isWechat) {
  //       if (!user_id || !user_token) {
  //         localStorage.setItem('wantTo', window.location.href)
  //         // console.log('验证失败 - 微信授权 - wantTo' + window.location.href);
  //         return next('/index/login/wx');
  //       }
  //     }
  //     next();
  //   }
  // }

  if (to.path === '/login' && localStorage.getItem('user_token')) {
    return next('/')
  }

  if (to.path === '/register' && localStorage.getItem('user_token')) {
    return next('/')
  }

  if (to.path === '/forgetPassword' && localStorage.getItem('user_token')) {
    return next('/')
  }

  next()
  // if (to.path === '/login' || to.path === '/register' || to.path === '/index' || to.path === '/zx' || to.path ===
  //   '/zl' || to.path === '/xskt' || to.path === '/hd' || to.path === '/wk' || to.path === '/zp' || to.path ===
  //   '/jxqy' || to.path === '/forgetPassword' || to.path === '/' || to.path === '/we' || to.path === '/xskt/salaryClassroom'|| to.path === '/sendInvite') {
  //   return next()
  // }
  // const token = localStorage.getItem('user_token')
  // if (!token) {
  //   return next('/login')
  // }
  // next()
})

export default router
