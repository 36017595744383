import { render, staticRenderFns } from "./wk.vue?vue&type=template&id=a2cd74b0&scoped=true&"
import script from "./wk.vue?vue&type=script&lang=js&"
export * from "./wk.vue?vue&type=script&lang=js&"
import style0 from "./wk.vue?vue&type=style&index=0&id=a2cd74b0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2cd74b0",
  null
  
)

export default component.exports