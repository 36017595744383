<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <Header></Header>

    <!-- PC端 -->
    <div v-if="show_pc" class="pc">
      <Company
        :img="companyInfo.logo"
        :companyName="companyInfo.business_name"
        :market="companyInfo.market"
        :scale="companyInfo.scale"
        :type_name="companyInfo.type_name"
      ></Company>
      <!-- 企业模块 -->
      <div class="modular">
        <ul>
          <li
            v-for="(item, index) in modularList"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickModular(index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <!-- 公司简介模块 -->
      <div class="company_box" v-if="i == 0">
        <div
          v-if="JSON.stringify(companyInfo) == '{}'"
          style="height: 400px; margin-top: 20px"
        >
          暂无信息...
        </div>
        <div class="introduce" v-if="JSON.stringify(companyInfo) !== '{}'">
          <!-- 公司介绍 -->

          <div class="company">
            <div class="conmpany_text">
              <p>公司介绍</p>
              <span></span>
            </div>
            <div class="conmpany_content">
              <!-- <p>岗位描述：</p> -->
              <p>
                {{ companyInfo.description }}
              </p>
            </div>
          </div>

          <!-- 产品介绍 -->
          <div class="product">
            <div class="product_text">
              <p>产品介绍</p>
              <span></span>
            </div>
            <ul>
              <li
                v-for="(item, index) in companyInfo.product_info"
                :key="index"
              >
                <div class="supplier">
                  <div class="img" @click.stop="ClickProductName(item)">
                    <img :src="item.cover" alt="" />
                  </div>
                  <div class="">
                    <p>
                      <span>产品名称：</span>
                      <span @click.stop="ClickProductName(item)">{{
                        item.title
                      }}</span>
                    </p>
                    <p>
                      <span @click="ClickProduct(item.description)"
                        >产品链接：</span
                      >{{ item.description }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- 公司地址 -->
          <div class="addres">
            <div class="addres_text">
              <p>公司地址</p>
              <span></span>
            </div>
            <div class="addres_box">
              <el-collapse accordion v-model="activeNames">
                <el-collapse-item
                  v-for="(item, index) in companyInfo.address_data"
                  :key="index"
                  :name="item.address_name"
                >
                  <template slot="title">
                    <div style="width: 120%" @click="ClickShowMap(item, index)">
                      <i class="el-icon-location-outline"></i
                      ><span>{{ item.address_name }}</span>
                    </div>
                  </template>
                  <div style="width: 100%; height: 300px" :id="index"></div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>

      <!-- 职位区域 -->
      <div class="position" v-if="i == 1">
        <!-- 职位左侧区域 -->
        <div class="position_left">
          <div
            style="width: 100%; margin-top: 20px"
            v-if="positionList.length <= 0"
          >
            该企业暂无职位招聘
          </div>
          <ul v-if="positionList.length > 0">
            <li
              v-for="(item, index) in positionList"
              :key="item.id"
              @click="ClickPosition(item.id)"
            >
              <div class="left">
                <div>
                  <span>{{ item.title }}</span
                  ><span>{{ item.create_time }}</span>
                </div>
                <div>
                  <span>{{ item.salary }}</span>
                  <span>{{ item.left_box.exp_desc }}</span>
                  <span>{{ item.left_box.edut_desc }}</span>
                </div>
              </div>
              <div class="right">
                <div>
                  <span>{{ item.business_name }}</span>
                </div>
                <div>
                  <span>{{ item.right_box.department }}</span>
                  <span>{{ item.right_box.is_market }}</span>
                  <span>{{ item.right_box.scale }}</span>
                </div>
                <div class="image"><img :src="item.logo" alt="" /></div>
              </div>
            </li>
          </ul>
          <!-- 分页 -->
          <!-- 职位招聘分页 -->
          <div class="page" v-if="total > queryInfo.pagesize">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="queryInfo.pagenum"
              :page-size="queryInfo.pagesize"
              layout="prev, pager, next"
              :total="total"
              :hide-on-single-page="true"
            >
            </el-pagination>
          </div>
        </div>

        <!-- 职位右侧区域 推荐职位 -->
        <div class="position_right">
          <div class="top">
            <p>职位推荐</p>
            <span></span>
          </div>
          <div
            class="bottom"
            v-for="(item, index) in firstPositionLists"
            :key="item.id"
            @click="ClickPosition(item.id)"
          >
            <div>
              <p>{{ item.title }}</p>
              <p>{{ item.business_name }}</p>
            </div>
            <div>{{ item.salary }}</div>
          </div>
        </div>
      </div>

      <!-- 企业资质模块 -->
      <div class="qualifications" v-if="i == 2">
        <div class="nothing_prove" v-if="qualificationsList.length <= 0">
          该企业尚未上传相关资质信息
        </div>
        <div
          v-if="qualificationsList.length > 0"
          class="prove"
          v-for="(item, index) in qualificationsList"
          :key="item.id"
          @click="ClickProve(item)"
        >
          <div class="img">
            <img :src="item.cover" alt="" />
          </div>
          <div class="qualifications_text">
            <p>{{ item.name }}</p>
            <p>
              {{ item.content }}
            </p>
          </div>
        </div>
        <!-- 分页 -->
        <!-- 企业直至分页 -->
        <div class="page" v-if="proveTotal > proveQueryInfo.pagesize">
          <el-pagination
            background
            @size-change="proveHandleSizeChange"
            @current-change="proveHandleCurrentChange"
            :current-page.sync="proveQueryInfo.pagenum"
            :page-size="proveQueryInfo.pagesize"
            layout="prev, pager, next"
            :total="proveTotal"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 遮罩层 -->
      <el-dialog title="" :visible.sync="certificateShow" width="50%">
        <div class="certificate" @click.stop>
          <div class="img">
            <img :src="proveDetails.cover" alt="" />
          </div>
          <p>
            {{ proveDetails.name ? proveDetails.name : proveDetails.title }}
          </p>
          <p>
            {{
              proveDetails.conment
                ? proveDetails.conment
                : proveDetails.description
            }}
          </p>
        </div>
      </el-dialog>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="recruit_mb">
        <Company
          :img="companyInfo.logo"
          :companyName="companyInfo.business_name"
          :market="companyInfo.market"
          :scale="companyInfo.scale"
          :type_name="companyInfo.type_name"
        ></Company>
        <!-- 企业模块 -->
        <div class="modular">
          <ul>
            <li
              v-for="(item, index) in modularList"
              :key="index"
              :class="{ color: i == index }"
              @click="ClickModular(index)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <!-- 公司简介模块 -->
        <div class="company_box" v-if="i == 0">
          <div class="introduce">
            <!-- 公司介绍 -->
            <div class="company">
              <div class="conmpany_text">
                <p>公司介绍</p>
                <span></span>
              </div>
              <div class="conmpany_content">
                <!-- <p>岗位描述：</p> -->
                <p>
                  {{ companyInfo.description }}
                </p>
              </div>
            </div>

            <!-- 产品介绍 -->
            <div class="product">
              <div class="product_text">
                <p>产品介绍</p>
                <span></span>
              </div>
              <ul>
                <li
                  v-for="(item, index) in companyInfo.product_info"
                  :key="index"
                  @click="ClickProduct(item.description)"
                >
                  <div class="supplier">
                    <div class="img" @click.stop="ClickProductName(item)">
                      <img :src="item.cover" alt="" />
                    </div>
                    <div class="" style="width: calc(100% - 49px)">
                      <p>
                        <span>产品名称：</span>
                        <span @click.stop="ClickProductName(item)">{{
                          item.title
                        }}</span>
                      </p>
                      <p>
                        <span @click="ClickProduct(item.description)"
                          >产品链接：</span
                        ><span>{{ item.description }}</span>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <!-- 公司地址 -->
            <div class="addres">
              <div class="addres_text">
                <p>公司地址</p>
                <span></span>
              </div>
              <div class="addres_box">
                <el-collapse accordion>
                  <el-collapse-item
                    v-for="(item, index) in companyInfo.address_data"
                    :key="index"
                  >
                    <template slot="title">
                      <div
                        style="width: 120%"
                        @click="ClickShowMap(item, index)"
                      >
                        <i class="el-icon-location-outline"></i
                        ><span>{{ item.address_name }}</span>
                      </div>
                    </template>
                    <div style="width: 100%; height: 150px" :id="index"></div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
        </div>

        <!-- 职位区域 -->
        <div class="position" v-if="i == 1">
          <!-- 职位左侧区域 -->
          <div class="position_left">
            <ul>
              <li
                v-for="(item, index) in positionList"
                :key="item.id"
                @click="ClickPosition(item.id)"
              >
                <div class="left">
                  <div>
                    <span>{{ item.title }}</span>
                    <span>{{ item.salary }}</span>
                  </div>
                  <div>
                    <div class="img"><img :src="item.logo" alt="" /></div>
                    <span>{{ item.business_name }}</span>
                    <span>{{ item.left_box.exp_desc }}</span>
                    <span>{{ item.left_box.edut_desc }}</span>
                    <span>{{ item.create_time }}</span>
                  </div>
                </div>
              </li>
            </ul>

            <!-- 分页 -->
            <!-- 职位招聘分页 -->

            <div
              class="more"
              v-if="positionList.length !== total"
              @click="ClickPositionMore"
            >
              加载更多
            </div>
            <div class="more" v-if="positionList.length == total">
              没有更多了...
            </div>
          </div>
        </div>

        <!-- 企业资质模块 -->
        <div class="qualifications" v-if="i == 2">
          <div class="nothing_prove" v-if="qualificationsList.length <= 0">
            该企业尚未上传相关资质信息
          </div>
          <div
            class="prove"
            v-if="qualificationsList.length > 0"
            v-for="(item, index) in qualificationsList"
            :key="item.id"
            @click="ClickProve(item)"
          >
            <div class="img">
              <img :src="item.cover" alt="" />
            </div>
            <div class="qualifications_text">
              <p>{{ item.name }}</p>
              <p>
                {{ item.content }}
              </p>
            </div>
          </div>
          <!-- 分页 -->
          <!-- 企业直至分页 -->
          <div
            class="more"
            v-if="positionList.length !== total"
            @click="ClickProveMore"
          >
            加载更多
          </div>
          <div
            class="more"
            v-if="positionList.length == total && positionList.length > 10"
          >
            没有更多了...
          </div>
        </div>

        <!-- 遮罩层 -->
        <el-dialog title="" :visible.sync="certificateShow" width="50%">
          <div class="certificate" @click.stop>
            <div class="img">
              <img :src="proveDetails.cover" alt="" />
            </div>
            <p>
              {{ proveDetails.name ? proveDetails.name : proveDetails.title }}
            </p>
            <p>
              {{
                proveDetails.conment
                  ? proveDetails.conment
                  : proveDetails.description
              }}
            </p>
          </div>
        </el-dialog>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
 <script charset="utf-8" src="https://map.qq.com/api/js?v=2.exp&key=AFIBZ-K6Q6W-UGCRN-OFWF4-2DNLF-6OFMR"></script>
<script>
import Header from "../../components/header";
import Footer from "../../components/footer";
import Company from "../../components/company";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    Header,
    Footer,
    Company,
  },
  metaInfo() {
    return {
      title: this.companyInfo.business_name
        ? this.companyInfo.business_name + " | 新税网"
        : window.location.href,

      meta: [
        {
          name: "description",
          content: this.companyInfo.description,
        },
        {
          name: "keywords",
          content: this.companyInfo.business_name,
        },
        {
          name: "author",
          content: this.companyInfo.business_name + " - 新税网",
        },
      ],
    };
  },
  data() {
    return {
      showMap: "",
      activeNames: "1",
      // 默认显示 PC端的css
      show_pc: true,

      // 企业id
      id: "",

      // 遮罩层的资质显示与隐藏
      certificateShow: false,
      // 默认选中第一个样式
      i: 0,
      // 公司模块列表
      modularList: ["公司介绍", "职位招聘", "企业资质"],

      // 企业信息
      companyInfo: "",
      // 公司名称

      //   职位列表
      positionList: [],

      // 招聘职位页码
      // 查询文章列表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      // 总条数
      total: 0,

      // 资质证明
      // 查询文章列表参数
      proveQueryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      // 总条数
      proveTotal: 0,

      //   推荐职位列表
      firstPositionLists: [],

      // 资质列表
      qualificationsList: [],
      // 企业资质详情
      proveDetails: "",
      fullscreenLoading: false,
      lat: 0,
      lng: 0,
    };
  },
  created() {
    (this.fullscreenLoading = true),
      // 判断是PC端还是移动端
      (this.show_pc = this.$store.state.show_pc);
    // 接收企业id
    this.id = this.$route.query.id;
    // 获取公司详情介绍
    this.getCompanyDetails();
    // 获取推荐职位列表
    this.getPositionLists();
    // 推荐职位
    this.getFirstPositionLists();
    // 获取企业资质
    this.getproveList();

    // var center = new qq.maps.LatLng(经度, 纬度);
    // // this.map  在data 里面声明一个变量
    // this.map = new qq.maps.Map(this.$refs.map, {
    //   center: center, // 地图的中心地理坐标。
    //   zoom: 32, // 地地图缩放
    // });
  },
  methods: {
    ClickShowMap(item, index) {
      // console.log(123)
      // console.log(item)
      this.lat = item.lat;
      this.lng = item.lng;
      // map.destroy();
      this.$nextTick(() => {
        this.initMap(index);
      });
    },

    initMap(index) {
      let that = this;
      console.log(this.lat);

      var myLatlng = new qq.maps.LatLng(that.lat, that.lng);
      that.lat = 0;
      that.lng = 0;
      console.log(this.lat);
      var myOptions = {
        zoom: 14, //设置地图缩放级别
        center: myLatlng, //设置中心点样式
        mapTypeId: qq.maps.MapTypeId.ROADMAP, //设置地图样式详情参见MapType
      };

      //获取dom元素添加地图信息
      var map = new qq.maps.Map(document.getElementById(index), myOptions);

      var marker = new qq.maps.Marker({
        position: myLatlng,
        map: map,
      });
      // map.destroy();
    },
    // 获取公司详情介绍
    getCompanyDetails() {
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_business_info", {
          business_id: this.id,
        })
        .then((res) => {
          console.log("公司详情");
          console.log(res.data);
          this.fullscreenLoading = false;

          this.companyInfo = res.data;

          this.showMap = res.data.address_data[0];
          this.ClickShowMap(res.data.address_data[0], 0);
          this.activeNames = res.data.address_data[0].address_name;
        })
        .catch((error) => {});
    },
    // 点击介绍 职位 资质 模块切换
    ClickModular(i) {
      this.i = i;

      if (i == 0) {
        this.ClickShowMap(this.showMap, 0);
        this.activeNames = this.showMap.address_name;
      }
    },

    // 点击产品页面跳转
    ClickProduct(url) {
      if (url) {
        window.open(url, "_blank");
        return;
      }
    },
    // 点击产品名称
    ClickProductName(item) {
      this.proveDetails = item;
      this.certificateShow = true;
      console.log(this.proveDetails);
    },
    // 职位列表
    getPositionLists() {
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_recruit_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          business_id: this.id,
        })
        .then((res) => {
          this.positionList = res.data.list;
          this.total = res.data.total_count;
          console.log("总条数");
          console.log(res.data.total_count);
        })
        .catch((error) => {});
    },

    // 职位招聘左侧 职位
    ClickPositon(id) {},
    // 职位招聘右侧 企业
    ClickEnterprise(id) {},
    // 推荐职位
    getFirstPositionLists() {
      this.$http
        .get("/index.php/api/v1_0_0.recruit/get_recruit_list", {
          page: 1,
          page_size: 10,
          is_recommend: 1,
          notNeed: true,
        })
        .then((res) => {
          this.firstPositionLists = res.data.list;
          // this.total = res.data.total_count;
        })
        .catch((error) => {});
    },
    // 点击推荐职位 跳转 到职位详情
    ClickPosition(id) {
      console.log(id);
      this.$router.push({
        path: "/zp/recruit/recruitDetails",
        query: { id: id },
      });
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getPositionLists();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.getPositionLists();
    },
    // 点击公司资质
    ClickProve(item) {
      this.proveDetails = item;
      this.certificateShow = true;
    },
    // 获取企业资质证明
    getproveList() {
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_business_zlist", {
          business_id: this.id,
          page: this.proveQueryInfo.pagenum,
          page_size: this.proveQueryInfo.pagesize,
        })
        .then((res) => {
          console.log(res);
          this.qualificationsList = res.data.list ? res.data.list : "";

          this.qualificationsList.forEach((item, i) => {
            if (item.cover == "static/img/zz.249b86c0.png") {
              this.qualificationsList = this.qualificationsList.splice(
                item.id,
                1
              );
            }
          });
          console.log(this.qualificationsList);
          this.proveTotal = res.data.total_count;
        })
        .catch((error) => {});
    },
    // 每页显示的条数发生的变化会触发的函数
    proveHandleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getproveList();
    },
    // 当前页发生变化 触发的函数
    proveHandleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.getproveList();
    },
    // 关闭资质证明遮罩层
    ClickCertificateShow() {
      this.certificateShow = false;
    },

    // 移动端
    // 职位
    ClickPositionMore() {
      this.$http
        .get("/index.php/api/v1_0_0.recruit/get_recruit_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          is_recommend: 0,
          notNeed: true,
        })
        .then((res) => {
          res.data.list.forEach((item) => {
            this.positionList.push(item);
          });
          this.total = res.data.total_count;
        })
        .catch((error) => {});
    },

    // 资质
    ClickProveMore() {
      this.proveQueryInfo.pagenum++;
      this.$http
        .get("/index.php/api/v1_0_0.recruit/get_business_zlist", {
          params: {
            business_id: this.id,
            page: this.proveQueryInfo.pagenum,
            page_size: this.proveQueryInfo.pagesize,
            notNeed: true,
          },
        })
        .then((res) => {
          res.data.list.forEach((item) => {
            this.qualificationsList.push(item);
          });
          this.proveTotal = res.data.total_count;
        })
        .catch((error) => {});
    },

    // 腾讯地图
  },
  mounted() {
    // this.initMap();
    // // 腾讯地图
    // var that = this;
    // window.addEventListener(
    //   "message",
    //   function (event) {
    //     var loc = event.data;
    //     if (loc && loc.module == "locationPicker") {
    //       console.log("location", loc);
    //       that.showMap = false;
    //       // do you want do
    //       // that.companyAddr = loc.poiaddress
    //     }
    //   },
    //   false
    // );
  },
};
</script>

<style lang='less' scoped>
img {
  object-fit: contain;
}
.pc {
  // 企业模块
  .modular {
    text-align: left;
    height: 54px;
    line-height: 54px;
    background: #f8f8f8;
    border: 1px solid #f0f0f0;
    padding: 0 10.45%;
    .color {
      color: #1677ff;
    }
    ul {
      width: 1200px;
      margin: 0 auto;
      li {
        display: inline-block;
        padding-right: 50px;
        font-size: 16px;
        font-weight: 400;
        color: #303038;
        cursor: pointer;
      }
    }
  }

  // 公司简介模块
  .company_box {
    width: 1200px;
    margin: 0 auto;
    // margin: 0 10.45%;
    height: auto;
    background-color: #fff;
    .introduce {
      text-align: left;
      width: 66%;
      height: 100%;
      .company {
        .conmpany_text {
          padding: 40px 0 20px;
          p {
            font-size: 16px;
            font-weight: 600;
            color: #292931;
            line-height: 22px;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .conmpany_content {
          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7e8393;
            line-height: 20px;
          }
          p:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            color: #666c7f;
            line-height: 36px;
          }
        }
      }
      .product {
        .product_text {
          padding: 20px 0 10px 0;
          p {
            font-size: 16px;
            font-weight: 600;
            color: #292931;
            line-height: 22px;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        ul {
          li {
            margin: 10px 0;
            cursor: pointer;
            .supplier {
              display: flex;
              align-items: center;
              .img {
                width: 58px;
                height: 58px;

                border-radius: 8px;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  /*background-color: pink;*/
                }
              }
              div:nth-child(2) {
                margin-left: 10px;

                p:nth-child(1) {
                  span {
                    font-size: 16px;
                    font-weight: 600;
                    color: #292931;
                    line-height: 22px;
                  }
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 22px;
                  padding: 4px 0;
                  span:nth-child(2) {
                    color: #666c7a;
                    // color: #292931;
                  }
                }
                p:nth-child(2) {
                  padding: 4px 0;
                  font-size: 14px;
                  font-weight: 400;
                  color: #666c7f;
                  line-height: 20px;
                  margin: 0;
                }
              }
            }
          }
        }
      }
      .addres {
        .addres_text {
          padding: 20px 0 10px 0;
          p {
            font-size: 16px;
            font-weight: 600;
            color: #292931;
            line-height: 22px;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .addres_box {
          margin: 0 00 8px;
          padding-bottom: 40px;
          // height: 600px;

          .el-collapse {
            border: 2px solid #21282c;
            border-radius: 10px;

            .el-collapse-item {
              box-sizing: border-box;
              padding: 0px 5px;
              margin-bottom: 2px;
              .mymap {
                width: 100%;
                height: 200px;
              }
              #container {
                /*地图(容器)显示大小*/
                width: 100%;
                height: 200px;
                /* position: absolute; */
              }
            }
          }
        }
      }
    }
  }
  // 招聘模块
  .position {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // margin: 0 10.45%;
    .position_left {
      width: 66%;
      height: 100%;

      ul {
        li {
          display: flex;
          justify-content: start;
          background-color: #fff;
          padding: 18px 0px 17px 0px;
          margin: 0 20px 15px 30px;

          border-bottom: 1px solid #ddd;
          cursor: pointer;
          .left {
            width: 55%;

            text-align: left;
            div:nth-child(1) {
              margin-bottom: 20px;
              span:nth-child(1) {
                font-size: 16px;
                font-weight: 400;
                color: #1677ff;
                line-height: 22px;
                margin-right: 20px;
              }
              span {
                font-size: 12px;
                font-weight: 400;
                color: #afb2ba;
                line-height: 17px;
              }
            }
            div:nth-child(2) {
              span {
                display: inline-block;
                height: 12px;
                line-height: 12px;
                font-size: 14px;
                font-weight: 400;
                color: #afb2ba;
                padding: 0 6px;
                border-right: 1px solid #afb2ba;
              }
              span:nth-child(1) {
                font-size: 16px;
                font-weight: 600;
                color: #ff733c;
                padding-left: 0px;
                border-right: 0;
              }
              span:last-child {
                border-right: 0;
              }
            }
          }
          .right {
            width: 45%;
            text-align: left;
            position: relative;
            div:nth-child(1) {
              margin-bottom: 20px;

              span:nth-child(1) {
                font-size: 16px;
                font-weight: 400;
                color: #1677ff;
                line-height: 22px;
              }
              span:nth-child(2) {
                font-size: 12px;
                font-weight: 400;
                color: #afb2ba;
                line-height: 17px;
              }
            }
            div:nth-child(2) {
              span {
                display: inline-block;
                height: 12px;
                line-height: 12px;
                font-size: 14px;
                font-weight: 400;
                color: #afb2ba;
                border-right: 1px solid #afb2ba;
                padding: 0 6px;
              }
              span:nth-child(1) {
                padding-left: 0px;
              }
              span:last-child {
                border-right: 0;
              }
            }
            .image {
              position: absolute;
              right: 0;
              top: 0;
              width: 54px;
              height: 54px;
              border-radius: 5px;
              overflow: hidden;
              img {
                display: inline-block;
                width: 100%;
                height: 100%;
                /*background-color: pink;*/
              }
            }
          }
        }
      }
      .page {
        text-align: center;
        padding: 25px 0 50px 0;
      }
    }
    .position_right {
      width: 33%;
      height: 100%;
      background-color: #fff;
      .top {
        text-align: left;
        display: flex;
        flex-direction: column;
        padding: 20px;
        p {
          font-weight: 600;
          color: #292931;
          line-height: 25px;
          padding-bottom: 5px;
        }
        span {
          display: inline-block;
          width: 60px;
          height: 3px;
          background: #1677ff;
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
        padding: 0px 25px 0px 20px;
        cursor: pointer;
        div:nth-child(1) {
          text-align: left;
          p:nth-child(1) {
            font-size: 16px;
            font-weight: 500;
            color: #424b60;
            padding: 4px 0;
          }
          p:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            color: #afb2ba;
            padding: 4px 0;
          }
        }
        div:nth-child(2) {
          font-size: 16px;
          font-weight: 600;
          color: #ff733c;
          line-height: 22px;
        }
      }
    }
  }

  // 资质模块
  .qualifications {
    width: 1200px;
    margin: 0 auto;
    // margin: 0 10.45%;
    height: auto;
    background-color: #fff;
    .nothing_prove {
      height: 320px;
      line-height: 300px;
    }
    .prove {
      width: 66%;
      height: 100%;
      // height: 30vh;
      padding: 20px 0px;
      display: flex;
      border-bottom: 5px solid #f5f5f5;
      cursor: pointer;
      .img {
        width: 31%;
        height: 150px;
        padding-left: 15px;
        border-radius: 5px;
        overflow: hidden;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          /*background-color: pink;*/
        }
      }
      .qualifications_text {
        width: 69%;
        text-align: left;
        padding-left: 15px;
        p:nth-child(1) {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #292931;
          line-height: 30px;
          margin-bottom: 10px;
        }
        p:nth-child(2) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #808080;
          line-height: 20px;
        }
      }
    }
    .prove:hover {
      background-color: #f5f5f5;
    }
    .page {
      text-align: center;
      padding: 25px 0 50px 0;
    }
  }

  // 遮罩层

  .certificate {
    text-align: left;
    // width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    .img {
      width: 100%;
      height: 300px;
      overflow: hidden;

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        /*background-color: pink;*/
        margin-bottom: 10px;
        object-fit: contain;
      }
    }
  }

  p:nth-child(2) {
    font-size: 18px;
    font-weight: 500;
    color: #313138;
    line-height: 25px;
    margin: 10px 0;
  }
  p:nth-child(3) {
    font-size: 16px;
    font-weight: 400;
    color: #797983;
    line-height: 22px;
  }
}
.recruit_mb {
  min-height: 75vh;
  padding-top: 60px;
  background-color: #fff;

  // 企业模块
  .modular {
    text-align: left;
    height: 44px;
    background: #f8f8f8;
    border: 1px solid #f0f0f0;
    .color {
      color: #1677ff;
    }
    ul {
      display: flex;
      height: 100%;
      box-sizing: border-box;
      justify-content: space-around;
      align-items: center;

      li {
        display: inline-block;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303038;
        cursor: pointer;
      }
    }
  }

  // 公司简介模块
  .company_box {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 0 10px;
    box-sizing: border-box;
    .introduce {
      text-align: left;
      width: 100%;
      height: 100%;
      .company {
        .conmpany_text {
          padding: 15px 0;
          p {
            font-size: 16px;
            font-weight: 600;
            color: #292931;
            line-height: 22px;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .conmpany_content {
          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7e8393;
            line-height: 20px;
          }
          p:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            color: #666c7f;
            line-height: 36px;
          }
        }
      }
      .product {
        .product_text {
          padding: 20px 0 10px 0;
          p {
            font-size: 16px;
            font-weight: 600;
            color: #292931;
            line-height: 22px;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        ul {
          li {
            margin: 10px 0;
            cursor: pointer;
            .supplier {
              display: flex;
              .img {
                width: 49px;
                height: 49px;
                border-radius: 8px;
                overflow: hidden;
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  /*background-color: pink;*/
                }
              }
              div:nth-child(2) {
                margin-left: 10px;

                p:nth-child(1) {
                  span {
                    font-size: 16px;
                    font-weight: 600;
                    color: #292931;
                    line-height: 22px;
                  }
                  font-size: 16px;
                  font-weight: 400;
                  color: #292931;
                  line-height: 22px;
                  padding: 4px 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                p:nth-child(2) {
                  width: 100%;
                  padding: 4px 0;
                  font-size: 14px;
                  font-weight: 400;
                  color: #666c7f;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
      .addres {
        .addres_text {
          padding: 20px 0 10px 0;
          p {
            font-size: 16px;
            font-weight: 600;
            color: #292931;
            line-height: 22px;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .addres_box {
          padding-bottom: 40px;
          // height: 200px;

          .el-collapse {
            border: 2px solid #666;
            border-radius: 10px;

            .el-collapse-item {
              box-sizing: border-box;
              padding: 0px 5px;
              margin-bottom: 2px;
              .mymap {
                width: 100%;
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
  // 招聘模块
  .position {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .position_left {
      width: 100%;
      height: 100%;

      ul {
        li {
          display: flex;
          justify-content: start;
          background-color: #fff;
          padding: 15px 10px;
          margin-bottom: 15px;
          cursor: pointer;
          .left {
            width: 100%;

            text-align: left;
            div:nth-child(1) {
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              span:nth-child(1) {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #1677ff;
                line-height: 22px;
                margin-right: 20px;
              }
              span {
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ff733c;
                line-height: 22px;
                border-right: 0;
              }
            }
            div:nth-child(2) {
              display: flex;
              align-items: center;
              span:nth-child(2) {
                border: 0;
                margin-right: 5px;
              }

              .img {
                margin: 0;
                width: 20px;
                height: 20px;
                img {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                }
              }
              span {
                display: inline-block;
                height: 12px;
                line-height: 12px;
                font-weight: 400;
                color: #afb2ba;
                padding: 0 5px;
                border-right: 1px solid #afb2ba;

                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
              }

              span:last-child {
                border-right: 0;
              }
            }
          }
        }
      }
      .more {
        width: 128px;
        height: 36px;
        line-height: 36px;
        background: #ffffff;
        margin: 20px auto;
        border: 2px solid #e5e6ea;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #555c69;
      }
    }
  }

  // 资质模块
  .qualifications {
    width: 100%;
    height: auto;
    background-color: #fff;
    .nothing_prove {
      height: 300px;
      line-height: 300px;
    }
    .prove {
      width: 100%;
      height: 100%;
      padding: 20px 10px;
      display: flex;
      box-sizing: border-box;
      .img {
        width: 112px;
        height: 70px;
        border-radius: 5px;
        overflow: hidden;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          /*background-color: pink;*/
        }
      }
      .qualifications_text {
        width: calc(100% - 112px);
        text-align: left;
        padding-left: 10px;
        p:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #292931;
          padding: 5px 0;
        }
        p:nth-child(2) {
          height: 34px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #808080;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    .page {
      text-align: center;
      padding: 25px 0 50px 0;
    }
  }
  // 遮罩层
  .certificate {
    padding: 0 10px 10px;

    .img {
      width: 100%;
      height: 110px;
      border-radius: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    p:nth-child(2) {
      text-align: left;

      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #313138;
      line-height: 21px;
      margin: 5px 0;
    }
    p:nth-child(3) {
      text-align: left;

      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #797983;
    }
  }
}
/deep/ .el-dialog {
  border-radius: 10px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  padding: 5px;
}
/deep/ .el-dialog__headerbtn {
  top: 5px;
  right: 7px;
  display: none;
}
</style>
