import Vue from 'vue'
import Vuex from 'vuex'
import user from './mudules/user.js'
import query from './mudules/query.js'
import { isPc } from "../utils";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    uploadUrl: 'https://www.xinshui.com/index.php/api/common.upload/do_upload',//上传url
    goContent: '',
    goEdit: '',
    isIdentity: '',
    // 控制是否编辑
    editDisabled: '',
    // 检测 移动端 和 PC端的变化
    show_pc: isPc(),
    // is_business: '',
    get_userinfo: {},
    baseURL: '',
    wap_publish_img: ''


  },
  modules: {
    user, query
  },
  getters: {},
  mutations: {

  },
  actions: {

  },

})
