<template>
  <div>
    <Header :indexi="8"></Header>

    <!-- pc端 -->
    <div v-if="show_pc" class="pc">
      <!-- 专题头部图片 -->
      <div class="img">
        <img
          :src="headerImg"
          alt=""
        />
      </div>

      <!-- 搜索 -->
      <div class="activity_search">
        <div class="top">
          <input v-model="queryInfo.keyword" placeholder="搜索专题内容" />
          <button @click="ClickSearch">搜索</button>
        </div>
      </div>

      <!-- 活动内容 -->
      <div class="activity_content" v-loading="fullscreenLoading">
        <div v-if="!ClassRoomList.length">没有更多了...</div>
        <ul>
          <li
            style="position: relative"
            v-for="(item, index) in ClassRoomList"
            :key="item.id"
            @click="ClickClassRoom(item)"
          >
            <div>
              <img :src="item.thumb" alt="" />
            </div>

            <div>
              <p>{{ item.title }}</p>
              <p style="margin: 5px 0" v-html="item.desc">{{ item.desc }}</p>
              <p
                style="
                  font-size: 12px;
                  color: #ccc;
                  display: flex;
                  align-items: center;
                "
              >
              <div style="display: flex; font-size:12px;color: #9798ac;padding-top:2px">


              <div style="width:14px;height:14px;margin-right: 5px">
                  <img
                  width="14px"
                  height="14px"
                  src="../assets/icon/时间@2x.png"
                  alt=""
                />
              </div >
               {{ item.create_time }}
              </div>

              </p>
            </div>
            <!-- <div>
              <p>
                <img src="../assets/icon/类型@2x.png" alt="" />
                <span>{{ item.cate_name }}</span>
                <img src="../assets/icon/人数@2x.png" alt="" />
                <span>{{ item.people_count }}</span>
              </p>
              <p>{{ item.price_str }}</p>
            </div> -->
            <!-- <div class="tip">{{ item.is_online_str }}</div> -->
          </li>
        </ul>
      </div>
      <!-- 分页 -->
      <!-- 底部分页 -->
      <div class="page" v-if="total > 9">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.pagenum"
          :page-size="queryInfo.pagesize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="zt_md">
        <!-- 活动头部图片 -->
        <div class="header" v-if="bannerShow">
          <wc-swiper>
            <wc-slide v-for="(item, key) in ClassRoomList" :key="key">
              <div style="height: 188px" @click="ClickBanner(item.thumb)">
                <img
                  style="display: inline-block; height: 188px; width: 100%"
                  :src="item.thumb"
                  alt=""
                />
              </div>
            </wc-slide>
          </wc-swiper>
        </div>

        <!-- 内容 -->
        <div class="activity">
          <!-- 搜索 -->
          <div class="activity_search">
            <div class="top">
              <input
                v-model="queryInfo.keyword"
                @focus="focusPrice"
                placeholder="搜索职位、公司"
              />
              <button @click="ClickSearch">搜索</button>
            </div>
          </div>

          <!-- 活动内容 -->
          <div class="activity_content">
            <ul>
              <li
                style="position: relative"
                v-for="(item, index) in ClassRoomList"
                :key="item.id"
                @click="ClickClassRoom(item)"
              >
                <div>
                  <img :src="item.thumb" alt="" />
                </div>
                <div>
                  <p>{{ item.title }}</p>
                  <p v-html="item.desc">{{ item.desc }}</p>
                </div>
                <!-- <div>
                  <p>
                    <img src="../assets/icon/类型@2x.png" alt="" />
                    <span>{{ item.cate_name }}</span>
                    <img src="../assets/icon/人数@2x.png" alt="" />
                    <span>{{ item.people_count }}人报名</span>
                  </p>
                  <p v-if="item.is_pay == 2">
                    {{ item.price }}<span> {{ item.unit }}</span> <span></span>
                  </p>
                  <p v-if="item.is_pay == 1">免费</p>
                </div>
                <div class="tip">{{ item.is_online_str }}</div> -->
              </li>
            </ul>
          </div>
          <!-- 加载更多 -->
          <div
            class="more"
            v-if="ClassRoomList.length !== total"
            @click="ClickMore"
          >
            加载更多
          </div>
          <!-- <div
            class="more"
            @click="ClickPackUpMore"
            v-if="ClassRoomList.length > 10"
          >
            收起更多
          </div> -->
          <div class="more" v-if="ClassRoomList.length == total">
            没有更多了...
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";
export default {
  components: {
    Header,
    Footer,
  },
  metaInfo: {
    title: "新税网 - 专题",
    meta: [
      // {
      //   name: "description",
      //   content: "这是精选企业页面",
      // },
      {
        name: "keywords",
        content: "新税网-专题",
      },
    ],
  },
  data() {
    return {
      headerImg: "",
      // 默认显示 PC端的css
      show_pc: true,
      ClassRoomList: [],
      // 查询分页表参数
      queryInfo: {
        keyword: "",
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示9条
        pagesize: 9,
      },
      // 总条数
      total: 0,
      fullscreenLoading: false,
      // 移动端广告轮播的显示与隐藏
      bannerShow: true,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 获取 专题列表
    this.getClassList();
  },
  methods: {
    //   搜索
    ClickSearch() {
      if (!this.queryInfo.keyword) {
        this.$message.error("请输入内容");
        return false;
      }
      this.getClassList();
    },

    // 获取 薪税课堂列表
    getClassList() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.theme/get_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.headerImg = data.back_url;
        this.ClassRoomList = data.list;
        this.total = data.total_count;
      }
      this.fullscreenLoading = true;
      this.$http
        .post("/index.php/api/v1_0_0.theme/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,

          keyword: this.queryInfo.keyword,
        })
        .then((res) => {
          console.log(res);
          this.headerImg = res.data.back_url;
          this.ClassRoomList = res.data.list;
          this.total = res.data.total_count;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {})
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },

    ClickClassRoom(item) {
      this.$router.push({ path: "/zt/specialDetails", query: { id: item.id } });
    },

    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getClassList();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.getClassList();
    },
    // 加载更多
    ClickMore() {
      this.queryInfo.pagenum++;
      this.$http
        .post("/index.php/api/v1_0_0.course/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          cid: this.classification,
          type: this.price,
          keyword: this.queryInfo.keyword,
        })
        .then((res) => {
          res.data.list.forEach((item) => {
            this.ClassRoomList.push(item);
          });

          this.total = res.data.total_count;
        })
        .catch((error) => {});
    },
    // input框聚焦
    focusPrice() {
      this.bannerShow = false;
    },
  },
};
</script>
<style lang='less' scoped>
.tip {
  position: absolute;
  left: 25px;
  top: 15px;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #f51a75;
}
.pc {
  .img {
    width: 100%;
    height: 300px;
    line-height: 250px;
    font-size: 35px;
    font-weight: 500;
    color: #ffffff;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  .activity_search {
    width: 930px;
    // padding: 0 19%;
    margin: 30px auto;
    .top {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
      input {
        width: 100%;
        height: 52px;
        border: 0;
        outline: none;
        background: #ffffff;
        border-radius: 8px 0px 0px 8px;
        border: 1px solid #f0f0f0;
        font-size: 14px;
        padding-left: 30px;
        box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
      }
      button {
        width: 140px;
        height: 54px;
        background: #1677ff;
        border-radius: 0px 8px 8px 0px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        border: 0;
        cursor: pointer;
      }
    }
  }

  .activity_content {
    width: 1200px;
    margin: 0 auto;
    // margin: 0 10.5%;
    padding: 20px 0;
    ul {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      width: 100%;
      li {
        width: 33.33%;
        margin-bottom: 20px;
        box-sizing: border-box;
        padding: 0 10px;
        cursor: pointer;
        div:nth-child(1) {
          width: 100%;
          overflow: hidden;
          height: 230px;
          img {
            width: 100%;
            height: 100%;
            // background-color: pink;
            background-color: #fff;
          }
        }
        div:nth-child(2) {
          // border-left: 1px solid #f6f0f0;
          // border-right: 1px solid #f6f0f0;
          border-top: 0px;
          border: 1px solid #f6f0f0;

          text-align: left;
          padding: 10px;
          p:nth-child(1) {
            font-size: 16px;
            font-weight: 700;
            color: #333333;
            line-height: 25px;
            text-stroke: 1px #979797;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p:nth-child(2) {
            height: 37px;
            font-size: 14px;
            font-weight: 400;
            color: #8a919e;
            line-height: 20px;
            overflow: hidden; //强制多行显示
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            height: 37px;
          }
        }
        div:nth-child(3) {
          border-left: 1px solid #f6f0f0;
          border-right: 1px solid #f6f0f0;
          height: 41px;
          background: #f5f8ff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          p {
            display: flex;
            align-items: center;
            img {
              display: inline-block;
              width: 13px;
              height: 13px;
              // background-color: pink;
              margin-right: 6px;
            }
            span {
              font-size: 12px;
              font-weight: 400;
              color: #9798ac;
              line-height: 17px;
              margin-right: 20px;
            }
          }
          p:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #f51a75;
            line-height: 22px;
            span {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 600;
              color: #f51a75;
              line-height: 22px;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .page {
    text-align: center;
    padding: 0px 0 30px 0;
  }
}
// 移动端
.zt_md {
  .header {
    margin-top: 60px;

    /deep/.wc-pagination {
      bottom: 0;
      // justify-content: flex-end;

      padding-right: 8px;
      box-sizing: border-box;
    }
    /deep/.wc-dot {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      margin: 0 2px;

      background: #ffffff;
      opacity: 0.5;
    }
    /deep/ .wc-dot-active {
      background-color: #fff;
      width: 10px;
      opacity: 1;
    }
  }
  .activity {
    // .inputMargin {
    //   margin-top: 90px !important;
    // }
    .activity_search {
      width: 100%;
      padding: 20px 0;
      margin: 0;

      .top {
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          width: 255px;
          height: 40px;
          background: #ffffff;
          box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
          border-radius: 4px 0px 0px 4px;
          border: 1px solid #f0f0f0;
          border: 0;
          outline: none;
          box-sizing: border-box;
          padding: 0 10px;
        }
        button {
          width: 80px;
          height: 40px;
          background: #1677ff;
          border-radius: 0px 4px 4px 0px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 17px;
        }
      }
    }

    .activity_content {
      margin: 0;
      padding: 0 10px;
      box-sizing: border-box;
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        li {
          width: 100%;
          margin-bottom: 12px;
          padding: 0;
          box-sizing: border-box;
          div:nth-child(1) {
            width: 100%;
            height: 223px;
            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
          div:nth-child(2) {
            border: 1px solid #f6f0f0;
            border-bottom: 0;

            box-sizing: border-box;

            text-align: left;
            padding: 10px;
            p:nth-child(1) {
              font-size: 15px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 700;

              // font-weight: 500;
              color: #333333;
              // line-height: 21px;
            }
            p:nth-child(2) {
              height: 42px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #8a919e;

              line-height: 20px;
              margin-top: 5px;
              color: #8a919e;
              overflow: hidden; //强制多行显示
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          div:nth-child(3) {
            border-top: 0;

            border: 1px solid #f6f0f0;

            box-sizing: border-box;
            padding: 0;
            width: 100%;
            height: 40px;
            background: #f5f8ff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            p:nth-child(1) {
              display: flex;
              align-items: center;
              img {
                display: inline-block;
                width: 13px;
                height: 13px;
                margin: 5px;
              }
              span {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9798ac;
                margin-right: 15px;
              }
            }
            p:nth-child(2) {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #f51a75;
              line-height: 17px;
            }
          }
        }
        li:last-child {
          margin-bottom: 0;
        }
      }
    }
    .more {
      width: 128px;
      height: 36px;
      line-height: 36px;
      background: #ffffff;
      margin: 20px auto;
      border: 2px solid #e5e6ea;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #555c69;
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 180px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>
