import axios from 'axios'

// import router from 'vue-router'
import router from '../router'

import { Message, Loading } from 'element-ui';
// 创建axios实例
const service = axios.create({
  baseURL: 'https://www.xinshui.com/', // api的base_url
  // baseURL: 'http://192.168.0.42:3032/',
  timeout: 15000, // 请求超时时间
})

// request 拦截器
service.interceptors.request.use(
  function (config) {
    if (!config.notNeed) {
      // 添加 token 参数
      const user_id = localStorage.getItem("user_id")
      const user_token = localStorage.getItem("user_token")

      if (user_id && user_token) {

        config.data.user_id = user_id
        config.data.user_token = user_token
      }
    }
    // loading
    // Toast.loading({ mask: true, message: '', duration: 15000 })

    return config
  },
  error => {
    Promise.reject(error)
  }
)

// respone 拦截器
service.interceptors.response.use(
  response => {
    // 清除 loading
    // Toast.clear()
    // console.log(response);
    // console.log(response.data.errcode);
    if (response.data.errcode == 0) {
      console.log(response);
      return Promise.reject(response.data);
    }
    // 错误处理
    const res = response.data
    if (Number(res.code) == 200) {
      return Promise.resolve(res)
    } else if (res.code == 201) {
      // 弹出错误
      Message.error(res.msg)
      return Promise.reject(res)
    } else if (res.code == 202) {
      // 登录令牌错误 跳转登录
      Message.error(res.msg)
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_token");
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        } //登录成功后跳入浏览的当前页面
      })
      console.log('跳转登录')
      return Promise.reject(res)
    } else if (res.code == 400) {
      return Promise.reject(res)
    } else if (res.code == 401) {
      return Promise.reject(res)
    } else {

      // 500 或者其他错误提示
      Message.error(res.msg)
      return Promise.reject(res)
    }
  },
  error => {
    Message.error(error.msg)
    return Promise.reject(error)
  }
)

export default service
