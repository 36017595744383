<template>
  <div>
    <div v-if="show_pc">
      <div class="message">
        <div class="message_articleList">
          <div class="message_articleList_left">
            <div class="article" v-if="path !== '/zx'">
              <div class="article_top">
                <ul>
                  <li
                    v-for="(item, index) in article"
                    v-if="index < 7"
                    :key="index"
                    :class="{ color: id == item.id }"
                    @click="ClickaRticle(item.id)"
                  >
                    <p>
                      {{ item.title }}
                    </p>
                    <div :class="{ bgcolor: id == item.id }"></div>
                  </li>
                </ul>
                <div class="gdly" @click="CickMore(article.length)">
                  更多领域
                </div>
              </div>
              <div class="moreclass" v-show="show">
                <ul>
                  <li
                    v-for="(item, index) in article"
                    v-if="index >= 7"
                    :key="index"
                    :class="{ color: id == item.id }"
                    @click="ClickaRticle(item.id)"
                  >
                    <el-tooltip
                      effect="light"
                      :content="item.title"
                      placement="top"
                    >
                      <p>
                        {{ item.title }}
                      </p>
                    </el-tooltip>
                    <div :class="{ bgcolor: id == item.id }"></div>
                  </li>
                </ul>
              </div>
            </div>
            <Article></Article>
          </div>
          <div class="message_articleList_rigth">
            <!-- 作者专栏 -->
            <Author></Author>

            <!-- 推荐企业  企业招聘 -->
            <Enterprise></Enterprise>

            <!-- 最新活动 -->
            <NewActivity></NewActivity>

            <!-- 薪税课堂 -->
            <Classroom></Classroom>

            <!-- 热门文库 -->
            <Library></Library>
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="articlesClass_md" v-if="path !== '/zx'">
        <div class="cont">
          <ul>
            <li
              v-for="(item, index) in article"
              :key="index"
              @click="ClickaRticle(item.id)"
              :class="{ color: id == item.id }"
            >
              <p>
                {{ item.title }}
              </p>
              <div :class="{ bgcolor: id == item.id }"></div>
            </li>
          </ul>
        </div>
      </div>
      <Article></Article>
      <Library></Library>
    </div>
  </div>
</template>

<script>
import { log } from "util";

// 作者组件
import Author from "./author";

// 文章推荐列表组件
import Article from "./article";

// 企业推荐
import Enterprise from "./enterprise";

// 最新活动
import NewActivity from "./newactivity";

// 薪税课堂
import Classroom from "./classroom";

// 热门文库
import Library from "./library";

export default {
  components: {
    Author,
    Article,
    Enterprise,
    NewActivity,
    Classroom,
    Library,
  },

  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,

      path: "",

      // 控制更多分类显示与隐藏
      show: false,
      // 默认显示第一个 文章推荐
      id: 0,

      // 查询文章列表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      // 总条数
      total: 10,
      // 文章分类
      article: [],
      // 上文章分类
      articleTop: [],
      // 下文章分类
      articleBottom: [],
      // 推流地址
      push_url: "",
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.path = this.$route.path;
    this.$store.state.query.classId = 0;
    this.getA();
  },

  methods: {
    getA() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.article/get_cate_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.article = data.list;
      }

      //分类列表
      this.$http
        .get("/index.php/api/v1_0_0.article/get_cate_list", {
          notNeed: true,
        })
        .then((res) => {
          this.article = res.data.list;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {});
    },

    // 点击更多
    CickMore(length) {
      this.$router.push("/zx");
      if (length <= 8) {
        // this.$message.info("没有更多了").onClose(() => {});
      }
      if (length > 8) {
        this.show = !this.show;
      }
    },
    // 点击文章分类按钮
    ClickaRticle(id) {
      console.log(id);
      this.id = id;
      this.$store.state.query.classId = id;
    },
  },
  watch: {
    prizeNumber() {
      this.freeze = true;
      this.wheelDeg = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.message {
  .message_articleList {
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 1200px;
    margin: 24px auto 0;
    text-align: left;
    .message_articleList_left {
      width: 66.6%;
      height: 100%;
      .article {
        position: relative;
        height: 90px;
        .article_top {
          height: 100%;
          margin: 0 10px 7px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          ul {
            padding: 0 10px;
            // width: 85%;
            height: 100%;
            display: flex;
            align-items: center;
            // justify-content: space-around;
            li:nth-child(1) {
              // margin-left: 14px;
            }
            li:last-child {
              margin-right: 0;
            }
            .color {
              p {
                color: #1677ff;
              }
            }
            li {
              display: flex;
              // align-items: center;
              justify-content: start;
              flex-direction: column;
              font-size: 14px;
              color: #292931;
              margin-top: 20px;
              // width: 200px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-right: 36px;

              cursor: pointer;
              p {
                // width: 100px;
                font-weight: 600;
                height: 100%;
                line-height: 100%;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
                font-size: 16px;
                color: #292931;
                white-space: nowrap; //强制一行显示
                overflow: hidden;
                text-overflow: ellipsis;
              }

              div {
                width: 58px;
                height: 2px;
                text-align: left;
                margin-top: 15px;
              }
              .bgcolor {
                background: #1677ff;
              }
            }

            li:nth-child(7) {
              padding-right: 5px;
            }
          }

          .gdly {
            display: inline-block;
            color: #666;
            text-align: right;
            font-size: 14px;
            padding: 0 10px 0 12px;
            border-left: 2px solid #9d9d9d;
            cursor: pointer;

            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9d9d9d;
            height: 15px;
            line-height: 15px;
          }
        }
        .moreclass {
          ul {
            width: 88%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: start;

            padding: ;

            li:last-child {
              margin-right: 0;
            }

            li {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              color: #292931;
              margin-right: 0px;
              // width: 80px;

              cursor: pointer;
              p {
                font-size: 14px;
                width: 100%;
                height: 100%;
                line-height: 100%;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
              }

              div {
                width: 58px;
                height: 2px;
                text-align: center;
                margin-top: 15px;
              }
              .bgcolor {
                background: #1677ff;
              }
            }
            .color {
              p {
                color: #1677ff;
              }
            }

            li:nth-child(7) {
              padding-right: 5px;
            }
          }
        }
        .moreclass {
          position: absolute;
          bottom: -50px;
          padding-left: 14px;
          width: 100%;
          height: 54px;
          line-height: 54px;
          background-color: #fff;
          box-sizing: border-box;
          transition: height ease 0.2s;
          z-index: 2;
          ul {
            li {
              display: inline-block;
              margin-left: 30px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #292931;
              line-height: 22px;

              cursor: pointer;
            }
          }
        }
      }
    }
    .message_articleList_rigth {
      width: 32.65%;
      height: 100%;
    }
  }
}
// 移动端

.articlesClass_md {
  width: 100%;
  overflow: hidden;
  // 去掉滚动条
  .cont::-webkit-scrollbar {
    display: none;
  }
  .cont {
    height: 60px;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    ul {
      height: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      // margin-top: 13px;
      left: 0px;
      top: 0px;
      position: absolute;
      overflow-x: auto;
      white-space: nowrap;
      li:nth-child(1) {
        margin-left: 9px;
      }
      .color {
        p {
          color: #1677ff;
        }
      }
      li {
        letter-spacing: 1px;

        margin-right: 22px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 17px;

        display: inline-block;
        float: left;
        font-size: 13px;
        text-decoration: none;
        p {
          // width: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        div {
          width: 44px;
          height: 2px;
          text-align: center;
          margin: 10px auto 0;
        }
        .bgcolor {
          background: #1677ff;
        }
      }
    }
  }
}

// 控制付费的样得字体颜色
.Color {
  color: #ff5916 !important;
}
</style>
