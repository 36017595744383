<template>
  <div>
    <div v-if="show_pc" class="pc">
      <div class="invite">
        <div class="img">
          <img :src="cover" width="100%" height="100%" />
        </div>
        <!-- 积分明细 -->
        <div class="bottom">
          <div class="jfmx">
            <p style="position: relative">
              <span style="width: 88px; background-color: #fff">我的邀请</span>
              <span
                style="position: absolute; bottom: 0; left: 0; width: 72px"
              ></span>
            </p>

            <p class="jfmx_btn" @click="invite" style="cursor: pointer">
              邀请好友
            </p>
          </div>
          <ul>
            <li v-if="!jfmxList.length">您还没有邀请好友...</li>
            <li v-for="(item, index) in jfmxList" :key="index">
              <div class="left">
                <p>
                  <img
                    :src="item.headimg"
                    alt=""
                    style="width: 50px; height: 50px"
                  />
                </p>
                <p>{{ item.nickname }}</p>
              </div>
              <div class="right">
                <p>{{ item.create_time }}</p>
              </div>
            </li>
          </ul>

          <el-pagination
            :hide-on-single-page="true"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.pagenum"
            :page-size="queryInfo.pagesize"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>

        <el-dialog
          style="width: 50%; left: 25%"
          :visible.sync="centerDialogVisible"
          :show-close="false"
          :close-on-click-modal="false"
          center
        >
          <img :src="qrcode" style="width: 100%" />
          <el-input
            placeholder="请输入内容"
            v-model="yaoqing_url"
            :disabled="true"
          >
          </el-input>
          <div
            style="
              color: #1677ff;
              margin-top: 10px;
              text-align: center;
              font-weight: 700;
              position: relative;
              cursor: pointer;
            "
          >
            <p @click="copyURL">复制链接地址</p>
            <img
              src="../../../assets/image/close.png"
              style="
                width: 40px;
                height: 40px;
                position: absolute;
                top: 60px;
                left: 50%;
                margin-left: -20px;
              "
              @click="centerDialogVisible = false"
            />
          </div>
        </el-dialog>
      </div>
    </div>
    <div v-if="!show_pc" id="mobile">
      <div style="width: 100%; height: 60px"></div>
      <Header></Header>
      <div class="invite">
        <img :src="cover" style="width: 100%; height: 179px" />
        <!-- 积分明细 -->
        <div class="bottom">
          <div class="jfmx">
            <p style="position: relative">
              <span style="width: 88px; background-color: #fff">我的邀请</span>
              <span
                style="position: absolute; bottom: -4px; left: 0; width: 72px"
              ></span>
            </p>

            <p
              class="btn"
              @click="invite"
              style="
                cursor: pointer;
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                margin-bottom: 0;
                color: #fff;
                line-height: 44px;
              "
            >
              邀请好友
            </p>
          </div>
          <ul>
            <li v-if="!jfmxList.length">您还没有邀请好友...</li>
            <li v-for="(item, index) in jfmxList" :key="index">
              <div class="left">
                <p>
                  <img
                    :src="item.headimg"
                    alt=""
                    style="width: 50px; height: 50px"
                  />
                </p>
                <p>{{ item.nickname }}</p>
              </div>
              <div class="right">
                <p>{{ item.create_time }}</p>
              </div>
            </li>
          </ul>

          <el-pagination
            :hide-on-single-page="true"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.pagenum"
            :page-size="queryInfo.pagesize"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>

        <el-dialog
          style="width: 50%; left: 25%"
          :visible.sync="centerDialogVisible"
          :show-close="false"
          :close-on-click-modal="false"
          center
        >
          <img :src="qrcode" style="width: 100%" />
          <el-input
            placeholder="请输入内容"
            v-model="yaoqing_url"
            :disabled="true"
          >
          </el-input>
          <div
            style="
              color: #1677ff;
              margin-top: 10px;
              text-align: center;
              font-weight: 700;
              position: relative;
              cursor: pointer;
            "
          >
            <p @click="copyURL">复制链接地址</p>
            <img
              src="../../../assets/image/close.png"
              style="
                width: 40px;
                height: 40px;
                position: absolute;
                top: 60px;
                left: 50%;
                margin-left: -20px;
              "
              @click="centerDialogVisible = false"
            />
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      cover: "../../../assets/image/5cb7e08b8770c@2x.png",
      qrcode:
        "http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201212/185654_538166.gif",
      yaoqing_url: "http://www.baidu.com",
      centerDialogVisible: false,
      //   积分明细列表
      jfmxList: [],
      queryInfo: {
        pagesize: 10,
        pagenum: 1,
      },
      total: 0,
      show_pc: true,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.$parent.setIcon = "邀请好友";
    this.get_list();
    this.get_invite();
  },
  methods: {
    // 获取邀请信息
    get_invite() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_invite_info", {})
        .then(({ data }) => {
          this.cover = data.cover;
          this.yaoqing_url = data.invite_url;
          this.qrcode = data.qrcode;
        })
        .catch((error) => {});
    },
    // 获取邀请列表
    get_list() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_invite_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          console.log(data);
          this.jfmxList = data.list;
          this.total = data.total_count;
        })
        .catch((error) => {});
    },
    invite() {
      this.centerDialogVisible = true;
    },
    //复制二维码
    copyURL() {
      this.$copyText(this.yaoqing_url).then(
        (e) => {
          this.$message.success("复制成功");
          this.centerDialogVisible = false;
        },
        (e) => {
          this.$message.error("复制失败");
        }
      );
    },

    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      console.log(newNum);
      this.queryInfo.pagenum = newNum;
      this.get_list();
    },
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f5f5f5");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>

<style scoped lang='less'>
#mobile {
  background-color: #fff;
  /deep/.el-dialog__header {
    padding: 0;
  }
  .invite {
    /* padding: 20px; */
  }
  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    line-height: 44px;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .bottom {
    padding: 10px;
    .jfmx {
      display: flex;

      /* flex-direction: column; */
      /* align-items: center; */
      /* margin-left: 20px; */
      /* width: 72px; */

      justify-content: space-between;
      margin-top: 10px;

      .jfmx_btn {
        width: 88px;
        height: 34px;
        background: #1677ff;
        border-radius: 5px;
        color: #fff;
        font-weight: 400;
        text-align: center;
        font-size: 14px;
        line-height: 34px;
      }

      p {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #292931;
        line-height: 25px;
        text-align: left;
        margin-bottom: 4px;
      }

      span {
        display: inline-block;
        width: 64px;
        height: 3px;
        background: #1677ff;
      }
    }

    ul {
      margin-top: 20px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0 12px 0;

        .left {
          p {
            text-align: left;
          }

          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #292931;
            line-height: 20px;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
            margin-top: 5px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1f1f24;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
          }
        }
      }
    }
  }
}
.pc {
  .invite {
    padding: 20px;
    .img {
      width: 100%;
      height: 245.5px;
      overflow: hidden;
    }
  }

  .bottom {
    .jfmx {
      display: flex;

      /* flex-direction: column; */
      /* align-items: center; */
      /* margin-left: 20px; */
      /* width: 72px; */

      justify-content: space-between;
      margin-top: 30px;

      .jfmx_btn {
        width: 88px;
        height: 34px;
        background: #1677ff;
        border-radius: 5px;
        color: #fff;
        font-weight: 400;
        text-align: center;
        font-size: 14px;
        line-height: 34px;
      }

      p {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #292931;
        line-height: 25px;
        text-align: left;
        margin-bottom: 4px;
      }

      span {
        display: inline-block;
        width: 64px;
        height: 3px;
        background: #1677ff;
      }
    }

    ul {
      margin-top: 20px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0 12px 0;

        .left {
          p {
            text-align: left;
          }

          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #292931;
            line-height: 20px;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
            margin-top: 5px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1f1f24;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
          }
        }
      }
    }
  }
}
</style>
