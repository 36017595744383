<template>
  <!-- 寻求报道 -->
  <div>
    <div v-if="show_pc" v-loading.fullscreen.lock="loading">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <!-- 公司名称 -->
        <el-form-item label="公司名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>

        <!-- 项目名称 -->
        <el-form-item label="项目名称" prop="project">
          <el-input
            v-model="ruleForm.project"
            placeholder="请输入项目名称"
          ></el-input>
        </el-form-item>

        <!-- 行业 -->
        <el-form-item label="行业" prop="industry">
          <template v-for="(item, index) in industryList">
            <span
              v-model="industry"
              style="margin-right: 15px; cursor: pointer"
              :class="{ blueBG: item.select }"
              @click="industry(index)"
              >{{ item.title }}</span
            >
          </template>
        </el-form-item>

        <el-form-item label="城市" prop="region">
          <el-cascader
            :options="optionsCity"
            v-model="value"
            clearable
            :props="{ label: 'shortname', children: 'sub', value: 'id' }"
            @change="handleCascader"
            placeholder="请选择城市"
          ></el-cascader>
        </el-form-item>

        <!-- 公司简介 -->
        <el-form-item label="公司介绍" prop="companyDesc">
          <el-input
            type="textarea"
            v-model="ruleForm.companyDesc"
            placeholder="请输入简介"
            :autosize="true"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系人" prop="linkman">
          <div class="box">
            <input placeholder="姓名" type="text" v-model="ruleForm.lx_name" />
            <input
              placeholder="微信"
              type="text"
              v-model="ruleForm.lx_weixin"
            />
            <input
              placeholder="手机号"
              type="number"
              v-model="ruleForm.lx_phone"
              maxlength="11"
            />
          </div>
        </el-form-item>

        <!-- 是否右官网 -->
        <el-form-item label="是否有官网" prop="official">
          <el-radio-group v-model="ruleForm.official">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
            <span class="height"> </span>
          </el-radio-group>
        </el-form-item>

        <!-- 是否有App -->
        <el-form-item label="是否有App" prop="App">
          <el-radio-group v-model="ruleForm.App">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
            <span class="height"> </span>
          </el-radio-group>
        </el-form-item>

        <!-- 是否有公众号 -->
        <el-form-item label="是否有公众号" prop="Accounts" label-width="120px">
          <el-radio-group v-model="ruleForm.Accounts">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
            <span class="height"> </span>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            style="
              margin-left: 0px;
              display: inline-block;
              background-color: #1677ff;
              width: 104px;
              border-radius: 0;
              height: 34px;
              line-height: 4px;
            "
            @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div v-if="!show_pc" id="mobile">
      <Header></Header>
      <div style="width: 100%; height: 90px"></div>
      <div
        class="release_bg"
        :style="{
          backgroundImage:
            'url(https://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20210204/153945_458907.png)',
        }"
        style="margin-bottom: 30px"
      >
        <div style="width: 100%; height: 50px"></div>
        <div style="padding: 10px" v-loading.fullscreen.lock="loading">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            style="background-color: #fff; padding: 20px 10px"
          >
            <!-- 公司名称 -->
            <el-form-item label="公司名称" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入公司名称"
              ></el-input>
            </el-form-item>

            <!-- 项目名称 -->
            <el-form-item label="项目名称" prop="project">
              <el-input
                v-model="ruleForm.project"
                placeholder="请输入项目名称"
              ></el-input>
            </el-form-item>

            <!-- 行业 -->
            <el-form-item label="行业" prop="industry">
              <div style="display: flex; flex-wrap: wrap; margin-top: 5px">
                <template v-for="(item, index) in industryList">
                  <div
                    v-model="industry"
                    style="
                      cursor: pointer;
                      padding: 0 10px;
                      margin-bottom: 10px;
                      margin-right: 6px;
                      border: 1px solid #e5e6ea;
                      text-align: left;
                    "
                    :class="{ blueBG: item.select }"
                    @click="industry(index)"
                  >
                    {{ item.title }}
                  </div>
                </template>
              </div>
            </el-form-item>

            <el-form-item label="城市" prop="region">
              <el-cascader
                :options="optionsCity"
                v-model="value"
                clearable
                :props="{ label: 'shortname', children: 'sub', value: 'id' }"
                @change="handleCascader"
                placeholder="请选择城市"
              ></el-cascader>
            </el-form-item>

            <!-- 公司简介 -->
            <el-form-item label="公司介绍" prop="companyDesc">
              <el-input
                type="textarea"
                :autosize="true"
                v-model="ruleForm.companyDesc"
                placeholder="请输入简介"
                style="margin: 6px 0"
              ></el-input>
            </el-form-item>

            <el-form-item label="联系人" prop="linkman">
              <div
                class="box"
                style="display: flex; flex-wrap: wrap; margin-top: 8px"
              >
                <input
                  placeholder="姓名"
                  type="text"
                  v-model="ruleForm.lx_name"
                />
                <input
                  placeholder="微信"
                  type="text"
                  v-model="ruleForm.lx_weixin"
                />
                <input
                  placeholder="手机号"
                  type="number"
                  v-model="ruleForm.lx_phone"
                  maxlength="11"
                />
              </div>
            </el-form-item>

            <!-- 是否右官网 -->
            <el-form-item label="是否有官网" prop="official">
              <el-radio-group v-model="ruleForm.official">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
                <span class="height"> </span>
              </el-radio-group>
            </el-form-item>

            <!-- 是否有App -->
            <el-form-item label="是否有App" prop="App">
              <el-radio-group v-model="ruleForm.App">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
                <span class="height"> </span>
              </el-radio-group>
            </el-form-item>

            <!-- 是否有公众号 -->
            <el-form-item
              label="是否有公众号"
              prop="Accounts"
              label-width="120px"
            >
              <el-radio-group v-model="ruleForm.Accounts">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
                <span class="height"> </span>
              </el-radio-group>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                class="btn"
                @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
          <div style="width: 100%; height: 60px"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../header.vue";
import city from "../../common/city.js";
export default {
  components: {
    Header,
  },
  data() {
    //自定义验证
    var linkman = (rule, value, callback) => {
      let form = this.ruleForm;
      if (form.lx_name == "") {
        callback(new Error("请输入联系人姓名"));
        return;
      }
      if (form.lx_weixin == "") {
        callback(new Error("请输入联系人微信"));
        return;
      }
      if (form.lx_phone == "") {
        callback(new Error("请输入联系人电话"));
        return;
      }
      if (form.lx_phone.length < 11 || form.lx_phone.length > 11) {
        callback(new Error("联系人电话不正确"));
        return;
      }
      callback();
    };
    var industry = (rule, value, callback) => {
      if (
        this.ruleForm.province == "" ||
        this.ruleForm.city == "" ||
        this.ruleForm.area == ""
      ) {
        callback(new Error("请选择城市"));
        return;
      }
      callback();
    };
    return {
      value: "",
      optionsCity: "",
      // 寻求报道
      industryList: [], //行业列表
      optionsCity: [],
      ruleForm: {
        name: "",
        region: "",
        region2: "",
        region3: "",
        project: "",
        companyDesc: "",
        official: "是",
        App: "是",
        Accounts: "是",
        lx_name: "",
        lx_weixin: "",
        lx_phone: "",
        province: 0, //省
        city: 0, //市
        area: 0, //区
        industry: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        project: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
        industry: [
          {
            required: true,
            message: "请选择行业",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择城市",
            trigger: "change",
            // validator: industry,
          },
        ],
        companyDesc: [
          {
            required: true,
            message: "请输入公司介绍",
            trigger: "blur",
          },
        ],
        linkman: [
          {
            required: true,
            validator: linkman,
            trigger: "blur",
          },
          // required: true,
          // message: "请输入联系人信息",
          // trigger: "blur"
        ],
        official: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
        App: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
        Accounts: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
      },

      cityList2: [],
      cityList: [], // 城市列表
      cityList3: [],
      user: "",
      show_pc: true,
      loading: false,
    };
  },

  created() {
    // document.querySelector('body').style.backgroundColor = "#f5f5f5"

    this.loading = true;

    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    //获取省市区
    this.$http
      .post("/index.php/api/v1_0_0.address/get_address", {
        notNeed: true,
      })
      .then((data) => {
        console.log(data);
        this.cityList = data.data.list;
        this.optionsCity = data.data.list;
        // console.log(data.data.list)
      })
      .catch((error) => {});
    //获取行业列表
    this.$http
      .post("/index.php/api/v1_0_0.report/get_business_type", {})
      .then((data) => {
        data.data.list.map((item, index) => {
          item.select = false;
        });
        data.data.list[0].select = true;
        this.industryList = data.data.list;
        // this.industryList[0]
        this.loading = false;
      })
      .catch((error) => {});
  },
  methods: {
    handleCascader(e) {
      console.log(e);
      if (e.length == 0) {
        this.rules.region = [
          {
            required: true,
            message: "请选择城市",
            trigger: "change",
          },
        ];
        return;
      }
      this.$refs.ruleForm.clearValidate("region");
      this.rules.region = "";
      this.ruleForm.province = e[0];
      this.ruleForm.city = e[1];
      this.ruleForm.area = e[2];
    },
    handleChange(e) {
      console.log(e);
    },
    //行业样式切换
    industry(item) {
      this.industryList.map((item, index) => {
        item.select = false;
      });
      this.industryList[item].select = true;
      this.ruleForm.industry = this.industryList[item].id;
    },

    submitForm(formName) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/index.php/api/v1_0_0.report/publish_report", {
              publish_data: {
                type: this.ruleForm.industry, //行业类型id
                province: this.ruleForm.province, //省
                city: this.ruleForm.city, //市
                area: this.ruleForm.area, //区
                name: this.ruleForm.name, //公司名称
                job_name: this.ruleForm.project, //项目名称
                description: this.ruleForm.companyDesc, //公司介绍
                lx_name: this.ruleForm.lx_name, //联系人姓名
                lx_weixin: this.ruleForm.lx_weixin, //联系人微信
                lx_phone: this.ruleForm.lx_phone, //联系人电话
                is_web: this.ruleForm.official == "是" ? 1 : 2, //官网1是2否
                is_app: this.ruleForm.App == "是" ? 1 : 2, //app 1是2否
                is_wx_public: this.ruleForm.Accounts == "是" ? 1 : 2, //微信公众号 1是2否
              },
            })
            .then((data) => {
              console.log(data);
              this.$message.success(data.msg);
              this.$router.replace("/");
              return;
            })
            .catch((error) => {});
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // clearInput(){
    //   this.ruleForm= {
    //     name: "",
    //     region: '',
    //     region2: '',
    //     region3: '',
    //     project: '',
    //     companyDesc: '',
    //     official: '是',
    //     App: '是',
    //     Accounts: '是',
    //     lx_name: '',
    //     lx_weixin: '',
    //     lx_phone: '',
    //     province: 0, //省
    //     city: 0, //市
    //     area: 0, //区
    //     industry: '医药',
    //   }
    // }
  },
};
</script>
<style lang='less' scoped>
body {
  background-color: #f5f5f5;
}
#mobile {
  /deep/.el-form-item__error {
    padding-top: 0;
  }

  /deep/.el-input {
    margin-top: 8px;
  }

  .release_bg {
    width: 100%;
    height: 180px;

    background: #f1f5f6;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    /* padding: 120px 0 50px; */
  }

  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    /* line-height: 44px; */
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .box {
    /* display: flex;
   flex: 1;
    justify-content: space-between;
    align-items: center; */

    input {
      width: 100%;
      height: 34px;
      background: #ffffff;
      border: 1px solid #e5e6ea;
      display: block;
      outline: none;
      padding-left: 10px;
      margin-bottom: 8px;
    }
  }
}

.blueBG {
  background-color: #1677ff;
  color: #fff;
}

/deep/.el-select {
  width: 160px;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 28%;
    height: 34px;
    background: #ffffff;
    border: 1px solid #e5e6ea;

    outline: none;
    padding-left: 10px;
  }
}

span {
  display: inline-block;
  height: 34px;
  line-height: 34px;
  background: #ffffff;
  border: 1px solid #e5e6ea;
  padding: 0 10px;

  font-size: 14px;
  font-weight: 400;
  color: #89909d;
}

.height {
  display: inline-block;
  height: 24px;
  border: 0;
}
</style>
