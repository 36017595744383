<template>
  <div>
    <div class="pc" v-if="show_pc">
      <Header></Header>
      <div
        class="release_box"
        v-if="isRouterAlive"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
        <div
          class="release_bg"
          :style="{
            backgroundImage:
              'url(' +
              'http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201214/095440_327691.png' +
              ')',
          }"
        >
          <div class="release">
            <div class="release_left">
              <p>企业认证</p>
              <p>
                认证企业时需缴纳300元的企业认证费,认证通过后,您可发布资讯,招聘,文章,企业信息,文库,活动,课堂等信息
              </p>
            </div>
            <div class="release_rigth">
              <div
                style="margin-bottom: 30px; color: #f56c6c; cursor: pointer"
                @click="goApprove"
              >
                {{ content }}
              </div>
              <div style="margin-bottom: 30px; color: #f56c6c; cursor: pointer">
                {{ content1 }}
              </div>
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="120px"
              >
                <!-- 企业LOGO -->
                <el-form-item label="企业LOGO" prop="LOGO">
                  <el-upload
                    :action="uploadUrl"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :disabled="disabled"
                    :on-success="logoSuccess"
                    :show-file-list="false"
                    :on-remove="handleRemove"
                  >
                    <img
                      :src="ruleForm.LOGOs"
                      v-if="ruleForm.LOGOs"
                      style="width: 108px; height: 108px"
                      alt=""
                    />
                    <div class="uploadClass" v-if="!ruleForm.LOGOs">
                      <i
                        class="el-icon-plus"
                        style="font-size: 36px; margin-top: 8px; color: #d8d8d8"
                      ></i>
                      <p style="margin-top: 4px; font-size: 14px">上传logo</p>
                      <p>(尺寸:120*120px)</p>
                    </div>
                  </el-upload>
                  <!-- 大logo -->
                  <div class="bigLogo">
                    <el-upload
                      :action="uploadUrl"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :disabled="disabled"
                      :on-success="fengmainSuccess"
                      :show-file-list="false"
                      :on-remove="handleRemove"
                    >
                      <img
                        :src="ruleForm.cover"
                        v-if="ruleForm.cover"
                        style="width: 100%; height: 108px"
                        alt=""
                      />
                      <div class="uploadClass" v-if="!ruleForm.cover">
                        <i
                          class="el-icon-plus"
                          style="
                            font-size: 36px;
                            margin-top: 8px;
                            color: #d8d8d8;
                          "
                        ></i>
                        <p style="margin-top: 4px; font-size: 14px">上传logo</p>
                        <p>(尺寸:280*196px)</p>
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item label="规模人数" prop="scale">
                  <!-- 规模人数 -->
                  <el-select
                    v-model="ruleForm.scale"
                    placeholder="请选择规模人数"
                    style="width: 100%"
                    :disabled="disabled"
                  >
                    <el-option
                      v-for="item in scaleList"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <!-- 企业类型 -->
                <el-form-item label="企业类型" prop="genre">
                  <el-select
                    v-model="ruleForm.genre"
                    placeholder="请选择企业类型"
                    style="width: 100%"
                    :disabled="disabled"
                  >
                    <el-option
                      v-for="(item, index) in genreList"
                      :key="index"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <!-- 企业网址 -->
                <el-form-item label="企业网址">
                  <el-input
                    v-model="ruleForm.url"
                    placeholder="请输入企业网址"
                    :disabled="disabled"
                  ></el-input>
                </el-form-item>

                <!-- 是否上市 -->
                <el-form-item
                  label="是否上市"
                  prop="resource"
                  style="position: relative"
                >
                  <el-radio-group
                    v-model="ruleForm.resource"
                    :disabled="disabled"
                  >
                    <el-radio label="未上市"></el-radio>
                    <el-radio label="已上市"></el-radio>
                    <span style="line-height: 50px"></span>
                  </el-radio-group>
                </el-form-item>

                <!-- 企业名称 -->
                <el-form-item label="企业名称" prop="companyName">
                  <el-input
                    v-model="ruleForm.companyName"
                    placeholder="请输入企业名称"
                    :disabled="disabled"
                  ></el-input>
                </el-form-item>

                <!-- 姓名 -->
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入姓名"
                    :disabled="disabled"
                  ></el-input>
                </el-form-item>

                <!-- 联系方式 -->
                <el-form-item label="联系方式" prop="contactWay">
                  <el-input
                    v-model="ruleForm.phone"
                    placeholder="请输入手机号"
                    :disabled="disabled"
                  ></el-input>
                  <span style="margin: 0 8px"></span>
                  <el-input
                    v-model="ruleForm.wx"
                    placeholder="请输入微信号"
                    :disabled="disabled"
                  ></el-input>
                </el-form-item>

                <!-- 营业执照 -->
                <el-form-item label="营业执照" prop="license">
                  <div class="license">
                    <el-upload
                      :action="uploadUrl"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :disabled="disabled"
                      :show-file-list="false"
                      :on-change="licenseChange"
                      :on-success="licenseImg"
                      :on-remove="licenseRemove"
                    >
                      <img
                        :src="ruleForm.license"
                        v-if="ruleForm.license"
                        style="width: 100%; height: 108px"
                        alt=""
                      />
                      <div class="uploadClass" v-if="!ruleForm.license">
                        <i
                          class="el-icon-plus"
                          style="
                            font-size: 36px;
                            margin-top: 8px;
                            color: #d8d8d8;
                          "
                        ></i>
                        <p style="margin-top: 14px; font-size: 14px">
                          上传营业执照
                        </p>
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
                <div style="border-top: 1px dashed #89909d">
                  <p
                    style="
                      text-align: left;
                      font-size: 16px;
                      margin: 10px 0 20px 0;
                      color: #89909d;
                      font-weight: 600;
                      margin-left: 5px;
                    "
                  >
                    企业开票信息
                  </p>

                  <!-- 纳税人识别号 -->
                  <el-form-item
                    label="纳税人识别号"
                    prop="taxpayer_number"
                    style="font-weight: 600px"
                  >
                    <el-input
                      v-model="ruleForm.taxpayer_number"
                      placeholder="请输入纳税人识别号"
                      :disabled="disabled"
                    ></el-input>
                  </el-form-item>

                  <!-- 邮寄地址 -->
                  <el-form-item label="邮寄地址" prop="register_address">
                    <el-input
                      v-model="ruleForm.register_address"
                      placeholder="请输入邮寄地址"
                      :disabled="disabled"
                    ></el-input>
                  </el-form-item>

                  <!-- 企业电话 -->
                  <el-form-item label="企业电话" prop="business_phone">
                    <el-input
                      v-model="ruleForm.business_phone"
                      placeholder="请输入企业电话"
                      :disabled="disabled"
                    ></el-input>
                  </el-form-item>

                  <!-- 电子邮箱 -->
                  <el-form-item label="电子邮箱" prop="email">
                    <el-input
                      v-model="ruleForm.email"
                      placeholder="请输入电子邮箱"
                      :disabled="disabled"
                    ></el-input>
                  </el-form-item>
                </div>

                <!-- 提交 -->
                <el-form-item>
                  <el-button
                    type="primary"
                    style="
                      margin-left: 20px;
                      display: inline-block;
                      background-color: #1677ff;
                      width: 114px;
                      border-radius: 0;
                      height: 34px;
                      line-height: 4px;
                    "
                    @click="isSubmitForm('ruleForm')"
                    :disabled="disabled"
                    >{{ btnText }}</el-button
                  >
                  <div class="agreement">
                    <el-checkbox v-model="checked"></el-checkbox
                    ><span @click="ClickAgreement"
                      >请勾选同意《企业用户认证协议》</span
                    >
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <el-dialog
        :title="agreementInfo.title"
        :visible.sync="agreementVisible"
        width="50%"
        top="20vh"
      >
        <span v-if="!loading" v-html="agreementInfo.content">{{
          agreementInfo.content
        }}</span>
        <div
          v-if="loading"
          v-loading="loading"
          style="width: 100%; height: 100%"
        ></div
      ></el-dialog>
    </div>

    <!-- 移动端 -->
    <div id="mobile" v-if="!show_pc">
      <div
        class="release_box"
        v-if="isRouterAlive"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
        <Header></Header>
        <div style="width: 100%; height: 90px"></div>
        <div
          class="release_bg"
          style="
            backgroundimage: url(http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201218/201345_452966.png);
            margin-bottom: 30px;
          "
        >
          <div style="width: 100%; height: 30px"></div>
          <div style="padding: 10px">
            <div class="release_rigth" style="background-color: #fff">
              <div
                style="margin-bottom: 20px; color: #f56c6c; cursor: pointer"
                @click="goApprove"
              >
                {{ content }}
              </div>
              <div style="margin-bottom: 20px; color: #f56c6c; cursor: pointer">
                {{ content1 }}
              </div>
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="105px"
              >
                <!-- 企业LOGO -->
                <el-form-item label="企业logo" prop="LOGO">
                  <div>
                    <el-upload
                      :action="uploadUrl"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :disabled="disabled"
                      :on-success="logoSuccess"
                      :show-file-list="false"
                      :on-remove="handleRemove"
                    >
                      <img
                        :src="ruleForm.LOGOs"
                        v-if="ruleForm.LOGOs"
                        style="width: 108px; height: 108px"
                        alt=""
                      />
                      <div class="uploadClass" v-if="!ruleForm.LOGOs">
                        <i
                          class="el-icon-plus"
                          style="
                            font-size: 36px;
                            margin-top: 8px;
                            color: #d8d8d8;
                          "
                        ></i>
                        <p style="margin-top: 4px; font-size: 14px">上传logo</p>
                        <p>(尺寸:120*120px)</p>
                      </div>
                    </el-upload>
                    <!-- 大logo -->
                    <div class="bigLogo">
                      <el-upload
                        :action="uploadUrl"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :disabled="disabled"
                        :on-success="fengmainSuccess"
                        :show-file-list="false"
                        :on-remove="handleRemove"
                      >
                        <img
                          :src="ruleForm.cover"
                          v-if="ruleForm.cover"
                          style="width: 100%; height: 108px"
                          alt=""
                        />
                        <div class="uploadClass" v-if="!ruleForm.cover">
                          <i
                            class="el-icon-plus"
                            style="
                              font-size: 36px;
                              margin-top: 8px;
                              color: #d8d8d8;
                            "
                          ></i>
                          <p style="margin-top: 4px; font-size: 14px">
                            上传logo
                          </p>
                          <p>(尺寸:280*196px)</p>
                        </div>
                      </el-upload>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="规模人数" prop="scale">
                  <!-- 规模人数 -->
                  <el-select
                    v-model="ruleForm.scale"
                    placeholder="请选择规模人数"
                    style="width: 100%; margin-top: 8px"
                    :disabled="disabled"
                  >
                    <el-option
                      v-for="item in scaleList"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <!-- 企业类型 -->
                <el-form-item label="企业类型" prop="genre">
                  <el-select
                    v-model="ruleForm.genre"
                    placeholder="请选择企业类型"
                    style="width: 100%; margin-top: 8px"
                    :disabled="disabled"
                  >
                    <el-option
                      v-for="(item, index) in genreList"
                      :key="index"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <!-- 企业网址 -->
                <el-form-item label="企业网址">
                  <el-input
                    v-model="ruleForm.url"
                    placeholder="请输入企业网址"
                    :disabled="disabled"
                    style="margin-top: 8px"
                  ></el-input>
                </el-form-item>

                <!-- 是否上市 -->
                <el-form-item
                  label="是否上市"
                  prop="resource"
                  style="position: relative"
                >
                  <el-radio-group
                    v-model="ruleForm.resource"
                    :disabled="disabled"
                  >
                    <el-radio label="未上市"></el-radio>
                    <el-radio label="已上市"></el-radio>
                    <span style="line-height: 50px"></span>
                  </el-radio-group>
                </el-form-item>

                <!-- 企业名称 -->
                <el-form-item label="企业名称" prop="companyName">
                  <el-input
                    v-model="ruleForm.companyName"
                    placeholder="请输入企业名称"
                    :disabled="disabled"
                  ></el-input>
                </el-form-item>

                <!-- 姓名 -->
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入姓名"
                    :disabled="disabled"
                    style="margin-top: 8px"
                  ></el-input>
                </el-form-item>

                <!-- 联系方式 -->
                <el-form-item label="联系方式" prop="contactWay">
                  <el-input
                    v-model="ruleForm.phone"
                    placeholder="请输入手机号"
                    :disabled="disabled"
                    style="margin-top: 8px"
                  ></el-input>
                  <span style="margin: 0 8px"></span>
                  <el-input
                    v-model="ruleForm.wx"
                    placeholder="请输入微信号"
                    :disabled="disabled"
                    style="margin-top: 8px"
                  ></el-input>
                </el-form-item>

                <!-- 营业执照 -->
                <el-form-item label="营业执照" prop="license">
                  <div class="license" style="margin-top: 8px">
                    <el-upload
                      :action="uploadUrl"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :disabled="disabled"
                      :show-file-list="false"
                      :on-change="licenseChange"
                      :on-success="licenseImg"
                      :on-remove="licenseRemove"
                    >
                      <img
                        :src="ruleForm.license"
                        v-if="ruleForm.license"
                        style="width: 100%; height: 108px"
                        alt=""
                      />
                      <div class="uploadClass" v-if="!ruleForm.license">
                        <i
                          class="el-icon-plus"
                          style="
                            font-size: 36px;
                            margin-top: 8px;
                            color: #d8d8d8;
                          "
                        ></i>
                        <p style="margin-top: 14px; font-size: 14px">
                          上传营业执照
                        </p>
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>

                <div style="border-top: 1px dashed #89909d">
                  <p
                    style="
                      text-align: left;
                      font-size: 16px;
                      margin: 10px 0 20px 0;
                      color: #89909d;
                      font-weight: 600;
                      margin-left: 5px;
                    "
                  >
                    企业开票信息
                  </p>

                  <!-- 纳税人识别号 -->
                  <el-form-item
                    label="纳税人识别号"
                    prop="taxpayer_number"
                    style="font-weight: 600px"
                  >
                    <el-input
                      v-model="ruleForm.taxpayer_number"
                      placeholder="请输入纳税人识别号"
                      :disabled="disabled"
                    ></el-input>
                  </el-form-item>

                  <!-- 邮寄地址 -->
                  <el-form-item label="邮寄地址" prop="register_address">
                    <el-input
                      v-model="ruleForm.register_address"
                      placeholder="请输入邮寄地址"
                      :disabled="disabled"
                    ></el-input>
                  </el-form-item>

                  <!-- 企业电话 -->
                  <el-form-item label="企业电话" prop="business_phone">
                    <el-input
                      v-model="ruleForm.business_phone"
                      placeholder="请输入企业电话"
                      :disabled="disabled"
                    ></el-input>
                  </el-form-item>

                  <!-- 电子邮箱 -->
                  <el-form-item label="电子邮箱" prop="email">
                    <el-input
                      v-model="ruleForm.email"
                      placeholder="请输入电子邮箱"
                      :disabled="disabled"
                    ></el-input>
                  </el-form-item>
                </div>

                <!-- 提交 -->
                <el-form-item>
                  <el-button
                    type="primary"
                    class="btn"
                    @click="isSubmitForm('ruleForm')"
                    :disabled="disabled"
                    >{{ btnText }}</el-button
                  >
                </el-form-item>
              </el-form>
              <div class="agreement">
                <el-checkbox v-model="checked"></el-checkbox
                ><span @click="ClickAgreement"
                  >请勾选同意《企业用户认证协议》</span
                >
              </div>
              <div style="width: 100%; height: 35px"></div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        :title="agreementInfo.title"
        :visible.sync="agreementVisible"
        width="50%"
        top="5vh"
      >
        <span v-if="!loading" v-html="agreementInfo.content">{{
          agreementInfo.content
        }}</span>
        <div
          v-if="loading"
          v-loading="loading"
          style="width: 100%; height: 100%"
        ></div
      ></el-dialog>
    </div>
  </div>
</template>
<script>
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    var linkman = (rule, value, callback) => {
      let form = this.ruleForm;
      if (form.phone == "") {
        callback(new Error("请输入联系电话"));
        return;
      }
      if (form.phone.length > 11 || form.phone.length < 11) {
        callback(new Error("请输入正确的联系电话"));
        return;
      }
      // if (form.wx == "") {
      //   callback(new Error("请输入联系微信"));
      //   return;
      // }

      callback();
    };
    return {
      checked: "",
      agreementInfo: "",
      agreementVisible: false,
      loading: false,
      disabled: false,
      scaleList: ["0-50人", "50-100人", "100-200人", "200人以上"],
      genreList: [],
      // 背景图片
      mgUrl: require("../../../assets/image/bg.jpg"),

      chargeList: ["积分", "余额"], // 支付方式
      //   上传图片路径
      dialogImageUrl: "",
      //   是否显示弹出框
      dialogVisible: false,

      ruleForm: {
        scale: "",
        genre: "",
        url: "",
        resource: "未上市",
        companyName: "",
        name: "",
        contactWay: "",
        license: "",
        LOGOs: "",
        cover: "",
        phone: "",
        wx: "",
        taxpayer_number: "",
        register_address: "",
        business_phone: "",
        email: "",
      },
      rules: {
        scale: [
          {
            required: true,
            message: "请选择规模人数",
            trigger: "change",
          },
        ],
        genre: [
          {
            required: true,
            message: "请选择企业类型",
            trigger: "change",
          },
        ],
        // url: [
        //   {
        //     required: true,
        //     message: "请输入企业网址",
        //     trigger: "blur",
        //   },
        // ],
        resource: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],

        companyName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        contactWay: [
          {
            required: true,
            validator: linkman,
            trigger: "blur",
          },
        ],
        LOGO: [
          {
            required: true,
            message: "请上传图片",
          },
        ],
        LOGOs: [
          {
            required: true,
            message: "请上传LOGO",
          },
        ],
        license: [
          {
            required: true,
            message: "请上传营业执照",
            trigger: "change",
          },
        ],

        taxpayer_number: [
          {
            required: true,
            message: "请输入纳税人识别号",
            trigger: "blur",
          },
        ],
        register_address: [
          {
            required: true,
            message: "请输入邮寄地址",
            trigger: "blur",
          },
        ],
        business_phone: [
          {
            required: true,
            message: "请输入企业电话 ",
            // trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入电子邮箱",
            trigger: "blur",
          },
        ],
      },
      user: "",
      classifyList: [],
      // 默认第一个发布开 和 显示
      i: 0,
      content: "",
      btnText: "提交",
      content1: "",
      isRouterAlive: true,
      fullscreenLoading: false,
      show_pc: true,
    };
  },
  async created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.fullscreenLoading = true;
    //获取行业列表
    let companyType = await this.$http
      .post("/index.php/api/v1_0_0.report/get_business_type", {})
      .catch((error) => {});

    this.genreList = companyType.data.list;

    //获取用户信息
    let user = await this.$http
      .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
      .catch((error) => {});
    console.log(user.data.is_real);
    if (user.data.is_real == 0) {
      this.$confirm(
        "您还没有实名认证, 申请企业认证需要实名认证?",
        "实名认证提示",
        {
          confirmButtonText: "去认证",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$router.push({
            path: "/personal/user",
            query: {
              tabs: 1,
            },
          });
        })
        .catch(() => {});

      this.content = "您还没有实名认证，去认证 >";
    }
    if (user.data.is_real == 2) {
      this.content = "您的实名认证信息还在审核中";
    }
    if (user.data.is_real == 3) {
      this.content = "您的实名认证被驳回，去重新提交认证 >";
    }
    //企业申请信息
    this.applyMsg();
  },
  computed: {
    ...mapState(["uploadUrl"]),
  },
  methods: {
    ClickAgreement() {
      this.agreementVisible = true;
      this.loading = true;
      this.$http
        .post("index.php/api/v1_0_0.ordinary/get_user_business", {})
        .then(({ data }) => {
          this.agreementInfo = data;
          this.loading = false;
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goApprove() {
      this.$router.push({
        path: "/personal/user",
        query: {
          tabs: 1,
        },
      });
    },
    logoSuccess(response, file, fileList) {
      console.log(response);
      this.ruleForm.LOGOs = response.data.file_url;
      if (this.ruleForm.cover != "") {
        this.$refs.ruleForm.clearValidate("LOGO");
        this.rules.LOGO = "";
      }
    },
    fengmainSuccess(response, file, fileList) {
      this.ruleForm.cover = response.data.file_url;
      if (this.ruleForm.LOGOs != "") {
        this.$refs.ruleForm.clearValidate("LOGO");
        this.rules.LOGO = "";
      }
    },
    licenseImg(response) {
      this.ruleForm.license = response.data.file_url;
      this.$refs.ruleForm.clearValidate("license");
      this.rules.license = "";
    },
    async isSubmitForm(formName) {
      console.log(this.ruleForm);
      let isValidate = await this.$refs[formName]
        .validate()
        .catch((error) => {});
      if (isValidate) {
        if (this.btnText == "修改") {
          this.$confirm("修改信息需要重新审核，确定要修改吗？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((res) => {
              this.submitForm(formName);
            })
            .catch((res) => {
              this.$message({
                type: "info",
                message: "已取消修改",
              });
            });
        } else {
          this.submitForm(formName);
        }
      }
    },
    submitForm(formName) {
      if (!this.checked) {
        this.$message.error("请勾选企业用户认证协议");
        return;
      }
      this.$confirm(
        "请联系工作人员进行线下账户打款，打款后可使用企业账号",
        "提示",
        {
          confirmButtonText: "知道了",
          cancelButtonText: "取消",
          showCancelButton: false,
          type: "warning",
        }
      )
        .then(async () => {
          this.fullscreenLoading = true;
          let submit = await this.$http
            .post("/index.php/api/v1_0_0.consumer/business_auth", {
              auth_data: {
                logo: this.ruleForm.LOGOs, //企业logo
                cover: this.ruleForm.cover, //企业封面图
                scale: this.ruleForm.scale, //规模
                type: this.ruleForm.genre, //企业类型
                web_url: this.ruleForm.url, //企业网址
                name: this.ruleForm.companyName, //企业名称
                lx_name: this.ruleForm.name, //联系人姓名
                lx_weixin: this.ruleForm.wx, //联系人微信
                lx_phone: this.ruleForm.phone, //联系人手机号
                license: this.ruleForm.license, //营业执照
                is_market: this.ruleForm.resource == "是" ? 1 : 2, //是否上市 1是 2否
                taxpayer_number: this.ruleForm.taxpayer_number, //纳税人识别号
                register_address: this.ruleForm.register_address, //邮寄地址
                business_phone: this.ruleForm.business_phone, //企业电话
                email: this.ruleForm.email, //电子邮箱
              },
            })
            .then((data) => {
              this.fullscreenLoading = false;
              this.$message.success("提交成功");

              console.log(data);
            })
            .catch((error) => {
              this.fullscreenLoading = false;

              console.log(error);
            });
        })
        .catch(() => {});

      // if (submit.data.balance == 0) {
      //   //没有设置支付密码
      //   this.$confirm(submit.msg, "提示", {
      //     confirmButtonText: "设置",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   })
      //     .then(() => {
      //       this.$router.push({
      //         path: "/personal/financial",
      //         query: {
      //           tabs: "支付密码设置",
      //         },
      //       });
      //     })
      //     .catch(() => {});
      // }
      // if (submit.data.balance == 2) {
      //   //余额够
      //   let balance = await this.$prompt("请输入密码", submit.msg, {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     inputPlaceholder: "请输入支付密码",
      //     inputType: "password",
      //     inputValidator: function (v) {
      //       if (!v) return "请输入支付密码";
      //     },
      //   }).catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消支付",
      //     });
      //   });
      //   console.log(balance, "balance");
      //   if (typeof balance == "undefined") return;
      //   let pay = await this.$http
      //     .post("/index.php/api/v1_0_0.consumer/do_pay", {
      //       password: balance.value,
      //     })
      //     .catch((error) => {});
      //   console.log(pay);

      //   if (pay.data.need_password) {
      //     //没有设置支付密码
      //     this.$confirm(pay.msg, "提示", {
      //       confirmButtonText: "设置",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     })
      //       .then(() => {
      //         this.$router.push({
      //           path: "/personal/financial",
      //           query: {
      //             tabs: "支付密码设置",
      //           },
      //         });
      //       })
      //       .catch(() => {});
      //   } else {
      //     this.$message.success(pay.msg);
      //     this.applyMsg();
      //   }
      // }

      // if (submit.data.balance == 1) {
      //   this.$confirm("余额不足，请先充值", "提示", {
      //     confirmButtonText: "充值",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   })
      //     .then(() => {
      //       this.$router.push({
      //         path: "/personal/financial",
      //         query: {
      //           tabs: "我的余额",
      //         },
      //       });
      //     })
      //     .catch(() => {});
      // }
      // if (submit.data.balance == 3) {
      //   this.$message.success(submit.msg);
      //   this.applyMsg();
      // }
      // this.$message.success(data.msg);
    },
    applyMsg() {
      //申请信息
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_business_info", {})
        .then((data) => {
          this.fullscreenLoading = false;
          console.log(data, "申请信息");
          console.log(Object.keys(data.data).length);
          if (Object.keys(data.data).length === 1) {
            return;
          }
          if (!data.data.is_real) {
            return false;
          }
          let ruleForm = this.ruleForm;
          ruleForm.resource = data.data.is_market == 2 ? "未上市" : "已上市";
          ruleForm.phone = data.data.lx_phone;
          ruleForm.name = data.data.lx_name;
          ruleForm.companyName = data.data.name;
          ruleForm.wx = data.data.lx_weixin;
          ruleForm.genre = data.data.type;
          ruleForm.scale = data.data.scale;
          ruleForm.url = data.data.web_url;
          ruleForm.LOGOs = data.data.logo;
          ruleForm.cover = data.data.cover;
          ruleForm.license = data.data.license;
          ruleForm.taxpayer_number = data.data.taxpayer_number;
          ruleForm.register_address = data.data.register_address;
          ruleForm.business_phone = data.data.business_phone;
          ruleForm.email = data.data.email;

          this.$refs.ruleForm.clearValidate("LOGO");
          this.rules.LOGO = "";
          if (data.data.is_pay == 1 || data.data.is_pay == 3) {
            return false;
          }

          if (data.data.is_business == 0) {
            this.btnText = "提交";
            this.content1 = "";
            return;
          }

          if (data.data.is_business == 1) {
            this.btnText = "修改";
            // this.disabled = true
            this.content1 = "";
          }
          if (data.data.is_business == 2) {
            this.btnText = "审核中";
            this.content1 = "";
            this.disabled = true;
          }
          if (data.data.is_business == 3) {
            this.$confirm(
              "您的企业认证未通过: " + data.data.remark,
              "企业认证审核驳回",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {})
              .catch(() => {});
            this.content1 = data.data.remark + "，请重新填写资料提交认证";
            this.btnText = "重新提交";
          }
        })
        .catch((error) => {});
    },
    //LOGO验证
    licenseChange(file, fileList) {
      this.$refs.ruleForm.rules.LOGO = [];
    },
    licenseRemove(file, fileList) {},
    handlePictureCardPreview() {},
    handleRemove() {},
  },
  mounted() {},
};
</script>
<style lang='less' scoped>
/deep/.el-dialog__title {
  font-weight: 600;
}
#mobile {
  /deep/.el-dialog__body {
    height: 80vh;
    overflow: auto;
  }
  .agreement {
    // margin: 27px 0 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    margin-left: 15px;
    span {
      margin-left: 5px;
      cursor: pointer;
      color: #1677ff;
      font-size: 12px;
    }
  }
  /deep/.el-form-item__label {
    padding-right: 10px;
  }
  .release_bg {
    width: 100%;
    height: 180px;

    background: #f1f5f6;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    /* padding: 120px 0 50px; */
  }

  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    /* line-height: 44px; */
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  /deep/.el-radio-group {
    height: 20px;
  }

  .license {
    /deep/ .el-upload--picture-card {
      width: 148px;
      height: 98px;
      background: #fafafa;
      border: 1px solid #d9dce2;
    }
  }

  .bigLogo {
    margin-top: 20px;

    /deep/ .el-upload--picture-card {
      width: 140px;
      height: 98px;

      background: #fafafa;
      border: 1px solid #d9dce2;
    }
  }

  /deep/ .el-upload--picture-card {
    width: 98px;
    height: 98px;

    background: #fafafa;
    border: 1px solid #d9dce2;
  }

  .uploadClass {
    color: #999da4;
    line-height: 17px;
    font-size: 12px;

    p {
      line-height: 20px;
    }
  }

  .release_box {
    .release_bg {
      /* width: 100%;
        height: auto;
        background: #F1F5F6;
        background-size: 100% 270px;
        background-repeat: no-repeat;
        padding: 120px 0 50px; */

      .release {
        margin: auto 17%;
        background-color: #fff;
        display: flex;
        justify-content: start;
        padding: 50px 54px 0 32px;

        .release_left {
          width: 26.73%;
          padding: 0 32px 0 0;
          margin-top: 14px;

          p:nth-child(1) {
            color: #1677ff;
            margin-bottom: 12px;
          }

          p:nth-child(2) {
            font-size: 10px;

            color: #878e9b;
            line-height: 17px;
          }
        }
      }

      .release_rigth {
        /* width: 73.18%; */
        border: 1px solid #e5e6ea;
        /* margin-top: 13px; */
        padding: 10px;
      }
    }
  }
}
.pc {
  /deep/.el-dialog__body {
    height: 500px;
    overflow: auto;
  }
  // 协议弹框
  .agreement {
    // margin: 27px 0 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    margin-left: 15px;
    span {
      margin-left: 5px;
      cursor: pointer;
      color: #1677ff;
    }
  }
  /deep/.el-radio-group {
    height: 20px;
  }

  .license {
    /deep/ .el-upload--picture-card {
      width: 158px;
      height: 108px;
      background: #fafafa;
      border: 1px solid #d9dce2;
    }
  }

  .bigLogo {
    margin-left: 20px;

    /deep/ .el-upload--picture-card {
      width: 158px;
      height: 108px;

      background: #fafafa;
      border: 1px solid #d9dce2;
    }
  }

  /deep/ .el-upload--picture-card {
    width: 108px;
    height: 108px;

    background: #fafafa;
    border: 1px solid #d9dce2;
  }

  .uploadClass {
    color: #999da4;
    line-height: 17px;
    font-size: 12px;

    p {
      line-height: 20px;
    }
  }

  .release_box {
    .release_bg {
      width: 100%;
      height: auto;
      background: #f1f5f6;
      background-size: 100% 270px;
      background-repeat: no-repeat;
      padding: 120px 0 50px;

      .release {
        margin: auto 17%;
        background-color: #fff;
        display: flex;
        justify-content: start;
        padding: 50px 54px 0 32px;

        .release_left {
          width: 26.73%;
          padding: 0 32px 0 0;
          margin-top: 14px;

          p:nth-child(1) {
            color: #1677ff;
            margin-bottom: 12px;
          }

          p:nth-child(2) {
            font-size: 10px;

            color: #878e9b;
            line-height: 17px;
          }
        }
      }

      .release_rigth {
        width: 73.18%;
        border-left: 1px solid #e5e6ea;
        margin-top: 13px;
        padding: 0 20px 30px;
      }
    }
  }
}
</style>
