<template>
  <div>
    <Header :indexi="7"></Header>

    <!-- 精选企业 -->
    <!-- PC端 -->
    <div v-if="show_pc" class="pc">
      <div class="enterprises_box">
        <!-- 图片 -->
        <div
          class="image"
          :style="{
            backgroundImage: 'url(' + headerImg + ')',
          }"
        >
<!--          <p>精选企业</p>-->
<!--          <p>Selected enterprises</p>-->
          <!-- <img src="" alt="" /> -->
        </div>
        <!-- 选项 -->
        <div class="activity_search">
          <div class="top">
            <input v-model="keyword" placeholder="搜索企业" />
            <button @click="ClickSearch">搜索</button>
          </div>
        </div>
        <!-- 地址 行业 年薪模块 -->
        <div class="screen_box">
          <ul>
            <li v-for="(item, index) in optionList" :key="index">
              <div>{{ item.name }}</div>
              <div>
                <span
                  :class="{
                    color:
                      item.fields == 'province'
                        ? province == item2.value
                          ? true
                          : false
                        : item.fields == 'cid'
                        ? cid == item2.value
                          ? true
                          : false
                        : false,
                  }"
                  v-for="(item2, index) in item.list"
                  :key="index"
                  @click="ClickFormatList(item.fields, item2.value)"
                  >{{ item2.title }}</span
                >
              </div>
            </li>
          </ul>
          <p @click="ClickClear">
            <i class="el-icon-delete"></i> 清空所有筛选条件
          </p>
        </div>
        <!-- 精选企业内容 -->
        <div class="enterprises" v-loading="fullscreenLoading">
          <p style="margin-bottom: 20px" v-if="enterpriseList.length <= 0">
            没有更多了
          </p>
          <ul v-if="enterpriseList.length > 0">
            <li
              v-for="(item, index) in enterpriseList"
              :key="item.id"
              @click="ClickEnterprisesList(item.business_id)"
            >
              <div class="Company">
                <div class="img"><img :src="item.cover" alt="" /></div>
                <div class="Companytext">
                  <p>{{ item.business_name }}</p>
                  <p v-html="item.description">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <!-- 分页 -->
        <!-- 底部分页 -->
        <div class="page" v-if="total > 16">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.pagenum"
            :page-size="10"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="jxqy_md">
        <div class="enterprises_box">
          <!-- 轮播图 -->
          <div class="banner">
            <wc-swiper>
              <wc-slide v-for="(item, key) in enterpriseList" :key="key">
                <div
                  style="height: 188px"
                  @click="ClickBanner(item.target_url)"
                >
                  <img
                    style="display: inline-block; height: 188px; width: 100%"
                    :src="item.cover"
                    alt=""
                  />
                </div>
              </wc-slide>
            </wc-swiper>
          </div>
          <!-- 搜索 -->
          <div class="top">
            <input v-model="keyword" placeholder="搜索企业" />
            <button @click="ClickSearch">搜索</button>
          </div>
          <!-- 选项 -->
          <!-- 行业 -->
          <div class="screen_box">
            <ul>
              <li v-for="(item, index1) in optionList" :key="index1">
                <div>{{ item.name }}</div>
                <div>
                  <span
                    :class="{
                      color:
                        item.fields == 'province'
                          ? province == item2.value
                            ? true
                            : false
                          : item.fields == 'cid'
                          ? cid == item2.value
                            ? true
                            : false
                          : false,
                    }"
                    v-for="(item2, index2) in item.list"
                    :key="index2"
                    v-if="index2 <= cityMore"
                    @click="ClickFormatList(item.fields, item2.value)"
                    >{{ item2.title }}</span
                  >
                  <span v-if="item.fields == 'province' && item.list.length > 9"
                    ><span
                      style="color: #1677ff; border-left: 1px solid #d8d8d8"
                      @click="ClickCityMore"
                      v-if="cityMore > 9"
                      >收起</span
                    >
                    <span
                      style="color: #1677ff; border-left: 1px solid #d8d8d8"
                      @click="ClickCityMore"
                      v-if="cityMore == 8"
                      >更多</span
                    >
                  </span>
                </div>
              </li>
            </ul>
            <p @click="ClickClear">
              <i class="el-icon-delete"></i> 清空所有筛选条件
            </p>
          </div>
          <!-- 精选企业内容 -->
          <div class="enterprises">
            <ul>
              <li
                v-for="(item, index) in enterpriseList"
                :key="item.id"
                @click="ClickEnterprisesList(item.business_id)"
              >
                <div class="Company">
                  <div class="img"><img :src="item.cover" alt="" /></div>
                  <div class="Companytext">
                    <p>{{ item.business_name }}</p>
                    <p>
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            class="more"
            v-if="enterpriseList.length !== total"
            @click="ClickMore"
          >
            加载更多
          </div>
          <!-- <div
            class="more"
            @click="ClickPackUpMore"
            v-if="enterpriseList.length > 10"
          >
            收起更多
          </div> -->

          <div class="more" v-if="enterpriseList.length == total">
            没有更多了...
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";
import { log } from "util";
export default {
  components: {
    Header,
    Footer,
  },
  metaInfo: {
    title: "新税网 - 精选企业",
    meta: [
      // {
      //   name: "description",
      //   content: "这是精选企业页面",
      // },
      {
        name: "keywords",
        content: "新税网-精选企业",
      },
    ],
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 头部背景图片
      headerImg: "",
      // 省份
      province: "",
      // 分类
      cid: "",
      // 输入框内容
      keyword: "",

      // 精选企业列表
      enterpriseList: [],

      // 查询文章列表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 16,
      },
      // 总条数
      total: 0,

      // 企业类型列表
      optionList: {},

      fullscreenLoading: false,

      // 移动端控制城市显示个数
      cityMore: 8,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 筛选列表
    this.getClassList();
    // 企业精选列表
    this.gteEnterpriseList();
  },
  methods: {
    playAudioHandler() {
      this.isPlaying = !this.isPlaying;
      const player = this.$refs.audioPlayer;
      if (this.isPlaying) {
        player.load();
        player.play();
      } else {
        player.pause();
      }
    },
    // 广告图页面跳转
    ClickBanner(url) {
      if (url) {
        // _blank打开新页面
        window.open(url, "_blank");
      }
    },
    // 筛选列表
    getClassList() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.recruit/get_screen";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.optionList = data;
      }
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_screen", {})
        .then((res) => {
          console.log("企业城市");
          this.optionList = res.data;
          this.$storage.set(storageKey, res.data);
          console.log(res);
        })
        .catch((error) => {});
    },

    // 点击搜索
    ClickSearch() {
      if (!this.keyword) {
        this.$message.error("请输入内容");
        return false;
      }

      this.gteEnterpriseList();
    },
    // 点击筛选条件
    ClickFormatList(fields, value) {
      if (fields == "province") {
        this.province = value;
      }
      if (fields == "cid") {
        this.cid = value;
      }
      this.gteEnterpriseList();
      console.log(this.cid, this.province);
    },

    // 重置按钮
    ClickClear() {
      this.cid = 0;
      this.province = "";
      this.keyword = "";
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = 9;
      this.gteEnterpriseList();
    },

    // 企业列表
    gteEnterpriseList() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.recruit/get_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.enterpriseList = data.list;
        this.total = data.total_count;
        this.headerImg = data.back_url;
      }

      this.fullscreenLoading = true;
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.show_pc ? 16 : 3,
          hot: 0,
          province: this.province,
          cid: this.cid,
          keyword: this.keyword,
        })
        .then((res) => {
          this.enterpriseList = res.data.list;
          this.total = res.data.total_count;
          this.headerImg = res.data.back_url;

          this.$storage.set(storageKey, res.data);
          console.log(res);
        })
        .catch((error) => {})
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },
    // 点击企业列表的每一项
    ClickEnterprisesList(id) {
      this.$router.push({ path: "/jxqy/recruit", query: { id: id } });
      console.log(id);
    },

    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.gteEnterpriseList();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.gteEnterpriseList();
    },

    // 移动端
    // 搜索
    toSearch() {},
    // 点击更多
    ClickMore() {
      this.queryInfo.pagenum++;
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_list", {
          page: this.queryInfo.pagenum,
          page_size: 3,
          hot: 0,
          province: this.province,
          cid: this.cid,
          keyword: this.keyword,
        })
        .then((res) => {
          console.log(res);
          res.data.list.forEach((item) => {
            this.enterpriseList.push(item);
          });
          this.total = res.data.total_count;
          this.headerImg = res.data.back_url;
        })
        .catch((error) => {});
    },

    // 点击查看更多省份
    ClickCityMore() {
      if (this.cityMore == 8) {
        this.cityMore = this.optionList[0].list.length;
        return;
      }
      this.cityMore = 8;
    },
    // 点击收起
    ClickPackUpMore() {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = 10;
      this.gteEnterpriseList();
    },
  },
};
</script>
<style lang='less' scoped>
img {
  object-fit: contain;
}
.jxqy_md {
  width: 100%;
  padding-top: 60px;
  background-color: #fff;
  .enterprises_box {
    /*width: "";*/
    .banner {
      /deep/.wc-pagination {
        bottom: 0;
        // justify-content: flex-end;

        padding-right: 8px;
        box-sizing: border-box;
      }
      /deep/.wc-dot {
        width: 5px;
        height: 5px;
        border-radius: 5px;
        margin: 0 2px;

        background: #ffffff;
        opacity: 0.5;
      }
      /deep/ .wc-dot-active {
        background-color: #fff;
        width: 10px;
        opacity: 1;
      }
    }
    .top {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;

      input {
        width: 255px;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #f0f0f0;
        padding: 10px;
        box-sizing: border-box;
        border: 0;
        outline: none;
      }
      button {
        width: 80px;
        height: 40px;
        background: #1677ff;
        border-radius: 0px 4px 4px 0px;
        border: 0;
        outline: none;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .screen_box {
      background-color: #fff;
      padding: 20px 10px 0px;
      ul {
        padding: 0;
        li {
          display: flex;
          justify-self: start;
          div:nth-child(1) {
            white-space: nowrap;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #424b60;
            padding-right: 10px;
          }
          div:nth-child(2) {
            height: auto;
            display: flex;
            justify-self: start;
            flex-wrap: wrap;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #787e8f;
            .color {
              color: #1677ff !important;
            }
            span {
              padding: 0 10px;
              margin-bottom: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #787e8f;
              cursor: pointer;
            }
          }
        }
      }
      p {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        color: #c5cacf;
        line-height: 17px;
        cursor: pointer;
      }
    }
    .enterprises {
      height: auto;
      margin: 0;
      background-color: #fff;
      ul {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;

        li {
          // margin: 0 47px;
          padding: 0px;
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 20px;

          cursor: pointer;
          .Company {
            width: 281px;
            /*height: 315px;*/
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #f0f0f0;
            box-sizing: border-box;
            .img {
              display: inline-block;
              width: 280px;
              /*height: 195px;*/
              img {
                display: inline-block;
                width: 100%;
                height: 100%;
                /*background-color: pink;*/
              }
            }
            .Companytext {
              /*height: 120px;*/
              box-sizing: border-box;
              padding: 20px 14px;
              p:nth-child(1) {
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 22px;
                margin: 0;
              }
              p:nth-child(2) {
                height: 48px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #717482;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
            }
          }
        }
      }
    }
    .more {
      width: 128px;
      height: 36px;
      line-height: 36px;
      margin: 20px auto;
      border: 2px solid #e5e6ea;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #555c69;
      background-color: #fff;
    }
  }
}
.pc {
  .enterprises_box {
    .image {
      width: 100%;
      height: 300px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p:nth-child(1) {
        font-size: 44px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 62px;
      }
      p:nth-child(2) {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
      }
    }
    // 筛选
    .activity_search {
      width: 930px;
      // padding: 0 19%;
      margin: 30px auto;
      .top {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        input {
          width: 100%;
          height: 52px;
          border: 0;
          outline: none;
          background: #ffffff;
          border-radius: 8px 0px 0px 8px;
          border: 1px solid #f0f0f0;
          font-size: 14px;
          padding-left: 30px;
          box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
        }
        button {
          width: 140px;
          height: 54px;
          background: #1677ff;
          border-radius: 0px 8px 8px 0px;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          border: 0;
          cursor: pointer;
        }
      }
    }
    .screen_box {
      width: 1200px;
      margin: 0 auto;
      // margin: 0 9%;

      background-color: #fff;
      padding: 15px 10px 10px;
      margin-bottom: 30px;
      // box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
      // border-radius: 8px;
      border-bottom: 1px solid #ece6e6;
      ul {
        li {
          display: flex;
          justify-self: start;
          div:nth-child(1) {
            width: 5%;
            line-height: 40px;
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            // background-color: #fff;
          }
          div:nth-child(2) {
            width: 95%;
            height: auto;
            line-height: 40px;
            // background-color: #fff;
            display: flex;
            justify-self: start;
            flex-wrap: wrap;
            padding-right: 60px;
            padding-bottom: 10px;
            .color {
              color: #1677ff !important;
            }
            span {
              padding: 0 13px;
              font-size: 14px;
              font-weight: 400;
              color: #787e8f;
              cursor: pointer;
            }
          }
        }
      }
      p {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        color: #c5cacf;
        line-height: 17px;
        padding: 0px 15px;
        margin-bottom: 15px;
        cursor: pointer;
      }
    }
    .option {
      margin: 0 10%;
      padding: 40px 0 40px 25px;
      border-bottom: 1px solid #ece6e6;
      // box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
      // border-radius: 5px;

      .industry {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 30px;
        div:nth-child(1) {
          margin-right: 15px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #424b60;
          line-height: 22px;
        }
        div:nth-child(2) {
          span {
            margin: 0 15px;

            font-size: 14px;
            font-weight: 400;
            color: #787e8f;
            line-height: 20px;
            cursor: pointer;
          }
        }
      }
      .city {
        text-align: left;
        span:nth-of-type(2) {
          color: #1677ff;
          font-size: 14px;
          font-weight: 400;
          color: #4e98ff;
          cursor: pointer;
        }
        span:nth-child(1) {
          margin-right: 30px;
          font-weight: 400;
          color: #000;
        }
        .el-cascader {
          margin: 0 8px;
        }
      }
      p {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        color: #c5cacf;
        line-height: 17px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
    .enterprises {
      width: 1200px;
      margin: 0 auto;
      height: auto;
      // margin: 0 10.45% 0 10%;
      ul {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 100%;
        li {
          padding: 0 10px;
          box-sizing: border-box;
          width: 25%;
          cursor: pointer;
          .Company {
            width: 100%;
            height: 314.89px;
            background-color: #fff;
            // margin-top: 20px;
            .img {
              // height: 62%;
              // width: 100%;
              height: 195px;
              width: 280px;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              img {
                // display: inline-block;
                // width: 100%;
                // height: 100%;
                // height: auto;
                max-width: 100%;
              }
            }
            .Companytext {
              padding: 20px 14px;
              p:nth-child(1) {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                line-height: 22px;
              }
              p:nth-child(2) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #717482;
                line-height: 24px;
                text-align: center;
                overflow: hidden;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
    .page {
      text-align: center;
      padding: 25px 0 50px 0;
    }
  }
}
</style>
