<template>
  <div>
    <div v-if="show_pc" class="pc">
      <div class="top">
        <ul>
          <li
            v-for="(item, index) in infosTitle"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickInfoList(item, index)"
          >
            <el-badge
              v-if="message_num > 0 && item.title == '消息通知'"
              :value="message_num"
              class="item"
            >
              {{ item.title }}</el-badge
            >

            <span v-if="!message_num > 0 && item.title == '消息通知'">{{
              item.title
            }}</span>

            <el-badge
              v-if="sys_num > 0 && item.title == '系统公告'"
              :value="sys_num"
              class="item"
            >
              {{ item.title }}</el-badge
            >
            <span v-if="!sys_num > 0 && item.title == '系统公告'">{{
              item.title
            }}</span>
            <span v-if=""> </span>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <div
          v-if="!total"
          style="
            color: #666;
            font-size: 14px;
            padding: 40px;
            width: 13%;
            margin: 0 auto;
          "
          v-loading="messgeLoading"
        >
          <img src="../../../assets/image/缺省图.png" alt="" />
          <p style="margin-top: 15px">暂无消息</p>
        </div>
        <ul v-if="total">
          <li
            v-for="(item, index) in infosList"
            :key="index"
            @click="ClickState(item)"
          >
            <!-- <el-badge is-dot> </el-badge> -->
            <span :class="{ color: item.is_read }"
              >【{{ item.is_read ? "已读" : "未读" }}】</span
            >
            <span
              style="
                display: inline-block;
                width: 72%;
                text-align: left;
                font-size: 14px;
                color: #666;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ item.content }}
            </span>
            <span
              style="
                display: inline-block;
                text-align: right;
                width: 19%;
                font-size: 12px;
              "
            >
              {{ item.create_time }}
            </span>
          </li>
        </ul>
        <!-- 底部分页 -->
        <div class="page">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.pagenum"
            :page-size="queryInfo.pagesize"
            layout="prev, pager, next"
            :total="total"
            :hide-on-single-page="true"
          >
          </el-pagination>
        </div>
      </div>

      <el-dialog
        :title="i ? '系统消息' : '消息通知'"
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
        v-loading="loading"
        :show-close="false"
      >
        <span v-html="dataInfo.content">{{ dataInfo.content }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >关闭</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div v-if="!show_pc" class="mobile">
      <Header></Header>
      <div style="width: 100%; height: 60px"></div>
      <div
        style="
          text-align: left;
          display: flex;
          align-items: center;
          line-height: 26px;
          padding-left: 15px;
          background-color: #fff;
          font-weight: 700;
        "
      >
        <p
          style="
            width: 3px;
            height: 18px;
            background-color: #1677ff;
            margin-right: 10px;
          "
        ></p>
        <p style="font-family: PingFangSC-Regular, PingFang SC; color: #292931">
          消息通知
        </p>
      </div>
      <div class="top">
        <ul>
          <li
            v-for="(item, index) in infosTitle"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickInfoList(item, index)"
          >
            <el-badge
              v-if="message_num > 0 && item.title == '消息通知'"
              :value="message_num"
              class="item"
            >
              {{ item.title }}</el-badge
            >

            <span v-if="!message_num > 0 && item.title == '消息通知'">{{
              item.title
            }}</span>

            <el-badge
              v-if="sys_num > 0 && item.title == '系统公告'"
              :value="sys_num"
              class="item"
            >
              {{ item.title }}</el-badge
            >
            <span v-if="!sys_num > 0 && item.title == '系统公告'">{{
              item.title
            }}</span>
            <span v-if=""> </span>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <div
          v-if="!total"
          style="color: #666; font-size: 14px; padding: 40px; width: 20%"
        >
          <img src="../../../assets/image/缺省图.png" alt="" />
          <p style="margin-top: 15px">暂无消息</p>
        </div>
        <LoadMore
          :onLoadMore="onLoadMore"
          :enableLoadMore="enableLoadMore"
          ref="LoadMorez"
        >
          <ul v-if="total" :class="{ height: infosList.length > 10 }">
            <li
              v-for="(item, index) in infosList"
              :key="index"
              @click="ClickState(item)"
            >
              <!-- <el-badge is-dot> </el-badge> -->
              <p
                style="display: flex; align-items: center; padding: 6px 0 2px 0"
              >
                <span style="width: 15%" :class="{ color: item.is_read }"
                  >【{{ item.is_read ? "已读" : "未读" }}】</span
                >
                <span
                  style="
                    display: inline-block;
                    width: 85%;
                    text-align: left;
                    font-size: 12px;
                    color: #666;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 13px;
                  "
                >
                  {{ item.content }}
                </span>
              </p>

              <p style="text-align: right; font-size: 12px">
                <span
                  style="width: 35%; color: #999; -webkit-transform: scale(0.8)"
                >
                  {{ item.create_time }}
                </span>
              </p>
            </li>
          </ul>
        </LoadMore>
      </div>
      <el-dialog
        :title="i ? '系统消息' : '消息通知'"
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
        :show-close="false"
      >
        <span v-html="dataInfo.content" v-loading="loading">{{
          dataInfo.content
        }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >关闭</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Header from "../../header.vue";
import LoadMore from "../../loadMore/loadMore.vue";
export default {
  components: {
    Header,
    LoadMore,
  },
  data() {
    return {
      show_pc: true,
      messgeLoading: false,
      loading: false,
      dialogVisible: false,
      queryInfo: {
        pagenum: 1,
        pagesize: 10,
      },
      total: 0,
      infosTitle: [{ title: "消息通知" }, { title: "系统公告" }],
      infosList: [],
      i: 0,
      dataInfo: {},
      message_num: 0,
      sys_num: 0,
      enableLoadMore: true,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.getInfoList();
  },
  methods: {
    getInfoList() {
      // 消息通知
      if (this.i == 0) {
        this.messgeLoading = true;

        this.$http
          .post("/index.php/api/v1_0_0.message/get_message_list", {
            page: this.queryInfo.pagenum,
            page_size: this.queryInfo.pagesize,
          })
          .then(({ data }) => {
            this.messgeLoading = false;

            this.total = data.total_count;

            if (!this.show_pc) {
              data.total_count == this.infosList.length
                ? (this.enableLoadMore = false)
                : (this.enableLoadMore = true);
              this.infosList = this.infosList.concat(data.list);
              console.log(this.infosList);
              return;
            }
            this.infosList = data.list;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // 系统消息
      if (this.i == 1) {
        console.log(this.infosList);
        this.messgeLoading = true;

        this.$http
          .post("/index.php/api/v1_0_0.message/get_sys_list", {
            page: this.queryInfo.pagenum,
            page_size: this.queryInfo.pagesize,
          })
          .then(({ data }) => {
            this.messgeLoading = false;

            this.total = data.total_count;
            if (!this.show_pc) {
              data.total_count == this.infosList.length
                ? (this.enableLoadMore = false)
                : (this.enableLoadMore = true);

              this.infosList = this.infosList.concat(data.list);
              console.log(this.infosList);
              return;
            }
            this.infosList = data.list;

            console.log(data.list);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.$http
        .post("/index.php/api/v1_0_0.message/get_message_count ", {})
        .then(({ data }) => {
          this.message_num = data.message_num;
          this.sys_num = data.sys_num;
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    },
    ClickInfoList(item, i) {
      this.i = i;
      this.queryInfo.pagenum = 1;
      this.getInfoList();
    },
    ClickState(item) {
      this.dialogVisible = true;
      this.dataInfo = {};
      this.loading = true;
      this.$http
        .post("/index.php/api/v1_0_0.message/get_detail", {
          id: item.id,
        })
        .then(({ data }) => {
          this.loading = false;

          this.dataInfo = data;
          console.log(data);
          this.getInfoList();
          // const arry = this.infosList.forEach((v) => {
          //   if (v.id == item.id) {
          //     v.is_read = item.is_read;
          //   }
          // });
          // this.infosList = arry;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.getInfoList();
    },
    //移动端上拉刷新
    onLoadMore(done, total = "") {
      console.log(done);
      if (!this.enableLoadMore) {
        return;
      }
      if (total == "") {
        this.queryInfo.pagenum++;
      }
      this.getInfoList();
    },
  },
};
</script>
<style lang='less' scoped>
.pc {
  .top {
    ul {
      display: flex;
      justify-content: start;
      padding: 20px 0 29px 5px;

      .color {
        color: #1677ff;
      }

      li {
        margin: 0 25px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 22px;
        cursor: pointer;

        /deep/.is-fixed {
          top: -5px;
          right: 0;
        }
      }
    }
  }
  .bottom {
    padding-bottom: 40px;
    ul {
      padding: 0 20px;

      // li:nth-child(odd) {
      //   background-color: #eee;
      // }
      li:nth-child(even) {
        background-color: #f5f5f5;
      }
      li {
        // padding: 0 20px;
        width: 100%;
        font-size: 14px;
        box-sizing: border-box;
        cursor: pointer;
        padding: 10px 0;
        display: flex;
        align-items: center;
        .color {
          color: green !important;
        }
        span:nth-child(1) {
          display: inline-block;
          width: 7%;
          color: red;
          font-size: 12px;
        }
        .el-badge {
          margin-right: 10px;
          display: flex;
          align-items: center;
        }

        /deep/.is-fixed.is-dot {
          right: 10px;
        }
        /deep/ .is-fixed {
          top: 10px;
        }
        /deep/.is-fixed {
          width: 5px;
          height: 5px;
        }
      }
    }
    .page {
      margin-top: 30px;
    }
  }
  /deep/.el-dialog__body {
    padding: 10px 30px;
    min-height: 250px;
    font-size: 16px;
  }
  /deep/.el-dialog__title {
    font-size: 22px;
  }
}
.mobile {
  .top {
    ul {
      display: flex;
      justify-content: start;
      padding: 14px 0 14px 15px;

      .color {
        color: #1677ff;
      }

      li {
        margin-right: 15px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 22px;
        cursor: pointer;

        /deep/.is-fixed {
          top: -5px;
          right: 0;
        }
      }
    }
  }
  .bottom {
    padding-bottom: 40px;
    ul {
      padding: 5px 8px 5px;

      // li:nth-child(odd) {
      //   background-color: #eee;
      // }
      li:nth-child(even) {
        background-color: #f5f5f5;
      }
      li {
        // padding: 0 20px;
        width: 100%;
        font-size: 14px;
        box-sizing: border-box;
        cursor: pointer;
        padding: 5px 0;

        .color {
          color: green !important;
        }
        span:nth-child(1) {
          display: inline-block;
          width: 15%;
          color: red;
          font-size: 12px;
        }
        .el-badge {
          margin-right: 10px;
          display: flex;
          align-items: center;
        }

        /deep/.is-fixed.is-dot {
          right: 10px;
        }
        /deep/ .is-fixed {
          top: 10px;
        }
        /deep/.is-fixed {
          width: 5px;
          height: 5px;
        }
      }
    }
    .height {
      overflow: auto;
    }
    .page {
      margin-top: 30px;
    }
  }
  /deep/.el-dialog__body {
    padding: 10px 30px;
    min-height: 250px;
    font-size: 14px;
  }
  /deep/.el-dialog__title {
    font-size: 18px;
  }
}
</style>