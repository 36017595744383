<template>
  <div>
    <Header :indexi="2"></Header>
    <!-- PC端 -->
    <div v-if="show_pc">
      <!-- 专栏老师列表内容区域 -->
      <div class="details_box">
        <!-- 老师列表左侧区域 -->

        <div class="details_left" v-loading="fullscreenLoading">
          <!-- 文章列表文本区域 -->
          <div class="article_text">
            <p>文章列表</p>
            <span></span>
          </div>

          <!-- 文章列表区域 -->
          <div
            class="articleConment"
            v-for="(item, index) in articleListTop"
            :key="item.id"
            :class="{ hover: hoverId == item.id }"
            @click="infoDetails(item)"
            @mouseover="MouseHover(item.id)"
          >
            <!-- 左侧图片 -->
            <div class="image"><img :src="item.cover" alt="" /></div>
            <!-- 右侧内容 -->
            <div class="conment">
              <p>{{ item.title }}</p>
              <div class="messages" v-html="item.description">
                {{ item.description }}
              </div>
              <div class="price">
                <div>
                  <span>{{ item.com_from }} </span>
                  <span> {{ item.time_tmp }}</span>
                </div>
                <span :class="{ Color: 2 == item.is_pay }">{{
                  item.price_str
                }}</span>
              </div>
            </div>
          </div>

          <!-- 广告 -->
          <div
            class="banner"
            v-if="zjgk.img_url"
            @click="ClickBanner(zjgk.target_url)"
          >
            <img :src="zjgk.img_url" alt="" />
            <p>广告</p>
          </div>

          <!-- 文章下 -->
          <div
            class="articleConment"
            v-for="(item, index) in articleListBottom"
            :key="item.id"
            @click="infoDetails(item)"
            :class="{ hover: hoverId == item.id }"
            @mouseover="MouseHover(item.id)"
          >
            <!-- 左侧图片 -->
            <div class="image"><img :src="item.cover" alt="" /></div>
            <!-- 右侧内容 -->
            <div class="conment">
              <p>{{ item.title }}</p>
              <div class="messages" v-html="item.description">
                {{ item.description }}
              </div>
              <div class="price">
                <div>
                  <span>{{ item.com_from }} </span>
                  <span> {{ item.time_tmp }}</span>
                </div>
                <span :class="{ Color: 2 == item.is_pay }">{{
                  item.price_str
                }}</span>
              </div>
            </div>
          </div>

          <!-- 分页 -->
          <!-- 底部分页 -->
          <div class="page" v-if="articleListTop.length > 0">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="queryInfo.pagenum"
              :page-size="queryInfo.pagesize"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 老师列表右侧区域 -->
        <div class="details_rigth">
          <div class="data">
            <!-- 头像 资料区域 -->
            <div class="top">
              <div class="portrait" style="background: #fff">
                <img
                  v-if="authorInfo.headimg"
                  :src="authorInfo.headimg"
                  alt=""
                />
              </div>
              <div class="infor">
                <p>{{ authorInfo.nickname }}</p>
                <p>{{ authorInfo.description }}</p>
              </div>
            </div>
            <!-- 文章数量区域 -->
            <div class="bottom" v-if="articleListTop.lenght >= 0">
              <div class="text">{{ authorInfo.article_count }}</div>
              <div class="numders">
                <ul>
                  <li v-for="(item, index) in articleList" :key="index">
                    <p>{{ item.title }}</p>
                    <p>{{ item.create_time }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 专栏作者 -->
          <Author></Author>
          <!-- 企业推荐 -->
          <Enterprise></Enterprise>

          <!-- 最新活动 -->
          <Newactivity></Newactivity>

          <!-- 薪税课堂 -->
          <Classroom></Classroom>

          <!-- 热门文库 -->

          <Library></Library>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="details_md">
        <div class="data">
          <!-- 头像 资料区域 -->
          <div class="top">
            <div class="portrait">
              <img :src="authorInfo.headimg" alt="" />
            </div>
            <div class="infor">
              <p>{{ authorInfo.nickname }}</p>
              <p>{{ authorInfo.description }}</p>
            </div>
          </div>
          <!-- 文章数量区域 -->
          <div class="bottom" v-if="articleListTop.lenght >= 0">
            <div class="text">{{ authorInfo.article_count }}</div>
            <div class="numders">
              <ul>
                <li v-for="(item, index) in articleList" :key="index">
                  <p>{{ item.title }}</p>
                  <p>{{ item.create_time }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="details_left">
          <!-- 文章列表文本区域 -->
          <div class="article_text">
            <span></span>
            <p>作者发布的文章</p>
          </div>

          <!-- 文章列表区域 -->
          <div
            class="articleConment"
            v-for="(item, index) in articleAllList"
            :key="item.id"
            :class="{ hover: hoverId == item.id }"
            @click="infoDetails(item)"
            @mouseover="MouseHover(item.id)"
          >
            <!-- 左侧图片 -->
            <div class="image"><img :src="item.cover" alt="" /></div>
            <!-- 右侧内容 -->
            <div class="conment">
              <p>{{ item.title }}</p>

              <div class="price">
                <div>
                  <span>{{ item.com_from }} </span>
                  <span> {{ item.time_tmp }}</span>
                </div>
                <span :class="{ Color: 2 == item.is_pay }">{{
                  item.price_str
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="more"
          v-if="articleAllList.length !== total"
          @click="ClickMore"
        >
          加载更多
        </div>
        <div
          class="more"
          @click="ClickPackUpMore"
          v-if="articleAllList.length > 10"
        >
          收起更多
        </div>

        <div class="more" v-if="articleAllList.length == total">
          没有更多了...
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/header";
import Footer from "../../components/footer";
import Article from "../../components/article";
import Author from "../../components/author";
import Enterprise from "../../components/enterprise";
import Newactivity from "../../components/newactivity";
import Classroom from "../../components/classroom";
import Library from "../../components/library";
import { Message } from "element-ui";
export default {
  components: {
    Header,
    Footer,
    // Article,
    Author,
    Enterprise,
    Newactivity,
    Classroom,
    Library,
  },
  metaInfo() {
    return {
      title: this.authorInfo.nickname
        ? this.authorInfo.nickname + "文章列表" + " | 新税网"
        : window.location.href,

      meta: [
        {
          name: "description",
          content: this.authorInfo.description,
        },
        {
          name: "keywords",
          content: this.infoDetails.title,
        },
        {
          name: "author",
          content: this.authorInfo.nickname + " - 新税网",
        },
      ],
    };
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 作者id
      id: "",
      // 文章列表的中间广告
      zjgk: "",
      // 查询文章列表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      // 总条数
      total: 0,

      // 默认添加遮罩层
      hoverId: 0,

      // 广告以上的5条
      articleListTop: [],
      // 广告5条一下
      articleListBottom: [],

      // 当前作者文章数量列表
      numdersList: [],

      // 作者信息
      authorInfo: "",
      // 作者文章数量
      articleList: "",

      // 移动端数据
      articleAllList: "",
      fullscreenLoading: false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 获取作者id
    this.id = this.$route.query.id;
    this.fullscreenLoading = true;
    // 获取文章列表
    this.getArticle();
    // 获取作者信息
    this.getAuthorInfo();
  },
  methods: {
    //获取文章列表
    getArticle() {
      this.$http
        .post("/index.php/api/v1_0_0.writer/writer_article_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          writer_id: this.id,
        })
        .then((res) => {
          console.log("作者专栏文章");
          console.log(res);
          const list = res.data.list;
          this.articleAllList = list;
          this.articleListTop = list.slice(0, 5);
          this.articleListBottom = list.slice(5);
          this.total = res.data.total_count;
          this.zjgk = res.data.advert;
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },

    // 查看文章列表详情函数
    infoDetails(item) {
      console.log(item);
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }
      // return;
      this.$http
        .post("/index.php/api/v1_0_0.article/view_log", {
          id: item.id,
        })
        .then(() => {
          this.$router.push({
            path: "/zx/details",
            query: {
              id: item.id,
            },
          });
        })
        .catch(() => {});

      return false;
      // 直接进入详情
      if (item.is_pay == 1 || item.is_owner) {
      }

      // 需要付费
      if (item.is_pay == 2) {
        this.$http
          .post("/index.php/api/v1_0_0.article/check_balance", {
            id: item.id,
          })
          .then((res) => {
            console.log(res);
            if (res.data.balance) {
              if (res.data.safe_password) {
                this.$confirm(res.msg, "提示", {
                  confirmButtonText: "去设置",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.$router.push({
                      path: "/personal/financial",
                      query: {
                        tabs: "支付密码设置",
                      },
                    });
                  })
                  .catch(() => {});
              } else {
                this.$prompt("请输入密码", res.msg, {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  inputPlaceholder: "请输入支付密码",
                  inputType: "password",
                  inputValidator: function (v) {
                    if (!v) return "请输入支付密码";
                  },
                })
                  .then(({ value }) => {
                    this.$http
                      .post("/index.php/api/v1_0_0.article/do_pay", {
                        id: item.id,
                        password: value,
                      })
                      .then((res) => {
                        // 加入成功，进入直播页面
                        console.log(res);
                        Message.success("购买资讯成功");
                        this.$router.push({
                          path: "/zx/details",
                          query: {
                            id: item.id,
                          },
                        });
                      })
                      .catch((error) => {});
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消支付",
                    });
                  });
              }
            } else {
              this.$confirm(res.msg, "余额不足", {
                confirmButtonText: "获取积分",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  // TODO 去充值的跳转
                  this.$router.push({
                    path: "/personal/financial",
                    query: { i: 1 },
                  });
                })
                .catch(() => {});
            }
          })
          .catch((error) => {});
      }
    },

    // 鼠标经过事件
    MouseHover(id) {
      this.hoverId = id;
    },

    // 广告点击事件
    ClickBanner(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getArticle();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;

      this.getArticle();
    },
    // 获取作者信息以及文章信息
    getAuthorInfo() {
      this.$http
        .get("/index.php/api/v1_0_0.writer/writer_info", {
          params: { writer_id: this.id },
          notNeed: true,
        })
        .then((res) => {
          this.authorInfo = res.data.writer_info;
          this.articleList = res.data.writer_article_list;
          console.log(res.data);
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang='less' scoped>
.details_box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // margin: 0 10.45%;
  .details_left {
    width: 66%;
    height: auto;
  }
  .article_text {
    margin: 10px 0 7px 0;
    width: 100%;
    height: 90px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    p {
      // width: 104px;
      height: 37px;
      font-size: 26px;
      font-weight: 600;
      color: #292931;
      line-height: 37px;
      text-align: left;
      margin-bottom: 10px;
    }
    // span {
    //   display: block;
    //   width: 68px;
    //   height: 5px;
    //   background: #1677ff;
    //   margin-left: 18px;
    // }
  }
  .details_rigth {
    width: 33%;
    height: auto;
    padding-top: 50px;

    .data {
      width: 100%;
      height: auto;
      margin-bottom: 8px;
      .top {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: start;
        .portrait {
          height: 104px;
          width: 104px;
          // background-color: #999;
          box-sizing: border-box;
          overflow: hidden;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }

        .infor {
          width: calc(100% - 104px);
          height: 104px;
          margin-left: 14px;
          p {
            text-align: left;
          }
          p:nth-child(1) {
            font-size: 18px;
            font-weight: 600;
            color: #303030;
            line-height: 25px;
            margin-bottom: 10px;
          }
          p:nth-child(2) {
            font-size: 12px;
            font-weight: 400;
            color: #313a4a;
            line-height: 17px;
            overflow: hidden; //强制多行显示
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
        }
      }
      .bottom {
        width: 100%;
        height: auto;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
          width: 72px;
          border-bottom: 2px solid #477ae4;
          font-size: 14px;
          font-weight: 400;
          color: #313a4a;
          line-height: 40px;
          margin-bottom: -2px;
          z-index: 11;
        }
        .numders {
          width: 100%;
          height: auto;
          background-color: #fff;
          border: 1px solid #e8e9ec;
          ul {
            padding: 14px 20px 12px;
            li {
              width: 100%;
              text-align: left;
              margin-bottom: 15px;
              box-sizing: content-box;

              p:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                color: #313a4a;
                line-height: 20px;
                padding: 10px 0;
              }
              p:nth-child(2) {
                font-size: 12px;
                font-weight: 400;
                color: #8c939f;
                line-height: 17px;
              }
            }
          }
        }
      }
    }
  }
  .text {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #292931;
    line-height: 33px;
    margin: 20px 0;
  }
  .articleConment {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 190px;
    background-color: #fff;
    padding: 20px 10px;
    box-sizing: border-box;
    margin-bottom: 10px;

    .image {
      width: 31%;
      height: 150px;
      background-color: #999;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .conment {
      display: inline-block;
      width: 67%;
      height: 150px;
      padding-left: 13px;
      text-align: left;

      p {
        font-size: 20px;
        font-weight: 500;
        color: #292931;
        white-space: nowrap; //强制一行显示
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .messages {
        height: 55%;
        margin: 10px 0;
        box-sizing: border-box;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #808080;
        line-height: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        cursor: pointer;
      }

      .price {
        display: flex;
        justify-content: space-between;
        div {
          height: 12px;
          line-height: 12px;

          span {
            display: inline-block;
            height: 100%;
            color: #a8aeb8;
            font-size: 12px;
          }
          span:nth-child(1) {
            border-right: 1px solid #a8aeb8;
            padding-right: 5px;
          }
          span:nth-child(2) {
            padding-left: 5px;
          }
        }

        span {
          color: #1677ff;
          font-size: 12px;
        }
      }
    }
  }
}

.hover {
  background: #f9fafb;
}

.page {
  text-align: center;
  padding: 25px 0 50px 0;
}

// 控制付费的样得字体颜色
.Color {
  color: #ff5916 !important;
}
.banner {
  // width: 100%;
  height: 200px;
  position: relative;
  margin: 22px 0px 29px 10px;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  p {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 30px;
    text-align: center;
    background: #1677ff;
    border-radius: 0px 0px 0px 20px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
  }
}

.details_md {
  min-height: 56vh;
  padding: 60px 0 0px;
  .data {
    width: 100%;
    height: 154px;

    .top {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #e8e9ec;
      .portrait {
        height: 60px;
        width: 60px;
        background-color: #999;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 60px;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }

      .infor {
        p {
          text-align: center;
        }
        p:nth-child(1) {
          font-size: 15px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #303030;
          line-height: 21px;
          margin: 18px 0 6px 0;
        }
        p:nth-child(2) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #313a4a;
          line-height: 17px;
        }
      }
    }
    .bottom {
      width: 100%;
      height: auto;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        width: 72px;
        border-bottom: 2px solid #477ae4;
        font-size: 14px;
        font-weight: 400;
        color: #313a4a;
        line-height: 40px;
        margin-bottom: -2px;
        z-index: 11;
      }
      .numders {
        width: 100%;
        height: auto;
        background-color: #fff;
        border: 1px solid #e8e9ec;
        ul {
          padding: 14px 20px 12px;
          li {
            width: 100%;
            text-align: left;
            margin-bottom: 15px;
            box-sizing: content-box;

            p:nth-child(1) {
              font-size: 14px;
              font-weight: 400;
              color: #313a4a;
              line-height: 20px;
              padding: 10px 0;
            }
            p:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
              color: #8c939f;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
  .article_text {
    padding: 12px 10px;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 2px;
      height: 14px;
      background: #1677ff;
    }
    p {
      text-align: left;
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #292931;
      margin-left: 2px;
    }
    line-height: 21px;
  }
  .articleConment {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;

    .image {
      width: 112px;
      height: 70px;
      background-color: #999;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .conment {
      display: inline-block;
      width: 233px;
      padding-left: 8px;
      text-align: left;

      p {
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 700;
        color: #292931;
        line-height: 20px;
        margin-bottom: 16px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        letter-spacing: 0.5px;
      }

      .price {
        display: flex;
        justify-content: space-between;
        div {
          height: 12px;
          line-height: 12px;

          span {
            display: inline-block;
            height: 100%;
            color: #a8aeb8;
            font-size: 12px;
          }
          span:nth-child(1) {
            border-right: 1px solid #a8aeb8;
            padding-right: 5px;
            letter-spacing: 1px;
          }
          span:nth-child(2) {
            padding-left: 5px;
            letter-spacing: 1px;
          }
        }

        span {
          color: #1677ff;
          font-size: 12px;
        }
      }
    }
  }
  .Color {
    color: #ff5916 !important;
  }
  .more {
    width: 128px;
    height: 36px;
    line-height: 36px;
    margin: 20px auto;
    border: 2px solid #e5e6ea;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #555c69;
    background-color: #fff;
  }
}
</style>
