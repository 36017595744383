<template>
  <div>
    <Header></Header>
    <div class="release_box">
      <div
        class="release_bg"
        :style="{
          backgroundImage:
            'url(http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201212/161140_761023.png)',
        }"
      >
        <div class="release">
          <div class="release_left" style="position: relative">
            <!-- <p style="margin-bottom: 20px;color: #1677FF;">< 返回</p> -->
            <el-button
              type="text"
              icon="el-icon-arrow-left"
              style="position: absolute; left: 0; top: -15px"
              @click="goBack"
              >返回</el-button
            >
            <ul style="margin-top: 40px">
              <li
                v-for="(item, index) in releaseList"
                :key="index"
                @click="ClickReleasePc(item, index)"
                :class="{ color: i == index }"
              >
                <img
                  :src="item.img"
                  alt=""
                  style="width: 20px; height: 20px; margin-right: 8px"
                />
                <span style="line-height: 20px">{{ item.releaseName }}</span>
              </li>
            </ul>
          </div>
          <div class="release_rigth">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/header";
import Footer from "../../components/footer";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // 背景图片
      mgUrl: require("../../assets/image/a50fef692785c5e78c96fe3d0326d973_最大尺寸 (1)@2x.png"),

      // 发布列表
      releaseList: [
        // {
        //   img: require("../../assets/image/publish/新闻报纸@2x.png"),
        //   releaseName: "寻求报道",
        // },
        // {
        //   img: require("../../assets/image/publish/活动@2x.png"),
        //   releaseName: "发布活动",
        // },
        // {
        //   img: require("../../assets/image/publish/课程查看@2x.png"),
        //   releaseName: "发布课程",
        // },
        // {
        //   img: require("../../assets/image/publish/资讯@2x.png"),
        //   releaseName: "发布资讯",
        // },
        // {
        //   img: require("../../assets/image/publish/文库@2x.png"),
        //   releaseName: "发布文库",
        // },
        // {
        //   img: require("../../assets/image/publish/职位@2x.png"),
        //   releaseName: "发布职位",
        // },
      ],
      // 默认第一个发布开 和 显示
      i: 0,
      tid: "",
      isIdentity: "",
      fullPath: "", //上一级页面
    };
  },
  computed: {
    ...mapState(["goEdit", "uploadUrl"]),
  },
  beforeRouteEnter(to, from, next) {
    localStorage.setItem("backName", from.fullPath);
    next();
  },
  created() {
    this.getUser();
  },
  methods: {
    ClickReleasePc(item, index) {
      if (
        typeof item.releaseName == "undefined" &&
        this.isIdentity != "作家" &&
        this.isIdentity != "企业"
      ) {
        this.recover(1);

        this.i = 0;
        this.$router.push("/release/seek");
        this.releaseList[0].img = require("../../assets/image/publish/新闻报纸备份@2x.png");
        return;
      }

      if (this.isIdentity == "普通用户") {
        this.recover(1);
        this.i = 0;
        this.$router.push("/release/seek");
        this.releaseList[0].img = require("../../assets/image/publish/新闻报纸备份@2x.png");
      } else if (this.isIdentity == "作家") {
        this.recover(2);
        if (
          item.releaseName == "寻求报道" ||
          typeof item.releaseName == "undefined"
        ) {
          this.i = 0;
          this.$router.push("/release/seek");
          this.releaseList[0].img = require("../../assets/image/publish/新闻报纸备份@2x.png");
        }
        if (item.releaseName == "发布资讯") {
          this.i = 1;
          this.$router.push("/release/info");
          this.releaseList[1].img = require("../../assets/image/publish/资讯备份@2x.png");
        }
        if (item.releaseName == "发布文库") {
          this.i = 2;
          this.$router.push("/release/library");
          this.releaseList[2].img = require("../../assets/image/publish/文库备份@2x.png");
        }
        return;
      }

      if (this.isIdentity == "企业") {
        this.recover(3);
      }

      this.releaseList.map((v, i) => {
        if (item.releaseName == v.releaseName) {
          this.i = i;
          console.log(this.i);
        }
      });

      if (
        item.releaseName == "寻求报道" ||
        typeof item.releaseName == "undefined"
      ) {
        this.$router.push("/release/seek");
        this.releaseList[0].img = require("../../assets/image/publish/新闻报纸备份@2x.png");
      } else if (this.i == 1) {
        this.$router.push("/release/activity");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/活动备份@2x.png");
      } else if (item.releaseName == "发布课程") {
        this.$router.push("/release/course");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/课程查看备份@2x.png");
      } else if (this.i == 3) {
        this.$router.push("/release/info");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/资讯备份@2x.png");
      } else if (this.i == 4) {
        this.$router.push("/release/library");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/文库备份@2x.png");
      } else if (this.i == 5) {
        this.$router.push("/release/postion");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/职位备份@2x.png");
      }
    },
    // 点击发布列表
    ClickRelease(item, index) {
      console.log(item, "item");
      if (
        typeof item.releaseName == "undefined" &&
        this.isIdentity != "作家" &&
        this.isIdentity != "企业"
      ) {
        this.recover(1);
        console.log("fsndkfnsk");
        this.i = 0;
        // this.$router.push("/release/seek");
        this.releaseList[0].img = require("../../assets/image/publish/新闻报纸备份@2x.png");
        return;
      }

      if (this.isIdentity == "普通用户") {
        this.recover(1);
      } else if (this.isIdentity == "作家") {
        this.recover(2);

        if (
          item.releaseName == "寻求报道" ||
          typeof item.releaseName == "undefined"
        ) {
          this.i = 0;
          // this.$router.push("/release/seek");
          this.releaseList[0].img = require("../../assets/image/publish/新闻报纸备份@2x.png");
        }
        if (item.releaseName == "发布资讯") {
          this.i = 1;
          // this.$router.push("/release/info");
          this.releaseList[1].img = require("../../assets/image/publish/资讯备份@2x.png");
        }
        if (item.releaseName == "发布文库") {
          this.i = 2;
          // this.$router.push("/release/library");
          this.releaseList[2].img = require("../../assets/image/publish/文库备份@2x.png");
        }
        return;
      } else {
        this.recover(3);
      }

      this.releaseList.map((v, i) => {
        if (item.releaseName == v.releaseName) {
          this.i = i;
          console.log(this.i);
        }
      });

      if (item.releaseName == "寻求报道") {
        // this.$router.push("/release/seek");
        this.releaseList[0].img = require("../../assets/image/publish/新闻报纸备份@2x.png");
      } else if (this.i == 1) {
        // this.$router.push("/release/activity");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/活动备份@2x.png");
      } else if (item.releaseName == "发布课程") {
        // this.$router.push("/release/course");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/课程查看备份@2x.png");
      } else if (this.i == 3) {
        // this.$router.push("/release/info");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/资讯备份@2x.png");
      } else if (this.i == 4) {
        // this.$router.push("/release/library");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/文库备份@2x.png");
      } else if (this.i == 5) {
        // this.$router.push("/release/postion");
        this.releaseList[
          this.i
        ].img = require("../../assets/image/publish/职位备份@2x.png");
      }
    },
    getUser() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
        .then(({ data }) => {
          this.userInfo = data;
          this.$store.state.user.userInfo = data;
          if (data.is_writer == 0 && data.is_business == 0) {
            this.isIdentity = "普通用户";
            this.recover(1);
          }
          if (data.is_writer == 1) {
            this.isIdentity = "作家";
            this.recover(2);
          }
          if (data.is_business == 1) {
            this.isIdentity = "企业";
            this.recover(3);
          }
          if (typeof this.$route.query.item == "undefined") {
            this.ClickReleasePc();
          } else {
            this.ClickRelease(
              {
                releaseName: this.$route.query.item,
              },
              0
            );
          }
        })
        .catch((error) => {});
    },
    recover(type) {
      if (type == 1) {
        this.releaseList = [
          {
            img: require("../../assets/image/publish/新闻报纸@2x.png"),
            releaseName: "寻求报道",
          },
        ];
      }
      if (type == 2) {
        this.releaseList = [
          {
            img: require("../../assets/image/publish/新闻报纸@2x.png"),
            releaseName: "寻求报道",
          },
          {
            img: require("../../assets/image/publish/资讯@2x.png"),
            releaseName: "发布资讯",
          },
          {
            img: require("../../assets/image/publish/文库@2x.png"),
            releaseName: "发布文库",
          },
        ];
      }
      if (type == 3) {
        this.releaseList = [
          {
            img: require("../../assets/image/publish/新闻报纸@2x.png"),
            releaseName: "寻求报道",
          },
          {
            img: require("../../assets/image/publish/活动@2x.png"),
            releaseName: "发布活动",
          },
          {
            img: require("../../assets/image/publish/课程查看@2x.png"),
            releaseName: "发布课程",
          },
          {
            img: require("../../assets/image/publish/资讯@2x.png"),
            releaseName: "发布资讯",
          },
          {
            img: require("../../assets/image/publish/文库@2x.png"),
            releaseName: "发布文库",
          },
          {
            img: require("../../assets/image/publish/职位@2x.png"),
            releaseName: "发布职位",
          },
        ];
      }
    },
    goBack() {
      this.$router.push(localStorage.getItem("backName"));
    },
  },
  mounted() {
    // this.$router.push("/release");
  },
  watch: {},
};
</script>
<style lang='less' scoped>
.color {
  color: #1677ff;
}

.release_box {
  .release_bg {
    width: 100%;

    height: auto;
    background: #f1f5f6;
    background-size: 100% 270px;
    background-repeat: no-repeat;
    padding: 120px 0 50px;

    .release {
      margin: 0 auto;
      width: 1200px;
      /* margin: auto 17%; */
      background-color: #fff;
      display: flex;
      justify-content: start;
      padding: 50px 54px 50px;

      .release_left {
        width: 26.73%;
        color: #878e9b;

        ul {
          li {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 28px;
            cursor: pointer;

            span {
            }
          }
        }

        /* ul {
            margin-left: 10px;



            li{
              margin-bottom: 28px;
            }

            img {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-top: -2px;
            }

            span {
              font-size: 14px;
              font-weight: 400;

              line-height: 20px;
              margin-left: 6px;
            }

            ul {
              margin-left: 10px;
            }
          } */
      }

      .release_rigth {
        width: 73.18%;
        border-left: 1px solid #e5e6ea;
        margin-top: 13px;
        padding: 0 20px;
      }
    }
  }
}
</style>
