const user = {
  state: {
    userInfo: {
      headimg: require("@/assets/image/user@2x.png"),
    }, //用户信息
    id: '',
    token: '',
    headerIndex: 0,

  },
  getters: {

  },
  mutations: {
    setHeaderIndex(state, value) {
      state.headerIndex = value
    },
  },
  actions: {}
}

export default user;
