<template>
  <!-- 发布课程 -->
  <div>
    <div v-if="show_pc" v-loading.fullscreen.lock="loading">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <!-- 课程名称 -->
        <el-form-item label="课程名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入课程名字"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 邀请嘉宾 -->

        <el-form-item label="邀请嘉宾" prop="honored" v-if="isEdit">
          <el-input
            v-model="ruleForm.honored"
            placeholder="邀请嘉宾"
            :disabled="isEdit"
          >
            <!-- <template slot="append" style="cursor:pointer"></template> -->
            <el-button slot="append" @click="copyURL">复制</el-button>
          </el-input>
        </el-form-item>

        <!-- 课程分类 -->
        <el-form-item label="课程分类" prop="region">
          <el-select
            v-model="ruleForm.region"
            placeholder="请选择课程分类"
            style="width: 100%"
            :disabled="editDisabled"
          >
            <el-option
              v-for="item in classifyList"
              :key="item.value"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报名人数" prop="people_num">
          <el-input
            v-model="ruleForm.people_num"
            placeholder="0表示不限制人数"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 是否收费 -->
        <el-form-item
          label="是否收费"
          prop="resource"
          style="position: relative"
        >
          <el-radio-group v-model="ruleForm.resource" :disabled="editDisabled">
            <el-radio label="否"></el-radio>
            <el-radio label="是" style="height: 100%"></el-radio>
            <span style="line-height: 40px; height: 24px"></span>
            <!-- <span class="money" style="border: 0;"><input type="numder" /><span ></span>1</span> -->
            <!-- <div style="display: flex;position: absolute;top: 1px;z-index: 9;align-items: center;" v-if="ruleForm.resource=='是'">
                  <span style="border: 0;line-height: 34px;">请选择支付方式</span>
                 <el-select v-model="ruleForm.charge" placeholder="请选择" style="width:100px;margin: 0 15px;">
                    <el-option v-for="item in chargeList" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </div> -->
            <span class="money" v-if="ruleForm.resource == '是'"
              ><input
                type="numder"
                v-model="ruleForm.money"
                :disabled="editDisabled"
              /><span style="border: 0">{{
                ruleForm.charge == "积分" ? "分" : "元"
              }}</span></span
            >
          </el-radio-group>
        </el-form-item>

        <!-- 课程简介 -->
        <el-form-item label="课程简介" prop="desc">
          <el-input
            type="textarea"
            :autosize="true"
            v-model="ruleForm.desc"
            rows="12"
            :disabled="editDisabled"
            placeholder="请输入课程简介"
          ></el-input>
        </el-form-item>

        <!-- 内容 课程详情 -->
        <el-form-item label="课程详情" prop="content">
          <div class="text" style="height: 314px; width: 100%">
            <quill-editor
              class="quill"
              v-model="ruleForm.content"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              :disabled="editDisabled"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
              style="height: 80%"
            >
            </quill-editor>
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :file-list="richImg"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
            >
            </el-upload>
            <!-- 视频上传事件，实现视频上传 -->
            <el-upload
              id="uploadFileVideo"
              :action="uploadUrl"
              :file-list="richImg"
              :show-file-list="false"
              :on-success="videoUploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
            >
            </el-upload>
          </div>
        </el-form-item>

        <!-- 讲师详情 -->
        <el-form-item label="讲师详情" prop="teacherDesc">
          <el-input
            type="textarea"
            :autosize="true"
            v-model="ruleForm.teacherDesc"
            placeholder="请输入讲师详情"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 直播时间 -->
        <el-form-item label="直播时间" prop="time">
          <div class="block">
            <el-date-picker
              style="line-height: 180px; margin-top: 4px"
              align="center"
              v-model="ruleForm.time"
              type="datetimerange"
              :disabled="editDisabled"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <!-- 课程封面 -->
        <el-form-item label="封面图片" prop="fengImg" ref="img">
          <el-upload
            :action="uploadUrl"
            :show-file-list="false"
            :on-preview="handlePictureCardPreview"
            :on-success="onSuccess"
            :disabled="editDisabled"
            :on-remove="handleRemove1"
            :file-list="coverList"
            :on-progress="process"
            :before-upload="beforeAvatarUpload"
            :on-change="change"
            :auto-upload="true"
            ref="upload"
          >
            <img
              width="100%"
              :src="ruleForm.fengImg"
              alt=""
              style="width: 88px; height: 88px"
            />
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <!-- 课程图片 -->
        <el-form-item label="课程图片" prop="keImg">
          <el-upload
            :before-upload="beforeAvatarUpload"
            :action="uploadUrl"
            :on-progress="process"
            :on-change="change"
            :show-file-list="false"
            :on-preview="handlePictureCardPreview"
            :on-success="onSuccess1"
            :disabled="editDisabled"
            :on-remove="handleRemove2"
            :file-list="fileList"
          >
            <img
              width="100%"
              :src="ruleForm.keImg"
              alt=""
              style="width: 88px; height: 88px"
            />
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <!-- 课件 -->
        <el-form-item label="课件" prop="kejian">
          <el-upload
            :before-upload="beforeAvatarUpload"
            :action="uploadUrl"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-success="kejianOnSuccess"
            :disabled="editDisabled"
            :limit="5"
            :on-remove="handleRemove3"
            :file-list="kejianList"
          >
            <img
              width="100%"
              src="../../assets/image/e256101b333d6e0e9b43abd90d81247.png"
              alt=""
              style="width: 88px; height: 88px"
            />
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <!-- 提交 -->
        <el-form-item>
          <el-button
            type="primary"
            style="
              margin-left: 0px;
              display: inline-block;
              background-color: #1677ff;
              width: 104px;
              border-radius: 0;
              height: 34px;
              line-height: 4px;
            "
            :disabled="editDisabled"
            @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div v-if="!show_pc" id="mobile">
      <Header></Header>
      <div style="width: 100%; height: 90px"></div>
      <div
        class="release_bg"
        :style="{
          backgroundImage:
            'url(https://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20210204/153945_458907.png)',
        }"
        style="margin-bottom: 30px"
      >
        <div style="width: 100%; height: 50px"></div>
        <div style="padding: 10px" v-loading.fullscreen.lock="loading">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            style="background-color: #fff; padding: 20px 10px"
          >
            <!-- 课程名称 -->
            <el-form-item label="课程名称" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入课程名字"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 邀请嘉宾 -->

            <el-form-item label="邀请嘉宾" prop="honored" v-if="isEdit">
              <el-input
                v-model="ruleForm.honored"
                placeholder="邀请嘉宾"
                :disabled="isEdit"
              >
                <!-- <template slot="append" style="cursor:pointer"></template> -->
                <el-button slot="append" @click="copyURL">复制</el-button>
              </el-input>
            </el-form-item>

            <!-- 课程分类 -->
            <el-form-item label="课程分类" prop="region">
              <el-select
                v-model="ruleForm.region"
                placeholder="请选择课程分类"
                style="width: 100%"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="item in classifyList"
                  :key="item.value"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!-- 报名人数 -->
            <el-form-item label="报名人数" prop="people_num">
              <el-input
                v-model="ruleForm.people_num"
                placeholder="请输入报名人数"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 是否收费 -->
            <el-form-item
              label="是否收费"
              prop="resource"
              style="position: relative"
            >
              <el-radio-group
                v-model="ruleForm.resource"
                :disabled="editDisabled"
              >
                <el-radio label="否"></el-radio>
                <el-radio label="是" style="height: 100%"></el-radio>
                <span style="line-height: 40px; height: 24px"></span>
                <!-- <span class="money" style="border: 0;"><input type="numder" /><span ></span>1</span> -->
                <!-- <div style="display: flex;position: absolute;top: 1px;z-index: 9;align-items: center;" v-if="ruleForm.resource=='是'">
                  <span style="border: 0;line-height: 34px;">请选择支付方式</span>
                 <el-select v-model="ruleForm.charge" placeholder="请选择" style="width:100px;margin: 0 15px;">
                    <el-option v-for="item in chargeList" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </div> -->
                <span
                  class="money"
                  style="padding: 0"
                  v-if="ruleForm.resource == '是'"
                  ><input
                    type="numder"
                    v-model="ruleForm.money"
                    :disabled="editDisabled"
                  /><span style="border: 0; margin-right: 6px">{{
                    ruleForm.charge == "积分" ? "分" : "元"
                  }}</span></span
                >
              </el-radio-group>
            </el-form-item>

            <!-- 课程简介 -->
            <el-form-item label="课程简介" prop="desc">
              <el-input
                style="margin: 6px 0"
                type="textarea"
                :autosize="true"
                v-model="ruleForm.desc"
                :disabled="editDisabled"
                rows="12"
                placeholder="请输入课程简介"
              ></el-input>
            </el-form-item>

            <!-- 内容 课程详情 -->
            <div id="keContent">
              <el-form-item label="课程详情" prop="content">
                <div class="text" style="height: 300px; margin-top: 10px">
                  <quill-editor
                    class="quill"
                    v-model="ruleForm.content"
                    ref="myQuillEditor"
                    :options="editorOption"
                    @blur="onEditorBlur($event)"
                    :disabled="editDisabled"
                    @focus="onEditorFocus($event)"
                    @change="onEditorChange($event)"
                    style="height: 80%; text-align: left; line-height: 0"
                  >
                  </quill-editor>
                </div>
              </el-form-item>
            </div>

            <div style="width: 100%; height: 34px"></div>
            <!-- 讲师详情 -->
            <el-form-item label="讲师详情" prop="teacherDesc">
              <el-input
                type="textarea"
                :autosize="true"
                v-model="ruleForm.teacherDesc"
                placeholder="请输入讲师详情"
                style="margin: 6px 0"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 直播时间 -->
            <el-form-item label="直播时间" prop="time">
              <div class="block">
                <el-date-picker
                  style="line-height: 180px; margin-top: 4px"
                  align="center"
                  v-model="ruleForm.time"
                  type="datetimerange"
                  :disabled="editDisabled"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </el-form-item>

            <!-- 课程封面 -->
            <el-form-item label="封面图片" prop="fengImg" ref="img">
              <el-upload
                :action="uploadUrl"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-success="onSuccess"
                :disabled="editDisabled"
                :before-upload="beforeAvatarUpload"
                style="margin: 6px 0"
                :limit="1"
                :on-remove="handleRemove1"
                :file-list="coverList"
              >
                <img
                  width="100%"
                  :src="ruleForm.fengImg"
                  alt=""
                  style="width: 88px; height: 88px"
                />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>

            <!-- 课程图片 -->
            <el-form-item label="课程图片" prop="keImg">
              <el-upload
                :action="uploadUrl"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-success="onSuccess1"
                :disabled="editDisabled"
                :before-upload="beforeAvatarUpload"
                style="margin: 6px 0"
                :limit="1"
                :on-remove="handleRemove2"
                :file-list="fileList"
              >
                <img
                  width="100%"
                  :src="ruleForm.keImg"
                  alt=""
                  style="width: 88px; height: 88px"
                />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>

            <!-- 课件 -->
            <el-form-item label="课件" prop="kejian">
              <el-upload
                :action="uploadUrl"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-success="kejianOnSuccess"
                :disabled="editDisabled"
                :before-upload="beforeAvatarUpload"
                style="margin: 6px 0"
                :limit="5"
                :on-remove="handleRemove3"
                :file-list="kejianList"
              >
                <img
                  width="100%"
                  src="../../assets/image/e256101b333d6e0e9b43abd90d81247.png"
                  alt=""
                  style="width: 88px; height: 88px"
                />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>

            <!-- 提交 -->
            <el-form-item>
              <el-button
                type="primary"
                class="btn"
                :disabled="editDisabled"
                @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div style="width: 100%; height: 60px"></div>
      </div>
    </div>
    <div id="inputVideoSize">
      <div class="videoWidth">
        <span class="videoText">视频宽度</span>
        <el-input v-model="videoWidth" placeholder="请输入视频宽度"></el-input>
      </div>
      <div class="videoHeight">
        <span class="videoText">视频高度</span>
        <el-input v-model="videoHeight" placeholder="请输入视频高度"></el-input>
      </div>
      <div class="btns">
        <el-button @click="ClickClose">关闭</el-button>
        <el-button type="primary" @click="ClickVideo">确定</el-button>
      </div>
    </div>
    <!-- 视频上传弹框 -->

    <el-dialog
      title="视频上传"
      :visible.sync="dialogVideo"
      width="30%"
      :show-close="false"
    >
      <!-- action必选参数, 上传的地址 -->
      <el-upload
        class="avatar-uploader el-upload--text"
        action
        :http-request="handleUpload"
        :show-file-list="false"
        :on-success="handleVideoSuccess"
        :before-upload="beforeUploadVideo"
        :on-progress="uploadVideoProcess"
      >
        <video
          v-if="video_url != '' && videoFlag == false"
          :src="video_url"
          style="width: 100%; height: 200px"
          controls="controls"
        >
          您的浏览器不支持视频播放
        </video>
        <i
          v-else-if="video_url == '' && videoFlag == false"
          class="el-icon-plus avatar-uploader-icon"
        ></i>
        <el-progress
          v-if="videoFlag == true"
          type="circle"
          :percentage="videoUploadPercent"
          style="margin-top: 30px"
        ></el-progress>
      </el-upload>

      <P class="text" style="margin-top: 20px"
        >请保证视频格式正确，且不超过5M</P
      >

      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px;
            border: 0;
          "
          >视频宽度</span
        ><el-input v-model="video_width" placeholder="视频宽度"></el-input>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px;
            border: 0;
          "
          >视频宽度</span
        >
        <el-input v-model="video_height" placeholder="视频高度"></el-input>
      </div>

      <span slot="footer" class="dialog-footer" style="border: 0">
        <el-button @click="clickCancel">取 消</el-button>
        <el-button type="primary" @click="toUploadVideo">确 定</el-button>
      </span>
    </el-dialog>
    <div id="test" @click="test"></div>
  </div>
</template>
<script>
import Header from "../header.vue";
import { mapState, mapMutations } from "vuex";
import VideoBlot from "../../utils/video";
import * as Quill from "quill";
import { client, getFileNameUUID } from "../../utils/ali-oss";
Quill.register(VideoBlot);
export default {
  components: {
    Header,
  },
  data() {
    var charge = (rule, value, callback) => {
      // if (this.ruleForm.resource == "是" && this.ruleForm.charge == '') {
      //   callback(new Error('请选择支付方式'))
      //   return
      // }
      if (
        this.ruleForm.resource == "是" &&
        (this.ruleForm.money == "" || this.ruleForm.money < 0)
      ) {
        callback(new Error("请输入金额"));
        return;
      }
      callback();
    };
    //图片上传验证
    let fengImg = (rule, value, callback) => {
      if (
        this.ruleForm.fengImg == "" ||
        this.ruleForm.fengImg == "static/img/上传封面图.b16f5bf8.png"
      ) {
        callback(new Error("请上传封面图片"));
      }
      callback();
    };
    let imgKC = (rule, value, callback) => {
      if (
        this.ruleForm.keImg == "" ||
        this.ruleForm.keImg == "static/img/上传课程.cf8d2627.png"
      ) {
        callback(new Error("请上传课程图片"));
      }
      callback();
    };
    return {
      video_width: "100%",
      video_height: "100px",
      video_url: "",
      videoFlag: false,
      videoUploadPercent: 0,
      select_index: 0,
      dialogVideo: false,
      videoWidth: "",
      // 视频高
      videoHeight: "",
      isEdit: false, //是否编辑状态
      coverList: [], //编辑封面预览
      fileList: [], //编辑课程预览
      kejianList: [], //编辑课件预览
      richImg: [],
      editorOption: {
        placeholder: "",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              // ['blockquote', 'code-block'],     //引用，代码块
              [
                { header: 1 },
                {
                  header: 2,
                },
              ], // 标题，键值对的形式；1、2表示字体大小
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ], //列表
              //[{ script: "sub" }, { script: "super" }], // 上下标
              [
                {
                  indent: "-1",
                },
                {
                  indent: "+1",
                },
              ], // 缩进
              [
                {
                  direction: "rtl",
                },
              ], // 文本方向
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
              // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
              // [{ 'align': [] }],    //对齐方式
              ["clean"], //清除字体样式
              ["image", "video"], //上传图片、上传视频
            ],
            handlers: {
              video: function (val) {
                if (val) {
                  document.querySelector("#test").click();

                  // 劫持原来的视频点击按钮事件
                  // document.querySelector("#inputVideoSize").style.display =
                  //   "block";
                } else {
                  this.quill.format("video", false);
                }
              },
              image: function (value) {
                if (value) {
                  // upload点击上传事件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },

      chargeList: ["余额"], // 支付方式
      //   上传图片路径
      dialogImageUrl: "",
      //   是否显示弹出框
      dialogVisible: false,

      ruleForm: {
        kejian: [],
        people_num: "",
        name: "",
        honored: "",
        region: "",
        resource: "否",
        desc: "",
        teacherDesc: "",
        time: "",
        fengImg: require("../../assets/image/上传封面图.png"),
        keImg: require("../../assets/image/上传课程.png"),

        charge: "",
        money: "",
        content: "", //富文本内容
      },
      rules: {
        kejian: [
          {
            required: false,
            message: "请上传课件",
            trigger: "blur",
          },
        ],
        keImg: [
          {
            required: true,
            message: "请上传课程图片",
            trigger: "change",
            validator: imgKC,
          },
        ],
        fengImg: [
          {
            required: true,
            message: "请上传封面图片",
            trigger: "change",
            validator: fengImg,
          },
        ],
        resource: [
          {
            required: true,
            validator: charge,
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入课程名字",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请编辑课程详情",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择课程分类",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "请输入课程简介",
            trigger: "change",
          },
        ],
        teacherDesc: [
          {
            required: true,
            message: "请输入讲师详情",
            trigger: "change",
          },
        ],
        time: [
          {
            required: true,
            message: "请选择直播时间",
          },
        ],
      },
      user: "",
      classifyList: [],
      tid: "",
      show_pc: true,
      loading: false,
      editDisabled: false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;

    //获取课程分类
    this.$http
      .get("/index.php/api/v1_0_0.course/get_cate_list", {
        notNeed: true,
      })
      .then((data) => {
        console.log(data, "课程分类");
        this.classifyList = data.data.list;
      })
      .catch((error) => {});

    //获取编辑内容详情
    this.tid = this.$route.query.tid;
    if (this.tid) {
      this.loading = true;
      this.isEdit = true;
      // this.editDisabled = this.$store.state.editDisabled;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/course_detail", {
          tid: this.tid,
        })
        .then((data) => {
          data.data.status == 1
            ? (this.editDisabled = true)
            : (this.editDisabled = false);
          let ruleForm = this.ruleForm;
          ruleForm.honored = data.data.course_code_url + data.data.course_code;
          ruleForm.name = data.data.title;
          ruleForm.region = data.data.cid;
          ruleForm.charge = data.data.unit;
          ruleForm.resource = data.data.is_pay == 1 ? "否" : "是";
          ruleForm.money =
            data.data.price == "￥免费" ? 0 : data.data.price.split("￥")[1];

          ruleForm.content = data.data.content;
          ruleForm.teacherDesc = data.data.teacher_desc;
          ruleForm.time = [
            new Date(data.data.online_time),
            new Date(data.data.end_time),
          ];
          ruleForm.desc = data.data.description;

          //添加图片预览 取消当前上传验证
          this.coverList.push({
            url: data.data.cover,
          });
          this.fileList.push({
            url: data.data.content_img,
          });
          this.rules.keImg = "";
          this.rules.fengImg = "";
          this.rules.kejian = "";
          ruleForm.fengImg = data.data.cover;
          ruleForm.keImg = data.data.content_img;
          if (data.data.book_imgs.length >= 1) {
            data.data.book_imgs.map((item, index) => {
              console.log(item);
              this.kejianList.push({ url: item });
            });
          }
          console.log(this.kejianList);
          this.loading = false;
          document.getElementsByTagName("body").scrollIntoView();
        })
        .catch((error) => {});
      return;
    }
  },
  computed: {
    ...mapState(["uploadUrl"]),
  },
  methods: {
    /**
     * 自定义上传方法
     */
    handleUpload(option) {
      let that = this;
      async function multipartUpload() {
        const fileName = getFileNameUUID();
        //定义唯一的文件名，打印出来的uid其实就是时间戳
        //client 是第一步中的 client
        client()
          .multipartUpload("xinshui/" + fileName, option.file, {
            progress: function (p) {
              //获取进度条的值
              // console.log(p)
              that.videoFlag = true;
              // console.log(file.percentage)
              var pro = p * 100;
              that.videoUploadPercent = pro.toFixed(0) * 1;
            },
          })
          .then((result) => {
            //下面是如果对返回结果再进行处理，根据项目需要
            // https://xinshuibucket.oss-cn-beijing.aliyuncs.com/1612356926822_9ac83937
            that.videoFlag = false;
            that.videoUploadPercent = 0;
            that.video_url =
              "https://xinshuibucket.oss-cn-beijing.aliyuncs.com/" +
              result.name;
          })
          .catch((err) => {
            console.log("err:", err);
            this.$message.error("视频上传失败，请重新上传！");
          });
      }
      multipartUpload();
    },

    // 视频上传成功
    handleVideoSuccess(res, file) {
      //获取上传图片地址
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      console.log(res);
      if (res.code == 200) {
        this.video_url = res.data.file_url;
      } else {
        this.$message.error("视频上传失败，请重新上传！");
      }
    },

    // 视频上传之前
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 5;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过5MB哦!");
        return false;
      }
    },

    // 视频上传进度
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      // console.log(file.percentage)
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },

    // 确定添加视频
    toUploadVideo() {
      var quill = this.$refs.myQuillEditor.quill;

      this.dialogVideo = false;

      // return false;
      console.log(this.select_index);
      quill.insertEmbed(this.select_index, "simpleVideo", {
        url: this.video_url,
        controls: "controls",
        width: this.video_width,
        height: this.video_height,
      });
    },
    // 取消
    clickCancel() {
      this.dialogVideo = false;
      this.video_url = "";
    },
    test() {
      // 记录此时的位置
      var quill = this.$refs.myQuillEditor.quill;
      this.select_index = quill.getSelection().index;
      this.video_url = "";

      // 展示视频弹框
      this.dialogVideo = true;
    },
    // 图片处理
    uploadSuccess(res) {
      console.log("成功");
      console.log(res.data.file_url, "res");
      var dt = {};
      dt.url = res.data.file_url;
      let quill = this.$refs.myQuillEditor.quill;

      console.log(quill);
      // 如果上传成功
      if (res.code === 200 && dt.url !== null) {
        console.log("插入图片");
        console.log(dt.url);

        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  dt.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", dt.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      // loading加载隐藏
      this.quillUpdateImg = false;
    },
    // 视频上传成功函数
    videoUploadSuccess(res) {
      var dt = {};
      dt.url = res.data.file_url;

      let quill = this.$refs.myQuillEditor.quill;
      if (res.code === 200 && dt.url !== null) {
        let length = quill.getSelection().index;
        quill.insertEmbed(length, "simpleVideo", {
          url: dt.url,
          controls: "controls",
          width: this.videoWidth + "px",
          height: this.videoHeight + "px",
        });

        quill.setSelection(length + 1);
      } else {
        this.$message.error("视频插入失败");
      }
    },
    //视频上传事件
    ClickVideo() {
      window.document.querySelector("#uploadFileVideo input").click();
    },
    // 关闭
    ClickClose() {
      document.querySelector("#inputVideoSize").style.display = "none";
    },

    // 上传失败钩子函数
    uploadError(res) {
      console.log("失败");
      console.log(res);
    },
    // 上传前钩子函数
    beforeUpload() {},
    change() {
      this.loading = false;
    },
    process() {
      this.loading = true;
    },

    //复制邀请链接
    copyURL() {
      this.$copyText(this.ruleForm.honored).then(
        (e) => {
          this.$message.success("复制成功");
        },
        (e) => {
          this.$message.error("复制失败");
        }
      );
    },
    // 图片上传格式
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/png" || "image/jpeg" || "image/bmp" || "image/gif";

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、PNG、bmp、gif格式!");
      }

      return isJPG;
    },
    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/index.php/api/v1_0_0.course/publish_course", {
              tid: this.tid,

              publish_data: {
                cid: this.ruleForm.region, //课程分类id
                is_pay: this.ruleForm.resource == "是" ? "2" : "1", //是否付费 1免费 2付费
                pay_type: this.ruleForm.charge == "余额" ? "2" : "1", //付费方式 1积分 2余额
                teacher_desc: this.ruleForm.teacherDesc, //讲师介绍
                title: this.ruleForm.name, //课程名称
                people_num: this.ruleForm.people_num
                  ? this.ruleForm.people_num
                  : 0,
                description: this.ruleForm.desc, //课程介绍
                content: this.ruleForm.content, //课程详情
                content_img: this.ruleForm.keImg, //课程详情图片
                cover: this.ruleForm.fengImg, //课程封面
                online_time: this.ruleForm.time[0], //直播时间
                end_time: this.ruleForm.time[1], //直播时间
                price: this.ruleForm.money * 100, //价格 int 如果是余额单位为分
                book_imgs: this.ruleForm.kejian, //课件
              },
            })
            .then((data) => {
              console.log(data);
              this.$message.success(data.msg);
              this.loading = false;
              this.$router.push({
                path: "/personal/content",
                query: {
                  tabs: "课程",
                },
              });
              return;
            })
            .catch((error) => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onSuccess(response, file, fileList) {
      console.log(response.data.file_url);
      this.ruleForm.fengImg = response.data.file_url;
      this.$refs.ruleForm.clearValidate("fengImg");
      this.rules.fengImg = "";
    },
    onSuccess1(response, file, fileList) {
      this.ruleForm.keImg = response.data.file_url;
      this.$refs.ruleForm.clearValidate("keImg");
      this.rules.keImg = "";
    },
    // 删除图片 触发验证
    handleRemove1(file, fileList) {
      // this.$refs.rules.img.validateField('img')
      this.ruleForm.fengImg = "";
      console.log(file, fileList);
      if (fileList.length < 1) {
        this.rules.fengImg = [
          {
            required: true,
            message: "请上传封面图片",
            trigger: "blur",
          },
        ];
      }
    },
    handleRemove2(file, fileList) {
      this.ruleForm.keImg = "";
      if (fileList.length < 1) {
        this.rules.keImg = [
          {
            required: true,
            message: "请上传课程图片",
            trigger: "blur",
          },
        ];
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onEditorReady(editor) {}, // 准备编辑器
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {}, // 内容改变事件
    //课件上传
    kejianOnSuccess(response, file, fileList) {
      console.log(response);
      this.ruleForm.kejian.push(response.data.file_url);
      this.$refs.ruleForm.clearValidate("kejian");
      this.rules.kejian = "";
    },
    handleRemove3(file, fileList) {
      this.ruleForm.kejian = [];
      if (fileList.length < 1) {
        this.rules.kejian = [
          {
            required: true,
            message: "请上传课件",
            trigger: "blur",
          },
        ];
      }
    },
    openTitle() {
      this.$confirm(
        "用户会员身份不同，所付费价格不同，不同身份会员，收益价格不同",
        "提示",
        {
          confirmButtonText: "知道了",
          cancelButtonText: "取消",
          showCancelButton: false,
          type: "warning",
        }
      )
        .then(() => {})
        .catch(() => {});
    },
  },
  watch: {
    "ruleForm.resource": {
      handler(value) {
        if (value == "是" && !this.editDisabled) {
          this.openTitle();
        }
      },
    },
  },
};
</script>
<style lang='less' scoped>
body {
  background-color: #f5f5f5;
}
#mobile {
  #keContent {
    /deep/.el-form-item__error {
      padding-top: 35px !important;
    }
  }

  /deep/.el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  /deep/.el-form-item__error {
    padding-top: 0;
  }

  /deep/.el-input {
    margin-top: 8px;
  }

  .release_bg {
    width: 100%;
    height: 180px;

    background: #f1f5f6;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    /* padding: 120px 0 50px; */
  }

  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    /* line-height: 44px; */
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .box {
    /* display: flex;
   flex: 1;
    justify-content: space-between;
    align-items: center; */

    input {
      width: 100%;
      height: 34px;
      background: #ffffff;
      border: 1px solid #e5e6ea;
      display: block;
      outline: none;
      padding-left: 10px;
      margin-bottom: 8px;
    }
  }
}

/deep/.el-date-editor .el-range__icon {
  line-height: 26px;
}
/deep/.el-date-editor .el-range-separator {
  line-height: 26px;
}
/deep/.el-upload--picture-card {
  width: 88px;
  height: 88px;
  border: 0;
}

.money {
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid #e5e6ea;
  padding: 0 10px;

  input {
    width: 76px;
    /* height: 34px; */
    background: #ffffff;
    outline: none;
    border: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #4f5764;
  }
}

span {
  display: inline-block;
  height: 34px;
  line-height: 34px;
  background: #ffffff;

  font-size: 14px;
  font-weight: 400;
  color: #89909d;
}
#inputVideoSize {
  width: 500px;
  height: 200px;
  background-color: #ccc;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display: none;

  .videoWidth,
  .videoHeight {
    display: flex;
    align-items: center;
    height: 34px;
    .videoText {
      display: inline-block;
      width: 100px;
    }
  }
  .btns {
    width: 100%;
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
/deep/.ql-tooltip ql-editing {
}
</style>

<style>
.ql-tooltip .ql-editing {
  position: absolute !important;
  top: 20px !important;
  left: 20px !important;
}
</style>
