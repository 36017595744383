<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!--    <video id="videoElement" controls autoplay muted width="600" height="450"></video>-->

    <Header :indexi="3"></Header>
    <!-- pc端 -->
    <!-- 课堂详情开课页面 -->
    <div v-if="show_pc" class="pc">
      <div class="classOpen">
        <!-- 课堂详情开课 左侧 页面 -->
        <div class="content">
          <div class="classOpen_left">
            <div class="broadcast">
              <!-- 老师信息 -->
              <div class="teacher">
                <div class="img">
                  <img :src="classOpenInfo.headimg" alt="" />
                </div>
                <div class="class_info">
                  <div class="date">
                    <span>{{ classOpenInfo.title }}</span
                    ><span>{{ classOpenInfo.cate_name }}</span>
                  </div>
                  <div class="taeacher_info">
                    <span>{{ classOpenInfo.description }}</span>
                  </div>
                  <div class="parameter">
                    <ul>
                      <li>
                        <span
                          ><img
                            src="../../assets/icon/学生@2x.png"
                            alt="" /></span
                        ><span> 学生数量:{{ classOpenInfo.people_count }}</span>
                      </li>
                      <li>
                        <span
                          ><img src="../../assets/icon/时间@2x.png" alt="" />
                        </span>
                        <span>
                          直播时间：{{ classOpenInfo.online_time }}
                          <!--                          <span v-if="classOpenInfo.end_time">-->
                          <!--                            ~ {{ classOpenInfo.end_time }}</span>-->
                        </span>
                      </li>

                      <!-- <li>
                        <span><img src="../../assets/icon/人数@2x.png" alt="" /></span><span>观看热度：{{ classOpenInfo.view_count }}</span>
                      </li> -->
                      <li>
                        <span
                          ><img
                            src="../../assets/icon/人数@2x.png"
                            alt="" /></span
                        ><span>直播间人数：{{ book_num }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- 收藏点赞 -->
              <div class="thumbs">
                <div
                  v-if="classOpenInfo.is_praise == 1"
                  @click="ClickThumbs"
                  style="
                    background-color: #1677ff;
                    color: #fff;
                    border: 1px solid #1677ff;
                  "
                >
                  <img src="../../assets/icon/编组3@2x.png" alt="" />
                  {{ classOpenInfo.praise_count }}
                </div>
                <div v-if="classOpenInfo.is_praise == 2" @click="ClickThumbs">
                  点赞
                  <img src="../../assets/icon/编组2@2x.png" alt="" />
                  {{ classOpenInfo.praise_count }}
                </div>
                <div
                  v-if="classOpenInfo.is_collect == 1"
                  @click="ClickCollection"
                  style="border: 1px solid #98c3ff; color: #1677ff"
                >
                  <img src="../../assets/icon/编组@2x.png" alt="" />已收藏
                </div>
                <div
                  v-if="classOpenInfo.is_collect == 2"
                  @click="ClickCollection"
                >
                  <img src="../../assets/icon/编组备份@2x(1).png" alt="" />收藏
                </div>
              </div>
            </div>

            <!-- 开播之后的轮播图 -->
            <el-carousel
              :interval="5000"
              :autoplay="false"
              trigger="click"
              :arrow="arrow"
              height="580px"
              v-if="(classOpenInfo.is_online = 2)"
              @change="
                (pre, next) => {
                  booksChange(pre, next);
                }
              "
              :initial-index="book_current_index"
              ref="book"
            >
              <el-carousel-item
                v-if="classOpenInfo.book_imgs"
                v-for="(item, index) in classOpenInfo.book_imgs"
                :key="index"
              >
                <div style="width: 100%; height: 100%">
                  <img
                    style="display: inline-block; width: 100%; height: 100%"
                    :src="item"
                    alt=""
                    fit="contain"
                  />
                </div>
                <!-- <el-image style="width: 100%" :src="item" fit="contain">
                </el-image> -->
              </el-carousel-item>
              <div
                style="width: 100%; height: 100%; overflow: hidden"
                v-if="!classOpenInfo.book_imgs"
              >
                <img
                  width="100%"
                  height="100%"
                  :src="classOpenInfo.cover"
                  alt=""
                  fit="contain"
                />
              </div>
              <div class="online">{{ classOpenInfo.is_online_str }}</div>
              <!-- <video
                id="videoElement"
                style="z-index: 10; position: absolute; right: 0; top: 1%"
                controls
                muted
                autoplay
                height="150px"
                v-if="classOpenInfo.is_online == 3"
              ></video> -->

              <!--        视频播放测试-->
              <div id="video-section" class="text-center">
                <div id="remote-video-wrap"></div>
              </div>

              <!-- 回放 -->
              <div v-if="classOpenInfo.media_url">
                <video-player
                  style="
                    z-index: 10;
                    position: absolute;
                    right: 0;
                    width: 250px;
                    top: 1%;
                  "
                  ref="videoPlayer"
                  class="video-player vjs-custom-skin"
                  :playsinline="true"
                  :options="playerOptions"
                ></video-player>
              </div>
            </el-carousel>

            <!-- 没开播 -->
            <div class="image" v-if="classOpenInfo.is_online !== 2">
              <img :src="classOpenInfo.cover" alt="" />
              <div>{{ classOpenInfo.is_online_str }}</div>
            </div>
          </div>

          <!-- 课堂详情开课 右侧 页面 -->
          <div class="classOpen_right">
            <!-- 互动区域 -->
            <div class="interaction">
              <div class="comment">
                <el-tabs
                  v-model="activeName"
                  @tab-click="tabClick"
                  v-loading="loading"
                >
                  <el-tab-pane label="嘉宾区" name="first">
                    <div class="commentlist">
                      <div class="guest">
                        <ul>
                          <li v-if="!guestList.length">还没有嘉宾...</li>
                          <li v-for="(item, index) in guestList" :key="index">
                            <div class="img">
                              <img :src="item.headimg" alt="" />
                            </div>
                            <div class="info">
                              <p>{{ item.nickname }}</p>
                              <!--                            <p>介绍</p>-->
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </el-tab-pane>
                  <!--pc端讨论区-->
                  <el-tab-pane label="讨论区" name="second">
                    <!-- 发言模块 -->
                    <div class="commentlist" style="">
                      <div
                        style="
                          font-size: 14px;
                          color: #9c9cab;
                          position: absolute;
                          top: 50px;
                          left: 10%;
                        "
                        v-if="words.length < 1"
                      >
                        <img
                          src="@/assets/image/缺省图.png"
                          style="width: 40%"
                        />
                        <p style="margin-top: 15px">暂无消息</p>
                      </div>
                      <ul
                        @scroll.passive="getScroll($event)"
                        id="roomScroll"
                        ref="roomScroll"
                      >
                        <li
                          v-for="(item, index) in words"
                          :key="index"
                          :class="{ left: item.user_id == user_id }"
                          id="ScrollLi"
                          style=""
                        >
                          <!-- 系统消息 -->
                          <div
                            v-if="item.user_id == 0"
                            :title="item.content"
                            style=""
                            class="system_messages"
                          >
                            {{ item.content }}
                          </div>
                          <div class="commentlist_top" v-if="item.user_id != 0">
                            <div style="min-width: 40px">
                              <vue-hover-mask v-if="item.user_id != user_id">
                                <img
                                  :src="item.headimg"
                                  alt=""
                                  v-if="item.user_id != 0"
                                />
                                <template v-slot:action>
                                  <span
                                    @click="toMoney(item)"
                                    class="span"
                                    style="text-align: center"
                                    v-if="
                                      item.user_type == 3 || item.user_type == 2
                                    "
                                  >
                                    <img
                                      src="../../assets/icon/打赏@2x.png"
                                      alt=""
                                      style="width: 14px; height: 14px"
                                    />
                                    <span>打赏</span>
                                  </span>
                                  <span
                                    @click="toNoword(item)"
                                    class="span"
                                    style="text-align: center"
                                    v-if="
                                      item.user_type == 1 &&
                                      (classOpenInfo.consumer_type == 3 ||
                                        classOpenInfo.consumer_type == 2)
                                    "
                                  >
                                    <img
                                      src="../../assets/icon/禁言@2.2x.png"
                                      alt=""
                                      style="width: 14px; height: 14px"
                                    />
                                    <span>禁言</span>
                                  </span>
                                </template>
                              </vue-hover-mask>

                              <img
                                :src="item.headimg"
                                alt=""
                                v-if="item.user_id == user_id"
                              />
                            </div>
                            <div style="">
                              <p>
                                <span
                                  style="background: #ffa200"
                                  v-if="item.user_type > 1"
                                  >{{ item.user_type_str }}</span
                                >
                                <span v-if="item.user_type == 1">{{
                                  item.user_type_str
                                }}</span>
                                {{ item.nickname }}
                              </p>
                              <!-- 表情文字 -->
                              <p
                                v-if="item.type == 'text'"
                                v-html="
                                  item.content.replace(
                                    /\#[\u4E00-\u9FA5]{1,3}\;/gi,
                                    emotion
                                  )
                                "
                                class="leftP"
                                style="
                                  width: 100%;
                                  white-space: normal;
                                  word-break: break-all;
                                  overflow: hidden;
                                  text-align: left !important;
                                "
                              ></p>
                              <!-- 图片 -->
                              <div
                                v-if="item.type == 'picture'"
                                style="width: 130px"
                              >
                                <img
                                  :src="item.content"
                                  style="
                                    height: 100%;
                                    width: 100%;
                                    margin-top: 8px;
                                  "
                                  @click="dialogClick(item.content)"
                                />
                              </div>
                              <!-- 视频 -->
                              <div
                                v-if="item.type == 'video'"
                                style="
                                  margin-top: 8px;
                                  position: relative;
                                  cursor: pointer;
                                "
                              >
                                <video
                                  :src="item.content"
                                  style="width: 130px"
                                  controls
                                ></video>
                              </div>
                              <!-- 语音消息 -->
                              <div
                                class="audio__wrap"
                                v-if="item.type == 'audio'"
                              >
                                <audio
                                  controls
                                  :ref="'audioPlayer' + item.id + activeName"
                                  style="display: none"
                                >
                                  <source
                                    :src="item.content"
                                    type="audio/mp3"
                                  />
                                </audio>
                                <!-- :class="{ direction: item.user_id ==  }" -->
                                <div
                                  class="right_self__audio"
                                  v-if="item.user_id == user_id"
                                >
                                  <div
                                    class="audio__trigger"
                                    @click="
                                      playAudioHandler(item.id, item.duration)
                                    "
                                  >
                                    <div
                                      :class="{
                                        'wifi-symbol': true,
                                        'wifi-symbol--avtive':
                                          isPlaying && item.id == isPlayingId,
                                      }"
                                    >
                                      <div class="wifi-circle first"></div>
                                      <div class="wifi-circle second"></div>
                                      <div class="wifi-circle third"></div>
                                    </div>
                                  </div>
                                  <el-badge
                                    :is-dot="false"
                                    v-if="isDot(item.id)"
                                  >
                                    {{ item.duration }}</el-badge
                                  >
                                  <!--                                  <el-badge-->
                                  <!--                                    :is-dot="false"-->
                                  <!--                                    v-if="!isDot(item.id)"-->
                                  <!--                                  >-->
                                  <!--                                    {{ item.duration }}</el-badge-->
                                  <!--                                  >-->
                                </div>

                                <div
                                  class="self__audio"
                                  v-if="item.user_id != user_id"
                                >
                                  <div
                                    class="audio__trigger"
                                    @click="
                                      playAudioHandler(item.id, item.duration)
                                    "
                                  >
                                    <div
                                      :class="{
                                        'wifi-symbol': true,
                                        'wifi-symbol--avtive':
                                          isPlaying && item.id == isPlayingId,
                                      }"
                                    >
                                      <div class="wifi-circle first"></div>
                                      <div class="wifi-circle second"></div>
                                      <div class="wifi-circle third"></div>
                                    </div>
                                  </div>
                                  <el-badge
                                    :is-dot="true"
                                    v-if="isDot(item.id)"
                                  >
                                    {{ item.duration }}</el-badge
                                  >
                                  <el-badge
                                    :is-dot="false"
                                    v-if="!isDot(item.id)"
                                  >
                                    {{ item.duration }}</el-badge
                                  >
                                </div>
                              </div>

                              <!-- <div
                                v-if="item.type == 'audio'"
                                style="margin-top: 6px"
                              >
                                <audio
                                  controls
                                  style="width: 160px; height: 30px"
                                >
                                  <source
                                    :src="item.content"
                                    type="audio/mp3"
                                  />
                                </audio>
                              </div> -->
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <!-- 发表 评论区域与 -->
                    <div class="comment_box">
                      <div class="funct">
                        <ul>
                          <li>
                            <el-popover
                              placement="top-start"
                              width="100%"
                              trigger="click"
                            >
                              <emotion
                                @emotion="handleEmotion"
                                :height="200"
                              ></emotion>
                              <img
                                :src="require('../../assets/icon/表情@2x.png')"
                                alt=""
                                slot="reference"
                                style="width: 21px; height: 21px"
                              />
                            </el-popover>
                          </li>

                          <li>
                            <el-popover
                              ref="popover"
                              placement="top-start"
                              width="50"
                              trigger="focus"
                            >
                              <span
                                class="span"
                                style="
                                  display: flex;
                                  align-items: center;
                                  cursor: pointer;
                                  text-align: center;
                                "
                              >
                                <span>按住说话{{ num }}s</span>
                              </span>
                            </el-popover>
                            <img
                              :src="require('../../assets/icon/语音@2x.png')"
                              alt=""
                              style="width: 21px; height: 21px"
                              v-popover:popover
                              @mousedown.prevent="mouseStart"
                              @mouseup.prevent="mouseEnd"
                            />
                          </li>

                          <li>
                            <el-upload
                              class="avatar-uploader"
                              :action="uploadUrl"
                              :show-file-list="false"
                              :on-progress="onProgress"
                              :before-upload="beforeVideoUpload"
                              :on-success="videoSuccess"
                              :disabled="classOpenInfo.is_online == 3"
                            >
                              <img
                                :src="require('../../assets/icon/视频@2x.png')"
                                alt=""
                                style="width: 21px; height: 21px"
                              />
                            </el-upload>
                          </li>

                          <li>
                            <el-upload
                              class="avatar-uploader"
                              :action="uploadUrl"
                              :show-file-list="false"
                              :on-success="handleAvatarSuccess"
                              :on-progress="onProgress"
                              :before-upload="beforeAvatarUpload"
                              :disabled="classOpenInfo.is_online == 3"
                            >
                              <img
                                :src="require('../../assets/icon/图片@2x.png')"
                                alt=""
                                style="width: 21px; height: 21px"
                              />
                            </el-upload>
                          </li>
                        </ul>

                        <div
                          class="forbidden"
                          v-if="
                            classOpenInfo.consumer_type == 3 ||
                            classOpenInfo.consumer_type == 2
                          "
                        >
                          <!-- 禁言列表 -->
                          <div class="forbidden_list">
                            <div>
                              <el-dropdown
                                trigger="click"
                                :hide-on-click="false"
                                placement="top"
                              >
                                <span
                                  class="el-dropdown-link"
                                  @click="getBannedList"
                                  style="margin-top: 4px"
                                >
                                  <div class="image">
                                    <img
                                      src="../../assets/icon/列表@2x.png"
                                      alt=""
                                    />
                                  </div>
                                  禁言列表
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item
                                    style="
                                      display: flex;
                                      justify-content: space-between;
                                      padding: 0 10px;
                                    "
                                    v-for="(item, index) in forbiddenlist"
                                    :key="index"
                                  >
                                    <div
                                      class="people"
                                      style="display: flex; align-items: center"
                                    >
                                      <div
                                        style="
                                          display: inline-block;
                                          width: 38px;
                                          height: 38px;
                                          border-radius: 38px;
                                          overflow: hidden;
                                        "
                                      >
                                        <img
                                          :src="item.headimg"
                                          alt=""
                                          style="
                                            display: inline-block;
                                            width: 100%;
                                            height: 100%;
                                            background-color: #fff;
                                          "
                                        />
                                      </div>
                                      <span style="margin: 0 30px 0 20px">{{
                                        item.nickname
                                      }}</span>
                                    </div>
                                    <div
                                      style="
                                        display: flex;
                                        align-items: center;
                                        flex-direction: column;
                                        cursor: pointer;
                                      "
                                    >
                                      <div
                                        style="
                                          width: 14px;
                                          height: 14px;
                                          margin-bottom: 6px;
                                        "
                                      >
                                        <img
                                          src="../../assets/icon/禁言@2x.png"
                                          alt=""
                                          style="
                                            display: inline-block;
                                            width: 100%;
                                            height: 100%;
                                          "
                                        />
                                      </div>
                                      <p
                                        style="
                                          font-size: 14px;
                                          font-weight: 400;
                                          color: #7f868d;
                                        "
                                        @click="toNoword(item)"
                                      >
                                        解禁
                                      </p>
                                    </div>
                                  </el-dropdown-item>
                                </el-dropdown-menu>
                              </el-dropdown>
                            </div>
                          </div>
                          <div
                            class="forbidden_all"
                            style="
                              display: flex;
                              justify-content: start;
                              align-items: center;
                              cursor: pointer;
                            "
                            @click="allNOword"
                          >
                            <div
                              style="
                                width: 14px;
                                height: 14px;
                                margin-right: 5px;
                              "
                            >
                              <img
                                src="../../assets/icon/解出语音@2x.png"
                                alt=""
                                style="
                                  bisplay: inline-block;
                                  width: 100%;
                                  height: 100%;
                                "
                              />
                            </div>
                            <span>{{
                              classOpenInfo.is_say == 1
                                ? "全员禁言"
                                : "全员解禁"
                            }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="input">
                        <el-input
                          placeholder="说点什么"
                          v-model="input"
                          show-word-limit
                          @keyup.enter.native="enterSearchMember"
                        >
                        </el-input>
                        <div class="btn">
                          <button
                            @click="toSendText"
                            :class="{
                              btnColor: classOpenInfo.is_online == 2,
                            }"
                          >
                            评论
                          </button>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="讲课区" name="third">
                    <div class="commentlist">
                      <!-- <ul class="teacher_area">
                        <li v-for="(item, index) in wallWords" :key="index" :class="{ left: item.user_id == user_id }">
                          <div class="commentlist_top">
                            <div>
                              <vue-hover-mask @click="handleClick">
                                <img :src="item.headimg" alt="" />
                                <template v-slot:action>
                                  <span class="span" style="margin-right: 5px">
                                    <span><img src="../../assets/icon/打赏@2x.png" alt="" /></span>
                                    <span>打赏</span>
                                  </span>
                                  <span class="span">
                                    <span><img src="../../assets/icon/禁言语音@2x.png" alt="" /></span>
                                    <span>解禁</span>
                                  </span>
                                </template>
                              </vue-hover-mask>
                            </div>
                            <div>
                              <p>
                                <span>{{item.user_type_str}}</span>
                                {{ item.nickname }}
                              </p>
                              <p>{{ item.content }}</p>
                            </div>
                          </div>
                        </li>
                      </ul> -->
                      <div
                        style="
                          font-size: 14px;
                          color: #9c9cab;
                          position: absolute;
                          top: 50px;
                          left: 10%;
                        "
                        v-if="words2.length < 1"
                      >
                        <img
                          src="@/assets/image/缺省图.png"
                          style="width: 40%"
                        />
                        <p style="margin-top: 15px">暂无消息</p>
                      </div>
                      <ul
                        @scroll.passive="getScroll($event)"
                        class="teacher_area"
                      >
                        <li
                          v-for="(item, index) in words2"
                          :key="index"
                          :class="{ left: item.user_id == user_id }"
                          id="ScrollLi2"
                        >
                          <!-- 系统消息 -->
                          <div
                            v-if="item.user_id == 0"
                            :title="item.content"
                            style=""
                            class="system_messages"
                          >
                            {{ item.content }}
                          </div>
                          <div class="commentlist_top" v-if="item.user_id != 0">
                            <div style="min-width: 40px">
                              <vue-hover-mask v-if="item.user_id != user_id">
                                <img
                                  :src="item.headimg"
                                  alt=""
                                  v-if="item.user_id != 0"
                                />
                                <template v-slot:action>
                                  <span
                                    @click="toMoney(item)"
                                    class="span"
                                    style="text-align: center"
                                    v-if="
                                      item.user_type == 3 || item.user_type == 2
                                    "
                                  >
                                    <img
                                      src="../../assets/icon/打赏@2x.png"
                                      alt=""
                                      style="width: 14px; height: 14px"
                                    />
                                    <span>打赏</span>
                                  </span>
                                  <span
                                    @click="toNoword(item)"
                                    class="span"
                                    style="text-align: center"
                                    v-if="
                                      item.user_type == 1 &&
                                      (classOpenInfo.consumer_type == 3 ||
                                        classOpenInfo.consumer_type == 2)
                                    "
                                  >
                                    <img
                                      src="../../assets/icon/禁言@2.2x.png"
                                      alt=""
                                      style="width: 14px; height: 14px"
                                    />
                                    <span>禁言</span>
                                  </span>
                                </template>
                              </vue-hover-mask>

                              <img
                                :src="item.headimg"
                                alt=""
                                v-if="item.user_id == user_id"
                              />
                            </div>
                            <div>
                              <!-- 文字消息 -->
                              <p>
                                <span
                                  style="background: #ffa200"
                                  v-if="item.user_type > 1"
                                  >{{ item.user_type_str }}</span
                                >
                                <span v-if="item.user_type == 1">{{
                                  item.user_type_str
                                }}</span>
                                {{ item.nickname }}
                              </p>
                              <!-- 表情 -->
                              <p
                                v-if="item.type == 'text'"
                                v-html="
                                  item.content.replace(
                                    /\#[\u4E00-\u9FA5]{1,3}\;/gi,
                                    emotion
                                  )
                                "
                                style="
                                  width: 100%;
                                  white-space: normal;
                                  word-break: break-all;
                                  overflow: hidden;
                                  text-align: left !important;
                                "
                              ></p>
                              <!-- 图片 -->
                              <div
                                v-if="item.type == 'picture'"
                                style="width: 130px"
                              >
                                <img
                                  :src="item.content"
                                  style="
                                    height: 100%;
                                    width: 100%;
                                    margin-top: 8px;
                                  "
                                  @click="dialogClick(item.content)"
                                />
                              </div>
                              <!-- 视频 -->
                              <div
                                v-if="item.type == 'video'"
                                style="
                                  margin-top: 8px;
                                  position: relative;
                                  cursor: pointer;
                                "
                              >
                                <video
                                  :src="item.content"
                                  style="width: 130px"
                                  controls
                                ></video>
                              </div>
                              <!-- 语音消息 -->
                              <!-- 语音消息 -->
                              <div
                                class="audio__wrap"
                                v-if="item.type == 'audio'"
                              >
                                <audio
                                  controls
                                  :ref="'audioPlayer' + item.id + activeName"
                                  style="display: none"
                                >
                                  <source
                                    :src="item.content"
                                    type="audio/mp3"
                                  />
                                </audio>
                                <div class="self__audio">
                                  <div
                                    class="audio__trigger"
                                    @click="
                                      playAudioHandler(item.id, item.duration)
                                    "
                                  >
                                    <div
                                      :class="{
                                        'wifi-symbol': true,
                                        'wifi-symbol--avtive':
                                          isPlaying && item.id == isPlayingId,
                                      }"
                                    >
                                      <div class="wifi-circle first"></div>
                                      <div class="wifi-circle second"></div>
                                      <div class="wifi-circle third"></div>
                                    </div>
                                  </div>
                                  <el-badge
                                    :is-dot="true"
                                    v-if="isDot(item.id)"
                                  >
                                    {{ item.duration }}</el-badge
                                  >
                                  <el-badge
                                    :is-dot="false"
                                    v-if="!isDot(item.id)"
                                  >
                                    {{ item.duration }}</el-badge
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible">
        <div style="width: 100%; overflow: hidden">
          <img width="100%" :src="dialogVisibleURL" alt="" />
        </div>
      </el-dialog>
      <!-- 打赏 -->
      <el-dialog title="选择打赏金额" :visible.sync="rechargeDialog">
        <!-- 打赏金额 -->
        <div class="recharge">
          <ul>
            <li
              v-for="item in rechargeList"
              :key="item.id"
              @click="ClickRechargeList(item)"
              :class="{ year_bg: rechargeId == item.id }"
            >
              {{ item.money_str }}
            </li>
          </ul>
          <div class="goRecharge" @click="goRecharge">打 赏</div>
        </div>
      </el-dialog>
      <el-dialog
        style="margin-top: 22vh"
        :visible.sync="centerDialogVisible"
        :show-close="false"
        width="9%"
        :close-on-click-modal="false"
      >
        {{ openContent }}
      </el-dialog>
      <Footer></Footer>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="classOpen_mb">
        <div class="classOpen">
          <!-- 课堂详情开课 左侧 页面 -->
          <div class="classOpen_left">
            <!-- 开播之后的轮播图 -->
            <el-carousel
              :interval="5000"
              :autoplay="false"
              trigger="click"
              :arrow="arrow"
              webkit-playsinline="true"
              height="188px"
              v-if="classOpenInfo.is_online != 1"
              @change="
                (pre, next) => {
                  booksChange(pre, next);
                }
              "
              :initial-index="book_current_index"
              ref="book"
            >
              <el-carousel-item
                v-if="classOpenInfo.book_imgs"
                v-for="(item, index) in classOpenInfo.book_imgs"
                :key="index"
              >
                <img
                  style="display: inline-block; width: 100%; height: 100%"
                  :src="item"
                  alt=""
                />
                <!-- <el-image style="width: 100%" :src="item" fit="contain">
                </el-image> -->
              </el-carousel-item>
              <div
                style="width: 100%; height: 100%; overflow: hidden"
                v-if="!classOpenInfo.book_imgs"
              >
                <img
                  width="100%"
                  height="100%"
                  :src="classOpenInfo.cover"
                  alt=""
                  fit="contain"
                />
              </div>
              <div class="online">{{ classOpenInfo.is_online_str }}</div>
              <!--              <video-->
              <!--                v-if="classOpenInfo.is_online == 2"-->
              <!--                id="videoElement"-->
              <!--                style="z-index: 100;position: fixed;right: 0%;top: 10%;width: 0%;"-->
              <!--                controls-->
              <!--                autoplay-->
              <!--                height="100px"-->
              <!--                playsinline="true"-->
              <!--                webkit-playsinline="true"-->
              <!--                x5-video-player-type="h5-page"-->
              <!--                x5-video-orientation="h5"-->
              <!--                x5-video-player-fullscreen="true"-->
              <!--                x-webkit-airplay="true"-->
              <!--                x5-video-ignore-metadata="true"-->
              <!--              ></video>-->
              <!--              <video-player-->
              <!--                v-if="classOpenInfo.pull_url"-->
              <!--                style="-->
              <!--                  z-index: 10;-->
              <!--                  position: absolute;-->
              <!--                  right: 1%;-->
              <!--                  width: 180px;-->
              <!--                  top: 1%;-->
              <!--                "-->
              <!--                ref="videoPlayer"-->
              <!--                class="video-player vjs-custom-skin"-->
              <!--                :playsinline="true"-->
              <!--                :options="playerOptions"-->
              <!--              />-->
              <div id="video-section" class="text-center">
                <div id="remote-video-wrap"></div>
              </div>

              <!-- 回放 -->
              <div v-if="classOpenInfo.media_url">
                <video-player
                  style="
                    z-index: 10;
                    position: absolute;
                    right: 0;
                    width: 250px;
                    top: 1%;
                  "
                  ref="videoPlayer"
                  class="video-player vjs-custom-skin"
                  :playsinline="true"
                  :options="playerOptions"
                ></video-player>
              </div>
            </el-carousel>

            <!-- 没开播 -->
            <div class="image" v-if="classOpenInfo.is_online == 1">
              <img :src="classOpenInfo.cover" alt="" />
              <div>{{ classOpenInfo.is_online_str }}</div>
            </div>
          </div>
          <!-- 信息 -->
          <div>
            <div class="class_info">
              <div class="date">
                <span>{{ classOpenInfo.title }}</span
                ><span>{{ classOpenInfo.cate_name }}</span>
                <span>{{ classOpenInfo.description }}</span>
              </div>
              <div class="taeacher_info"></div>
              <div class="parameter">
                <ul>
                  <li>
                    <span
                      ><img src="../../assets/icon/学生@2x.png" alt="" /></span
                    ><span> 学生数量：{{ classOpenInfo.people_count }}</span>
                    <span
                      ><img src="../../assets/icon/人数@2x.png" alt="" /></span
                    ><span>直播间人数：{{ book_num }}</span>
                  </li>
                  <li
                    style="
                      width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    <span
                      ><img
                        src="../../assets/icon/时间@2x.png"
                        alt=""
                        style="width: 14px"
                      />
                    </span>

                    <span>
                      直播时间：{{ classOpenInfo.online_time }}
                      <span v-if="classOpenInfo.end_time">
                        ~ {{ classOpenInfo.end_time }}</span
                      >
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 课堂详情开课 右侧 页面 -->
            <div class="classOpen_right">
              <!-- 互动区域 -->
              <div class="interaction">
                <div class="comment">
                  <el-tabs
                    v-model="activeName"
                    @tab-click="tabClick"
                    v-loading="loading"
                  >
                    <el-tab-pane label="嘉宾区" name="first">
                      <div class="commentlist">
                        <div class="guest">
                          <ul>
                            <li v-if="!guestList.length">还没有嘉宾...</li>
                            <li v-for="(item, index) in guestList" :key="index">
                              <div class="img">
                                <img :src="item.headimg" alt="" />
                              </div>
                              <div class="info">
                                <p>{{ item.nickname }}</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="讨论区" name="second">
                      <!-- 发言模块 -->
                      <div class="commentlist" style="">
                        <div class="noMessage">
                          <div
                            style="
                              font-size: 14px;
                              color: #9c9cab;

                              height: 160px;
                            "
                            v-if="words.length < 1"
                          >
                            <img
                              src="@/assets/image/缺省图.png"
                              style="width: 20%"
                            />
                            <p style="margin-top: 15px">暂无消息</p>
                          </div>
                          <div class="thumbs">
                            <div
                              v-if="classOpenInfo.is_praise == 1"
                              @click="ClickThumbs"
                              style="
                                background-color: #1677ff;
                                color: #fff;
                                border: 1px solid #1677ff;
                              "
                            >
                              <img
                                src="../../assets/icon/编组3@2x.png"
                                alt=""
                              />
                              {{ classOpenInfo.praise_count }}
                            </div>
                            <div
                              v-if="classOpenInfo.is_praise == 2"
                              @click="ClickThumbs"
                            >
                              点赞
                              <img
                                src="../../assets/icon/编组2@2x.png"
                                alt=""
                              />
                              {{ classOpenInfo.praise_count }}
                            </div>
                            <div
                              v-if="classOpenInfo.is_collect == 1"
                              @click="ClickCollection"
                              style="border: 1px solid #98c3ff; color: #1677ff"
                            >
                              <img
                                src="../../assets/icon/编组@2x.png"
                                alt=""
                              />已收藏
                            </div>
                            <div
                              v-if="classOpenInfo.is_collect == 2"
                              @click="ClickCollection"
                            >
                              <img
                                src="../../assets/icon/编组备份@2x(1).png"
                                alt=""
                              />收藏
                            </div>
                          </div>
                        </div>
                        <ul
                          class="komz"
                          @scroll.passive="getScroll($event)"
                          id="roomScroll"
                          ref="roomScroll"
                          v-if="words.length >= 1"
                        >
                          <li
                            v-for="(item, index) in words"
                            :key="index"
                            :class="{ left: item.user_id == user_id }"
                            id="ScrollLi"
                            style=""
                          >
                            <!-- 系统消息 -->
                            <div
                              v-if="item.user_id == 0"
                              :title="item.content"
                              style=""
                              class="system_messages"
                            >
                              {{ item.content }}
                            </div>
                            <div
                              class="commentlist_top"
                              v-if="item.user_id != 0"
                            >
                              <div style="min-width: 40px">
                                <vue-hover-mask
                                  @click="handleClick(item, index)"
                                  v-if="item.user_id != user_id"
                                >
                                  <img
                                    :src="item.headimg"
                                    alt=""
                                    v-if="item.user_id != 0"
                                  />
                                  <template v-slot:action>
                                    <span
                                      class="span"
                                      style="text-align: center"
                                      v-if="
                                        item.user_type == 3 ||
                                        item.user_type == 2
                                      "
                                    >
                                      <img
                                        src="../../assets/icon/打赏@2x.png"
                                        alt=""
                                        style="width: 14px; height: 14px"
                                      />
                                      <span>打赏</span>
                                    </span>
                                    <span
                                      class="span"
                                      style="text-align: center"
                                      v-if="
                                        item.user_type == 1 &&
                                        (classOpenInfo.consumer_type == 3 ||
                                          classOpenInfo.consumer_type == 2)
                                      "
                                    >
                                      <img
                                        src="../../assets/icon/禁言@2.2x.png"
                                        alt=""
                                        style="width: 14px; height: 14px"
                                      />
                                      <span>禁言</span>
                                    </span>
                                  </template>
                                </vue-hover-mask>

                                <img
                                  :src="item.headimg"
                                  alt=""
                                  v-if="item.user_id == user_id"
                                />
                              </div>
                              <div style="">
                                <p>
                                  <span>{{ item.user_type_str }}</span>
                                  {{ item.nickname }}
                                </p>
                                <!-- 表情文字 -->
                                <p
                                  v-if="item.type == 'text'"
                                  v-html="
                                    item.content.replace(
                                      /\#[\u4E00-\u9FA5]{1,3}\;/gi,
                                      emotion
                                    )
                                  "
                                  class="leftP"
                                  style="
                                    width: 100%;
                                    white-space: normal;
                                    word-break: break-all;
                                    overflow: hidden;
                                    text-align: left !important;
                                  "
                                ></p>
                                <!-- 图片 -->
                                <div
                                  v-if="item.type == 'picture'"
                                  style="width: 130px"
                                >
                                  <img
                                    :src="item.content"
                                    style="
                                      height: 100%;
                                      width: 100%;
                                      margin-top: 8px;
                                    "
                                    @click="dialogClick(item.content)"
                                  />
                                </div>
                                <!-- 视频 -->
                                <div
                                  v-if="item.type == 'video'"
                                  style="
                                    margin-top: 8px;
                                    position: relative;
                                    cursor: pointer;
                                  "
                                >
                                  <video
                                    :src="item.content"
                                    style="width: 180px; height: 80px"
                                    controls
                                  ></video>
                                </div>
                                <!-- 语音消息 -->
                                <!-- 语音消息 -->
                                <div
                                  class="audio__wrap"
                                  v-if="item.type == 'audio'"
                                >
                                  <audio
                                    controls
                                    :ref="'audioPlayer' + item.id + activeName"
                                    style="display: none"
                                  >
                                    <source
                                      :src="item.content"
                                      type="audio/mp3"
                                    />
                                  </audio>

                                  <div
                                    class="right_self__audio"
                                    v-if="item.user_id == user_id"
                                  >
                                    <div
                                      class="audio__trigger"
                                      @click="
                                        playAudioHandler(item.id, item.duration)
                                      "
                                    >
                                      <div
                                        :class="{
                                          'wifi-symbol': true,
                                          'wifi-symbol--avtive':
                                            isPlaying && item.id == isPlayingId,
                                        }"
                                      >
                                        <div class="wifi-circle first"></div>
                                        <div class="wifi-circle second"></div>
                                        <div class="wifi-circle third"></div>
                                      </div>
                                    </div>
                                    <el-badge
                                      :is-dot="false"
                                      v-if="isDot(item.id)"
                                    >
                                      {{ item.duration }}</el-badge
                                    >
                                  </div>
                                  <div
                                    class="self__audio"
                                    v-if="item.user_id != user_id"
                                  >
                                    <div
                                      class="audio__trigger"
                                      @click="
                                        playAudioHandler(item.id, item.duration)
                                      "
                                    >
                                      <div
                                        :class="{
                                          'wifi-symbol': true,
                                          'wifi-symbol--avtive':
                                            isPlaying && item.id == isPlayingId,
                                        }"
                                      >
                                        <div class="wifi-circle first"></div>
                                        <div class="wifi-circle second"></div>
                                        <div class="wifi-circle third"></div>
                                      </div>
                                    </div>
                                    <el-badge
                                      :is-dot="true"
                                      v-if="isDot(item.id)"
                                    >
                                      {{ item.duration }}</el-badge
                                    >
                                    <el-badge
                                      :is-dot="false"
                                      v-if="!isDot(item.id)"
                                    >
                                      {{ item.duration }}</el-badge
                                    >
                                  </div>
                                </div>
                                <!-- <div
                                v-if="item.type == 'audio'"
                                style="margin-top: 6px"
                              >

                                <audio
                                  controls
                                  style="width: 160px; height: 30px"
                                >
                                  <source
                                    :src="item.content"
                                    type="audio/mp3"
                                  />
                                </audio>
                              </div> -->
                              </div>
                            </div>
                          </li>
                          <!-- 收藏点赞 -->
                          <div class="thumbs">
                            <div
                              v-if="classOpenInfo.is_praise == 1"
                              @click="ClickThumbs"
                              style="
                                background-color: #1677ff;
                                color: #fff;
                                border: 1px solid #1677ff;
                              "
                            >
                              <img
                                src="../../assets/icon/编组3@2x.png"
                                alt=""
                              />
                              {{ classOpenInfo.praise_count }}
                            </div>
                            <div
                              v-if="classOpenInfo.is_praise == 2"
                              @click="ClickThumbs"
                            >
                              点赞
                              <img
                                src="../../assets/icon/编组2@2x.png"
                                alt=""
                              />
                              {{ classOpenInfo.praise_count }}
                            </div>
                            <div
                              v-if="classOpenInfo.is_collect == 1"
                              @click="ClickCollection"
                              style="border: 1px solid #98c3ff; color: #1677ff"
                            >
                              <img
                                src="../../assets/icon/编组@2x.png"
                                alt=""
                              />已收藏
                            </div>
                            <div
                              v-if="classOpenInfo.is_collect == 2"
                              @click="ClickCollection"
                            >
                              <img
                                src="../../assets/icon/编组备份@2x(1).png"
                                alt=""
                              />收藏
                            </div>
                          </div>
                        </ul>

                        <!-- 发表 评论区域与 -->
                        <div class="comment_box">
                          <div class="input">
                            <el-input
                              placeholder="说点什么"
                              v-model="input"
                              show-word-limit
                            >
                            </el-input>
                            <div class="btn">
                              <button
                                @click="toSendText"
                                :class="{
                                  btnColor: classOpenInfo.is_online == '直播中',
                                }"
                              >
                                评论
                              </button>
                            </div>
                          </div>
                          <div class="funct">
                            <ul>
                              <li>
                                <el-popover
                                  placement="top-start"
                                  width="100%"
                                  trigger="click"
                                >
                                  <emotion
                                    @emotion="handleEmotion"
                                    :height="200"
                                  ></emotion>
                                  <img
                                    :src="
                                      require('../../assets/icon/表情@2x.png')
                                    "
                                    alt=""
                                    slot="reference"
                                    style="width: 21px; height: 21px"
                                  />
                                </el-popover>
                              </li>
                              <li>
                                <el-popover
                                  ref="popover"
                                  placement="top"
                                  trigger="click"
                                >
                                  <span
                                    class="span"
                                    style="
                                      display: flex;
                                      align-items: center;
                                      cursor: pointer;
                                      text-align: center;
                                    "
                                  >
                                    <span>说话{{ num }}s,点击结束</span>
                                  </span>
                                </el-popover>
                                <!--                              @mousedown.prevent="mouseStart"  @mouseup.prevent="mouseEnd"-->
                                <img
                                  style="width: 21px; height: 21px"
                                  :src="
                                    require('../../assets/icon/语音@2x.png')
                                  "
                                  alt=""
                                  v-popover:popover
                                  @click="toStart"
                                />
                              </li>

                              <li>
                                <el-upload
                                  class="avatar-uploader"
                                  :action="uploadUrl"
                                  :show-file-list="false"
                                  :on-progress="onProgress"
                                  :before-upload="beforeVideoUpload"
                                  :on-success="videoSuccess"
                                  :disabled="classOpenInfo.is_online == 3"
                                >
                                  <img
                                    :src="
                                      require('../../assets/icon/视频@2x.png')
                                    "
                                    alt=""
                                    style="width: 21px; height: 21px"
                                  />
                                </el-upload>
                              </li>

                              <li>
                                <el-upload
                                  class="avatar-uploader"
                                  :action="uploadUrl"
                                  :show-file-list="false"
                                  :on-success="handleAvatarSuccess"
                                  :on-progress="onProgress"
                                  :before-upload="beforeAvatarUpload"
                                  :disabled="classOpenInfo.is_online == 3"
                                >
                                  <img
                                    :src="
                                      require('../../assets/icon/图片@2x.png')
                                    "
                                    alt=""
                                    style="width: 21px; height: 21px"
                                  />
                                </el-upload>
                              </li>
                            </ul>

                            <div
                              class="forbidden"
                              v-if="
                                classOpenInfo.consumer_type == 3 ||
                                classOpenInfo.consumer_type == 2
                              "
                            >
                              <!-- 禁言列表 -->
                              <div class="forbidden_list">
                                <div>
                                  <el-dropdown
                                    trigger="click"
                                    :hide-on-click="false"
                                  >
                                    <span
                                      class="el-dropdown-link"
                                      @click="getBannedList"
                                      style="margin-top: 4px"
                                    >
                                      <div class="image">
                                        <img
                                          src="../../assets/icon/列表@2x.png"
                                          alt=""
                                        />
                                      </div>
                                      禁言列表
                                    </span>
                                    <el-dropdown-menu
                                      slot="dropdown"
                                      style="margin: 0; padding: 5px 0"
                                    >
                                      <el-dropdown-item
                                        v-if="forbiddenlist.length <= 0"
                                        style="padding: 0 5px; font-size: 12px"
                                        >暂无人被禁言</el-dropdown-item
                                      >
                                      <div v-if="forbiddenlist.length > 0">
                                        <el-dropdown-item
                                          style="
                                            display: flex;
                                            justify-content: space-between;
                                            padding: 0 10px;
                                          "
                                          v-for="(item, index) in forbiddenlist"
                                          :key="index"
                                        >
                                          <div
                                            class="people"
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            <div
                                              style="
                                                display: inline-block;
                                                width: 38px;
                                                height: 38px;
                                                border-radius: 38px;
                                                overflow: hidden;
                                              "
                                            >
                                              <img
                                                :src="item.headimg"
                                                alt=""
                                                style="
                                                  display: inline-block;
                                                  width: 100%;
                                                  height: 100%;
                                                  background-color: #fff;
                                                "
                                              />
                                            </div>
                                            <span
                                              style="margin: 0 30px 0 20px"
                                              >{{ item.nickname }}</span
                                            >
                                          </div>
                                          <div
                                            style="
                                              display: flex;
                                              align-items: center;
                                              flex-direction: column;
                                              cursor: pointer;
                                            "
                                          >
                                            <div
                                              style="
                                                width: 14px;
                                                height: 14px;
                                                margin-bottom: 6px;
                                              "
                                            >
                                              <img
                                                src="../../assets/icon/禁言@2x.png"
                                                alt=""
                                                style="
                                                  display: inline-block;
                                                  width: 100%;
                                                  height: 100%;
                                                "
                                              />
                                            </div>
                                            <p
                                              v-if="item.state"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                color: #7f868d;
                                              "
                                            >
                                              解禁
                                            </p>
                                            <p
                                              v-else
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                color: #7f868d;
                                              "
                                            >
                                              讨论
                                            </p>
                                          </div>
                                        </el-dropdown-item>
                                      </div>
                                    </el-dropdown-menu>
                                  </el-dropdown>
                                </div>
                              </div>
                              <div
                                class="forbidden_all"
                                style="
                                  display: flex;
                                  justify-content: start;
                                  align-items: center;
                                  cursor: pointer;
                                "
                                @click="allNOword"
                              >
                                <div
                                  style="
                                    width: 14px;
                                    height: 14px;
                                    margin-right: 5px;
                                  "
                                >
                                  <img
                                    src="../../assets/icon/解出语音@2x.png"
                                    alt=""
                                    style="
                                      bisplay: inline-block;
                                      width: 100%;
                                      height: 100%;
                                    "
                                  />
                                </div>
                                <span>{{
                                  classOpenInfo.is_say == 1
                                    ? "全员禁言"
                                    : "全员解禁"
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="讲课区" name="third">
                      <div class="commentlist">
                        <div
                          style="
                            font-size: 14px;
                            color: #9c9cab;
                            position: absolute;
                            top: 50px;
                            left: 10%;
                          "
                          v-if="words2.length < 1"
                        >
                          <img
                            src="@/assets/image/缺省图.png"
                            style="width: 40%"
                          />
                          <p style="margin-top: 15px">暂无消息</p>
                        </div>
                        <ul
                          @scroll.passive="getScroll($event)"
                          class="teacher_area"
                        >
                          <li
                            v-for="(item, index) in words2"
                            :key="index"
                            :class="{ left: item.user_id == user_id }"
                            id="ScrollLi"
                          >
                            <!-- 系统消息 -->
                            <div
                              v-if="item.user_id == 0"
                              :title="item.content"
                              style=""
                              class="system_messages"
                            >
                              {{ item.content }}
                            </div>
                            <div
                              class="commentlist_top"
                              v-if="item.user_id != 0"
                            >
                              <div>
                                <vue-hover-mask
                                  @click="handleClick(item, index)"
                                  v-if="item.user_id != user_id"
                                >
                                  <img
                                    :src="item.headimg"
                                    alt=""
                                    v-if="item.user_id != 0"
                                  />
                                  <template v-slot:action>
                                    <span
                                      class="span"
                                      style="text-align: center"
                                      v-if="
                                        item.user_type == 3 ||
                                        item.user_type == 2
                                      "
                                    >
                                      <img
                                        src="../../assets/icon/打赏@2x.png"
                                        alt=""
                                        style="width: 14px; height: 14px"
                                      />
                                      <span>打赏</span>
                                    </span>
                                    <span
                                      class="span"
                                      style="text-align: center"
                                      v-if="
                                        item.user_type == 1 &&
                                        (classOpenInfo.consumer_type == 3 ||
                                          classOpenInfo.consumer_type == 2)
                                      "
                                    >
                                      <img
                                        src="../../assets/icon/禁言@2.2x.png"
                                        alt=""
                                        style="width: 14px; height: 14px"
                                      />
                                      <span>禁言</span>
                                    </span>
                                  </template>
                                </vue-hover-mask>

                                <img
                                  :src="item.headimg"
                                  alt=""
                                  v-if="item.user_id == user_id"
                                />
                              </div>
                              <div>
                                <!-- 文字消息 -->
                                <p>
                                  <span>{{ item.user_type_str }}</span>
                                  {{ item.nickname }}
                                </p>
                                <!-- 表情 -->
                                <p
                                  v-if="item.type == 'text'"
                                  v-html="
                                    item.content.replace(
                                      /\#[\u4E00-\u9FA5]{1,3}\;/gi,
                                      emotion
                                    )
                                  "
                                ></p>
                                <!-- 图片 -->
                                <div
                                  v-if="item.type == 'picture'"
                                  style="width: 130px"
                                >
                                  <img
                                    :src="item.content"
                                    style="
                                      height: 100%;
                                      width: 100%;
                                      margin-top: 8px;
                                    "
                                    @click="dialogClick(item.content)"
                                  />
                                </div>
                                <!-- 视频 -->
                                <div
                                  v-if="item.type == 'video'"
                                  style="
                                    margin-top: 8px;
                                    position: relative;
                                    cursor: pointer;
                                  "
                                >
                                  <video
                                    :src="item.content"
                                    style="width: 130px"
                                    controls
                                  ></video>
                                </div>
                                <!-- 语音消息 -->
                                <div
                                  class="audio__wrap"
                                  v-if="item.type == 'audio'"
                                >
                                  <audio
                                    controls
                                    :ref="'audioPlayer' + item.id + activeName"
                                    style="display: none"
                                  >
                                    <source
                                      :src="item.content"
                                      type="audio/mp3"
                                    />
                                  </audio>

                                  <div class="self__audio">
                                    <div
                                      class="audio__trigger"
                                      @click="
                                        playAudioHandler(item.id, item.duration)
                                      "
                                    >
                                      <div
                                        :class="{
                                          'wifi-symbol': true,
                                          'wifi-symbol--avtive':
                                            isPlaying && item.id == isPlayingId,
                                        }"
                                      >
                                        <div class="wifi-circle first"></div>
                                        <div class="wifi-circle second"></div>
                                        <div class="wifi-circle third"></div>
                                      </div>
                                    </div>
                                    <el-badge
                                      :is-dot="true"
                                      v-if="isDot(item.id)"
                                    >
                                      {{ item.duration }}</el-badge
                                    >
                                    <el-badge
                                      :is-dot="false"
                                      v-if="!isDot(item.id)"
                                    >
                                      {{ item.duration }}</el-badge
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import recording from "../../tools/recorder.js";
import Emotion from "../../components/Emotion/index";
import Header from "../../components/header";
import Footer from "../../components/footer";
import VueHoverMask from "vue-hover-mask";
import { log } from "util";
import { Message } from "element-ui";
import flvjs from "flv.js";
import { isWeixin } from "../../utils";

export default {
  components: {
    Emotion,
    Header,
    Footer,
    // 鼠标hover
    VueHoverMask,
  },

  data() {
    return {
      dot_arr: [],
      isPlayingId: "",
      isPlaying: false,
      // duration: "",
      // 默认显示 PC端的css
      show_pc: true,
      old_scrollBottom: 0,
      fullscreenLoading: false,
      openContent: "禁言成功",
      rechargeId: 1, // 打赏 默认显示ID第一个
      rechargeList: [], // 打赏金额列表
      rechargeDialog: false, //打赏弹框
      dialogVisible: false,
      dialogVisibleURL: "", //点击的图片消息路劲
      centerDialogVisible: false, //禁言/打赏弹窗
      //语音
      yuyinForm: {
        time: "按住说话(60秒)",
        audioUrl: "",
      },

      num: 60, // 按住说话时间
      recorder: null,
      interval: "",
      audioFileList: [], // 上传语音列表
      startTime: "", // 语音开始时间
      endTime: "", // 语音结束

      // 直播窗口配置
      playerOptions: {
        autoplay: false, // 如果true,浏览器准备好时开始回放。

        muted: true, // 默认情况下将会消除任何音频。

        loop: false, // 导致视频一结束就重新开始。

        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）

        language: "zh-CN",

        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）

        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

        sources: [
          {
            type: "application/x-mpegURL", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目

            src: "", // 视频url地址
          },
        ],

        poster: "", // 你的封面地址

        width: 300, //播放器宽度

        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。

        controlBar: {
          timeDivider: true,

          durationDisplay: true,

          remainingTimeDisplay: false,

          fullscreenToggle: true, // 全屏按钮
        },
      },
      // 小窗显示与隐藏
      // window: false,

      // 默认展示讨论区
      activeName: "second",
      // 开课id
      id: "",

      // 开课详情
      classOpenInfo: {
        cover: "",
      },

      // 禁言列表
      forbiddenlist: [],

      // 评论输入框
      input: "",
      // 评论数量的列表
      words: [], // 房间已经说过的话
      words2: [],
      wallWords: [], // 上墙的话

      // 查询文章列表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 24,
      },
      // 总条数
      total: 0,
      // 嘉宾列表
      guestList: [],
      // 卷曲高度
      scroll: "",

      client_id: "", //当前用户 被分配的 websocket client_id
      wantClose: false, // 是否正常断开ws
      closeWxCount: 0, // ws异常断开次数
      page: 0, // 当前讨论区页数, 页面需要记录第几页来切割数据
      wallPage: 0, // 当前公屏页面, 页面需要记录第几页来切割数据
      wallNoMore: false, // 公屏没有更多数据
      page_size: 20, // 页面加载条数
      arrow: "never", // 轮播图箭头
      book_num: 0, // 直播间人数
      noMore: false, // 是否更多
      user_id: 0,
      uploadUrl: "",
      EmotionIpt: "",
      book_current_index: 0,
      money_item: "",
      loading: false,
      luyin: false,

      streamUrl: "",
    };
  },
  mounted() {
    // if (flvjs.isSupported()) {
    //   let videoElement = document.getElementById("videoElement");
    //   let flvPlayer = flvjs.createPlayer({
    //     type: "flv",
    //     isLive: true,
    //     hasAudio: false,
    //     url: "http://img.ksbbs.com/asset/Mon_1704/15868902d399b87.flv",
    //   });
    //   console.log(flvPlayer, "flv对象");
    //   flvPlayer.attachMediaElement(videoElement);
    //   flvPlayer.load();
    //   flvPlayer.play();
    // }
  },
  created() {
    window._tx_webrtc_enable = true;

    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.uploadUrl = this.$store.state.uploadUrl;
    // 获取 课程id
    this.id = this.$route.query.id;
    this.user_id = localStorage.getItem("user_id");

    // 获取开课详情
    this.getClassOpen();

    // 获取嘉宾列表
    // this.getGuestList();
  },
  // 页面销毁前
  beforeDestroy() {
    console.log("beforeDestroy");
    console.log("断开连接");

    // 断开链接
    this.wantClose = true;
    if (this.ws) {
      this.ws.close();
    }

    // 谁离开了房间 告诉服务器
  },
  methods: {
    onAddStream(e) {
      console.log(`onAddStream, streamId:${e.streamId}`, e.stream);
      var streamId = e.streamId;
      var video = document.getElementById(streamId);
      if (!video) {
        video = this.createVideoElement(streamId);
      }

      video.srcObject = e.stream;
      //video.muted = true
      video.autoplay = true;
      video.playsinline = true;
      var playPromise = video.play();
      if (playPromise) {
        playPromise
          .then(() => {
            console.log(`play ok!`);
          })
          .catch(() => {
            console.log(`play failed!`);
          });
      }
    },
    createVideoElement(id) {
      var videoDiv = document.createElement("div");

      if (this.show_pc) {
        videoDiv.innerHTML = `<video id="${id}" autoplay unmuted playsinline controls style="
                                z-index: 10;
                                position: absolute;
                                right: 0;
                                width: 250px;
                                top: 1%;
                              "></video>`; //width="480" height="320"
      } else {
        videoDiv.innerHTML = `<video id="${id}" autoplay unmuted playsinline controls style="
                                z-index: 10;
                                position: absolute;
                                right: 0;
                                width: 180px;
                                top: 1%;
                              "></video>`; //width="480" height="320"
      }

      document.querySelector("#remote-video-wrap").appendChild(videoDiv);

      return document.getElementById(id);
    },

    //
    getStreamId(url) {
      //var url = "webrtc://domain/path/stream_id[?txSecret=xxx&txTime=xxx]";
      var parseStreamid = /^(?:webrtc:\/\/)(?:[0-9.\-A-Za-z_]+)(?:\/)(?:[0-9.\-A-Za-z_]+)(?:\/)([^?#]*)(?:\?*)(?:[^?#]*)/;
      var result = parseStreamid.exec(url);
      if (result) return result[1];
      return null;
    },

    // 播放视频
    toAction() {
      let that = this;
      var clientInfo = "clientinfo_test";
      var sessionId = "sessionId_Test";
      var streamId = this.getStreamId(that.streamUrl);
      var video = document.getElementById(streamId);
      if (!video) {
        video = this.createVideoElement(streamId);
      }
      console.log(667);
      console.log(streamId);
      var config = {
        iceServers: [],
        bundlePolicy: "max-bundle",
        rtcpMuxPolicy: "require",
        tcpCandidatePolicy: "disable",
        IceTransportsType: "nohost",
        sdpSemantics: "unified-plan",
      };

      var optional = {
        optional: [
          {
            DtlsSrtpKeyAgreement: true,
          },
        ],
      };

      var offerSdpOption = {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
        voiceActivityDetection: false,
      };

      let peerConnection = new RTCPeerConnection(config, optional);
      console.log(peerConnection);
      peerConnection.onicecandidate = function (e) {
        console.log("peerConnection.onicecandidate:", e);
      };
      peerConnection.onaddstream = function (e) {
        console.log("peerConnection.onaddstream");
      };
      peerConnection.onremovestream = function (e) {
        console.log("peerConnection.onremovestream");
      };
      peerConnection.ontrack = function (e) {
        console.log(
          "peerConnection.ontrack, kind:" +
            e.track.kind +
            ",track.id:" +
            e.track.id
        );

        var track = e.track;
        if (!peerConnection.stream) {
          peerConnection.streamId = streamId;
          peerConnection.stream = new MediaStream();
          peerConnection.stream.addTrack(track);

          that.onAddStream(peerConnection);
        } else {
          peerConnection.stream.addTrack(track);
          console.log(
            "track.lenght:" + peerConnection.stream.getTracks().length
          );
        }
      };
      peerConnection.onconnectionstatechange = function (e) {
        console.log("onconnectionstatechange", e);
      };
      peerConnection.oniceconnectionstatechange = function (e) {
        console.log(
          "peerConnection.oniceconnectionstatechange: " + JSON.stringify(e),
          e
        );
      };
      peerConnection.onicegatheringstatechange = function (e) {
        console.log(
          "peerConnection.onicegatheringstatechange : " +
            e.target.iceGatheringState,
          e
        );
      };
      peerConnection.onsignalingstatechange = function (e) {
        console.log(
          "peerConnection.onsignalingstatechange : " +
            peerConnection.signalingState,
          e
        );
      };
      peerConnection.onnegotiationneeded = function (e) {
        console.log("peerConnection.onnegotiationneeded", e);
      };

      peerConnection
        .createOffer(offerSdpOption)
        .then(function (offer) {
          var stringSdp = offer.sdp.toString();
          var arrSdp = stringSdp.split("\r\n");
          var stringSdpModify = "";

          for (var i = 0; i < arrSdp.length - 1; i++) {
            if (arrSdp[i].lastIndexOf("a=fmtp:111") != -1) {
              stringSdpModify += arrSdp[i] + ";stereo=1" + "\r\n";
            } else {
              stringSdpModify += arrSdp[i] + "\r\n";
            }
          }
          offer.sdp = stringSdpModify;
          console.log("peerConnection.split3", offer.sdp);

          peerConnection.setLocalDescription(offer);
          console.log(888);
          //var url = 'https://live.rtc.qq.com:8687/webrtc/v1/pullstream';
          var url = "https://webrtc.liveplay.myqcloud.com/webrtc/v1/pullstream";
          //var url = "http://[240e:ab:b220:200:2e::]/6721.liveplay.myqcloud.com/webrtc/v1/pullstream";
          console.log(999);

          let reqBody = {
            streamurl: that.streamUrl,
            sessionid: sessionId,
            clientinfo: clientInfo,
            localsdp: offer,
          };

          that.$http
            .post(url, reqBody)
            .then((data) => {})
            .catch((data) => {
              console.log("pullStreamRes:", data);
              //    data = data.data;
              var errCode = data.errcode;
              var errMsg = data.errmsg;
              if (errCode != 0) {
                console.log(
                  `pull stream failed!errCode:${errCode}, errmsg:${errMsg}`
                );
                return;
              }

              var remoteSdp = data.remotesdp;
              var svrSig = data.svrsig;

              // console.log(`svrSig:${svrSig}`);

              peerConnection.setRemoteDescription(
                new RTCSessionDescription(remoteSdp),
                function () {
                  console.log("setRemoteSdp succ!");
                },
                function (e) {
                  console.log("setRemoteSdp failed, exception = " + e.message);
                }
              );
            });

          // $.ajax({
          //   type: "post",
          //   url: url,
          //   data: JSON.stringify(reqBody),
          //   //contentType: "application/json; charset=utf-8",
          //   dataType: "json",
          //   success: pullStreamRes,
          //   crossDomain: true
          // });
          //fly.interceptors.request.use((request) => {
          //   request.headers['Content-Type'] ='application/json';
          //   request.headers['Access-Control-Allow-Origin'] ='*';
          //   request.headers['Access-Control-Allow-Credentials'] ='true';
          //})
          //fly.post(url, JSON.stringify(reqBody)).then(pullStreamRes).catch(function(error){
          //   console.log("fly post err:", error);
          //});
        })
        .catch(function (reason) {
          console.log("create offer failed : reason = " + reason);
        });
    },

    enterSearchMember() {
      console.log("键盘事件");
      this.toSendText();
    },
    isDot(id) {
      // console.log(this.dot_arr.indexOf(id))
      if (this.dot_arr.indexOf(id) != -1) {
        return false;
      }
      return true;
    },
    // 去打赏
    toMoney(item) {
      console.log(item);
      // 请求是否有钱打赏
      this.$http
        .post("/index.php/api/v1_0_0.courselive/get_reward", {})
        .then((res) => {
          console.log("获取打赏金额");
          console.log(res);
          // 1需要设置支付密码 2余额不足
          if (res.data.do_handle == 1) {
            this.$confirm(res.msg, "设置支付密码", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              center: true,
            })
              .then(() => {
                this.$router.push({
                  path: "/personal/financial",
                  query: {
                    tabs: "支付密码设置",
                  },
                });
              })
              .catch(() => {});
            return false;
          }
          if (res.data.do_handle == 2) {
            this.$confirm(res.msg, "充值提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              center: true,
            })
              .then(() => {
                this.$router.push({
                  path: "/personal/financial",
                  query: {
                    tabs: "我的余额",
                  },
                });
              })
              .catch(() => {});
            return false;
          }
          // 展示弹框
          this.rechargeDialog = true;
          // 展示打赏列表
          this.rechargeList = res.data.list;
          // 设置当前打赏人信息
          this.money_item = item;
          // 设置初始打赏金额
          this.rechargeId = res.data.list[0].id;
        })
        .catch((error) => {});
    },

    //打赏金额选择
    ClickRechargeList(item) {
      console.log(item);
      this.rechargeId = item.id;
    },

    // 打赏创建订单
    goRecharge() {
      // 组织参数
      if (!this.money_item) {
        this.$message.error("请选择打赏人");
        return false;
      }

      if (!this.rechargeId) {
        this.$message.error("请选择打赏金额");
        return false;
      }

      this.$http
        .post("/index.php/api/v1_0_0.courselive/reward_order", {
          teach_id: this.money_item.user_id,
          course_id: this.money_item.course_id,
          reward_id: this.rechargeId,
        })
        .then((res) => {
          console.log("打赏订单创建");
          console.log(res);
          // 余额不足
          if (res.data.do_handle == 2) {
            this.$confirm(res.msg, "充值提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              center: true,
            })
              .then(() => {
                this.$router.push({
                  path: "/personal/financial",
                  query: {
                    tabs: "我的余额",
                  },
                });
              })
              .catch(() => {});
            return false;
          }

          // 支付密码
          this.$prompt("请输入支付密码", res.msg, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputPlaceholder: "请输入支付密码",
            inputType: "password",
            inputValidator: function (v) {
              if (!v) return "请输入支付密码";
            },
          })
            .then(({ value }) => {
              this.$http
                .post("/index.php/api/v1_0_0.courselive/do_pay", {
                  order_num: res.data.order_num,
                  password: value,
                })
                .then((res) => {
                  console.log("确定支付之后的结果");
                  console.log(res);
                  this.rechargeDialog = false;
                  this.$message.success("打赏成功");
                })
                .catch((error) => {});
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消支付",
              });
            });
        })
        .catch((error) => {});
    },

    // 去禁言
    toNoword(item) {
      console.log(item);

      // 禁言
      this.$http
        .post("/index.php/api/v1_0_0.courselive/do_nosay", {
          student_id: item.user_id,
          course_id: this.classOpenInfo.id,
        })
        .then((res) => {
          console.log(res, "禁言");
          this.$message.success(res.msg);
        })
        .catch((error) => {});
    },

    // 全员禁言
    allNOword() {
      console.log("全员禁言");

      this.$http
        .post("/index.php/api/v1_0_0.courselive/do_nosay_all", {
          course_id: this.classOpenInfo.id,
        })
        .then((res) => {
          console.log(res, "全员禁言");

          this.$message.success(res.msg);
          this.classOpenInfo.is_say = this.classOpenInfo.is_say == 1 ? 2 : 1;
        })
        .catch((error) => {});
    },

    //设置房间滚动条
    setScrol(site = ScrollLi.length - 1) {
      let ScrollLi = document.querySelectorAll("#ScrollLi");
      console.log(ScrollLi.length);
      ScrollLi[site].scrollIntoView();
      window.scrollTo(0, 0);
    },
    setScrol2(site = ScrollLi2.length - 1) {
      let ScrollLi2 = document.querySelectorAll("#ScrollLi2");
      console.log(ScrollLi2.length);
      ScrollLi2[site].scrollIntoView();
      window.scrollTo(0, 0);
    },

    //禁言列表
    getBannedList() {
      this.$http
        .post("/index.php/api/v1_0_0.courselive/get_consumer_no", {
          course_id: this.classOpenInfo.id,
        })
        .then((res) => {
          console.log("禁言列表");
          console.log(res);
          this.forbiddenlist = res.data.list;
        })
        .catch((error) => {});
    },
    //图片查看
    dialogClick(url) {
      (this.dialogVisible = true), (this.dialogVisibleURL = url);
    },
    // 发评论
    toSendText() {
      if (!this.input) {
        this.$message.error("请输入评论内容");
        return false;
      }
      //
      this.$http
        .post("/index.php/api/v1_0_0.courselive/send", {
          type: "text",
          content: this.input,
          course_id: this.classOpenInfo.id,
        })
        .then((res) => {
          console.log("发送文本消息结果");
          console.log(res);
          //设置聊天室滚动条
          this.setScrol();
          this.input = "";
        })
        .catch((error) => {});
    },
    // 课件切换
    booksChange(cur, pre) {
      console.log(cur);
      console.log(pre);
      // 直播中，只允许老师控制
      if (this.classOpenInfo.is_online == 2) {
        // 直播中只允许讲师操作
        if (this.classOpenInfo.consumer_type != 3) {
          this.$refs.book.setActiveItem(this.book_current_index);
          return;
        }
        // TODO 告诉后台，课件切换

        this.$http
          .post("/index.php/api/v1_0_0.courselive/set_course_book", {
            index: cur,
            course_id: this.classOpenInfo.id,
          })
          .then((res) => {
            console.log(res, "课件切换");
          })
          .catch((error) => {});
      }
    },

    // 切换tab
    tabClick(tab, event) {
      // 获取嘉宾
      if (this.activeName == "first") {
        this.getGuestList();
      }

      // 获取讨论区
      if (this.activeName == "second") {
        this.page = 0;
        this.getNextWords(() => {});
      }

      // 获取讲课区
      if (this.activeName == "third") {
        this.wallPage = 0;
        this.getNextWallWords(() => {
          this.setScrol2();
        });
      }
    },
    // 获取开课详情
    getClassOpen() {
      this.fullscreenLoading = true;
      let that = this;
      this.$http
        .post("/index.php/api/v1_0_0.courselive/get_detail", {
          id: this.id,
        })
        .then((res) => {
          this.fullscreenLoading = false;
          console.log("直播课详情");
          console.log(res);
          // consumer_type 1成员 2嘉宾 3讲师
          this.classOpenInfo = res.data;
          // 是否显示轮播图箭头
          this.arrow = res.data.consumer_type == 3 ? "always" : "never";

          //
          if (res.data.pull_url) {
            console.log("拉流地址");
            console.log(res.data.pull_url);

            this.streamUrl = res.data.pull_url;

            this.toAction();
          }
          // 直播流地址
          this.playerOptions.sources[0].src = res.data.media_url;

          // if (!this.show_pc) {
          //   this.playerOptions.poster = res.data.cover;
          //
          //   var pull_url = res.data.pull_url.replace("flv", "m3u8");
          //   this.playerOptions.sources[0].src = pull_url;
          // }

          // 直播的封面
          // this.playerOptions.poster = res.data.cover;

          // if (res.data.pull_url) {
          //   console.log(flvjs.isSupported());
          //   // if (flvjs.isSupported()) {
          //   this.$nextTick(() => {
          //     if (flvjs.isSupported()) {
          //       let videoElement = document.getElementById("videoElement");
          //       let flvPlayer = flvjs.createPlayer({
          //         type: "flv",
          //         isLive: true,
          //         hasAudio: true,
          //         url: res.data.pull_url,
          //         enableWorker: true,
          //         enableStashBuffer: false,
          //         stashInitialSize: 128,
          //       });
          //       console.log(flvPlayer, "flv对象");
          //       flvPlayer.attachMediaElement(videoElement);
          //       // flvPlayer.muted = true;
          //       flvPlayer.load();
          //       flvPlayer.muted = true;
          //       console.log(flvPlayer);
          //       flvPlayer.play();
          //     }
          //   });
          //   // }
          // }

          // 直播间尚未开始时 检测开始
          if (this.classOpenInfo.is_online == 1) {
            let s = setInterval(() => {
              let dateObj = new Date();
              let nowTime = dateObj.getTime() / 1000;
              if (nowTime >= this.classOpenInfo.online_time) {
                clearInterval(s);
                // TODO
                // this.classOpenInfo.is_online = 2
                location.reload();
              }
            }, 10000);
          }

          // websocket 初始化 监听 实时说的话
          this.initWebsocket();

          // 获取房间已经说过的话
          this.getNextWords(() => {});
          this.getNextWallWords(() => {});

          // 如果是嘉宾或者讲师
          if (res.data.consumer_type > 1) {
            this.getBannedList();
          }
        })
        .catch((error) => {
          this.$router.push({
            path: "/xskt/salaryClassroom",
            query: {
              id: this.id,
            },
          });
        });
    },

    // websocket 初始化
    initWebsocket() {
      let that = this;

      let host = "wss://www.xinshui.com/wss";
      let ws = new WebSocket(host);

      // 服务端主动推送消息时会触发这里的onmessage
      ws.onmessage = this.onMessage;
      ws.onopen = function () {
        console.log("ws open");
      };
      ws.onerror = function () {
        console.log("ws error");
        console.log("");
      };
      ws.onclose = function () {
        console.log("ws close");
        console.log("");

        that.leaveRoom(that.client_id);

        if (that.wantClose == true) {
          console.log("ws正常断开");
        } else {
          // 服务器异常时， 太快请求， 会导致服务不稳定
          // 连接期间不让用户多余操作

          setTimeout(() => {
            // ws异常断开
            that.closeWxCount++;
            console.log("ws异常断开 次数:" + that.closeWxCount);

            if (that.closeWxCount <= 10) {
              // 断线重连
              that.initWebsocket();
            } else {
              // 提示不稳定
            }
          }, 2000);
        }
      };

      this.ws = ws;
    },

    // 接收到消息
    onMessage(e) {
      console.log("接受到消息");
      console.log(e.data);
      var data = JSON.parse(e.data);
      console.log(data);

      if (!data) return false;

      switch (data.type) {
        // 初始化分配 cid
        case "init":
          let cid = data.client_id;
          this.client_id = cid;
          this.joinRoom(cid);
          break;

        // 心跳
        case "ping":
          break;

        // 接收到消息
        case "message":
          console.log(data.content);

          this.words.push(data.content);
          this.setScrol();
          // 聊天外层容器 滚动条 到最后
          // this.toBottom();
          break;

        // 老师结束直播
        case "dissolve_room":
          // this.$toast("房间直播已经结束！");
          this.status = 2;
          break;

        // 老师更新了 房间配置信息
        case "set_live":
          this.wall = data.data.room.wall;
          this.mode = data.data.room.mode;
          break;

        // 有人加入了房间
        case "join_room":
          this.count_member = data.data.room.num;

          // this.$toast(data.data.user.nickname + '进入了房间')
          break;

        // 有人离开了房间
        case "leave_room":
          break;

        // 有人打赏
        case "reward":
          this.$toast(data.data.msg);
          break;

        // 课件变化
        case "courseware_change":
          console.log("课件变化");
          console.log(data.content.place_id);
          this.$refs.book.setActiveItem(data.content.place_id);
          this.book_current_index = data.content.place_id;
          this.classOpenInfo.book_imgs = data.content.book_imgs;
          break;

        // 有人撤回消息
        case "revoke_message":
          var id = data.data.id;

          // 将id 存入 localStorage， 后端的消息放在了 redis中，撤回消息在刷新后， 又会出现
          this.revokeMessage = id;

          var j = -1;
          for (let i = 0; i < this.words.length; i++) {
            if (this.words[i].msg.id == id) {
              j = i;
            }
          }
          console.log("j:" + j);
          if (j != -1) this.words.splice(j, 1);

          // 公屏撤回
          const index = this.words2.findIndex((v) => {
            return v.msg.id == id;
          });
          if (index != -1) this.words2.splice(index, 1);
          break;

        default:
          break;
      }
    },

    // 用户加入房间
    joinRoom(cid) {
      // 加入直播房间
      this.$http
        .post("/index.php/api/v1_0_0.courselive/join", {
          client_id: cid,
          course_id: this.classOpenInfo.id,
        })
        .then((res) => {
          console.log("加入直播间结果");
          console.log(res);
          this.book_num = res.data.num;
          this.setScrol();
        })
        .catch((error) => {});
    },

    // 用户离开房间
    leaveRoom(cid) {
      console.log(cid + "离开房间");
    },

    // 获取房间已经说过的话
    getNextWords(callback) {
      this.page += 1;

      this.$http
        .post("/index.php/api/v1_0_0.courselive/get_word", {
          page: this.page,
          page_size: this.page_size,
          course_id: this.classOpenInfo.id,
        })
        .then((res) => {
          console.log("获取讨论区消息列表");

          console.log(res);
          this.words = res.data.list;
          console.log(this.words);

          if (res.data.list.length < this.page_size) {
            this.noMore = true;
          }

          if (callback && typeof callback == "function") {
            callback();
          }
        })
        .catch((error) => {});
    },

    // 获取房间上墙中的已经说过的话
    getNextWallWords(callback) {
      this.wallPage += 1;

      this.$http
        .post("/index.php/api/v1_0_0.courselive/get_word", {
          page: this.page,
          page_size: this.page_size,
          course_id: this.classOpenInfo.id,
          type: 1,
        })
        .then((res) => {
          console.log("获取讲课区消息列表");
          console.log(res);
          this.words2 = res.data.list;

          if (res.data.list.length < this.page_size) {
            this.noMore = true;
          }

          if (callback && typeof callback == "function") {
            callback();
          }
        })
        .catch((error) => {});
    },

    // 跳转到底部
    // toBottom() {

    //   console.log('跳转到底部');
    //   return false;

    //   // 翻看聊天记录时，不跳到底部
    //   if (this.flag) return false
    //   setTimeout(() => {
    //     // this.$nextTick(() => {
    //     // 发言墙 滚到最后
    //     this.allDiv.scrollTop = this.allDiv.scrollHeight; // 滚动高度

    //     // 发言板 滚到最后
    //     let fayanbanDiv = document.getElementById("fayanban");
    //     if (fayanbanDiv) {
    //       fayanbanDiv.scrollTop = fayanbanDiv.scrollHeight; // 滚动高度
    //     }
    //     // });
    //   }, 100);
    // },
    getScroll(e) {
      let scrollBottom =
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
      // console.log(scrollBottom)
      // console.log(e.target.scrollTop)
      if (e.target.scrollTop == 0) {
        console.log(scrollBottom);

        this.$http
          .post("/index.php/api/v1_0_0.courselive/get_word", {
            course_id: this.classOpenInfo.id,
            id: this.words[0].id,
          })
          .then((res) => {
            console.log("上拉加载");
            console.log(res);
            this.words.unshift(...res.data.list);
            // console.log('总高度', e.target.scrollHeight)
            // console.log('上次计算的高度', this.old_scrollBottom)
            // console.log('滚动高度', e.target.scrollTop)
            // console.log('窗口高度', e.target.clientHeight)
            //
            // console.log('要移动的', e.target.scrollHeight - this.old_scrollBottom)
            this.$refs.roomScroll.scrollTop =
              e.target.scrollHeight -
              this.old_scrollBottom -
              e.target.clientHeight;
            this.old_scrollBottom = scrollBottom;
          })
          .catch((error) => {});
      }
    },

    beforeVideoUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 5;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过5MB哦!");
        return false;
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onProgress(event, file, fileList) {
      // console.log(event)
      // console.log(file)
      // console.log(fileList)
      this.loading = true;
    },
    // 发送图片
    handleAvatarSuccess(file) {
      console.log(file);
      //发送图片
      this.$http
        .post("/index.php/api/v1_0_0.courselive/send", {
          type: "picture",
          content: file.data.file_url,
          course_id: this.classOpenInfo.id,
        })
        .then((res) => {
          this.loading = false;
          console.log(res, "发送图片消息结果");
          //设置聊天室滚动条
          this.setScrol();
        })
        .catch((error) => {});
    },
    //发送视频
    videoSuccess(file) {
      console.log(file, "视频");
      this.$http
        .post("/index.php/api/v1_0_0.courselive/send", {
          type: "video",
          duration: 1,
          content: file.data.file_url,
          course_id: this.classOpenInfo.id,
        })
        .then((res) => {
          console.log(res, "发送视频消息结果");
          this.loading = false;
          //设置聊天室滚动条
          this.setScrol();
        })
        .catch((error) => {});
    },

    // 发送表情
    handleEmotion(i) {
      console.log(i);
      this.input += i;
      this.input.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, this.emotion);
    },

    // 将匹配结果替换表情图片
    emotion(res) {
      let word = res.replace(/\#|\;/gi, "");
      const list = [
        "微笑",
        "撇嘴",
        "色",
        "发呆",
        "得意",
        "流泪",
        "害羞",
        "闭嘴",
        "睡",
        "大哭",
        "尴尬",
        "发怒",
        "调皮",
        "呲牙",
        "惊讶",
        "难过",
        "酷",
        "冷汗",
        "抓狂",
        "吐",
        "偷笑",
        "可爱",
        "白眼",
        "傲慢",
        "饥饿",
        "困",
        "惊恐",
        "流汗",
        "憨笑",
        "大兵",
        "奋斗",
        "咒骂",
        "疑问",
        "嘘",
        "晕",
        "折磨",
        "衰",
        "骷髅",
        "敲打",
        "再见",
        "擦汗",
        "抠鼻",
        "鼓掌",
        "糗大了",
        "坏笑",
        "左哼哼",
        "右哼哼",
        "哈欠",
        "鄙视",
        "委屈",
        "快哭了",
        "阴险",
        "亲亲",
        "吓",
        "可怜",
        "菜刀",
        "西瓜",
        "啤酒",
        "篮球",
        "乒乓",
        "咖啡",
        "饭",
        "猪头",
        "玫瑰",
        "凋谢",
        "示爱",
        "爱心",
        "心碎",
        "蛋糕",
        "闪电",
        "炸弹",
        "刀",
        "足球",
        "瓢虫",
        "便便",
        "月亮",
        "太阳",
        "礼物",
        "拥抱",
        "强",
        "弱",
        "握手",
        "胜利",
        "抱拳",
        "勾引",
        "拳头",
        "差劲",
        "爱你",
        "NO",
        "OK",
        "爱情",
        "飞吻",
        "跳跳",
        "发抖",
        "怄火",
        "转圈",
        "磕头",
        "回头",
        "跳绳",
        "挥手",
        "激动",
        "街舞",
        "献吻",
        "左太极",
        "右太极",
      ];
      let index = list.indexOf(word);
      return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`;
    },

    // 点赞 或 取消点赞
    ClickThumbs() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_praise", {
          id: this.id,
          type: 4,
        })
        .then((res) => {
          this.classOpenInfo.praise_count = res.data.praise_count;
          this.classOpenInfo.is_praise = res.data.is_praise;
          console.log(res.data);
        })
        .catch((error) => {});
    },

    // 收藏 或 取消收藏
    ClickCollection() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_collect", {
          id: this.id,
          type: 4,
        })
        .then((res) => {
          this.classOpenInfo.is_collect = res.data.is_collect;
          this.classOpenInfo.collect_count = res.data.collect_count;
          console.log(res);
        })
        .catch((error) => {});
    },

    // 点击 表情 语音 视频 图片
    ClickFunctIng(i) {
      console.log(i);
    },

    // 清除定时器
    clearTimer() {
      if (this.interval) {
        this.num = 60;
        clearInterval(this.interval);
      }
    },

    toStart() {
      if (!this.luyin) {
        if (this.classOpenInfo.is_online == 3) {
          this.$message.error("直播课已结束");
          return false;
        }
        this.clearTimer();
        this.startTime = new Date().getTime();
        console.log(recording);

        recording.get((rec) => {
          console.log(rec);
          // 当首次按下时，要获取浏览器的麦克风权限，所以这时要做一个判断处理
          if (rec) {
            // 首次按下，只调用一次
            if (this.flag) {
              this.mouseEnd();
              this.flag = false;
            } else {
              this.recorder = rec;
              this.interval = setInterval(() => {
                if (this.num <= 0) {
                  this.recorder.stop();
                  this.num = 60;
                  this.clearTimer();
                } else {
                  this.num--;
                  this.time = "松开结束（" + this.num + "秒）";
                  this.recorder.start();
                }
              }, 1000);
            }
          }
        });
        this.luyin = true;
      } else {
        if (this.classOpenInfo.is_online == 3) {
          return false;
        }

        let yuyinTime = 60 - this.num; //语音时长
        console.log(yuyinTime, "语音时长");
        if (yuyinTime < 1) {
          this.$message.error("时长过短");
          return;
        }
        this.clearTimer();
        this.endTime = new Date().getTime();
        if (this.recorder) {
          this.recorder.stop();
          // 重置说话时间
          this.num = 60;
          this.time = "按住说话（" + this.num + "秒）";
          // 获取语音二进制文件
          let bold = this.recorder.getBlob();
          console.log(bold, "bold");
          // 将获取的二进制对象转为二进制文件流
          // let files = new File([bold], 'test.mp3', {
          //   type: 'audio/mp3',
          //   lastModified: Date.now()
          // })
          // console.log(files, 'files')
          let fd = new FormData();
          fd.append("file", bold, Math.ceil(Math.random() * 100) + "test.mp3");
          // fd.append('tenantId', 3) // 额外参数，可根据选择填写
          // 这里是通过上传语音文件的接口，获取接口返回的路径作为语音路径
          this.$http
            .post("/index.php/api/common.upload/do_upload", fd)
            .then((res) => {
              console.log(res, "res");
              //发送消息
              this.$http
                .post("/index.php/api/v1_0_0.courselive/send", {
                  type: "audio",
                  duration: yuyinTime,
                  content: res.data.file_url,
                  course_id: this.classOpenInfo.id,
                })
                .then((res) => {
                  console.log(res, "发送语音消息结果");
                  this.luyin = false;
                  //设置聊天室滚动条
                  this.$nextTick(function () {
                    this.$refs.roomScroll.scrollTop = this.$refs.roomScroll.scrollHeight;
                  });
                })
                .catch((error) => {});
            })
            .catch((error) => {});
        }
      }
    },

    // 长按说话
    mouseStart() {
      console.log("长按说话");

      if (this.classOpenInfo.is_online == 3) {
        this.$message.error("直播课已结束");
        return false;
      }
      this.clearTimer();
      this.startTime = new Date().getTime();
      console.log(recording);

      recording.get((rec) => {
        console.log(rec);
        // 当首次按下时，要获取浏览器的麦克风权限，所以这时要做一个判断处理
        if (rec) {
          // 首次按下，只调用一次
          if (this.flag) {
            this.mouseEnd();
            this.flag = false;
          } else {
            this.recorder = rec;
            this.interval = setInterval(() => {
              if (this.num <= 0) {
                this.recorder.stop();
                this.num = 60;
                this.clearTimer();
              } else {
                this.num--;
                this.time = "松开结束（" + this.num + "秒）";
                this.recorder.start();
              }
            }, 1000);
          }
        }
      });
    },
    // 松开时上传语音
    mouseEnd() {
      console.log("松开上传语音");
      if (this.classOpenInfo.is_online == 3) {
        return false;
      }

      let yuyinTime = 60 - this.num; //语音时长
      console.log(yuyinTime, "语音时长");
      if (yuyinTime < 1) {
        this.$message.error("时长过短");
        return;
      }
      this.clearTimer();
      this.endTime = new Date().getTime();
      if (this.recorder) {
        this.recorder.stop();
        // 重置说话时间
        this.num = 60;
        this.time = "按住说话（" + this.num + "秒）";
        // 获取语音二进制文件
        let bold = this.recorder.getBlob();
        console.log(bold, "bold");
        // 将获取的二进制对象转为二进制文件流
        // let files = new File([bold], 'test.mp3', {
        //   type: 'audio/mp3',
        //   lastModified: Date.now()
        // })
        // console.log(files, 'files')
        let fd = new FormData();
        fd.append("file", bold, Math.ceil(Math.random() * 100) + "test.mp3");
        // fd.append('tenantId', 3) // 额外参数，可根据选择填写
        // 这里是通过上传语音文件的接口，获取接口返回的路径作为语音路径
        this.$http
          .post("/index.php/api/common.upload/do_upload", fd)
          .then((res) => {
            console.log(res, "res");
            //发送消息
            this.$http
              .post("/index.php/api/v1_0_0.courselive/send", {
                type: "audio",
                duration: yuyinTime,
                content: res.data.file_url,
                course_id: this.classOpenInfo.id,
              })
              .then((res) => {
                console.log(res, "发送语音消息结果");
                //设置聊天室滚动条
                this.$nextTick(function () {
                  this.$refs.roomScroll.scrollTop = this.$refs.roomScroll.scrollHeight;
                });
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      }
    },

    // 获取嘉宾列表
    getGuestList() {
      this.$http
        .post("/index.php/api/v1_0_0.course/get_guest", {
          id: this.id,
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then((res) => {
          console.log("嘉宾列表");
          console.log(res);
          this.guestList = res.data.list;
        })
        .catch((error) => {});
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getGuestList();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.getGuestList();
    },

    playAudioHandler(id, duration) {
      let that = this;

      this.isPlaying = !this.isPlaying;
      this.isPlayingId = id;
      console.log(this.$refs);

      console.log(this.$refs[`audioPlayer${id}${this.activeName}`]);
      const player = this.$refs[`audioPlayer${id}${this.activeName}`];

      if (this.isPlaying) {
        let that = this;
        if (isWeixin()) {
          console.log(wx);
          console.log("微信播放语音");

          wx.ready(function () {
            const player = that.$refs[`audioPlayer${id}${that.activeName}`];

            console.log(player);

            player[0].load();

            player[0].play();
          });

          setTimeout(function () {
            that.isPlayingId = 0;
            that.isPlaying = false;
            that.dot_arr.push(id);
            console.log(that.dot_arr);
          }, duration * 1000);
        } else {
          player[0].load();
          player[0].play();

          setTimeout(function () {
            that.isPlayingId = 0;
            that.isPlaying = false;
            that.dot_arr.push(id);
            console.log(that.dot_arr);
          }, duration * 1000);
        }
      } else {
        player[0].pause();
      }
    },
  },

  watch: {},
};
</script>
<style lang='less' scoped>
/*img:hover {*/
/*  transform: scale(1);*/
/*  object-fit:contain;*/
/*}*/
/deep/.vjs-custom-skin > .video-js .vjs-control {
  width: 2em;
}
/deep/.vjs-mute-control {
  // cursor: pointer;
  // flex: none;
  // padding-left: 2em;

  padding-right: 2em;
  padding-bottom: 3em;
}
/deep/.video-js .vjs-mute-control {
  padding-left: 0em;
}
.pc {
  /deep/.el-carousel__item[data-v-fd98d698]:nth-child(2n + 1) {
    background-color: #f5f5f5;
  }

  /*/deep/.el-dialog__header {*/
  /*  padding: 0;*/
  /*}*/
  /deep/.el-icon {
    border: 0;
  }

  .system_messages {
    height: 20px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 10px;
    padding: 0 12px;
    /*margin: 20px 0;*/
    /*width: 280px;*/
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }

  .span {
    font-size: 10px;

    .div {
      width: 10px !important;
      height: 10px !important;
      margin-left: 20px;
      background-color: red;
      padding: 0;
    }
  }

  .classOpen {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    padding-top: 20px;
    width: auto;
    background-color: #f5f5f5;
    padding-bottom: 50px;

    .content {
      // padding: 0 5%;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: start;

      .classOpen_left {
        width: 75%;
        height: 100%;
        margin-right: 20px;

        .broadcast {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 108px;
          background-color: #fff;
          border-radius: 6px;
          padding: 8px;
          box-sizing: border-box;

          .teacher {
            display: flex;
            justify-content: start;

            .img {
              width: 92px;
              height: 92px;

              img {
                display: inline-block;
                width: 100%;
                height: 100%;
                background-color: #fff;
              }
            }

            .class_info {
              margin-left: 10px;
              width: 85%;

              .date {
                text-align: left;
                display: flex;
                justify-content: start;
                align-items: center;
                margin: 10px 0 8px;

                span:nth-child(1) {
                  display: inline-block;
                  height: 25px;
                  font-size: 20px;
                  font-weight: 500;
                  color: #292931;
                  line-height: 28px;
                  margin-right: 13px;
                  overflow: hidden;
                  width: 80%;
                }

                span:nth-child(2) {
                  width: 60px;
                  height: 24px;
                  line-height: 24px !important;
                  background: #1677ff;
                  border-radius: 2px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 17px;
                  text-align: center;
                }
              }

              .taeacher_info {
                text-align: left;

                span {
                  display: inline-block;
                  width: 550px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #848592;
                  line-height: 17px;
                  white-space: nowrap; //强制一行显示
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .parameter {
                padding-bottom: 30px;

                ul {
                  display: flex;
                  justify-content: start;

                  li {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin: 8px 15px 5px 0;

                    span {
                      font-size: 12px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #848592;
                      line-height: 17px;
                    }

                    span:nth-child(1) {
                      width: 14px;
                      height: 14px;
                      margin-right: 5px;

                      img {
                        width: 100%;
                        height: 100%;
                        // background-color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }

          .thumbs {
            display: flex;
            justify-content: center;
            align-items: center;
            // margin: 30px 0;
            margin-right: 12px;
            // width: 300px;

            div {
              width: 88px;
              height: 30px;
              background: #fff;
              border-radius: 25px;
              font-weight: 400;
              color: #999;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #eee;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              line-height: 20px;
              cursor: pointer;

              img {
                display: inline-block;
                width: 16px;
                height: 16px;
                padding: 0 5px;
              }
            }

            div:nth-child(2) {
              border: 1px solid #eee;
              background-color: #fff;
              color: #999;
              margin-left: 15px;
            }
          }
        }

        .online {
          position: absolute;
          bottom: 75px;
          left: 0;
          width: 240px;
          height: 30px;
          line-height: 30px;
          text-align: left;
          padding-left: 20px;
          color: #fff;
          box-sizing: border-box;
          background: linear-gradient(300deg, transparent 0%, #ba7f1f 100%);
          z-index: 10;
        }

        .image {
          position: relative;
          width: 100%;
          height: 580px;
          overflow: hidden;

          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: #fff;
          }

          div {
            position: absolute;
            bottom: 30px;
            left: 0;
            width: 240px;
            height: 30px;
            line-height: 30px;
            text-align: left;
            padding-left: 20px;
            color: #fff;
            box-sizing: border-box;
            background: linear-gradient(300deg, transparent 0%, #ba7f1f 100%);
          }
        }
      }

      .classOpen_right {
        width: 25%;
        height: 688px;

        .comment {
          width: 100%;
          background-color: #f8f8f8;

          /deep/.el-tabs__nav {
            display: flex;
            justify-content: space-around;
            padding: 0 10px;
          }

          /deep/.el-tabs__active-bar {
            left: 10px;
          }

          .text {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;

            p {
              text-align: left;
              font-size: 18px;
              font-weight: 600;
              color: #292931;
              line-height: 25px;

              span {
                display: block;
                width: 64px;
                height: 3px;
                background: #1677ff;
                margin-top: 5px;
              }
            }
          }

          .comment_box {
            height: 102px;
            padding: 0 10px;
            box-sizing: border-box;
            background-color: #fff;
            box-shadow: 0px -5px 10px -5px #888888;

            .funct {
              display: flex;
              justify-content: space-between;

              ul {
                display: flex;
                justify-content: start;
                padding: 13px 0;

                li {
                  display: inline-block;
                  width: 21px;
                  height: 21px;
                  // background-color: pink;
                  margin-right: 12px;
                  cursor: pointer;

                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              .forbidden {
                display: flex;
                justify-self: start;

                .forbidden_list {
                  display: flex;
                  align-items: center;

                  .el-dropdown-link {
                    width: 70px;
                    display: flex;
                    justify-self: start;
                    align-items: center;
                    font-size: 12px;
                    color: #5a5b63;

                    -webkit-transform: scale(0.86);

                    cursor: pointer;

                    .image {
                      width: 15px;
                      height: 14px;
                      margin: 0 3px;
                      z-index: 0;

                      img {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                }

                .forbidden_all {
                  width: 80px;
                  font-size: 12px;
                  -webkit-transform: scale(0.85);
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #5a5b63;
                  // margin: 0 20px;
                }
              }
            }

            .input {
              display: flex;
              justify-content: start;

              /deep/ .el-input__inner {
                height: 38px;
                border-radius: 5px 0 0 5px;
                border: 2px solid #dcdfe6;
              }

              .btn {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                // margin-left: 8px;
                margin-left: -2px;
                z-index: 1;
                .btnColor {
                  background: #4e6ef2 !important;
                  color: #fff;
                }
                button {
                  width: 50px;
                  height: 38px;
                  background: #eeeeee;
                  border-radius: 0 5px 5px 0;

                  // border-radius: 5px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #9299a3;
                  line-height: 20px;
                  border: none;
                  outline: none;
                  cursor: pointer;
                }
              }
            }
          }

          .commentlist {
            width: 100%;
            overflow: auto;
            // height: 515px;
            background-color: #fff;

            .teacher_area {
              height: 635px;
            }

            .guest {
              overflow: auto;
              height: 635px;
              padding: 7.5px 0px;
              box-sizing: border-box;

              ul {
                // display: flex;
                // justify-content: start;
                // flex-wrap: wrap;
                li {
                  display: flex;
                  padding: 10px;
                  align-items: center;

                  .img {
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                    overflow: hidden;

                    img {
                      display: inline-block;
                      width: 100%;
                      height: 100%;
                      background-color: pink;
                    }
                  }

                  .info {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;

                    p:nth-child(1) {
                      font-size: 14px;
                    }

                    p:nth-child(2) {
                      font-size: 12px;
                      margin-left: 10px;
                    }
                  }
                }
              }
            }

            ul {
              overflow: auto;
              height: 533px;
              padding: 0 7px;

              li {
                margin-bottom: 20px;
                // width: 100%;
                .commentlist_top {
                  display: flex;
                  // align-items: center;

                  div:nth-child(1) {
                    width: 40px;
                    height: 40px;
                    border-radius: 58px;
                    overflow: hidden;
                    margin-right: 10px;
                    // background-color: pink;

                    img {
                      display: inline-block;
                      width: 100%;
                      height: 100%;
                    }

                    .span {
                      font-size: 8px;
                      display: inline-block;
                      padding-top: 5px;

                      span:nth-child(1) {
                        width: 11px;
                        height: 12px;

                        // margin-left: 6px;
                        img {
                          display: inline-block;
                          width: 100%;
                          height: 100%;
                        }
                      }

                      span {
                      }

                      span {
                        display: block;
                      }
                    }
                  }

                  div:nth-child(2) {
                    p:nth-child(1) {
                      font-size: 14px;
                      font-weight: 600;
                      color: #292931;
                      line-height: 22px;
                      text-align: left;
                      // margin-top: 15px;

                      span {
                        display: inline-block;
                        width: 44px;
                        height: 18px;
                        background: #1677ff;
                        border-radius: 2px;
                        font-size: 10px;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 18px;
                        text-align: center;
                      }
                    }

                    p:nth-child(2) {
                      font-size: 12px;
                      font-weight: 400;
                      color: #5a5b63;
                      line-height: 20px;
                      text-align: left;
                    }

                    .audio__wrap {
                      margin-top: 5px;
                      padding: 0;

                      .self__audio {
                        padding: 0;
                        .audio__duration {
                          display: inline-block;
                          line-height: 32px;
                          height: 32px;
                          padding-right: 6px;
                          color: #888888;
                        }
                        .audio__trigger {
                          cursor: pointer;
                          vertical-align: top;
                          display: inline-block;
                          line-height: 32px;
                          height: 32px;
                          width: 100px;
                          background-color: #f5f5f5;
                          border-radius: 4px;
                          position: relative;
                          .wifi-symbol {
                            border-radius: 0;

                            position: absolute;
                            left: 0px;
                            top: -8px;
                            width: 50px;
                            height: 50px;
                            box-sizing: border-box;
                            overflow: hidden;
                            transform: rotate(137deg) scale(0.5);
                            .wifi-circle {
                              border: 5px solid #999999;
                              border-radius: 50%;
                              position: absolute;
                            }

                            .first {
                              width: 5px;
                              height: 5px;
                              background: #cccccc;
                              top: 45px;
                              left: 45px;
                            }
                            .second {
                              width: 25px;
                              height: 25px;
                              top: 35px;
                              left: 35px;
                            }
                            .third {
                              width: 40px;
                              height: 40px;
                              top: 25px;
                              left: 25px;
                            }
                          }
                          .wifi-symbol--avtive {
                            .second {
                              animation: bounce 1s infinite 0.2s;
                            }
                            .third {
                              animation: bounce 1s infinite 0.4s;
                            }
                          }
                        }
                        /deep/.el-badge {
                          height: 32px;
                          line-height: 32px;
                          .el-badge__content.is-dot {
                            width: 5px;
                            height: 5px;
                          }
                          .el-badge__content.is-fixed.is-dot {
                            right: -3px;
                          }
                        }
                        @keyframes bounce {
                          0% {
                            opacity: 0; /*初始状态 透明度为0*/
                          }
                          100% {
                            opacity: 1; /*结尾状态 透明度为1*/
                          }
                        }
                      }
                      .right_self__audio {
                        padding: 0;
                        .audio__duration {
                          display: inline-block;
                          line-height: 32px;
                          height: 32px;
                          padding-right: 6px;
                          color: #888888;
                        }
                        .audio__trigger {
                          cursor: pointer;
                          vertical-align: top;
                          display: inline-block;
                          line-height: 32px;
                          height: 32px;
                          width: 100px;
                          background-color: #f5f5f5;
                          border-radius: 4px;
                          position: relative;
                          .wifi-symbol {
                            border-radius: 0;

                            position: absolute;
                            right: 0px;
                            top: -8px;
                            width: 50px;
                            height: 50px;
                            box-sizing: border-box;
                            overflow: hidden;
                            transform: rotate(317deg) scale(0.5);
                            .wifi-circle {
                              border: 5px solid #999999;
                              border-radius: 50%;
                              position: absolute;
                            }

                            .first {
                              width: 5px;
                              height: 5px;
                              background: #cccccc;
                              top: 45px;
                              left: 45px;
                            }
                            .second {
                              width: 25px;
                              height: 25px;
                              top: 35px;
                              left: 35px;
                            }
                            .third {
                              width: 40px;
                              height: 40px;
                              top: 25px;
                              left: 25px;
                            }
                          }
                          .wifi-symbol--avtive {
                            .second {
                              animation: bounce 1s infinite 0.2s;
                            }
                            .third {
                              animation: bounce 1s infinite 0.4s;
                            }
                          }
                        }
                        /deep/.el-badge {
                          height: 32px;
                          line-height: 32px;
                          float: left;
                          /*.el-badge__content.is-dot {*/
                          /*  width: 5px;*/
                          /*  height: 5px;*/
                          /*}*/
                          /*.el-badge__content.is-fixed.is-dot {*/
                          /*  right: -3px;*/
                          /*}*/
                        }
                        @keyframes bounce {
                          0% {
                            opacity: 0; /*初始状态 透明度为0*/
                          }
                          100% {
                            opacity: 1; /*结尾状态 透明度为1*/
                          }
                        }
                      }

                      .direction {
                        display: flex;
                        flex-direction: row-reverse;
                        .audio__trigger {
                          position: relative;
                          .wifi-symbol {
                            transform: rotate(-45deg) scale(0.5);
                            position: absolute;
                            left: 50px;
                            top: -8px;
                          }
                        }
                        /deep/.el-badge {
                          .el-badge__content.is-fixed.is-dot {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .left {
            .commentlist_top {
              display: flex !important;
              flex-direction: row-reverse !important;
              text-align: right !important;

              div:nth-child(2) {
                padding-right: 10px !important;

                p {
                  text-align: right !important;
                  color: red !important;
                }
              }
            }
          }
        }

        .data {
          width: 100%;
          height: auto;
          margin-bottom: 20px;

          .top {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: start;

            .portrait {
              height: 104px;
              width: 104px;
              background-color: #999;
              box-sizing: border-box;

              img {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }

            .infor {
              width: ceil(100% - 104px);
              height: 104px;
              margin-left: 14px;

              p {
                text-align: left;
              }

              p:nth-child(1) {
                font-size: 18px;
                font-weight: 600;
                color: #303030;
                line-height: 25px;
                margin-bottom: 10px;
              }

              p:nth-child(2) {
                font-size: 12px;
                font-weight: 400;
                color: #313a4a;
                line-height: 17px;
              }
            }
          }
        }

        .author {
          background-color: #fafafa;
          padding: 20px 20px 10px 20px;
          margin-bottom: 10px;

          .top {
            display: flex;
            justify-content: space-between;
            height: 24px;
            line-height: 24px;

            p:nth-child(1) {
              border-left: 4px solid #1677ff;
              height: 24px;
              line-height: 24px;
              font-size: 18px;
              font-weight: 700;
              padding-left: 10px;
            }

            p:nth-child(2) {
              width: 18px;
              height: 18px;
              margin-top: 3px;
              cursor: pointer;

              img {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }
          }

          .bottom {
            margin-top: 25px;
            padding-bottom: 30px;

            ul {
              display: flex;
              justify-content: start;

              flex-wrap: wrap;

              li {
                width: 64px;
                text-align: center;
                margin: 4% 3%;

                // margin: 10px 5px;
                div {
                  width: 100%;
                  height: 64px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-bottom: 8px;
                  background-color: #fff;
                  cursor: pointer;

                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    // opacity: 0;
                  }
                }

                P {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #292931;
                  line-height: 20px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }

  .recharge {
    text-align: center;

    ul {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      margin-bottom: 30px;
      padding: 0px 0px 0 10px;

      .year_bg {
        background: #1677ff;
        color: #ffffff;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98px;
        height: 56px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #d3d7de;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #292931;
        margin: 10px;
        cursor: pointer;
      }
    }
  }

  .goRecharge {
    width: 88px;
    height: 34px;
    background: #1677ff;
    margin: 0 auto;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 34px;
    /* margin-bottom: 30px; */
    cursor: pointer;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 580px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  /deep/.el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #fff;
  }

  /deep/.el-carousel__indicators--horizontal {
    left: 88%;
    transform: translateX(5%);
  }

  // 修改轮播图 选中小白点的样式
  /deep/.el-carousel__indicator.is-active button {
    width: 20px !important;
  }
}

.classOpen_mb {
  /deep/.el-popover {
    top: 3px;
  }
  /deep/.el-tabs__item {
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 400;
    color: #292931;
  }
  padding-top: 60px;
  .system_messages {
    height: 20px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 10px;
    padding: 0 12px;
    margin: 20px 0;
    width: 280px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 auto;
  }

  .span {
    font-size: 10px;

    .div {
      width: 10px !important;
      height: 10px !important;
      margin-left: 20px;
      background-color: red;
      padding: 0;
    }
  }

  .classOpen {
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    padding-bottom: 50px;
    width: 100%;

    .classOpen_left {
      width: 100%;
      // height: 100%;
      margin-right: 20px;
      position: fixed;
      z-index: 11;
      .broadcast {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 108px;
        background-color: #fff;
        border-radius: 6px;
        padding: 8px;
        box-sizing: border-box;

        .teacher {
          display: flex;
          justify-content: start;

          .img {
            width: 92px;
            height: 92px;

            img {
              display: inline-block;
              width: 100%;
              height: 100%;
              background-color: #fff;
            }
          }
        }
      }

      .online {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 120px;
        height: 30px;
        line-height: 30px;
        text-align: left;
        padding-left: 20px;
        color: #fff;
        font-size: 12px;
        box-sizing: border-box;
        letter-spacing: 3px;
        background: linear-gradient(300deg, transparent 0%, #ba7f1f 100%);
        z-index: 10;
      }

      .image {
        position: relative;
        width: 100%;
        height: 188px;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: #fff;
        }

        div {
          position: absolute;
          bottom: 30px;
          left: 0;
          width: 120px;
          height: 30px;
          line-height: 30px;
          text-align: left;
          padding-left: 20px;
          color: #fff;
          box-sizing: border-box;
          background: linear-gradient(265deg, transparent 0%, #ba7f1f 100%);
        }
      }
    }
    .class_info {
      position: fixed;
      top: 255px;

      // margin-top: 190px;
      margin-left: 10px;
      height: 82px;
      width: 100%;

      .date {
        text-align: left;
        display: flex;
        justify-content: start;
        align-items: center;
        /*margin: 10px 0 8px 0;*/

        span:nth-child(1) {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #292931;
          margin-right: 5px;
        }

        span:nth-child(2) {
          width: 60px;
          height: 20px;
          // line-height: 24px !important;
          background: #1677ff;
          border-radius: 2px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          text-align: center;
        }
        span:nth-child(3) {
          width: 100px;
          white-space: nowrap; //强制一行显示
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #848592;
          line-height: 17px;
          margin-left: 10px;
        }
      }

      .parameter {
        ul {
          display: flex;
          flex-direction: column;

          li {
            display: flex;
            justify-content: start;
            align-items: center;
            // margin: 8px 15px 5px 0;
            margin-bottom: 5px;

            span {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #848592;
              line-height: 17px;
            }

            span:nth-child(1) {
              width: 14px;
              height: 14px;
              margin-right: 5px;

              img {
                width: 100%;
                height: 100%;
                // background-color: #fff;
              }
            }
            span:nth-child(3) {
              width: 14px;
              height: 14px;
              margin: 0 5px 0 20px;

              img {
                width: 100%;
                height: 100%;
                // background-color: #fff;
              }
            }
          }
        }
      }
    }

    .classOpen_right {
      width: 100%;
      height: 520px;
      position: fixed;
      top: 330px;
      z-index: 999;

      .comment {
        width: 100%;
        background-color: #f8f8f8;

        /deep/.el-tabs__nav {
          display: flex;
          justify-content: space-around;
          padding: 0 10px;
        }

        /deep/.el-tabs__active-bar {
          left: 10px;
        }

        .text {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #fff;

          p {
            text-align: left;
            font-size: 18px;
            font-weight: 600;
            color: #292931;
            line-height: 25px;

            span {
              display: block;
              width: 64px;
              height: 3px;
              background: #1677ff;
              margin-top: 5px;
            }
          }
        }

        .comment_box {
          position: fixed;
          bottom: -3px;
          width: 100%;
          height: 88px;
          box-sizing: border-box;
          background-color: #fff;
          box-shadow: 0px -5px 10px -5px #888888;
          z-index: 9999;

          .funct {
            display: flex;
            justify-content: space-between;
            height: 44px;
            background: #f8f8f8;
            ul {
              height: 100%;
              display: flex;
              justify-content: start;
              align-items: center;
              padding-left: 13px;
              li:last-child {
                margin-right: 0;
              }

              li {
                display: inline-block;
                height: 100%;
                // background-color: pink;
                display: flex;
                align-items: center;
                margin: 0 30px 0 0;
                cursor: pointer;
                .avatar-uploader {
                  width: 100%;
                  height: 21px;
                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                  }
                }
                span {
                  display: inline-block;
                  height: 21px;
                }
              }
            }

            .forbidden {
              display: flex;
              justify-self: start;

              .forbidden_list {
                display: flex;
                align-items: center;

                .el-dropdown-link {
                  width: 80px;
                  display: flex;
                  justify-self: start;
                  align-items: center;
                  font-size: 12px;
                  color: #5a5b63;

                  -webkit-transform: scale(0.8);

                  cursor: pointer;

                  .image {
                    width: 15px;
                    height: 14px;
                    margin: 0 3px;
                    z-index: 0;

                    img {
                      display: inline-block;
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }

              .forbidden_all {
                width: 80px;
                font-size: 12px;
                -webkit-transform: scale(0.85);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #5a5b63;
                // margin: 0 20px;
              }
            }
          }

          .input {
            height: 44px;
            display: flex;
            justify-content: start;

            /deep/ .el-input__inner {
              height: 38px;
              border-radius: 5px;
              border: 0px solid #dcdfe6;
            }

            .btn {
              // margin-left: 8px;
              margin-left: -2px;
              z-index: 1;
              width: 64px;
              height: 100%;
              display: flex;
              align-items: center;
              margin-right: 10px;
              button {
                width: 100%;
                height: 28px;
                border-radius: 16px;
                background: #1677ff;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                border: none;
                outline: none;
                cursor: pointer;
              }
            }
          }
        }

        .commentlist {
          width: 100%;
          overflow: auto;
          // height: 515px;
          // background-color: #fff;
          .teacher_area {
            height: 520px;
            margin-bottom: 0;
          }

          .guest {
            overflow: auto;
            height: 520px;
            margin: 0 10px;
            background-color: #f5f5f5;
            box-sizing: border-box;

            ul {
              // display: flex;
              // justify-content: start;
              // flex-wrap: wrap;
              li {
                display: flex;
                padding: 10px;
                align-items: center;

                .img {
                  width: 40px;
                  height: 40px;
                  border-radius: 40px;
                  overflow: hidden;

                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background-color: pink;
                  }
                }

                .info {
                  display: flex;
                  align-items: center;
                  margin-left: 10px;

                  p:nth-child(1) {
                    font-size: 14px;
                  }

                  p:nth-child(2) {
                    font-size: 12px;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          .noMessage {
            /*height: calc(100vh - 468px);*/
            position: relative;
            .thumbs {
              position: absolute;
              bottom: 0;
            }
          }
          .komz {
            overflow: auto;
            height: calc(100vh - 465px);
            margin: 0 10px;
            background-color: #f5f5f5;
            box-sizing: border-box;
            // padding-bottom: 90%;
            // padding-bottom: cacl(100vh - 20vh);
            li {
              margin-bottom: 20px;

              .commentlist_top {
                display: flex;
                // align-items: center;

                div:nth-child(1) {
                  width: 45px;
                  height: 45px;
                  border-radius: 58px;
                  overflow: hidden;
                  margin-right: 10px;
                  // background-color: pink;

                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                  }

                  .span {
                    font-size: 8px;
                    display: inline-block;
                    padding-top: 5px;

                    span:nth-child(1) {
                      width: 11px;
                      height: 12px;

                      // margin-left: 6px;
                      img {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                      }
                    }

                    span {
                    }

                    span {
                      display: block;
                    }
                  }
                }

                div:nth-child(2) {
                  // padding-left: 10px;

                  p:nth-child(1) {
                    font-size: 14px;
                    font-weight: 600;
                    color: #292931;
                    line-height: 22px;
                    text-align: left;
                    // margin-top: 15px;

                    span {
                      display: inline-block;
                      width: 44px;
                      height: 18px;
                      background: #1677ff;
                      border-radius: 2px;
                      font-size: 10px;
                      font-weight: 400;
                      color: #ffffff;
                      line-height: 18px;
                      text-align: center;
                    }
                  }

                  p:nth-child(2) {
                    font-size: 12px;
                    font-weight: 400;
                    color: #5a5b63;
                    line-height: 20px;
                    text-align: left;
                  }
                  .audio__wrap {
                    margin-top: 5px;
                    padding: 0;

                    .self__audio {
                      padding: 0;
                      .audio__duration {
                        display: inline-block;
                        line-height: 32px;
                        height: 32px;
                        padding-right: 6px;
                        color: #888888;
                      }
                      .audio__trigger {
                        cursor: pointer;
                        vertical-align: top;
                        display: inline-block;
                        line-height: 32px;
                        height: 32px;
                        width: 100px;
                        background-color: #fff;
                        border-radius: 4px;
                        position: relative;
                        .wifi-symbol {
                          border-radius: 0;
                          position: absolute;
                          left: 0px;
                          top: -8px;
                          width: 50px;
                          height: 50px;
                          box-sizing: border-box;
                          overflow: hidden;
                          transform: rotate(137deg) scale(0.5);
                          .wifi-circle {
                            border: 5px solid #999999;
                            border-radius: 50%;
                            position: absolute;
                          }

                          .first {
                            width: 5px;
                            height: 5px;
                            background: #cccccc;
                            top: 45px;
                            left: 45px;
                          }
                          .second {
                            width: 25px;
                            height: 25px;
                            top: 35px;
                            left: 35px;
                          }
                          .third {
                            width: 40px;
                            height: 40px;
                            top: 25px;
                            left: 25px;
                          }
                        }
                        .wifi-symbol--avtive {
                          .second {
                            animation: bounce 1s infinite 0.2s;
                          }
                          .third {
                            animation: bounce 1s infinite 0.4s;
                          }
                        }
                      }
                      /deep/.el-badge {
                        height: 32px;
                        line-height: 32px;
                        .el-badge__content.is-dot {
                          width: 5px;
                          height: 5px;
                        }
                        .el-badge__content.is-fixed.is-dot {
                          right: -3px;
                        }
                      }
                      @keyframes bounce {
                        0% {
                          opacity: 0; /*初始状态 透明度为0*/
                        }
                        100% {
                          opacity: 1; /*结尾状态 透明度为1*/
                        }
                      }
                    }

                    .right_self__audio {
                      padding: 0;
                      .audio__duration {
                        display: inline-block;
                        line-height: 32px;
                        height: 32px;
                        padding-right: 6px;
                        color: #888888;
                      }
                      .audio__trigger {
                        cursor: pointer;
                        vertical-align: top;
                        display: inline-block;
                        line-height: 32px;
                        height: 32px;
                        width: 100px;
                        background-color: #fff;
                        border-radius: 4px;
                        position: relative;
                        .wifi-symbol {
                          border-radius: 0;

                          position: absolute;
                          right: 0px;
                          top: -8px;
                          width: 50px;
                          height: 50px;
                          box-sizing: border-box;
                          overflow: hidden;
                          // transform: rotate(137deg) scale(0.5);
                          transform: rotate(317deg) scale(0.5);
                          .wifi-circle {
                            border: 5px solid #999999;
                            border-radius: 50%;
                            position: absolute;
                          }

                          .first {
                            width: 5px;
                            height: 5px;
                            background: #cccccc;
                            top: 45px;
                            left: 45px;
                          }
                          .second {
                            width: 25px;
                            height: 25px;
                            top: 35px;
                            left: 35px;
                          }
                          .third {
                            width: 40px;
                            height: 40px;
                            top: 25px;
                            left: 25px;
                          }
                        }
                        .wifi-symbol--avtive {
                          .second {
                            animation: bounce 1s infinite 0.2s;
                          }
                          .third {
                            animation: bounce 1s infinite 0.4s;
                          }
                        }
                      }
                      /deep/.el-badge {
                        height: 32px;
                        line-height: 32px;
                        float: left;
                        /*.el-badge__content.is-dot {*/
                        /*  width: 5px;*/
                        /*  height: 5px;*/
                        /*}*/
                        /*.el-badge__content.is-fixed.is-dot {*/
                        /*  right: -3px;*/
                        /*}*/
                      }
                      @keyframes bounce {
                        0% {
                          opacity: 0; /*初始状态 透明度为0*/
                        }
                        100% {
                          opacity: 1; /*结尾状态 透明度为1*/
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .teacher_area {
            overflow: auto;
            height: calc(100vh - 385px);
            margin: 0 10px;
            background-color: #f5f5f5;
            li {
              margin-bottom: 10px;

              .commentlist_top {
                display: flex;
                align-items: center;

                div:nth-child(1) {
                  width: 45px;
                  height: 45px;
                  border-radius: 58px;
                  overflow: hidden;
                  // background-color: pink;

                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                  }

                  .span {
                    font-size: 8px;
                    display: inline-block;
                    padding-top: 5px;

                    span:nth-child(1) {
                      width: 11px;
                      height: 12px;

                      // margin-left: 6px;
                      img {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                      }
                    }

                    span {
                    }

                    span {
                      display: block;
                    }
                  }
                }

                div:nth-child(2) {
                  padding-left: 10px;

                  p:nth-child(1) {
                    font-size: 14px;
                    font-weight: 600;
                    color: #292931;
                    line-height: 22px;
                    text-align: left;
                    margin-top: 15px;

                    span {
                      display: inline-block;
                      width: 44px;
                      height: 18px;
                      background: #1677ff;
                      border-radius: 2px;
                      font-size: 10px;
                      font-weight: 400;
                      color: #ffffff;
                      line-height: 18px;
                      text-align: center;
                    }
                  }

                  p:nth-child(2) {
                    width: 100%;
                    font-size: 12px;
                    font-weight: 400;
                    color: #5a5b63;
                    line-height: 20px;
                    text-align: left;
                  }
                  .audio__wrap {
                    margin-top: 5px;
                    padding: 0;

                    .self__audio {
                      padding: 0;
                      .audio__duration {
                        display: inline-block;
                        line-height: 32px;
                        height: 32px;
                        padding-right: 6px;
                        color: #888888;
                      }
                      .audio__trigger {
                        cursor: pointer;
                        vertical-align: top;
                        display: inline-block;
                        line-height: 32px;
                        height: 32px;
                        width: 100px;
                        background-color: #fff;
                        border-radius: 4px;
                        position: relative;
                        .wifi-symbol {
                          border-radius: 0;

                          position: absolute;
                          left: 0px;
                          top: -8px;
                          width: 50px;
                          height: 50px;
                          box-sizing: border-box;
                          overflow: hidden;
                          transform: rotate(137deg) scale(0.5);
                          .wifi-circle {
                            border: 5px solid #999999;
                            border-radius: 50%;
                            position: absolute;
                          }

                          .first {
                            width: 5px;
                            height: 5px;
                            background: #cccccc;
                            top: 45px;
                            left: 45px;
                          }
                          .second {
                            width: 25px;
                            height: 25px;
                            top: 35px;
                            left: 35px;
                          }
                          .third {
                            width: 40px;
                            height: 40px;
                            top: 25px;
                            left: 25px;
                          }
                        }
                        .wifi-symbol--avtive {
                          .second {
                            animation: bounce 1s infinite 0.2s;
                          }
                          .third {
                            animation: bounce 1s infinite 0.4s;
                          }
                        }
                      }
                      /deep/.el-badge {
                        height: 32px;
                        line-height: 32px;
                        .el-badge__content.is-dot {
                          width: 5px;
                          height: 5px;
                        }
                        .el-badge__content.is-fixed.is-dot {
                          right: -3px;
                        }
                      }
                      @keyframes bounce {
                        0% {
                          opacity: 0; /*初始状态 透明度为0*/
                        }
                        100% {
                          opacity: 1; /*结尾状态 透明度为1*/
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .thumbs {
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            // margin: 30px 0;
            margin-right: 12px;
            // width: 300px;
            // margin-bottom: 88px;
            margin-top: 0;
            padding: 20px 0;

            div {
              width: 120px;
              height: 30px;
              background: #fff;
              border-radius: 25px;
              font-weight: 400;

              color: #1677ff;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #eee;
              font-family: PingFangSC-Regular, PingFang SC;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              cursor: pointer;

              img {
                display: inline-block;
                width: 16px;
                height: 16px;
                padding: 0 5px;
              }
            }

            div:nth-child(2) {
              border: 1px solid #eee;
              background-color: #fff;
              color: #999;
              margin-left: 15px;
            }
          }
        }

        .left {
          .commentlist_top {
            display: flex !important;
            flex-direction: row-reverse !important;
            text-align: right !important;

            div:nth-child(2) {
              padding-right: 10px !important;

              p {
                text-align: right !important;
                // color: red !important;
                // color: #292931;
              }
            }
          }
        }
      }

      .data {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        .top {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: start;

          .portrait {
            height: 104px;
            width: 104px;
            background-color: #999;
            box-sizing: border-box;

            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }

          .infor {
            width: ceil(100% - 104px);
            height: 104px;
            margin-left: 14px;

            p {
              text-align: left;
            }

            p:nth-child(1) {
              font-size: 18px;
              font-weight: 600;
              color: #303030;
              line-height: 25px;
              margin-bottom: 10px;
            }

            p:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
              color: #313a4a;
              line-height: 17px;
            }
          }
        }
      }

      .author {
        background-color: #fafafa;
        padding: 20px 20px 10px 20px;
        margin-bottom: 10px;

        .top {
          display: flex;
          justify-content: space-between;
          height: 24px;
          line-height: 24px;

          p:nth-child(1) {
            border-left: 4px solid #1677ff;
            height: 24px;
            line-height: 24px;
            font-size: 18px;
            font-weight: 700;
            padding-left: 10px;
          }

          p:nth-child(2) {
            width: 18px;
            height: 18px;
            margin-top: 3px;
            cursor: pointer;

            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }

        .bottom {
          margin-top: 25px;
          padding-bottom: 30px;

          ul {
            display: flex;
            justify-content: start;

            flex-wrap: wrap;

            li {
              width: 64px;
              text-align: center;
              margin: 4% 3%;

              // margin: 10px 5px;
              div {
                width: 100%;
                height: 64px;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 8px;
                background-color: #fff;
                cursor: pointer;

                img {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  // opacity: 0;
                }
              }

              P {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #292931;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  .recharge {
    text-align: center;

    ul {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      margin-bottom: 30px;
      padding: 0px 0px 0 10px;

      .year_bg {
        background: #1677ff;
        color: #ffffff;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98px;
        height: 56px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #d3d7de;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #292931;
        margin: 10px;
        cursor: pointer;
      }
    }
  }

  .goRecharge {
    width: 88px;
    height: 34px;
    background: #1677ff;
    margin: 0 auto;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 34px;
    /* margin-bottom: 30px; */
    cursor: pointer;
  }
  /deep/.el-carousel__button {
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: #fff;
  }
  // 修改轮播图 选中小白点的样式
  /deep/.el-carousel__indicator.is-active button {
    width: 10px !important;
  }
  /deep/.el-carousel__indicator--horizontal {
    padding: 6px 4px;
  }
}
</style>
<style>
@import url("//at.alicdn.com/t/font_1262845_52b6h42svd7.css");

.iconfont {
  font-size: 25px;
}
</style>
