<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 活动详情页面 -->
    <Header :indexi="4"></Header>

    <!-- PC端 -->
    <div v-if="show_pc" style="background-color: #f5f5f5" class="pc">
      <!-- 课堂详情内容 -->
      <div class="classroom">
        <!-- 课堂详情 左侧 内容 -->
        <div class="classroom_left">
          <!-- 课程大图 以及 课程信息 直播时间 报名人数 -->
          <div class="curriculum">
            <div class="image">
              <img :src="activityInfo.cover" alt="" />
            </div>
            <div class="date">
              <div>
                <span>{{ activityInfo.address }}</span
                ><span style="padding: 0 10px">{{
                  activityInfo.cate_name
                }}</span>
              </div>
              <div class="thumbs">
                <div
                  v-if="activityInfo.is_praise == 1"
                  @click="ClickThumbs"
                  style="
                    background-color: #1677ff;
                    color: #fff;
                    border: 1px solid #1677ff;
                  "
                >
                  <img src="../../assets/icon/编组3@2x.png" alt="" />
                  {{ activityInfo.praise_count }}
                </div>
                <div v-if="activityInfo.is_praise == 2" @click="ClickThumbs">
                  点赞
                  <img src="../../assets/icon/编组2@2x.png" alt="" />
                  {{ activityInfo.praise_count }}
                </div>
                <div
                  v-if="activityInfo.is_collect == 1"
                  @click="ClickCollection"
                  style="border: 1px solid #98c3ff; color: #1677ff"
                >
                  <img src="../../assets/icon/编组@2x.png" alt="" />已收藏
                </div>
                <div
                  v-if="activityInfo.is_collect == 2"
                  @click="ClickCollection"
                >
                  <img src="../../assets/icon/编组备份@2x(1).png" alt="" />收藏
                </div>
              </div>
            </div>

            <div class="parameter">
              <ul>
                <li>
                  <span><img src="../../assets/icon/人员@2x.png" alt="" /></span
                  ><span>{{ parameter.nickname }}</span>
                </li>
                <li>
                  <span>
                    <img src="../../assets/icon/时间@2x.png" alt="" />
                  </span>
                  <span
                    >{{ parameter.sign_start_time }} <span>~</span
                    >{{ parameter.sign_end_time }}</span
                  >
                </li>
                <li>
                  <span><img src="../../assets/icon/人数@2x.png" alt="" /></span
                  ><span>{{ parameter.people_count }}</span>
                </li>
                <li>
                  <span><img src="../../assets/icon/地址@2x.png" alt="" /></span
                  ><span>{{ parameter.address }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 课程详情介绍 -->
          <div class="CourseDetails">
            <ul>
              <li>
                <div class="title">
                  <span>活动介绍</span>
                  <span></span>
                </div>
                <p v-html="activityInfo.description">
                  {{ activityInfo.description }}
                </p>
              </li>
              <li>
                <div class="title">
                  <span>活动详情</span>
                  <span></span>
                </div>
                <p v-html="activityInfo.content"></p>
              </li>
            </ul>
          </div>
        </div>

        <!-- 课堂详情 右侧 内容 -->
        <div class="classroom_right">
          <!-- 薪税课堂 -->
          <div class="newest">
            <div class="top">
              <div class="left">
                <p>新税活动</p>
              </div>
              <div class="right" @click="ClickClassRoom">
                <img src="../../assets/icon/更多@2x.png" style="width: 16px" />
              </div>
            </div>
            <div class="bottom">
              <ul>
                <li
                  v-for="(item, index) in classRoomList"
                  :key="item.id"
                  @click="ClickclassRoomList(item)"
                >
                  <div class="img">
                    <img :src="item.cover" alt="" />
                  </div>
                  <p>{{ item.title }}</p>
                  <p>{{ item.create_time }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部立即报名模块 -->
      <div class="signUp">
        <div
          class="btn"
          v-if="2 == activityInfo.is_sign && activityInfo.is_online == 2"
        >
          <span>{{
            activityInfo.is_pay == 1
              ? activityInfo.price_str
              : activityInfo.price_str + activityInfo.unit
             +'  /'+"人"}}</span>
          <el-button type="primary" @click="ClickJoin">立即报名</el-button>
        </div>
        <div
          class="btn"
          v-if="2 == activityInfo.is_sign && activityInfo.is_online == 1"
        >
          <el-button type="primary">报名未开始，敬请期待</el-button>
        </div>
        <div
          class="btn"
          v-if="2 == activityInfo.is_sign && activityInfo.is_online == 3"
        >
          <el-button type="primary">报名已结束</el-button>
        </div>
        <div class="btn" v-if="1 == activityInfo.is_sign">
          <el-button type="primary">已报名</el-button>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="activitDetails_mb">
        <!-- 课堂详情内容 -->
        <div class="classroom">
          <!-- 课堂详情 左侧 内容 -->
          <div class="classroom_left">
            <!-- 课程大图 以及 课程信息 直播时间 报名人数 -->
            <div class="curriculum">
              <div class="image">
                <img :src="activityInfo.cover" alt="" />
              </div>
              <div class="date">
                <div>
                  <span>{{ activityInfo.address }}</span
                  ><span
                    ><span>{{ activityInfo.cate_name }}</span></span
                  >
                </div>
                <div class="thumbs">
                  <div
                    v-if="activityInfo.is_praise == 1"
                    @click="ClickThumbs"
                    style="
                      background-color: #1677ff;
                      color: #fff;
                      border: 1px solid #1677ff;
                    "
                  >
                    <img src="../../assets/icon/编组3@2x.png" alt="" />
                    {{ activityInfo.praise_count }}
                  </div>
                  <div v-if="activityInfo.is_praise == 2" @click="ClickThumbs">
                    点赞
                    <img src="../../assets/icon/编组2@2x.png" alt="" />
                    {{ activityInfo.praise_count }}
                  </div>
                  <div
                    v-if="activityInfo.is_collect == 1"
                    @click="ClickCollection"
                    style="border: 1px solid #98c3ff; color: #1677ff"
                  >
                    <img src="../../assets/icon/编组@2x.png" alt="" />已收藏
                  </div>
                  <div
                    v-if="activityInfo.is_collect == 2"
                    @click="ClickCollection"
                  >
                    <img
                      src="../../assets/icon/编组备份@2x(1).png"
                      alt=""
                    />收藏
                  </div>
                </div>
              </div>

              <div class="parameter">
                <ul>
                  <li>
                    <span
                      ><img src="../../assets/icon/人员@2x.png" alt="" /></span
                    ><span>{{ parameter.nickname }}</span>
                  </li>
                  <li>
                    <span>
                      <img src="../../assets/icon/时间@2x.png" alt="" />
                    </span>
                    <span
                      >{{ parameter.sign_start_time }} <span>~</span
                      >{{ parameter.sign_end_time }}</span
                    >
                  </li>
                  <li>
                    <span
                      ><img src="../../assets/icon/人数@2x.png" alt="" /></span
                    ><span>{{ parameter.people_count }}</span>
                  </li>
                  <li>
                    <span
                      ><img src="../../assets/icon/地址@2x.png" alt="" /></span
                    ><span>{{ parameter.address }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 课程详情介绍 -->
            <div class="CourseDetails">
              <ul>
                <li>
                  <div class="title">
                    <span>活动介绍</span>
                    <span></span>
                  </div>
                  <p v-html="activityInfo.description">
                    {{ activityInfo.description }}
                  </p>
                </li>
                <li>
                  <div class="title">
                    <span>活动详情</span>
                    <span></span>
                  </div>
                  <p v-html="activityInfo.content"></p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 底部立即报名模块 -->
        <div class="signUp">
          <div
            class="btn"
            v-if="2 == activityInfo.is_sign && activityInfo.is_online == 2"
          >
            <span>{{
              activityInfo.is_pay == 1
                ? activityInfo.price_str
                : activityInfo.price_str + activityInfo.unit
            }}</span>
            <el-button type="primary" @click="ClickJoin">立即报名</el-button>
          </div>
          <div
            class="btn"
            v-if="2 == activityInfo.is_sign && activityInfo.is_online == 1"
          >
            <span></span>

            <el-button type="primary">报名未开始，敬请期待</el-button>
          </div>
          <div
            class="btn"
            v-if="2 == activityInfo.is_sign && activityInfo.is_online == 3"
          >
            <span></span>

            <el-button type="primary">报名已结束</el-button>
          </div>
          <div class="btn" v-if="1 == activityInfo.is_sign">
            <span></span>
            <el-button style="background: #5f6369">已报名</el-button>
          </div>
        </div>
      </div>

      <el-dialog
        title="填写报名信息"
        :visible.sync="dialogFormVisible"
        :show-close="false"
        width="90%"
       @close="addDialogClose"

      >
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item
            label="联系人:"
            :label-width="formLabelWidth"
             prop="lx_name"
          >
            <el-input
              style="width: 90%"
              v-model="form.lx_name"
              placeholder="请输入联系人"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号:"
            :label-width="formLabelWidth"
            :rules="[{ required: true, message: '手机号不能为空' }]"
          >
            <el-input
              style="width: 90%"
              v-model="form.lx_phone"
              placeholder="请输入真实联系电话"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="报名人数:"
            :label-width="formLabelWidth"
            :rules="[{ required: true, message: '报名人数不能为空' },{type:"number"}]"
          >
            <el-input
              style="width: 90%"
              v-model.number="form.people_num"
              placeholder="请输入报名人数"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('form')">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!--    报名填写信息框-->
    <!--    <el-button type="text" @click="dialogFormVisible = true">打开嵌套表单的 Dialog</el-button>-->

    <el-dialog
      title="填写报名信息"
      :visible.sync="dialogFormVisible"
      :show-close="false"
      width="30%"
       @close="addDialogClose"

    >
      <el-form :model="form" :rules="rules"  ref="form">
        <el-form-item
          label="联系人:"
          :label-width="formLabelWidth"
          prop="lx_name"
          
        
        >
          <el-input
            style="width: 90%"
            v-model="form.lx_name"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号:"
          :label-width="formLabelWidth"
          :rules="[{ required: true, message: '手机号不能为空'}]"
        >
          <el-input
            style="width: 90%"
            v-model="form.lx_phone"
            placeholder="请输入真实联系电话"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="报名人数:"
          :label-width="formLabelWidth"
          :rules="[{ required: true, message: '报名人数不能为空'}]"
        >
          <el-input
            style="width: 90%"
            v-model="form.people_num"
            placeholder="请输入报名人数"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from "../../components/header";
import { Message } from "element-ui";
import router from "../../router";

export default {
  components: {
    Header,
  },
  metaInfo() {
    return {
      title: this.activityInfo.address
        ? this.activityInfo.address + " | 新税网"
        : window.location.href,

      meta: [
        {
          name: "description",
          content: this.activityInfo.description,
        },
        {
          name: "keywords",
          content: this.activityInfo.address,
        },
        {
          name: "author",
          content: this.activityInfo.nickname + " - 新税网",
        },
      ],
    };
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,

      // 活动信息 直播时间 报名人数列表
      parameter: {},
      // 活动详情
      activityInfo: {},
      // 活动详情id
      id: this.$route.query.id,

      // 薪税课堂列表
      classRoomList: "",
      dialogFormVisible: false,
      form: {
        lx_name: "",
        lx_phone: "",
        people_num: "",
      },
      formLabelWidth: "120px",
      fullscreenLoading: false,
      rules: {
        lx_name: [
          { required: true, message: "请输入中文的标题", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              //校验中文的正则：/^[\u4e00-\u9fa5]{0,}$/
              if (
                /^[\u4e00-\u9fa5]+$/.test(value) == false &&
                /[a-zA-z]$/.test(value) == false
              ) {
                callback(new Error("请输入只含汉字或字母"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 获取活动详情
    this.getEventDetails();

    // 获取薪税课堂列表
    this.getClassRoomList();
  },
  methods: {
    addDialogClose() {
      this.$refs.form.resetFields();
    },
    // 表单提交
    submitForm(form) {
      let lx_name = this.form.lx_name;
      if (!lx_name) {
        Message.error("请填写联系人");
        return false;
      }
      let lx_phone = this.form.lx_phone;
      if (!lx_phone) {
        Message.error("请填写手机号");
        return false;
      }
      let people_num = this.form.people_num * 1;
      if (!people_num) {
        Message.error("请填写报名人数");
        return false;
      }
      if (this.form.people_num < 1) {
        Message.error("报名人数输入不正确");
        return false;
      }

      // 2是否免费
      console.log(this.activityInfo);

      if (this.activityInfo.price == 0) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            // 免费加入的接口
            this.$http
              .post("/index.php/api/v1_0_0.activity/activity_sign_free", {
                id: this.id,
                lx_name: lx_name,
                lx_phone: lx_phone,
                people_num: people_num.toFixed(0),
              })
              .then((res) => {
                console.log(res);
                Message.success("活动报名成功");
                // 更新报名状态
                this.activityInfo.is_sign = 1;
                // 关闭莫太狂
                this.dialogFormVisible = false;
                // 更新报名人数
                this.parameter.people_count =
                  this.parameter.people_count + people_num;
                return false;
              })
              .catch((error) => {
                return false;
              });
            return false;
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
      // 3不免费，去请求需要支付的金额
      // TODO 请求是否需要充值显示输入密码还是去充值
      if (this.activityInfo.price !== 0) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            this.$http
              .post("/index.php/api/v1_0_0.activity/activity_sign", {
                id: this.id,
                lx_name: lx_name,
                lx_phone: lx_phone,
                people_num: people_num,
              })
              .then((res) => {
                console.log(res);
                // 余额足够
                if (res.data.balance) {
                  if (res.data.safe_password) {
                    this.$confirm(res.msg, "提示", {
                      confirmButtonText: "去设置",
                      cancelButtonText: "取消",
                      type: "warning",
                    })
                      .then(() => {
                        this.$router.push({
                          path: "/personal/financial",
                          query: {
                            tabs: "支付密码设置",
                          },
                        });
                      })
                      .catch(() => {});
                  } else {
                    this.$prompt("请输入密码", res.msg, {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      inputPlaceholder: "请输入支付密码",
                      inputType: "password",
                      inputValidator: function (v) {
                        if (!v) return "请输入支付密码";
                      },
                    })
                      .then(({ value }) => {
                        this.$http
                          .post("/index.php/api/v1_0_0.activity/do_pay", {
                            id: this.id,
                            password: value,
                          })
                          .then((res) => {
                            if (res.data.need_password) {
                              //没有设置支付密码
                              this.$confirm(pay.msg, "提示", {
                                confirmButtonText: "设置",
                                cancelButtonText: "取消",
                                type: "warning",
                              })
                                .then(() => {
                                  this.$router.push({
                                    path: "/personal/financial",
                                    query: {
                                      tabs: "支付密码设置",
                                    },
                                  });
                                })
                                .catch(() => {});
                              return;
                            }

                            // 加入成功，进入直播页面
                            Message.success("活动报名成功");
                            // 更新报名状态
                            this.activityInfo.is_sign = 1;
                            // 关闭莫太狂
                            this.dialogFormVisible = false;
                            // 更新报名人数
                            this.parameter.people_count =
                              this.parameter.people_count + people_num;
                          })
                          .catch((error) => {});
                      })
                      .catch(() => {
                        this.$message({
                          type: "info",
                          message: "已取消支付",
                        });
                      });
                  }
                } else {
                  this.$confirm(res.msg, "余额不足", {
                    confirmButtonText: "去充值",
                    cancelButtonText: "取消",
                    type: "warning",
                  })
                    .then(() => {
                      // TODO 跳转充值页面,带参数
                      this.$router.push({
                        path: "/personal/financial",
                        query: {
                          tabs: "我的余额",
                        },
                      });
                    })
                    .catch(() => {
                      this.$message({
                        type: "info",
                        message: "已取消充值",
                      });
                    });
                }
              })
              .catch((error) => {});
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    // 获取活动详情
    getEventDetails() {
      this.fullscreenLoading = true;

      this.$http
        .post("/index.php/api/v1_0_0.activity/get_detail", {
          id: this.id,
        })
        .then((res) => {
          this.fullscreenLoading = false;

          console.log("活动详情");
          console.log(res.data);
          this.activityInfo = res.data;
          this.parameter = res.data.mid;
        })
        .catch(({ error }) => {});
    },
    // 点击更多跳转薪税课堂首页
    ClickClassRoom() {
      this.$router.push("/hd");
    },
    // 获取薪税活动列表
    getClassRoomList() {
      this.$http
        .post("/index.php/api/v1_0_0.activity/get_recommend", {
          id: this.id,
        })
        .then((res) => {
          console.log(res.data.list);
          this.classRoomList = res.data.list;
        })
        .catch((error) => {});
    },
    // 点击薪税列表项
    ClickclassRoomList(item) {
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {
            this.$router.push({
              path: "/hd/activityDetails",
              query: { id: item.id },
            });
            location.reload();
          });
        return;
      }
      this.$router.push({
        path: "/hd/activityDetails",
        query: { id: item.id },
      });
      location.reload();
      // this.id = item.id;
      // this.getEventDetails();
    },

    // 报名活动
    ClickJoin() {
      // 1是否登录
      if (
        !localStorage.getItem("user_id") ||
        !localStorage.getItem("user_token")
      ) {
        Message.error("请登录后报名");
        router.replace({
          path: "/login",
          query: {
            redirect: router.currentRoute.fullPath, //登录成功后跳入浏览的当前页面
          },
        });
        return false;
      }
      this.dialogFormVisible = true;
      return false;
    },
    // 点赞 或 取消点赞
    ClickThumbs() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_praise", {
          id: this.id,
          type: 2,
        })
        .then((res) => {
          Message.success("操作成功").onClose(() => {});
          this.activityInfo.praise_count = res.data.praise_count;
          this.activityInfo.is_praise = res.data.is_praise;
          console.log(res.data);
        })
        .catch((error) => {});
    },

    // 收藏 或 取消收藏
    ClickCollection() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_collect", {
          id: this.id,
          type: 2,
        })
        .then((res) => {
          Message.success("操作成功").onClose(() => {});
          this.activityInfo.is_collect = res.data.is_collect;
          this.activityInfo.collect_count = res.data.collect_count;
          console.log(res);
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang='less' scoped>
.pc {
  .classroom {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // padding: 0 10.45%;
    padding-top: 30px;
    background-color: #f5f5f5;
    .classroom_left {
      width: 66%;
      height: auto;
      // background-color: red;
      .curriculum {
        .image {
          width: 100%;
          height: 480px;
          overflow: hidden;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: #fff;
          }
        }
        .date {
          display: flex;
          justify-content: space-between;
          align-items: center;
          div:nth-child(1) {
            text-align: left;
            display: flex;
            justify-content: start;
            margin: 20px 0;
            span:nth-child(1) {
              font-size: 20px;
              font-weight: 500;
              color: #292931;
              line-height: 28px;
              margin-right: 13px;
            }
            span:nth-child(2) {
              /*width: 60px;*/
              height: 24px;
              line-height: 24px !important;
              background: #1677ff;
              border-radius: 2px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              line-height: 17px;
              text-align: center;
            }
          }
          .thumbs {
            display: flex;
            justify-content: center;
            // margin: 30px 0;

            div {
              width: 100px;
              height: 35px;
              background: #eee;
              border-radius: 25px;
              font-weight: 400;
              color: #999;
              margin: 0 11px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #eee;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              cursor: pointer;
              img {
                display: inline-block;
                width: 16px;
                height: 16px;
                padding: 0 5px;
              }
            }
            div:nth-child(2) {
              border: 1px solid #eee;
              background-color: #fff;
              color: #999;
            }
          }
        }
        .parameter {
          padding-bottom: 30px;
          ul {
            li {
              display: flex;
              justify-content: start;
              align-items: center;
              margin: 5px 0;
              span:nth-child(1) {
                width: 14px;
                height: 14px;
                line-height: 14px;
                margin-right: 10px;
                img {
                  width: 100%;
                  height: 100%;
                  // background-color: pink;
                }
              }
              span:nth-child(2) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #848592;
              }
            }
          }
        }
      }
      .CourseDetails {
        ul {
          margin-bottom: 150px;
          li {
            text-align: left;
            margin-top: 10px;
            .title {
              span:nth-child(1) {
                font-size: 18px;
                font-weight: 600;
                color: #292931;
              }
              span:nth-child(2) {
                display: block;
                width: 60px;
                height: 3px;
                background: #1677ff;
                margin-top: 5px;
              }
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #303030;
              line-height: 22px;
              letter-spacing: 2px;
              padding: 20px 0;
            }
          }
        }
      }
    }
    .classroom_right {
      width: 33%;
      height: 100%;
      // background-color: blue;
      .newest {
        padding: 5px 20px 5px 20px;
        background-color: #ffffff;
        margin-bottom: 10px;

        .top {
          height: 55px;
          line-height: 55px;
          display: flex;
          justify-content: space-between;
          .left {
            p {
              display: inline-block;
              cursor: pointer;
              margin-right: 20px;
              font-size: 16px;
              height: 40px;

              font-size: 18px;
              font-weight: 600;
              color: #292931;
              border-bottom: 3px solid #1677ff;
            }
            // .textStyle {
            //   font-size: 18px;
            //   font-weight: 600;
            //   color: #292931;
            //   border-bottom: 3px solid #1677ff;
            // }
          }
          .right {
            margin-top: 5px;
            cursor: pointer;
          }
        }
        .bottom {
          margin-top: 10px;
          padding-bottom: 90px;
          ul {
            li {
              margin-bottom: 20px;
              cursor: pointer;
              .img {
                width: 100%;
                height: 210px;
                overflow: hidden;
                img {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                }
              }
              P {
                text-align: left;
              }
              p:nth-child(2) {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #292931;
                margin: 10px 0;
              }
              p:nth-child(3) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9496a7;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  .signUp {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2b3442;
    border: 1px solid #979797;
    z-index: 2;
    .btn {
      margin-left: 45%;
      span {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 33px;
      }
      .el-button {
        /*width: 180px;*/
        height: 54px;
        margin-left: 50px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        border-radius: 8px;
        background: #1677ff;
        border: 0;
      }
    }
  }
}
.activitDetails_mb {
  padding: 60px 0 50px 0;
  .classroom {
    display: flex;
    justify-content: space-between;
    padding: 0;
    background-color: #fff;
    .classroom_left {
      width: 100%;
      height: auto;
      // background-color: red;
      .curriculum {
        .image {
          width: 100%;
          height: 180px;
          overflow: hidden;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: #fff;
          }
        }
        .date {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          margin: 20px 0;
          div:nth-child(1) {
            text-align: left;
            display: flex;
            justify-content: start;
            align-items: center;
            margin: 0;
            span:nth-child(1) {
              font-size: 15px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 700;
              color: #292931;
              line-height: 28px;
              margin-right: 13px;
            }
            span:nth-child(2) {
              width: 50px;
              height: 20px;
              line-height: 15px !important;
              background: #1677ff;
              border-radius: 2px;
              font-weight: 400;
              text-align: center;
              span {
                display: inline-block;
                width: 50px;
                height: 20px;
                line-height: 20px;
                color: #ffffff;
                font-size: 12px;
                margin: 0;
                transform: scale(0.8);
              }
            }
          }
          .thumbs {
            display: flex;
            justify-content: center;
            // margin: 30px 0;

            div {
              padding: 10px;
              width: 85px;
              height: 30px;
              background: #eee;
              border-radius: 25px;
              font-weight: 400;
              color: #999;
              margin: 0 11px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #eee;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              box-sizing: border-box;
              cursor: pointer;
              img {
                display: inline-block;
                width: 16px;
                height: 16px;
                padding: 0 5px;
              }
            }
            div:nth-child(2) {
              border: 1px solid #eee;
              background-color: #fff;
              color: #999;
            }
          }
        }
        .parameter {
          padding: 0 10px;
          ul {
            li {
              display: flex;
              justify-content: start;
              align-items: center;
              margin: 6px 0;
              span:nth-child(1) {
                width: 14px;
                height: 14px;
                line-height: 14px;
                margin-right: 10px;
                img {
                  width: 100%;
                  height: 100%;
                  // background-color: pink;
                }
              }
              span:nth-child(2) {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #848592;
                line-height: 17px;
              }
            }
          }
        }
      }
      .CourseDetails {
        padding: 0 10px;
        ul {
          margin-bottom: 150px;
          li {
            text-align: left;
            margin-top: 10px;
            .title {
              span:nth-child(1) {
                font-size: 15px;
                font-weight: 600;
                color: #292931;
              }
              span:nth-child(2) {
                display: block;
                width: 60px;
                height: 3px;
                background: #1677ff;
                margin-top: 5px;
              }
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #303030;
              line-height: 22px;
              letter-spacing: 2px;
              padding: 20px 0;
            }
          }
        }
      }
    }
  }
  .signUp {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2b3442;
    border: 1px solid #979797;
    z-index: 2;
    .btn {
      width: 100%;
      margin-left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // flex-direction: row-reverse;
      margin: 0 10px;
      span {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 33px;
      }
      .el-button {
        /*width: 180px;*/
        display: flex;
        align-items: center;
        height: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        border-radius: 8px;
        background: #1677ff;
        border: 0;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
      }
    }
  }
}
</style>
