<template>
  <!-- 文章推荐列表组件 -->
  <div v-loading="fullscreenLoading" style="min-height: 67vh">
    <!-- 文章列表内容 -->
    <!-- 文章上 -->
    <!-- <div v-if="show_pc">
      <div class="text" v-if="path == '/zx'">
        {{ this.$store.state.query.classText }}
      </div>
    </div> -->

    <!-- PC端 -->
    <div v-if="show_pc">
      <div
        class="articleConment"
        v-for="(item, index) in articleListTop"
        :key="item.id"
        :class="{ hover: hoverId == item.id }"
        @click="infoDetails(item)"
        @mouseover="MouseHover(item.id)"
      >
        <!-- 左侧图片 -->
        <div class="image"><img :src="item.cover" alt="" /></div>
        <!-- 右侧内容 -->
        <div class="conment">
          <p>{{ item.title }}</p>
          <div class="messages" v-html="item.description">
            {{ item.description }}
          </div>
          <div class="price">
            <div>
              <span>{{ item.com_from }} </span>
              <span> {{ item.time_tmp }}</span>
            </div>
            <!--            <span :class="{ Color: 2 == item.is_pay }">{{-->
            <!--              item.is_pay == 2-->
            <!--                ? item.is_owner-->
            <!--                  ? "已购买"-->
            <!--                  : item.price_str-->
            <!--                : item.price_str-->
            <!--            }}</span>-->
            <span v-if="item.is_pay == 2" :class="{ Color: 2 == item.is_pay }"
              >付费阅读</span
            >
          </div>
        </div>
      </div>

      <!-- 广告 -->
      <div
        class="banner"
        v-if="zjgk.img_url"
        @click="ClickBanner(zjgk.target_url)"
      >
        <img :src="zjgk.img_url" alt="" />
        <p>广告</p>
      </div>

      <!-- 文章下 -->
      <div
        class="articleConment"
        v-for="(item, index) in articleListBottom"
        :key="item.id"
        @click="infoDetails(item)"
        :class="{ hover: hoverId == item.id }"
        @mouseover="MouseHover(item.id)"
      >
        <!-- 左侧图片 -->
        <div class="image"><img :src="item.cover" alt="" /></div>
        <!-- 右侧内容 -->
        <div class="conment">
          <p>{{ item.title }}</p>
          <div class="messages">
            {{ item.description }}
          </div>
          <div class="price">
            <div>
              <span>{{ item.com_from }} </span>
              <span> {{ item.time_tmp }}</span>
            </div>
            <!--            <span :class="{ Color: 2 == item.is_pay }">{{-->
            <!--              item.is_pay == 2-->
            <!--                ? item.is_owner-->
            <!--                  ? "已购买"-->
            <!--                  : item.price_str-->
            <!--                : item.price_str-->
            <!--            }}</span>-->
            <span v-if="item.is_pay == 2" :class="{ Color: 2 == item.is_pay }"
              >付费阅读</span
            >
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <!-- 底部分页 -->
      <div class="page" v-if="total > 10">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.pagenum"
          :page-size="queryInfo.pagesize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="articleConment_box">
        <div
          class="articleConment_md"
          v-for="(item, index) in allList"
          :key="item.id"
          :class="{ hover: hoverId == item.id }"
          @click="infoDetails(item)"
          @mouseover="MouseHover(item.id)"
        >
          <!-- 左侧图片 -->
          <div class="image"><img :src="item.cover" alt="" /></div>
          <!-- 右侧内容 -->
          <div class="conment">
            <p>{{ item.title }}</p>

            <div class="price">
              <div>
                <span>{{ item.com_from }} </span>
                <span> {{ item.time_tmp }}</span>
              </div>
              <!--              <span :class="{ Color: 2 == item.is_pay }">{{-->
              <!--                item.is_pay == 2-->
              <!--                  ? item.is_owner-->
              <!--                    ? "已购买"-->
              <!--                    : item.price_str-->
              <!--                  : item.price_str-->
              <!--              }}</span>-->
              <span v-if="item.is_pay == 2" :class="{ Color: 2 == item.is_pay }"
                >付费阅读</span
              >
            </div>
          </div>
        </div>
        <div class="more" v-if="allList.length !== total" @click="ClickMore">
          加载更多
        </div>
        <div class="more" v-if="allList.length == total">没有更多了...</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";

export default {
  // 同步资讯id
  computed: {
    getInfoId() {
      return this.$store.state.query.classId;
    },
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 获取路径
      path: this.$route.path,
      // 资讯id

      // 文章列表的中间广告
      zjgk: "",
      // 查询文章列表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 15,
      },
      // 总条数
      total: 0,

      // 默认添加遮罩层
      hoverId: 0,
      // 广告以上的5条
      articleListTop: [],
      // 广告5条一下
      articleListBottom: [],

      // 全部文章列表
      allList: "",
      fullscreenLoading: false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;

    console.log(this.$store.state.query.classId);
    this.getArticle();
  },
  methods: {
    //获取文章列表
    getArticle() {
      this.fullscreenLoading = true;
      this.$http
        .post("/index.php/api/v1_0_0.article/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          cid: this.$store.state.query.classId,
          is_recommend: this.path == "/zx" ? "" : 1,
        })
        .then((res) => {
          console.log("文章列表数据");
          const list = res.data.list;
          this.allList = list;
          this.articleListTop = list.slice(0, 5);
          this.articleListBottom = list.slice(5);
          console.log(this.articleListBottom);
          this.total = res.data.total_count;
          this.zjgk = res.data.advert;
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },

    // 查看文章列表详情函数
    infoDetails(item) {
      console.log(item);
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }
      // return;
      this.$http
        .post("/index.php/api/v1_0_0.article/view_log", {
          id: item.id,
        })
        .then(() => {
          this.$router.push({
            path: "/zx/details",
            query: {
              id: item.id,
            },
          });
        })
        .catch(() => {});

      return false;
      // 直接进入详情
      if (item.is_pay == 1 || item.is_owner) {
        this.$router.push({
          path: "/zx/details",
          query: {
            id: item.id,
          },
        });
        return false;
      }

      // 需要付费
      if (item.is_pay == 2) {
      }
    },

    // 鼠标经过事件
    MouseHover(id) {
      this.hoverId = id;
    },

    // 广告点击事件
    ClickBanner(url) {
      if (url) {
        window.open = (url, "_blank");
      }
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getArticle();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.getArticle();
    },
    // 手机
    // 加载更多
    ClickMore() {
      this.queryInfo.pagenum++;
      this.$http
        .post("/index.php/api/v1_0_0.article/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          cid: this.$store.state.query.classId,
          is_recommend: this.$store.state.query.classId ? 0 : 1,
        })
        .then((res) => {
          console.log("文章列表数据");

          res.data.list.forEach((item) => {
            this.allList.push(item);
          });

          this.total = res.data.total_count;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
  },
  watch: {
    // 监听分类id发生变化 来调用 资讯列表函数
    getInfoId() {
      this.getArticle();
    },
  },
};
</script>
<style lang='less' scoped>
.text {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #292931;
  line-height: 33px;
  margin: 0 0 20px 6px;
}

.articleConment {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 190px;
  background-color: #fff;
  padding: 20px 10px;
  box-sizing: border-box;
  margin-bottom: 10px;

  .image {
    width: 31%;
    height: 150px;
    background-color: #999;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .conment {
    display: inline-block;
    width: 67%;
    height: 150px;
    padding-left: 13px;
    text-align: left;
    cursor: pointer;

    p {
      font-size: 18px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #292931;
    }

    .messages {
      // height: 55%;
      height: 60px;
      margin: 10px 0 30px;
      box-sizing: border-box;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #808080;
      line-height: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      cursor: pointer;
      letter-spacing: 1px;
    }

    .price {
      display: flex;
      justify-content: space-between;
      height: 12px;
      line-height: 12px;
      padding-right: 15px;
      div {
        span {
          display: inline-block;
          height: 100%;
          color: #a8aeb8;
          font-size: 12px;
          font-weight: normal;
        }

        span:nth-child(1) {
          border-right: 1px solid #a8aeb8;
          padding-right: 5px;
          letter-spacing: 1px;
        }

        span:nth-child(2) {
          padding-left: 5px;
          letter-spacing: 1px;
        }
      }

      span {
        color: #1677ff;
        font-size: 12px;
        font-weight: 800;
      }
    }
  }
}

.hover {
  background: #f9fafb;
}

.page {
  text-align: center;
  padding: 25px 0 50px 0;
}

// 控制付费的样得字体颜色
.Color {
  color: #ff5916 !important;
}

.banner {
  // width: 100%;
  height: 200px;
  position: relative;
  margin: 22px 0px 29px 10px;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;

  img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  p {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 30px;
    text-align: center;
    background: #1677ff;
    border-radius: 0px 0px 0px 20px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
  }
}

// 移动端

.articleConment_box {
  // margin-bottom: 94px;
  min-height: 35vh;
  .articleConment_md {
    display: flex;
    justify-content: start;
    margin-bottom: 5px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    .image {
      width: 29.87%;
      height: 70px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .conment {
      margin-left: 8px;
      width: 68.13%;

      p {
        text-align: left;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 700;
        color: #292931;
        line-height: 20px;
        height: 40px;
        overflow: hidden; //强制多行显示
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        letter-spacing: 0.5px;
      }

      .price {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1677ff;

        div {
          width: 75%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          span {
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a8aeb8;
            line-height: 14px;
            letter-spacing: 1px;
            -webkit-transform: scale(0.2);
          }
        }

        span {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1677ff;
          line-height: 20px;
        }
      }
    }
  }

  .more {
    width: 128px;
    height: 36px;
    line-height: 36px;
    background: #ffffff;
    margin: 20px auto 20px;
    border: 2px solid #e5e6ea;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #555c69;
  }

  /deep/.el-message-box {
    width: 300px;
    z-index: 10000;
  }
}
</style>
