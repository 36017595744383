<template>
  <div>
    <Header></Header>

    <!-- PC端 -->
    <div v-if="show_pc">
      <div
        class="login"
        :style="{
          backgroundImage: 'url(' + registerURl + ')',
        }"
      >
        <div class="register_form">
          <div class="register">
            <p>注册新账户</p>
            <p @click="ClickLogin" style="cursor: pointer">登录</p>
          </div>
          <!-- 手机号码 -->
          <div class="phone">
            <i class="el-icon-mobile-phone" size="16px"></i
            ><input
              v-model="fromData.phoneInput"
              type="number"
              placeholder="请输入手机号"
              maxlength="11"
            />
          </div>
          <!-- 图形验证码 -->
          <div class="code">
            <i class="el-icon-lock" size="16px"></i
            ><input
              v-model="fromData.imgCodeInput"
              type="number"
              placeholder="请输入图形验证码"
            />
            <div
              class="img"
              @click="ClickImgCode"
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(225, 225, 225, 0.3)"
            >
              <img v-if="!loading" :src="imgCode" alt="" />
            </div>
          </div>
          <!-- 验证码 -->
          <div class="code">
            <i class="el-icon-lock" size="16px"></i
            ><input
              v-model="fromData.codeInput"
              type="number"
              placeholder="请输入验证码"
            />
            <button style="padding: 0 10px">
              <span
                v-show="code.show"
                @click="getCode"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                "
                >发送验证码</span
              >
              <span v-show="!code.show" class="count"
                >{{ code.count }}s后重新发送</span
              >
            </button>
          </div>

          <!-- 设置密码 -->
          <div class="password">
            <i class="el-icon-lock" size="16px"></i
            ><input
              v-model="fromData.passwordInput1"
              type="password"
              placeholder="请设置密码"
            />
          </div>
          <!-- 重复输入密码 -->
          <div class="password">
            <i class="el-icon-lock" size="16px"></i
            ><input
              v-model="fromData.passwordInput2"
              type="password"
              placeholder="请重复密码"
            />
          </div>
          <div class="agreement">
            <el-checkbox v-model="checked"></el-checkbox
            ><span @click="ClickAgreement">请勾选同意《用户注册协议》</span>
          </div>
          <div class="register_btn" @click="reg">注&nbsp册</div>
        </div>
        <el-dialog
          :title="agreementInfo.title"
          :visible.sync="agreementVisible"
          width="50%"
          top="20vh"
        >
          <span v-if="!loading" v-html="agreementInfo.content">{{
            agreementInfo.content
          }}</span>
          <div
            v-if="loading"
            v-loading="loading"
            style="width: 100%; height: 100%"
          ></div>
        </el-dialog>
      </div>
    </div>

    <!-- 移动端 -->

    <div v-if="!show_pc">
      <div class="register_mb">
        <div
          class="login"
          :style="{
            backgroundImage: 'url(' + registerURl + ')',
          }"
        >
          <div class="register_form">
            <div class="register">
              <p>注册新账户</p>
              <p @click="ClickLogin" style="cursor: pointer">登录</p>
            </div>
            <div class="phone">
              <i class="el-icon-mobile-phone" size="16px"></i
              ><input
                v-model="fromData.phoneInput"
                type="number"
                placeholder="请输入手机号"
                maxlength="11"
              />
            </div>
            <!-- 图形验证码 -->
            <div class="code">
              <i class="el-icon-lock" size="16px"></i
              ><input
                v-model="fromData.imgCodeInput"
                type="number"
                placeholder="请输入图形验证码"
              />
              <div
                class="img"
                @click="ClickImgCode"
                v-loading="loading"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(225, 225, 225, 0.3)"
              >
                <img v-if="!loading" :src="imgCode" alt="" />
              </div>
            </div>
            <!-- 发送验证码 -->
            <div class="code">
              <i class="el-icon-lock" size="16px"></i
              ><input
                v-model="fromData.codeInput"
                type="number"
                placeholder="请输入验证码"
              />
              <button style="padding: 0 10px">
                <span v-show="code.show" @click="getCode">发送验证码</span>
                <span v-show="!code.show" class="count"
                  >{{ code.count }}s后重新发送</span
                >
              </button>
            </div>

            <div class="password">
              <i class="el-icon-lock" size="16px"></i
              ><input
                v-model="fromData.passwordInput1"
                type="password"
                placeholder="请设置密码"
              />
            </div>
            <div class="password">
              <i class="el-icon-lock" size="16px"></i
              ><input
                v-model="fromData.passwordInput2"
                type="password"
                placeholder="请重复密码"
              />
            </div>
            <div class="agreement">
              <el-checkbox v-model="checked"></el-checkbox
              ><span @click="ClickAgreement">请勾选同意《用户注册协议》</span>
            </div>
            <div class="register_btn" @click="reg">注&nbsp册</div>
          </div>
        </div>
        <el-dialog
          :title="agreementInfo.title"
          :visible.sync="agreementVisible"
          width="50%"
          top="5vh"
        >
          <span v-if="!loading" v-html="agreementInfo.content">{{
            agreementInfo.content
          }}</span>

          <div
            v-if="loading"
            v-loading="loading"
            style="width: 100%; height: 100%"
          ></div>
        </el-dialog>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../header";
import Footer from "../footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      checked: "",
      agreementInfo: "",
      agreementVisible: false,
      loading: false,
      // 图形验证码加载...
      loading: false,
      // 默认显示 PC端的css
      show_pc: true,
      // 登录背景图片
      registerURl: "",
      fromData: {
        //   电话号码输入框
        phoneInput: "",
        // 图片验证码
        imgCodeInput: "",

        //   设置新密码输入框
        passwordInput1: "",
        //   重新输入密码
        passwordInput2: "",
        // 图片验证码输入框
        imgCodeInput: "",
      },
      // 验证码图片
      imgCode: "",
      // 验证码key
      key: "",

      // 发送验证码,
      code: {
        show: true,
        count: "",
        timer: null,
      },
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;

    // 获取图片地址
    this.getImgCode();

    if (!localStorage.getItem("userbg")) {
      this.$http
        .get("/index.php/api/v1_0_0.ordinary/get_web_info")
        .then(({ data: res }) => {
          const userbg = res.data.back_url;
          localStorage.setItem("userbg", userbg);
          this.registerURl = localStorage.getItem("userbg");
        })
        .catch((error) => {});
    }
    this.registerURl = localStorage.getItem("userbg");
  },
  methods: {
    ClickAgreement() {
      this.agreementVisible = true;
      this.loading = true;
      this.$http
        .post("index.php/api/v1_0_0.ordinary/get_user_register", {})
        .then(({ data }) => {
          this.agreementInfo = data;
          this.loading = false;
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取图片地址
    getImgCode() {
      // 图形验证码加载...
      this.loading = true;
      this.$http
        .post("index.php/api/v1_0_0.ordinary/get_verify")
        .then((res) => {
          console.log("图片");
          // 图形验证码加载...
          this.loading = false;
          this.imgCode = res.data.verify;
          this.key = res.data.key;
          console.log(res.data);
        })
        .catch(() => {});
    },
    // 刷新图形验证码
    ClickImgCode() {
      this.getImgCode();
    },
    //点击注册
    reg() {
      let pid = this.$route.query.pid == undefined ? 0 : this.$route.query.pid;
      console.log(pid);
      console.log(this.fromData);
      let fromData = this.fromData;
      if (fromData.phoneInput == "") {
        this.$message.error("请输入手机号");
        return;
      }
      if (fromData.phoneInput.length < 11 || fromData.phoneInput.length > 11) {
        this.$message.error("手机号格式不正确");
        return;
      }
      if (fromData.imgCodeInput == "") {
        this.$message.error("请输入图形验证码");
        return;
      }

      if (fromData.passwordInput1 == "") {
        this.$message.error("请设置密码");
        return;
      }
      if (fromData.passwordInput2 == "") {
        this.$message.error("请重复密码");
        return;
      }
      if (fromData.passwordInput1 != fromData.passwordInput2) {
        this.$message.error("两次密码输入不一致，请重新输入");
        return;
      }
      if (fromData.codeInput == "") {
        this.$message.error("请输入验证码");
        return;
      }
      if (!this.checked) {
        this.$message.error("请勾选用户注册协议");
        return;
      }

      this.$http
        .post("index.php/api/v1_0_0.ordinary/check", {
          verify_code: fromData.imgCodeInput,
          key: this.key,
        })
        .then(() => {
          this.$http
            .post("/index.php/api/v1_0_0.login/do_register", {
              phone: fromData.phoneInput,
              password: fromData.passwordInput1,
              qrpassword: fromData.passwordInput2,
              yzm: fromData.codeInput,
              pid: pid,
              verify_code: fromData.imgCodeInput,
              key: this.key,
            })
            .then((data) => {
              console.log(data, "item");

              localStorage.setItem("user_id", data.data.user_id);
              localStorage.setItem("user_token", data.data.user_token);

              console.log(this.$route.query.redirect);

              let hostName = this.$route.query.redirect; // 获取域名

              if (hostName) {
                // 判断如果域名是你项目域名，说明是从本网站内部跳转过来的，
                this.$router.push(hostName); // 登录成功后，返回上次进入的页面；
              } else {
                this.$router.push("/index");
                this.$message({
                  message: data.msg,
                  type: "success",
                  duration: 1500,
                });
              }
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    // 点击发送短信 倒计时 函数
    getCode() {
      if (
        this.fromData.phoneInput.length < 11 ||
        this.fromData.phoneInput.length > 11
      ) {
        this.$message.error("手机号格式不正确");
        return;
      }
      this.$http
        .post("index.php/api/v1_0_0.ordinary/check", {
          verify_code: this.fromData.imgCodeInput,
          key: this.key,
        })
        .then(() => {
          this.$http
            .post("/index.php/api/v1_0_0.sendmsg/do_sendmsg", {
              phone: this.fromData.phoneInput,
              time_stamp: new Date().getTime(),
              sign: this.$md5(
                this.$md5(
                  this.fromData.phoneInput + "xinshui" + new Date().getTime()
                )
              ),
            })
            .then((data) => {
              this.$message.success("验证码已发送");
              const TIME_COUNT = 60;
              if (!this.code.timer) {
                this.code.count = TIME_COUNT;
                this.code.show = false;
                this.code.timer = setInterval(() => {
                  if (this.code.count > 0 && this.code.count <= TIME_COUNT) {
                    this.code.count--;
                  } else {
                    this.code.show = true;
                    clearInterval(this.code.timer);
                    this.code.timer = null;
                  }
                }, 1000);
              }
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    },
    // 点击登录 跳转登录页面
    ClickLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-dialog__title {
  font-weight: 600;
}
.login {
  /deep/.el-dialog__body {
    height: 500px;
    overflow: auto;
  }
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  background-size: cover;

  .register_form {
    /*width: 463px;*/

    /*// width: 24.11%;*/
    /*height: 79.58%;*/
    /*background: #fff;*/
    /*margin: 75px auto;*/
    /*padding: 40px;*/
    width: 463px;
    height: 674px;
    box-sizing: border-box;

    background: #fff;
    margin: 75px auto;
    padding: 40px;

    .register {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 40px 0;
      text-align: left;

      p:nth-child(1) {
        font-size: 33px;
        font-weight: 500;
        color: #252f3f;
        line-height: 46px;
      }

      p:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        color: #252f3f;
        line-height: 22px;
      }
    }

    .phone,
    .code,
    .password {
      background-color: #fff;
      display: flex;
      justify-content: start;
      border-bottom: 1px solid #f4f4f4;
      align-items: center;
      // padding: 30px 0 14px;
      padding: 25px 0 10px;

      i {
        margin-right: 10px;
      }

      input {
        height: 30px;
        width: 78%;
        outline: none;
        border: 0;
        font-size: 14px;
        font-weight: 400;
        color: #7e7e7e;
        line-height: 20px;
      }

      button {
        width: 120px;
        height: 30px;
        background: #f1f5f6;
        font-size: 12px;
        font-weight: 400;
        color: #1677ff;
        line-height: 17px;
        border: 0;
        outline: none;
        cursor: pointer;
      }
      .img {
        width: 80px;
        height: 30px;
        // background-color: pink;
        overflow: hidden;
        /deep/.el-loading-spinner {
          margin-top: -7px;
        }
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .agreement {
      margin: 70px 0 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 10px;
        cursor: pointer;
        color: #1677ff;
      }
    }

    .register_btn {
      margin: 0px 15.2% 32px;
      height: 42px;
      background: #1677ff;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 42px;
      cursor: pointer;
    }
  }
}
.register_mb {
  /deep/.el-dialog__body {
    height: 80vh;
    overflow: auto;
  }
  padding-top: 60px;
  .login {
    padding: 0 30px;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background-size: 100% 100%;
  }
  .register_form {
    /*width: 100%;*/
    /*height: 480px;*/
    /*background: #fff;*/
    /*margin: 75px auto 81px;*/
    /*padding: 0 20px;*/
    /*box-sizing: border-box;*/
    width: 100%;
    height: 500px;
    background: #fff;
    margin: 70px auto 80px;
    padding: 0 30px;

    .register {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0 46px 0;
      text-align: left;

      p:nth-child(1) {
        font-size: 30px;
        font-weight: 500;
        color: #252f3f;
        line-height: 46px;
      }

      p:nth-child(2) {
        font-size: 15px;
        font-weight: 400;
        color: #252f3f;
        line-height: 22px;
      }
      .img {
        width: 50px;
        height: 30px;
        background-color: pink;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .phone,
    .code,
    .password {
      background-color: #fff;
      display: flex;
      justify-content: start;
      border-bottom: 1px solid #f4f4f4;
      align-items: center;
      padding: 0;
      margin-top: 20px;

      i {
        margin-right: 10px;
      }

      input {
        width: 78%;
        outline: none;
        border: 0;
        font-size: 14px;
        font-weight: 400;
        color: #7e7e7e;
        line-height: 20px;
      }

      button {
        width: 142px;
        height: 30px;
        background: #f1f5f6;
        font-size: 12px;
        font-weight: 400;
        color: #1677ff;
        line-height: 17px;
        border: 0;
        outline: none;
      }
    }
    .agreement {
      margin-top: 40px;
      span {
        font-size: 12px;
      }
    }
    .register_btn {
      margin: 10px 10px 52px;
      // width: 233px;
      height: 30.86px;
      background: #1677ff;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30.86px;
      cursor: pointer;
    }
  }
}

// 去除input 数字类型 右侧的上下箭头
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
