<template>
  <!-- 个人中心 -->

  <div>
    <div v-if="show_pc">
      <!-- 用户信息绑定列表 -->
      <div class="infor">
        <ul>
          <li
            v-for="(item, index) in usersList"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickUsersList(index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>

      <!-- 填写表单信息区域 -->

      <!-- 个人信息表单 -->
      <el-form
        :model="inforRuleForm"
        :rules="inforRules"
        ref="inforFormRef"
        class="form"
        v-if="i == 0"
      >
        <!-- 用户昵称 -->
        <el-form-item label="用户昵称" prop="user">
          <el-input
            v-model="inforRuleForm.user"
            placeholder="请输入昵称"
          ></el-input>
        </el-form-item>

        <!-- 真是姓名 -->
        <!-- <el-form-item label="真是姓名">
        <el-input v-model="inforRuleForm.name" placeholder="请输入"></el-input>
      </el-form-item> -->

        <!-- 职位 -->
        <el-form-item label="职位">
          <el-input
            v-model="inforRuleForm.post"
            placeholder="请输入"
          ></el-input>
        </el-form-item>

        <!-- 手机号 -->
        <el-form-item label="手机号">
          <el-input
            v-model="inforRuleForm.phone"
            placeholder="请输入"
            :disabled="true"
          ></el-input>
        </el-form-item>

        <!-- 个人信息 -->
        <el-form-item label="个人介绍" prop="grjj">
          <el-input
            type="textarea"
            v-model="inforRuleForm.grjj"
            placeholder="请输入简介"
            :autosize="true"
          ></el-input>
        </el-form-item>

        <!-- 编辑 -->
        <el-form-item>
          <el-button
            type="primary"
            style="
              margin: 30px 0 0 120px;
              display: inline-block;
              background-color: #1677ff;
              width: 104px;
              border-radius: 0;
              height: 34px;
              line-height: 4px;
            "
            @click="ClickInforEdit"
            >{{ inforRuleForm.userBtn }}
          </el-button>
        </el-form-item>
      </el-form>

      <!-- 实名认证 -->
      <div class="form" v-if="i == 1">
        <!-- <p style="font-size: 14px;line-height: 30px;color: #1677FF;" v-if="is_real==0">您的实名认证<span></span></p> -->
        <p
          style="font-size: 14px; line-height: 30px; color: #1677ff"
          v-if="is_real == 3"
        >
          您的实名认证未通过请重新认证
        </p>
        <el-form :model="proveForm" :rules="proveRules" ref="proveRef">
          <!-- 用户姓名 -->
          <el-form-item label="真实姓名" prop="username">
            <el-input
              v-model="proveForm.username"
              placeholder="请输入真实姓名"
              :disabled="disabled"
            ></el-input>
          </el-form-item>

          <!-- 身份证号 -->
          <el-form-item label="身份证号" prop="userid">
            <el-input
              v-model="proveForm.userid"
              placeholder="请输入"
              :disabled="disabled"
            ></el-input>
          </el-form-item>

          <!-- 身份证照片-->
          <div class="sfz">
            <el-form-item label="身份证照片" prop="userphoto">
              <el-upload
                :action="uploadUrl"
                list-type="picture-card"
                :on-preview="ClickBusinPreview"
                :on-remove="ClickBusinRemove"
                :disabled="disabled"
                :show-file-list="false"
                :on-success="success1"
              >
                <div style="line-height: 0; font-size: 12px" class="uploadSFZ">
                  <img :src="proveForm.real_card_img" alt="" />
                  <p>上传手持身份证</p>
                </div>
              </el-upload>

              <el-upload
                :action="uploadUrl"
                list-type="picture-card"
                :on-preview="ClickBusinPreview"
                :on-remove="ClickBusinRemove"
                :disabled="disabled"
                :show-file-list="false"
                :on-success="success2"
              >
                <div style="line-height: 0; font-size: 12px" class="uploadSFZ">
                  <img :src="proveForm.card_img_front" alt="" />
                  <p>上传身份证正面</p>
                </div>
              </el-upload>

              <el-upload
                :action="uploadUrl"
                list-type="picture-card"
                :on-preview="ClickBusinPreview"
                :on-remove="ClickBusinRemove"
                :disabled="disabled"
                :show-file-list="false"
                :on-success="success3"
              >
                <div style="line-height: 0; font-size: 12px" class="uploadSFZ">
                  <img :src="proveForm.card_img_back" alt="" />
                  <p>上传身份证背面</p>
                </div>
              </el-upload>
            </el-form-item>
          </div>

          <!-- 保存 -->
          <el-form-item>
            <el-button
              type="primary"
              @click.stop="ClickProveSave"
              style="
                width: 104px;
                height: 34px;
                line-height: 0px;
                margin: 30px 0 0 120px;
                border-radius: 0;
                background: #1677ff;
              "
              v-if="is_real == 0"
            >
              保存
            </el-button>
            <el-button
              type="primary"
              style="
                width: 104px;
                height: 34px;
                line-height: 0px;
                margin: 30px 0 0 120px;
                border-radius: 0;
                background: #1677ff;
              "
              v-if="is_real == 1"
            >
              已认证
            </el-button>
            <el-button
              type="primary"
              style="
                width: 104px;
                height: 34px;
                line-height: 0px;
                margin: 30px 0 0 120px;
                border-radius: 0;
                background: #1677ff;
              "
              v-if="is_real == 2"
            >
              待审核
            </el-button>
            <el-button
              type="primary"
              @click.stop="ClickProveSave"
              style="
                width: 104px;
                height: 34px;
                line-height: 0px;
                margin: 30px 0 0 120px;
                border-radius: 0;
                background: #1677ff;
              "
              v-if="is_real == 3"
            >
              保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 微信绑定 -->
      <div v-if="i == 2">
        <div class="wx" v-if="!is_bind_wechat">
          <div class="img">
            <img :src="img" alt="" />
          </div>
          <p>扫描二维码绑定微信</p>
        </div>
        <div class="wx" v-if="is_bind_wechat">
          <!--        <div class="img">-->
          <img :src="wechat_avatar" alt="" />
          <!--        </div>-->
          <p>{{ wechat_nickname }}</p>
          <p>已经绑定微信</p>
        </div>
      </div>

      <!-- 推广设置 -->
      <div class="form" v-if="i == 3 && userInfo.is_business != 1">
        <el-form
          v-if="userInfo.member_level > 1"
          :model="businForm"
          :rules="businRules"
          ref="businForm"
        >
          <!-- 手机号 -->
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="businForm.phone"
              placeholder="请输入手机号"
              :disabled="businForm.disabled"
            ></el-input>
          </el-form-item>

          <!-- 业务介绍 -->
          <el-form-item label="业务介绍" prop="ywjs" style="margin-top: 20px">
            <el-input
              type="textarea"
              v-model="businForm.ywjs"
              placeholder="请输入业务介绍"
              :disabled="businForm.disabled"
            ></el-input>
          </el-form-item>

          <!-- 微信二维码 -->
          <el-form-item label="微信二维码" prop="code" style="margin-top: 20px">
            <el-upload
              :action="uploadUrl"
              list-type="picture-card"
              :on-preview="ClickBusinPreview"
              :show-file-list="false"
              :disabled="businForm.disabled"
              :on-success="wxCodeSuccess"
              :on-remove="ClickBusinRemove"
            >
              <img
                :src="businForm.wxCode"
                alt=""
                style="width: 88px; height: 88px"
              />
            </el-upload>
          </el-form-item>

          <!-- 保存 -->
          <el-form-item>
            <el-button
              type="primary"
              @click.stop="ClickbusinForm"
              :disabled="businForm.disabled"
              style="
                width: 104px;
                height: 34px;
                line-height: 0px;
                margin: 30px 0 0 120px;
                border-radius: 0;
                background: #1677ff;
              "
              >{{ businForm.btnContent }}
            </el-button>
          </el-form-item>
        </el-form>
        <div v-if="userInfo.member_level == 1" style="margin-top: 40px">
          <el-button
            v-if="this.$store.state.user.userInfo.is_business !== 1"
            type="primary"
            round
            style="padding: 8px 23px"
            @click="ClickUpgrade"
            >去升级</el-button
          >
          <p style="font-size: 16px; margin-top: 20px">
            升级会员配置自己的推广信息
          </p>
          <p />
        </div>
      </div>
    </div>

    <!-- 会员升级 -->
    <el-dialog
      title="会员升级"
      :visible.sync="upgradeDialog"
      width="540px"
      top="18vh"
    >
      <div class="upgrade_Box" v-loading="loading">
        <!-- 会员升级 进度 -->
        <div class="upgrade">
          <div class="icon" @click="ClickWhite">
            <span v-if="0 == silver"><i></i></span>
            <span v-else
              ><img src="../../../assets/image/白领@2x.png" alt=""
            /></span>
          </div>
          <p :class="{ speed_bg: 1 <= silver }"></p>
          <div class="icon" @click="ClickSilver">
            <span v-if="1 == silver"><i></i></span>
            <span v-else
              ><img src="../../../assets/image/白银@2x.png" alt=""
            /></span>
          </div>
          <p :class="{ speed_bg: 2 <= silver }"></p>
          <div class="icon" @click="ClickGold">
            <span v-if="2 == silver"><i></i></span>
            <span v-else
              ><img src="../../../assets/image/黄金@2x.png" alt=""
            /></span>
          </div>
        </div>
        <!-- 会员名字 -->
        <div class="text">
          <span>白领会员</span>
          <span>银牌会员</span>
          <span>黄金会员</span>
        </div>
        <!-- 会员图片 -->
        <div class="img">
          <img
            src="../../../assets/image/白领会员@2x.png"
            alt=""
            v-if="0 == silver"
          />
          <img
            src="../../../assets/image/白银会员@2x.png"
            alt=""
            v-if="1 == silver"
          />
          <img
            src="../../../assets/image/黄金会员@2x.png"
            alt=""
            v-if="2 == silver"
          />
        </div>
        <!-- 权益 -->
        <div class="equity">
          <p>会员权益介绍</p>
          <p>
            成长值是指,会员通过线路预定、填写资料、老带新、发表文章等途径所获得的经验值,它标志着会员在累计的经验值,成长值越高会员等级越高,
          </p>
        </div>
        <!-- 充值年数 -->
        <div class="year" v-show="silver == 1 && member_level != 3">
          <ul>
            <li
              v-for="(item, index) in silverList"
              @click="ClickYearList(item)"
              :class="{ year_bg: yearId == index + 1 }"
            >
              {{ item.name }}
            </li>
          </ul>
          <p
            style="color: #9c9cab; margin-bottom: 10px"
            v-if="member_end_time != ''"
          >
            会员到期时间：{{ member_end_time }}
          </p>
          <div
            class="money"
            @click="ClickUpgradeYear"
            style="width: 160px"
            v-if="member_level == 1"
          >
            ¥{{ VIPprice }} 立即升级
          </div>
          <div
            class="money"
            @click="ClickUpgradeYear"
            style="width: 160px"
            v-if="member_level == 2 || member_level == 3"
          >
            ¥{{ VIPprice }} 立即续费
          </div>
        </div>

        <div class="year" v-show="silver == 2">
          <ul>
            <li
              v-for="(item, index) in goldList"
              @click="ClickYearList(item)"
              :class="{ year_bg: yearId == index + 1 }"
            >
              {{ item.name }}
            </li>
          </ul>
          <p
            style="color: #9c9cab; margin-bottom: 10px"
            v-if="member_end_time != ''"
          >
            会员到期时间：{{ member_end_time }}
          </p>
          <div
            class="money"
            @click="ClickUpgradeYear"
            style="width: 160px"
            v-if="member_level == 1"
          >
            ¥{{ VIPprice }} 立即升级
          </div>
          <div
            class="money"
            @click="ClickUpgradeYear"
            style="width: 160px"
            v-if="member_level == 2 || member_level == 3"
          >
            ¥{{ VIPprice }} 立即续费
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 移动端 -->
    <div v-if="!show_pc" id="mobile">
      <Header></Header>
      <div style="width: 100%; height: 60px"></div>
      <div
        style="
          text-align: left;
          display: flex;
          align-items: center;
          line-height: 26px;
          padding-left: 15px;
          background-color: #fff;
          font-weight: 700;
        "
      >
        <p
          style="
            width: 3px;
            height: 18px;
            background-color: #1677ff;
            margin-right: 10px;
          "
        ></p>
        <p style="font-family: PingFangSC-Regular, PingFang SC; color: #292931">
          个人中心
        </p>
      </div>
      <!-- 用户信息绑定列表 -->
      <div class="infor_mobile">
        <ul>
          <li
            v-for="(item, index) in usersList"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickUsersList(index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>

      <!-- 填写表单信息区域 -->

      <!-- 个人信息表单 -->
      <el-form
        :model="inforRuleForm"
        :rules="inforRules"
        ref="inforFormRef"
        class="form_mobile"
        v-if="i == 0"
        label-position="left"
        label-width="82px"
      >
        <!-- 用户昵称 -->
        <el-form-item label="用户昵称" prop="user">
          <el-input
            v-model="inforRuleForm.user"
            placeholder="请输入昵称"
          ></el-input>
        </el-form-item>

        <!-- 真是姓名 -->
        <!-- <el-form-item label="真是姓名">
          <el-input v-model="inforRuleForm.name" placeholder="请输入"></el-input>
        </el-form-item> -->

        <!-- 职位 -->
        <el-form-item label="职位">
          <el-input
            v-model="inforRuleForm.post"
            placeholder="请输入职位"
          ></el-input>
        </el-form-item>

        <!-- 手机号 -->
        <el-form-item label="手机号">
          <el-input
            v-model="inforRuleForm.phone"
            placeholder="请输入手机号"
            :disabled="true"
          ></el-input>
        </el-form-item>

        <!-- 个人信息 -->
        <el-form-item label="个人介绍" prop="grjj">
          <el-input
            type="textarea"
            v-model="inforRuleForm.grjj"
            placeholder="请输个人介绍"
            :autosize="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 编辑 -->
      <el-button
        v-if="i == 0"
        type="primary"
        class="btn"
        @click="ClickInforEdit"
        >{{ inforRuleForm.userBtn }}
      </el-button>

      <!-- 实名认证 -->
      <div class="form_mobile" v-if="i == 1">
        <!-- <p style="font-size: 14px;line-height: 30px;color: #1677FF;" v-if="is_real==0">您的实名认证<span></span></p> -->
        <p
          style="font-size: 14px; line-height: 30px; color: #1677ff"
          v-if="is_real == 3"
        >
          您的实名认证未通过请重新认证
        </p>
        <el-form
          :model="proveForm"
          :rules="proveRules"
          ref="proveRef"
          label-position="left"
          label-width="82px"
        >
          <!-- 用户姓名 -->
          <el-form-item label="真实姓名" prop="username">
            <el-input
              v-model="proveForm.username"
              placeholder="请输入您的真实姓名"
              :disabled="disabled"
            ></el-input>
          </el-form-item>

          <!-- 身份证号 -->
          <el-form-item label="身份证号" prop="userid">
            <el-input
              v-model="proveForm.userid"
              placeholder="请输入您的身份证号"
              :disabled="disabled"
            ></el-input>
          </el-form-item>

          <!-- 身份证照片-->
          <div class="">
            <el-form-item
              label="身份证照片"
              prop="userphoto"
              label-width="148px"
            >
            </el-form-item>
          </div>

          <div style="display: flex; flex-wrap: wrap">
            <div style="margin: 0 20px 20px 0">
              <el-upload
                :action="uploadUrl"
                list-type="picture-card"
                :on-preview="ClickBusinPreview"
                :on-remove="ClickBusinRemove"
                :disabled="disabled"
                :show-file-list="false"
                :on-success="success1"
              >
                <div style="line-height: 0; font-size: 12px" class="uploadSFZ">
                  <img :src="proveForm.real_card_img" alt="" />
                  <p>上传手持身份证</p>
                </div>
              </el-upload>
            </div>

            <div>
              <el-upload
                :action="uploadUrl"
                list-type="picture-card"
                :on-preview="ClickBusinPreview"
                :on-remove="ClickBusinRemove"
                :disabled="disabled"
                :show-file-list="false"
                :on-success="success2"
              >
                <div style="line-height: 0; font-size: 12px" class="uploadSFZ">
                  <img :src="proveForm.card_img_front" alt="" />
                  <p>上传身份证正面</p>
                </div>
              </el-upload>
            </div>

            <div>
              <el-upload
                :action="uploadUrl"
                list-type="picture-card"
                :on-preview="ClickBusinPreview"
                :on-remove="ClickBusinRemove"
                :disabled="disabled"
                :show-file-list="false"
                :on-success="success3"
              >
                <div style="line-height: 0; font-size: 12px" class="uploadSFZ">
                  <img :src="proveForm.card_img_back" alt="" />
                  <p>上传身份证背面</p>
                </div>
              </el-upload>
            </div>
          </div>

          <!-- 保存 -->
          <el-form-item>
            <el-button
              type="primary"
              @click.stop="ClickProveSave"
              v-if="is_real == 0"
              class="btn"
            >
              保存
            </el-button>
            <el-button type="primary" class="btn" v-if="is_real == 1">
              已认证
            </el-button>
            <el-button type="primary" class="btn" v-if="is_real == 2">
              待审核
            </el-button>
            <el-button
              type="primary"
              @click.stop="ClickProveSave"
              class="btn"
              v-if="is_real == 3"
            >
              保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 微信绑定 -->
      <div v-if="i == 2">
        <div class="wx" v-if="!is_bind_wechat">
          <div class="img">
            <img :src="img" alt="" />
          </div>
          <p>扫描二维码绑定微信</p>
        </div>
        <div class="wx" v-if="is_bind_wechat">
          <!--        <div class="img">-->
          <img :src="wechat_avatar" alt="" />
          <!--        </div>-->
          <p>{{ wechat_nickname }}</p>
          <p>已经绑定微信</p>
        </div>
      </div>

      <!-- 推广设置 -->
      <div class="form_mobile" v-if="i == 3">
        <el-form
          v-if="userInfo.member_level > 1"
          :model="businForm"
          :rules="businRules"
          ref="businForm"
          label-position="left"
          label-width="88px"
        >
          <!-- 手机号 -->
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="businForm.phone"
              placeholder="请输入手机号"
              :disabled="businForm.disabled"
            ></el-input>
          </el-form-item>

          <!-- 业务介绍 -->
          <el-form-item label="业务介绍" prop="ywjs" style="margin-top: 20px">
            <el-input
              type="textarea"
              v-model="businForm.ywjs"
              placeholder="请输入业务介绍"
              :disabled="businForm.disabled"
            ></el-input>
          </el-form-item>

          <!-- 微信二维码 -->
          <el-form-item
            label="微信二维码"
            prop="code"
            style="margin-top: 20px"
            label-width="90px"
          >
            <el-upload
              :action="uploadUrl"
              list-type="picture-card"
              :on-preview="ClickBusinPreview"
              :show-file-list="false"
              :disabled="businForm.disabled"
              :on-success="wxCodeSuccess"
              :on-remove="ClickBusinRemove"
            >
              <img
                :src="businForm.wxCode"
                alt=""
                style="width: 88px; height: 88px"
              />
            </el-upload>
          </el-form-item>

          <!-- 保存 -->
          <el-form-item>
            <el-button type="primary" @click.stop="ClickbusinForm" class="btn"
              >{{ businForm.btnContent }}
            </el-button>
          </el-form-item>
        </el-form>
        <div v-if="userInfo.member_level == 1" style="margin-top: 40px">
          <el-button
            v-if="this.$store.state.user.userInfo.is_business !== 1"
            type="primary"
            round
            style="padding: 8px 23px"
            @click="ClickUpgrade"
            >去升级</el-button
          >
          <p style="font-size: 16px; margin-top: 20px">
            升级会员配置自己的推广信息
          </p>
          <p />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../../header.vue";
import { log } from "util";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    Header,
  },
  data() {
    let rulesIdNumber = (rule, value, callback) => {
      console.log(value);
      if (value == "") {
        callback(new Error("请输入身份证号"));
      } else {
        const reg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/;
        const card = reg.test(value);
        if (!card) {
          callback(new Error("身份证格式如:423024xxxx0216xxxx"));
        } else {
          callback();
        }
      }
    };
    return {
      disabled: false,
      // 用户信息列表
      usersList: ["个人信息", "实名认证", "微信绑定"],

      //   默认用户信息选中
      i: 0,

      // 个人信息
      inforRuleForm: {
        user: "", //   用户昵称
        name: "", //真是姓名
        post: "", //职位
        phone: "", //手机号
        grjj: "", //个人信息
        userBtn: "编辑",
      },

      // 实名认证
      proveForm: {
        username: "",
        userid: "",
        userphone: "",
        real_card_img: require("../../../assets/image/sfz.png"), //手持
        card_img_front: require("../../../assets/image/编组 19@2x.png"), //正面
        card_img_back: require("../../../assets/image/编组 7@2x.png"), //反面
      },

      //   绑定微信
      img:
        "http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201212/185654_538166.gif",
      is_bind_wechat: false,
      wechat_nickname: "",
      wechat_avatar: "",
      timer: null,

      //   推广设置
      businForm: {
        btnContent: "保存",
        disabled: false,
        ywjs: "",
        wxCode: require("@/assets/image/上传封面图.png"),
        //   手机号
        phone: "",
        //   业务介绍
        busin: "",
        // 对话框图像地址
        dialogImageUrl: "",
        //   对话框可见
        dialogVisible: false,
      },

      //   个人信息验证表单'
      inforRules: {
        user: [
          {
            required: true,
            message: "请输入用户昵称",
            trigger: "blur",
          },
        ],
        grjj: [
          {
            required: true,
            message: "请填写个人介绍",
            trigger: "blur",
          },
        ],
      },

      //   实名认证表单
      proveRules: {
        userphoto: [
          {
            required: true,
            message: "请上传身份证",
            trigger: "blur",
          },
        ],

        username: [
          {
            required: true,
            message: "请输入用户姓名",
            trigger: "blur",
          },
        ],
        userid: [
          {
            required: true,
            validator: rulesIdNumber,
            trigger: "blur",
          },
        ],
      },

      //   推广设置表单
      businRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        ywjs: [
          {
            required: true,
            message: "请输入业务介绍",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请上传微信二维码",
            trigger: "blur",
          },
        ],
      },
      user: "",
      is_real: 0,
      show_pc: true,
      userInfo: "",

      goldList: [], //黄金会员价格
      silverList: [], //银牌会员价格
      member_end_time: 0, //会员到期时间
      member_level: 1,
      upgradeDialog: false,
      VIPprice: 0,
      // 控制白银会员的进度索引第一个
      silver: 0,

      // 升级年数默认显示ID第一个
      yearId: 1,
      loading: false,
    };
  },

  created() {
    // document.querySelector('body').style.backgroundColor = "#f5f5f5"

    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.$parent.setIcon = "个人中心";

    this.getUser();

    //获取推广信息

    if (this.$route.query.tabs) {
      this.i = this.$route.query.tabs;
    }
  },
  computed: {
    ...mapState(["uploadUrl"]),
  },
  methods: {
    // 点击切换选升级年数
    ClickYearList(id) {
      this.yearId = id.id;
      this.VIPprice = id.price;
      console.log("升级年数", id.id);
    },

    // 立即支付
    async ClickUpgradeYear() {
      this.loading = true;
      console.log("立即支付升级年数", this.yearId, this.member_name);
      let memberOrder = await this.$http
        .post("/index.php/api/v1_0_0.consumer/member_inc_order", {
          member_name: this.member_name,
          member_year: this.yearId,
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });

      console.log(memberOrder);
      // 有钱
      if (memberOrder.data.balance) {
        memberOrder.msg = `<p style="font-size: 16px;line-height: 26px;">${memberOrder.msg}</p>`;
        let balance = await this.$prompt("请输入密码", {
          confirmButtonText: "确定",
          message: memberOrder.msg,
          showClose: false,
          dangerouslyUseHTMLString: true,
          cancelButtonText: "取消",
          inputPlaceholder: "请输入支付密码",
          inputType: "password",
          inputValidator: function (v) {
            if (!v) return "请输入支付密码";
          },
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消支付",
          });
        });
        if (typeof balance == "undefined") return;
        console.log(balance, "balance");

        let pay = await this.$http
          .post("/index.php/api/v1_0_0.consumer/member_inc_pay ", {
            password: balance.value,
            order_num: memberOrder.data.order_num,
          })
          .catch((error) => {});
        console.log(pay, "pay");
        if (pay.data.need_password) {
          //没有设置支付密码
          this.$confirm(pay.msg, "提示", {
            confirmButtonText: "设置",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.upgradeDialog = false;
              this.ClickFinancialListList("支付密码设置", 0);
            })
            .catch(() => {});
        } else {
          this.$message.success(pay.msg);
          this.ClickUpgrade();
        }
      }
      //钱不够
      if (!memberOrder.data.balance) {
        this.$confirm(memberOrder.msg, "提示", {
          confirmButtonText: "充值",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.upgradeDialog = false;
            this.ClickRecharge(); //打开充值弹框
          })
          .catch(() => {});
      }
    },

    ClickWhite() {
      this.silver = 0;
    },

    // 点击白银会员
    ClickSilver() {
      console.log("银牌");
      this.member_name = "silver";
      this.yearId = 1;
      this.silver = 1;
      if (this.silverList.length >= 1) {
        this.VIPprice = this.silverList[0].price;
      }
    },
    // 点击黄金会员
    ClickGold() {
      console.log("金牌");
      this.member_name = "gold";
      this.yearId = 1;
      this.silver = 2;
      this.VIPprice = this.goldList[0].price;
    },

    ClickUpgrade() {
      this.upgradeDialog = true;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/consumer_inc", {})
        .then((data) => {
          console.log(data.data, "升级1");
          this.goldList = data.data.gold;
          this.silverList = data.data.silver;
          for (let i = 0; i < data.data.silver.length; i++) {
            data.data.silver[i].id = i + 1;
            data.data.gold[i].id = i + 1;
          }
          this.member_end_time = data.data.member_end_time; //会员到期时间

          if (data.data.member_level == 1) {
            this.member_level = 1;
          }
          if (data.data.member_level == 2) {
            this.member_level = 2;
            this.ClickSilver();
          }
          if (data.data.member_level == 3) {
            this.member_level = 3;
            this.ClickGold();
          }
        })
        .catch((error) => {});
    },

    getTg() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_popu_info", {})
        .then(({ data }) => {
          console.log(data, "data");
          if (data.status == 1) {
            this.businForm.btnContent = "已设置";
            this.businForm.disabled = true;
          }
          if (data.qrcode != "") {
            this.businForm.wxCode = data.qrcode;
            this.businRules.code = "";
            // this.$refs.businForm.clearValidate('code');
          }
          this.businForm.ywjs = data.desc;
          this.businForm.phone = data.phone;
        })
        .catch((error) => {});
    },
    //点击推广保存
    ClickbusinForm() {
      console.log(this.businForm);
      this.$refs.businForm.validate(async (valid) => {
        if (valid) {
          this.$http
            .post("/index.php/api/v1_0_0.consumer/popularize", {
              phone: this.businForm.phone,
              desc: this.businForm.ywjs,
              qrcode: this.businForm.wxCode,
            })
            .then((data) => {
              this.$message.success(data.msg);
            })
            .catch((error) => {
              if (error.code == 400) {
                this.$confirm(error.msg, "提示", {
                  confirmButtonText: "升级",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.$router.push({
                      path: "/personal/financial",
                      query: {
                        tabs: "我的余额",
                      },
                    });
                  })
                  .catch(() => {});
              }
            });
        }
      });
    },
    //   点击用户信息列表
    ClickUsersList(i) {
      //清除验证BUG
      if (this.i == 3 && i == 1) {
        this.$refs.businForm.resetFields();
      }
      this.i = i;
      console.log(i);
      clearInterval(this.timer);
      if (this.i == 1) {
        //获取用户信息
        this.$http
          .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
          .then(({ data }) => {
            console.log(data);
            this.$store.state.user.userInfo = data;
            this.is_real = data.real.is_real;
            console.log(this.is_real, " this.is_real");
            if (this.is_real == 1 || this.is_real == 2) {
              this.disabled = true;
              this.proveForm = {
                username: data.real.realname,
                userid: data.real.card_number,
                userphone: data.real.card_number,
                real_card_img: data.real.real_card_img, //手持
                card_img_front: data.real.card_img_front,
                card_img_back: data.real.card_img_back,
              };
            }
          })
          .catch((error) => {});
      }
      if (this.i == 2) {
        //获取用户信息
        this.$http
          .post("/index.php/api/v1_0_0.consumer/wx_qrcode", {})
          .then(({ data }) => {
            console.log("获取微信绑定二维码,是否绑定微信");
            console.log(data);
            if (data.is_bind_weixin == 1) {
              this.is_bind_wechat = true;
              this.wechat_avatar = data.wx_headimg;
              this.wechat_nickname = data.wx_nickname;
            } else {
              // 去获取微信二维码
              this.img = data.url;
              clearInterval(this.timer);
              let that = this;
              // 开启定时器，轮询查询扫码绑定状态
              this.timer = setInterval(function () {
                that.$http
                  .post("/index.php/api/v1_0_0.consumer/get_login_status", {})
                  .then(({ data }) => {
                    clearInterval(that.timer);
                    console.log("查询微信绑定状态");
                    console.log(data);
                    that.is_bind_wechat = true;
                    that.wechat_avatar = data.wx_headimg;
                    that.wechat_nickname = data.wx_nickname;
                  })
                  .catch((error) => {});
              }, 1000);
            }
          })
          .catch((error) => {});
      }
    },

    getUser() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
        .then(({ data }) => {
          console.log("用户信息");
          console.log(data);
          if (data.is_business !== 1 && this.usersList.length > 4) {
            this.usersList.push("推广设置");
          }
          this.$store.state.user.userInfo = data;
          this.inforRuleForm.user = data.nickname;
          this.inforRuleForm.post = data.profession;
          this.inforRuleForm.phone = data.phone;
          this.inforRuleForm.grjj = data.description;
          this.userInfo = data;
          console.log(this.userInfo);

          if (data.member_level > 1) {
            this.getTg();
          }
        })
        .catch((error) => {});
    },

    // 个人信息 点击编辑按钮
    ClickInforEdit() {
      if (this.inforRuleForm.userBtn == "编辑") {
        this.inforRuleForm.userBtn = "保存";
        return;
      }
      //修改个人信息
      if (this.inforRuleForm.userBtn == "保存") {
        this.$refs.inforFormRef.validate(async (valid) => {
          if (valid) {
            this.$http
              .post("/index.php/api/v1_0_0.consumer/info_auth", {
                auth_data: {
                  nickname: this.inforRuleForm.user,
                  profession: this.inforRuleForm.post,
                  description: this.inforRuleForm.grjj,
                },
              })
              .then((data) => {
                this.$message.success(data.msg);
                this.getUser();
                this.inforRuleForm.userBtn = "编辑";
              })
              .catch((error) => {});
          } else {
            // this.$message.error("请把带有*的填写完整");
          }
        });
        return;
      }
    },
    // 实名认证 点击保存
    ClickProveSave() {
      this.$refs.proveRef.validate(async (valid) => {
        console.log(this.proveForm);
        let proveForm = this.proveForm;

        if (
          proveForm.real_card_img &&
          proveForm.real_card_img == "static/image/sfz.png"
        ) {
          this.$message.error("请上传手持身份证照片");
          return false;
        }

        if (
          proveForm.card_img_front &&
          proveForm.card_img_front == "static/img/编组 19@2x.f9808591.png"
        ) {
          this.$message.error("请上传身份证正面照片");
          return false;
        }

        if (
          proveForm.card_img_back &&
          proveForm.card_img_back == "static/img/编组 7@2x.be195edf.png"
        ) {
          this.$message.error("请上传身份证背面照片");
          return false;
        }

        if (
          valid &&
          proveForm.real_card_img != "" &&
          proveForm.card_img_front != "" &&
          proveForm.card_img_back != ""
        ) {
          this.$http
            .post("/index.php/api/v1_0_0.consumer/realname_auth", {
              auth_data: {
                realname: this.proveForm.username, //真实姓名
                card_number: this.proveForm.userid, //身份证号码
                card_img_front: this.proveForm.card_img_front, //身份证正面照
                card_img_back: this.proveForm.card_img_back, //身份证反面照
                real_card_img: this.proveForm.real_card_img, //手持身份证正面照
              },
            })
            .then((data) => {
              this.$message.success(data.msg);
              console.log(data, "实名");
            })
            .catch((error) => {});
        }
        if (!valid) {
          // return this.$message.error("请把带有*的填写完整");
        }
      });
    },
    // 推广设置
    // 点击保存
    // ClickBusinSave () {
    //   this.$refs.businRef.validate(async (valid) => {
    //     console.log(valid)

    //     if (!valid) {
    //       // return this.$message.error("请把带有*的填写完整");
    //     }
    //   })
    // },
    // 删除上传图片列表的函数
    ClickBusinRemove(file, fileList) {
      console.log(file, fileList);
    },
    //   点击文件列表中已上传的文件时的钩子
    ClickBusinPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    success1(response, file, fileList) {
      this.proveForm.real_card_img = response.data.file_url;

      this.$refs.proveRef.clearValidate("userphoto");
      this.proveRules.userphoto = "";
    },

    success2(response, file, fileList) {
      this.proveForm.card_img_front = response.data.file_url;
    },
    success3(response, file, fileList) {
      this.proveForm.card_img_back = response.data.file_url;
    },
    wxCodeSuccess(response, file, fileList) {
      this.businForm.wxCode = response.data.file_url;
      this.$refs.businForm.clearValidate("code");
      this.businRules.code = "";
    },
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f5f5f5");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
    // 关闭绑定微信页面 清除 定时器
    clearInterval(this.timer);
  },
};
</script>
<style lang='less' scoped>
/* 移动端 */
#mobile {
  background-color: #fff;
  /deep/.el-form-item__label {
    padding-right: 14px;
  }
  .wx {
    height: 300px;
    margin: 0;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    line-height: 4px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .infor_mobile {
    display: block;

    ul {
      display: flex;
      /* justify-content: start; */
      padding: 14px 0px;
      // justify-content: space-around;

      .color {
        color: #1677ff;
      }

      li {
        margin: 0 15px;

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        /* margin: 0 25px; */
        cursor: pointer;
      }
    }
  }

  .form_mobile {
    padding: 23px 10px 23px 15px;

    /deep/ .el-upload--picture-card {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

.sfz {
  /deep/ .el-form-item__error {
    padding-top: 25px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 88px;
  height: 88px;
  display: block;
}

.uploadSFZ {
  img {
    width: 130px;
    height: 83px;
  }
}

/deep/ .el-upload--picture-card {
  width: 130px;
  /* height: 83px; */
  border: 0;
  margin-right: 18px;
  margin-top: 15px;
  background-color: #fff;
}

.infor {
  ul {
    display: flex;
    justify-content: start;
    padding: 20px 50px;

    .color {
      color: #1677ff;
    }

    li {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #292931;
      line-height: 22px;
      margin: 0 25px;
      cursor: pointer;
    }
  }
}

.form {
  padding: 10px 160px 40px 50px;

  /deep/ .el-upload--picture-card {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.wx {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 58px auto 130px;
  width: 163px;

  .img {
    width: 149px;
    height: 149px;
    border-radius: 8px;
    border: 1px solid #f5eded;
    padding: 6px;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      background: #f8f8f8;
    }
  }

  p {
    margin: 15px 0;

    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #343d4c;
    line-height: 20px;
    letter-spacing: 2px;
  }
}

// 推广设置
.el-upload {
  p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999da4;
    line-height: 17px;
    margin-top: 14px;
  }
}

// 会员升级
.upgrade_Box {
  margin: 0 auto;
  padding: 0 30px 52px 30px;

  .upgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 22px 19px 0;

    .icon {
      width: 18px;
      height: 18px;
      margin: 0 -2px 0 -1px;
      position: relative;

      span {
        position: absolute;
        right: 1px;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 18px;
        background: #1677ff;
        text-align: center;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          border: 0;
          outline: none;
        }

        i {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: #ffffff;
        }
      }
    }

    .speed_bg {
      background: #1677ff;
    }

    p {
      //   width: 169px;
      width: 40%;
      height: 6px;
      background: #eff1fd;
      margin-left: 1px;
    }
  }

  .text {
    display: flex;
    justify-content: space-between;
    margin: 13px 19px 0;
  }

  .img {
    width: 104px;
    height: 100px;
    margin: 30px auto 25px;

    img {
      width: 100px;
      height: 100px;
    }
  }

  .equity {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #292931;
    line-height: 17px;
    text-stroke: 1px #979797;
    margin: 0 19px 40px;
  }

  .year {
    ul {
      display: flex;
      margin-bottom: 40px;

      .year_bg {
        background: #1677ff;
        color: #ffffff;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 78px;
        height: 44px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #d3d7de;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        margin: 0 8px;
        cursor: pointer;
      }
    }

    .money {
      width: 108px;
      height: 34px;
      background: #1677ff;
      margin: 0 auto;

      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 34px;
      cursor: pointer;
    }
  }
}
</style>
<style>
.el-textarea__inner {
  background: #fafafa !important;
}
</style>
