const query = {
    state: {
        // 文章推荐列表
        classId: 0,
        classText: '推荐资讯',
        item: '',
        i: '',
        footerInfo: {},


    },
    getters: {
    },
    mutations: {
    },
    actions: {
    }
}

export default query