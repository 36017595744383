<template>
  <div v-if="newActivityList.length">
    <!-- 最新活动组件 -->
    <div class="newest">
      <div class="top">
        <div class="left">
          <p>最新活动</p>
        </div>
        <div class="right" @click="ClickMore">
          <img src="../assets/icon/更多@2x.png" />
        </div>
      </div>
      <div class="bottom">
        <el-carousel trigger="click" height="310px">
          <el-carousel-item
            v-for="(item, index) in newActivityList"
            :key="index"
          >
            <div class="img" @click="ClickNewActivity(item)">
              <img :src="item.cover" alt="" />
            </div>
            <div class="text">
              <p>{{ item.title }}</p>
              <span>{{ item.create_time }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newActivityList: [],
    };
  },
  created() {
    // 获取最新活动列表
    this.getNewActivityList();
  },
  methods: {
    // 点击更多
    ClickMore() {
      this.$router.push("/hd");
    },

    // 获取最新活动列表
    getNewActivityList() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.activity/get_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.newActivityList = data.list;
      }

      this.$http
        .get("/index.php/api/v1_0_0.activity/get_list", {
          params: { page: 1, page_size: 3 },
          notNeed: true,
        })
        .then((res) => {
          this.newActivityList = res.data.list;
          this.$storage.set(newActivityList, res.data);
        })
        .catch((error) => {});
    },
    // 点击每一个
    ClickNewActivity(item) {
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }
      this.$router.push({
        path: "/hd/activityDetails",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang='less' scoped>
.newest {
  padding: 5px 20px 5px 20px;
  background-color: #ffffff;
  margin-bottom: 10px;

  .top {
    height: 55px;
    line-height: 55px;
    display: flex;
    justify-content: space-between;
    .left {
      p {
        display: inline-block;
        cursor: pointer;
        margin-right: 20px;
        font-size: 16px;
        height: 40px;
        font-weight: 600;
        color: #292931;
        border-bottom: 3px solid #1677ff;
      }
      // .textStyle {
      //   font-size: 18px;
      //   font-weight: 600;
      //   color: #292931;
      //   border-bottom: 3px solid #1677ff;
      // }
    }
    .right {
      margin-top: 5px;
      cursor: pointer;
      width: 16px;
      height: 16px;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .bottom {
    margin-top: 10px;
    height: 310px;
    // background-color: pink;
    .img {
      width: 100%;
      height: 210px;
      cursor: pointer;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
    .text {
      text-align: left;
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 25px;
        margin: 10px 0;
        text-align: left;
        overflow: hidden; //强制多行显示
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9496a7;
        line-height: 20px;
      }
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #fff;
    }
  }
}
/deep/.el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #d9dddf;
}
// 修改轮播图 选中小白点的样式
/deep/.el-carousel__indicator.is-active button {
  width: 20px !important;
}
/deep/.el-carousel__indicators--horizontal {
  left: 79%;
  transform: translateX(5%);
}
/deep/ .el-carousel__arrow {
  top: 35%;
}
</style>
