<template>
  <!--  <div v-loading.fullscreen.lock="fullscreenLoading">-->
  <div>
    <Header :indexi="3"></Header>

    <!-- PC端 -->
    <div v-if="show_pc">
      <!-- 课堂详情内容 -->
      <div class="classroom">
        <!-- 课堂详情 左侧 内容 -->
        <div class="classroom_left" v-loading="fullscreenLoading">
          <!-- 课程大图 以及 课程信息 直播时间 报名人数 -->
          <div class="curriculum">
            <!--            <div class="wx">-->
            <!--              <img src="../../assets/icon/微信@2x.png" alt="" />-->
            <!--            </div>-->

            <div class="image">
              <img :src="classDetailsInfo.cover" alt="" />
            </div>
            <div style="display: flex; justify-content: space-between">
              <div class="date">
                <span>{{ classDetailsInfo.title }}</span
                ><span>{{ classDetailsInfo.cate_name }}</span>
              </div>
              <div class="thumbs">
                <div
                  v-if="classDetailsInfo.is_praise == 1"
                  @click="ClickThumbs"
                  style="
                    background-color: #1677ff;
                    color: #fff;
                    border: 1px solid #1677ff;
                  "
                >
                  <img src="../../assets/icon/编组3@2x.png" alt="" />
                  {{ classDetailsInfo.praise_count }}
                </div>
                <div
                  v-if="classDetailsInfo.is_praise == 2"
                  @click="ClickThumbs"
                >
                  点赞
                  <img src="../../assets/icon/编组2@2x.png" alt="" />
                  {{ classDetailsInfo.praise_count }}
                </div>
                <div
                  v-if="classDetailsInfo.is_collect == 1"
                  @click="ClickCollection"
                  style="border: 1px solid #98c3ff; color: #1677ff"
                >
                  <img src="../../assets/icon/编组@2x.png" alt="" />已收藏
                </div>
                <div
                  v-if="classDetailsInfo.is_collect == 2"
                  @click="ClickCollection"
                >
                  <img src="../../assets/icon/编组备份@2x(1).png" alt="" />收藏
                </div>
              </div>
            </div>

            <div class="parameter">
              <ul>
                <li>
                  <span><img src="../../assets/icon/人员@2x.png" alt="" /></span
                  ><span>{{ classDetailsInfo.nickname }}</span>
                </li>
                <li>
                  <span><img src="../../assets/icon/时间@2x.png" alt="" /></span
                  ><span
                    >{{ classDetailsInfo.online_time
                    }}<span v-if="classDetailsInfo.end_time">~</span
                    >{{ classDetailsInfo.end_time }}</span
                  >
                </li>
                <li>
                  <span><img src="../../assets/icon/人数@2x.png" alt="" /></span
                  ><span>{{ classDetailsInfo.people_count }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 课程详情介绍 -->
          <div class="CourseDetails">
            <ul>
              <li>
                <div class="title">
                  <span>课程简介</span>
                  <span></span>
                </div>
                <p v-html="classDetailsInfo.description">
                  {{ classDetailsInfo.description }}
                </p>
              </li>
              <li>
                <div class="title">
                  <span>讲师介绍</span>
                  <span></span>
                </div>
                <p>
                  {{ classDetailsInfo.teacher_desc }}
                </p>
              </li>
              <li>
                <div class="title">
                  <span>课程详情</span>
                  <span></span>
                </div>
                <p v-html="classDetailsInfo.content">
                  {{ classDetailsInfo.content }}
                </p>
              </li>
              <li>
                <div class="title">
<!--                  <span>课程图片</span>-->
<!--                  <span></span>-->
                </div>
                <div class="photos" style="margin-top: 20px">
<!--                  <img :src="classDetailsInfo.content_img" alt="" />-->
                </div>
              </li>
            </ul>
          </div>

          <!-- 照片区域 -->
          <!-- <div class="photos">
            <img :src="classDetailsInfo.content_img" alt="" />
          </div> -->
        </div>

        <!-- 课堂详情 右侧 内容 -->
        <div class="classroom_right">
          <!-- 薪税课堂 -->
          <div class="newest">
            <div class="top">
              <div class="left">
                <p>新税课堂</p>
              </div>
              <div class="right" @click="ClickClassRoom">
                <img src="../../assets/icon/更多@2x.png" />
              </div>
            </div>
            <div class="bottom">
              <ul>
                <li
                  v-for="(item, index) in classRoomList"
                  :key="item.id"
                  @click="ClickclassRoomList(item)"
                >
                  <div class="img">
                    <img :src="item.cover" alt="" />
                  </div>
                  <p>{{ item.title }}</p>
                  <p>{{ item.create_time }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部立即报名模块 -->
      <div class="signUp_box">
        <div class="signUp">
          <div
            class="btn"
            v-if="
              2 == classDetailsInfo.is_sign && classDetailsInfo.is_online < 3
            "
          >
            <span>{{ classDetailsInfo.price_str }}</span>
            <el-button type="primary" @click="ClickJoin">立即报名</el-button>
          </div>
          <div
            class="btn1"
            v-if="
              1 == classDetailsInfo.is_sign && classDetailsInfo.is_online < 3
            "
          >
            <el-button type="primary" @click="toLive">进入直播间</el-button>
            <!-- 已报名-进入直播间 -->
          </div>
          <div class="btn1" v-if="classDetailsInfo.is_online == 3">
            <el-button type="primary" @click="toOrigin">查看回放 </el-button>
            <!-- 已结束-进入回看 -->
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="salaryClassromm_md">
        <!-- 课堂详情 左侧 内容 -->
        <div class="classroom_left" v-loading="fullscreenLoading">
          <!-- 课程大图 以及 课程信息 直播时间 报名人数 -->
          <div class="curriculum">
            <!-- 活动头部图片 -->
            <div class="header">
              <wc-swiper>
                <wc-slide v-for="(item, key) in classRoomList" :key="key">
                  <div
                    style="height: 188px"
                    @click="ClickBanner(item.target_url)"
                  >
                    <img
                      style="display: inline-block; height: 188px; width: 100%"
                      :src="item.cover"
                      alt=""
                    />
                  </div>
                </wc-slide>
              </wc-swiper>
            </div>
            <div style="display: flex; justify-content: space-between">
              <div class="date">
                <span>{{ classDetailsInfo.title }}</span
                ><span
                  ><span>{{ classDetailsInfo.cate_name }}</span></span
                >
              </div>
              <div class="thumbs">
                <div
                  v-if="classDetailsInfo.is_praise == 1"
                  @click="ClickThumbs"
                  style="
                    background-color: #1677ff;
                    color: #fff;
                    border: 1px solid #1677ff;
                  "
                >
                  <img src="../../assets/icon/编组3@2x.png" alt="" />
                  {{ classDetailsInfo.praise_count }}
                </div>
                <div
                  v-if="classDetailsInfo.is_praise == 2"
                  @click="ClickThumbs"
                >
                  点赞
                  <img src="../../assets/icon/编组2@2x.png" alt="" />
                  {{ classDetailsInfo.praise_count }}
                </div>
                <div
                  v-if="classDetailsInfo.is_collect == 1"
                  @click="ClickCollection"
                  style="border: 1px solid #98c3ff; color: #1677ff"
                >
                  <img src="../../assets/icon/编组@2x.png" alt="" />已收藏
                </div>
                <div
                  v-if="classDetailsInfo.is_collect == 2"
                  @click="ClickCollection"
                >
                  <img src="../../assets/icon/编组备份@2x(1).png" alt="" />收藏
                </div>
              </div>
            </div>
            <div class="parameter">
              <ul>
                <li>
                  <span><img src="../../assets/icon/人员@2x.png" alt="" /></span
                  ><span>{{ classDetailsInfo.nickname }}</span>
                  <div class="wx">
                    <!-- <img src="../../assets/icon/微信@2x.png" alt="" /> -->
                  </div>
                </li>
                <li>
                  <span><img src="../../assets/icon/时间@2x.png" alt="" /></span
                  >直播时间：<span
                    style="
                      width: 256px;
                      display: inline-block;
                      text-align: left;
                      white-space: nowrap;
                    "
                    >{{ classDetailsInfo.online_time
                    }}<span v-if="classDetailsInfo.end_time">~</span
                    >{{ classDetailsInfo.end_time }}</span
                  >
                </li>
                <li>
                  <span><img src="../../assets/icon/人数@2x.png" alt="" /></span
                  >报名人数：<span>{{ classDetailsInfo.people_count }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 课程详情介绍 -->
          <div class="CourseDetails">
            <ul>
              <li>
                <div class="title">
                  <span>课程简介</span>
                  <span></span>
                </div>
                <p>
                  {{ classDetailsInfo.description }}
                </p>
              </li>
              <li>
                <div class="title">
                  <span>讲师介绍</span>
                  <span></span>
                </div>
                <p>
                  {{ classDetailsInfo.teacher_desc }}
                </p>
              </li>
              <li>
                <div class="title">
                  <span>课程详情</span>
                  <span></span>
                </div>
                <p v-html="classDetailsInfo.content"></p>
              </li>
            </ul>
          </div>

          <!-- 照片区域 -->
          <!-- <div class="photos">
            <img :src="classDetailsInfo.content_img" alt="" />
          </div> -->
        </div>

        <div class="footer">
          <Footer></Footer>
        </div>

        <div class="signUp">
          <div
            class="btn"
            v-if="
              2 == classDetailsInfo.is_sign && classDetailsInfo.is_online < 3
            "
          >
            <span>{{ classDetailsInfo.price_str }}</span>
            <el-button type="primary" @click="ClickJoin" style="padding: 0 20px"
              >立即报名</el-button
            >
          </div>
          <div
            class="btn1"
            v-if="
              1 == classDetailsInfo.is_sign && classDetailsInfo.is_online < 3
            "
          >
            <el-button type="primary" @click="toLive" style="padding: 0 20px"
              >进入直播间</el-button
            >
          </div>
          <div class="btn1" v-if="classDetailsInfo.is_online == 3">
            <el-button type="primary" @click="toOrigin" style="padding: 0 20px"
              >查看回放</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Message } from "element-ui";
import router from "../../router";

export default {
  components: {
    Header,
    Footer,
  },
  metaInfo() {
    return {
      title: this.classDetailsInfo.title
        ? this.classDetailsInfo.title + " | 新税网"
        : window.location.href,

      meta: [
        {
          name: "description",
          content: this.classDetailsInfo.description,
        },
        {
          name: "keywords",
          content: this.classDetailsInfo.title,
        },
        {
          name: "author",
          content: this.classDetailsInfo.nickname + " - 新税网",
        },
      ],
    };
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,

      // 课堂详情id
      id: "",
      // 课程信息 直播时间 报名人数列表
      classDetailsInfo: "",
      // 薪税课堂列表
      classRoomList: "",
      fullscreenLoading: false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 上一级页面携带过来的参数
    if (!this.$route.query.id) {
      this.$router.push("/");
      return false;
    }

    this.id = this.$route.query.id;

    // 获取课程详情
    this.getClassDetails();

    // 获取薪税课堂列表
    this.getClassRoomList();
  },
  methods: {
    // 获取课程详情
    getClassDetails() {
      this.fullscreenLoading = true;
      this.$http
        .post("/index.php/api/v1_0_0.course/get_detail", {
          id: this.id,
        })
        .then((res) => {
          this.fullscreenLoading = false;
          this.classDetailsInfo = res.data;
        })
        .catch((error) => {});
    },
    // 点赞 或 取消点赞
    ClickThumbs() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_praise", {
          id: this.id,
          type: 4,
        })
        .then((res) => {
          Message.success("操作成功").onClose(() => {});
          this.classDetailsInfo.praise_count = res.data.praise_count;
          this.classDetailsInfo.is_praise = res.data.is_praise;
          console.log(res.data);
        })
        .catch((error) => {});
    },

    // 收藏 或 取消收藏
    ClickCollection() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_collect", {
          id: this.id,
          type: 4,
        })
        .then((res) => {
          Message.success("操作成功").onClose(() => {});
          this.classDetailsInfo.is_collect = res.data.is_collect;
          this.classDetailsInfo.collect_count = res.data.collect_count;
          console.log(res);
        })
        .catch((error) => {});
    },
    // 点击更多跳转薪税课堂首页
    ClickClassRoom() {
      this.$router.push("/xskt");
    },
    // TODO 获取薪税课堂列表
    getClassRoomList() {
      this.$http
        .post("/index.php/api/v1_0_0.course/get_detail_list", {
          id: this.id,
        })
        .then((res) => {
          console.log(res.data.list);
          this.classRoomList = res.data.list;
        })
        .catch((error) => {});
    },
    // 点击加入
    ClickJoin() {
      // 1是否登录
      if (
        !localStorage.getItem("user_id") ||
        !localStorage.getItem("user_token")
      ) {
        Message.error("请登录后加入");
        this.$router.push({
          path: "/login",
          query: {
            redirect: this.$router.currentRoute.fullPath, //登录成功后跳入浏览的当前页面
          },
        });
        return false;
      }
      // 2是否免费
      if (this.classDetailsInfo.price == 0) {
        // 弹出免费加入的弹框
        this.$alert("本直播课程免费加入", "", {
          confirmButtonText: "确定加入",
          callback: (action) => {
            if (action == "confirm") {
              // 免费加入的接口
              this.$http
                .post("/index.php/api/v1_0_0.course/course_sign_free", {
                  id: this.id,
                })
                .then((res) => {
                  // 加入成功，进入直播页面
                  console.log(res);
                  Message.success("加入课堂成功");
                  this.$router.push({
                    path: "/xskt/classOpen",
                    query: { id: this.id },
                  });
                })
                .catch((error) => {});
            }
          },
        });
        return false;
      }
      // 3不免费，去请求需要支付的金额
      // TODO 请求是否需要充值显示输入密码还是去充值
      this.$http
        .post("/index.php/api/v1_0_0.course/course_sign", {
          id: this.id,
        })
        .then((res) => {
          console.log(res);
          // 余额足够
          if (res.data.balance) {
            if (res.data.safe_password) {
              this.$confirm(res.msg, "提示", {
                confirmButtonText: "去设置",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$router.push({
                    path: "/personal/financial",
                    query: {
                      tabs: "支付密码设置",
                    },
                  });
                })
                .catch(() => {});
            } else {
              this.$prompt("请输入密码", res.msg, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPlaceholder: "请输入支付密码",
                inputType: "password",
                inputValidator: function (v) {
                  if (!v) return "请输入支付密码";
                },
              })
                .then(({ value }) => {
                  this.$http
                    .post("/index.php/api/v1_0_0.course/do_pay", {
                      id: this.id,
                      password: value,
                    })
                    .then((res) => {
                      // 加入成功，进入直播页面
                      console.log(res);
                      Message.success("加入课堂成功");
                      this.$router.push({
                        path: "/xskt/classOpen",
                        query: { id: this.id },
                      });
                    })
                    .catch((error) => {});
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消支付",
                  });
                });
            }
          } else {
            this.$confirm(res.msg, "余额不足", {
              confirmButtonText: "去充值",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                // TODO 跳转充值页面,带参数
                this.$router.push({ path: "/personal/financial" });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消充值",
                });
              });
          }
        })
        .catch((error) => {});
    },

    // 进入直播间
    toLive() {
      console.log(this.classDetailsInfo.outside_url);
      if (
        !localStorage.getItem("user_id") ||
        !localStorage.getItem("user_token")
      ) {
        Message.error("请登录后加入");
        this.$router.push({
          path: "/login",
          query: {
            redirect: this.$router.currentRoute.fullPath, //登录成功后跳入浏览的当前页面
          },
        });
        return false;
      }
      if (this.classDetailsInfo.outside_url) {
        return window.open(this.classDetailsInfo.outside_url, "_blank");
      }
      this.$router.push({ path: "/xskt/classOpen", query: { id: this.id } });
    },

    // 进入回放
    toOrigin() {
      if (
        !localStorage.getItem("user_id") ||
        !localStorage.getItem("user_token")
      ) {
        Message.error("请登录后加入");
        this.$router.push({
          path: "/login",
          query: {
            redirect: this.$router.currentRoute.fullPath, //登录成功后跳入浏览的当前页面
          },
        });
        return false;
      }
      if (this.classDetailsInfo.outside_url) {
        return window.open(this.classDetailsInfo.outside_url, "_blank");
      }

      this.$router.push({ path: "/xskt/classOpen", query: { id: this.id } });
    },
    // 点击薪税列表项
    ClickclassRoomList(item) {
      console.log(item);
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {
            this.$router.push({
              path: "/xskt/salaryClassroom",
              query: { id: item.id },
            });
            location.reload();
          });
        return;
      }
      this.$router.push({
        path: "/xskt/salaryClassroom",
        query: { id: item.id },
      });
      location.reload();

      // this.id = item.id;
      // this.getClassDetails();
    },
  },
};
</script>
<style lang='less' scoped>
/*img{*/
/*  object-fit: contain;*/
/*}*/
.classroom {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // margin: 0 10.45%;
  padding-top: 30px;
  .classroom_left {
    width: 66%;
    height: auto;
    // background-color: red;
    .curriculum {
      position: relative;
      .wx {
        position: absolute;
        left: -35px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      .image {
        width: 100%;
        height: 480px;
        overflow: hidden;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: #fff;
        }
      }
      .date {
        text-align: left;
        display: flex;
        justify-content: start;
        margin: 20px 0;

        span:nth-child(1) {
          display: block;
          width: 80%;
          font-size: 20px;
          font-weight: 500;
          color: #292931;
          line-height: 28px;
          margin-right: 13px;
          overflow: hidden; //强制多行显示
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        span:nth-child(2) {
          width: 60px;
          height: 24px;
          line-height: 24px !important;
          background: #1677ff;
          border-radius: 2px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 17px;
          text-align: center;
          padding: 0 20px;
        }
      }
      .thumbs {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin: 30px 0;

        div {
          width: 100px;
          height: 35px;
          background: #eee;
          border-radius: 25px;
          font-weight: 400;
          color: #999;
          margin: 0 11px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #eee;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          cursor: pointer;
          img {
            display: inline-block;
            width: 16px;
            height: 16px;
            padding: 0 5px;
          }
        }
        div:nth-child(2) {
          border: 1px solid #eee;
          background-color: #fff;
          color: #999;
        }
      }
      .parameter {
        padding-bottom: 30px;
        ul {
          li {
            display: flex;
            justify-content: start;
            align-items: center;
            margin: 5px 0;
            span:nth-child(1) {
              width: 14px;
              height: 14px;
              line-height: 14px;
              margin-right: 10px;

              img {
                width: 100%;
                height: 100%;
                // background-color: pink;
              }
            }
          }
          li:nth-child(2) {
            span {
              span {
                margin: 0 10px;
              }
            }
          }
        }
      }
    }
    .CourseDetails {
      ul {
        li {
          text-align: left;
          margin-top: 10px;
          .title {
            span:nth-child(1) {
              font-size: 16px;
              font-weight: 600;
              color: #292931;
            }
            span:nth-child(2) {
              display: block;
              width: 60px;
              height: 3px;
              background: #1677ff;
              margin-top: 5px;
            }
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #303030;
            line-height: 22px;
            letter-spacing: 2px;
            padding: 20px 0;
          }
        }
      }
    }
    .photos {
      width: 80%;
      /*height: 900px;*/
      overflow: hidden;
      margin-bottom: 138px;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
    }
  }
  .classroom_right {
    width: 33%;
    height: 100%;
    // background-color: blue;
    .newest {
      padding: 5px 20px 5px 20px;
      background-color: #ffffff;
      margin-bottom: 10px;

      .top {
        height: 55px;
        line-height: 55px;
        display: flex;
        justify-content: space-between;
        .left {
          p {
            display: inline-block;
            cursor: pointer;
            margin-right: 20px;
            font-size: 16px;
            height: 40px;

            font-size: 16px;
            font-weight: 600;
            color: #292931;
            border-bottom: 3px solid #1677ff;
          }
          // .textStyle {
          //   font-size: 18px;
          //   font-weight: 600;
          //   color: #292931;
          //   border-bottom: 3px solid #1677ff;
          // }
        }
        .right {
          margin-top: 5px;
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .bottom {
        margin-top: 10px;
        padding-bottom: 90px;
        ul {
          li {
            margin-bottom: 20px;
            cursor: pointer;
            .img {
              width: 100%;
              height: 210px;
              overflow: hidden;
              img {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }
            P {
              text-align: left;
            }
            p:nth-child(2) {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #292931;
              margin: 10px 0;
            }
            p:nth-child(3) {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9496a7;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
.signUp_box {
  width: 100%;
  height: 88px;
  background: #2b3442;
  position: fixed;
  bottom: 0;

  .signUp {
    width: 1200px;
    margin: 0 auto;
    height: 88px;
    line-height: 88px;
    background: #2b3442;
    // border: 1px solid #979797;
    z-index: 2;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 20px;
    box-sizing: border-box;
    .btn {
      span {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 33px;
      }
      .el-button {
        width: 180px;
        height: 54px;
        margin-left: 50px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        border: 0;
        outline: none;
      }
    }
    .btn1 {
      margin-left: 45%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 33px;
      }
      .el-button {
        height: 54px;
        margin-left: 50px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        background: #1677ff;

        color: #ffffff;
      }
    }
  }
}

.salaryClassromm_md {
  padding: 60px 0 45px;
  .classroom_left {
    width: 100%;
    height: auto;
    // padding: 0 10px;
    box-sizing: border-box;
    // background-color: red;
    .curriculum {
      .header {
        /deep/.wc-pagination {
          bottom: 0;
          // justify-content: flex-end;

          padding-right: 8px;
          box-sizing: border-box;
        }
        /deep/.wc-dot {
          width: 5px;
          height: 5px;
          border-radius: 5px;
          margin: 0 2px;

          background: #ffffff;
          opacity: 0.5;
        }
        /deep/ .wc-dot-active {
          background-color: #fff;
          width: 10px;
          opacity: 1;
        }
      }
      .date {
        padding: 0 10px;
        text-align: left;
        display: flex;
        justify-content: start;
        margin: 20px 0;
        span:nth-child(1) {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #292931;
          line-height: 21px;
          // margin-right: 13px;
        }
        span:nth-child(2) {
          display: inline-block;
          width: 50px;
          height: 20px;
          background: #1677ff;
          border-radius: 2px;
          line-height: 20px;
          box-sizing: border-box;
          margin-left: 7px;
          padding-bottom: 21px;
          span {
            display: inline-block;
            text-align: center;
            width: 50px;
            height: 20px;
            line-height: 20px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            -webkit-transform: scale(0.7);
            /*padding: 0 10px;*/
          }
        }
      }
      .thumbs {
        display: flex;
        justify-content: center;
        // margin: 30px 0;
        align-items: center;

        div {
          padding: 10px;
          width: 85px;
          height: 30px;
          background: #eee;
          border-radius: 25px;
          font-weight: 400;
          color: #999;
          margin: 0 11px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #eee;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          box-sizing: border-box;
          cursor: pointer;
          img {
            display: inline-block;
            width: 16px;
            height: 16px;
            padding: 0 5px;
          }
        }
        div:nth-child(2) {
          border: 1px solid #eee;
          background-color: #fff;
          color: #999;
        }
      }
      .parameter {
        padding: 0 10px 12px;
        padding-bottom: 12px;
        ul {
          li {
            text-align: center;
            position: relative;

            display: flex;
            justify-content: start;
            align-items: center;
            margin-bottom: 12px;

            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #848592;
            line-height: 17px;
            span:nth-child(1) {
              width: 14px;
              height: 14px;
              line-height: 14px;
              margin-right: 5px;
              img {
                width: 100%;
                height: 100%;
                // background-color: pink;
              }
            }
            span:nth-child(2) {
              span {
                margin: 0 5px;
              }
            }
          }
        }
      }
    }
    .CourseDetails {
      padding: 0 10px;
      ul {
        li {
          text-align: left;
          .title {
            span:nth-child(1) {
              font-size: 15px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 700;
              color: #292931;
              letter-spacing: 1px;
            }
            span:nth-child(2) {
              display: block;
              width: 60px;
              height: 3px;
              background: #1677ff;
              margin-top: 5px;
            }
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #303030;
            line-height: 22px;
            letter-spacing: 2px;
            padding: 15px 0 20px 0;
          }
        }
      }
    }
    .photos {
      width: 100%;
      /*height: 502px;*/
      overflow: hidden;
      padding: 0 13px;
      box-sizing: border-box;
      margin-bottom: 20px;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
    }
  }
  .footer /deep/ .footer_mb {
    position: relative;
  }
  .signUp {
    position: fixed;
    bottom: -2px;

    width: 100%;
    height: 49px;
    line-height: 49px;
    // padding: 10px 0;
    background: #2b3442;
    border: 0px;
    box-sizing: border-box;
    .btn {
      height: 100%;
      display: flex;
      padding: 0 10px 0 20px;
      margin: 0;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
      }

      .el-button {
        display: flex;
        align-items: center;
        width: 98px;
        height: 30px;
        background: #1677ff;
        border-radius: 5px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .btn1 {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      margin-right: 10px;

      .el-button {
        height: 30px;
        margin-left: 18px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        background: #1677ff;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
