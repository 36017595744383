<template>
  <div class="">
    <div v-if="show_pc" class="pc">
      <!-- 财务管理列表 -->
      <div class="top">
        <ul>
          <li
            v-for="(item, index) in financialList"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickFinancialListList(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>

      <!-- 我的余额 -->
      <div v-if="item == '我的余额'">
        <!-- 中间展示 -->
        <div class="center">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="display: flex">
              <div>
                <p>{{ yeInfo.ye }}</p>
                <p>{{ yeInfo.how }}</p>
                <p>
                  <span>{{ yeInfo.yesm }}</span
                  ><span>{{ yeInfo.yesmInfo }}</span>
                </p>
              </div>
              <div style="margin-left: 50px">
                <p>可提现</p>
                <p>{{ yeInfo.can_drawmoney_str }}</p>
                <p><span></span><span></span></p>
              </div>
            </div>
            <div class="btns">
              <span
                class="btn"
                style="background: #ee8525"
                @click="ClickWithdraw"
                >提现</span
              >
              <span
                class="btn"
                style="background: #28cc5c"
                @click="ClickRecharge"
                >充值</span
              >
              <span
                class="btn"
                style="background: #ff0000; padding: 0 5px"
                @click="ClickConversion"
                >积分兑换</span
              >
              <span
                class="btn"
                style="background: #fd588b"
                @click="ClickUpgrade"
                v-if="isIdentity != '企业'"
                >升级</span
              >
            </div>
          </div>
        </div>
        <!-- 余额明细 -->
        <div class="bottom">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div class="jfmx">
              <p>{{ yeInfo.yemx }}</p>
              <span></span>
            </div>
            <!--            <button-->
            <!--              style="margin-right: 20px; color: #1677ff; cursor: pointer"-->
            <!--              @click="exportExcel"-->
            <!--            >-->
            <!--              导出-->
            <!--            </button>-->
            <el-button
              style="margin-right: 2%; padding: 8px 20px"
              type="primary"
              @click="exportExcel"
              >导出</el-button
            >
          </div>
          <ul>
            <li v-for="(item, index) in yemxList" :key="index">
              <div class="left">
                <p>{{ item.remark }}</p>
                <p>{{ item.create_time }}</p>
              </div>
              <div class="right">
                <p>{{ item.price }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 我的积分 -->
      <div v-if="item == '我的积分'">
        <!-- 中间展示 -->
        <div class="center">
          <p>{{ jfInfo.jf }}</p>
          <p>{{ jfInfo.how }}</p>
          <p>
            <span>{{ jfInfo.jfsm }}</span
            ><span>{{ jfInfo.jfsmInfo }}</span>
          </p>
        </div>
        <!-- 积分明细 -->
        <div class="bottom">
          <div class="jfmx">
            <p>{{ jfInfo.jfmx }}</p>
            <span></span>
          </div>
          <ul>
            <li v-for="(item, index) in jfmxList" :key="index">
              <div class="left">
                <p>{{ item.remark }}</p>
                <p>{{ item.create_time }}</p>
              </div>
              <div class="right">
                <p>{{ item.price }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 发票索取 -->
      <div v-if="item == '发票索取'">
        <!-- 中间展示 -->
        <div class="invoice">
          <div style="display: flex; justify-content: start">
            <!-- 可用额度 -->
            <div class="open">
              <p>{{ invoiceInfo.opens.open }}</p>
              <p>{{ invoiceInfo.opens.money }}</p>
              <p @click="ClickInvoice">{{ invoiceInfo.opens.ticket }}</p>
            </div>
            <!-- 已开额度  -->
            <div class="opened">
              <p>{{ invoiceInfo.openeds.opened }}</p>
              <p>{{ invoiceInfo.openeds.money }}</p>
              <p @click="ClickInvoiceRecord">
                {{ invoiceInfo.openeds.record }}
              </p>
            </div>
            <!-- 过期额度 -->
            <div class="overdue">
              <p>{{ invoiceInfo.overdues.overdue }}</p>
              <p>{{ invoiceInfo.overdues.money }}</p>
            </div>
          </div>
          <p class="explain">
            <span>{{ invoiceInfo.fpsm }}</span
            ><span>{{ invoiceInfo.fpsmInfo }}</span>
          </p>
        </div>
        <!-- 发票明细 -->
        <div class="bottom">
          <div class="jfmx">
            <p>{{ invoiceInfo.fpmx }}</p>
            <span></span>
          </div>

          <ul>
            <li v-for="(item, index) in fpmxList" :key="index">
              <div class="left">
                <p>{{ "开票税号：" + item.taxpayer_number }}</p>
                <p>{{ "开票企业：" + item.business_name }}</p>
              </div>
              <div class="right">
                <p>-{{ item.invoice }}</p>
                <p>{{ item.create_time }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 支付密码设置 -->
      <div v-if="item == '支付密码设置'">
        <div class="payment">
          <div class="phone">
            <span>手机号</span
            ><input
              type="number"
              :value="editPasword.phone"
              disabled="disabled"
            />
          </div>
          <div class="code" v-if="is_code">
            <span>验证码</span
            ><input type="number" v-model="editPasword.code" />
            <button>
              <span v-show="code.show" @click="getCode">发送验证码</span>
              <span v-show="!code.show" class="count"
                >{{ code.count }}s后重新发送</span
              >
            </button>
          </div>
          <div class="password">
            <span>支付密码</span
            ><input
              type="password"
              v-model="editPasword.password"
              placeholder="请输入"
            />
          </div>
          <div class="editPsw">
            <span></span>
            <p @click="ClickEditPassword">{{ payText }}</p>
          </div>
        </div>
      </div>

      <!-- 会员升级 -->
      <el-dialog
        title="会员升级"
        :visible.sync="upgradeDialog"
        width="540px"
        top="18vh"
      >
        <div class="upgrade_Box" v-loading="cz_loading">
          <!-- 会员升级 进度 -->
          <div class="upgrade">
            <div class="icon" @click="ClickWhite">
              <span v-if="0 == silver"><i></i></span>
              <span v-else
                ><img src="../../../assets/image/白领@2x.png" alt=""
              /></span>
            </div>
            <p :class="{ speed_bg: 1 <= silver }"></p>
            <div class="icon" @click="ClickSilver">
              <span v-if="1 == silver"><i></i></span>
              <span v-else
                ><img src="../../../assets/image/白银@2x.png" alt=""
              /></span>
            </div>
            <p :class="{ speed_bg: 2 <= silver }"></p>
            <div class="icon" @click="ClickGold">
              <span v-if="2 == silver"><i></i></span>
              <span v-else
                ><img src="../../../assets/image/黄金@2x.png" alt=""
              /></span>
            </div>
          </div>
          <!-- 会员名字 -->
          <div class="text">
            <span>白领会员</span>
            <span>银牌会员</span>
            <span>黄金会员</span>
          </div>
          <!-- 会员图片 -->
          <div class="img">
            <img
              src="../../../assets/image/白领会员@2x.png"
              alt=""
              v-if="0 == silver"
            />
            <img
              src="../../../assets/image/白银会员@2x.png"
              alt=""
              v-if="1 == silver"
            />
            <img
              src="../../../assets/image/黄金会员@2x.png"
              alt=""
              v-if="2 == silver"
            />
          </div>
          <!-- 权益 -->
          <div class="equity">
            <p>{{ remark.title ? remark.title : "" }}</p>
            <p>
              {{ remark.content ? remark.content : "" }}
            </p>
          </div>
          <!-- 充值年数 -->
          <div class="year" v-show="silver == 1 && member_level != 3">
            <ul>
              <li
                v-for="(item, index) in silverList"
                @click="ClickYearList(item)"
                :class="{ year_bg: yearId == index + 1 }"
              >
                {{ item.name }}
              </li>
            </ul>
            <p
              style="color: #9c9cab; margin-bottom: 10px"
              v-if="member_end_time != ''"
            >
              会员到期时间：{{ member_end_time }}
            </p>
            <div
              class="money"
              @click="ClickUpgradeYear"
              style="width: 160px"
              v-if="member_level == 1"
            >
              ¥{{ VIPprice }} 立即升级
            </div>
            <div
              class="money"
              @click="ClickUpgradeYear"
              style="width: 160px"
              v-if="member_level == 2 || member_level == 3"
            >
              ¥{{ VIPprice }} 立即续费
            </div>
          </div>

          <div class="year" v-show="silver == 2">
            <ul>
              <li
                v-for="(item, index) in goldList"
                @click="ClickYearList(item)"
                :class="{ year_bg: yearId == index + 1 }"
              >
                {{ item.name }}
              </li>
            </ul>
            <p
              style="color: #9c9cab; margin-bottom: 10px"
              v-if="member_end_time != ''"
            >
              会员到期时间：{{ member_end_time }}
            </p>
            <div
              class="money"
              @click="ClickUpgradeYear"
              style="width: 160px"
              v-if="member_level == 1"
            >
              ¥{{ VIPprice }} 立即升级
            </div>
            <div
              class="money"
              @click="ClickUpgradeYear"
              style="width: 160px"
              v-if="member_level == 2 || member_level == 3"
            >
              ¥{{ VIPprice }} 立即续费
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 充值 -->
      <el-dialog
        title="选择充值金额"
        :visible.sync="rechargeDialog"
        width="540px"
        top="18vh"
        :show-close="false"
      >
        <!-- 充值金额 -->
        <div class="recharge">
          <ul>
            <li
              v-for="(item, index) in rechargeList"
              :key="item.id"
              @click="ClickRechargeList(item)"
              :class="{ year_bg: rechargeId == item.recharge_id }"
            >
              {{ item.money }}
            </li>
          </ul>
          <div class="goRecharge" @click="ClickGoRecharge">去充值</div>
        </div>
      </el-dialog>

      <!-- 选择支付方式 -->
      <el-dialog
        title="选择充值金额"
        :visible.sync="payModeDialog"
        width="540px"
        top="18vh"
        :show-close="false"
      >
        <!-- 支付方式 -->
        <div class="paymode" v-loading="loading" element-loading-text="支付中">
          <ul>
            <li
              v-for="(item, index) in payModeList"
              :key="index"
              :class="{ paybd: payI == item.id }"
              @click="ClickPayMode(item.id)"
            >
              <img
                v-if="item.id == 1"
                src="../../../assets/image/微信支付@2x.png"
                alt=""
              />
              <img
                v-if="item.id == 2"
                src="../../../assets/image/支付宝@2x.png"
                alt=""
              />
              <p>{{ item.type_name + "支付" }}</p>
            </li>
          </ul>
          <div class="zf" @click="ClickPayment">支付</div>
        </div>
      </el-dialog>

      <!-- 扫码支付 -->
      <el-dialog
        :title="payI ? '微信支付' : '支付宝支付'"
        :visible.sync="codeDialog"
        width="540px"
        top="18vh"
      >
        <!-- 扫码 -->
        <div class="scanCode">
          <p>
            应付金额：<span>{{ rechargeMoney }}</span
            >元
          </p>
          <div class="code">
            <img :src="weixin_url" alt="" />
          </div>
          <div class="text">
            <div v-if="payI == 1">
              <img src="../../../assets/image/微信支付@2x.png" alt="" />
              <span>微信支付</span>
            </div>
            <div v-if="payI == 0">
              <img src="../../../assets/image/支付宝@2x.png" alt="" />
              <span>支付宝支付</span>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 提现 -->
      <el-dialog
        title="提现"
        :visible.sync="withdrDialog"
        width="540px"
        top="18vh"
        :show-close="false"
      >
        <!-- 提现方式 -->
        <div class="withdr">
          <ul>
            <li
              v-for="(item, index) in withdrList"
              :key="index"
              @click="ClickWithdrList(index)"
            >
              <div>
                <div class="img">
                  <img :src="item.img" alt="" />
                </div>
                <span>{{ item.text }}</span>
              </div>
              <p :class="{ withdr_bd: withdrI == index }"></p>
            </li>
          </ul>
          <div class="money">
            <input
              v-model="withdrawMoney"
              type="text"
              placeholder="请输入提现金额"
            />
          </div>
          <div class="account" v-show="0 == withdrI">
            <input
              v-model="account"
              type="text"
              placeholder="请输入支付宝账号"
            />
          </div>
          <div class="confirm">
            <div @click="withdraw(withdrI)">确定</div>
            <div @click="withdrawClose">关闭</div>
          </div>
        </div>
      </el-dialog>

      <!-- 开发票信息填写 -->
      <el-dialog
        title="发票信息填写"
        :show-close="false"
        :visible.sync="invoiceDialog"
        width="540px"
        top="25vh"
        style="font-size: 28px"
        :close-on-click-modal="false"
      >
        <span
          class="el-icon-circle-close"
          style="position: absolute; right: 17px; font-size: 25px; top: 17px"
          @click="invoiceDialog = false"
        ></span>
        <el-form
          ref="ticketForm"
          :model="ticketForm"
          :rules="ticketRules"
          style="width: 100%; margin: 0 20px 0 10px"
        >
          <el-form-item label="开票金额" prop="money">
            <el-input
              v-model="ticketForm.money"
              placeholder="请输入开票金额"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮寄地址" prop="yjAddr">
            <el-input
              v-model="ticketForm.yjAddr"
              placeholder="请输入邮寄地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司名称" prop="compay">
            <el-input
              v-model="ticketForm.compay"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="纳税人识别码" prop="number">
            <el-input
              v-model="ticketForm.number"
              placeholder="请输入纳税人识别码"
            ></el-input>
          </el-form-item>
          <el-form-item label="注册地址" prop="zcAddr">
            <el-input
              v-model="ticketForm.zcAddr"
              placeholder="请输入注册地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司电话" prop="gsPhone">
            <el-input
              v-model="ticketForm.gsPhone"
              placeholder="请输入公司电话"
              type="number"
            ></el-input>
          </el-form-item>

          <el-form-item label="联系人" prop="name">
            <el-input
              v-model="ticketForm.name"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人电话" prop="yjPhone" type="number">
            <el-input
              v-model="ticketForm.yjPhone"
              placeholder="请输入联系人电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email">
            <el-input
              v-model="ticketForm.email"
              placeholder="请输入电子邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              style="
                margin: 10px auto;
                width: 88px;
                height: 34px;
                line-height: 0;
                background: #1677ff;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
              "
              type="primary"
              @click="invoicetSubmit('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 开发票信息填写提交成功 -->
      <el-dialog
        title=""
        :visible.sync="invoiceSubmitDialog"
        width="540px"
        top="35vh"
        :show-close="false"
      >
        <div class="prompt">
          <div class="state">
            <span
              class="el-icon-check"
              style="font-size: 50px; color: #fff"
            ></span>
          </div>
          <p>提交成功</p>
          <p>请等待后台审核信息</p>
          <div class="back" @click="invoiceSubmitDialog = false">返回</div>
        </div>
      </el-dialog>

      <!-- 申请开票记录 -->
      <el-dialog
        title="开票记录信息"
        :visible.sync="invoiceRecordDialog"
        width="861px"
        top="25vh"
        :show-close="false"
      >
        <div class="record">
          <div
            style="
              font-size: 14px;
              color: #9c9cab;
              padding: 20px;
              margin: 100px 0 100px 0;
            "
            v-if="total1 < 1"
          >
            <img src="../../../assets/image/缺省图.png" style="width: 13%" />
            <p style="margin-top: 15px">暂无列表</p>
          </div>
          <ul style="height: 400px" v-if="total1 >= 1">
            <li
              v-for="(item, index) in recordList"
              :key="index"
              style="margin-bottom: 0px; height: 90px; margin-top: 0"
            >
              <div class="left">
                <p>{{ "开票税号：" + item.taxpayer_number }}</p>
                <p>{{ "开票企业：" + item.business_name }}</p>
                <p>{{ "开票金额：" + item.invoice }}</p>
              </div>
              <div class="right">
                <p v-if="'已完成' == item.status_name" style="color: #16ca2c">
                  {{ item.status_name }}
                </p>
                <p v-if="'开票中' == item.status_name" style="color: #1677ff">
                  {{ item.status_name }}
                </p>
                <p v-if="'已驳回' == item.status_name" style="color: #898b9c">
                  {{ item.status_name }}
                </p>
                <p v-if="'已开票' == item.status_name" style="color: #ff0000">
                  {{ item.status_name }}
                </p>
                <p v-if="'已邮寄' == item.status_name" style="color: #005500">
                  {{ item.status_name }}
                </p>
                <p>{{ item.create_time }}</p>
              </div>
            </li>
          </ul>
          <div class="page" style="padding: 25px 0 40px 0">
            <el-pagination
              background
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page.sync="queryInfo.pagenum1"
              :page-size="5"
              layout="prev, pager, next"
              :total="total1"
              :hide-on-single-page="true"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>

      <div
        style="font-size: 14px; color: #9c9cab; padding: 20px"
        v-if="total < 1 && item !== '支付密码设置'"
      >
        <img src="../../../assets/image/缺省图.png" style="width: 13%" />
        <p style="margin-top: 15px">暂无列表</p>
      </div>
      <!-- 底部分页 -->
      <div class="page" style="padding: 25px 0 50px 0">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.pagenum"
          :page-size="10"
          layout="prev, pager, next"
          :total="total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc" id="mobile">
      <div style="width: 100%; height: 60px"></div>
      <Header></Header>
      <!-- 财务管理列表 -->
      <div class="top">
        <div
          style="
            text-align: left;
            display: flex;
            align-items: center;
            line-height: 26px;
            padding-left: 20px;
            background-color: #fff;
            font-weight: 700;
          "
        >
          <p
            style="
              width: 3px;
              height: 18px;
              background-color: #1677ff;
              margin-right: 10px;
            "
          ></p>
          <p>财务管理</p>
        </div>
        <ul style="">
          <li
            v-for="(item, index) in financialList"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickFinancialListList(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>

      <!-- 我的积分 -->
      <div v-if="item == '我的积分'">
        <!-- 中间展示 -->
        <div class="center">
          <p>{{ jfInfo.jf }}</p>
          <p>{{ jfInfo.how }}</p>
          <p>
            <span>{{ jfInfo.jfsm }}</span
            ><span>{{ jfInfo.jfsmInfo }}</span>
          </p>
        </div>
        <!-- 积分明细 -->
        <div class="bottom">
          <div class="jfmx">
            <p>{{ jfInfo.jfmx }}</p>
            <span></span>
          </div>
          <LoadMore
            :onLoadMore="onLoadMore"
            :enableLoadMore="enableLoadMore"
            ref="LoadMore"
          >
            <ul>
              <li v-for="(item, index) in jfmxList" :key="index">
                <div class="left">
                  <p>{{ item.remark }}</p>
                  <p>{{ item.create_time }}</p>
                </div>
                <div class="right">
                  <p style="font-size: 14px; font-weight: 700">
                    {{ item.price }}
                  </p>
                </div>
              </li>
            </ul>
          </LoadMore>
        </div>
      </div>

      <!-- 我的余额 -->
      <div v-if="item == '我的余额'">
        <!-- 中间展示 -->
        <div class="center">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
            "
          >
            <div>
              <p>{{ yeInfo.ye }}</p>
              <p>{{ yeInfo.how }}</p>
              <p>
                <span>{{ yeInfo.yesm }}</span
                ><span>{{ yeInfo.yesmInfo }}</span>
              </p>
            </div>
            <div
              class="btns"
              style="position: absolute; top: 0px; right: -20px"
            >
              <!-- <span class="btn" style="background: #fd588b" @click="ClickUpgrade" v-if="isIdentity=='企业'">升级</span> -->
              <span
                class="btn"
                style="background: #fd588b"
                @click="ClickUpgrade"
                v-if="isIdentity != '企业'"
                >升级</span
              >
              <span
                class="btn"
                style="background: #ee8525"
                @click="ClickWithdraw"
                >提现</span
              >
              <span
                class="btn"
                style="background: #28cc5c"
                @click="ClickRecharge"
                >充值</span
              >
              <span
                class="btn"
                style="background: #ff0000; padding: 0 5px; width: 60px"
                @click="ClickConversion"
                >积分兑换</span
              >
            </div>
          </div>
        </div>
        <!-- 余额明细 -->
        <div class="bottom">
          <div class="jfmx">
            <p>{{ yeInfo.yemx }}</p>
            <span></span>
          </div>
          <LoadMore
            :onLoadMore="onLoadMore"
            :enableLoadMore="enableLoadMore"
            ref="LoadMore"
          >
            <ul>
              <li v-for="(item, index) in yemxList" :key="index">
                <div class="left">
                  <p>{{ item.remark }}</p>
                  <p>{{ item.create_time }}</p>
                </div>
                <div class="right">
                  <p style="font-size: 14px; font-weight: 700">
                    {{ item.price }}
                  </p>
                </div>
              </li>
            </ul>
          </LoadMore>
        </div>
      </div>

      <!-- 发票索取 -->
      <div v-if="item == '发票索取'">
        <!-- 中间展示 -->
        <div class="invoice">
          <div style="display: flex; justify-content: start">
            <!-- 可用额度 -->
            <div class="open">
              <p>{{ invoiceInfo.opens.open }}</p>
              <p>{{ invoiceInfo.opens.money }}</p>
              <p @click="ClickInvoice">{{ invoiceInfo.opens.ticket }}</p>
            </div>
            <!-- 已开额度  -->
            <div class="opened">
              <p>{{ invoiceInfo.openeds.opened }}</p>
              <p>{{ invoiceInfo.openeds.money }}</p>
              <p @click="ClickInvoiceRecord">
                {{ invoiceInfo.openeds.record }}
              </p>
            </div>
            <!-- 过期额度 -->
            <div class="overdue">
              <p>{{ invoiceInfo.overdues.overdue }}</p>
              <p>{{ invoiceInfo.overdues.money }}</p>
            </div>
          </div>
          <p class="explain" style="margin-left: 0">
            <span>{{ invoiceInfo.fpsm }}</span
            ><span>{{ invoiceInfo.fpsmInfo }}</span>
          </p>
        </div>
        <!-- 发票明细 -->
        <div class="bottom">
          <div class="jfmx">
            <p>{{ invoiceInfo.fpmx }}</p>
            <span></span>
          </div>
          <LoadMore
            :onLoadMore="onLoadMore"
            :enableLoadMore="enableLoadMore"
            ref="LoadMore"
          >
            <ul>
              <li v-for="(item, index) in fpmxList" :key="index">
                <div class="left">
                  <p>{{ "开票税号：" + item.taxpayer_number }}</p>
                  <p>{{ "开票企业：" + item.business_name }}</p>
                </div>
                <div class="right">
                  <p>-{{ item.invoice }}</p>
                  <p>{{ item.create_time }}</p>
                </div>
              </li>
            </ul>
          </LoadMore>
        </div>
      </div>

      <!-- 支付密码设置 -->
      <div
        v-if="item == '支付密码设置'"
        style="padding: 20px; position: relative"
      >
        <div class="payment">
          <div class="phone">
            <span>手机号</span
            ><input
              type="number"
              :value="editPasword.phone"
              disabled="disabled"
            />
          </div>
          <div class="code" v-if="is_code">
            <span>验证码</span
            ><input type="number" v-model="editPasword.code" />
            <button
              style="
                position: absolute;
                right: 30px;
                color: #1677ff;
                top: 90px;
                background-color: #fff;
              "
            >
              <span v-show="code.show" @click="getCode">发送验证码</span>
              <span v-show="!code.show" class="count"
                >{{ code.count }}s后重新发送</span
              >
            </button>
          </div>
          <div class="password">
            <span>支付密码</span
            ><input
              type="password"
              v-model="editPasword.password"
              placeholder="请输入"
            />
          </div>
          <div
            style="
              background-color: #1677ff;
              height: 38px;
              color: #fff;
              width: 200px;
              margin: 0 auto;
              border-radius: 5px;
              line-height: 38px;
              margin-top: 30px;
              text-align: center;
            "
          >
            <span></span>
            <p @click="ClickEditPassword">{{ payText }}</p>
          </div>
        </div>
      </div>

      <!-- 会员升级 -->
      <el-dialog
        title="会员升级"
        :visible.sync="upgradeDialog"
        width="540px"
        top="18vh"
      >
        <div class="upgrade_Box" v-loading="cz_loading">
          <!-- 会员升级 进度 -->
          <div class="upgrade">
            <div class="icon" @click="ClickWhite">
              <span v-if="0 == silver"><i></i></span>
              <span v-else
                ><img src="../../../assets/image/白领@2x.png" alt=""
              /></span>
            </div>
            <p :class="{ speed_bg: 1 <= silver }"></p>
            <div class="icon" @click="ClickSilver">
              <span v-if="1 == silver"><i></i></span>
              <span v-else
                ><img src="../../../assets/image/白银@2x.png" alt=""
              /></span>
            </div>
            <p :class="{ speed_bg: 2 <= silver }"></p>
            <div class="icon" @click="ClickGold">
              <span v-if="2 == silver"><i></i></span>
              <span v-else
                ><img src="../../../assets/image/黄金@2x.png" alt=""
              /></span>
            </div>
          </div>
          <!-- 会员名字 -->
          <div class="text">
            <span>白领会员</span>
            <span>银牌会员</span>
            <span>黄金会员</span>
          </div>
          <!-- 会员图片 -->
          <div class="img">
            <img
              src="../../../assets/image/白领会员@2x.png"
              alt=""
              v-if="0 == silver"
            />
            <img
              src="../../../assets/image/白银会员@2x.png"
              alt=""
              v-if="1 == silver"
            />
            <img
              src="../../../assets/image/黄金会员@2x.png"
              alt=""
              v-if="2 == silver"
            />
          </div>
          <!-- 权益 -->
          <div class="equity">
            <p>会员权益介绍</p>
            <p>
              成长值是指,会员通过线路预定、填写资料、老带新、发表文章等途径所获得的经验值,它标志着会员在累计的经验值,成长值越高会员等级越高,
            </p>
          </div>
          <!-- 充值年数 -->
          <div class="year" v-show="silver == 1 && member_level != 3">
            <ul>
              <li
                v-for="(item, index) in silverList"
                @click="ClickYearList(item)"
                :class="{ year_bg: yearId == index + 1 }"
              >
                {{ item.name }}
              </li>
            </ul>
            <p
              style="color: #9c9cab; margin-bottom: 10px"
              v-if="member_end_time != ''"
            >
              会员到期时间：{{ member_end_time }}
            </p>
            <div
              class="money"
              @click="ClickUpgradeYear"
              style="width: 160px"
              v-if="member_level == 1"
            >
              ¥{{ VIPprice }} 立即升级
            </div>
            <div
              class="money"
              @click="ClickUpgradeYear"
              style="width: 160px"
              v-if="member_level == 2 || member_level == 3"
            >
              ¥{{ VIPprice }} 立即续费
            </div>
          </div>

          <div class="year" v-show="silver == 2">
            <ul>
              <li
                v-for="(item, index) in goldList"
                @click="ClickYearList(item)"
                :class="{ year_bg: yearId == index + 1 }"
              >
                {{ item.name }}
              </li>
            </ul>
            <p
              style="color: #9c9cab; margin-bottom: 10px"
              v-if="member_end_time != ''"
            >
              会员到期时间：{{ member_end_time }}
            </p>
            <div
              class="money"
              @click="ClickUpgradeYear"
              style="width: 160px"
              v-if="member_level == 1"
            >
              ¥{{ VIPprice }} 立即升级
            </div>
            <div
              class="money"
              @click="ClickUpgradeYear"
              style="width: 160px"
              v-if="member_level == 2 || member_level == 3"
            >
              ¥{{ VIPprice }} 立即续费
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 充值 -->
      <el-dialog
        title="选择充值金额"
        :visible.sync="rechargeDialog"
        width="100%"
        top="10vh"
        :show-close="false"
      >
        <!-- 充值金额 -->
        <div class="recharge">
          <ul style="padding-left: 12px">
            <li
              v-for="(item, index) in rechargeList"
              :key="item.id"
              @click="ClickRechargeList(item)"
              :class="{ year_bg: rechargeId == item.recharge_id }"
              style="font-size: 14px; font-weight: 700"
            >
              {{ item.money }}
            </li>
          </ul>
          <div class="goRecharge" @click="ClickGoRecharge">去充值</div>
        </div>
      </el-dialog>

      <!-- 选择支付方式 -->
      <el-dialog
        title="选择充值金额"
        :visible.sync="payModeDialog"
        width="540px"
        top="18vh"
        :show-close="false"
      >
        <!-- 支付方式 -->
        <div class="paymode" v-loading="loading" element-loading-text="支付中">
          <ul>
            <li
              v-for="(item, index) in payModeList"
              :key="index"
              :class="{ paybd: payI == item.id }"
              @click="ClickPayMode(item.id)"
            >
              <img
                v-if="item.id == 1"
                src="../../../assets/image/微信支付@2x.png"
                alt=""
              />
              <img
                v-if="item.id == 2"
                src="../../../assets/image/支付宝@2x.png"
                alt=""
              />
              <p>{{ item.type_name + "支付" }}</p>
            </li>
          </ul>
          <div class="zf" @click="ClickPayment">支付</div>
        </div>
      </el-dialog>

      <!-- 扫码支付 -->
      <el-dialog
        :title="payI ? '微信支付' : '支付宝支付'"
        :visible.sync="codeDialog"
        width="540px"
        top="18vh"
        :show-close="false"
      >
        <!-- 扫码 -->
        <div class="scanCode">
          <p>
            应付金额：<span>{{ rechargeMoney }}</span
            >元
          </p>
          <div class="code">
            <img :src="weixin_url" alt="" />
          </div>
          <div class="text">
            <div v-if="payI == 1">
              <img src="../../../assets/image/微信支付@2x.png" alt="" />
              <span>微信支付</span>
            </div>
            <div v-if="payI == 2">
              <img src="../../../assets/image/支付宝@2x.png" alt="" />
              <span>支付宝支付</span>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 提现 -->
      <el-dialog
        title="提现"
        :visible.sync="withdrDialog"
        width="540px"
        top="18vh"
        show-close
      >
        <!-- 提现方式 -->
        <div class="withdr">
          <ul>
            <li
              v-for="(item, index) in withdrList"
              :key="index"
              @click="ClickWithdrList(index)"
            >
              <div>
                <div class="img">
                  <img :src="item.img" alt="" />
                </div>
                <span>{{ item.text }}</span>
              </div>
              <p :class="{ withdr_bd: withdrI == index }"></p>
            </li>
          </ul>
          <div class="money">
            <input
              v-model="withdrawMoney"
              type="text"
              placeholder="请输入提现金额"
            />
          </div>
          <div class="account" v-show="0 == withdrI">
            <input
              v-model="account"
              type="text"
              placeholder="请输入支付宝账号"
            />
          </div>
          <div class="confirm">
            <div @click="withdraw(withdrI)">确定</div>
            <div @click="withdrawClose">关闭</div>
          </div>
        </div>
      </el-dialog>

      <!-- 开发票信息填写 -->
      <el-dialog
        title="发票信息填写"
        :show-close="false"
        :visible.sync="invoiceDialog"
        width="540px"
        top="10vh"
        style="font-size: 28px"
        :close-on-click-modal="false"
      >
        <span
          class="el-icon-circle-close"
          style="position: absolute; right: 17px; font-size: 25px; top: 17px"
          @click="invoiceDialog = false"
        ></span>
        <el-form
          ref="ticketForm"
          :model="ticketForm"
          :rules="ticketRules"
          style="margin: 0 20px 0 10px"
        >
          <el-form-item label="开票金额" prop="money">
            <el-input
              v-model="ticketForm.money"
              placeholder="请输入开票金额"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮寄地址" prop="yjAddr">
            <el-input
              v-model="ticketForm.yjAddr"
              placeholder="请输入邮寄地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司名称" prop="compay">
            <el-input
              v-model="ticketForm.compay"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="纳税人识别码" prop="number">
            <el-input
              v-model="ticketForm.number"
              placeholder="请输入纳税人识别码"
            ></el-input>
          </el-form-item>
          <el-form-item label="注册地址" prop="zcAddr">
            <el-input
              v-model="ticketForm.zcAddr"
              placeholder="请输入注册地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司电话" prop="gsPhone">
            <el-input
              v-model="ticketForm.gsPhone"
              placeholder="请输入公司电话"
              type="number"
            ></el-input>
          </el-form-item>

          <el-form-item label="联系人" prop="name">
            <el-input
              v-model="ticketForm.name"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人电话" prop="yjPhone" type="number">
            <el-input
              v-model="ticketForm.yjPhone"
              placeholder="请输入联系人电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" prop="email">
            <el-input
              v-model="ticketForm.email"
              placeholder="请输入电子邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              style="
                margin: 10px auto;
                width: 88px;
                height: 34px;
                line-height: 0;
                background: #1677ff;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
              "
              type="primary"
              @click="invoicetSubmit('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 开发票信息填写提交成功 -->
      <el-dialog
        title=""
        :visible.sync="invoiceSubmitDialog"
        width="540px"
        top="25vh"
        :show-close="false"
      >
        <div class="prompt">
          <div class="state">
            <span
              class="el-icon-check"
              style="font-size: 50px; color: #fff"
            ></span>
          </div>
          <p>提交成功</p>
          <p>请等待后台审核信息</p>
          <div class="back" @click="invoiceSubmitDialog = false">返回</div>
        </div>
      </el-dialog>

      <!-- 申请开票记录 -->
      <el-dialog
        title="开票记录信息"
        :visible.sync="invoiceRecordDialog"
        width="861px"
        :show-close="false"
      >
        <div class="record">
          <div
            style="
              font-size: 14px;
              color: #9c9cab;
              padding: 20px;
              margin: 100px 0 100px 0;
            "
            v-if="total1 < 1"
          >
            <img src="../../../assets/image/缺省图.png" style="width: 13%" />
            <p style="margin-top: 15px">暂无列表</p>
          </div>
          <LoadMore
            :onLoadMore="onLoadMore"
            :enableLoadMore="enableLoadMore"
            ref="LoadMore"
          >
            <ul style="height: 400px" v-if="total1 >= 1">
              <li
                v-for="(item, index) in recordList"
                :key="index"
                style="margin-bottom: 0px; height: 90px; margin-top: 0"
              >
                <div class="left">
                  <p>{{ "开票税号：" + item.taxpayer_number }}</p>
                  <p>{{ "开票企业：" + item.business_name }}</p>
                  <p>{{ "开票金额：" + item.invoice }}</p>
                </div>
                <div class="right">
                  <p v-if="'已完成' == item.status_name" style="color: #16ca2c">
                    {{ item.status_name }}
                  </p>
                  <p v-if="'开票中' == item.status_name" style="color: #1677ff">
                    {{ item.status_name }}
                  </p>
                  <p v-if="'已驳回' == item.status_name" style="color: #898b9c">
                    {{ item.status_name }}
                  </p>
                  <p v-if="'已开票' == item.status_name" style="color: #ff0000">
                    {{ item.status_name }}
                  </p>
                  <p v-if="'已邮寄' == item.status_name" style="color: #005500">
                    {{ item.status_name }}
                  </p>
                  <p>{{ item.create_time }}</p>
                </div>
              </li>
            </ul>
          </LoadMore>
          <div class="page" style="padding: 25px 0 40px 0">
            <!--   <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page.sync="queryInfo.pagenum1"
              :page-size="5" layout="prev, pager, next" :total="total1" :hide-on-single-page="true">
            </el-pagination> -->
          </div>
        </div>
      </el-dialog>

      <div
        style="font-size: 14px; color: #9c9cab; padding: 20px"
        v-if="total < 1"
      >
        <img src="../../../assets/image/缺省图.png" style="width: 13%" />
        <p style="margin-top: 15px">暂无列表</p>
      </div>
      <!-- 底部分页 -->
      <!-- <div class="page" style="padding: 25px 0 50px 0;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="queryInfo.pagenum"
          :page-size="10" layout="prev, pager, next" :total="total" :hide-on-single-page="true">
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>
<script>
import LoadMore from "../../loadMore/loadMore.vue";
import Header from "../../header.vue";
import Blob from "../../../excel/Blob";
import Export2Excel from "../../../excel/Export2Excel";
export default {
  name: "financial",
  components: {
    Header,
    LoadMore,
  },
  // computed: {
  //   getInfoId() {
  //     return this.$store.state.query.upgradeDialog;
  //   },
  // },
  data() {
    return {
      item: "我的积分",
      VIPprice: 0,
      loading: false,
      // 总条数
      total: 0,
      total1: 0,
      queryInfo: {
        pagenum1: 1,
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      // 控制升级显示框的显示与隐藏
      upgradeDialog: false,

      // 控制充值显示框的显示与隐藏
      rechargeDialog: false,

      // 控制支付方式框的显示与隐藏
      payModeDialog: false,

      // 控制扫码框的显示与隐藏
      codeDialog: false,

      // 提现框的显示与隐藏
      withdrDialog: false,

      // 开票信息填写框的显示与隐藏
      invoiceDialog: false,

      // 开票信息提交成功框的显示与隐藏
      invoiceSubmitDialog: false,

      invoiceRecordDialog: false,

      // 财务管理列表
      financialList: ["我的余额", "我的积分", "支付密码设置"],
      //   默认第一个选中索引第一个
      i: 0,

      // 控制白银会员的进度索引第一个
      silver: 0,

      // 升级年数默认显示ID第一个
      yearId: 1,

      // 充值默认显示ID第一个
      rechargeId: "",
      // 默认充值第一个30元
      rechargeMoney: "",

      // 支付方式默认显示索引第一个
      payI: 1,

      // 提现金额
      withdrawMoney: "",
      // 提现账号
      account: "",

      // 提现方式默认索引第一个
      withdrI: 0,

      // 发送验证码,
      code: {
        show: true,
        count: "",
        timer: null,
      },
      // 修改密码的参数
      editPasword: {
        phone: "",
        code: "",
        password: "",
      },

      // 开票填写信息
      ticketForm: {
        compay: "",
        number: "",
        zcAddr: "",
        gsPhone: "",
        yjAddr: "",
        yjPhone: "",
        money: "",
        email: "",
        name: "",
      },
      // 开票表单验证
      ticketRules: {
        money: [
          {
            required: true,
            message: "请输入开票金额",
            trigger: "blur",
          },
        ],
        compay: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入纳税人识别码",
            trigger: "blur",
          },
        ],
        zcAddr: [
          {
            required: true,
            message: "请输入注册地址",
            trigger: "blur",
          },
        ],
        gsPhone: [
          {
            required: true,
            message: "请输入公司电话",
            trigger: "blur",
          },
        ],
        yjAddr: [
          {
            required: true,
            message: "请输入邮寄地址",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        yjPhone: [
          {
            required: true,
            message: "请输入联系人电话",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入电子邮箱",
            trigger: "blur",
          },
        ],
      },

      // 升级充值年数列表
      yearList: [
        {
          text: "1年",
          id: 1,
        },
        {
          text: "2年",
          id: 2,
        },
        {
          text: "3年",
          id: 3,
        },
        {
          text: "4年",
          id: 4,
        },
        {
          text: "5年",
          id: 5,
        },
      ],

      // 充值列表
      rechargeList: [],
      // 支付方式列表
      payModeList: [
        {
          img: require("../../../assets/image/支付宝@2x.png"),
          mode: "支付宝支付",
        },
        {
          img: require("../../../assets/image/微信支付@2x.png"),
          mode: "微信支付",
        },
      ],
      // 提现方式列表
      withdrList: [
        {
          img: require("../../../assets/image/支付宝@2x.png"),
          text: "支付宝",
          zh: "请输入支付宝账号",
        },
        {
          img: require("../../../assets/image/微信支付@2x.png"),
          text: "微信",
          zh: "请输入微信账号",
        },
      ],

      //   我的积分信息
      jfInfo: {
        jf: "积分",
        how: 0.0,
        jfsm: "积分说明:",
        jfsmInfo: "积分说明信息****************************",
        jfmx: "积分明细",
      },

      //   我的余额信息
      yeInfo: {
        ye: "余额",
        how: 0,
        yesm: "说明:",
        yesmInfo: "余额说明信息****************************",
        yemx: "余额明细",
        can_drawmoney_str: 0,
      },

      // 发票索取
      invoiceInfo: {
        opens: {
          open: "可开发票额度",
          money: 0,
          ticket: "开票",
        },
        openeds: {
          opened: "已开发票额度",
          money: 0,
          record: "申请开票记录",
        },
        overdues: {
          overdue: "已过期票额度",
          money: 0,
        },
        fpsm: "发票说明:",
        fpsmInfo: "发票说明信息****************************",
        fpmx: "发票明细",
      },
      //   积分明细列表
      jfmxList: [
        {
          create_time: "2020-11-26", //变动时间
          remark: "测试-虚拟积分变动", //变动事件名称
          price: 100, //变动数值
        },
      ],

      //   余额明细列表
      yemxList: [],

      // 成功发票发票列表
      fpmxList: [],

      // 发票记录列表
      recordList: [],
      weixin_url:
        "http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201212/185654_538166.gif", //微信支付二维码
      user: "",
      timer: null, //支付的定时器
      goldList: [], //黄金会员价格
      silverList: [], //银牌会员价格
      member_end_time: 0, //会员到期时间
      remark: {}, //权益信息
      userInfo: "",
      isIdentity: "",
      payText: "立即设置",
      member_name: "",
      member_level: "1",
      is_code: true, // 是否展示发送验证码
      show_pc: true,
      enableLoadMore: true,
      cz_loading: false,
    };
  },
  async created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.$parent.setIcon = "财务管理";
    this.getUser();
    this.item = this.$store.state.query.item;
    this.i = this.$store.state.query.i;

    console.log(this.upgradeDialog);
    if (this.$route.query.tabs) {
      if (this.$store.state.isIdentity == "企业") {
        this.financialList = [
          "我的余额",
          "我的积分",

          "发票索取",
          "支付密码设置",
        ];
      }
      this.ClickFinancialListList(this.$route.query.tabs, 0);
    } else {
      this.i = 0;
      this.item = "我的余额";
      this.getBalance();
    }
  },
  methods: {
    exportExcel() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/my_balance", {
          page: this.queryInfo.pagenum,
          page_size: 10000,
        })
        .then((data) => {
          var yemxList = data.data.list;
          var title = this.$store.state.get_userinfo.nickname;

          require.ensure([], () => {
            const {
              export_json_to_excel,
            } = require("../../../excel/Export2Excel");
            const tHeader = ["标题", "金额", "时间"];
            // 上面设置Excel的表格第一行的标题
            const filterVal = ["remark", "price", "create_time"];

            // 上面的index、nickName、name是tableData里对象的属性
            const list = yemxList; //把data里的tableData存到list
            const data = this.formatJson(filterVal, list);
            var date = new Date();
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var d = date.getDate();
            d = d < 10 ? "0" + d : d;
            // var hh = date.getHours();
            // var mm = date.getMinutes();
            // var ss = date.getSeconds();
            // + hh + ":" + mm + "：" + ss
            const time = y + "-" + m + "-" + d;
            export_json_to_excel(tHeader, data, title + "余额明细" + time);
          });
        })
        .catch((error) => {});
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    //移动端上拉刷新
    onLoadMore(done, total = "") {
      let item = this.item;
      if (!this.enableLoadMore) {
        return;
      }
      if (total == "") {
        this.queryInfo.pagenum++;
      }
      if (item == "我的积分") {
        this.getIntegral();
        return;
      }
      if (item == "我的余额") {
        this.getBalance();
        return;
      }
      if (item == "发票索取") {
        this.getInvoice();
        this.getinvoiceLog();
        return;
      }
    },
    getUser() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
        .then(({ data }) => {
          this.userInfo = data;
          this.$store.state.user.userInfo = data;
          this.editPasword.phone = data.phone;
          if (data.is_bind_safe == 1) {
            this.editPasword.password = "123456";
            this.payText = "修改密码";
            this.is_code = false;
          }
          console.log(data.is_business);
          if (data.is_writer != 1 && data.is_business != 1) {
            this.isIdentity = "普通用户";
            this.financialList = ["我的余额", "我的积分", "支付密码设置"];
          }
          if (data.is_writer == 1) {
            this.isIdentity = "作者";
            this.financialList = ["我的余额", "我的积分", "支付密码设置"];
          }
          if (data.is_business == 1) {
            this.isIdentity = "企业";
            this.financialList = [
              "我的余额",
              "我的积分",

              "发票索取",
              "支付密码设置",
            ];
          }
        })
        .catch((error) => {});
    },
    //获取积分
    getIntegral() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/my_score", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          console.log(data);
          this.fullscreenLoading = false;
          this.total = data.total_count;
          this.jfInfo.how = data.score;
          this.jfInfo.jfsmInfo = data.desc;

          if (!this.show_pc) {
            data.total_count == this.jfmxList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.jfmxList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }

          this.jfmxList = data.list;
        })
        .catch((error) => {});
    },
    //获取余额
    getBalance() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/my_balance", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then((data) => {
          console.log(data, "54545");
          this.explain = data.data.remark;
          this.yeInfo.yesmInfo = data.data.desc;
          this.yeInfo.how = data.data.balance;
          this.yemxList = data.data.list;
          this.yeInfo.can_drawmoney_str = data.data.can_drawmoney_str;
          this.total = data.data.total_count;
          if (data.data._balance > 0 && !data.data.safe_password) {
            //没有设置支付密码
            this.$confirm("网站内消费需要设置支付密码", "提示", {
              confirmButtonText: "去设置",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.item = "支付密码设置";
                this.financialList.map((v, i) => {
                  if ("支付密码设置" == v) {
                    this.i = i;
                  }
                });
                this.total = 1;
              })
              .catch(() => {});
          }

          if (!this.show_pc) {
            data.data.total_count == this.yemxList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.yemxList.push(...data.data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }

          this.yemxList = data.data.list;
        })
        .catch((error) => {});
    },
    //提现
    withdraw(item) {
      //0支付宝   1 微信
      if (this.withdrawMoney < 0 || this.withdrawMoney == "") {
        this.$message.error("请输入正确的提现金额");
        return;
      }
      if (item == 0 && this.account == "") {
        this.$message.error("请输入正确的提现账号");
        return;
      }
      console.log(this.withdrawMoney);
      this.$http
        .post("/index.php/api/v1_0_0.consumer/draw_money", {
          quota: this.withdrawMoney * 100,
          user_type: item == 0 ? 2 : 1,
          alipay_number: this.account,
        })
        .then((data) => {
          this.getBalance();
          console.log(data, "提现");
          this.yeInfo.how = data.data.balance / 100;
          this.$message.success(data.msg);
          this.withdrDialog = false;
        })
        .catch((error) => {});
    },
    withdrawClose() {
      this.withdrDialog = false;
    },
    //获取发票
    getInvoice() {
      //发票统计
      this.$http
        .post("/index.php/api/v1_0_0.consumer/invoice_total", {})
        .then(({ data }) => {
          console.log(data, "发票统计");
          this.invoiceInfo.opens.money = data.cur_quota; //可开发票额度
          this.invoiceInfo.openeds.money = data.past_quota;
          this.invoiceInfo.overdues.money = data.expire_quota;
        })
        .catch((error) => {});
    },
    //获取开票记录
    getinvoiceLog() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/invoice_log", {
          type: 0,
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          this.total = data.total_count;
          if (!this.show_pc) {
            data.total_count == this.fpmxList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.fpmxList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }
          this.fpmxList = data.list;
          console.log("开票记录123");
        })
        .catch((error) => {});
    },
    // 点击财务管理列表的函数
    ClickFinancialListList(item, index) {
      this.jfmxList = [];
      this.yemxList = [];
      this.fpmxList = [];
      this.recordList = [];

      this.queryInfo.pagenum = 1;
      this.item = item;
      this.financialList.map((v, i) => {
        if (item == v) {
          this.i = i;
          console.log(this.i, "this.i");
        }
      });
      if (item == "我的积分") {
        this.getIntegral();
        return;
      }
      if (item == "我的余额") {
        this.getBalance();
        return;
      }
      if (item == "发票索取") {
        this.getInvoice();
        this.getinvoiceLog();
        return;
      }
      if (item == "支付密码设置") {
        this.getUser();
        this.total = 1;
        return;
      }
    },

    // 白领会员
    ClickWhite() {
      this.silver = 0;
    },

    // 点击白银会员
    ClickSilver() {
      console.log("银牌");
      this.member_name = "silver";
      this.yearId = 1;
      this.silver = 1;
      if (this.silverList.length >= 1) {
        this.VIPprice = this.silverList[0].price;
      }
    },
    // 点击黄金会员
    ClickGold() {
      console.log("金牌");
      this.member_name = "gold";
      this.yearId = 1;
      this.silver = 2;
      this.VIPprice = this.goldList[0].price;
    },

    // 升级模块函数
    // 点击升级
    ClickUpgrade() {
      console.log("升级");
      this.upgradeDialog = true;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/consumer_inc", {})
        .then((data) => {
          console.log(data.data, "升级1");
          this.goldList = data.data.gold;
          this.remark = data.data.remark;
          this.silverList = data.data.silver;
          for (let i = 0; i < data.data.silver.length; i++) {
            data.data.silver[i].id = i + 1;
            data.data.gold[i].id = i + 1;
          }
          this.member_end_time = data.data.member_end_time; //会员到期时间

          if (data.data.member_level == 1) {
            this.member_level = 1;
          }
          if (data.data.member_level == 2) {
            this.member_level = 2;
            this.ClickSilver();
          }
          if (data.data.member_level == 3) {
            this.member_level = 3;
            this.ClickGold();
          }
        })
        .catch((error) => {});
    },

    // 点击切换选升级年数
    ClickYearList(id) {
      this.yearId = id.id;
      this.VIPprice = id.price;
      console.log("升级年数", id.id);
    },
    // 立即支付
    async ClickUpgradeYear() {
      this.cz_loading = true;
      console.log("立即支付升级年数", this.yearId, this.member_name);
      let memberOrder = await this.$http
        .post("/index.php/api/v1_0_0.consumer/member_inc_order", {
          member_name: this.member_name,
          member_year: this.yearId,
        })
        .catch((error) => {})
        .finally(() => {
          this.cz_loading = false;
        });

      console.log(memberOrder);
      // 有钱
      if (memberOrder.data.balance) {
        memberOrder.msg = `<p style="font-size: 16px;line-height: 26px;">${memberOrder.msg}</p>`;
        let balance = await this.$prompt("请输入密码", {
          confirmButtonText: "确定",
          message: memberOrder.msg,
          showClose: false,
          dangerouslyUseHTMLString: true,
          cancelButtonText: "取消",
          inputPlaceholder: "请输入支付密码",
          inputType: "password",
          inputValidator: function (v) {
            if (!v) return "请输入支付密码";
          },
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消支付",
          });
        });
        if (typeof balance == "undefined") return;
        console.log(balance, "balance");

        let pay = await this.$http
          .post("/index.php/api/v1_0_0.consumer/member_inc_pay ", {
            password: balance.value,
            order_num: memberOrder.data.order_num,
          })

          .catch((error) => {});
        console.log(pay, "pay");
        if (pay.data.need_password) {
          //没有设置支付密码
          this.$confirm(pay.msg, "提示", {
            confirmButtonText: "设置",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.upgradeDialog = false;
              this.ClickFinancialListList("支付密码设置", 0);
            })
            .catch(() => {});
        } else {
          // console.log(pay.msg);
          this.$message.success(pay.msg);
          this.ClickUpgrade();
          this.$parent.getUser();
        }
      }
      //钱不够
      if (!memberOrder.data.balance) {
        this.$confirm(memberOrder.msg, "提示", {
          confirmButtonText: "充值",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.upgradeDialog = false;
            this.ClickRecharge(); //打开充值弹框
          })
          .catch(() => {});
      }
    },

    // 充值模块函数
    // 点击充值
    ClickRecharge() {
      console.log("充值");
      this.$http
        .post("/index.php/api/v1_0_0.rechargepay/get_recharge_list", {})
        .then((data) => {
          console.log(data);
          this.rechargeList = data.data;
          this.rechargeId = data.data[0].recharge_id;
          this.rechargeMoney = data.data[0].money;
          this.rechargeDialog = true;
        })
        .catch((error) => {});
    },

    // 点击切换充值金额
    ClickRechargeList(item) {
      console.log("充值");
      console.log(item);
      // 充值金额
      this.rechargeMoney = item.money;
      // 充值金额ID
      this.rechargeId = item.recharge_id;
      console.log("选择充值金额", item.recharge_id);
    },
    // 点击积分兑换
    async ClickConversion() {
      let prompt = await this.$prompt(this.explain, "积分兑换", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPlaceholder: "请输入要兑换的数量",
        inputType: "number",
        inputValidator: function (v) {
          if (!v || v < 1) {
            return "请输入要兑换的数量";
          }
        },
      }).catch(() => {});
      console.log(prompt, "prompt");
      if (typeof prompt == "undefined") return;
      //查询余额够不够
      let score = await this.$http
        .post("/index.php/api/v1_0_0.consumer/exchange_score", {
          score: prompt.value,
        })
        .catch((error) => {});
      console.log(score, "score.balance");

      if (score.data.balance) {
        //余额够
        let balance = await this.$prompt("请输入支付密码", score.msg, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPlaceholder: "请输入支付密码",
          inputType: "password",
          inputValidator: function (v) {
            if (!v) return "请输入支付密码";
          },
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消支付",
          });
        });
        if (typeof balance == "undefined") return;
        let pay = await this.$http
          .post("/index.php/api/v1_0_0.consumer/score_do_pay", {
            order_num: score.data.order_num,
            password: balance.value,
          })
          .catch((error) => {});
        console.log(pay);

        if (pay.data.need_password) {
          //没有设置支付密码
          this.$confirm(pay.msg, "提示", {
            confirmButtonText: "设置",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.upgradeDialog = false;
              this.ClickFinancialListList("支付密码设置", 0);
            })
            .catch(() => {});
        } else {
          this.$message.success(pay.msg);
          this.ClickFinancialListList("我的余额", 1);
        }
      }
      //余额不够
      if (!score.data.balance) {
        this.$confirm(score.msg, "提示", {
          confirmButtonText: "充值",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.ClickRecharge(); //打开充值弹框
          })
          .catch(() => {});
      }
    },
    // 点击去充值
    ClickGoRecharge() {
      console.log();
      console.log("去充值", this.rechargeId);
      this.rechargeDialog = false;
      this.payModeDialog = true;
      this.$http
        .post("/index.php/api/v1_0_0.rechargepay/get_pay_type", {})
        .then(({ data }) => {
          console.log("支付方式");
          this.payModeList = data.list;
          this.payI = data.list[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 点击切换支付方式
    ClickPayMode(id) {
      this.payI = id;
      console.log("支付方式", id);
    },
    // 点击支付
    async ClickPayment() {
      console.log("支付", this.payI, this.rechargeId);
      //支付宝支付
      if (this.payI == 2) {
        // this.payI = 2;
        let order = await this.$http
          .post("/index.php/api/v1_0_0.Rechargepay/recharge_code", {
            type: this.payI,
            recharge_id: this.rechargeId,
          })
          .catch((error) => {});

        console.log(order, "order");
        this.loading = true;
        window.open(decodeURIComponent(order.data.target_url)); //跳转支付宝收银台
        this.timer = setInterval(() => {
          this.$http
            .post("/index.php/api/v1_0_0.Rechargepay/check_alipay", {
              order_num: order.data.order_num,
            })
            .then((data) => {
              console.log("支付宝成功");
              console.log(data.data.status);
              if (data.data.status == 2) {
                this.$message.success("充值成功");

                // this.codeDialog = false;
                this.payModeDialog = false;
                this.loading = false;
                this.getBalance();
                clearInterval(this.timer);
                return;
              }
            })
            .catch((error) => {
              console.log("支付宝失败");
              console.log(error);
            });
        }, 1000);
        return;
      }

      this.payModeDialog = false;
      this.codeDialog = true;
      //微信支付
      let order = await this.$http
        .post("/index.php/api/v1_0_0.Rechargepay/recharge_code", {
          type: this.payI,
          recharge_id: this.rechargeId,
        })
        .catch((error) => {});
      console.log(order, "order");
      this.weixin_url = order.data.weixin_url;
      this.timer = setInterval(() => {
        this.$http
          .post("/index.php/api/v1_0_0.Rechargepay/check_alipay", {
            order_num: order.data.order_num,
          })
          .then((data) => {
            console.log(data.data.status);
            if (data.data.status == 2) {
              // this.$message.success("充值成功");
              this.codeDialog = false;
              this.getBalance();

              clearInterval(this.timer);
              this.codeDialog = false;
              return;
            }
          })
          .catch((error) => {});
      }, 1000);
    },

    // 提现模块
    // 点击提现
    ClickWithdraw() {
      console.log(11);
      this.withdrDialog = true;
    },
    // 点击提现方式
    ClickWithdrList(i) {
      this.withdrI = i;
    },

    // 点击开票
    ClickInvoice() {
      const is_business = this.$store.state.user.userInfo.is_business;
      const is_business_time = this.$store.state.user.userInfo.is_business_time;

      if (is_business == 1 && !is_business_time) {
        return this.$alert("企业认证已过期，请联系工作人员线下续费!", "提示", {
          confirmButtonText: "关闭",
          callback: () => {},
        });
      }
      this.invoiceDialog = true;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_business_info", {})
        .then(({ data }) => {
          this.ticketForm.compay = data.name;
          this.ticketForm.number = data.taxpayer_number;
          this.ticketForm.gsPhone = data.business_phone;
          this.ticketForm.zcAddr = data.register_address;
          this.ticketForm.email = data.email;
          this.ticketForm.yjPhone = data.lx_phone;
          this.ticketForm.name = data.lx_name;
        })
        .catch((error) => {
          console.log(error);
        });
      console.log("开票");
    },
    // 提交点击开票信息
    invoicetSubmit() {
      // 表单验证
      this.$refs.ticketForm.validate(async (valid) => {
        console.log(valid);
        if (valid) {
          this.$http
            .post("/index.php/api/v1_0_0.consumer/open_invoice", {
              invoice_data: {
                invoice: this.ticketForm.money, //开票金额 int
                // business_name: this.ticketForm.compay, //企业名称
                // taxpayer_number: this.ticketForm.number, //纳税人识别号
                // register_address: this.ticketForm.zcAddr, //注册地址
                // business_phone: this.ticketForm.gsPhone, //公司电话
                mail_address: this.ticketForm.yjAddr, //邮寄地址
                // mail_phone: this.ticketForm.yjPhone, //邮寄电话
                // email: this.ticketForm.email, //电子邮箱
              },
            })
            .then((data) => {
              console.log(data, "开票");
              // this.$message.success("提交成功");
              this.invoiceDialog = false;
              this.invoiceSubmitDialog = true;
              this.getInvoice();
              this.ticketForm = {
                compay: "",
                number: "",
                zcAddr: "",
                gsPhone: "",
                yjAddr: "",
                yjPhone: "",
                money: "",
              };
              this.getinvoiceLog();
            })
            .catch((error) => {});
        }
      });
    },

    // 点击申请开票记录
    ClickInvoiceRecord() {
      const is_business = this.$store.state.user.userInfo.is_business;
      const is_business_time = this.$store.state.user.userInfo.is_business_time;

      if (is_business == 1 && !is_business_time) {
        return this.$alert("企业认证已过期，请联系工作人员线下续费!", "提示", {
          confirmButtonText: "关闭",
          callback: () => {},
        });
      }
      this.$http
        .post("/index.php/api/v1_0_0.consumer/invoice_log", {
          type: 1,
          page: this.queryInfo.pagenum1,
          page_size: 5,
        })
        .then(({ data }) => {
          this.invoiceRecordDialog = true;

          this.total1 = data.total_count;
          if (!this.show_pc) {
            data.total_count == this.recordList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.recordList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }
          this.recordList = data.list;
          console.log(this.total1, "123开票记录");
        })
        .catch((error) => {});
    },

    // 点击发送短信 倒计时 函数
    getCode() {
      this.editPasword.password = "";
      this.$http
        .post("/index.php/api/v1_0_0.sendmsg/do_sendmsg", {
          phone: this.editPasword.phone,
          time_stamp: new Date().getTime(),
          sign: this.$md5(
            this.$md5(this.editPasword.phone + "xinshui" + new Date().getTime())
          ),
        })
        .then((data) => {
          this.$message.success("验证码已发送");
          const TIME_COUNT = 60;
          if (!this.code.timer) {
            this.code.count = TIME_COUNT;
            this.code.show = false;
            this.code.timer = setInterval(() => {
              if (this.code.count > 0 && this.code.count <= TIME_COUNT) {
                this.code.count--;
              } else {
                this.code.show = true;
                clearInterval(this.code.timer);
                this.code.timer = null;
              }
            }, 1000);
          }
        })
        .catch((error) => {});
    },
    // 点击修改密码
    ClickEditPassword() {
      if (!this.is_code) {
        this.is_code = true;
        this.payText = "保存密码";
        return false;
      }

      this.$http
        .post("/index.php/api/v1_0_0.consumer/set_password", {
          yzm: this.editPasword.code,
          password: this.editPasword.password,
        })
        .then((data) => {
          this.$message.success(data.msg);
          this.editPasword.code = "";
          this.editPasword.password = "123456";
          this.code.show = true;
          clearInterval(this.code.timer);
          this.payText = "修改密码";
          this.is_code = false;
        })
        .catch((error) => {});
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      console.log(newSize);
      // this.getGoodsList();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      if (this.item == "我的积分") {
        this.getIntegral();
        return;
      }
      if (this.item == "我的余额") {
        this.getBalance();
        return;
      }
      if (this.item == "发票索取") {
        this.getInvoice();
        this.getinvoiceLog();
        return;
      }
    },
    handleCurrentChange1(newNum) {
      this.queryInfo.pagenum1 = newNum;
      this.ClickInvoiceRecord();
    },
    handleSizeChange1(newSize) {},
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f5f5f5");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  watch: {
    //监听支付弹窗关闭
    codeDialog(a) {
      if (!a) {
        clearInterval(this.timer);
      }
    },
    payModeDialog(a) {
      if (!a) {
        this.loading = false;
        clearInterval(this.timer);
      }
    },
    // getInfoId() {
    //   this.$router.push("/personal/financial");
    //   this.upgradeDialog = true;
    //   this.ClickUpgrade();
    // },
  },
};
</script>
<style lang='less' scoped>
#mobile {
  .ipt {
    width: 250px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #e5e6ea;
    /* margin-left: 24px; */
    padding-left: 10px;
    outline: none;
  }

  .top {
    ul {
      background-color: #fff;
      display: flex;
      // justify-content: space-around;
      justify-content: space-between;
      /* justify-content: start; */
      padding: 15px;

      .color {
        color: #1677ff;
      }

      li {
        // margin-right: 20px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 21px;
        cursor: pointer;
      }
    }
  }

  .center {
    margin: 20px 10px;
    /* margin: 10px 14px 40px 20px; */
    height: 142px;
    background: linear-gradient(270deg, #1677ff 0%, #31afff 100%);
    box-shadow: 0px 23px 22px -17px rgba(0, 97, 255, 0.41);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    box-sizing: border-box;

    p:nth-child(1) {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 25px;
      padding-left: 4px;
    }

    p:nth-child(2) {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 42px;
    }

    p:nth-child(3) {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      padding-left: 2px;
      margin-top: 7px;

      span:nth-child(1) {
        margin-right: 5px;
        letter-spacing: 1px;
      }
    }

    .btns {
      display: flex;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 49px;
        height: 24px;
        line-height: 24px;
        background: #ee8525;
        border-radius: 12px;
        text-align: center;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fdfeff;
        margin: 0 8px 0 0;
        cursor: pointer;
      }
    }
  }

  .invoice {
    margin: 20px 10px;
    height: 160px;
    background: linear-gradient(270deg, #1677ff 0%, #31afff 100%);
    box-shadow: 0px 23px 22px -17px rgba(0, 97, 255, 0.41);
    border-radius: 10px;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    text-align: left;
    box-sizing: border-box;

    .explain {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      padding-left: 2px;
      margin: 0px 0 0 31px;

      span:nth-child(1) {
        margin-right: 5px;
        letter-spacing: 1px;
      }
    }

    div {
      p:nth-child(1) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
        padding-left: 4px;
      }

      p:nth-child(2) {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 42px;
      }

      // 可开发票额度
      .open {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14;
        p:nth-child(3) {
          width: 44px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          background: linear-gradient(270deg, #f58224 0%, #fbb84a 100%);
          border-radius: 12px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin: 5px 0 16px 0;
          cursor: pointer;
        }
      }

      // 已开发票额度
      .opened {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p:nth-child(3) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffc677;
          cursor: pointer;
        }
      }

      // 已过期票额度
      .overdue {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .bottom {
    .jfmx {
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      margin-left: 20px;
      justify-content: flex-start;
      /* width: 80px; */

      p {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #292931;
        line-height: 25px;
        text-align: left;
        margin-bottom: 4px;
      }

      span {
        display: inline-block;
        width: 72px;
        height: 3px;
        background: #1677ff;
      }
    }

    ul {
      margin-top: 15px;
      padding: 0 20px;
      background-color: #fff;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0 12px 0;
        .left {
          p {
            text-align: left;
          }

          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #292931;
            line-height: 20px;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
            margin-top: 5px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1f1f24;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
          }
        }
      }
    }
  }

  .payment {
    margin-top: 10px;
    text-align: right;
    .phone,
    .code,
    .password,
    .editPsw {
      display: flex;
      /* justify-self: start; */
      align-items: center;
      /* padding-left: 30px; */
      margin: 16px 0px 0 0;

      span {
        margin-right: 10px;
        display: inline-block;
        width: 56px;
        height: 20px;
        font-size: 14px;
        /* text-align: right; */
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #89909d;
        line-height: 20px;
      }

      input {
        width: 250px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #e5e6ea;
        /* margin-left: 24px; */
        padding-left: 10px;
        outline: none;
      }
    }

    .code {
      button {
        width: 90px;
        height: 30px;
        background: #f1f5f6;
        border: 0;

        outline: none;
        margin-left: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          display: inline-block;
          width: 100%;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1677ff;
        }
      }
    }

    .editPsw {
      margin: 40px 0 97px 0;

      p {
        width: 104px;
        height: 34px;
        line-height: 34px;
        background: #1677ff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 24px;
        cursor: pointer;
      }
    }
  }

  /deep/ .el-dialog__wrapper {
    background: rgba(10, 10, 10, 0.1);

    .el-dialog {
      border-radius: 12px;
    }

    .el-dialog__header {
      padding: 14px 0;

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 20px;
      }
    }

    .el-dialog__body {
      display: flex;
      justify-content: center;
      padding: 0;
    }

    // .el-icon {
    //   display: none;
    // }
  }

  // 会员升级

  .upgrade_Box {
    margin: 0 auto;
    padding: 0 30px 52px 30px;

    .upgrade {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 22px 19px 0;

      .icon {
        width: 18px;
        height: 18px;
        margin: 0 -2px 0 -1px;
        position: relative;

        span {
          position: absolute;
          right: 1px;
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 18px;
          background: #1677ff;
          text-align: center;

          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            border: 0;
            outline: none;
          }

          i {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background: #ffffff;
          }
        }
      }

      .speed_bg {
        background: #1677ff;
      }

      p {
        //   width: 169px;
        width: 40%;
        height: 6px;
        background: #eff1fd;
        margin-left: 1px;
      }
    }

    .text {
      display: flex;
      justify-content: space-between;
      margin: 13px 19px 0;
    }

    .img {
      width: 104px;
      height: 100px;
      margin: 30px auto 25px;

      img {
        width: 100px;
        height: 100px;
      }
    }

    .equity {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #292931;
      line-height: 17px;
      text-stroke: 1px #979797;
      margin: 0 19px 40px;
    }

    .year {
      ul {
        display: flex;
        margin-bottom: 40px;

        .year_bg {
          background: #1677ff;
          color: #ffffff;
        }

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 78px;
          height: 44px;
          background: #fff;
          border-radius: 5px;
          border: 1px solid #d3d7de;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #292931;
          margin: 0 8px;
          cursor: pointer;
        }
      }

      .money {
        width: 108px;
        height: 34px;
        background: #1677ff;
        margin: 0 auto;

        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 34px;
        cursor: pointer;
      }
    }
  }

  // 去充值金额
  .recharge {
    padding-bottom: 23px;
    ul {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      margin-bottom: 20px;
      /* padding: 22px 30px 0 27px; */
      text-align: center;
      .year_bg {
        background: #1677ff;
        color: #ffffff;
      }

      li {
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 34px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #d3d7de;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #292931;
        margin: 8px;
        cursor: pointer;
      }
    }

    .goRecharge {
      width: 88px;
      height: 34px;
      background: #1677ff;
      margin: 0 auto;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 34px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }

  // 选择支付方式
  .paymode {
    padding-bottom: 30px;

    ul {
      display: flex;
      padding: 46px 0 58px 0;

      .paybd {
        border: 2px solid #1677ff;
      }

      li {
        width: 110px;
        height: 110px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #ffffff;
        border: 2px solid #d3d7de;
        border-radius: 5px;
        margin: 0 20px;
        cursor: pointer;

        img {
          display: inline-block;
          height: 44px;
          width: 44px;
        }

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9c9cab;
          margin-top: 10px;
        }
      }
    }

    .zf {
      width: 88px;
      height: 34px;
      line-height: 34px;
      background: #1677ff;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  // 扫码支付
  .scanCode {
    padding-bottom: 7px;

    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9ca0a6;
      line-height: 20px;
      margin: 8px 0;

      span {
        color: #ddb17d;
      }
    }

    .code {
      width: 150px;
      height: 150px;
      background: #ffffff;
      border-radius: 5px;
      border: 2px solid #4ab01b;
      padding: 15px 17px;
      box-sizing: border-box;
      margin: 8px auto;

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .text {
      margin: 22px 0;

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: inline-block;
          width: 28px;
          height: 28px;
        }

        span {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #323232;
          line-height: 25px;
          margin-left: 5px;
        }
      }
    }
  }

  //  提现
  .withdr {
    padding-bottom: 10px;

    ul {
      display: flex;
      margin-top: 8px;

      li:nth-child(1) {
        margin: 0 40px 0 34px;
      }

      li:nth-child(2) {
        margin: 0 34px 0 40px;
      }

      li {
        cursor: pointer;

        div {
          display: flex;
          align-items: center;

          .img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 5px;

            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9c9cab;
          }
        }

        p {
          width: 67px;
          height: 2px;
          background: #fff;
          border-radius: 4px;
          margin-top: 6px;
        }

        .withdr_bd {
          background: #1677ff;
        }
      }
    }

    .money,
    .account {
      // width: 268px;
      // height: 54px;
      border-bottom: 1px solid #dde0e5;
      display: flex;
      justify-content: center;
      padding: 9px 0;

      input {
        text-align: center;
        border: 0;
        outline: none;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }

    .money {
      margin: 29px 0 16px;

      input {
        width: 98px;
      }
    }

    .account {
      input {
        width: 112px;
      }
    }

    .confirm {
      margin: 49px auto 30px;
      display: flex;
      justify-content: center;
      div {
        width: 88px;
        height: 34px;
        background: #1677ff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 34px;
        margin: 0 10px;
        cursor: pointer;
      }
      div:nth-child(2) {
        background-color: #ccc;
      }
    }
  }

  // 提交开票信息的提示框
  .prompt {
    display: flex;
    flex-direction: column;

    .state {
      width: 80px;
      height: 80px;
      background: #52c41a;
      margin: 28px auto 24px;
      padding-top: 15px;
      box-sizing: border-box;
      border-radius: 50%;
      overflow: hidden;
    }

    p:nth-child(2) {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 32px;
    }

    p:nth-child(3) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 22px;
      margin: 8px 0;
    }

    .back {
      width: 88px;
      height: 34px;
      line-height: 34px;
      background: #1677ff;

      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin: 35px auto 50px;
      cursor: pointer;
    }
  }

  // 申请开票记录
  .record {
    width: 100%;

    ul {
      padding: 14px 0 37px 0;

      li {
        margin: 12px 0;
        display: flex;
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;

        .left {
          text-align: left;

          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #292931;
            line-height: 20px;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
            margin: 5px 0;
          }

          p:nth-child(3) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
          }
        }

        .right {
          text-align: right;

          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 20px;
            margin: 5px 0;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
            margin: 5px 0;
          }
        }
      }
    }
  }

  /deep/ .el-form-item__label:before {
    display: none;
    flex-direction: column;
  }

  /deep/ .el-form-item__error {
    padding-top: 0;
  }
}

.pc {
  .top {
    ul {
      display: flex;
      justify-content: start;
      padding: 20px 0 29px 5px;

      .color {
        color: #1677ff;
      }

      li {
        margin: 0 25px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 22px;
        cursor: pointer;
      }
    }
  }

  .center {
    margin: 10px 14px 40px 20px;
    height: 142px;
    background: linear-gradient(270deg, #1677ff 0%, #31afff 100%);
    box-shadow: 0px 23px 22px -17px rgba(0, 97, 255, 0.41);
    border-radius: 10px;
    padding: 30px 12px 20px 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    box-sizing: border-box;

    p:nth-child(1) {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 25px;
      padding-left: 4px;
    }

    p:nth-child(2) {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 42px;
    }

    p:nth-child(3) {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      padding-left: 2px;
      margin-top: 7px;

      span:nth-child(1) {
        margin-right: 5px;
        letter-spacing: 1px;
      }
    }

    .btns {
      display: flex;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 64px;
        height: 24px;
        line-height: 24px;
        background: #ee8525;
        border-radius: 12px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }

  .invoice {
    margin: 10px 14px 40px 20px;
    height: 160px;
    background: linear-gradient(270deg, #1677ff 0%, #31afff 100%);
    box-shadow: 0px 23px 22px -17px rgba(0, 97, 255, 0.41);
    border-radius: 10px;
    padding: 30px 12px 11px 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    box-sizing: border-box;

    .explain {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      padding-left: 2px;
      margin: 0px 0 0 31px;

      span:nth-child(1) {
        margin-right: 5px;
        letter-spacing: 1px;
      }
    }

    div {
      p:nth-child(1) {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
        padding-left: 4px;
      }

      p:nth-child(2) {
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 42px;
      }

      // 可开发票额度
      .open {
        margin: 0 52px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p:nth-child(3) {
          width: 44px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          background: linear-gradient(270deg, #f58224 0%, #fbb84a 100%);
          border-radius: 12px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin: 5px 0 16px 0;
          cursor: pointer;
        }
      }

      // 已开发票额度
      .opened {
        margin: 0 52px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p:nth-child(3) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffc677;
          cursor: pointer;
        }
      }

      // 已过期票额度
      .overdue {
        margin: 0 52px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .bottom {
    .jfmx {
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      margin-left: 20px;
      justify-content: flex-start;
      /* width: 80px; */

      p {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #292931;
        line-height: 25px;
        text-align: left;
        margin-bottom: 4px;
      }

      span {
        display: inline-block;
        width: 72px;
        height: 3px;
        background: #1677ff;
      }
    }

    ul {
      margin: 20px 20px 17px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0 12px 0;

        .left {
          p {
            text-align: left;
          }

          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #292931;
            line-height: 20px;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
            margin-top: 5px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1f1f24;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
          }
        }
      }
    }
  }

  .payment {
    margin-top: 10px;

    .phone,
    .code,
    .password,
    .editPsw {
      display: flex;
      justify-self: start;
      align-items: center;
      padding-left: 30px;
      margin: 16px 0;

      span {
        display: inline-block;
        width: 56px;
        height: 20px;
        font-size: 14px;
        text-align: right;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #89909d;
        line-height: 20px;
      }

      input {
        width: 250px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #e5e6ea;
        margin-left: 24px;
        padding-left: 10px;
        outline: none;
      }
    }

    .code {
      button {
        width: 90px;
        height: 30px;
        background: #f1f5f6;
        border: 0;

        outline: none;
        margin-left: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          display: inline-block;
          width: 100%;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1677ff;
        }
      }
    }

    .editPsw {
      margin: 40px 0 97px 0;

      p {
        width: 104px;
        height: 34px;
        line-height: 34px;
        background: #1677ff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 24px;
        cursor: pointer;
      }
    }
  }

  /deep/ .el-dialog__wrapper {
    background: rgba(10, 10, 10, 0.1);

    .el-dialog {
      border-radius: 12px;
    }

    .el-dialog__header {
      padding: 14px 0;

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 20px;
      }
    }

    .el-dialog__body {
      display: flex;
      justify-content: center;
      padding: 0;
    }

    // .el-icon {
    //   display: none;
    // }
  }

  // 会员升级
  .upgrade_Box {
    margin: 0 auto;
    padding: 0 30px 52px 30px;

    .upgrade {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 22px 19px 0;

      .icon {
        width: 18px;
        height: 18px;
        margin: 0 -2px 0 -1px;
        position: relative;

        span {
          position: absolute;
          right: 1px;
          display: inline-block;
          width: 100%;
          height: 100%;
          border-radius: 18px;
          background: #1677ff;
          text-align: center;

          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            border: 0;
            outline: none;
          }

          i {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background: #ffffff;
          }
        }
      }

      .speed_bg {
        background: #1677ff;
      }

      p {
        //   width: 169px;
        width: 40%;
        height: 6px;
        background: #eff1fd;
        margin-left: 1px;
      }
    }

    .text {
      display: flex;
      justify-content: space-between;
      margin: 13px 19px 0;
    }

    .img {
      width: 104px;
      height: 100px;
      margin: 30px auto 25px;

      img {
        width: 100px;
        height: 100px;
      }
    }

    .equity {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #292931;
      line-height: 17px;
      text-stroke: 1px #979797;
      margin: 0 19px 40px;
    }

    .year {
      ul {
        display: flex;
        margin-bottom: 40px;

        .year_bg {
          background: #1677ff;
          color: #ffffff;
        }

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 78px;
          height: 44px;
          background: #fff;
          border-radius: 5px;
          border: 1px solid #d3d7de;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #292931;
          margin: 0 8px;
          cursor: pointer;
        }
      }

      .money {
        width: 108px;
        height: 34px;
        background: #1677ff;
        margin: 0 auto;

        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 34px;
        cursor: pointer;
      }
    }
  }

  // 去充值金额
  .recharge {
    ul {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      margin-bottom: 30px;
      padding: 22px 30px 10px 27px;

      .year_bg {
        background: #1677ff;
        color: #ffffff;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98px;
        height: 56px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #d3d7de;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #292931;
        margin: 10px;
        cursor: pointer;
      }
    }

    .goRecharge {
      width: 88px;
      height: 34px;
      background: #1677ff;
      margin: 0 auto;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 34px;
      margin-bottom: 30px;
      cursor: pointer;
    }
  }

  // 选择支付方式
  .paymode {
    padding-bottom: 30px;

    ul {
      display: flex;
      padding: 46px 0 58px 0;

      .paybd {
        border: 2px solid #1677ff;
      }

      li {
        width: 110px;
        height: 110px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #ffffff;
        border: 2px solid #d3d7de;
        border-radius: 5px;
        margin: 0 20px;
        cursor: pointer;

        img {
          display: inline-block;
          height: 44px;
          width: 44px;
        }

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9c9cab;
          margin-top: 10px;
        }
      }
    }

    .zf {
      width: 88px;
      height: 34px;
      line-height: 34px;
      background: #1677ff;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  // 扫码支付
  .scanCode {
    padding-bottom: 7px;

    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9ca0a6;
      line-height: 20px;
      margin: 8px 0;

      span {
        color: #ddb17d;
      }
    }

    .code {
      width: 150px;
      height: 150px;
      background: #ffffff;
      border-radius: 5px;
      border: 2px solid #4ab01b;
      padding: 15px 17px;
      box-sizing: border-box;
      margin: 8px 0;

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .text {
      margin: 22px 0;

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: inline-block;
          width: 28px;
          height: 28px;
        }

        span {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #323232;
          line-height: 25px;
          margin-left: 5px;
        }
      }
    }
  }

  //  提现
  .withdr {
    ul {
      display: flex;
      margin-top: 8px;

      li:nth-child(1) {
        margin: 0 40px 0 34px;
      }

      li:nth-child(2) {
        margin: 0 34px 0 40px;
      }

      li {
        cursor: pointer;

        div {
          display: flex;
          align-items: center;

          .img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 5px;

            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9c9cab;
          }
        }

        p {
          width: 67px;
          height: 2px;
          background: #fff;
          border-radius: 4px;
          margin-top: 6px;
        }

        .withdr_bd {
          background: #1677ff;
        }
      }
    }

    .money,
    .account {
      // width: 268px;
      // height: 54px;
      border-bottom: 1px solid #dde0e5;
      display: flex;
      justify-content: center;
      padding: 9px 0;

      input {
        text-align: center;
        border: 0;
        outline: none;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }

    .money {
      margin: 29px 0 16px;

      input {
        width: 98px;
      }
    }

    .account {
      input {
        width: 112px;
      }
    }

    .confirm {
      margin: 49px auto 30px;
      display: flex;
      justify-content: center;

      div {
        width: 88px;
        height: 34px;
        background: #1677ff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 34px;
        margin: 0 10px;
        cursor: pointer;
      }
      div:nth-child(2) {
        background-color: #ccc;
      }
    }
  }

  // 提交开票信息的提示框
  .prompt {
    display: flex;
    flex-direction: column;

    .state {
      width: 80px;
      height: 80px;
      background: #52c41a;
      margin: 28px auto 24px;
      padding-top: 15px;
      box-sizing: border-box;
      border-radius: 50%;
      overflow: hidden;
    }

    p:nth-child(2) {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 32px;
    }

    p:nth-child(3) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 22px;
      margin: 8px 0;
    }

    .back {
      width: 88px;
      height: 34px;
      line-height: 34px;
      background: #1677ff;

      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin: 35px auto 50px;
      cursor: pointer;
    }
  }

  // 申请开票记录
  .record {
    width: 100%;

    ul {
      padding: 14px 0 37px 0;

      li {
        margin: 12px 0;
        display: flex;
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;

        .left {
          text-align: left;

          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #292931;
            line-height: 20px;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
            margin: 5px 0;
          }

          p:nth-child(3) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
          }
        }

        .right {
          text-align: right;

          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 20px;
            margin: 5px 0;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898b9c;
            line-height: 17px;
            margin: 5px 0;
          }
        }
      }
    }
  }

  // 隐藏 *
  /deep/ .el-form-item__label:before {
    display: none;
    flex-direction: column;
  }

  /deep/ .el-form-item__error {
    padding-top: 0;
  }
}
</style>
