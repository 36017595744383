<template>
  <div>
    <Header></Header>

    <!-- PC端 -->
    <div v-if="show_pc">
      <div
        class="login"
        :style="{
          backgroundImage: 'url(' + loginURl + ')',
        }"
      >
        <div class="login_form">
          <p>账号密码登录</p>
          <!-- 手机号码 -->
          <div class="phone">
            <i class="el-icon-mobile-phone" size="16px"></i
            ><input
              v-model="phoneInput"
              type="number"
              placeholder="请输入手机号"
              maxlength="11"
            />
          </div>
          <!-- 输入密码 -->
          <div class="password">
            <i class="el-icon-lock" size="16px"></i
            ><input
              v-model="passwordInput"
              type="password"
              placeholder="请输入密码"
            />
          </div>
          <!-- 输入验证码 -->
          <div class="code">
            <i class="el-icon-lock" size="16px"></i
            ><input
              v-model="imgCodeInput"
              type="number"
              placeholder="请输入图形验证码"
            />
            <!-- 验证码图片 -->
            <div
              class="img"
              @click="ClickImgCode"
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(225, 225, 225, 0.3)"
            >
              <img v-if="!loading" :src="imgCode" alt="" />
            </div>
          </div>

          <div class="forget_register">
            <span @click="ClickForgetPassword">忘记密码?</span>
            <span @click="ClickRegister">立即注册</span>
          </div>
          <div class="login_btn" @keyup.13.$listeners="submit" @click="login">
            登&nbsp录
          </div>
          <div class="tripar">
            <div>
              <span></span>
              <p>第三方账号登录</p>
              <span></span>
            </div>
            <div @click="WXlogin" v-loading.fullscreen.lock="fullscreenLoading">
              <img src="../../assets/image/微信登录2@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="login_mb">
        <div
          class="login"
          :style="{
            backgroundImage: 'url(' + loginURl + ')',
          }"
        >
          <div class="login_form">
            <p>账号密码登录</p>
            <div class="phone">
              <i class="el-icon-mobile-phone" size="16px"></i
              ><input
                v-model="phoneInput"
                type="number"
                placeholder="请输入手机号"
                maxlength="11"
              />
            </div>
            <div class="password">
              <i class="el-icon-lock" size="16px"></i
              ><input
                v-model="passwordInput"
                type="password"
                placeholder="请输入密码"
              />
            </div>
            <!-- 输入验证码 -->
            <div class="code">
              <i class="el-icon-lock" size="16px"></i
              ><input
                v-model="imgCodeInput"
                type="number"
                placeholder="请输入图形验证码"
              />
              <!-- 验证码图片 -->
              <div
                class="img"
                @click="ClickImgCode"
                v-loading="loading"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(225, 225, 225, 0.3)"
              >
                <img v-if="!loading" :src="imgCode" alt="" />
              </div>
            </div>
            <div class="forget_register">
              <span @click="ClickForgetPassword">忘记密码?</span>
              <span @click="ClickRegister">立即注册</span>
            </div>
            <div class="login_btn" @click="login">登&nbsp录</div>
            <div class="tripar">
              <div>
                <span></span>
                <p>第三方账号登录</p>
                <span></span>
              </div>
              <div
                @click="WXlogin"
                v-loading.fullscreen.lock="fullscreenLoading"
              >
                <img src="../../assets/image/微信登录2@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
    <!-- 绑定的提示 -->
    <el-dialog
      title="绑定手机号"
      :visible.sync="dialogFormVisible"
      :show-close="false"
      width="30%"
    >
      <div class="phone">
        <i class="el-icon-mobile-phone" size="16px"></i
        ><input
          v-model="form.phone"
          type="number"
          placeholder="请输入手机号"
          maxlength="11"
        />
      </div>
      <div class="code">
        <i class="el-icon-lock" size="16px"></i
        ><input v-model="form.yzm" type="number" placeholder="请输入验证码" />
        <button style="padding: 0 10px; width: 120px">
          <span v-show="code.show" @click="getCode">发送验证码</span>
          <span v-show="!code.show" class="count"
            >{{ code.count }}s后重新发送</span
          >
        </button>
      </div>
      <div class="password">
        <i class="el-icon-lock" size="16px"></i
        ><input
          v-model="form.password"
          type="password"
          placeholder="请设置密码"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 登录二维码-->
    <el-dialog
      title="微信扫码登录"
      :visible.sync="centerDialogVisible"
      width="20%"
      :show-close="false"
      :before-close="centerDialogClose"
      center
    >
      <div style="width: 100%; height: 100%; overflow: hidden">
        <img
          style="display: inline-block; width: 100%; height: 100%"
          :src="url"
          alt=""
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from "../header";
import Footer from "../footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // 图形验证码加载...
      loading: false,
      // 默认显示 PC端的css
      show_pc: true,

      fullscreenLoading: false,
      // 登录背景图片
      loginURl: "",
      // 电话号码输入框
      phoneInput: "",
      // 密码输入框
      passwordInput: "",
      // 图片验证码输入框
      imgCodeInput: "",
      // 验证码图片
      imgCode: "",
      // 验证码key
      key: "",
      // 定时器
      timer: null,
      form: {
        phone: "",
        yzm: "",
        password: "",
      },
      url: "",
      formLabelWidth: "120px",
      dialogFormVisible: false,
      centerDialogVisible: false,
      // 发送验证码,
      code: {
        show: true,
        count: "",
        timer: null,
      },
      sign_code: "",
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;

    // 获取图片地址
    this.getImgCode();

    if (!localStorage.getItem("userbg")) {
      this.$http
        .get("/index.php/api/v1_0_0.ordinary/get_web_info")
        .then((res) => {
          const userbg = res.data.back_url;
          localStorage.setItem("userbg", userbg);
          this.loginURl = res.data.back_url;
        })
        .catch((error) => {});
    }
    this.loginURl = localStorage.getItem("userbg");
    var _this = this;
    document.onkeydown = function (e) {
      let key = event.keyCode;
      if (key == 13 && _this.$route.path == "/login") {
        _this.submit();
      }
    };
  },
  methods: {
    submit() {
      this.login();
    },
    // 获取图片地址
    getImgCode() {
      this.loading = true;
      this.$http
        .post("index.php/api/v1_0_0.ordinary/get_verify")
        .then((res) => {
          console.log("图片");
          this.loading = false;

          this.imgCode = res.data.verify;
          this.key = res.data.key;
          console.log(res.data);
        })
        .catch(() => {});
    },
    // 刷新图形验证码
    ClickImgCode() {
      this.getImgCode();
    },
    // 关闭
    centerDialogClose(done) {
      clearInterval(this.timer);
      done();
    },
    // 发送验证码
    getCode() {
      if (this.form.phone.length < 11 || this.form.phone.length > 11) {
        this.$message.error("手机号格式不正确");
        return;
      }
      this.$http
        .post("/index.php/api/v1_0_0.sendmsg/do_sendmsg", {
          phone: this.form.phone,
          time_stamp: new Date().getTime(),
          sign: this.$md5(
            this.$md5(this.form.phone + "xinshui" + new Date().getTime())
          ),
        })
        .then(({ data }) => {
          const TIME_COUNT = 60;
          if (!this.code.timer) {
            this.code.count = TIME_COUNT;
            this.code.show = false;
            this.code.timer = setInterval(() => {
              if (this.code.count > 0 && this.code.count <= TIME_COUNT) {
                this.code.count--;
              } else {
                this.code.show = true;
                clearInterval(this.code.timer);
                this.code.timer = null;
              }
            }, 1000);
          }
          this.$message.success("验证码已发送");
        })
        .catch((error) => {});
    },

    // 绑定手机号
    submitForm() {
      let form = this.form;
      if (!form.phone) {
        this.$message.error("请输入手机号");
        return;
      }
      if (form.phone.length != 11) {
        this.$message.error("手机号格式不正确");
        return;
      }
      if (!form.yzm) {
        this.$message.error("请输入验证码");
        return;
      }

      if (!form.password) {
        this.$message.error("请设置密码");
        return;
      }

      this.$http({
        url: "/index.php/api/v1_0_0/login/wx_bind_phone",
        method: "post",
        data: {
          phone: form.phone,
          yzm: form.yzm,
          password: form.password,
          code: this.sign_code,
        },
      })
        .then((res) => {
          this.dialogFormVisible = false;

          localStorage.setItem("user_id", res.data.user_id);
          localStorage.setItem("user_token", res.data.user_token);
          this.$message({
            message: "登录成功",
            type: "success",
            duration: 1500,
          });

          console.log(this.$route.query.redirect);

          let hostName = this.$route.query.redirect; // 获取域名

          if (hostName) {
            // 判断如果域名是你项目域名，说明是从本网站内部跳转过来的，
            this.$router.push(hostName); // 登录成功后，返回上次进入的页面；
          } else {
            this.$router.push("/index");
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //点击登录
    login() {
      if (this.phoneInput == "") {
        this.$message.error("请输入手机号");
        return;
      }
      if (this.phoneInput.length < 11) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (this.passwordInput == "") {
        this.$message.error("请输入密码");
        return;
      }
      if (this.imgCodeInput == "") {
        this.$message.error("请输入图形验证码");
        return;
      }

      // 王凯新增的
      this.$http
        .post("index.php/api/v1_0_0.ordinary/check", {
          verify_code: this.imgCodeInput,
          key: this.key,
        })
        .then(() => {
          this.$http({
            url: "/index.php/api/v1_0_0.login/do_login",
            method: "post",
            data: {
              phone: this.phoneInput,
              password: this.passwordInput,
              verify_code: this.imgCodeInput,
              key: this.key,
            },
            notNeed: true,
          })
            .then((data) => {
              localStorage.setItem("user_id", data.data.user_id);
              localStorage.setItem("user_token", data.data.user_token);
              this.$message({
                message: "登录成功",
                type: "success",
                duration: 1500,
              });

              console.log(this.$route.query.redirect);

              let hostName = this.$route.query.redirect; // 获取域名

              if (hostName) {
                // 判断如果域名是你项目域名，说明是从本网站内部跳转过来的，
                this.$router.push(hostName); // 登录成功后，返回上次进入的页面；
              } else {
                this.$router.push("/index");
              }
            })
            .catch((res) => {
              console.log(res);
            });
        })
        .catch(() => {});
    },

    //第三方登录
    WXlogin() {
      this.fullscreenLoading = true;
      //获取微信二维码
      this.$http
        .post("/index.php/api/v1_0_0.login/wx_qrcode")
        .then(({ data }) => {
          console.log(data);
          this.sign_code = data.code;
          this.url = data.url;
          let that = this;
          that.fullscreenLoading = false;
          that.centerDialogVisible = true;

          clearInterval(this.timer);

          // 定时器
          this.timer = setInterval(function () {
            that
              .$http({
                url: "/index.php/api/v1_0_0/login/get_login_status",
                method: "post",
                data: {
                  code: data.code,
                },
              })
              .then((data) => {
                // 清除定时器
                clearInterval(that.timer);
                that.centerDialogVisible = false;
                // 200 之后决定是登录成功，还是去绑定
                console.log(data);
                if (data.data.is_first) {
                  that.dialogFormVisible = true;
                } else {
                  localStorage.setItem("user_id", data.data.user_id);
                  localStorage.setItem("user_token", data.data.user_token);

                  let hostName = that.$route.query.redirect; // 获取域名
                  if (hostName) {
                    // 判断如果域名是你项目域名，说明是从本网站内部跳转过来的，
                    that.$router.push(hostName); // 登录成功后，返回上次进入的页面；
                  } else {
                    that.$router.push("/index");
                    that.$message({
                      message: "登录成功",
                      type: "success",
                      duration: 1500,
                    });
                  }
                }
              })
              .catch((res) => {
                console.log(res);
              });
          }, 1000);
        })
        .catch((error) => {});
    },
    // 点击立即注册按钮 跳转注册
    ClickRegister() {
      this.$router.push("/register");
    },
    // 点击忘记密码 跳转忘记密码页面
    ClickForgetPassword() {
      this.$router.push("/forgetPassword");
    },
  },
};
</script>
<style lang="less" scoped>
.phone,
.code,
.password {
  background-color: #fff;
  display: flex;
  justify-content: start;
  border-bottom: 1px solid #f4f4f4;
  align-items: center;
  padding: 30px 0 14px;

  i {
    margin-right: 10px;
  }

  input {
    width: 78%;
    height: 30px;
    outline: none;
    border: 0;
    font-size: 14px;
    font-weight: 400;
    color: #7e7e7e;
    line-height: 20px;
  }

  button {
    width: 120px;
    height: 30px;
    background: #f1f5f6;
    font-size: 12px;
    font-weight: 400;
    color: #1677ff;
    line-height: 17px;
    border: 0;
    outline: none;
  }
}

.login {
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  background-size: cover;

  .login_form {
    width: 463px;
    height: 674px;
    box-sizing: border-box;

    background: #fff;
    margin: 75px auto;
    padding: 40px;
    p {
      text-align: left;
      font-family: PingFangSC-Medium, PingFang SC;
      font-size: 33px;
      font-weight: 500;
      color: #252f3f;
      line-height: 46px;
      margin: 30px 0 27px 0;
    }

    .phone,
    .password,
    .code {
      background-color: #fff;
      display: flex;
      justify-content: start;
      border-bottom: 1px solid #f4f4f4;
      align-items: center;
      padding: 25px 0 10px;

      i {
        margin-right: 10px;
      }

      input {
        // width: 100%;
        outline: none;
        border: 0;
        font-size: 14px;
        font-weight: 400;
        color: #7e7e7e;
        line-height: 20px;
      }
      .img {
        width: 80px;
        height: 30px;
        overflow: hidden;
        /deep/.el-loading-spinner {
          margin-top: -7px;
        }
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .forget_register {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;

      span {
        font-size: 12px;
        font-weight: 400;
        color: #2b3443;
        line-height: 17px;
        cursor: pointer;
      }
    }

    .login_btn {
      margin: 32px 15.2%;
      height: 42px;
      background: #1677ff;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
    }

    .tripar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      div:nth-child(1) {
        display: flex;
        align-items: center;
        margin: 20px 0;

        p {
          font-size: 14px;
          font-weight: 400;
          color: #89909d;
          line-height: 20px;
          margin: 0 20px;
        }

        span {
          display: inline-block;
          width: 95px;
          height: 2px;
          background: #f4f4f4;
        }
      }

      div:nth-child(2) {
        margin: 10px 0;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        overflow: hidden;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.login_mb {
  padding-top: 60px;
  .login {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-size: 100% 100%;
    padding: 0px 30px;
    box-sizing: border-box;

    .login_form {
      width: 100%;
      height: 500px;
      background: #fff;
      margin: 70px auto 80px;
      padding: 0 30px;
      p {
        text-align: left;
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 700;
        color: #252f3f;
        margin: 30px 0 40px 0;
      }
      .phone,
      .password {
        background-color: #fff;
        display: flex;
        justify-content: start;
        border-bottom: 1px solid #f4f4f4;
        align-items: center;
        padding: 0;
        height: 36px;
        margin-top: 20px;

        i {
          margin-right: 10px;
        }

        input {
          width: 100%;
          outline: none;
          border: 0;
          font-size: 14px;
          font-weight: 400;
          color: #7e7e7e;
          line-height: 20px;
        }
      }

      .forget_register {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;

        span {
          font-size: 12px;
          font-weight: 400;
          color: #2b3443;
          line-height: 17px;
          cursor: pointer;
        }
      }

      .login_btn {
        width: 233px;
        height: 30.86px;
        margin: 15px auto;
        background: #1677ff;
        line-height: 30.86px;
        text-align: center;
        cursor: pointer;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }

      .tripar {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;

        div:nth-child(1) {
          display: flex;
          align-items: center;
          margin: 15px 0;

          p {
            font-size: 14px;
            font-weight: 400;
            color: #89909d;
            line-height: 20px;
            margin: 0 20px;
          }

          span {
            display: inline-block;
            width: 40px;
            height: 2px;
            background: #f4f4f4;
          }
        }

        div:nth-child(2) {
          margin: 0;
          width: 38px;
          height: 38px;
          border-radius: 100%;
          overflow: hidden;

          img {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
// 去除input 数字类型 右侧的上下箭头
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
