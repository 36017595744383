  <template>
  <!-- 资讯 -->
  <div>
    <!-- 头部 -->
    <Header :indexi="1"></Header>
    <div v-if="show_pc">
      <div class="article" :class="{ marginbottom: !show }">
        <ul>
          <li
            v-for="(item, index) in article"
            v-if="index < 8"
            :key="index"
            :class="{ color: id == item.id }"
            @click="articleClick(item)"
          >
            <p>
              {{ item.title }}
            </p>
            <div></div>
          </li>
        </ul>
        <div
          v-if="article.length > 8"
          class="gdly"
          @click="CickMore(article.length)"
        >
          <img src="../assets/icon/下拉更多@2x.png" alt="" />
        </div>
      </div>
      <div class="moreclass" v-show="show">
        <ul>
          <li
            v-for="(item, index) in article"
            v-if="index >= 8"
            :key="index"
            :class="{ color: id == item.id }"
            @click="articleClick(item)"
          >
            {{ item.title }}
            <div></div>
          </li>
        </ul>
      </div>
      <!-- 资讯内容区域 -->
      <News></News>

      <!-- 底部区域 -->
    </div>

    <!-- 手机端 -->
    <div v-if="!show_pc">
      <div class="articlesClass_md" v-if="path == '/zx'">
        <div class="cont">
          <ul>
            <li
              v-for="(item, index) in article"
              :key="index"
              @click="articleClick(item)"
              :class="{ color: id == item.id }"
            >
              <p>
                {{ item.title }}
              </p>
              <div :class="{ bgcolor: id == item.id }"></div>
            </li>
          </ul>
        </div>
      </div>
      <Article></Article>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/header";
import Footer from "../components/footer";
import News from "../components/news";
import Article from "../components/article";

export default {
  components: {
    Header,
    News,
    Article,
    Footer,
  },
  metaInfo: {
    title: "新税网 - 资讯",
    meta: [
      {
        name: "description",
        content: "这是资讯页面",
      },
      {
        name: "keywords",
        content: "新税网-资讯",
      },
    ],
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 默认显示第一个 文章推荐
      id: 0,
      // 控制更多分类显示与隐藏
      show: false,

      // 查询文章列表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      // 总条数
      total: 10,
      // 文章分类
      article: [],
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.path = this.$route.path;

    //分类列表
    this.$http
      .get("/index.php/api/v1_0_0.article/get_cate_list", {
        notNeed: true,
      })
      .then((data) => {
        // this.article = data.data.list;
        var arry = [];

        data.data.list.forEach((item) => {
          if (item.id == 0) {
            item.title = "最新资讯";
          }
          arry.push(item);
        });
        this.article = arry;
      });
  },

  methods: {
    CickMore(length) {
      if (length <= 8) {
        this.$message.info("没有更多了").onClose(() => {});
      }
      if (length > 8) {
        this.show = !this.show;
      }
    },
    // 点击文章分类按钮
    articleClick(item) {
      console.log("点击文章分类筛选");
      console.log(item);
      this.id = item.id;
      this.$store.state.query.classId = item.id;
      this.$store.state.query.classText = item.title;
    },
  },
};
</script>

<style lang='less' scoped>
.marginbottom {
  margin-bottom: 30px;
}
.moreclass {
  ul {
    background: #f8f8f8;
  }
  .color {
    color: #1677ff;
  }
}
.article {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  // padding: 0 10.4%;

  padding: 0;
  margin: 0 auto;
  // width: 1200px;
  /*margin-top: 70px;*/
  ul {
    width: 1184px;
    // width: 100%;
    height: 100%;
    line-height: 100%;
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .color {
      color: #1677ff;
    }
    li {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #292931;
      margin-right: 40px;
      display: inline-block;
      font-weight: 600;
      // width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    li:nth-child(7) {
      padding-right: 5px;
    }
  }
  .border {
    border-left: 0 !important;
    text-align: center !important;
    font-weight: 700 !important;
  }
  .gdly {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    cursor: pointer;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
}
.moreclass {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  height: 54px;
  line-height: 54px;
  z-index: 2;
  ul {
    width: 1200px;
    margin: 0 auto;
    background-color: #f8f8f8;
    // width: 100%;
    height: 100%;
    box-shadow: 4px 13px 40px -14px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: start;
    align-items: center;

    li {
      display: inline-block;
      // margin-right: 72px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #292931;
      line-height: 22px;
      width: 120px;

      cursor: pointer;
    }
  }
}

/deep/.popper__arrow::after {
  margin: 0 !important;
  top: 10px !important;
  border-bottom-color: #f5f5f5 !important;
}

.articlesClass_md {
  width: 100%;
  margin-top: 60px;
  overflow: hidden;

  // 去掉滚动条
  .cont::-webkit-scrollbar {
    display: none;
  }
  .cont {
    height: 60px;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    ul {
      height: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      overflow: hidden;
      // margin-top: 13px;

      left: 0px;
      top: 0px;
      position: absolute;
      overflow-x: auto;
      white-space: nowrap;
      display: flex;
      li:nth-child(1) {
        margin-left: 9px;
      }
      .color {
        p {
          color: #1677ff;
        }
      }
      li {
        display: inline-block;
        letter-spacing: 1px;
        // width: 50px;
        margin-right: 22px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 17px;

        display: inline-block;
        float: left;
        font-size: 13px;
        text-decoration: none;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        div {
          width: 44px;
          height: 2px;
          text-align: center;
          margin: 9px auto 0;
        }
      }
      .bgcolor {
        background: #1677ff;
      }
    }
  }
}
</style>
