<template>
  <div>
    <Header></Header>

    <div style="width: 100%; height: 600px">
      <el-dialog
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="dialogVisible"
        width="30%"
        top="22vh"
        v-if="isLogin"
        :before-close="handleClose"
      >
        <div
          style="
            width: 82%;
            height: 100px;
            font-size: 18px;
            font-weight: 700;
            padding: 40px;
            text-align: center;
          "
        >
          <span style="word-wrap: break-wordw; width: 100%">{{ content }}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="refuse1">拒 绝</el-button>
          <el-button type="primary" @click="consent1">同 意</el-button>
        </span>
      </el-dialog>

      <el-dialog
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="dialogVisible"
        width="30%"
        top="22vh"
        v-if="!isLogin"
        :before-close="handleClose"
      >
        <div
          style="
            width: 82%;
            height: 100px;
            font-size: 18px;
            font-weight: 700;
            padding: 40px;
            text-align: center;
          "
        >
          <span style="word-wrap: break-wordw; width: 100%">{{ content }}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="refuse">注 册</el-button>
          <el-button type="primary" @click="consent">登 录</el-button>
        </span>
      </el-dialog>

      <el-dialog
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="accept"
        width="30%"
        top="22vh"
        :before-close="handleClose"
      >
        <div
          style="
            width: 82%;
            height: 100px;
            font-size: 18px;
            font-weight: 700;
            padding: 40px;
            text-align: center;
          "
        >
          <span style="word-wrap: break-wordw; width: 100%">{{
            content1
          }}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="refuse">注 册</el-button> -->
          <el-button type="primary" @click="refuse1">好 的</el-button>
        </span>
      </el-dialog>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/header";
import Footer from "../components/footer";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      dialogVisible: false,
      content: "请先登录/注册账号",
      content1: "",
      isLogin: false,
      accept: false,
    };
  },
  created() {
    this.$http
      .post("/index.php/api/v1_0_0.course/invite_course_pre", {
        course_code: this.$route.query.course_code,
      })
      .then((data) => {
        this.content = data.msg;
        this.dialogVisible = true;
        this.isLogin = true;
      })
      .catch((error) => {
        if (error.code == 400) {
          this.dialogVisible = true;
        }
      });
  },
  methods: {
    consent1() {
      this.$http
        .post("/index.php/api/v1_0_0.course/invite_course", {
          course_code: this.$route.query.course_code,
        })
        .then((data) => {
          console.log(data);
          this.content1 = data.msg;
          this.dialogVisible = false;
          this.accept = true;
        })
        .catch((error) => {});
    },
    refuse1() {
      this.$router.replace("/");
    },

    //确定
    consent() {
      if (!this.isLogin) {
        this.$router.replace("/login");
        return;
      }
    },
    //取消
    refuse() {
      if (!this.isLogin) {
        this.$router.replace("/register");
        return;
      }
      this.$router.replace("/");
    },
    handleClose() {},
  },
};
</script>

<style scoped>
</style>
