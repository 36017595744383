<template>
  <div>
    <Header></Header>
    <!-- 搜索模块 -->
    <div v-if="show_pc" class="pc">
      <div class="search">
        <el-input v-model="keyword" placeholder="请输入内容"></el-input>
        <button @click="ClickSearch">搜索</button>
      </div>
      <!-- 地址 行业 年薪模块 -->
      <div class="screen_box">
        <ul>
          <li v-for="(item, index) in screenList" :key="index">
            <div>{{ item.name }}</div>
            <div>
              <span
                :class="{
                  color:
                    item.fields == 'province'
                      ? province == item2.value
                        ? true
                        : false
                      : item.fields == 'cid'
                      ? cid == item2.value
                        ? true
                        : false
                      : false,
                }"
                v-for="(item2, index) in item.list"
                :key="index"
                @click="ClickFormatList(item.fields, item2.value)"
                >{{ item2.title }}</span
              >
            </div>
          </li>
        </ul>
      </div>

      <!-- 职位区域 -->
      <div class="position">
        <!-- 职位左侧区域 -->
        <div class="position_left">
          <p v-if="positionList.length <= 0">没有更多了...</p>

          <ul v-if="positionList.length > 0">
            <li
              v-for="(item, index) in positionList"
              :key="item.id"
              @click="ClickPosition(item.id)"
            >
              <div class="left">
                <div>
                  <span>{{ item.title }}</span
                  ><span>{{ item.create_time }}</span>
                </div>
                <div>
                  <span>{{ item.salary }}</span>
                  <span>{{ item.left_box.exp_desc }}</span>
                  <span>{{ item.left_box.edut_desc }}</span>
                </div>
              </div>
              <div class="right">
                <div>
                  <span>{{ item.business_name }}</span>
                </div>
                <div>
                  <span>{{ item.right_box.department }}</span>
                  <span>{{ item.right_box.is_market }}</span>
                  <span>{{ item.right_box.scale }}</span>
                </div>
                <div class="image"><img :src="item.logo" alt="" /></div>
              </div>
            </li>
          </ul>
          <!-- 分页 -->
          <!-- 底部分页 -->
          <div class="page">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="queryInfo.pagenum"
              :page-size="10"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>

        <!-- 职位右侧区域 推荐职位 -->
        <div class="position_right">
          <div class="top">
            <p>职位推荐</p>
            <span></span>
          </div>
          <div
            class="bottom"
            v-for="(item, index) in firstPositionLists"
            :key="item.id"
            @click="ClickPosition(item.id)"
          >
            <div>
              <p>{{ item.title }}</p>
              <p>{{ item.business_name }}</p>
            </div>
            <div>{{ item.salary }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="recruitSearch_mb">
        <!-- 搜索 -->
        <div class="search">
          <div class="top">
            <input v-model="keyword" placeholder="请输入内容" />
            <button @click="ClickSearch">搜索</button>
          </div>
        </div>
        <!-- 地址 行业 年薪模块 -->
        <div class="screen_box">
          <ul>
            <li v-for="(item, index) in screenList" :key="index">
              <div>{{ item.name }}</div>
              <div>
                <span
                  :class="{
                    color:
                      item.fields == 'province'
                        ? province == item2.value
                          ? true
                          : false
                        : item.fields == 'cid'
                        ? cid == item2.value
                          ? true
                          : false
                        : false,
                  }"
                  v-for="(item2, index) in item.list"
                  :key="index"
                  v-if="index <= cityMore"
                  @click="ClickFormatList(item.fields, item2.value)"
                  >{{ item2.title }}</span
                >
                <span v-if="item.fields == 'province' && item.list.length > 9"
                  ><span
                    style="color: #1677ff; border-left: 1px solid #d8d8d8"
                    @click="ClickCityMore"
                    v-if="cityMore > 9"
                    >收起</span
                  >
                  <span
                    style="color: #1677ff; border-left: 1px solid #d8d8d8"
                    @click="ClickCityMore"
                    v-if="cityMore == 8"
                    >更多</span
                  >
                </span>
              </div>
            </li>
          </ul>
        </div>

        <!-- 职位区域 -->
        <div class="position" style="display: flex; flex-direction: column">
          <!-- 职位左侧区域 -->
          <div class="position_left">
            <ul v-if="positionList.length > 0">
              <li
                v-for="(item, index) in positionList"
                :key="item.id"
                @click="ClickPosition(item.id)"
              >
                <div class="left">
                  <div>
                    <span>{{ item.title }}</span>
                    <span> {{ item.salary }}</span>
                  </div>
                  <div>
                    <span>
                      <div class="image"><img :src="item.logo" alt="" /></div
                    ></span>
                    <span>{{ item.business_name }}</span>

                    <span>{{ item.left_box.exp_desc }}</span>
                    <span>{{ item.left_box.edut_desc }}</span>
                    <span>{{ item.create_time }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="more"
            v-if="positionList.length !== total"
            @click="ClickMore"
          >
            加载更多
          </div>
          <div class="more" v-if="positionList.length == total">
            没有更多了...
          </div>
        </div>
        <!-- 加载更多 -->
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/header";
import Footer from "../../components/footer";
import { log } from "util";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 搜索输入框
      input: "",
      //   筛选列表
      screenList: [],
      // 搜索内容
      keyword: "",
      // 省份
      province: "",
      // 分类
      cid: "",
      //   职位列表
      positionList: [],
      // 查询文章列表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      // 总条数
      total: 0,

      //   推荐职位列表
      firstPositionLists: [],
      cityMore: 8,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 获取推荐职位列表
    this.getPositionLists();
    // 推荐职位
    this.getFirstPositionLists();
    // 筛选列表

    this.getClassList();
  },
  methods: {
    // 筛选列表
    getClassList() {
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_screen", {})
        .then((res) => {
          console.log("城市");
          this.screenList = res.data;
          console.log(res);
        })
        .catch((error) => {});
    },

    // 点击搜索
    ClickSearch() {
      if (!this.keyword) {
        this.$message.error("请输入内容");
        return false;
      }

      this.getPositionLists();
    },
    // 点击筛选条件
    ClickFormatList(fields, value) {
      if (fields == "province") {
        this.province = value;
      }
      if (fields == "cid") {
        this.cid = value;
      }
      this.getPositionLists();
      console.log(this.cid, this.province);
    },

    // 职位列表
    getPositionLists() {
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_recruit_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          is_recommend: 0,
          province: this.province,
          cid: this.cid,
          keyword: this.keyword,
        })
        .then((res) => {
          console.log(res);
          this.positionList = res.data.list;

          this.total = res.data.total_count;
        })
        .catch((error) => {});
    },
    // 推荐职位
    getFirstPositionLists() {
      this.$http
        .get("/index.php/api/v1_0_0.recruit/get_recruit_list", {
          page: 1,
          page_size: 10,
          is_recommend: 1,
          notNeed: true,
        })
        .then((res) => {
          this.firstPositionLists = res.data.list;
          console.log(res.data);
          this.total = res.data.total_count;
        })
        .catch((error) => {});
    },

    // 点击推荐职位跳转到职位详情
    ClickPosition(id) {
      this.$router.push({
        path: "/zp/recruitSearch/recruitDetails",
        query: { id: id },
      });
      console.log(id);
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getPositionLists();

      console.log(newSize);
      // this.getGoodsList();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.getPositionLists();
    },
    ClickCityMore() {
      if (this.cityMore == 8) {
        this.cityMore = this.screenList[0].list.length;
        return;
      }
      this.cityMore = 8;
    },
    ClickMore() {
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_recruit_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          is_recommend: 0,
          province: this.province,
          cid: this.cid,
          keyword: this.keyword,
        })
        .then((res) => {
          console.log(res);
          res.data.list.forEach((item) => {
            this.positionList.push(item);
          });
          this.total = res.data.total_count;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang='less' scoped>
img {
  object-fit: contain;
}
.pc {
  // 搜索
  .search {
    width: 930px;
    margin: 25px auto 20px;

    display: flex;
    justify-content: start;
    // margin: 25px 18.5% 20px 18.5%;
    .el-input {
      height: 50px;
      padding-left: 30px;
      background: #ffffff;
      box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
      border-radius: 8px 0px 0px 8px;
    }
    /deep/ .el-input__inner {
      height: 100%;
      border: 0;
      outline: none;
    }
    button {
      width: 140px;
      height: 50px;
      border: none;
      outline: none;
      color: #fff;
      background: #1677ff;
      border-radius: 0px 8px 8px 0px;
      cursor: pointer;
    }
  }
  // 筛选
  .screen_box {
    width: 1200px;
    margin: 0 auto;
    // margin: 0 10.45%;
    background-color: #fff;
    padding: 15px 0 10px;
    margin-bottom: 30px;
    box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
    border-radius: 8px;
    ul {
      li {
        display: flex;
        justify-self: start;
        div:nth-child(1) {
          width: 5%;
          line-height: 40px;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          // background-color: #fff;
        }
        div:nth-child(2) {
          width: 95%;
          height: auto;
          line-height: 40px;
          // background-color: #fff;
          display: flex;
          justify-self: start;
          flex-wrap: wrap;
          padding-right: 60px;
          padding-bottom: 10px;
          .color {
            color: #1677ff !important;
          }
          span {
            padding: 0 13px;
            font-size: 14px;
            font-weight: 400;
            color: #787e8f;
            cursor: pointer;
          }
        }
      }
    }
  }
  .position {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // margin: 0 10.45%;
    .position_left {
      width: 66%;
      height: 100%;

      ul {
        li {
          display: flex;
          justify-content: start;
          background-color: #fff;
          padding: 18px 20px 17px 30px;
          margin-bottom: 15px;
          cursor: pointer;
          .left {
            width: 55%;

            text-align: left;
            div:nth-child(1) {
              margin-bottom: 20px;
              span:nth-child(1) {
                font-size: 16px;
                font-weight: 400;
                color: #1677ff;
                line-height: 22px;
                margin-right: 20px;
              }
              span {
                font-size: 12px;
                font-weight: 400;
                color: #afb2ba;
                line-height: 17px;
              }
            }
            div:nth-child(2) {
              span {
                display: inline-block;
                height: 12px;
                line-height: 12px;

                font-size: 14px;
                font-weight: 400;
                color: #afb2ba;
                padding: 0 6px;
                border-right: 1px solid #afb2ba;
              }
              span:nth-child(1) {
                font-size: 16px;
                font-weight: 600;
                color: #ff733c;
                padding-left: 0px;
                border-right: 0;
              }
              span:last-child {
                border-right: 0;
              }
            }
          }
          .right {
            width: 45%;
            text-align: left;
            position: relative;
            div:nth-child(1) {
              margin-bottom: 20px;

              span:nth-child(1) {
                font-size: 16px;
                font-weight: 400;
                color: #1677ff;
                line-height: 22px;
              }
              span:nth-child(2) {
                font-size: 12px;
                font-weight: 400;
                color: #afb2ba;
                line-height: 17px;
              }
            }
            div:nth-child(2) {
              span {
                display: inline-block;
                height: 12px;
                line-height: 12px;
                font-size: 14px;
                font-weight: 400;
                color: #afb2ba;
                border-right: 1px solid #afb2ba;
                padding: 0 6px;
              }
              span:nth-child(1) {
                padding-left: 0px;
              }
              span:last-child {
                border-right: 0;
              }
            }
            .image {
              position: absolute;
              right: 0;
              top: 0;
              width: 54px;
              height: 54px;
              border-radius: 5px;
              overflow: hidden;
              img {
                display: inline-block;
                width: 100%;
                height: 100%;
                /*background-color: pink;*/
              }
            }
          }
        }
      }
      .page {
        text-align: center;
        padding: 25px 0 50px 0;
      }
    }
    .position_right {
      width: 33%;
      height: 100%;
      background-color: #fff;
      .top {
        text-align: left;
        display: flex;
        flex-direction: column;
        padding: 20px;
        p {
          font-weight: 600;
          color: #292931;
          line-height: 25px;
          padding-bottom: 5px;
        }
        span {
          display: inline-block;
          width: 60px;
          height: 3px;
          background: #1677ff;
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
        padding: 0px 25px 0px 20px;
        cursor: pointer;
        div:nth-child(1) {
          text-align: left;
          p:nth-child(1) {
            font-size: 16px;
            font-weight: 500;
            color: #424b60;
            padding: 4px 0;
          }
          p:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            color: #afb2ba;
            padding: 4px 0;
          }
        }
        div:nth-child(2) {
          font-size: 16px;
          font-weight: 600;
          color: #ff733c;
          line-height: 22px;
        }
      }
    }
  }
}
.recruitSearch_mb {
  background-color: #f5f5f5;
  padding-top: 60px;
  .search {
    height: 110px;
    background: linear-gradient(270deg, #414a5d 0%, #424b61 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    .top {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
      height: 40px;
      margin: auto;

      input {
        padding: 10px;
        width: 255px;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
        border-radius: 4px 0px 0px 4px;
        box-sizing: border-box;
        border: 0;
        outline: none;
      }
      button {
        width: 80px;
        height: 40px;
        background: #1677ff;
        border-radius: 0px 4px 4px 0px;
        color: #ffffff;
        border: 0;
        outline: none;
        cursor: pointer;
      }
    }
  }
  .screen_box {
    width: 100%;
    background-color: #fff;
    padding: 15px 10px 10px;
    box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    ul {
      li {
        display: flex;
        justify-self: start;
        div:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #424b60;
          // line-height: 40px;
          white-space: nowrap;
          padding-right: 10px;
        }
        div:nth-child(2) {
          height: auto;
          // line-height: 40px;
          display: flex;
          justify-self: start;
          flex-wrap: wrap;

          color: #787e8f;
          .color {
            color: #1677ff !important;
          }
          span {
            padding: 0 10px;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 20px;
            color: #787e8f;
            cursor: pointer;
          }
        }
      }
    }
  }
  .position {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .position_left {
      width: 100%;
      height: 100%;

      ul {
        li {
          display: flex;
          justify-content: start;
          background-color: #fff;
          padding: 15px 10px 10px;
          margin: 10px;
          border-radius: 5px;
          cursor: pointer;
          .left {
            width: 100%;
            text-align: left;
            div:nth-child(1) {
              width: 100%;
              display: flex;
              justify-content: space-between;
              span:nth-child(1) {
                font-size: 16px;
                font-weight: 400;
                color: #1677ff;
                line-height: 22px;
                margin-right: 20px;
              }
              span {
                font-size: 12px;
                font-weight: 400;
                color: #afb2ba;
                line-height: 17px;
              }
              span:nth-child(2) {
                font-size: 16px;
                font-weight: 600;
                color: #ff733c;
                padding-left: 0px;
                border-right: 0;
              }
            }
            div:nth-child(2) {
              display: flex;
              align-items: center;
              margin-top: 20px;
              span {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #afb2ba;
                display: inline-block;
                height: 12px;
                line-height: 12px;
              }

              span:nth-child(1) {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 6px;

                .image {
                  width: 100%;
                  height: 100%;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              span:nth-child(2) {
                display: inline-block;
                width: 60px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              span:nth-child(3) {
                display: inline-block;
                width: 50px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 6px 0 15px;
                border-right: 1px solid #afb2ba;
              }
              span:nth-child(4) {
                display: inline-block;
                width: 50px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 15px 0 6px;
              }

              span:last-child {
                border-right: 0;
              }
            }
          }
        }
      }
      .page {
        text-align: center;
        padding: 25px 0 50px 0;
      }
    }
  }
  .more {
    width: 128px;
    height: 36px;
    line-height: 36px;
    background: #ffffff;
    margin: 20px auto;
    border: 2px solid #e5e6ea;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #555c69;
  }
}
</style>
<style scoped>
.el-input__inner {
  height: 100% !important;
  outline: none !important;
  border: 0 !important;
}
</style>
