<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div v-if="show_pc">
      <div class="top">
        <div class="left">
          <ul>
            <li
              v-for="(item, index) in contentList"
              :key="index"
              :class="{ color: i == index }"
              @click="ClickContentList(item, index)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="right" v-show="i !== 5" @click="publish">
          {{ issueBTN }}
        </div>
      </div>

      <div class="bottom">
        <!-- 资讯 -->
        <ul class="zx" v-if="item == '资讯'">
          <li v-for="(item, index) in zxList" :key="index">
            <div class="img">
              <img :src="item.cover" alt="" />
              <span
                class="release_status"
                v-if="item.status == 2"
                style="background: #1677ff"
                >待审核</span
              >
              <span
                class="release_status"
                v-if="item.status == 3"
                style="background: #ff7c7c"
                >被驳回</span
              >
              <span
                class="release_status"
                v-if="item.status == 0"
                style="background: red"
                >已删除</span
              >
              <!-- {{item.status_name}} -->
            </div>
            <div class="content" @click="ClickInfo(item)">
              <p>
                {{ item.title }}
              </p>
              <p class="userContentText">
                {{ item.description }}
              </p>
            </div>
            <div class="edit">
              <!-- item.status == 1 ? "查看" : "编辑" -->

              <span @click="ClickEdit(item, '资讯')"> 编辑 </span>
              <span @click="ClickDeleite(item, '资讯')">删除</span>
            </div>
          </li>
        </ul>

        <!-- 课堂 -->
        <ul class="zx" v-if="item == '课程'">
          <p style="text-align: right">
            <span
              style="
                display: inline-block;
                height: 24px;
                line-height: 24px;
                margin-right: 20px;
                padding: 0 5px;
                font-size: 14px;
                cursor: pointer;
                color: rgb(22, 119, 255);
              "
              @click="ClickExplain"
            >
              直播教程说明
            </span>
          </p>

          <li
            v-for="(item, index) in ktList"
            :key="index"
            style="display: flex; justify-content: space-between"
          >
            <div style="display: flex; width: 50%">
              <div class="img">
                <img :src="item.cover" alt="" />
                <span
                  class="release_status"
                  v-if="item.status == 2"
                  style="background: #1677ff"
                  >待审核</span
                >
                <span
                  class="release_status"
                  v-if="item.status == 3"
                  style="background: #ff7c7c"
                  >被驳回</span
                >
                <span
                  class="release_status"
                  v-if="item.status == 0"
                  style="background: red"
                >
                  已删除</span
                >
              </div>
              <div class="content" @click="ClickClassRoom(item)">
                <p
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ item.title }}
                </p>
                <p class="userContentText" style="-webkit-line-clamp: 3">
                  {{ item.description }}
                </p>
              </div>
            </div>
            <div class="editKc" ref="editKc">
              <span
                class="span1"
                v-if="item.is_end == true && item.status == 1"
                slot="append"
                @click="generateURL(item)"
                style="background-color: #61b15a; width: 84px"
                >生成推流地址</span
              >
              <span
                class="span2"
                v-if="item.status == 1 && item.push_url_pre && item.is_end"
                slot="append"
                @click="copyURL(item.push_url_pre, item)"
                style="background-color: #1677ff; width: 84px"
                >复制推流地址</span
              >
              <span
                class="span2"
                v-if="item.status == 1 && item.push_url_pre && item.is_end"
                slot="append"
                @click="copyURL(item.push_url_after, item)"
                style="background-color: #433d3c; width: 84px"
                >复制串流密钥</span
              >
              <span class="span3" @click="ClickEdit(item, '课程')">{{
                item.status == 1 ? "查看" : "编辑"
              }}</span>
              <span class="span4" @click="ClickDeleite(item, '课程')"
                >删除</span
              >
            </div>
          </li>
        </ul>

        <!-- 活动 -->
        <ul class="zx" v-if="item == '活动'">
          <li v-for="(item, index) in hdList" :key="index">
            <div class="img">
              <img :src="item.cover" alt="" />
              <span
                class="release_status"
                v-if="item.status == 2"
                style="background: #1677ff"
                >待审核</span
              >
              <span
                class="release_status"
                v-if="item.status == 3"
                style="background: #ff7c7c"
                >被驳回</span
              >
              <span
                class="release_status"
                v-if="item.status == 0"
                style="background: red"
                >已删除</span
              >
            </div>
            <div class="content" @click="ClickActivity(item)">
              <p>
                {{ item.title }}
              </p>
              <p class="userContentText">
                {{ item.description }}
              </p>
            </div>
            <div class="edit">
              <span @click="ClickEdit(item, '活动')">{{
                item.status == 1 ? "查看" : "编辑"
              }}</span>
              <span @click="ClickDeleite(item, '活动')">删除</span>
            </div>
          </li>
        </ul>

        <!-- 文库 -->
        <ul class="wk" v-if="item == '文库'">
          <li v-for="(item, index) in wkList" :key="index">
            <div @click="ClickLibrary(item)">
              <div class="title">
                <div
                  id="wk_icon"
                  :style="{
                    color: item.type_color,
                    borderColor: item.type_color,
                  }"
                >
                  {{ item.type_name }}
                </div>
                <p>{{ item.title }}</p>
                <span
                  class="release_status"
                  v-if="item.status == 2"
                  style="background: #1677ff"
                  >待审核</span
                >
                <span
                  class="release_status"
                  v-if="item.status == 3"
                  style="background: #ff7c7c"
                  >被驳回</span
                >
                <span
                  class="release_status"
                  v-if="item.status == 0"
                  style="background: red"
                  >已删除</span
                >
              </div>
              <p class="userContentText" style="-webkit-line-clamp: 4">
                {{ item.description }}
              </p>
            </div>
            <div class="edit">
              <span @click="ClickEdit(item, '文库')">{{
                item.status == 1 ? "查看" : "编辑"
              }}</span>
              <span @click="ClickDeleite(item, '文库')">删除</span>
            </div>
          </li>
        </ul>

        <!-- 招聘 -->
        <ul class="zp" v-if="item == '招聘'">
          <li v-for="(item, index) in zpList" :key="index">
            <div class="left" @click="ClickRecruit(item)">
              <div class="top">
                <p>{{ item.title }}</p>
                <p>{{ "-" + item.work_address }}</p>
                <p>发布于{{ item.time_tmp }}</p>
              </div>
              <div class="bottom">
                <div class="span">
                  <span>{{ item.salary }}</span>
                  <span>{{ item.exp_desc }}</span>
                  <span>{{ item.edut_desc }}</span>
                </div>
                <div class="status">
                  <span
                    class="release_status"
                    v-if="item.status == 2"
                    style="background: #1677ff"
                    >待审核</span
                  >
                  <span
                    class="release_status"
                    v-if="item.status == 3"
                    style="background: #ff7c7c"
                    >被驳回</span
                  >
                  <span
                    class="release_status"
                    v-if="item.status == 0"
                    style="background: red"
                    >已删除</span
                  >
                </div>
              </div>
            </div>
            <div class="edit">
              <span @click="ClickEdit(item, '招聘')">{{
                item.status == 1 ? "查看" : "编辑"
              }}</span>
              <span @click="ClickDeleite(item, '招聘')">删除</span>
            </div>
          </li>
        </ul>

        <!-- 企业信息
      <el-form :model="informatForm" ref="inforFormRef" class="form" v-if="item=='企业信息'&& !editShow" style="padding-right: 30px">
        企业介绍
        <el-form-item label="企业介绍">
          <el-input type="textarea" v-model="informatForm.description" placeholder="请输入企业介绍"></el-input>
        </el-form-item>

        <el-form-item label="企业邮箱">
          <el-input type="text" v-model="informatForm.email" placeholder="请输入企业邮箱"></el-input>
        </el-form-item>


        产品信息
        <el-form-item label="产品信息" prop="user">
          <div style="display: flex; flex-direction: column; width: 100%">
            <template v-for="(item,index) in informatForm.product_info">
              <div style="display: flex; width: 100%; margin-bottom: 16px">
                <img :src="item.cover" alt="" style="display: block; width: 100px; height: 100px" />
                <div style="
                      width: 100%;
                      margin-left: 16px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      justify-content: space-around;
                    ">
                  <el-input v-model="item.title" placeholder="产品名字"></el-input>
                  <el-input v-model="item.description" placeholder="产品地址"></el-input>
                </div>
              </div>
            </template>

          </div>


        </el-form-item>

        企业地址
        <el-form-item label="企业地址">
          <div style="display: flex;flex-wrap: wrap;width: 100%;">
            <template v-for="(item,index) in addressHide" v-if="addressHide.length>=1">
              <el-input :value="item" placeholder="请输入企业地址" style="margin-bottom: 10px;width: 100%;"></el-input>
            </template>


              <el-input  placeholder="请输入企业地址" style="margin-bottom: 10px;width: 100%;" v-if="addressHide.length<1"></el-input>

          </div>


        </el-form-item>

        企业资质
        <template v-for="(item,index) in informatForm.zz">
          <el-form-item label="企业资质" prop="user">

            <div style="width: 100%">
              <el-input v-model="item.name" placeholder="请输入企业资质"></el-input>
              <div style="width: 100px; height: 100px; margin-top: 10px">
                <img :src="item.cover" alt="" style="display: block; width: 100%; height: 100%" />
              </div>
            </div>


          </el-form-item>

          资质介绍
          <el-form-item label="资质介绍" prop="grjj">
            <el-input type="textarea" autosize v-model="item.description" placeholder="请输入企业百度(纳斯达克:BDU),全球最大的中文搜索引擎、最大的中文网站。1999年底身在美国硅谷的李彦宏看到了中国互联网及中文搜索引擎服务的巨大发展潜力,抱着技术改变世界的梦想,他毅然辞掉硅谷的高薪工作,携搜索引擎专利技术,于2000年1月1日在中关村创建了百度公司。“百度二字来自于八百年前南宋词人辛弃疾的一句词:众里寻他千百度。这句话描述了词人对理想的执着百度成为中国掌握世界"></el-input>
          </el-form-item>

        </template>

        编辑
        <el-form-item>
          <el-button type="primary" @click.stop="ClickInforEdit" style="
                        width: 104px;
                        height: 34px;
                        line-height: 0px;
                        margin: 20px 0 0 120px;
                        border-radius: 0;
                        background: #1677FF;
                      ">编辑</el-button>
        </el-form-item>
      </el-form> -->

        <!-- 企业信息编辑 -->

        <el-form
          :model="informatForm"
          ref="inforFormRef"
          class="form"
          v-if="item == '企业信息'"
          style="padding-right: 30px"
          v-loading.fullscreen.lock="loading"
        >
          <!-- 企业介绍 -->
          <el-form-item label="企业介绍">
            <el-input
              type="textarea"
              autosize
              v-model="informatForm.description"
              placeholder="请输入您的企业简介"
            ></el-input>
          </el-form-item>

          <el-form-item label="企业邮箱">
            <el-input
              type="text"
              v-model="informatForm.email"
              placeholder="请输入企业邮箱"
            ></el-input>
          </el-form-item>

          <!-- 产品信息 -->
          <div class="ProductMsg">
            <el-form-item
              label="产品信息"
              prop="user"
              style="position: relative"
            >
              <div style="display: flex; flex-direction: column">
                <div
                  style="display: flex; margin-bottom: 16px"
                  v-for="(item, index) in informatForm.product_info"
                  :key="index"
                >
                  <el-upload
                    :before-upload="beforeAvatarUpload"
                    :action="uploadUrl"
                    list-type="picture-card"
                    :on-success="ProducSucces"
                    :show-file-list="false"
                    :on-preview="handlePictureCardPreview"
                  >
                    <img
                      :src="item.cover"
                      @click="elUpload(index)"
                      alt=""
                      style="display: block; width: 100px; height: 100px"
                    />
                  </el-upload>

                  <div
                    style="
                      width: 100%;
                      margin-left: 16px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      justify-content: space-around;
                    "
                  >
                    <el-input
                      v-model="item.title"
                      placeholder="产品名字"
                    ></el-input>
                    <el-input
                      v-model="item.description"
                      placeholder="产品地址"
                    ></el-input>
                  </div>
                </div>
              </div>
              <div style="display: flex; margin-bottom: 16px">
                <div style="display: flex; margin-left: 106px">
                  <!-- 添加产品 -->
                  <span class="addProduct" @click="ClickAddProduct"
                    >添加产品</span
                  >
                  <span
                    class="delete"
                    @click="ClickDeleteProduct"
                    v-if="informatForm.product_info.length >= 2"
                    >删除</span
                  >
                </div>
              </div>
            </el-form-item>
          </div>

          <!-- 企业地址 -->
          <el-form-item label="企业地址">
            <div style="display: flex; flex-direction: column" class="address">
              <div
                style="display: flex"
                v-for="(item, index) in informatForm.address"
                :key="index"
              >
                <el-cascader
                  :options="optionsCity"
                  v-model="informatForm.address[index].value"
                  clearable
                  :props="{
                    label: 'shortname',
                    children: 'sub',
                    value: 'shortname',
                  }"
                  @change="handleCascader"
                  placeholder="请选择城市"
                ></el-cascader>

                <el-input
                  v-model="item.address_name"
                  placeholder="请输入企业地址"
                  @focus="coordinates(index)"
                  style="margin-left: 25px; width: 320px"
                ></el-input>
              </div>
              <div style="display: flex; margin: 17px 0 0 106px">
                <!-- 添加地址 -->
                <span class="addProduct" @click="ClickAddAddr">添加地址</span>
                <span
                  class="delete"
                  @click="ClickDeleteAddr"
                  v-if="informatForm.address.length >= 2"
                  >删除</span
                >
              </div>
            </div>
          </el-form-item>

          <!-- 添加资质 -->
          <div>
            <div v-for="(item, index) in informatForm.zz" :key="index">
              <!-- 企业资质 -->
              <el-form-item label="企业资质" prop="user">
                <div style="width: 100%">
                  <el-input
                    :before-upload="beforeAvatarUpload"
                    v-model="item.name"
                    placeholder="请输入企业资质"
                  ></el-input>
                  <div style="width: 100px; height: 100px; margin-top: 10px">
                    <el-upload
                      :action="uploadUrl"
                      list-type="picture-card"
                      :on-success="zzSuccess"
                      :show-file-list="false"
                      :on-preview="handlePictureCardPreview"
                    >
                      <img
                        :src="item.cover"
                        alt=""
                        style="display: block; width: 100px; height: 100px"
                        @click="elUpload(index)"
                      />
                    </el-upload>
                  </div>
                </div>
              </el-form-item>

              <!-- 资质介绍 -->
              <el-form-item label="资质介绍" prop="grjj">
                <el-input
                  type="textarea"
                  autosize
                  v-model="item.description"
                  placeholder="请输入相应的资质介绍"
                ></el-input>
              </el-form-item>
            </div>
            <!-- <div style="display: flex; margin: 17px 0 0 230px">
            添加资质
            <span class="addProduct" @click="ClickAddProve">添加资质</span>
            <span class="delete" @click="ClickDeleteProve" v-if="informatForm.zz.length >= 2">删除</span>
          </div> -->
          </div>

          <!-- 保存 -->
          <el-form-item>
            <el-button
              type="primary"
              style="
                width: 104px;
                height: 34px;
                line-height: 0px;
                margin: 20px 0 0 120px;
                border-radius: 0;
                background: #1677ff;
              "
              @click="preserve"
              >保存</el-button
            >
          </el-form-item>
        </el-form>

        <div
          style="font-size: 14px; color: #9c9cab; padding: 20px"
          v-if="total < 1"
        >
          <img src="../../../assets/image/缺省图.png" style="width: 13%" />
          <p style="margin-top: 15px">暂无列表</p>
        </div>

        <!-- 底部分页 -->
        <div class="page" v-if="i !== 5">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.pagenum"
            :page-size="queryInfo.pagesize"
            layout="prev, pager, next"
            :total="total"
            :hide-on-single-page="true"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 地图弹框 -->
      <div class="map">
        <el-dialog
          :visible.sync="dialogVisible"
          width="34%"
          :before-close="handleClose"
          :show-close="true"
          :close-on-click-modal="true"
        >
          <div style="padding: 30px">
            <el-input
              placeholder="地址中请包含城市名称，否则会影响解析效果"
              v-model="mapInput"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchMap"
              ></el-button>
            </el-input>
            <div style="width: 100%; height: 15px"></div>
            <div id="container"></div>
          </div>
        </el-dialog>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc" id="mobile">
      <Header></Header>
      <div style="width: 100%; height: 60px"></div>
      <div
        style="
          text-align: left;
          display: flex;
          align-items: center;
          line-height: 26px;
          padding-left: 20px;
          background-color: #fff;
          font-weight: 700;
        "
      >
        <p
          style="
            width: 3px;
            height: 18px;
            background-color: #1677ff;
            margin-right: 10px;
          "
        ></p>
        <p>内容管理</p>
      </div>
      <div class="btn" style="" v-show="i !== 5" @click="publish">
        {{ issueBTN }}
      </div>

      <div class="top_mobile">
        <div class="left_mobile">
          <ul style="">
            <li
              v-for="(item, index) in contentList"
              :key="index"
              :class="{ color: i == index }"
              @click="ClickContentList(item, index)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom_mobile">
        <LoadMore
          :onLoadMore="onLoadMore"
          :enableLoadMore="enableLoadMore"
          ref="LoadMore"
        >
          <!-- 资讯 -->
          <ul class="zx" v-if="item == '资讯'">
            <li v-for="(item, index) in zxList" :key="index">
              <vue-sideslip
                @click="zxClick(item, $event)"
                :operationList="[
                  // 侧滑菜单列表
                  {
                    name: item.status == 1 ? '查看' : '编辑', //item.status == 1 ? '查看' : '编辑'
                    style:
                      'background: #FEB842;color: #ffffff;line-height: 88px;width: 60px;height:88px;text-align: center;font-size: 12px;', // 自定义样式
                    click: '编辑', // 点击事件回调
                    // disabledStyle: 'opacity:1;', // 按钮禁用样式
                    html: (item) => {
                      // 这里可自由添加侧滑结构内容
                      return `<div>${item.name}</div>`;
                    },
                  },
                  {
                    name: '删除',
                    style:
                      'background: #FF6F7B;color: #ffffff;line-height: 88px;width: 60px;height:88px;text-align: center;font-size: 12px;',
                    click: '删除',
                    html: (item) => {
                      return `<div>${item.name}</div>`;
                    },
                  },
                ]"
                :name="index"
              >
                <div style="display: flex">
                  <div class="img">
                    <img :src="item.cover" alt="" />
                    <span
                      class="release_status"
                      v-if="item.status == 2"
                      style="background: #1677ff"
                      >待审核</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 3"
                      style="background: #ff7c7c"
                      >被驳回</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 0"
                      style="background: red"
                      >已删除</span
                    >
                    <!-- {{item.status_name}} -->
                  </div>
                  <div class="content" @click="ClickInfo(item)">
                    <p>
                      {{ item.title }}
                    </p>
                    <p class="userContentText" style="-webkit-line-clamp: 2">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </vue-sideslip>
            </li>
          </ul>

          <!-- 课堂 -->
          <ul class="zx" v-if="item == '课程'">
            <p style="text-align: right">
              <span
                style="
                  display: inline-block;
                  height: 24px;
                  line-height: 24px;
                  margin-right: 20px;
                  padding: 0 5px;
                  font-size: 12px;
                  cursor: pointer;
                  color: rgb(22, 119, 255);
                "
                @click="ClickExplain"
              >
                直播教程说明
              </span>
            </p>
            <li v-for="(item, index) in ktList" :key="index">
              <vue-sideslip
                @click="kcClick(item, $event)"
                :operationList="[
                  // 侧滑菜单列表
                  {
                    name: '生成推流地址', //

                    style:
                      'background-color: rgb(97, 177, 90);color: #ffffff;line-height: 88px;width: 60px;height:88px;text-align: center;font-size: 12px;word-wrap:break-word; word-break:normal;box-sizing: border-box;display: flex;flex-wrap: wrap;', // 自定义样式
                    click: '生成推流地址', // 点击事件回调
                    disabledStyle: 'opacity:1; ', // 按钮禁用样式
                    html: (item) => {
                      // 这里可自由添加侧滑结构内容
                      return `<span>${item.name}</span>`;
                    },
                  },
                  {
                    name: '复制推流地址', //
                    style:
                      'background: #1677ff;color: #ffffff;line-height: 88px;width: 60px;height:88px;text-align: center;font-size: 12px;word-wrap:break-word; word-break:normal;box-sizing: border-box;', // 自定义样式
                    click: '复制推流地址', // 点击事件回调
                    disabledStyle: 'opacity:1;', // 按钮禁用样式
                    html: (item) => {
                      // 这里可自由添加侧滑结构内容
                      return `<div>${item.name}</div>`;
                    },
                  },
                  {
                    name: '复制串流密钥', //
                    style:
                      'background: #433d3c;color: #ffffff;line-height: 88px;width: 60px;height:88px;text-align: center;font-size: 12px;word-wrap:break-word; word-break:normal;box-sizing: border-box;', // 自定义样式
                    click: '复制串流密钥', // 点击事件回调
                    // disabledStyle: 'opacity:1;', // 按钮禁用样式
                    html: (item) => {
                      // 这里可自由添加侧滑结构内容
                      return `<div>${item.name}</div>`;
                    },
                  },
                  {
                    name: item.status == 1 ? '查看' : '编辑', //
                    style:
                      'background: #FEB842;color: #ffffff;line-height: 88px;width: 60px; height:88px;text-align: center;font-size: 12px;', // 自定义样式
                    click: '编辑', // 点击事件回调
                    // disabledStyle: 'opacity:1;', // 按钮禁用样式
                    html: (item) => {
                      // 这里可自由添加侧滑结构内容
                      return `<div>${item.name}</div>`;
                    },
                  },
                  {
                    name: '删除',
                    style:
                      'background: #FF6F7B;color: #ffffff;line-height: 88px;width: 60px;height:88px;text-align: center;font-size: 12px;',
                    click: '删除',
                    html: (item) => {
                      return `<div>${item.name}</div>`;
                    },
                  },
                ]"
                :name="index"
              >
                <div style="display: flex">
                  <div class="img">
                    <img :src="item.cover" alt="" />
                    <span
                      class="release_status"
                      v-if="item.status == 2"
                      style="background: #1677ff"
                      >待审核</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 3"
                      style="background: #ff7c7c"
                      >被驳回</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 0"
                      style="background: red"
                    >
                      已删除</span
                    >
                  </div>
                  <div class="content" @click="ClickClassRoom(item)">
                    <p>
                      {{ item.title }}
                    </p>
                    <p class="userContentText" style="-webkit-line-clamp: 2">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </vue-sideslip>
            </li>
          </ul>

          <!-- 活动 -->
          <ul class="zx" v-if="item == '活动'">
            <li v-for="(item, index) in hdList" :key="index">
              <vue-sideslip
                @click="hdClick(item, $event)"
                :operationList="[
                  // 侧滑菜单列表
                  {
                    name: item.status == 1 ? '查看' : '编辑', //
                    style:
                      'background: #FEB842;color: #ffffff;line-height: 88px;width: 60px;height:88px;text-align: center;font-size: 12px;', // 自定义样式
                    click: '编辑', // 点击事件回调
                    // disabledStyle: 'opacity:1;', // 按钮禁用样式
                    html: (item) => {
                      // 这里可自由添加侧滑结构内容
                      return `<div>${item.name}</div>`;
                    },
                  },
                  {
                    name: '删除',
                    style:
                      'background: #FF6F7B;color: #ffffff;line-height: 88px;width: 60px;height:88px;text-align: center;font-size: 12px;',
                    click: '删除',
                    html: (item) => {
                      return `<div>${item.name}</div>`;
                    },
                  },
                ]"
                :name="index"
              >
                <div style="display: flex">
                  <div class="img">
                    <img :src="item.cover" alt="" />
                    <span
                      class="release_status"
                      v-if="item.status == 2"
                      style="background: #1677ff"
                      >待审核</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 3"
                      style="background: #ff7c7c"
                      >被驳回</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 0"
                      style="background: red"
                      >已删除</span
                    >
                  </div>
                  <div class="content" @click="ClickActivity(item)">
                    <p>
                      {{ item.title }}
                    </p>
                    <p class="userContentText">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </vue-sideslip>
            </li>
          </ul>

          <!-- 文库 -->
          <ul class="wk" v-if="item == '文库'">
            <li v-for="(item, index) in wkList" :key="index">
              <vue-sideslip
                @click="wkClick(item, $event)"
                :operationList="[
                  // 侧滑菜单列表
                  {
                    name: item.status == 1 ? '查看' : '编辑', //
                    style:
                      'background: #FEB842;color: #ffffff;line-height: 100px;width: 60px;height:100px;;text-align: center;font-size: 12px;', // 自定义样式
                    click: '编辑', // 点击事件回调
                    // disabledStyle: 'opacity:1;', // 按钮禁用样式
                    html: (item) => {
                      // 这里可自由添加侧滑结构内容
                      return `<div>${item.name}</div>`;
                    },
                  },
                  {
                    name: '删除',
                    style:
                      'background: #FF6F7B;color: #ffffff;line-height: 100px;width: 60px;height:100px;text-align: center;font-size: 12px;',
                    click: '删除',
                    html: (item) => {
                      return `<div>${item.name}</div>`;
                    },
                  },
                ]"
                :name="index"
              >
                <div @click="ClickLibrary(item)">
                  <div class="title">
                    <div
                      id="wk_icon"
                      :style="{
                        color: item.type_color,
                        borderColor: item.type_color,
                      }"
                    >
                      {{ item.type_name }}
                    </div>
                    <p>{{ item.title }}</p>
                    <span
                      class="release_status"
                      v-if="item.status == 2"
                      style="background: #1677ff"
                      >待审核</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 3"
                      style="background: #ff7c7c"
                      >被驳回</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 0"
                      style="background: red"
                      >已删除</span
                    >
                  </div>
                  <p class="userContentText" style="-webkit-line-clamp: 2">
                    {{ item.description }}
                  </p>
                </div>
              </vue-sideslip>
            </li>
          </ul>

          <!-- 招聘 -->
          <ul
            class="zp"
            v-if="item == '招聘'"
            style="margin: 0 10px; box-sizing: border-box"
          >
            <li v-for="(item, index) in zpList" :key="index">
              <vue-sideslip
                @click="zxClick(item, $event)"
                :operationList="[
                  // 侧滑菜单列表
                  {
                    name: item.status == 1 ? '查看' : '编辑', //
                    style:
                      'background: #FEB842;color: #ffffff;line-height: 100px;width: 60px;height:100px;text-align: center;font-size: 12px;', // 自定义样式
                    click: '编辑', // 点击事件回调
                    // disabledStyle: 'opacity:1;', // 按钮禁用样式
                    html: (item) => {
                      // 这里可自由添加侧滑结构内容
                      return `<div>${item.name}</div>`;
                    },
                  },
                  {
                    name: '删除',
                    style:
                      'background: #FF6F7B;color: #ffffff;line-height: 100px;width: 60px;height:100px;text-align: center;font-size: 12px;',
                    click: '删除',
                    html: (item) => {
                      return `<div>${item.name}</div>`;
                    },
                  },
                ]"
                :name="index"
              >
                <div class="left" @click="ClickRecruit(item)">
                  <div class="top">
                    <p>{{ item.title }}{{ "-" + item.work_address }}</p>
                    <p>{{ item.salary }}</p>
                  </div>
                  <div class="bottom">
                    <div class="span">
                      <span
                        ><div class="img"><img :src="item.logo" alt="" /></div
                      ></span>
                      <span>{{ item.business_name }}</span>
                      <span>
                        <span>{{ item.exp_desc }}</span>
                        <span>{{ item.edut_desc }}</span>
                      </span>
                      <span>发布于{{ item.time_tmp }}</span>
                    </div>
                    <div class="status">
                      <span
                        class="release_status"
                        v-if="item.status == 2"
                        style="background: #1677ff"
                        >待审核</span
                      >
                      <span
                        class="release_status"
                        v-if="item.status == 3"
                        style="background: #ff7c7c"
                        >被驳回</span
                      >
                      <span
                        class="release_status"
                        v-if="item.status == 0"
                        style="background: red"
                        >已删除</span
                      >
                    </div>
                  </div>
                </div>
              </vue-sideslip>
            </li>
          </ul>

          <!-- 企业信息编辑 -->

          <el-form
            :model="informatForm"
            ref="inforFormRef"
            class="form_mobile"
            v-if="item == '企业信息'"
            style="padding: 0 10px; margin-top: 20px"
            label-position="left"
            label-width="68px"
          >
            <!-- 企业介绍 -->
            <el-form-item label="企业介绍">
              <el-input
                type="textarea"
                autosize
                v-model="informatForm.description"
                placeholder="请输入您的企业简介"
              ></el-input>
            </el-form-item>

            <el-form-item label="企业邮箱">
              <el-input
                type="text"
                v-model="informatForm.email"
                placeholder="请输入企业邮箱"
              ></el-input>
            </el-form-item>

            <!-- 产品信息 -->
            <div class="ProductMsg">
              <el-form-item
                label="产品信息"
                prop="user"
                style="position: relative"
              >
              </el-form-item>
            </div>

            <div style="display: flex; flex-direction: column">
              <div
                style="display: flex; margin-bottom: 16px"
                v-for="(item, index) in informatForm.product_info"
                :key="index"
              >
                <el-upload
                  :before-upload="beforeAvatarUpload"
                  :action="uploadUrl"
                  list-type="picture-card"
                  :on-success="ProducSucces"
                  :show-file-list="false"
                  :on-preview="handlePictureCardPreview"
                >
                  <img
                    :src="item.cover"
                    @click="elUpload(index)"
                    alt=""
                    style="display: block; width: 100px; height: 100px"
                  />
                </el-upload>

                <div
                  style="
                    width: 100%;
                    margin-left: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    justify-content: space-around;
                  "
                >
                  <el-input
                    v-model="item.title"
                    placeholder="产品名字"
                  ></el-input>
                  <el-input
                    v-model="item.description"
                    placeholder="产品地址"
                  ></el-input>
                </div>
              </div>
            </div>
            <div style="display: flex; margin-bottom: 16px">
              <div style="display: flex; margin-left: 106px">
                <!-- 添加产品 -->
                <span class="addProduct" @click="ClickAddProduct"
                  >添加产品</span
                >
                <span
                  class="delete"
                  @click="ClickDeleteProduct"
                  v-if="informatForm.product_info.length >= 2"
                  >删除</span
                >
              </div>
            </div>

            <!-- 企业地址 -->
            <!-- <el-form-item label="企业地址">
          </el-form-item>
          <div style="" class="address">
            <div style="text-align: center;" v-for="(item, index) in informatForm.address" :key="index">
              <el-cascader :options="optionsCity" v-model="informatForm.address[index].value" clearable :props="{label:'shortname',children:'sub',value:'shortname'}"  @change="handleCascader" placeholder="请选择城市"></el-cascader>
              <el-input v-model="item.address_name" placeholder="请输入企业地址" @focus="coordinates(index)" style="margin-left: 0px;width: 320px;"></el-input>
            </div>

            <div style="display: flex; margin: 17px 0 0 106px">

              <span class="addProduct" @click="ClickAddAddr">添加地址</span>
              <span class="delete" @click="ClickDeleteAddr" v-if="informatForm.address.length >= 2">删除</span>
            </div>
          </div> -->

            <el-form-item label="企业地址">
              <div style="width: 100%" class="address">
                <div
                  style=""
                  v-for="(item, index) in informatForm.address"
                  :key="index"
                >
                  <el-input
                    v-model="item.address_name"
                    placeholder="请输入企业地址"
                    @focus="coordinates(index)"
                  ></el-input>
                </div>

                <div style="display: flex; margin: 10px 0 0 0">
                  <span class="addProduct" @click="ClickAddAddr" style=""
                    >添加地址</span
                  >
                  <span
                    class="delete"
                    @click="ClickDeleteAddr"
                    v-if="informatForm.address.length >= 2"
                    >删除</span
                  >
                </div>
              </div>
            </el-form-item>

            <!-- 添加资质 -->
            <div>
              <div v-for="(item, index) in informatForm.zz" :key="index">
                <!-- 企业资质 -->
                <el-form-item label="企业资质" prop="user">
                  <div style="width: 100%">
                    <el-input
                      v-model="item.name"
                      placeholder="请输入企业资质"
                    ></el-input>
                    <div style="width: 100px; height: 100px; margin-top: 10px">
                      <el-upload
                        :before-upload="beforeAvatarUpload"
                        :action="uploadUrl"
                        list-type="picture-card"
                        :on-success="zzSuccess"
                        :show-file-list="false"
                        :on-preview="handlePictureCardPreview"
                      >
                        <img
                          :src="item.cover"
                          alt=""
                          style="display: block; width: 100px; height: 100px"
                          @click="elUpload(index)"
                        />
                      </el-upload>
                    </div>
                  </div>
                </el-form-item>

                <!-- 资质介绍 -->
                <el-form-item label="资质介绍" prop="grjj">
                  <el-input
                    type="textarea"
                    autosize
                    v-model="item.description"
                    placeholder="请输入相应的资质介绍"
                  ></el-input>
                </el-form-item>
              </div>
              <div style="display: flex; margin: 10px 0 0 60px">
                <!-- 添加资质 -->
                <span class="addProduct" @click="ClickAddProve">添加资质</span>
                <span
                  class="delete"
                  @click="ClickDeleteProve"
                  v-if="informatForm.zz.length >= 2"
                  >删除</span
                >
              </div>
            </div>

            <!-- 保存 -->
            <el-form-item>
              <el-button
                type="primary"
                class="btn"
                style="line-height: 0"
                @click="preserve"
                >保存</el-button
              >
            </el-form-item>
          </el-form>

          <div
            style="font-size: 14px; color: #9c9cab; padding: 40px"
            v-if="total < 1"
          >
            <img src="../../../assets/image/缺省图.png" style="width: 25%" />
            <p style="margin-top: 15px">暂无列表</p>
          </div>
        </LoadMore>
      </div>

      <div style="width: 100%; height: 54px"></div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="34%"
        :before-close="handleClose"
        :show-close="false"
        top="14vh"
        :close-on-click-modal="true"
      >
        <div style="padding: 10px">
          <el-input
            placeholder="地址中请包含城市名称，否则会影响解析效果"
            v-model="mapInput"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchMap"
            ></el-button>
          </el-input>
          <div style="width: 100%; height: 15px"></div>
          <div id="container"></div>
        </div>
      </el-dialog>
    </div>

    <!-- 直播说明弹框 -->
    <div class="explain">
      <el-dialog
        title="直播教程说明"
        :visible.sync="explainDialogVisible"
        width="30%"
        center
        top="28vh"
      >
        <div v-html="explain">
          <template>
            <div>
              {{ explain }}
            </div>
          </template>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import LoadMore from "../../loadMore/loadMore.vue";
import Header from "../../header.vue";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Header,
    LoadMore,
  },
  data() {
    return {
      // is_business: this.$store.user.state.userInfo,
      explain: "",
      explainDialogVisible: false,
      mapInput: "",
      isList: false,
      item: "资讯",

      dialogVisible: false,
      dialogImageUrl: "",
      hideUpload: false,

      // 内容管理列表

      contentList: [],
      // 内容管理列表 默认显示第一个
      i: 0,

      zxList: [], // 资讯列表
      ktList: [], // 课堂列表
      hdList: [], // 活动列表
      // 文库列表
      wkList: [],
      // 招聘列表
      zpList: [],

      // 企业信息
      informatForm: {
        description: "",
        email: "",
        zz: [
          {
            name: "",
            description: "",
            cover: require("@/assets/image/zz.png"),
          },
        ],

        product_info: [
          {
            cover: require("@/assets/image/product.png"), //产品图片
            title: "", //产品名称
            description: "", //产品介绍
          },
        ],
        address: [
          {
            address_name: "",
            province: "0",
            city: 0,
            area: 0,
            lng: "0",
            lat: "0",
            value: [],
          },
        ],
      },

      // 企业地址列表
      informatAddrList: 2,

      // 企业资质列表
      informatProveList: 2,
      editShow: false,
      // 页码
      // 查询文章列表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 4,
      },
      // 总条数
      total: 0,
      value: [],

      user: "",
      companyInfo: "", //企业信息
      UploadIndex: "", //当前产品图片上传的序号
      optionsCity: "",
      location: {
        lat: 39.92855,
        lng: 116.41637,
      }, //地图坐标
      container: "",
      //选的经纬度
      longitude: "",
      latitude: "",
      MAPindex: 0,
      addressHide: [], //显示的地址
      isIdentity: "",
      issueBTN: "发布资讯",
      show_pc: true,
      fullscreenLoading: false,
      loading: false,
      enableLoadMore: true,
      push_url: "",
    };
  },
  computed: {
    ...mapState(["uploadUrl"]),
    isEdit() {
      return (item) => {
        console.log(item);
        return;
      };
    },
    editKcWidth() {
      this.$nextTick(() => {
        return "11";
      });
    },
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.show_pc
      ? (this.queryInfo.pagesize = 6)
      : (this.queryInfo.pagesize = 4);
    this.$parent.setIcon = "内容管理";
    this.getUser();

    if (this.$route.query.tabs) {
      if (
        this.$store.state.isIdentity == "作者" &&
        this.$route.query.tabs == "活动"
      ) {
        this.i = 1;
        this.issueBTN = "发布活动";
      }
      if (this.$route.query.tabs == "资讯") {
        this.i = 0;
        this.issueBTN = "发布资讯";
      }
      if (
        this.$store.state.isIdentity != "作者" &&
        this.$route.query.tabs == "活动"
      ) {
        this.i = 2;
        this.issueBTN = "发布活动";
      }
      if (this.$route.query.tabs == "课程") {
        this.i = 1;
        this.issueBTN = "发布课程";
      }
      if (this.$route.query.tabs == "文库") {
        this.i = 3;
        this.issueBTN = "发布文库";
      }
      if (this.$route.query.tabs == "招聘") {
        this.i = 4;
        this.issueBTN = "发布招聘";
      }
      if (this.$route.query.tabs == "企业信息") {
        this.i = 5;
        this.total = 1;
      }
      this.ClickContentList(this.$route.query.tabs, 0);
    } else {
      this.getZx();
    }

    //获取省市区
    this.$http
      .post("/index.php/api/v1_0_0.address/get_address", {})
      .then(({ data }) => {
        console.log(data, "chengshi");
        this.optionsCity = data.list;
      })
      .catch((error) => {});
  },

  methods: {
    // 点击资讯
    ClickInfo(item) {
      if (item.status != 1) {
        this.$message.error("资讯未发布");
        return false;
      }
      this.$router.push({
        path: "/zx/details",
        query: { id: item.id },
      });
    },
    // 点击课堂列表
    ClickClassRoom(item) {
      if (item.status != 1) {
        this.$message.error("课程未发布");
        return false;
      }
      this.$router.push({
        path: "/xskt/salaryClassroom",
        query: { id: item.id },
      });
    },

    // 点击课堂说明
    ClickExplain() {
      this.explainDialogVisible = true;
      this.$http
        .post("/index.php/api/v1_0_0.course/live_remark", {})
        .then(({ data }) => {
          this.explain = data.content;
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 点击活动列表
    ClickActivity(item) {
      if (item.status != 1) {
        this.$message.error("活动未发布");
        return false;
      }
      this.$router.push({
        path: "/hd/activityDetails",
        query: { id: item.id },
      });
    },
    // 点击文库
    ClickLibrary(item) {
      if (item.status != 1) {
        this.$message.error("文库未发布");
        return false;
      }
      this.$router.push({
        path: "/wk/libraryDetails",
        query: { id: item.id },
      });
    },
    // 点击招聘列表
    ClickRecruit(item) {
      if (item.status != 1) {
        this.$message.error("招聘未发布");
        return false;
      }
      this.$router.push({
        path: "/zp/recruitDetails",
        query: { id: item.id },
      });
    },
    // 图片上传格式
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/png" || "image/jpeg" || "image/bmp" || "image/gif";

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、PNG、bmp、gif格式!");
      }

      return isJPG;
    },
    //移动端上拉刷新
    onLoadMore(done, total = "") {
      console.log(done);
      let item = this.item;

      if (!this.enableLoadMore) {
        return;
      }
      if (total == "") {
        this.queryInfo.pagenum++;
      }
      if (item == "资讯") {
        this.getZx();
        this.issueBTN = "发布资讯";
      }
      if (item == "课程") {
        this.getKt();
        this.issueBTN = "发布课程";
      }
      if (item == "活动") {
        this.getHd();
        this.issueBTN = "发布活动";
      }
      if (item == "文库") {
        this.getWk();
        this.issueBTN = "发布文库";
      }
      if (item == "招聘") {
        this.getZp();
        this.issueBTN = "发布招聘";
      }
      // done();
    },
    //移动端内容管理事件
    zxClick(val, index) {
      console.log(val, index);
      index == "编辑"
        ? this.ClickEdit(val, "资讯")
        : this.ClickDeleite(val, "资讯");
    },
    kcClick(val, index) {
      console.log(val, index);
      if (index == "生成推流地址") {
        this.generateURL(val);
        return;
      }
      if (index == "复制推流地址") {
        this.copyURL(val.push_url_pre, val);
        return;
      }
      if (index == "复制串流密钥") {
        this.copyURL(val.push_url_after, val);
        return;
      }

      index == "编辑"
        ? this.ClickEdit(val, "课程")
        : this.ClickDeleite(val, "课程");
    },
    hdClick(val, index) {
      index == "编辑"
        ? this.ClickEdit(val, "活动")
        : this.ClickDeleite(val, "活动");
    },
    wkClick(val, index) {
      index == "编辑"
        ? this.ClickEdit(val, "文库")
        : this.ClickDeleite(val, "文库");
    },
    //地址搜索
    searchMap() {
      console.log(this.mapInput);
      let url = "https://apis.map.qq.com/ws/geocoder/v1";
      // let url = "https://apis.map.qq.com/ws/place/v1/search"
      this.$jsonp(url, {
        key: "BL4BZ-QUGEK-UEYJ5-AEFFV-UCDA2-W2BJZ",
        address: this.mapInput,
        // region:'北京',
        output: "jsonp",
      }).then((res) => {
        console.log(res)
        if (res.message == "查询无结果") {
          this.$message.error("查询无结果");
          return;
        }
        console.log(res, "res");
        this.location = res.result.location;
        this.informatForm.address[this.MAPindex].lng = res.result.location.lng;
        this.informatForm.address[this.MAPindex].lat = res.result.location.lat;
        this.initMap();
      });
    },

    // 生成推流地址
    generateURL(item) {
      const is_business = this.$store.state.user.userInfo.is_business;
      const is_business_time = this.$store.state.user.userInfo.is_business_time;

      if (is_business == 1 && !is_business_time) {
        return this.$alert("企业认证已过期，请联系工作人员线下续费!", "提示", {
          confirmButtonText: "关闭",
          callback: () => {},
        });
      }
      // if (item.status != 1) {
      //   return this.$message.error("课程还未审核通过");
      // }
      // if (item.push_url) {
      this.$http
        .post("/index.php/api/v1_0_0.course/create_flow ", { tid: item.id })
        .then((res) => {
          console.log(res);
          this.$message.success(res.msg);
          this.push_url = res.data.push_url;
          this.getKt();
        })
        .catch(() => {});
      // }
    },
    // 复制推流地址
    copyURL(url, val) {
      console.log(val);
      const is_business = this.$store.state.user.userInfo.is_business;
      const is_business_time = this.$store.state.user.userInfo.is_business_time;

      if (is_business == 1 && !is_business_time) {
        return this.$alert("企业认证已过期，请联系工作人员线下续费!", "提示", {
          confirmButtonText: "关闭",
          callback: () => {},
        });
      }
      if (val.status == 1 && val.push_url_pre && val.is_end) {
        this.push_url = url;

        if (!this.push_url) {
          this.$message.error("请先生成推流地址，成功后复制");
          return;
        }

        this.$copyText(this.push_url).then(
          (e) => {
            return this.$message.success("复制成功");
          },
          (e) => {
            return this.$message.error("复制失败");
          }
        );
      } else {
        return this.$message.warning("课程直播时间已结束");
      }
    },
    preserve() {
      const is_business = this.$store.state.user.userInfo.is_business;
      const is_business_time = this.$store.state.user.userInfo.is_business_time;

      if (is_business == 1 && !is_business_time) {
        return this.$alert("企业认证已过期，请联系工作人员线下续费!", "提示", {
          confirmButtonText: "关闭",
          callback: () => {},
        });
      }
      if (this.show_pc) {
        //移动端没有3连
        this.informatForm.address.map((item, index) => {
          item.province = item.value[0];
          item.city = item.value[1];
          item.area = item.value[2];
        });
      }

      this.informatForm.address.map((item, index) => {
        delete item.value;
      });

      console.log(this.informatForm.address);
      var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (!verify.test(this.informatForm.email)) {
        return this.$message.error("邮箱格式错误, 请重新输入");
      }

      //保存企业信息
      this.$http
        .post("/index.php/api/v1_0_0.consumer/business_edit", {
          description: this.informatForm.description,
          email: this.informatForm.email,
          product_info: this.informatForm.product_info,
          address: this.informatForm.address,
          zz: this.informatForm.zz,
          // zz: imgList,
        })
        .then((data) => {
          console.log(data, "保存企业信息");
          this.$message.success(data.msg);
          this.editShow = false;
          this.addressHide = [];
          this.getCompanyInfo();
        })
        .catch((error) => {});
    },
    handleCascader(e) {
      // e.length < 1
      if (!e) {
        this.informatForm.address[this.MAPindex].address_name = "";
        return;
      }
      console.log(e);
      let v = e[0] + e[1] + e[2];
      console.log(v);
      //根据地址查坐标
      let url = "https://apis.map.qq.com/ws/geocoder/v1";
      this.$jsonp(url, {
        key: "BL4BZ-QUGEK-UEYJ5-AEFFV-UCDA2-W2BJZ",
        address: v,
        output: "jsonp",
      }).then((res) => {
        if (res.message == "查询无结果") {
          // this.$message.error(data.msg);
          return;
        }
        this.location = res.result.location;
        this.informatForm.address[this.MAPindex].lng = res.result.location.lng;
        this.informatForm.address[this.MAPindex].lat = res.result.location.lat;
      });
    },
    initMap() {
      let that = this;
      var myLatlng = new qq.maps.LatLng(this.location.lat, this.location.lng);
      var myOptions = {
        zoom: 14, //设置地图缩放级别
        center: myLatlng, //设置中心点样式
        mapTypeId: qq.maps.MapTypeId.ROADMAP, //设置地图样式详情参见MapType
      };
      //获取dom元素添加地图信息
      var map = new qq.maps.Map(
        document.getElementById("container"),
        myOptions
      );

      var marker = new qq.maps.Marker({
        position: myLatlng,
        map: map,
      });
      //给地图添加点击事件
      //并获取鼠标点击的经纬度
      qq.maps.event.addListener(map, "click", function (event) {
        console.log(event);
        //点击地图获取经纬度 再获取地址
        that
          .$jsonp(`https://apis.map.qq.com/ws/geocoder/v1`, {
            key: "BL4BZ-QUGEK-UEYJ5-AEFFV-UCDA2-W2BJZ",
            location: `${event.latLng.getLat()},${event.latLng.getLng()}`,
            output: "jsonp",
          })
          .then((res) => {
            console.log(res);
            // console.log(res.result.formatted_addresses.recommend)
            if (!this.show_pc) {
              let nowAdd = that.informatForm.address[that.MAPindex];
              nowAdd.address_name = res.result.formatted_addresses.recommend; //详细地址
              nowAdd.lat = res.result.location.lat;
              nowAdd.lng = res.result.location.lng;
              nowAdd.province = res.result.ad_info.province.slice(0, -1);
              nowAdd.city = res.result.ad_info.city.slice(0, -1);
              nowAdd.area = res.result.ad_info.district.slice(0, -1);
            }
            that.informatForm.address[that.MAPindex].address_name =
              res.result.formatted_addresses.recommend;
            that.location = res.result.location;
            that.dialogVisible = false;
          });
      });
    },
    coordinates(index) {
      this.dialogVisible = true;
      this.MAPindex = index;
      this.$nextTick(() => {
        this.initMap();
      });
    },
    getUser() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
        .then(({ data }) => {
          this.userInfo = data;
          this.$store.state.user.userInfo = data;

          if (data.is_writer != 1 && data.is_business != 1) {
            this.isIdentity = "普通用户";
            this.contentList = [];
          }
          if (data.is_writer == 1) {
            this.isIdentity = "作者";
            this.contentList = ["资讯", "文库"];
          }
          if (data.is_business == 1) {
            this.isIdentity = "企业";
            this.contentList = [
              "资讯",
              "课程",
              "活动",
              "文库",
              "招聘",
              "企业信息",
            ];
          }
        })
        .catch((error) => {});
    },
    handleClose() {
      this.dialogVisible = false;
    },
    elUpload(index) {
      this.UploadIndex = index;
      console.log(index);
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //   点击内容管理列表
    ClickContentList(item, index, notabs) {
      console.log(notabs, "notabs");
      if (typeof notabs == "undefined") {
        (this.zxList = []), // 资讯列表
          (this.ktList = []), // 课堂列表
          (this.hdList = []), // 活动列表
          // 文库列表
          (this.wkList = []),
          // 招聘列表
          (this.zpList = []),
          (this.queryInfo.pagenum = 1);
      }
      this.isList = false;
      this.contentList.map((v, i) => {
        if (item == v) {
          console.log(i, "i");
          this.i = i;
        }
      });
      this.item = item;
      this.editShow = false;
      if (item == "资讯") {
        this.fullscreenLoading = true;
        this.getZx();
        this.issueBTN = "发布资讯";
      }
      if (item == "课程") {
        this.fullscreenLoading = true;
        this.getKt();
        this.issueBTN = "发布课程";
      }
      if (item == "活动") {
        this.fullscreenLoading = true;
        this.getHd();
        this.issueBTN = "发布活动";
      }
      if (item == "文库") {
        this.fullscreenLoading = true;
        this.getWk();
        this.issueBTN = "发布文库";
      }
      if (item == "招聘") {
        this.fullscreenLoading = true;
        this.getZp();
        this.issueBTN = "发布招聘";
      }
      if (item == "企业信息") {
        this.fullscreenLoading = true;
        this.total = 1;
        this.getCompanyInfo();
      }
    },
    // 点击编辑
    ClickEdit(item, falg) {
      // console.log(this.$store.state.user.userInfo.is_business);
      const is_business = this.$store.state.user.userInfo.is_business;
      const is_business_time = this.$store.state.user.userInfo.is_business_time;

      if (is_business == 1 && !is_business_time) {
        return this.$alert("企业认证已过期，请联系工作人员线下续费!", "提示", {
          confirmButtonText: "关闭",
          callback: () => {},
        });
      }
      item.status == 1
        ? (this.$store.state.editDisabled = true)
        : (this.$store.state.editDisabled = false);
      if (!this.show_pc) {
        if (falg == "资讯") {
          this.$router.push({
            path: "/release/infoMobile",
            query: {
              tid: item.id,
              item: "发布资讯",
            },
          });
        }
        if (this.issueBTN == "发布课程") {
          this.$router.push({
            path: "/release/courseMobile",
            query: {
              tid: item.id,
              item: "发布课程",
            },
          });
        }
        if (this.issueBTN == "发布活动") {
          this.$router.push({
            path: "/release/activityMobile",
            query: {
              tid: item.id,
              item: "发布活动",
            },
          });
        }
        if (this.issueBTN == "发布文库") {
          this.$router.push({
            path: "/release/libraryMobile",
            query: {
              tid: item.id,
              item: "发布文库",
            },
          });
        }
        if (this.issueBTN == "发布招聘") {
          this.$router.push({
            path: "/release/postionMobile",
            query: {
              tid: item.id,
              item: "发布招聘",
            },
          });
        }
        return;
      }

      console.log(item, falg);
      this.$store.state.goEdit = falg;
      console.log(this.$store.state.goEdit, "query");
      if (falg == "资讯") {
        this.$router.push({
          name: "Info",
          query: {
            tid: item.id,
            item: "发布资讯",
          },
        });
        return;
      }
      if (falg == "课程") {
        this.$router.push({
          name: "Course",
          query: {
            tid: item.id,
            item: "发布课程",
          },
        });
        return;
      }
      if (falg == "活动") {
        this.$router.push({
          name: "Activity",
          query: {
            tid: item.id,
            item: "发布活动",
          },
        });
        return;
      }
      if (falg == "文库") {
        this.$router.push({
          name: "Library",
          query: {
            tid: item.id,
            item: "发布文库",
          },
        });
        return;
      }
      if (falg == "招聘") {
        this.$router.push({
          name: "Postion",
          query: {
            tid: item.id,
            item: "发布招聘",
          },
        });
        return;
      }
    },
    // 点击删除
    ClickDeleite(item, falg) {
      console.log(item, falg);
      const is_business = this.$store.state.user.userInfo.is_business;
      const is_business_time = this.$store.state.user.userInfo.is_business_time;

      if (is_business == 1 && !is_business_time) {
        return this.$alert("企业认证已过期，请联系工作人员线下续费!", "提示", {
          confirmButtonText: "关闭",
          callback: () => {},
        });
      }
      if (falg == "资讯") {
        this.$http
          .post("/index.php/api/v1_0_0.consumer/article_del", {
            tid: item.id,
          })
          .then((data) => {
            this.$message.success(data.msg);
            this.zxList = [];
            this.getZx();
          })
          .catch((error) => {});
        return;
      }
      if (falg == "课程") {
        this.$http
          .post("/index.php/api/v1_0_0.consumer/course_del", {
            tid: item.id,
          })
          .then((data) => {
            this.$message.success(data.msg);
            this.ktList = [];
            this.getKt();
          })
          .catch((error) => {});
        return;
      }
      if (falg == "活动") {
        this.$http
          .post("/index.php/api/v1_0_0.consumer/activity_del", {
            tid: item.id,
          })
          .then((data) => {
            this.$message.success(data.msg);
            this.hdList = [];
            this.getHd();
          })
          .catch((error) => {});
        return;
      }
      if (falg == "文库") {
        this.$http
          .post("/index.php/api/v1_0_0.consumer/library_del", {
            tid: item.id,
          })
          .then((data) => {
            this.$message.success(data.msg);
            this.wkList = [];
            this.getWk();
          })
          .catch((error) => {});
        return;
      }
      if (falg == "招聘") {
        this.$http
          .post("/index.php/api/v1_0_0.consumer/recruit_del", {
            tid: item.id,
          })
          .then((data) => {
            this.$message.success(data.msg);
            this.zpList = [];
            this.getZp();
          })
          .catch((error) => {});
        return;
      }
    },
    // 企业信息点击编辑
    ClickInforEdit() {
      this.editShow = true;
      if (this.informatForm.address.length >= 1) {
        this.informatForm.address.map((item, index) => {
          item.value = [item.province, item.city, item.area];
        });
      }

      console.log("企业信息编辑");
    },
    // 点击添加产品信息
    ClickAddProduct() {
      this.informatForm.product_info.push({
        cover: require("@/assets/image/product.png"), //产品图片
        title: "", //产品名称
        description: "", //产品介绍
      });
    },
    // 点击删除产品信息
    ClickDeleteProduct() {
      this.informatForm.product_info.pop();
    },
    // 产品上传成功
    ProducSucces(response, file, fileList) {
      console.log(this.UploadIndex);
      this.informatForm.product_info[this.UploadIndex].cover =
        response.data.file_url;
    },
    // 资质图片上传成功
    zzSuccess(response, file, fileList) {
      this.informatForm.zz[this.UploadIndex].cover = response.data.file_url;
    },
    // 点击添加地址
    ClickAddAddr() {
      this.informatForm.address.push({
        address_name: "",
        province: 0,
        city: 0,
        area: 0,
        lng: "0",
        lat: "0",
      });
    },
    // 点击删除地址
    ClickDeleteAddr() {
      this.informatForm.address.pop();
    },

    // 点击添加资质
    ClickAddProve() {
      this.informatForm.zz.push({
        name: "",
        description: "",
        cover: require("@/assets/image/zz.png"),
      });
    },
    // 点击删除资质
    ClickDeleteProve() {
      this.informatForm.zz.pop();
    },

    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      console.log(newSize);
      // this.getGoodsList();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      // this.getGoodsList();
      console.log(newNum);

      this.ClickContentList(this.contentList[this.i], this.i, "notabs");
    },

    getZx() {
      //获取咨询
      this.$http
        .post("/index.php/api/v1_0_0.consumer/article_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          this.total = data.total_count;
          this.fullscreenLoading = false;
          if (!this.show_pc) {
            data.total_count == this.zxList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);

            this.zxList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }

          this.zxList = data.list;

          console.log(data, "咨询");
        })
        .catch((error) => {});
    },
    getKt() {
      //获取课堂
      this.$http
        .post("/index.php/api/v1_0_0.consumer/course_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          this.fullscreenLoading = false;
          if (!this.show_pc) {
            data.total_count == this.ktList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.total = data.total_count;
            this.ktList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }

          this.ktList = data.list;
          this.total = data.total_count;
          console.log(data, "课程");
        })
        .catch((error) => {});
    },
    getHd() {
      //获取活动
      this.$http
        .post("/index.php/api/v1_0_0.consumer/activity_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          this.fullscreenLoading = false;
          if (!this.show_pc) {
            data.total_count == this.ktList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.total = data.total_count;
            this.hdList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }

          this.hdList = data.list;
          this.total = data.total_count;
          console.log(data.list, "活动");
        })
        .catch((error) => {});
    },
    getWk() {
      //获取文库
      this.$http
        .post("/index.php/api/v1_0_0.consumer/library_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          console.log(data);
          this.fullscreenLoading = false;
          if (!this.show_pc) {
            data.total_count == this.ktList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.total = data.total_count;
            this.wkList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }

          this.wkList = data.list;
          this.total = data.total_count;
          console.log(data, "文库");
        })
        .catch((error) => {});
    },
    getZp() {
      //获取招聘
      this.$http
        .post("/index.php/api/v1_0_0.consumer/recruit_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          this.fullscreenLoading = false;
          if (!this.show_pc) {
            data.total_count == this.ktList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.total = data.total_count;
            this.zpList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }

          this.zpList = data.list;
          this.total = data.total_count;
          console.log(data.list, "招聘");
        })
        .catch((error) => {});
    },
    getCompanyInfo() {
      //获取企业信息
      this.loading = true;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/business_detail", {})
        .then(({ data }) => {
          this.fullscreenLoading = false;
          console.log(data, "企业信息");
          this.informatForm = data;
          this.informatForm.address.value = [];
          console.log(this.informatForm);
          if (this.informatForm.zz.length < 1) {
            this.informatForm.zz.push({
              name: "",
              description: "",
              cover: require("@/assets/image/zz.png"),
            });
          }
          if (this.informatForm.product_info.length < 1) {
            this.informatForm.product_info.push({
              cover: require("@/assets/image/product.png"), //产品图片
              title: "", //产品名称
              description: "", //产品介绍
            });
          }
          if (this.informatForm.address.length < 1) {
            this.informatForm.address.push({
              address_name: "",
              province: "0",
              city: 0,
              area: 0,
              lng: "0",
              lat: "0",
              value: [],
            });
          } else {
            console.log(this.informatForm.address, "this.informatForm.address");
            this.informatForm.address.map((item, index) => {
              item.value = [item.province, item.city, item.area];
            });

            // this.informatForm.address.map((item, index) => {
            //   if (item.province == item.city) {
            //     this.addressHide.push(item.province + '市' + item.address_name)
            //     // item.address_name = item.province + '市' + item.address_name
            //   } else {
            //     this.addressHide.push(item.province + '省' + item.city + '市' + item.address_name)
            //     // item.address_name = item.province + '省' + item.city + '市'  + item.address_name
            //   }
            // })
          }
          this.loading = false;
          console.log(this.informatForm);
        })
        .catch((error) => {});
    },

    publish() {
      if (!this.show_pc) {
        if (this.issueBTN == "发布资讯") {
          this.$router.push({
            path: "/release/infoMobile",
          });
        }
        if (this.issueBTN == "发布课程") {
          this.$router.push({
            path: "/release/courseMobile",
          });
        }
        if (this.issueBTN == "发布活动") {
          this.$router.push({
            path: "/release/activityMobile",
          });
        }
        if (this.issueBTN == "发布文库") {
          this.$router.push({
            path: "/release/libraryMobile",
          });
        }
        if (this.issueBTN == "发布招聘") {
          this.$router.push({
            path: "/release/postionMobile",
          });
        }
        return;
      }

      if (this.issueBTN == "发布资讯") {
        this.$router.push({
          path: "/release/info",
          query: {
            item: this.issueBTN,
          },
        });
      }
      if (this.issueBTN == "发布课程") {
        this.$router.push({
          path: "/release/course",
          query: {
            item: this.issueBTN,
          },
        });
      }
      if (this.issueBTN == "发布活动") {
        this.$router.push({
          path: "/release/activity",
          query: {
            item: this.issueBTN,
          },
        });
      }
      if (this.issueBTN == "发布文库") {
        this.$router.push({
          path: "/release/library",
          query: {
            item: this.issueBTN,
          },
        });
      }
      if (this.issueBTN == "发布招聘") {
        this.$router.push({
          path: "/release/postion",
          query: {
            item: this.issueBTN,
          },
        });
      }

      // this.$router.push({
      //   path: "/release",
      //   query: {
      //     item: this.issueBTN
      //   }
      // });
    },
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f5f5f5");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>
<style lang='less' scoped>
span {
  text-align: center;
}
/* 移动端 */
#mobile {
  text-align: center;
  /deep/.el-form-item__label {
    padding-right: 10px;
  }

  /* background-color: #fff; */

  .top_mobile {
    margin-bottom: 5px;
    display: flex;
    background-color: #fff;
    /* justify-content: space-between;
      align-items: center; */

    /* margin: 15px 20px 20px 5px; */

    .left_mobile {
      width: 100%;

      ul {
        padding: 14px 0 14px 15px;
        display: flex;
        // justify-content: space-around;
        /* justify-content: space-between; */
        /* justify-content: start; */

        .color {
          color: #1677ff;
        }
        li:last-child {
          margin: 0;
        }
        li {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #292931;
          line-height: 21px;
          margin-right: 30px;
          cursor: pointer;
        }
      }
    }

    .right {
      width: 72px;
      height: 32px;
      background: #1677ff;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      padding: 6px 8px;
      cursor: pointer;
      box-sizing: border-box;
    }
  }

  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    line-height: 44px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .bottom_mobile {
    /* background-color: #fff; */

    /* // 资讯 */
    ul {
      li {
        margin-bottom: 5px;
        background-color: #fff;
        width: 100%;
        padding-left: 10px;
        height: 88px;
        box-sizing: border-box;

        .img {
          margin: 10px 0;

          position: relative;
          // margin-top: 10px;
          width: 109px;
          height: 68px;
          border-radius: 5px;
          /* height: 100%; */
          overflow: hidden;
          cursor: pointer;

          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: pink;
          }

          .state {
            background: #ff7c7c !important;
          }

          span {
            position: absolute;
            top: 0;
            left: 0;
            width: 44px;
            height: 18px;
            line-height: 18px;
            background: #1677ff;
            border-radius: 5px 0px 5px 0px;
            box-sizing: border-box;
            // padding: 2px 5px;

            p {
              font-size: 10px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-transform: scale(0.8);
            }
          }
        }

        .content {
          margin: 10px 0 10px 7px;
          width: 65%;
          text-align: left;
          cursor: pointer;

          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #292931;
            line-height: 20px;
          }

          p:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #808080;
            line-height: 20px;
            margin-top: 4px;
            width: 237px;
            // height: 34px;
            overflow: hidden; //强制多行显示
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-height: 17px;
          }
        }
      }
    }

    // 文库
    .wk {
      li {
        height: 100px;
        /* display: flex;
          justify-content: space-between; */
        padding-left: 15px;

        /* // height: 120px; */
        div {
          /* width: 80%; */

          .title {
            width: 329px;

            display: flex;
            align-items: center;
            padding: 10px 0;
            justify-content: start;
            font-size: 15px;
            font-weight: 600;

            .img {
              width: 18px;
              height: 18px;
              border-radius: 3px;
              border: 1px solid #1677ff;
              margin-left: 0;

              img {
                width: 100%;
                height: 100%;
                background: pink;
              }
            }

            p {
              font-size: 15px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #24272f;
              line-height: 21px;
              padding: 0 10px 0 6px;
              margin: 0;
            }

            .state {
              background: #ff7c7c !important;
            }

            span {
              display: inline-block;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 44px;
              height: 18px;
              background: #1677ff;
              border-radius: 5px 0px 5px 0px;
              box-sizing: border-box;

              p {
                padding: 0;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                -webkit-transform: scale(0.8);
              }
            }
          }

          p {
            width: 329px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #453c3c;
            line-height: 20px;
            text-align: left;
          }
        }

        .edit {
          width: 18.5%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            display: inline-block;
            width: 48px;
            height: 24px;
            line-height: 24px;
            border-radius: 3px;
            margin-left: 16px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }

          span:nth-child(1) {
            background-color: #feb842;
          }

          span:nth-child(2) {
            background-color: #ff6f7b;
          }
        }
      }
    }

    // 招聘
    .zp {
      li {
        padding-left: 10px;
        display: flex;
        justify-content: space-between;
        height: auto;
        margin-bottom: 5px;
        border-radius: 5px;

        .left {
          .top {
            display: flex;
            justify-content: space-between;
            margin: 18px 0 21px 0px;

            P:nth-child(1) {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 600;
              color: #1677ff;
              line-height: 22px;
            }

            P:nth-child(2) {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ff733c;
              line-height: 22px;
              margin-right: 10px;
            }
          }

          .bottom {
            display: flex;
            align-items: center;

            .span {
              display: flex;
              align-items: center;
              span:nth-child(1) {
                .img {
                  width: 20px;
                  height: 20px;
                  margin: 0;
                }
              }

              span:nth-child(n + 2) {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #afb2ba;
                line-height: 13px;
                padding: 0 8px;
                display: inline-block;
                height: 13px;
                // border-right: 1px solid #afb2ba;
              }
              span:nth-child(3) {
                span:nth-child(1) {
                  display: inline-block;
                  height: 11px;
                  line-height: 11px;
                  padding-right: 8px;
                  border-right: 1px solid #afb2ba;
                }
                span:nth-child(2) {
                }
                span {
                }
              }
            }

            .status {
              .state {
                background: #ff7c7c !important;
              }

              span {
                display: inline-block;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 44px;
                height: 18px;
                background: #1677ff;
                border-radius: 5px 0px 5px 0px;
                box-sizing: border-box;

                p {
                  padding: 0;
                  font-size: 10px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #ffffff;
                  -webkit-transform: scale(0.8);
                }
              }
            }
          }
        }

        .edit {
          width: 18.5%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            display: inline-block;
            width: 48px;
            height: 24px;
            line-height: 24px;
            border-radius: 3px;
            margin-left: 16px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }

          span:nth-child(1) {
            background-color: #feb842;
          }

          span:nth-child(2) {
            background-color: #ff6f7b;
          }
        }
      }
    }

    // 企业信息

    .addProduct {
      display: inline-block;
      width: 180px;
      height: 24px;
      line-height: 24px;
      background: #8dacfc;
      border-radius: 3px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      margin: 0 8px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }

    .delete {
      display: inline-block;
      width: 48px;
      height: 24px;
      line-height: 24px;
      background: #f6929b;
      border-radius: 3px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      /* margin: 0 8px; */
      margin-left: 4px;
      cursor: pointer;
    }

    /* // 分页 */
    .page {
      text-align: center;
      padding: 25px 0 50px 0;
    }
  }
}

#container {
  /*地图(容器)显示大小*/
  width: 100%;
  height: 400px;
  /* position: absolute; */
}

.ProductMsg {
  /deep/ .el-form-item__content {
    display: block;
  }
}

/deep/.el-upload--picture-card {
  /* width: 88px;
    height: 88px; */
  border: 0;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 98px;
  height: 98px;
  border-radius: 0;
}

#wk_icon {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #292931;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
  font-weight: 600;
}

.release_status {
  color: #fff;
  font-size: 10px;
}

/deep/.el-form-item {
  margin-bottom: 30px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 15px 20px 20px 5px;

  .left {
    ul {
      display: flex;
      justify-content: start;

      .color {
        color: #1677ff;
      }

      li {
        height: 37px;
        margin: 0 25px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 37px;
        cursor: pointer;
      }
    }
  }

  .right {
    width: 72px;
    height: 32px;
    background: #1677ff;
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding: 6px 8px;
    cursor: pointer;
    box-sizing: border-box;
  }
}

.bottom {
  // 资讯
  ul {
    li {
      width: 100%;
      padding: 20px;
      height: 138px;
      box-sizing: border-box;
      display: flex;
      justify-self: start;

      .img {
        position: relative;
        margin-left: 7px;
        // width: 20%;
        width: 158px;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: pink;
        }

        .state {
          background: #ff7c7c !important;
        }

        span {
          position: absolute;
          top: 0;
          left: 0;
          width: 44px;
          height: 18px;
          line-height: 18px;
          background: #1677ff;
          border-radius: 5px 0px 5px 0px;
          box-sizing: border-box;
          // padding: 2px 5px;

          p {
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            -webkit-transform: scale(0.8);
          }
        }
      }

      .content {
        width: 50%;
        text-align: left;
        padding: 0 19px;
        cursor: pointer;

        p:nth-child(1) {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #292931;
          line-height: 22px;
          margin: 5px 0;
          white-space: nowrap; //强制一行显示
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p:nth-child(2) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #808080;
          line-height: 20px;
          margin: 5px 0;
        }
      }

      .edit {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          display: inline-block;
          width: 48px;
          height: 24px;
          line-height: 24px;
          border-radius: 3px;
          margin-left: 16px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }

        span:nth-child(1) {
          background-color: #feb842;
        }

        span:nth-child(2) {
          background-color: #ff6f7b;
        }
      }
    }
  }

  .editKc {
    display: flex;
    justify-content: center;
    padding-top: 40px;

    span {
      display: inline-block;
      width: 48px;
      height: 24px;
      line-height: 24px;
      border-radius: 3px;
      margin-left: 16px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
    .span1 {
      background-color: green;
    }
    .span2 {
      background-color: #feb842;
    }

    .span3 {
      background-color: #feb842;
    }

    .span4 {
      background-color: #ff6f7b;
    }
  }

  // 文库
  .wk {
    li {
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 30px;

      // height: 120px;
      div {
        width: 80%;
        cursor: pointer;
        .title {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 10px 0;
          justify-content: start;

          .img {
            width: 18px;
            height: 18px;
            border-radius: 3px;
            border: 1px solid #1677ff;
            margin-left: 0;

            img {
              width: 100%;
              height: 100%;
              background: pink;
            }
          }

          p {
            width: 95%;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #24272f;
            line-height: 22px;
            padding: 0 10px 0 6px;
            white-space: nowrap; //强制一行显示
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .state {
            background: #ff7c7c !important;
          }

          span {
            display: inline-block;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 18px;
            background: #1677ff;
            border-radius: 5px 0px 5px 0px;
            box-sizing: border-box;

            p {
              padding: 0;
              font-size: 10px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-transform: scale(0.8);
            }
          }
        }

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #453c3c;
          line-height: 20px;
          text-align: left;
        }
      }

      .edit {
        width: 18.5%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          display: inline-block;
          width: 48px;
          height: 24px;
          line-height: 24px;
          border-radius: 3px;
          margin-left: 16px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }

        span:nth-child(1) {
          background-color: #feb842;
        }

        span:nth-child(2) {
          background-color: #ff6f7b;
        }
      }
    }
  }

  // 招聘
  .zp {
    li {
      height: 98px !important;
      padding: 0 25px;
      display: flex;
      justify-content: space-between;
      height: auto;
      margin-bottom: 5px;

      .left {
        cursor: pointer;
        .top {
          display: flex;
          justify-content: start;
          margin: 18px 0 21px 5px;

          P:nth-child(1) {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1677ff;
            line-height: 22px;
          }
          P:nth-child(2) {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1677ff;
            line-height: 22px;
          }
          P:nth-child(3) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #afb2ba;
            line-height: 17px;
            margin-left: 17px;
          }
        }

        .bottom {
          display: flex;
          align-items: center;

          .span {
            span:nth-child(1) {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ff733c;
              line-height: 22px;
            }

            span:nth-child(n + 2) {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #afb2ba;
              line-height: 13px;
              padding: 0 8px;
              display: inline-block;
              height: 13px;
              border-right: 1px solid #afb2ba;
            }

            span:last-child {
              border: 0;
            }
          }

          .status {
            .state {
              background: #ff7c7c !important;
            }

            span {
              display: inline-block;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 44px;
              height: 18px;
              background: #1677ff;
              border-radius: 5px 0px 5px 0px;
              box-sizing: border-box;

              p {
                padding: 0;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                -webkit-transform: scale(0.8);
              }
            }
          }
        }
      }

      .edit {
        width: 18.5%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          display: inline-block;
          width: 48px;
          height: 24px;
          line-height: 24px;
          border-radius: 3px;
          margin-left: 16px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }

        span:nth-child(1) {
          background-color: #feb842;
        }

        span:nth-child(2) {
          background-color: #ff6f7b;
        }
      }
    }
  }

  // 企业信息

  .addProduct {
    display: inline-block;
    width: 180px;
    height: 24px;
    line-height: 24px;
    background: #8dacfc;
    border-radius: 3px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    margin: 0 8px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }

  .delete {
    display: inline-block;
    width: 48px;
    height: 24px;
    line-height: 24px;
    background: #f6929b;
    border-radius: 3px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin: 0 8px;
    cursor: pointer;
  }

  /* // 分页 */
  .page {
    text-align: center;
    // padding: 25px 0 50px 0;
    padding-bottom: 20px;
  }
}
.explain {
  /deep/ .el-dialog__header {
    // padding: 0;
  }
  /deep/.el-dialog__body {
    min-height: 100px;
    padding-top: 0;
  }
}
.map {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 10px;
  }
}
</style>
