<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <Header></Header>

    <!-- PC端 -->
    <div v-if="show_pc" class="pc">
      <div class="company_box">
        <div class="company">
          <div>
            <p>
              {{ positionInfo.title }} <span>{{ positionInfo.salary }}</span>
            </p>
            <p>
              {{ positionInfo.work_address }}
              · {{ positionInfo.exp_desc }} · {{ positionInfo.edut_desc }}
            </p>
          </div>
          <div @click="ClickCommunicate">简历投递</div>
        </div>
      </div>
      <!-- 职位 -->
      <div class="recruitDetails">
        <!-- 职位详情 -->
        <div class="recruitDetails_left">
          <!-- 职位描述区域 -->

          <div class="describe">
            <!-- 职位描述 文字-->

            <div class="describe_text">
              <p>公司地址</p>
              <span></span>
            </div>

            <!-- 岗位描述 -->
            <div class="post">
              <ul>
                <li>
                  <div>
                    <template>
                      <div v-html="positionInfo.description">
                        {{ positionInfo.description }}
                      </div>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 基本信息 -->
          <div class="infor">
            <div class="infor_text">
              <p>基本信息</p>
              <span></span>
            </div>
            <div class="infors">
              <ul>
                <li>
                  <span>职位类别：</span
                  ><span>{{ positionInfo.work_cate }}</span>
                </li>
                <li>
                  <span>发布时间：</span
                  ><span>{{ positionInfo.create_time }}</span>
                </li>
                <li>
                  <span>所属部门：</span
                  ><span>{{ positionInfo.department }}</span>
                </li>
                <li>
                  <span>汇报对象：</span
                  ><span>{{ positionInfo.report_to }}</span>
                </li>
                <li>
                  <span>工作地点：</span
                  ><span>{{ positionInfo.work_address }}</span>
                </li>
                <li>
                  <span>招聘人数：</span
                  ><span>{{ positionInfo.people_num }}</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 其他要求 -->
          <div class="require">
            <div class="require_text">
              <p>其他要求</p>
              <span></span>
            </div>
            <div class="requires">
              <ul>
                <li>
                  <span>海外经历：</span
                  ><span>{{ positionInfo.overseas_desc }}</span>
                </li>
                <li>
                  <span>年龄要求：</span
                  ><span>{{ positionInfo.age_desc }}</span>
                </li>
                <li>
                  <span>语言要求：</span
                  ><span>{{ positionInfo.lng_desc }}</span>
                </li>
                <li>
                  <span>是否统招：</span
                  ><span>{{ positionInfo.is_enroll == 1 ? "否" : "是" }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 职位推荐 -->
        <div class="recruitDetails_right">
          <!-- 推荐职位 -->
          <div class="posit_text">
            <p>公司基本信息</p>
            <span></span>
          </div>
          <div class="posit_content">
            <ul>
              <li>
                <div>
                  <img
                    :src="positionInfo.logo"
                    alt=""
                    @click="ClickEnterprisesList(positionInfo.business_id)"
                  />
                  <span>{{ positionInfo.business_name }}</span>
                </div>
              </li>
              <li>
                <div>
                  <img src="../../assets/icon/趋势@2x.png" alt="" />
                  <span>{{ positionInfo.is_market }}</span>
                </div>
              </li>
              <li>
                <div>
                  <img src="../../assets/icon/人数@2x.png" alt="" />
                  <span>{{ positionInfo.scale }}</span>
                </div>
              </li>
              <li>
                <div>
                  <img src="../../assets/icon/类型@2x.png" />
                  <span>{{ positionInfo.type_name }}</span>
                </div>
              </li>
              <li>
                <div>
                  <img src="../../assets/icon/网址@2x.png" />
                  <span>{{ positionInfo.web_url }}</span>
                </div>
              </li>
              <li>
                <div>
                  <img :src="positionInfo.logo" alt="" />
                  <span>更新于:&nbsp{{ positionInfo.create_time }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="recruitDetails_mb">
        <div class="company_box">
          <div class="company">
            <div>
              <p>
                <span>{{ positionInfo.title }}</span>
                <span>{{ positionInfo.salary }}</span>
              </p>
              <p>
                {{ positionInfo.work_address }}
                · {{ positionInfo.exp_desc }} · {{ positionInfo.edut_desc }}
              </p>
            </div>
          </div>
        </div>
        <!-- 职位 -->
        <div class="recruitDetails">
          <!-- 职位详情 -->
          <div class="recruitDetails_left">
            <!-- 职位描述区域 -->

            <div class="describe">
              <!-- 职位描述 文字-->

              <div class="describe_text">
                <p>公司地址</p>
                <span></span>
              </div>

              <!-- 岗位描述 -->
              <div class="post">
                <ul>
                  <li>
                    <template>
                      <div v-html="positionInfo.description">
                        {{ positionInfo.description }}
                      </div>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 基本信息 -->
            <div class="infor">
              <div class="infor_text">
                <p>基本信息</p>
                <span></span>
              </div>
              <div class="infors">
                <ul>
                  <li>
                    <span>职位类别：</span
                    ><span>{{ positionInfo.work_cate }}</span>
                  </li>
                  <li>
                    <span>发布时间：</span
                    ><span>{{ positionInfo.create_time }}</span>
                  </li>
                  <li>
                    <span>所属部门：</span
                    ><span>{{ positionInfo.department }}</span>
                  </li>
                  <li>
                    <span>汇报对象：</span
                    ><span>{{ positionInfo.report_to }}</span>
                  </li>
                  <li>
                    <span>工作地点：</span
                    ><span>{{ positionInfo.work_address }}</span>
                  </li>
                  <li>
                    <span>招聘人数：</span
                    ><span>{{ positionInfo.people_num }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 其他要求 -->
            <div class="require">
              <div class="require_text">
                <p>其他要求</p>
                <span></span>
              </div>
              <div class="requires">
                <ul>
                  <li>
                    <span>海外经历：</span
                    ><span>{{ positionInfo.overseas_desc }}</span>
                  </li>
                  <li>
                    <span>年龄要求：</span
                    ><span>{{ positionInfo.age_desc }}</span>
                  </li>
                  <li>
                    <span>语言要求：</span
                    ><span>{{ positionInfo.lng_desc }}</span>
                  </li>
                  <li>
                    <span>是否统招：</span
                    ><span>{{
                      positionInfo.is_enroll == 1 ? "否" : "是"
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- 职位推荐 -->
          <div class="recruitDetails_right">
            <!-- 推荐职位 -->
            <div class="posit_text">
              <p>公司基本信息</p>
              <span></span>
            </div>
            <div class="posit_content">
              <ul>
                <li>
                  <div>
                    <img
                      :src="positionInfo.logo"
                      alt=""
                      @click="ClickEnterprisesList(positionInfo.business_id)"
                    />
                    <span>{{ positionInfo.business_name }}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <img src="../../assets/icon/趋势@2x.png" alt="" />
                    <span>{{ positionInfo.is_market }}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <img src="../../assets/icon/人数@2x.png" alt="" />
                    <span>{{ positionInfo.scale }}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <img src="../../assets/icon/类型@2x.png" />
                    <span>{{ positionInfo.type_name }}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <img src="../../assets/icon/网址@2x.png" />
                    <span>{{ positionInfo.web_url }}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <img :src="positionInfo.logo" alt="" />
                    <span>更新于:&nbsp{{ positionInfo.create_time }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="简历投递说明" :visible.sync="outerVisible">
      <div class="popContainer">
        <div class="communicate">
          <p>
            <span v-if="!positionInfo.email">该企业暂无邮箱地址 </span>
            <span v-if="positionInfo.email" id="email">{{
              positionInfo.email
            }}</span>
          </p>
          <p @click="ClickCopy(positionInfo.email)" v-if="positionInfo.email">
            复制邮箱号
          </p>
        </div>
      </div>
    </el-dialog>
    <!-- <div class="popContainer" v-if="mask">
      <div class="communicate">
        <p>简历投递说明</p>
        <p>
          请将简历投递到邮箱<span v-if="!positionInfo.email"
            >XXXXXXXX.163.@com
          </span>
          <span v-if="positionInfo.email" id="email">{{
            positionInfo.email
          }}</span>
        </p>
        <p @click="ClickCopy(positionInfo.email)">复制邮箱号</p>
        <div class="icon" @click="ClickOut"><i class="el-icon-close"></i></div>
      </div>
    </div> -->

    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/header";
import Footer from "../../components/footer";
export default {
  components: {
    Header,
    Footer,
  },
  metaInfo() {
    return {
      title: this.positionInfo.title
        ? this.positionInfo.title + " | 新税网"
        : window.location.href,

      meta: [
        {
          name: "description",
          content: this.positionInfo.description_meta,
        },
        {
          name: "keywords",
          content: this.positionInfo.title,
        },
        {
          name: "author",
          content: this.positionInfo.business_name + " - 新税网",
        },
      ],
    };
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      outerVisible: false,
      // 遮罩层默认隐藏
      mask: false,

      // 职位id
      id: "",

      // 职位详情
      positionInfo: "",
      fullscreenLoading: false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.id = this.$route.query.id;
    // 委屈职位详情
    this.getPositonDetails();
  },
  methods: {
    // 获取职位详情
    getPositonDetails() {
      this.fullscreenLoading = true;

      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_detail", {
          // params: {
          id: this.id,
          // },
        })
        .then((res) => {
          console.log(res);
          this.fullscreenLoading = false;

          this.positionInfo = res.data;
          console.log(this.positionInfo);
        })
        .catch((error) => {});
    },
    ClickEnterprisesList(id) {
      this.$router.push({ path: "/jxqy/recruit", query: { id: id } });
      console.log(id);
    },

    // 点击立即沟通
    ClickCommunicate() {
      this.outerVisible = true;
    },
    ClickCopy(email) {
      this.$copyText(email).then(
        (e) => {
          this.$message.success("复制成功");
          this.centerDialogVisible = false;
        },
        (e) => {
          this.$message.error("复制失败");
        }
      );
    },
    ClickOut() {
      this.mask = false;
    },
  },
};
</script>
<style lang='less' scoped>
.pc {
  .company_box {
    // padding: 0 10.45%;
    height: 190px;
    text-align: left;
    background: linear-gradient(270deg, #414a5d 0%, #424b61 100%);
    .company {
      width: 1200px;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;
      padding: 50px 0;
      align-items: center;
      div:nth-child(1) {
        //   margin-left: 33px;
        p:nth-child(1) {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          span {
            font-size: 28px;
            font-weight: 600;
            color: #ff733c;
            line-height: 40px;
            margin-left: 50px;
          }
        }
        p:nth-child(2) {
          font-size: 14px;
          font-weight: 400;
          color: #afb2ba;
          line-height: 20px;
          padding: 10px 0;
        }
      }
      div:nth-child(2) {
        width: 296px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background: #1677ff;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        margin-right: 42px;
        cursor: pointer;
      }
    }
  }
  .recruitDetails {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // margin: 0 10.45%;
    // width: 100%;
    .recruitDetails_left {
      width: 66%;
      height: 100%;
      background-color: #fff;
      .describe {
        .describe_text {
          padding: 40px 0 10px;
          p {
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            color: #292931;
            line-height: 22px;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .post {
          text-align: left;
          ul {
            li {
              font-size: 14px;
              font-weight: 400;
              color: #666c7f;
              line-height: 36px;
            }
          }
        }
      }
      .infor {
        .infor_text {
          padding: 20px 0 10px 0;
          p {
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            color: #292931;
            line-height: 22px;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .infors {
          width: 100%;
          ul {
            width: 100%;
            display: flex;
            justify-self: start;
            flex-wrap: wrap;
            li {
              text-align: left;

              width: 40%;
              margin-bottom: 15px;
              span:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                color: #292931;
                line-height: 20px;
              }
              span:nth-child(2) {
                font-size: 14px;
                font-weight: 400;
                color: #666c7f;
                line-height: 20px;
              }
            }
          }
        }
      }
      .require {
        .require_text {
          padding: 20px 0 10px 0;
          p {
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            color: #292931;
            line-height: 22px;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .requires {
          width: 100%;
          ul {
            width: 100%;
            display: flex;
            justify-self: start;
            flex-wrap: wrap;
            margin-bottom: 75px;
            li {
              text-align: left;

              width: 40%;
              margin-bottom: 15px;
              span:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                color: #292931;
                line-height: 20px;
              }
              span:nth-child(2) {
                font-size: 14px;
                font-weight: 400;
                color: #666c7f;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
    .recruitDetails_right {
      width: 33%;
      height: 100%;
      .posit_text {
        padding: 40px 0 10px 0;
        p {
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          color: #292931;
          line-height: 22px;
        }
        span {
          display: block;
          width: 60px;
          height: 3px;
          background: #1677ff;
          margin: 5px 0;
        }
      }
      .posit_content {
        li:nth-child(1) {
          img {
            width: 89px;
            height: 44px;
          }
        }
        li:last-child {
          img {
            width: 0;
            height: 0;
          }
          span {
            margin-left: 0;

            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9ea2ae;
            line-height: 20px;
          }
        }
        li {
          text-align: left;
          padding: 20px 0;
          div {
            display: flex;
            align-items: center;
            img {
              display: inline-block;
              width: 16px;
              height: 16px;
            }
            span {
              font-size: 14px;
              font-weight: 400;
              color: #424b60;
              line-height: 20px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
.recruitDetails_mb {
  padding-top: 60px;
  .company_box {
    // height: 110px;
    text-align: left;
    background: linear-gradient(270deg, #414a5d 0%, #424b61 100%);
    .company {
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 12px 10px;
      div:nth-child(1) {
        width: 100%;
        p:nth-child(1) {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 0;
          span:nth-child(1) {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 28px;
          }

          span:nth-child(2) {
            font-size: 15px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ff733c;
            line-height: 21px;
          }
        }
        p:nth-child(2) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #afb2ba;
          line-height: 17px;
          margin-bottom: 17px;
        }
      }
    }
  }
  .recruitDetails {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    // padding: 10px;
    box-sizing: border-box;
    // margin: 0 10.45%;
    // width: 100%;
    .recruitDetails_left {
      width: 100%;
      height: 100%;
      background-color: #fff;
      padding: 0 10px;
      box-sizing: border-box;
      .describe {
        .describe_text {
          p {
            text-align: left;

            font-size: 15px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #292931;
            line-height: 21px;
            padding: 5px 0;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .post {
          text-align: left;
          margin: 15px 0 20px;
          ul {
            li {
              font-size: 14px;
              font-weight: 400;
              color: #666c7f;
              /deep/ img {
                display: inline-block;
                width: 100%;
              }
            }
          }
        }
      }
      .infor {
        .infor_text {
          padding: 0 0 10px 0;
          p {
            text-align: left;

            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #292931;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .infors {
          width: 100%;
          ul {
            width: 100%;
            display: flex;
            justify-self: start;
            flex-wrap: wrap;
            li {
              text-align: left;

              width: 50%;
              margin-bottom: 15px;
              span:nth-child(1) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #292931;
              }
              span:nth-child(2) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666c7f;
                line-height: 20px;
              }
            }
          }
        }
      }
      .require {
        .require_text {
          padding: 20px 0 10px 0;
          p {
            text-align: left;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #292931;
          }
          span {
            display: block;
            width: 20px;
            height: 3px;
            background: #1677ff;
            margin: 5px 0;
          }
        }
        .requires {
          width: 100%;
          ul {
            width: 100%;
            display: flex;
            justify-self: start;
            flex-wrap: wrap;
            li {
              text-align: left;

              width: 50%;
              margin-bottom: 15px;
              span:nth-child(1) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #292931;
              }
              span:nth-child(2) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666c7f;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
    .recruitDetails_right {
      background-color: #fff;
      padding: 0 10px;
      height: 100%;
      .posit_text {
        padding: 5px 0;
        p {
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          color: #292931;
          line-height: 22px;
        }
        span {
          display: block;
          width: 60px;
          height: 3px;
          background: #1677ff;
          margin: 5px 0;
        }
      }
      .posit_content {
        li:nth-child(1) {
          img {
            width: 89px;
            height: 44px;
          }
        }
        li:last-child {
          img {
            width: 0;
            height: 0;
          }
          span {
            margin-left: 0;

            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9ea2ae;
            line-height: 20px;
          }
        }
        li {
          text-align: left;
          padding-bottom: 20px;
          div {
            display: flex;
            align-items: center;
            img {
              display: inline-block;
              width: 16px;
              height: 16px;
            }
            span {
              font-size: 14px;
              font-weight: 400;
              color: #424b60;
              line-height: 20px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
/deep/.el-dialog__header {
  padding: 40px 20px 10px;
}
/deep/.el-dialog__title {
  font-size: 30px;
}
/deep/.el-icon {
  border: 0;
}
.popContainer {
  .communicate {
    p:nth-child(1) {
      font-size: 20px;
      color: #47463e;
      margin: 50px 0;
      span:nth-child(1) {
        margin-left: 10px;
      }
    }
    p:nth-child(2) {
      color: #587eed;
      font-size: 22px;
      cursor: pointer;
    }
  }
}
</style>
