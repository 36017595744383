<template>
  <!-- 资讯推广页 -->
  <div>
    <!-- PC端 -->
    <div v-if="show_pc" class="pc">
      <Header :indexi="2"></Header>

      <!-- 资讯推广页内容 -->
      <div class="extension">
        <!-- 背景图片 -->
        <div class="image">
          <img src="" alt="" />
        </div>
        <div class="extension_conten">
          <div class="extension_text">
            <p>三大交易所DeFi激战，我们应该押宝哪个平台币？</p>
            <div class="content_top">
              <p>
                十月刚刚进入第一周，比特币一度下跌到10,500美元，但现在已经回升至11,000美元以上，接下来又会发生什么呢？
              </p>
              <p>
                相比于一周前，比特币价格已经有所回升，本文撰写时（10月9日）已经比10月2日上涨近6.5%，达到11,076.70美元，市值也重新回到2000亿美元以上。
              </p>
              <p>
                本次反弹让许多交易者感到惊喜，因为此前多位市场分析师预测比特币价格会有更大回落，就在突破10,500美元支撑位并反弹至高于11,000美元之后，市场情绪开始变得乐观，但也有交易者认为短期内10,500美元仍然是最关键的比特币价格水平，因为该价位代表了当前价格周期和先前价格周期的历史重要支撑位。纵观整个2019年，比特币一直在尝试突破10,500美元峰值，可最终并未如愿，鉴于「10,500美元」是比特币最具代表性的阻力区域，因此也被认为是最强劲的价格支撑位。
              </p>
              <p>
                另一方面，在沉寂多时之后，比特币「巨鲸」最近又开始有所行动。据Whalemap研究人员发现，在10,407美元至10,570美元之间有比特币“巨鲸”出没，这意味着“巨鲸”们可能正在囤币。由于“巨鲸”通常会花更长时间持币，因此10,500美元区域仍是强劲支撑位的可能性很大——从这个角度来看，捍卫10,500美元支撑位对保持比特币上升势头至关重要。
              </p>
            </div>
            <div class="content_img">
              <img src="" alt="" />
            </div>
            <div class="content_bottom">
              <p>
                从中期来看，13,000美元可能是比特币长期反弹的主要阻力位。据比特币交易员“Salsa
                Tekila”分析，按照当前宏观价格趋势看13,000美元或是创造比特币价格历史新高的关键阻力位。“Salsa
                Tekila”表示，如果比特币持续稳定并巩固在关键支撑位以上，从长期来看价格可能会飙升到新高。
              </p>
              <p>
                现在，比特币已经突破11,000美元阻力位，但测试13000美元仍是近期最大挑战。在十月的前五天，各种宏观市场负面因素减缓了比特币上涨势头，但随着Square大举购买比特币消息传出之后，市场开始逐渐复苏。
              </p>
            </div>

            <div class="author">
              <div class="author_top">
                <div class="img">
                  <img src="" alt="" />
                </div>
                <span>作者</span>
              </div>
              <p>
                但无论是哪种类型用户，都一定会有同种共性——好奇心。好奇心足以牵引着一个人对所产生事物的关注程度和好感度，从而使得他由被动接受信息直接转型为主动了解信息
              </p>
              <div class="author_bottom">
                <div>
                  <div class="img"><img src="" alt="" /></div>
                  <span>联系我</span>
                </div>
                <div>
                  <div class="img"><img src="" alt="" /></div>
                  <span>加微信</span>
                </div>
              </div>
            </div>
            <div class="link">生成推广文章</div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <!-- 移动端  -->
    <div v-if="!show_pc" v-loading.fullscreen.lock="fullscreenLoading">
      <Header :indexi="1"></Header>

      <div class="extension_mb">
        <!-- 头部 -->
        <div class="subject">
          <p>{{ infoDetails.title }}</p>
          <div class="funct">
            <div class="funct_left">
              <ul>
                <li>
                  <img src="../../assets/image/时间@2x.png" alt="" />
                  <span>{{ infoDetails.create_time }}</span>
                </li>
                <li>
                  <img src="../../assets/image/眼睛@2x.png" alt="" />
                  <span>{{ infoDetails.view_count }}</span>
                </li>
                <li>
                  <img src="../../assets/image/点赞@2x.png" alt="" />
                  <span>{{ infoDetails.praise_count }}</span>
                </li>
                <li>
                  <img src="../../assets/icon/标签@2x.png" alt="" />
                  <span>{{ infoDetails.cate_name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 文章内容区域 点赞收藏 -->
        <div class="content">
          <!-- 文章内容区域 -->
          <div class="content_more">
            <div v-html="infoDetails.content" class="info">
              <template>
                <div>
                  {{ infoDetails.content }}
                </div>
              </template>
            </div>
            <div class="seemore" v-if="see_more" @click="ClickMore">
              <p>
                <span>({{ infoDetails.price_str }}) 查看更多</span
                ><img src="../../assets/icon/路径 5@2x.png" alt="" />
              </p>
            </div>
          </div>
          <!-- 点赞收藏 -->

          <div class="author_info" v-if="writer_info.headimg">
            <div class="info">
              <div class="img"><img :src="writer_info.headimg" alt="" /></div>
              <p>{{ writer_info.nickname }}</p>
              <div class="introduce">{{ writer_info.description }}</div>
            </div>
            <div
              class="thumbs"
              style="display: flex; justify-content: space-around"
            >
              <el-popover placement="top" width="160" v-model="visible">
                <div
                  style="display: flex; justify-content: start; width: 160px; height: 20px;"
                >
                  <span>{{ writer_info.phone }}</span>
                  <el-button
                    style="line-height: 1px"
                    type="primary"
                    size="mini"
                    @click="ClickCopy(writer_info.phone)"
                    >复制</el-button>
                </div>

                <el-button
                  slot="reference"
                  style="
                    width: 120px;
                    height: 38px;
                    background: #1677ff;
                    border-radius: 25px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                  "
                  >联系我</el-button
                >
              </el-popover>
              <el-popover placement="top" width="60">
                <div style="width: 60px; height: 60px; background-color: pink">
                  <img
                    style="display: block; width: 100%; height: 100%"
                    :src="writer_info.qrcode"
                    alt=""
                  />
                </div>

                <el-button
                  slot="reference"
                  style=""
                  style="
                    width: 120px;
                    height: 38px;
                    border-radius: 25px;
                    border: 1px solid #98c3ff;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    background: #1677ff;
                  "
                  >加微信</el-button
                >
              </el-popover>
            </div>
          </div>
        </div>
        <div class="extension" v-if="ouid == uid">
          <div class="btn" @click="toShare">推广给好友</div>
        </div>

        <el-dialog top="1vh" title="" :visible.sync="dialogVisible" width="30%">
          <div style="width: 100%; margin-left: 15px">
            <img style="width: 100%" src="../../assets/image/分享.png" alt="" />
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../../components/header";
import Footer from "../../components/footer";
import { isWeixin } from "../../utils";
import wx from "weixin-js-sdk"; // 分享提示遮罩层
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      visible: false,
      // 默认显示 PC端的css
      show_pc: true,
      // 是否投票
      // 资讯详情id user_id user_token
      uid: this.$route.query.uid,
      aid: this.$route.query.aid,
      // 资讯详情
      infoDetails: {},
      see_more: false,
      writer_info: {},
      ouid: 0,
      dialogVisible: false,
      fullscreenLoading:false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;

    console.log(this.$route.query.uid);
    console.log(this.$route.query.aid);
    this.uid = this.$route.query.uid;
    this.aid = this.$route.query.aid;
    this.ouid = localStorage.getItem("user_id");

    if (!this.$store.state.show_pc) {
      // 获取资讯详情
      this.fullscreenLoading =true;
      this.getInfoDetails();
    }
    // http://zhihuixiong.oss-cn-beijing.aliyuncs.com/20200311/113054_906153.png
  },
  methods: {
    ClickCopy(phone) {
      this.$copyText(phone).then(
        (e) => {
          console.log(222);

          this.$message.success("复制成功");
          // this.centerDialogVisible = false;
        },
        (e) => {
          this.$message.error("复制失败");
        }
      );
    },
    // 获取资讯详情
    getInfoDetails() {
      this.$http
        .post("/index.php/api/v1_0_0.article/popularize_article_detail", {
          popularize_id: this.uid,
          aid: this.aid,
          notNeed: true,
        })
        .then((res) => {
          console.log("推广文章信息");
          console.log(res);
          this.infoDetails = res.data;
          this.writer_info = res.data.popularize_info;

          //
          if (isWeixin() && this.ouid == this.uid) {
            // var link = window.location.href;
            var link =
              "http://www.xinshui.com/web/#/zx/details/extension?uid=" +
              this.uid +
              "&aid=" +
              this.aid;
            console.log(link);
            // link = 'http://www.xinshui.com/index.php/api/v1_0_0.article/wxredirect?url='+encodeURIComponent(link);
            var title = res.data.title;
            var desc = res.data.description;
            var imgUrl = res.data.cover;

            this.getWxShareConfig(link, title, desc, imgUrl);
          }
        })
        .finally(()=>{
          this.fullscreenLoading = false;
        });
    },

    getWxShareConfig(link, title, desc, imgUrl) {
      this.$http
        .post("index.php/api/v1_0_0.article/do_share", {
          aid: this.aid,
          url: window.location.href,
        })
        .then(({ data: res }) => {
          console.log(res);

          let appid = res.share.appId;
          let timestamp = res.share.timestamp;
          let noncestr = res.share.nonceStr;
          let signature = res.share.signature;
          wx.config({
            debug: false, // 是否开启调试模式
            appId: appid, //appid
            timestamp: timestamp, // 时间戳
            nonceStr: noncestr, // 随机字符串
            signature: signature, // 签名
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"], // 需要使用的
          });
          wx.ready(function () {
            // 分享给好友
            wx.onMenuShareAppMessage({
              title: title, // 分享标题
              desc: desc, // 分享描述
              link: link, // 分享链接
              imgUrl: imgUrl, // 分享图标
              success: function () {
                console.log("分享成功");
              },
              cancel: function () {
                console.log("取消了分享");
              },
            });
            // 分享到朋友圈
            wx.onMenuShareTimeline({
              title: title, // 分享标题
              link: link, // 分享链接
              imgUrl: imgUrl, // 分享图标
              success: function () {
                console.log("分享成功");
              },
              cancel: function () {
                console.log("取消了分享");
              },
            });
          });
          wx.error(function (res) {
            console.log("失败");
            console.log(res);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 去分享
    toShare() {
      // 判断是否微信浏览器
      this.dialogVisible = true;

      if (!isWeixin()) {
        this.$message.error("请在微信中打开此页面");
        return false;
      }
      // this.dialogVisible = true;

      // console.log(isWeixin())
    },
  },
};
</script>
<style lang='less' scoped>
.pc {
  .extension {
    margin: 0 auto;

    .image {
      width: 100%;
      height: 360px;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: pink;
      }
    }
    .extension_conten {
      margin-top: -330px;
      width: 100%;
      height: 2500px;
      position: relative;
      overflow: hidden;
      // background-color: #333;
      .extension_text {
        width: 79.01%;
        position: absolute;
        margin: 0 10.45%;
        padding: 50px 15%;
        box-sizing: border-box;
        text-align: left;
        background-color: #fff;
        display: inline-block;
        p {
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #020202;
          line-height: 42px;
        }
        .content_top {
          p {
            text-align: left;
            padding: 22px 0;
            font-size: 16px;
            font-weight: 400;
            color: #303030;
            line-height: 35px;
          }
        }
        .content_img {
          width: 100%;
          height: 513px;
          margin-bottom: 22px;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: pink;
          }
        }
        .content_bottom {
          text-align: left;
          p {
            padding: 22px 0;
            font-size: 16px;
            font-weight: 400;
            color: #303030;
            line-height: 35px;
          }
        }
        .author {
          width: 100%;
          height: 354px;
          background: #f8f8f9;
          border: 1px solid #979797;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 34px;
          box-sizing: border-box;
          .author_top {
            text-align: center;
            .img {
              margin: 25px 0;
              width: 104px;
              height: 104px;
              img {
                width: 100%;
                height: 100%;
                background-color: pink;
              }
            }
          }
          p {
            height: 95px;
            font-size: 14px;
            font-weight: 400;
            color: #6f717a;
            line-height: 20px;
            margin-top: 14px;
          }
          .author_bottom {
            display: flex;
            div:nth-child(1) {
              width: 120px;
              height: 38px;
              background: #1677ff;
              border-radius: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 20px;
              cursor: pointer;
              .img {
                width: 13px;
                height: 13px;
                margin: 5px;
                img {
                  width: 100%;
                  height: 100%;
                  background-color: pink;
                }
              }
              span {
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
              }
            }
            div:nth-child(2) {
              width: 120px;
              height: 38px;
              background: #1677ff;
              border-radius: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #ffffff;
              border-radius: 25px;
              border: 1px solid #98c3ff;
              margin: 0 20px;
              cursor: pointer;

              .img {
                width: 13px;
                height: 13px;
                margin: 5px;

                img {
                  width: 100%;
                  height: 100%;
                  background-color: pink;
                }
              }
              span {
                font-size: 14px;
                font-weight: 400;
                color: #1677ff;
                line-height: 20px;
              }
            }
          }
        }
        .link {
          width: 400px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #ffffff;
          border-radius: 8px;
          border: 2px solid #177aff;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1677ff;
          margin: 40px auto;
        }
      }
    }
  }
}
.extension_mb {
  /deep/.el-dialog {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
    box-shadow: ;
    background-color: transparent;
    .el-dialog__headerbtn {
      display: none;
    }
    .el-dialog__header {
      padding: 0;
    }
  }
  /*padding-bottom: 91px;*/
  margin-top: 60px;
  .subject {
    padding: 0 10px;
    p {
      text-align: left;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #020202;
      line-height: 25px;
      margin: 10px 0;
    }
    .funct {
      .funct_left {
        ul {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e8e9ee;
          padding-bottom: 8px;
          li {
            display: flex;
            align-items: center;
            margin-right: 15px;
            img {
              display: inline-block;
              width: 14px;
              height: 15px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9a9a9a;
              line-height: 20px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .content {
    margin-top: 7px;
    .content_more {
      min-height: 120px;

      position: relative;
      .info {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 35px;
        position: relative;
        padding: 0 10px;
      }
      .seemore {
        position: absolute;
        bottom: 0px;

        width: 100%;
        height: 120px;
        line-height: 120px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.7) 15%,
          #ffffff 100%
        );
        border-radius: 5px;

        color: #1677ff;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        img {
          display: inline-block;
          width: 13px;
          height: 6px;
          margin-left: 5px;
        }
      }
    }
    .author_info {
      width: 375px;
      /*height: 282px;*/
      background: #f8f8f9;
      padding: 20px;
      box-sizing: border-box;
      .info {
        .img {
          width: 62px;
          height: 62px;
          margin: 0 auto;

          img {
            display: inline-block;
            background-color: pink;

            width: 100%;
            height: 100%;
          }
        }
        p {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #303030;
          line-height: 22px;
          padding: 10px 0;
        }
        .introduce {
          width: 100%;
          /*height: 42px;*/
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6f717a;
          line-height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 2;
        }
      }
      .thumbs {
        margin-top: 30px;
        ul {
          width: 100%;
          display: flex;
          justify-content: space-around;
          li {
            width: 120px;
            height: 38px;
            line-height: 38px;
            background: #1677ff;
            border-radius: 25px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .extension {
    position: relative;
    bottom: 0;
    width: 100%;

    padding: 20px 0;
    .btn {
      margin: 0 auto;
      width: 300px;
      height: 44px;
      line-height: 44px;
      background: #1677ff;
      border-radius: 22px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 2px;
    }
  }
}
</style>
