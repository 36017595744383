// 引入兼容插件
import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import vueSideslip from 'vue-fkl-sideslip'
import 'vue-fkl-sideslip/lib/vue-fkl-sideslip.css'

Vue.use(vueSideslip);

// element-UI库
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

import wcSwiper from 'wc-swiper'
import 'wc-swiper/style.css'
Vue.use(wcSwiper);



import jquery from 'jquery'
window.jquery = window.$ = jquery

// 引入 Froala Editor js file.
require('froala-editor/js/froala_editor.pkgd.min')

require('froala-editor/js/froala_editor.pkgd.min')
require('froala-editor/css/froala_editor.pkgd.min.css')
// require('font-awesome/css/font-awesome.css')
require('froala-editor/css/froala_style.min.css')

import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)


// seo优化插件
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)





// 引入苹方字体样式
import './assets/font/fant.css'

// 引入工具类

// 富文本
import QuillEditor from 'vue-quill-editor'
// 导入富文本css样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 全局导入
Vue.use(QuillEditor)
// 全局样式
import '../src/assets/css/global.css'
// vuex
import store from '@/store/index'

// axios配置请求路径


// import axios from './common/http.interceptor.js'
// // 挂在axios http请求方式
// Vue.prototype.$http = axios



// 引入封装后的 request
import request from "@/tools/request"
Vue.prototype.$http = request

import storage from 'store'
Vue.prototype.$storage = storage



import md5 from 'js-md5'

Vue.prototype.$md5 = md5

// 时间过滤格式化处理
import moment from 'moment'

import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// // 分享组件
import vshare from 'vshare'
Vue.use(vshare)


Vue.filter('formdate', () => {
  return moment().format('YYYY-MM-DD hh:mm:ss')
})

// 视频播放器
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

Vue.use(VideoPlayer)

// m3u8播放
const hls = require("videojs-contrib-hls")

Vue.use(hls)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
  // 添加mounted，不然不会执行预编译
  // mounted() {
  //   document.dispatchEvent(new Event('render-event'));
  // }
}).$mount('#app')
