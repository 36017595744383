<template>
  <!-- 企业公司模块 -->
  <div>
    <div class="pc" v-if="show_pc">
      <div class="company_box">
        <div class="company">
          <div class="img">
            <img :src="img" alt="" />
          </div>
          <div>
            <p>{{ companyName }}</p>
            <p>
              <span>{{ market }}</span> · <span>{{ scale }}</span> ·
              <span>{{ type_name }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!show_pc">
      <div class="company_mb">
        <div class="company_box">
          <div class="company">
            <div class="img">
              <img :src="img" alt="" />
            </div>
            <div>
              <p>{{ companyName }}</p>
              <p>
                <span>{{ market }}</span> · <span>{{ scale }}</span> ·
                <span>{{ type_name }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { img: "", companyName: "", market: "", scale: "", type_name: "" },
  data() {
    return {
      show_pc: true,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
  },
};
</script>
<style lang='less' scoped>
  img{
    object-fit: contain;
  }
.pc {
  .company_box {
    padding: 0 10.45%;
    height: 190px;
    text-align: left;
    background: linear-gradient(270deg, #414a5d 0%, #424b61 100%);
    .company {
      width: 1200px;
      margin: 0 auto;
      padding: 40px 0;
      display: flex;
      justify-self: start;
      .img {
        width: 102px;
        height: 102px;
        border-radius: 8px;
        overflow: hidden;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: #fff;
        }
      }
      div:nth-child(2) {
        margin-left: 33px;
        p:nth-child(1) {
          font-size: 28px;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          margin: 12px 0;
        }
      }
      p:nth-child(2) {
        margin: 12px 0;
        font-size: 14px;
        font-weight: 400;
        color: #afb2ba;
        line-height: 20px;
      }
    }
  }
}
.company_mb {
  .company_box {
    display: flex;
    align-items: center;
    height: 110px;
    text-align: left;
    background: linear-gradient(270deg, #414a5d 0%, #424b61 100%);
    .company {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 0 10px;
      .img {
        width: 49px;
        height: 49px;
        border-radius: 8px;
        overflow: hidden;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: #fff;
        }
      }
      div:nth-child(2) {
        margin-left: 8px;
        p:nth-child(1) {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          padding: 4px 0;
        }
      }
      p:nth-child(2) {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #afb2ba;
        line-height: 17px;
      }
    }
  }
}
</style>
