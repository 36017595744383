<template>
  <!-- 发布资讯 -->
  <div>
    <div v-if="show_pc" v-loading.fullscreen.lock="loading">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <!-- 标题 -->
        <el-form-item label="标题" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入文章标题"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 分类 -->
        <el-form-item label="分类" prop="region">
          <el-select
            v-model="ruleForm.region"
            placeholder="请选择"
            style="width: 100%"
            :disabled="editDisabled"
          >
            <el-option
              v-for="(item, index) in classifyList"
              :key="item.value"
              :label="item.title"
              :value="item.id"
              v-show="index !== 0"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 摘要 -->
        <el-form-item label="摘要" prop="desc" style="position: relative">
          <el-input
            type="textarea"
            :autosize="true"
            v-model="ruleForm.desc"
            placeholder="请输入文章摘要"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 是否收费 -->
        <el-form-item label="是否收费" prop="resource">
          <el-radio-group v-model="ruleForm.resource" :disabled="editDisabled">
            <el-radio label="否"></el-radio>
            <el-radio label="是" style="height: 100%"></el-radio>
            <span style="border: 0"></span>
            <div
              style="
                display: flex;
                position: absolute;
                top: 0px;
                right: 0;
                z-index: 9;
                align-items: center;
              "
              v-if="ruleForm.resource == '是'"
            >
              <span
                style="
                  border: 0;
                  margin-right: 15px;
                  line-height: 28px;
                  font-size: 14px;
                "
                >请选择支付方式</span
              >
              <el-select
                v-model="ruleForm.charge"
                placeholder="请选择"
                style="width: 100px"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="item in chargeList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <span class="money" style="font-size: 14px"
                ><input
                  type="numder"
                  v-model="ruleForm.money"
                  :disabled="editDisabled"
                /><span style="border: 0">{{
                  ruleForm.charge == "积分" ? "分" : "元"
                }}</span></span
              >
            </div>
          </el-radio-group>
        </el-form-item>

        <!-- 来源 -->
        <el-form-item label="来源" prop="source">
          <el-input
            v-model="ruleForm.source"
            placeholder="请输入来源"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 作者 -->
        <el-form-item label="作者" prop="author">
          <el-input
            v-model="ruleForm.author"
            placeholder="请输入"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 附件 -->
        <el-form-item label="附件" prop="accessory">
          <el-upload
            action="https://www.xinshui.com/index.php/api/common.upload/do_upload_file"
            :on-preview="handlePictureCardPreview"
            :on-success="onSuccess"
            :disabled="editDisabled"
            :on-remove="handleRemove1"
            :file-list="fileList"
          >
            <img
              src="../../assets/image/上传附件.png"
              style="width: 88px; height: 88px"
            />
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <!-- 投票 -->
        <el-form-item label="投票" prop="vote">
          <el-input
            v-model="ruleForm.vote"
            placeholder="请输入"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <template v-for="(item, index) in ruleForm.voteSelect">
          <div
            style="
              display: flex;
              color: #89909d;
              padding-left: 120px;
              align-items: center;
              margin-bottom: 15px;
            "
          >
            <p style="font-size: 14px">投票选择</p>
            <el-input
              v-model="item.select"
              style="width: 376px; margin: 0 15px"
              placeholder="请输入"
              :disabled="editDisabled"
            ></el-input>
            <span
              @click="selectAdd(item, index)"
              style="
                width: 33px;
                height: 33px;
                border: 1px solid #dcdfe6;
                line-height: 32px;
              "
              v-if="item.isMinus"
              ><i class="el-icon-minus"></i
            ></span>
            <span
              @click="selectAdd(item, index)"
              style="
                width: 33px;
                height: 33px;
                border: 1px solid #dcdfe6;
                line-height: 32px;
                background-color: #1677ff;
              "
              v-if="!item.isMinus"
              ><i class="el-icon-plus" style="color: #fff"></i
            ></span>
          </div>
        </template>

        <!-- 封面 -->
        <el-form-item label="封面图" prop="cover">
          <el-upload
            :action="uploadUrl"
            :show-file-list="false"
            :on-preview="handlePictureCardPreview"
            :on-success="onSuccess1"
            :disabled="editDisabled"
            :on-remove="handleRemove1"
            :file-list="fileList"
            :on-progress="process"
            :on-change="change"
            :before-upload="beforeAvatarUpload"
          >
            <img :src="ruleForm.fengmian" style="width: 88px; height: 88px" />
          </el-upload>
        </el-form-item>

        <!-- 内容 富文本 -->
        <el-form-item label="内容" prop="free_content">
          <div class="text" style="height: 314px; width: 100%">
            <quill-editor
              class="quill"
              v-model="ruleForm.free_content"
              ref="free_myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              :disabled="editDisabled"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
              style="height: 80%"
            >
            </quill-editor>
            <!-- 图片上传 -->
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :file-list="richImg"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
            >
            </el-upload>

            <!-- 视频上传事件，实现视频上传 -->

            <!-- <input
              type="file"
              accept="video/*"
              name="file"
              ref="uploadFileVideo"
              id="uploadFileVideo"
              @change="changevideo"
              style="opacity: 0; width: 0; height: 0; cursor: pointer"
            /> -->
          </div>
        </el-form-item>

        <div v-if="ruleForm.resource == '是'">
          <el-form-item label="付费内容" prop="content">
            <div class="text" style="height: 314px; width: 100%">
              <quill-editor
                class="quill"
                v-model="ruleForm.content"
                ref="myQuillEditor"
                :options="editorOption2"
                @blur="onEditorBlur($event)"
                :disabled="editDisabled"
                @focus="onEditorFocus($event)"
                @change="onEditorChange($event)"
                style="height: 80%"
              >
              </quill-editor>
              <el-upload
                class="avatar-uploader2"
                :action="uploadUrl"
                :file-list="richImg"
                :show-file-list="false"
                :on-success="uploadSuccess2"
                :on-error="uploadError"
                :before-upload="beforeUpload"
              >
              </el-upload>
              <el-upload
                id="uploadFileVideo2"
                :action="uploadUrl"
                :file-list="richImg"
                :show-file-list="false"
                :on-success="uploadSuccess4"
                :on-error="uploadError"
                :before-upload="beforeUpload"
              >
              </el-upload>
            </div>
          </el-form-item>
        </div>

        <!-- 投票 -->
        <el-form-item label="">
          <div style="display: flex; align-items: center">
            <div style="width: 200px">
              <el-input
                placeholder="请输入图形验证码"
                v-model="ruleForm.imgCodeInput"
                :disabled="editDisabled"
              >
              </el-input>
            </div>
            <div
              style="
                width: 80px;
                heigth: 30px;
                overflow: hidden;
                margin-left: 15px;
                display: flex;
                align-items: center;
              "
              @click="ClickImgCode"
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(225, 225, 225, 0.3)"
            >
              <img
                v-if="!loading"
                style="display: inline-block"
                width="100%"
                height="100%"
                :src="imgCode"
                alt=""
              />
            </div>
          </div>
        </el-form-item>

        <!-- 提交 -->
        <el-form-item>
          <el-button
            type="primary"
            :disabled="editDisabled"
            style="
              margin-left: 0px;
              display: inline-block;
              background-color: #1677ff;
              width: 104px;
              border-radius: 0;
              height: 34px;
              line-height: 4px;
            "
            @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>

      <!-- <froala
        :tag="'textarea'"
        :config="froalaConfig"
        v-model="froalaContent"
      ></froala> -->
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc" id="mobile">
      <Header></Header>
      <div style="width: 100%; height: 90px"></div>
      <div
        class="release_bg"
        :style="{
          backgroundImage:
            'url(https://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20210204/153945_458907.png)',
        }"
        style="margin-bottom: 30px"
      >
        <div style="width: 100%; height: 50px"></div>
        <div style="padding: 10px" v-loading.fullscreen.lock="loading">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="76px"
            style="background-color: #fff; padding: 20px 10px"
          >
            <!-- 标题 -->
            <el-form-item label="标题" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入文章标题"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 分类 -->
            <el-form-item label="分类" prop="region">
              <el-select
                v-model="ruleForm.region"
                placeholder="请选择"
                style="width: 100%"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="item in classifyList"
                  :key="item.value"
                  :label="item.title"
                  :value="item.id"
                  v-show="index !== 0"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!-- 摘要 -->
            <el-form-item label="摘要" prop="desc" style="position: relative">
              <el-input
                type="textarea"
                :autosize="true"
                v-model="ruleForm.desc"
                placeholder="请输入文章摘要"
                style="margin-top: 10px"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 是否收费 -->
            <el-form-item label="是否收费" prop="resource" label-width="90px">
              <el-radio-group
                v-model="ruleForm.resource"
                :disabled="editDisabled"
              >
                <el-radio label="否"></el-radio>
                <el-radio label="是" style="height: 100%"></el-radio>
                <span style="border: 0"></span>
              </el-radio-group>
            </el-form-item>

            <div
              style="
                display: flex;
                align-items: center;
                padding-left: 22.7%;
                margin-top: -4px;
              "
              v-if="ruleForm.resource == '是'"
            >
              <!-- <span style="border: 0;">请选择支付方式</span> -->
              <el-select
                v-model="ruleForm.charge"
                placeholder="请选择支付方式"
                style="margin-top: -8px; width: 60%"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="(item, index) in chargeList"
                  :key="index + 'charge'"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <span
                class="money"
                style="width: 30%; position: relative; margin-left: 10px"
              >
                <input
                  style="width: 70%; text-align: left"
                  type="numder"
                  v-model="ruleForm.money"
                  :disabled="editDisabled"
                />
                <span
                  style="
                    border: 0;
                    position: absolute;
                    top: 0px;
                    right: 8px;
                    font-size: 14px;
                  "
                  >{{ ruleForm.charge == "积分" ? "分" : "元" }}</span
                >
              </span>
            </div>

            <!-- 来源 -->
            <el-form-item label="来源" prop="source">
              <el-input
                v-model="ruleForm.source"
                placeholder="请输入来源"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 作者 -->
            <el-form-item label="作者" prop="author">
              <el-input
                v-model="ruleForm.author"
                placeholder="请输入作者"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 附件 -->
            <el-form-item
              label="附件"
              prop="accessory"
              style="margin-top: 20px"
            >
              <el-upload
                :action="uploadUrl"
                :on-preview="handlePictureCardPreview"
                :on-success="onSuccess"
                :disabled="editDisabled"
                :on-remove="handleRemove1"
                :file-list="fileList"
              >
                <img
                  src="../../assets/image/上传附件.png"
                  style="width: 88px; height: 88px"
                />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>

            <!-- 投票 -->
            <el-form-item label="投票" prop="vote">
              <el-input
                v-model="ruleForm.vote"
                placeholder="请输入"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <template v-for="(item, index) in ruleForm.voteSelect">
              <div
                style="
                  display: flex;
                  color: #89909d;
                  padding-left: 68px;
                  align-items: center;
                  margin-bottom: 15px;
                "
              >
                <p style="font-size: 14px; min-width: 70px">投票选择</p>
                <el-input
                  v-model="item.select"
                  style="width: 376px; margin: 0 15px"
                  placeholder="请输入"
                  :disabled="editDisabled"
                ></el-input>
                <span
                  @click="selectAdd(item, index)"
                  style="
                    min-width: 33px;
                    min-height: 33px;
                    border: 1px solid #dcdfe6;
                    line-height: 33px;
                  "
                  v-if="item.isMinus"
                  ><i class="el-icon-minus"></i
                ></span>
                <span
                  @click="selectAdd(item, index)"
                  style="
                    min-width: 33px;
                    min-height: 33px;
                    border: 1px solid #dcdfe6;
                    line-height: 33px;
                    background-color: #1677ff;
                  "
                  v-if="!item.isMinus"
                  ><i class="el-icon-plus" style="color: #fff"></i
                ></span>
              </div>
            </template>

            <!-- 封面 -->
            <el-form-item label="封面图" prop="cover">
              <el-upload
                :action="uploadUrl"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-success="onSuccess1"
                :disabled="editDisabled"
                :on-remove="handleRemove"
                :file-list="coverList"
                :before-upload="beforeAvatarUpload"
              >
                <!-- ruleForm.fengmian -->
                <img
                  width="100%"
                  :src="ruleForm.fengmian"
                  alt=""
                  style="width: 88px; height: 88px"
                />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>

            <div id="quillBox">
              <el-form-item label="内容" prop="free_content" label-width="90px">
                <div class="text" style="height: 300px; margin-top: 10px">
                  <quill-editor
                    class="quill"
                    v-model="ruleForm.content"
                    ref="myQuillEditor"
                    :options="editorOption"
                    @blur="onEditorBlur($event)"
                    :disabled="editDisabled"
                    @focus="onEditorFocus($event)"
                    @change="onEditorChange($event)"
                    style="height: 80%; text-align: left; line-height: 0"
                  >
                  </quill-editor>
                </div>
              </el-form-item>
            </div>
            <div style="width: 100%; height: 30px"></div>

            <!-- 内容 富文本 -->
            <div id="quillBox" v-if="ruleForm.resource == '是'">
              <el-form-item label="付费内容" prop="content" label-width="90px">
                <div class="text" style="height: 300px; margin-top: 10px">
                  <quill-editor
                    class="quill"
                    v-model="ruleForm.content"
                    ref="myQuillEditor"
                    :options="editorOption2"
                    @blur="onEditorBlur($event)"
                    :disabled="editDisabled"
                    @focus="onEditorFocus($event)"
                    @change="onEditorChange($event)"
                    style="height: 80%; text-align: left; line-height: 0"
                  >
                  </quill-editor>
                </div>
              </el-form-item>
            </div>
            <div style="width: 100%; height: 40px"></div>
            <!-- 提交 -->
            <el-form-item>
              <el-button
                type="primary"
                class="btn"
                :disabled="editDisabled"
                @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
          <div style="width: 100%; height: 60px"></div>
        </div>
      </div>
    </div>

    <el-dialog
      title="视频上传"
      :visible.sync="dialogVideo"
      width="30%"
      :before-close="videoClose"
      :show-close="false"
    >
      <!-- action必选参数, 上传的地址 -->
      <el-upload
        class="avatar-uploader el-upload--text"
        action
        :http-request="handleUpload"
        :show-file-list="false"
        :on-success="handleVideoSuccess"
        :before-upload="beforeUploadVideo"
        :on-progress="uploadVideoProcess"
      >
        <video
          v-if="video_url != '' && videoFlag == false"
          :src="video_url"
          style="width: 100%; height: 200px"
          controls="controls"
        >
          您的浏览器不支持视频播放
        </video>
        <i
          v-else-if="video_url == '' && videoFlag == false"
          class="el-icon-plus avatar-uploader-icon"
        ></i>
        <el-progress
          v-if="videoFlag == true"
          type="circle"
          :percentage="videoUploadPercent"
          style="margin-top: 30px"
        ></el-progress>
      </el-upload>

      <P class="text" style="margin-top: 20px"
        >请保证视频格式正确，且不超过5M</P
      >

      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px; ;
          "
          >视频宽度</span
        ><el-input v-model="video_width" placeholder="视频宽度"></el-input>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px; ;
          "
          >视频宽度</span
        >
        <el-input v-model="video_height" placeholder="视频高度"></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clickCancel">取 消</el-button>
        <el-button type="primary" @click="toUploadVideo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="视频上传"
      :visible.sync="dialogVideos"
      width="30%"
      :before-close="videoClose"
      :show-close="false"
    >
      <!-- action必选参数, 上传的地址 -->
      <el-upload
        class="avatar-uploader el-upload--text"
        action
        :http-request="handleUpload"
        :show-file-list="false"
        :on-success="handleVideoSuccess"
        :before-upload="beforeUploadVideo"
        :on-progress="uploadVideoProcess"
      >
        <video
          v-if="video_url != '' && videoFlag == false"
          :src="video_url"
          style="width: 100%; height: 200px"
          controls="controls"
        >
          您的浏览器不支持视频播放
        </video>
        <i
          v-else-if="video_url == '' && videoFlag == false"
          class="el-icon-plus avatar-uploader-icon"
        ></i>
        <el-progress
          v-if="videoFlag == true"
          type="circle"
          :percentage="videoUploadPercent"
          style="margin-top: 30px"
        ></el-progress>
      </el-upload>

      <P class="text" style="margin-top: 20px"
        >请保证视频格式正确，且不超过5M</P
      >

      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px; ;
          "
          >视频宽度</span
        ><el-input v-model="video_width" placeholder="视频宽度"></el-input>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px; ;
          "
          >视频宽度</span
        >
        <el-input v-model="video_height" placeholder="视频高度"></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clickCancels">取 消</el-button>
        <el-button type="primary" @click="toUploadVideos">确 定</el-button>
      </span>
    </el-dialog>

    <div id="test" @click="test"></div>
    <div id="test2" @click="test2"></div>

    <div id="inputVideoSize">
      <div>
        <span>视频宽度</span>
        <el-input v-model="videoWidth" placeholder="请输入视频宽度"></el-input>
      </div>
      <div>
        <span>视频高度</span>
        <el-input v-model="videoHeight" placeholder="请输入视频高度"></el-input>
      </div>
      <div style="width: 100px; height: 80px">
        <!-- <el-upload
          id="uploadFileVideo"
          :action="uploadUrl"
          :file-list="richImg"
          :show-file-list="false"
          :on-success="uploadSuccess3"
          :on-error="uploadError"
          :before-upload="beforeUpload"
        >
        </el-upload> -->

        <el-upload
          id="uploadFileVideo"
          :action="uploadUrl"
          :file-list="richImg"
          :show-file-list="false"
          :on-success="uploadSuccess3"
          :on-error="uploadError"
          :before-upload="beforeUpload"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <div class="btns">
        <el-button @click="ClickClose">关闭</el-button>
        <el-button type="primary" @click="ClickVideo">确定</el-button>
      </div>
    </div>
    <div id="inputVideoSize2">
      <div>
        <span>视频宽度</span>
        <el-input v-model="videoWidth" placeholder="请输入视频宽度"></el-input>
      </div>
      <div>
        <span>视频高度</span>
        <el-input v-model="videoHeight" placeholder="请输入视频高度"></el-input>
      </div>
      <div class="btns">
        <el-button @click="ClickClose">关闭2</el-button>
        <el-button type="primary" @click="ClickVideo2">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../header.vue";
import { mapState, mapMutations } from "vuex";
import VideoBlot from "../../utils/video";
import * as Quill from "quill";
import { client, getFileNameUUID } from "../../utils/ali-oss";
Quill.register(VideoBlot);

export default {
  components: {
    Header,
  },
  data() {
    var charge = (rule, value, callback) => {
      if (this.ruleForm.resource == "是" && this.ruleForm.charge == "") {
        callback(new Error("请选择支付方式"));
        return;
      }
      if (
        this.ruleForm.resource == "是" &&
        (this.ruleForm.money == "" || this.ruleForm.money < 0)
      ) {
        callback(new Error("请输入金额"));
        return;
      }
      callback();
    };
    return {
      video_width: "100%",
      video_height: "100px",
      video_url: "",
      videoFlag: false,
      videoUploadPercent: 0,
      select_index: 0,
      dialogVideo: false,

      // 视频宽
      videoWidth: "",
      // 视频高
      videoHeight: "",
      dialogVideos: false,
      // imgCodeInput: "",
      // 图形验证码加载...
      loading: false,
      coverList: [], //编辑封面预览
      fileList: [], //编辑附件预览
      chargeList: ["积分", "余额"], // 支付方式
      classifyList: [], // 分类列表
      //   上传图片路径
      dialogImageUrl: "",
      //   是否显示弹出框
      dialogVisible: false,

      //   富文本
      richImg: [],
      editorOption: {
        placeholder: "",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              // ['blockquote', 'code-block'],     //引用，代码块
              [
                { header: 1 },
                {
                  header: 2,
                },
              ], // 标题，键值对的形式；1、2表示字体大小
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ], //列表
              //[{ script: "sub" }, { script: "super" }], // 上下标
              [
                {
                  indent: "-1",
                },
                {
                  indent: "+1",
                },
              ], // 缩进
              [
                {
                  direction: "rtl",
                },
              ], // 文本方向
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
              // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
              // [{ 'align': [] }],    //对齐方式
              ["clean"], //清除字体样式
              ["image", "video"], //上传图片、上传视频
            ],
            handlers: {
              video: function (val) {
                if (val) {
                  document.querySelector("#test").click();

                  // 劫持原来的视频点击按钮事件
                  // document.querySelector("#inputVideoSize").style.display =
                  //   "block";
                } else {
                  this.quill.format("video", false);
                }
              },

              image: function (value) {
                if (value) {
                  // upload点击上传事件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      editorOption2: {
        placeholder: "",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              // ['blockquote', 'code-block'],     //引用，代码块
              [
                { header: 1 },
                {
                  header: 2,
                },
              ], // 标题，键值对的形式；1、2表示字体大小
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ], //列表
              //[{ script: "sub" }, { script: "super" }], // 上下标
              [
                {
                  indent: "-1",
                },
                {
                  indent: "+1",
                },
              ], // 缩进
              [
                {
                  direction: "rtl",
                },
              ], // 文本方向
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
              // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
              // [{ 'align': [] }],    //对齐方式
              ["clean"], //清除字体样式
              ["image", "video"], //上传图片、上传视频
            ],
            handlers: {
              video: function (val) {
                if (val) {
                  // 劫持原来的视频点击按钮事件
                  document.querySelector("#test2").click();
                  // document.querySelector("#inputVideoSize2").style.display = "block";
                } else {
                  this.quill.format("video", false);
                }
              },
              image: function (value) {
                if (value) {
                  // upload点击上传事件
                  document.querySelector(".avatar-uploader2 input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },

      ruleForm: {
        imgCodeInput: "",

        fujian: "",
        fengmian: require("../../assets/image/上传图片.png"),
        charge: "",
        source: "新税网",
        desc: "",
        content: "请先输入上面的内容",
        free_content: "",
        money: "",
        name: "",
        region: "",
        delivery: false,
        type: [],
        resource: "否",
        author: "",
        vote: "",
        voteSelect: [
          {
            select: "",
            isMinus: false,
          },
        ],
      },
      rules: {
        source: [
          {
            required: true,
            message: "请输入来源",
            trigger: "change",
          },
        ],
        yzm: [
          {
            required: true,
            message: "请输入图形验证码",
            trigger: "change",
          },
        ],
        cover: [
          {
            required: true,
            message: "请上传封面图片",
            trigger: "change",
          },
        ],
        content: [
          {
            required: true,
            message: "请编辑付费课程详情",
            trigger: "blur",
          },
        ],
        free_content: [
          {
            required: true,
            message: "请编辑免费课程详情",
            trigger: "blur",
          },
          {
            min: 100,
            message: "不能低于100字",
            trigger: "blur",
          },
        ],
        resource: [
          {
            required: true,
            validator: charge,
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        author: [
          {
            required: true,
            message: "请输入作者",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择文章分类",
            trigger: "change",
          },
        ],

        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
      },
      user: "",
      isTid: false,
      tid: "",
      show_pc: true,
      // 控制是否禁止编辑
      editDisabled: false,
    };
  },
  computed: {
    ...mapState(["uploadUrl"]),
  },
  created() {
    // 获取图片地址
    this.getImgCode();
    // document.querySelector('body').style.backgroundColor = "#f5f5f5"
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    //获取分类列表
    this.$http
      .get("/index.php/api/v1_0_0.article/get_cate_list", {
        notNeed: true,
      })
      .then(({ data }) => {
        console.log(data, "分类");
        this.classifyList = data.list;
      })
      .catch((error) => {});

    //获取编辑内容详情
    this.tid = this.$route.query.tid;
    if (this.tid) {
      this.loading = true;
      this.editDisabled = this.$store.state.editDisabled;

      this.$http
        .post("/index.php/api/v1_0_0.consumer/article_detail", {
          tid: this.tid,
        })
        .then((data) => {
          if (data.code == 200) {
            console.log(data.data);
            this.isTid = true; //设置为编辑状态

            let ruleForm = this.ruleForm;

            ruleForm.name = data.data.title;
            ruleForm.region = data.data.cid;
            ruleForm.desc = data.data.description;
            ruleForm.charge = data.data.unit == "元" ? "余额" : data.data.unit;
            ruleForm.resource = data.data.is_pay == 1 ? "否" : "是";
            ruleForm.money =
              data.data.price == "￥免费"
                ? 0
                : data.data.unit == "元"
                ? data.data.price.split("￥")[1]
                : data.data.price;
            ruleForm.source = data.data.com_from;
            ruleForm.content = data.data.content;
            ruleForm.free_content = data.data.free_content;
            this.editDisabled = data.data.status == 1 ? true : false;
            //添加图片预览 取消当前上传验证
            this.coverList.push({
              url: data.data.cover,
            });

            if (data.data.file != "") {
              console.log(data.data.file, "data.data.cover");
              this.fileList.push({
                name: data.data.file_name,
              });
              ruleForm.fujian = data.data.file;
            }
            this.rules.cover = "";
            ruleForm.fengmian = data.data.cover;
            ruleForm.author = data.data.user_name;
            ruleForm.vote = data.data.votes.vote_data.title;
            ruleForm.voteSelect = [];
            data.data.votes.vote_data.answer.map((item, index) => {
              ruleForm.voteSelect.push({
                select: item.title,
                isMinus: true,
              });
            });
            ruleForm.voteSelect[ruleForm.voteSelect.length - 1].isMinus = false;

            this.loading = false;
          }
        })
        .catch((error) => {});
      return;
    }
  },
  methods: {
    /**
     * 自定义上传方法
     */
    handleUpload(option) {
      let that = this;
      async function multipartUpload() {
        const fileName = getFileNameUUID();
        //定义唯一的文件名，打印出来的uid其实就是时间戳
        //client 是第一步中的 client
        client()
          .multipartUpload("xinshui/" + fileName, option.file, {
            progress: function (p) {
              //获取进度条的值
              // console.log(p)
              that.videoFlag = true;
              // console.log(file.percentage)
              var pro = p * 100;
              that.videoUploadPercent = pro.toFixed(0) * 1;
            },
          })
          .then((result) => {
            //下面是如果对返回结果再进行处理，根据项目需要
            // https://xinshuibucket.oss-cn-beijing.aliyuncs.com/1612356926822_9ac83937
            that.videoFlag = false;
            that.videoUploadPercent = 0;
            that.video_url =
              "https://xinshuibucket.oss-cn-beijing.aliyuncs.com/" +
              result.name;
          })
          .catch((err) => {
            console.log("err:", err);
            this.$message.error("视频上传失败，请重新上传！");
          });
      }
      multipartUpload();
    },

    // 视频上传成功
    handleVideoSuccess(res, file) {
      //获取上传图片地址
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      console.log(res);
      if (res.code == 200) {
        this.video_url = res.data.file_url;
      } else {
        this.$message.error("视频上传失败，请重新上传！");
      }
    },

    // 视频上传之前
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 5;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过5MB哦!");
        return false;
      }
    },

    // 视频上传进度
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      // console.log(file.percentage)
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },

    // 确定添加视频
    toUploadVideo() {
      var quill = this.$refs.free_myQuillEditor.quill;

      this.dialogVideo = false;

      // return false;
      console.log(this.select_index);
      quill.insertEmbed(this.select_index, "simpleVideo", {
        url: this.video_url,
        controls: "controls",
        width: this.video_width,
        height: this.video_height,
      });
    },
    toUploadVideos() {
      var quill = this.$refs.myQuillEditor.quill;

      this.dialogVideos = false;

      // return false;
      console.log(this.select_index);
      quill.insertEmbed(this.select_index, "simpleVideo", {
        url: this.video_url,
        controls: "controls",
        width: this.video_width,
        height: this.video_height,
      });
    },
    // 取消
    clickCancel() {
      this.dialogVideo = false;
      this.video_url = "";
    },
    clickCancels() {
      this.dialogVideos = false;
      this.video_url = "";
    },

    test() {
      // 记录此时的位置
      var quill = this.$refs.free_myQuillEditor.quill;
      this.select_index = quill.getSelection().index;
      this.video_url = "";

      // 展示视频弹框
      this.dialogVideo = true;
    },
    test2() {
      // 记录此时的位置
      var quill = this.$refs.myQuillEditor.quill;
      this.select_index = quill.getSelection().index;

      // 展示视频弹框
      this.video_url = "";
      this.dialogVideos = true;
    },

    open() {
      this.$alert("这是一段内容", "标题名称", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      });
    },
    videoClose() {},
    // 图片处理
    uploadSuccess(res) {
      var dt = {};
      dt.url = res.data.file_url;
      let quill = this.$refs.free_myQuillEditor.quill;

      console.log(quill);
      // 如果上传成功
      if (res.code === 200 && dt.url !== null) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  dt.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", dt.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      // loading加载隐藏
      document.querySelector("#inputVideoSize").style.display = "none";
    },
    // 图片处理
    uploadSuccess2(res) {
      console.log("成功");
      console.log(res.data.file_url, "res");
      var dt = {};
      dt.url = res.data.file_url;
      let quill = this.$refs.myQuillEditor.quill;

      console.log(quill);
      // 如果上传成功
      if (res.code === 200 && dt.url !== null) {
        console.log("插入图片");
        console.log(dt.url);

        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  dt.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", dt.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      // loading加载隐藏
      this.quillUpdateImg = false;
    },
    uploadSuccess3(res) {
      console.log(res);
      var dt = {};
      dt.url = res.data.file_url;
      if (res.code === 200 && dt.url !== null) {
        this.ClickVideo(dt);
      } else {
        this.$message.error("视频插入失败");
      }
    },
    uploadSuccess4(res) {
      var dt = {};
      dt.url = res.data.file_url;

      let quill = this.$refs.myQuillEditor.quill;
      if (res.code === 200 && dt.url !== null) {
        let length = quill.getSelection().index;
        quill.insertEmbed(length, "simpleVideo", {
          url: dt.url,
          controls: "controls",
          width: this.videoWidth + "px",
          height: this.videoHeight + "px",
        });

        quill.setSelection(length + 1);
      } else {
        this.$message.error("视频插入失败");
      }
    },
    video() {
      // window.document.querySelector("#uploadFileVideo input").click();
    },
    //视频上传事件
    ClickVideo(dt) {
      let quill = this.$refs.free_myQuillEditor.quill;
      let length = quill.getSelection().index;
      quill.insertEmbed(length, "simpleVideo", {
        url: dt.url,
        controls: "controls",
        width: this.videoWidth + "px",
        height: this.videoHeight + "px",
      });

      quill.setSelection(length + 1);
    },
    ClickVideo2() {
      window.document.querySelector("#uploadFileVideo2 input").click();
    },
    ClickClose() {
      document.querySelector("#inputVideoSize").style.display = "none";
      document.querySelector("#inputVideoSize2").style.display = "none";
    },
    // 上传失败钩子函数
    uploadError(res) {
      console.log("失败");
      console.log(res);
    },
    // 上传前钩子函数
    beforeUpload() {},

    change() {
      this.loading = false;
    },
    process() {
      this.loading = true;
    },
    selectAdd(item, index) {
      //投票选择删除 添加

      if (!item.isMinus) {
        this.ruleForm.voteSelect.push({
          select: "",
          isMinus: false,
        });
        this.ruleForm.voteSelect[index].isMinus = true;
        return;
      } else {
        this.ruleForm.voteSelect.splice(index, 1);
        return;
      }
    },
    // 获取图片地址
    getImgCode() {
      // 图形验证码加载...
      this.loading = true;
      this.$http
        .post("index.php/api/v1_0_0.ordinary/get_verify", {})
        .then((res) => {
          this.loading = false;

          this.imgCode = res.data.verify;
          this.key = res.data.key;
        })
        .catch(() => {});
    },
    // 刷新图形验证码
    ClickImgCode() {
      this.getImgCode();
    },
    // 图片上传格式
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/png" || "image/jpeg" || "image/bmp" || "image/gif";

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG、PNG、bmp、gif格式!");
      }

      return isJPG;
    },
    //表单提交
    submitForm(formName) {
      this.loading = true;

      console.log(this.ruleForm.charge == "余额", this.ruleForm.money);
      // return;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let answer = [];
          this.ruleForm.voteSelect.map((item, index) => {
            answer.push(item.select);
          });
          this.$http
            .post("index.php/api/v1_0_0.ordinary/check", {
              verify_code: this.ruleForm.imgCodeInput,
              key: this.key,
            })
            .then(() => {
              this.loading = false;

              this.$http
                .post("/index.php/api/v1_0_0.article/publish_article", {
                  tid: this.tid,

                  publish_data: {
                    cid: this.ruleForm.region,
                    user_name: this.ruleForm.author,
                    com_from: this.ruleForm.source,
                    title: this.ruleForm.name,
                    description: this.ruleForm.desc,
                    content:
                      this.ruleForm.content == "请先输入上面的内容"
                        ? ""
                        : this.ruleForm.content,
                    free_content: this.ruleForm.free_content,
                    cover: this.ruleForm.fengmian,
                    file: this.ruleForm.fujian,
                    is_pay: this.ruleForm.resource == "是" ? "2" : "1", //是否收费 1免费 2付费
                    pay_type: this.ruleForm.charge == "余额" ? "2" : "1", //支付方式 1积分 2余额
                    price:
                      this.ruleForm.charge == "余额"
                        ? this.ruleForm.money * 100
                        : this.ruleForm.money,
                    vote: {
                      //投票数据
                      title: this.ruleForm.vote,
                      answer: answer,
                    },
                  },
                })
                .then((data) => {
                  console.log(data);
                  this.$message.success(data.msg);
                  this.$router.push({
                    path: "/personal/content",
                    query: {
                      tabs: "资讯",
                    },
                  });
                  return;
                })
                .catch((error) => {});
            })
            .catch((error) => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          this.loading = false;

          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onSuccess(response, file, fileList) {
      console.log(response.data.file_url, this.fileList);
      this.ruleForm.fujian = response.data.file_url;
    },
    onSuccess1(response, file, fileList) {
      console.log(response.data.file_url, "123");
      this.ruleForm.fengmian = response.data.file_url;
      this.$refs.ruleForm.clearValidate("cover");
      this.rules.cover = "";
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      if (fileList.length < 1) {
        this.rules.cover = [
          {
            required: true,
            message: "请上传封面图片",
            trigger: "change",
          },
        ];
      }
    },
    handleRemove1(file, fileList) {},
    handlePictureCardPreview(file) {
      console.log(file, "123");
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    onEditorReady(editor) {}, // 准备编辑器
    onEditorBlur(e) {
      console.log(e, "e");
    }, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {}, // 内容改变事件
    openTitle() {
      this.$confirm(
        "用户会员身份不同，所付费价格不同，不同身份会员，收益价格不同",
        "提示",
        {
          confirmButtonText: "知道了",
          cancelButtonText: "取消",
          showCancelButton: false,
          type: "warning",
        }
      )
        .then(() => {})
        .catch(() => {});
    },
  },
  watch: {
    "ruleForm.resource": {
      handler(value) {
        if (value == "是" && !this.editDisabled) {
          this.openTitle();
        }
      },
    },
  },
};
</script>
<style lang='less' scoped>
body {
  background-color: #f5f5f5;
}

#mobile {
  #quillBox {
    /deep/ .el-form-item__error {
      padding-top: 35px !important;
    }
  }
  /deep/.el-input__icon {
    line-height: 33px;
  }
  /deep/.el-form-item__label {
    padding-right: 15px;
  }
  /deep/.el-form-item__error {
    padding-top: 3px;
  }

  /deep/.el-input {
    margin-top: 8px;
  }

  .release_bg {
    width: 100%;
    height: 180px;

    background: #f1f5f6;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    /* padding: 120px 0 50px; */
  }

  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    /* line-height: 44px; */
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

/* 上传按钮 */
/deep/.el-upload--picture-card {
  width: 88px;
  height: 88px;
  border: 0;
}

.money {
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid #e5e6ea;
  padding: 0 10px;
  margin-left: 20px;
  height: 32px;
  line-height: 32px;

  input {
    width: 74px;
    /* height: 34px; */
    background: #ffffff;
    outline: none;
    border: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #4f5764;
  }
}

.color {
  color: #ffff;
}

.bgB {
  background-color: #1677ff;
}

span {
  display: inline-block;
  height: 24px;
}

i {
  p {
    font-size: 14px;
    font-weight: 400;
    color: #999da4;
    line-height: 20px;
  }
}

#inputVideoSize,
#inputVideoSize2 {
  width: 500px;
  height: 200px;
  background-color: #ccc;
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  padding: 0px 20px 20px;
  align-items: center;
  justify-content: center;
  display: none;

  div {
    display: flex;
    align-items: center;
    height: 34px;
    padding-top: 20px;

    span {
      display: inline-block;
      width: 100px;
      height: 34px;
      line-height: 34px;
      font-size: 14px;
      text-align: leftm;
    }
    .el-input {
      padding-top: 0;
    }
  }
  .btns {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
/deep/.avatar-uploader {
  // width: 50px;
  // margin: 0 auto;
}
/deep/.el-dialog__body {
  .el-input {
    // margin-top: 20px;
  }
}
</style>



<style >
.ql-snow .ql-tooltip {
  position: absolute !important;
  left: 25% !important;
  top: 30% !important;
  margin-top: 0 !important;
}
</style>
