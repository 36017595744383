<template>
  <div>
    <!-- /index/login/wx -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    console.log("/index/login/wx 页面 mounted");
    // console.log("wantTo " + localStorage.getItem("wantTo"));

    // 兼容以前
    let user_id = this.$route.params.user_id;
    let user_token = this.$route.params.user_token;

    // let user_id = "604";
    // let user_token = "0e853a3d1c5e27a57dcf5356b4a7aeab";

    if (user_id && user_token) {
      this.do200(user_id, user_token);
      return false;
    }

    let pid = this.$route.query.pid;
    if (pid) {
      pid *= 1;
    } else {
      pid = 0;
    }

    // 是否存在 code(用户授权后可以获取)
    let code = this.getQueryVariable("code");
    console.log("code:" + code);

    if (code) {
      this.get_id_token_by_code(code, pid);
    } else {
      // TODO
      console.log('组装请求去拿code信息');
      return false;

      let url_get_wx_code = 'https://open.weixin.qq.com/connect/oauth2/authorize';
      let appid = 'wx86bd522fce27abe9';
      let baseUrl = encodeURIComponent(window.location.href);

      let url =
        url_get_wx_code +
        "?appid=" +
        appid +
        "&redirect_uri=" +
        baseUrl +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
      window.location.href = url;
    }
  },
  methods: {

    // 通过code获取 用户信息
    get_id_token_by_code(code, pid) {
      // TODO 后台准备接口
      console.log('去微信授权登录');
    },

    // 200逻辑
    do200(user_id, user_token) {
      // 保存 user_id 和 user_token
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("user_token", user_token);

      let wantTo = localStorage.getItem("wantTo");

      if (!wantTo || wantTo.indexOf("#/index/login/wx") !== -1) {
        wantTo = "./";
      }

      console.log("登录完毕 wantTo:" + wantTo);
      window.location.href = wantTo;
    },

    // 获取 get 参数 的 value
    getQueryVariable(key) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == key) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>
<style scoped>
</style>
