<template>
  <div>
    <!-- PC端 -->
    <div v-if="show_pc">
      <!-- 轮播图 广告区域 -->
      <div class="contents">
        <!-- 轮播广告区域 -->

        <div class="banner">
          <!-- <div class="x_banner"></div> -->
          <!-- 左侧轮播 -->
          <div class="left">
            <el-carousel
              :interval="5000"
              v-if="bannerList.length"
              arrow="always"
              height="400px"
            >
              <el-carousel-item
                v-for="(item, index) in bannerList"
                :key="item.id"
              >
                <template>
                  <div class="img" @click="ClickBanner(item.target_url)">
                    <img :src="item.img_url" alt="" />
                    <p>{{ item.title }}</p>
                  </div>
                </template>
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- 右侧广告 -->
          <div class="right">
            <div class="top">
              <span @click="ClickMiniBanner(zsgk.target_url)">
                <div class="img">
                  <img :src="zsgk.img_url" v-if="zsgk.img_url" alt="" />
                </div>
                <p>{{ zsgk.title }}</p>
              </span>
              <span @click="ClickMiniBanner(ysgk.target_url)">
                <div class="img">
                  <img :src="ysgk.img_url" v-if="ysgk.img_url" alt="" />
                </div>
                <p>{{ ysgk.title }}</p>
              </span>
            </div>
            <div
              class="buttom"
              v-if="yzgk.img_url"
              @click="ClickMiniBanner(yzgk.target_url)"
            >
              <div class="img">
                <img :src="yzgk.img_url" alt="" />
              </div>
              <p>{{ yzgk.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="banner_md">
        <wc-swiper>
          <wc-slide v-for="(item, key) in bannerList" :key="key">
            <div style="height: 188px" @click="ClickBanner(item.img_url)">
              <img
                style="display: inline-block; height: 188px; width: 100%"
                :src="item.img_url"
                alt=""
              />
            </div>
          </wc-slide>
        </wc-swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { log } from "util";
export default {
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 轮播图广告
      bannerList: [],

      // 右侧三个 及中间一个广告数据
      // 左上
      zsgk: "",
      // 右上
      ysgk: "",
      // 右中
      yzgk: "",
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;

    this.getLeft();
    this.getRight();
  },
  methods: {
    getLeft() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.banner/get_banner";
      let data = this.$storage.get(storageKey);
      if (data) {
        console.log();
        this.bannerList = data.list;
      }

      // 左侧大广告数据请求
      this.$http
        .get("/index.php/api/v1_0_0.banner/get_banner", { notNeed: true })
        .then((res) => {
          this.bannerList = res.data.list;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {
          console.log(11);
        });
    },
    getRight() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.banner/get_advert";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.zsgk = data.list[0];
        this.ysgk = data.list[1];
        this.yzgk = data.list[2];
      }

      // 右侧三个 及中间一个广告数据请求
      this.$http
        .get("/index.php/api/v1_0_0.banner/get_advert", { notNeed: true })
        .then((res) => {
          this.zsgk = res.data.list[0];
          this.ysgk = res.data.list[1];
          this.yzgk = res.data.list[2];
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {});
    },

    // 广告图页面跳转
    ClickBanner(url) {
      if (url) {
        // _blank打开新页面
        window.open(url, "_blank");
      }
    },
    // 右侧广告页面跳转
    ClickMiniBanner(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.contents {
  // margin: 70px 10.41%;

  margin: 0 auto 0;
  width: 1200px;
  .banner {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // margin-bottom: 15px;
    .left {
      /deep/.el-carousel__indicators--horizontal {
        left: 90%;
        transform: translateX(5%);
      }

      /deep/.el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }
      width: 66.6%;
      height: 400px;
      .img {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
        p {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 12px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 300px;
        margin: 0;
      }
      .el-carousel {
        border-radius: 5px;
        height: 100%;
      }
      .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
      }

      .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
      }
    }
    .right {
      width: 32.7%;
      height: 400px;
      // margin: 0 0.25% 0 0%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        height: 48.5%;
        display: flex;
        justify-content: space-between;
        span {
          display: inline-block;
          width: 49%;
          height: 100%;
          // background-color: pink;
          position: relative;
          .img {
            width: 100%;
            height: 100%;
            border-radius: 5px;

            overflow: hidden;
            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
          p {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 30px;
            line-height: 30px;
            // background: rgba(0, 0, 0, 0.3);
            padding: 0 5px;
            border-radius: 0 0 5px 5px;
            box-sizing: border-box;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        margin-bottom: 1.5%;
      }
      .buttom {
        width: 100%;
        height: 49.5%;

        position: relative;
        .img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          overflow: hidden;
          img {
            display: inline-block;
            width: 100%;
            /*height: 100%;*/
          }
        }
        p {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 30px;
          box-sizing: border-box;
          line-height: 30px;
          background: rgba(0, 0, 0, 0.3);
          padding: 0 5px;
          border-radius: 0 0 5px 5px;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}
// 修改轮播图 选中小白点的样式
/deep/.el-carousel__indicator.is-active button {
  width: 20px !important;
}
.banner_md {
  width: 100%;

  margin-top: 60px;
  /deep/.wc-pagination {
    bottom: 0;
    justify-content: flex-end;

    padding-right: 8px;
    box-sizing: border-box;
  }
  /deep/.wc-dot {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    margin: 0 2px;

    background: #ffffff;
    opacity: 0.5;
  }
  /deep/ .wc-dot-active {
    background-color: #fff;
    width: 10px;
    opacity: 1;
  }
}
</style>
