<template>
  <div>
    <!-- 作者专栏组件 -->
    <div class="author">
      <div class="top">
        <p>专栏作者</p>
        <p @click="ClickMore">
          <img src="../assets/icon/更多@2x.png" />
        </p>
      </div>
      <div class="bottom">
        <ul>
          <li
            v-for="(item, index) in authorList"
            :key="index"
            @click="ClickAuthorDetail(item.writer_id)"
          >
            <div><img :src="item.headimg" alt="" /></div>
            <p>{{ item.nickname }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 作者列表
      authorList: [],
    };
  },
  created() {
    // 获取作者列表
    this.getAuthorList();
  },
  methods: {
    // 获取作者列表
    getAuthorList() {
      let storageKey = 'storageKey:/index.php/api/v1_0_0.writer/writer_list'
      let data = this.$storage.get(storageKey);
      if (data) {
        this.authorList = data.list
      }

      this.$http
        .post("/index.php/api/v1_0_0.writer/writer_list", {
          // params: {
          page: 1,
          page_size: 8,
          is_recommend: 1,
          // },
        })
        .then((res) => {
          this.authorList = res.data.list;
          this.$storage.set(storageKey, res.data)
        })
        .catch((error) => {});
    },

    // 点击更多作者
    ClickMore() {
      this.$router.push("/zl");
    },

    // 跳转活动详情页面
    ClickAuthorDetail(id) {
      console.log(id);
      this.$router.push({ path: "/zl/teacherList", query: { id: id } });
      location.reload();
    },
  },
};
</script>
<style lang='less' scoped>
.author {
  background-color: #fafafa;
  padding: 20px 20px 10px 20px;
  margin-bottom: 10px;
  .top {
    display: flex;
    justify-content: space-between;
    height: 24px;
    line-height: 24px;
    p:nth-child(1) {
      border-bottom: 4px solid #1677ff;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 700;
    }
    p:nth-child(2) {
      width: 16px;
      height: 16px;
      margin-top: 3px;
      // overflow: hidden;
      cursor: pointer;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .bottom {
    margin-top: 25px;
    ul {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      li {
        display: inline-block;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        div {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          // background-color: pink;
          overflow: hidden;
          margin-bottom: 8px;
          cursor: pointer;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
        p {
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #292931;
          line-height: 20px;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
