  <template>
  <!--  <div v-loading.fullscreen.lock="fullscreenLoading">-->
  <div>
    <Header :indexi="2"></Header>

    <!-- 广告组件 -->
    <Banner></Banner>

    <!-- PC端  -->
    <!-- 专栏作者推荐 -->
    <div v-if="show_pc" v-loading="fullscreenLoading">
      <div class="zl">
        <div class="author_box">
          <!-- 作者推荐 -->
          <div class="author_tj">
            <p>专栏作者推荐</p>
            <div></div>
          </div>

          <!-- 作者列表 -->
          <div class="authors_data">
            <div
              class="authors"
              v-for="(item, index) in authorsList"
              :key="index"
              @click="ClickTeacherList(item.writer_id)"
            >
              <div class="image"><img :src="item.headimg" alt="" /></div>

              <p>{{ item.nickname }}</p>
              <p>{{ item.description }}</p>
            </div>
          </div>

          <!-- page 分页 -->
          <!-- 底部分页 -->
          <div class="page" v-if="total > 20">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="queryInfo.pagenum"
              :page-size="queryInfo.pagesize"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <div class="library">
          <Library></Library>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc" v-loading="fullscreenLoading">
      <div class="zl_md">
        <div class="author_box">
          <!-- 作者推荐 -->
          <div class="author_tj">
            <span></span>

            <p>专栏作者推荐</p>
            <div></div>
          </div>

          <!-- 作者列表 -->
          <div class="authors_data">
            <div
              class="authors"
              v-for="(item, index) in authorsList"
              :key="index"
              @click="ClickTeacherList(item.writer_id)"
            >
              <div class="image"><img :src="item.headimg" alt="" /></div>
              <p>{{ item.nickname }}</p>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
        <div
          class="more"
          v-if="authorsList.length !== total"
          @click="ClickMore"
        >
          加载更多
        </div>
        <div class="more" v-if="authorsList.length == total">没有更多了...</div>
      </div>
    </div>
    <!-- 底部组件 -->

    <Footer></Footer>
  </div>
</template>


<script>
import Header from "../components/header";
import Banner from "../components/banner";
import Library from "../components/library";
import Footer from "../components/footer";

export default {
  components: {
    Header,
    Banner,
    Footer,
    Library,
  },
  metaInfo: {
    title: "新税网 - 专栏",
    meta: [
      // {
      //   name: "description",
      //   content: "这是专栏页面",
      // },
      {
        name: "keywords",
        content: "新税网-专栏",
      },
    ],
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 查询作者列表参数
      queryInfo: {
        // 查询相关作者
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 20,
      },
      // 总条数
      total: 0,

      // 作者列表
      authorsList: [],
      fullscreenLoading: false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 专栏作者列表
    this.getSpecialList();
  },
  methods: {
    // 专栏作者列表
    getSpecialList() {
      this.fullscreenLoading = true;
      this.$http
        .get("/index.php/api/v1_0_0.writer/writer_list", {
          params: {
            page: this.queryInfo.pagenum,
            page_size: this.queryInfo.pagesize,
            is_recommend: 1,
          },
          notNeed: true,
        })
        .then((res) => {
          this.fullscreenLoading = false;
          this.authorsList = res.data.list;
          this.total = res.data.total_count;
          console.log(res.data);
        })
        .catch((error) => {});
    },

    // 点击老师跳转到老师列表页面
    ClickTeacherList(id) {
      this.$router.push({ path: "/zl/teacherList", query: { id: id } });
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;

      console.log(newSize);
      // this.getGoodsList();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      // this.getGoodsList();
      console.log(newNum);
    },
    // 移动端加载更多
    ClickMore() {
      this.this.queryInfo.pagenum++;
      this.$http
        .get("/index.php/api/v1_0_0.writer/writer_list", {
          params: {
            page: this.queryInfo.pagenum,
            page_size: this.queryInfo.pagesize,
            is_recommend: 1,
          },
          notNeed: true,
        })
        .then((res) => {
          res.data.list.forEach((item) => {
            this.authorsList.push(item);
          });
          this.total = res.data.total_count;
          console.log(res.data);
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang='less' scoped>
.zl {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // margin: 0 10.45%;
  height: auto;
  margin-top: 24px;
  .author_box {
    width: 66.7%;
    height: 100%;
    .author_tj {
      height: 80px;
      text-align: left;
      padding-top: 26px;
      box-sizing: border-box;
      background-color: #fff;
      p {
        font-size: 24px;
        font-weight: 600;
        color: #292931;
        line-height: 37px;
        margin-bottom: 3px;
      }
      // div {
      //   width: 68px;
      //   height: 5px;
      //   background: #1677ff;
      //   margin-left: 18px;
      // }
    }
    .authors_data {
      width: 100%;
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      margin-bottom: 85px;
      .authors {
        width: 158px;
        height: auto;
        margin: 30px 2% 0;
        cursor: pointer;

        .image {
          margin-left: 19px;
          width: 120px;
          height: 120px;
          border-radius: 60px;
          overflow: hidden;

          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            // background-color: pink;
          }
        }

        p:nth-child(2) {
          margin: 16px 0 8px 0;

          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #292931;
          line-height: 25px;
          text-align: center;
        }
        p:nth-child(3) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #797987;
          line-height: 20px;
          text-align: center;
          overflow: hidden; //强制多行显示
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
  .library {
    width: 32.4%;
    height: 100%;
  }

  .page {
    width: 100%;
    text-align: center;
    padding-top: 50px;
  }
}
.zl_md {
  min-height: 50vh;
  .author_box {
    .author_tj {
      display: flex;
      align-items: center;
      margin-top: 9px;
      height: 44px;
      padding: 0 10px;
      span {
        display: inline-block;
        width: 2px;
        height: 14px;
        background: #1677ff;
      }
      p {
        margin-left: 2px;
        letter-spacing: 1px;
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        // font-weight: 600;
        color: #292931;
        line-height: 21px;
        font-weight: 700;
      }
    }
    .authors_data {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      padding-left: 20px;
      // justify-content: space-between;
      .authors:nth-child(1) {
        margin-left: 0;
      }
      .authors {
        display: flex;
        flex-direction: column;
        align-items: center;
        // width: 33.33%;
        width: 100px;
        margin: 15px 18px 0 0;
        .image {
          width: 60px;
          height: 60px;
          border-radius: 60px;
          overflow: hidden;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
        p:nth-child(2) {
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #292931;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          letter-spacing: 1px;
        }
        p:nth-child(3) {
          width: 100%;
          height: 35px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #797987;
          line-height: 17px;
          overflow: hidden; //强制多行显示
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          letter-spacing: 1px;
        }
      }
    }
  }
  .more {
    width: 128px;
    height: 36px;
    line-height: 36px;
    background: #ffffff;
    margin: 24px auto 20px;
    border: 2px solid #e5e6ea;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #555c69;
  }
}
</style>
