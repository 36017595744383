<template>
  <div>
    <div
      v-loading.fullscreen.lock="fullscreenLoading"
      class="pc"
      v-if="show_pc"
    >
      <Header></Header>
      <div class="release_box">
        <div
          class="release_bg"
          :style="{ backgroundImage: 'url(' + mgUrl + ')' }"
        >
          <div class="release">
            <div class="release_left">
              <p>专栏作家认证</p>
              <p>认证说明：认证专栏作者是需要您如实填写信息并且提供作家证明</p>
            </div>
            <div class="release_rigth">
              <div
                style="margin-bottom: 30px; color: #f56c6c; cursor: pointer"
                @click="goApprove"
              >
                {{ content }}
              </div>
              <div style="margin-bottom: 30px; color: #f56c6c; cursor: pointer">
                {{ content1 }}
              </div>
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
              >
                <!-- 专栏名称 -->
                <el-form-item label="专栏名称" prop="column">
                  <!-- <el-input v-model="ruleForm.column" placeholder="请输入专栏名称" :disabled="disabled"></el-input> -->

                  <el-select
                    v-model="ruleForm.column"
                    placeholder="请选择专栏名称"
                    style="width: 100%"
                    :disabled="disabled"
                  >
                    <el-option
                      v-for="item in nameList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <!-- 姓名 -->
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入姓名"
                    :disabled="true"
                  ></el-input>
                </el-form-item>

                <!-- 联系方式 -->
                <el-form-item label="联系方式" prop="contactWay">
                  <el-input
                    v-model="ruleForm.phone"
                    placeholder="请输入联系电话"
                    :disabled="disabled"
                  ></el-input>
                  <span style="margin: 0 8px"></span>
                  <el-input
                    v-model="ruleForm.wx"
                    placeholder="请输入微信号"
                    :disabled="disabled"
                  ></el-input>
                </el-form-item>

                <!-- 作品 -->

                <!-- <div class="worksB">
                    <el-form-item label="作品" prop="workss">
                      <template v-for="(item,index) in ruleForm.works">
                        <div class="works">
                          <el-input v-model="item.select" style="margin-right: 15px;" placeholder="请输入作品链接"></el-input>
                          <span @click="selectAdd(index)" style="width: 33px;height: 33px;border: 1px solid #DCDFE6;line-height: 32px;"
                            :class="[item.bgB]"><i :class="[item.className,item.color]"></i></span>
                        </div>
                      </template>

                    </el-form-item>
                  </div> -->

                <div class="worksB">
                  <el-form-item label="作品" prop="workss">
                    <template v-for="(item, index) in ruleForm.works">
                      <div style="">
                        <div class="works">
                          <el-input
                            v-model="item.select"
                            style="margin-right: 15px"
                            placeholder="请输入作品链接"
                            :disabled="disabled"
                          ></el-input>
                          <span
                            @click="selectAdd(item, index)"
                            style="
                              width: 33px;
                              height: 33px;
                              border: 1px solid #dcdfe6;
                              line-height: 32px;
                            "
                            v-if="item.isMinus"
                            :disabled="disabled"
                            ><i class="el-icon-minus"></i
                          ></span>
                          <span
                            @click="selectAdd(item, index)"
                            style="
                              width: 33px;
                              height: 33px;
                              border: 1px solid #dcdfe6;
                              line-height: 32px;
                              background-color: #1677ff;
                            "
                            v-if="!item.isMinus"
                            :disabled="disabled"
                            ><i class="el-icon-plus" style="color: #fff"></i
                          ></span>
                        </div>
                      </div>
                    </template>
                    <!-- <el-upload :action="uploadUrl" list-type="picture-card" :on-preview="handlePictureCardPreview"
                        :disabled="disabled" :on-change="licenseChange" :on-success='licenseImg' :on-remove="licenseRemove">
                        <div class="uploadClass">
                          <i class="el-icon-plus" style="font-size: 36px;margin-top: 8px;color: #D8D8D8;"></i>
                          <p style="margin-top: 14px;font-size: 14px;">上传作品文档</p>
                        </div>
                      </el-upload> -->
                  </el-form-item>
                </div>

                <!-- 提交 -->
                <el-form-item>
                  <el-button
                    type="primary"
                    style="
                      margin-left: 20px;
                      display: inline-block;
                      background-color: #1677ff;
                      width: 114px;
                      border-radius: 0;
                      height: 34px;
                      line-height: 4px;
                      margin-top: 0px;
                    "
                    @click="isSubmitForm('ruleForm')"
                    :disabled="disabled"
                    >{{ btnText }}</el-button
                  >
                  <div class="agreement">
                    <el-checkbox v-model="checked"></el-checkbox
                    ><span @click="ClickAgreement"
                      >请勾选同意《作家认证协议》</span
                    >
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
      <el-dialog
        :title="agreementInfo.title"
        :visible.sync="agreementVisible"
        width="50%"
        top="20vh"
      >
        <span v-if="!loading" v-html="agreementInfo.content">{{
          agreementInfo.content
        }}</span>
        <div
          v-if="loading"
          v-loading="loading"
          style="width: 100%; height: 100%"
        ></div>
      </el-dialog>
    </div>

    <div
      id="mobile"
      v-if="!show_pc"
      v-loading.fullscreen.lock="fullscreenLoading"
    >
      <Header></Header>
      <div style="width: 100%; height: 90px"></div>
      <div
        class="release_bg"
        style="
          backgroundimage: url(http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201218/201345_452966.png);
          margin-bottom: 30px;
        "
      >
        <div style="width: 100%; height: 50px"></div>
        <div style="padding: 10px">
          <div
            class="release_box"
            style="background-color: #fff; padding: 10px"
          >
            <!-- <div class="release_bg" :style="{ backgroundImage: 'url(' + mgUrl + ')' }"> -->
            <div class="release">
              <!-- <div class="release_left">
              <p>专栏作者认证</p>
              <p>认证说明：认证专栏作者是需要您如实填写信息井且捉供作家证明</p>
            </div> -->
              <div class="release_rigth">
                <div
                  style="margin-bottom: 20px; color: #f56c6c; cursor: pointer"
                  @click="goApprove"
                >
                  {{ content }}
                </div>
                <div
                  style="margin-bottom: 20px; color: #f56c6c; cursor: pointer"
                >
                  {{ content1 }}
                </div>
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="88px"
                >
                  <!-- 专栏名称 -->
                  <el-form-item label="专栏名称" prop="column">
                    <!-- <el-input v-model="ruleForm.column" placeholder="请输入专栏名称" :disabled="disabled"></el-input> -->

                    <el-select
                      v-model="ruleForm.column"
                      placeholder="请选择专栏名称"
                      style="width: 100%"
                      :disabled="disabled"
                    >
                      <el-option
                        v-for="item in nameList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <!-- 姓名 -->
                  <el-form-item label="姓名" prop="name">
                    <el-input
                      v-model="ruleForm.name"
                      placeholder="请输入姓名"
                      :disabled="true"
                      style="margin-top: 8px"
                    ></el-input>
                  </el-form-item>

                  <!-- 联系方式 -->
                  <div class="lxfs">
                    <el-form-item label="联系方式" prop="contactWay">
                      <div style="margin-top: 8px">
                        <el-input
                          v-model="ruleForm.phone"
                          placeholder="请输入联系电话"
                          :disabled="disabled"
                        ></el-input>
                      </div>
                      <div style="margin-top: 8px">
                        <el-input
                          v-model="ruleForm.wx"
                          placeholder="请输入微信号"
                          :disabled="disabled"
                        ></el-input>
                      </div>
                      <!-- <span style="margin: 0 8px;"></span> -->
                    </el-form-item>
                  </div>

                  <!-- 作品 -->

                  <!-- <div class="worksB">
                  <el-form-item label="作品" prop="workss">
                    <template v-for="(item,index) in ruleForm.works">
                      <div class="works">
                        <el-input v-model="item.select" style="margin-right: 15px;" placeholder="请输入作品链接"></el-input>
                        <span @click="selectAdd(index)" style="width: 33px;height: 33px;border: 1px solid #DCDFE6;line-height: 32px;"
                          :class="[item.bgB]"><i :class="[item.className,item.color]"></i></span>
                      </div>
                    </template>

                  </el-form-item>
                </div> -->

                  <div class="worksB">
                    <el-form-item label="作品" prop="workss">
                      <template v-for="(item, index) in ruleForm.works">
                        <div style="margin-top: 8px">
                          <div class="works">
                            <el-input
                              v-model="item.select"
                              style="margin-right: 15px"
                              placeholder="请输入作品链接"
                              :disabled="disabled"
                            ></el-input>
                            <span
                              @click="selectAdd(item, index)"
                              style="
                                min-width: 33px;
                                height: 33px;
                                border: 1px solid #dcdfe6;
                                line-height: 32px;
                              "
                              v-if="item.isMinus"
                              :disabled="disabled"
                              ><i class="el-icon-minus"></i
                            ></span>
                            <span
                              @click="selectAdd(item, index)"
                              style="
                                min-width: 33px;
                                height: 33px;
                                border: 1px solid #dcdfe6;
                                line-height: 32px;
                                background-color: #1677ff;
                              "
                              v-if="!item.isMinus"
                              :disabled="disabled"
                              ><i class="el-icon-plus" style="color: #fff"></i
                            ></span>
                          </div>
                        </div>
                      </template>
                      <!-- <el-upload :action="uploadUrl" list-type="picture-card" :on-preview="handlePictureCardPreview"
                      :disabled="disabled" :on-change="licenseChange" :on-success='licenseImg' :on-remove="licenseRemove">
                      <div class="uploadClass">
                        <i class="el-icon-plus" style="font-size: 36px;margin-top: 8px;color: #D8D8D8;"></i>
                        <p style="margin-top: 14px;font-size: 14px;">上传作品文档</p>
                      </div>
                    </el-upload> -->
                    </el-form-item>
                  </div>

                  <!-- 提交 -->
                  <el-form-item>
                    <el-button
                      type="primary"
                      class="btn"
                      @click="isSubmitForm('ruleForm')"
                      :disabled="disabled"
                      >{{ btnText }}</el-button
                    >
                  </el-form-item>
                </el-form>
                <div class="agreement">
                  <el-checkbox v-model="checked"></el-checkbox
                  ><span @click="ClickAgreement"
                    >请勾选同意《作家认证协议》</span
                  >
                </div>
                <div style="width: 100%; height: 100px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        :title="agreementInfo.title"
        :visible.sync="agreementVisible"
        width="50%"
        top="5vh"
      >
        <span v-if="!loading" v-html="agreementInfo.content">{{
          agreementInfo.content
        }}</span>
        <div
          v-if="loading"
          v-loading="loading"
          style="width: 100%; height: 100%"
        ></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    //自定义校验
    var contactWay = (rule, value, callback) => {
      if (this.ruleForm.phone.length > 11 || this.ruleForm.phone.length < 7) {
        callback(new Error("请输入正确的联系电话"));
        return;
      }
      if (this.ruleForm.wx == "") {
        callback(new Error("请输入联系微信"));
        return;
      }

      callback();
    };
    let workss = (rule, value, callback) => {
      var patt = /http[s]{0,1}:\/\/([\w.]+\/?)\S*/;

      let isIpt = this.ruleForm.works.every((item, index) => {
        return item.select != "";
      });
      if (!isIpt) {
        callback(new Error("作品链接不能为空"));
        return;
      }
      let isIpts = this.ruleForm.works.every((item, index) => {
        console.log(patt.test(item.select));
        return patt.test(item.select);
      });
      console.log(isIpts);
      if (!isIpts) {
        callback(new Error("作品链接前缀应为http/https"));
      }
      callback();
    };
    return {
      checked: "",
      agreementInfo: "",
      agreementVisible: false,
      loading: false,

      nameList: [],
      scaleList: ["0-50", "50-100", "100-200", "200以上"],

      // 背景图片
      mgUrl:
        "http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201214/095440_327691.png",

      chargeList: ["积分", "余额"], // 支付方式
      //   上传图片路径
      dialogImageUrl: "",
      //   是否显示弹出框
      dialogVisible: false,

      ruleForm: {
        column: "",
        name: "",
        wx: "",
        license: [],
        phone: "",
        works: [
          // 作品链接
          {
            isMinus: false,
            select: "",
          },
        ],
      },
      rules: {
        workss: [
          {
            required: true,
            // message: "请输入专栏名称",
            validator: workss,
            trigger: "blur",
          },
        ],
        column: [
          {
            required: true,
            message: "请选择专栏名称",
            trigger: "change",
          },
        ],
        genre: [
          {
            required: true,
            message: "请选择企业类型",
            trigger: "change",
          },
        ],
        url: [
          {
            required: true,
            message: "请输入企业网址",
            trigger: "blur",
          },
        ],

        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        contactWay: [
          {
            required: true,
            validator: contactWay,
            trigger: "blur",
          },
        ],
      },
      classifyList: [],
      // 默认第一个发布开 和 显示
      i: 0,
      user: "",
      content: "",
      content1: "",
      isIMGindex: 0,
      btnText: "提交",
      disabled: false,
      fullscreenLoading: false,
      show_pc: true,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.fullscreenLoading = true;
    //获取专栏列表
    this.$http
      .post("/index.php/api/v1_0_0.article/get_theme_list", {})
      .then(({ data }) => {
        this.nameList = data.list;
        // console.log(data,'data')
      })
      .catch((error) => {});
    //获取用户信息
    this.$http
      .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
      .then(({ data }) => {
        if (data.is_real == 0) {
          this.$confirm(
            "您还没有实名认证, 申请作者认证需要实名认证?",
            "实名认证提示",
            {
              confirmButtonText: "去认证",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.$router.push({
                path: "/personal/user",
                query: {
                  tabs: 1,
                },
              });
            })
            .catch(() => {});

          this.content = "您还没有实名认证，去认证 >";
          return;
        }
        if (data.is_real == 2) {
          this.content = "您的实名认证还在审核中";
          return;
        }
        if (data.is_real == 3) {
          this.content = "您的实名认证被驳回，去重新提交认证 >";
          return;
        }
      })
      .catch((error) => {});

    this.$http
      .post("/index.php/api/v1_0_0.consumer/get_writer_info", {})
      .then((data) => {
        this.fullscreenLoading = false;
        console.log(data, "data");
        if (data.data.lx_name == "") {
          return;
        }
        let ruleForm = this.ruleForm;
        ruleForm.name = data.data.lx_name;
        if (typeof data.data.is_writer == "undefined") {
          return;
        }

        if (data.data.is_writer == 0) return;

        if (data.data.is_writer == 1) {
          this.btnText = "修改";
        }
        if (data.data.is_writer == 2) {
          console.log(data.data.is_writer, "data.data.is_writer");
          (this.btnText = "审核中"), (this.disabled = true);
        }

        ruleForm.wx = data.data.lx_weixin;
        ruleForm.phone = data.data.lx_phone;
        ruleForm.column = data.data.theme;

        if (data.data.file.length >= 1) {
          ruleForm.works = [];
          data.data.file.map((item, index) => {
            ruleForm.works.push({
              isMinus: true,
              select: item,
            });
          });
        }

        ruleForm.works[ruleForm.works.length - 1].isMinus = false;

        if (data.data.is_writer == 3) {
          this.$confirm(
            "您的专栏作者认证未通过: " + data.data.remark,
            "专栏认证审核驳回",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {})
            .catch(() => {});

          this.content1 = data.data.remark + "，请重新填写资料提交认证";
          this.btnText = "重新提交";
          this.disabled = false;
        }
      })
      .catch((error) => {});
  },
  computed: {
    ...mapState(["uploadUrl"]),
  },
  methods: {
    // IMGclick(index){
    //   this.isIMGindex=index
    // },
    ClickAgreement() {
      this.agreementVisible = true;
      this.loading = true;
      this.$http
        .post("index.php/api/v1_0_0.ordinary/get_user_writer", {})
        .then(({ data }) => {
          this.agreementInfo = data;
          this.loading = false;
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goApprove() {
      this.$router.push({
        path: "/personal/user",
        query: {
          tabs: 1,
        },
      });
    },
    selectAdd(item, index) {
      //选择删除 添加
      console.log(index);
      if (!item.isMinus) {
        this.ruleForm.works.push({
          select: "",
          isMinus: false,
        });
        this.ruleForm.works[index].isMinus = true;
        return;
      } else {
        this.ruleForm.works.splice(index, 1);
        return;
      }
    },

    licenseImg(response) {
      this.ruleForm.works.license = response.data.file_url;
    },
    async isSubmitForm(formName) {
      console.log(this.ruleForm);
      let isValidate = await this.$refs[formName]
        .validate()
        .catch((error) => {});
      if (isValidate) {
        if (this.btnText == "修改") {
          this.$confirm("修改信息需要重新审核，确定要修改吗？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((res) => {
              this.submitForm(formName);
            })
            .catch((res) => {
              this.$message({
                type: "info",
                message: "已取消修改",
              });
            });
        } else {
          this.submitForm(formName);
        }
      }
    },
    submitForm(formName) {
      let licenseURl = [];
      this.ruleForm.works.map((item, index) => {
        licenseURl.push(item.select);
      });
      if (!this.checked) {
        this.$message.error("请勾选作者认证协议");
        return;
      }
      this.$http
        .post("/index.php/api/v1_0_0.consumer/writer_auth", {
          auth_data: {
            theme: this.ruleForm.column, //企业名称
            lx_name: this.ruleForm.name, //联系人姓名
            lx_weixin: this.ruleForm.wx, //联系人微信
            lx_phone: this.ruleForm.phone, //联系人手机号
            file: this.ruleForm.works, //array 作品链接
            license: this.ruleForm.licenseURl, //文档
          },
        })
        .then((data) => {
          this.$message.success(data.msg);
          this.$router.go(-1);
        })
        .catch((error) => {});
    },
    //LOGO验证
    licenseChange(file, fileList) {
      this.$refs.ruleForm.rules.LOGO = [];
    },
    licenseRemove(file, fileList) {},
    handlePictureCardPreview() {},
    handleRemove() {},
  },
  mounted() {},
};
</script>
<style lang='less' scoped>
/deep/.el-dialog__title {
  font-weight: 600;
}
#mobile {
  /deep/.el-dialog__body {
    height: 80vh;
    overflow: auto;
  }
  .agreement {
    // margin: 27px 0 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    margin-left: 15px;
    span {
      margin-left: 5px;
      cursor: pointer;
      color: #1677ff;
      font-size: 12px;
    }
  }
  .lxfs {
    /deep/.el-form-item__content {
      display: "";
    }
  }
  /deep/.el-form-item__label {
    padding-right: 10px;
  }
  .release_bg {
    width: 100%;
    height: 180px;

    background: #f1f5f6;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    /* padding: 120px 0 50px; */
  }

  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    /* line-height: 44px; */
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  .worksB {
    /deep/ .el-form-item__content {
      display: flex;
      flex-direction: column;
    }
  }

  .works {
    color: #89909d;
    align-items: center;
    /* margin-bottom: 15px; */
    display: flex;
    /* flex-direction: column; */
    width: 100%;

    /* /deep/ .el-form-item__content{
        display: none;
      } */
  }

  /deep/.el-radio-group {
    height: 20px;
  }

  .license {
    /deep/ .el-upload--picture-card {
      width: 158px;
      height: 108px;
      background: #fafafa;
      border: 1px solid #d9dce2;
    }
  }

  .bigLogo {
    margin-left: 20px;

    /deep/ .el-upload--picture-card {
      width: 158px;
      height: 108px;

      background: #fafafa;
      border: 1px solid #d9dce2;
    }
  }

  /deep/ .el-upload--picture-card {
    width: 108px;
    height: 108px;

    background: #fafafa;
    border: 1px solid #d9dce2;
  }

  .uploadClass {
    color: #999da4;
    line-height: 17px;
    font-size: 12px;

    p {
      line-height: 20px;
    }
  }

  .release_box {
    .release_bg {
      /* width: 100%;
        height: auto;
        background: #F1F5F6;
        background-size: 100% 270px;
        background-repeat: no-repeat;
        padding: 120px 0 50px;
 */
      .release {
        margin: auto 17%;
        // width: 1100px;
        background-color: #fff;
        display: flex;
        justify-content: start;
        padding: 50px 54px 0 32px;

        .release_left {
          width: 26.73%;
          padding: 0 32px 0 0;
          /* margin-top: 14px; */

          p:nth-child(1) {
            color: #1677ff;
            margin-bottom: 12px;
          }

          p:nth-child(2) {
            font-size: 10px;

            color: #878e9b;
            line-height: 17px;
          }
        }
      }
    }
  }

  .color {
    color: #ffff;
  }

  .bgB {
    background-color: #1677ff;
  }
}
.pc {
  /deep/.el-dialog__body {
    height: 500px;
    overflow: auto;
  }
  .worksB {
    /deep/ .el-form-item__content {
      display: flex;
      flex-direction: column;
    }
  }

  .works {
    color: #89909d;
    align-items: center;
    margin-bottom: 15px;
    display: flex;
    /* flex-direction: column; */
    width: 100%;

    /* /deep/ .el-form-item__content{
        display: none;
      } */
  }
  .agreement {
    // margin: 27px 0 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    margin-left: 15px;
    span {
      margin-left: 5px;
      cursor: pointer;
      color: #1677ff;
    }
  }

  /deep/.el-radio-group {
    height: 20px;
  }

  .license {
    /deep/ .el-upload--picture-card {
      width: 158px;
      height: 108px;
      background: #fafafa;
      border: 1px solid #d9dce2;
    }
  }

  .bigLogo {
    margin-left: 20px;

    /deep/ .el-upload--picture-card {
      width: 158px;
      height: 108px;

      background: #fafafa;
      border: 1px solid #d9dce2;
    }
  }

  /deep/ .el-upload--picture-card {
    width: 108px;
    height: 108px;

    background: #fafafa;
    border: 1px solid #d9dce2;
  }

  .uploadClass {
    color: #999da4;
    line-height: 17px;
    font-size: 12px;

    p {
      line-height: 20px;
    }
  }

  .release_box {
    .release_bg {
      width: 100%;
      height: auto;
      background: #f1f5f6;
      background-size: 100% 270px;
      background-repeat: no-repeat;
      padding: 120px 0 50px;

      .release {
        margin: 0 auto;
        width: 1100px;
        background-color: #fff;
        display: flex;
        justify-content: start;
        padding: 50px 54px 0 32px;

        .release_left {
          width: 26.73%;
          padding: 0 32px 0 0;
          margin-top: 14px;

          p:nth-child(1) {
            color: #1677ff;
            margin-bottom: 12px;
          }

          p:nth-child(2) {
            font-size: 10px;

            color: #878e9b;
            line-height: 17px;
          }
        }
      }

      .release_rigth {
        width: 73.18%;
        border-left: 1px solid #e5e6ea;
        margin-top: 13px;
        padding: 0 20px 10px;
      }
    }
  }

  .color {
    color: #ffff;
  }

  .bgB {
    background-color: #1677ff;
  }
}
</style>
