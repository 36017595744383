<template>
  <div>
    <Header></Header>
    <!-- PC端 -->
    <div v-if="show_pc">
      <div
        class="login"
        :style="{
          backgroundImage: 'url(' + registerURl + ')',
        }"
      >
        <div class="register_form">
          <div class="register">
            <p>绑定手机号码</p>
          </div>
          <div class="phone">
            <i class="el-icon-mobile-phone" size="16px"></i
            ><input
              v-model="phoneInput"
              type="number"
              placeholder="请输入手机号"
            />
          </div>
          <div class="code">
            <i class="el-icon-lock" size="16px"></i
            ><input
              v-model="codeInput"
              type="number"
              placeholder="请输入验证码"
            />
            <button>
              <span v-show="code.show" @click="getCode">发送验证码</span>
              <span v-show="!code.show" class="count"
                >{{ code.count }}s后重新发送</span
              >
            </button>
          </div>

          <div class="register_btn">注册</div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="bindPhone_mb">
        <div
          class="login"
          :style="{
            backgroundImage: 'url(' + registerURl + ')',
          }"
        >
          <div class="register_form">
            <div class="register">
              <p>绑定手机号码</p>
            </div>
            <div class="phone">
              <i class="el-icon-mobile-phone" size="16px"></i
              ><input
                v-model="phoneInput"
                type="number"
                placeholder="请输入手机号"
              />
            </div>
            <div class="code">
              <i class="el-icon-lock" size="16px"></i
              ><input
                v-model="codeInput"
                type="number"
                placeholder="请输入验证码"
              />
              <button>
                <span v-show="code.show" @click="getCode">发送验证码</span>
                <span v-show="!code.show" class="count"
                  >{{ code.count }}s后重新发送</span
                >
              </button>
            </div>

            <div class="register_btn">注册</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../header";
import Footer from "../footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 登录背景图片
      registerURl: "",

      //   电话号码输入框
      phoneInput: "",
      //   验证码输入框
      codeInput: "",

      // 发送验证码,
      code: {
        show: true,
        count: "",
        timer: null,
      },
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    if (!localStorage.getItem("userbg")) {
      this.$http
        .get("/index.php/api/v1_0_0.ordinary/get_web_info")
        .then(({ data: res }) => {
          const userbg = res.data.back_url;
          localStorage.setItem("userbg", userbg);
          this.registerURl = localStorage.getItem("userbg");
        })
        .catch((error) => {});
    }
    this.registerURl = localStorage.getItem("userbg");
  },
  methods: {
    // 点击发送短信 倒计时 函数
    getCode() {
      const TIME_COUNT = 60;
      if (!this.code.timer) {
        this.code.count = TIME_COUNT;
        this.code.show = false;
        this.code.timer = setInterval(() => {
          if (this.code.count > 0 && this.code.count <= TIME_COUNT) {
            this.code.count--;
          } else {
            this.code.show = true;
            clearInterval(this.code.timer);
            this.code.timer = null;
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang='less' scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  .register_form {
    width: 463px;

    // width: 24.11%;
    height: 79.58%;
    background: #fff;
    margin: 75px auto;
    padding: 40px;
    .register {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 40px 0;
      text-align: left;

      p:nth-child(1) {
        font-size: 33px;
        font-weight: 500;
        color: #252f3f;
        line-height: 46px;
      }
      p:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        color: #252f3f;
        line-height: 22px;
      }
    }

    .phone,
    .code {
      background-color: #fff;
      display: flex;
      justify-content: start;
      border-bottom: 1px solid #f4f4f4;
      align-items: center;
      padding: 30px 0 14px;
      i {
        margin-right: 10px;
      }
      input {
        height: 30px;
        width: 78%;
        outline: none;
        border: 0;
        font-size: 14px;
        font-weight: 400;
        color: #7e7e7e;
        line-height: 20px;
      }
      button {
        width: 90px;
        height: 30px;
        background: #f1f5f6;
        font-size: 12px;
        font-weight: 400;
        color: #1677ff;
        line-height: 17px;
        border: 0;
        outline: none;
      }
    }

    .register_btn {
      margin: 70px 15.2% 32px;
      height: 42px;
      background: #1677ff;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 42px;
      cursor: pointer;
    }
  }
}
.bindPhone_mb {
  margin: 90px 0 150px 0;
  .login {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-size: 100% 100%;
    padding: 0 20px;
    box-sizing: border-box;
    .register_form {
      width: 100%;
      background: #fff;
      margin: 115px auto 142px;
      padding: 0 20px;
      .register {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 30px 0 47px 0;
        text-align: left;

        p:nth-child(1) {
          font-size: 30px;
          font-weight: 500;
          color: #252f3f;
          line-height: 46px;
        }
        p:nth-child(2) {
          font-size: 15px;
          font-weight: 400;
          color: #252f3f;
          line-height: 22px;
        }
      }

      .phone,
      .code {
        background-color: #fff;
        display: flex;
        justify-content: start;
        border-bottom: 1px solid #f4f4f4;
        align-items: center;
        padding: 0;
        margin-top: 20px;
        i {
          margin-right: 10px;
        }
        input {
          width: 78%;
          outline: none;
          border: 0;
          font-size: 14px;
          font-weight: 400;
          color: #7e7e7e;
          line-height: 20px;
        }
        button {
          width: 90px;
          height: 30px;
          background: #f1f5f6;
          font-size: 12px;
          font-weight: 400;
          color: #1677ff;
          line-height: 17px;
          border: 0;
          outline: none;
        }
      }

      .register_btn {
        margin: 60px auto 30px;
        width: 233px;
        height: 30.86px;
        background: #1677ff;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        line-height: 30.86px;
        cursor: pointer;
      }
    }
  }
}

// 去除input 数字类型 右侧的上下箭头
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>