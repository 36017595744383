<template>
  <div id="LoadMore" class="clearfix">
    <div class="load-more-wrapper clearfix">
      <!-- <div class="load-refresh refresh" v-show="isRefresh">
        {{refreshText}}
        <i class="el-icon-bottom"></i>
      </div> -->
      <div
        class="inner"
        @touchstart="touchStart($event)"
        @touchend="touchEnd($event)"
      >
        <slot></slot>
        <div class="load-more" v-show="enableLoadMore">
          <!--  {{loadMoreText}} -->
          <!-- <i class="el-icon-top"></i> -->
        </div>
        <!-- <div class="load-end" v-show="!enableLoadMore">已经到底了!</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadMore",
  props: {
    enableLoadMore: {
      type: Boolean,
      default: true,
    },
    onLoadMore: {
      type: Function,
      default: undefined,
      require: false,
    },
  },
  data() {
    return {
      isRefresh: false,
      refreshText: "下拉刷新",
      loadMoreText: "上拉加载更多",
      startX: 0,
      startY: 0,
      isLoading: false,
      top: 0,
      touching: false,
      scrollTop: 0,
      loading: "",
      // enableLoadMore: true,
    };
  },

  methods: {
    openFullScreen() {
      //设置弹出框
      // this.loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
    },
    onEnlargeText: function (enlargeAmount) {},
    touchStart(e) {
      this.startY = e.targetTouches[0].pageY;
      this.startX = e.targetTouches[0].pageX;
    },
    closeloading() {
      this.loading.close();
    },
    scrollToEnd(e) {
      let windowHeight = window.screen.height;
      let scrollHeight = this.$el.scrollHeight;
      let clientHeight = this.$el.clientHeight;
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop == 0 && this.touching) {
        this.isRefresh = false;
        this.doLoadMore("total"); // 下拉刷新
      }
      if (this.touching) {
        // 下拉动作不加载下一页
        return false;
      }
      if (!this.enableLoadMore) {
        // this.$message({
        //   showClose: true,
        //   message: "没有更多数据了！",
        //   type: "error",
        //   center: true
        // });
      } else if (
        scrollHeight > windowHeight &&
        this.scrollTop + windowHeight >= scrollHeight &&
        this.scrollTop + clientHeight >= scrollHeight
      ) {
        this.doLoadMore();
        this.loading;
      } else if (
        scrollHeight < windowHeight &&
        this.scrollTop + clientHeight >= scrollHeight
      ) {
        this.doLoadMore();
      }
    },
    touchEnd(e) {
      if (this.isLoading) {
        return;
      }
      let endX = e.changedTouches[0].pageX,
        endY = e.changedTouches[0].pageY,
        dy = this.startY - endY,
        dx = endX - this.startX;
      if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
        return;
      }
      if (endY < this.startY) {
        this.touching = false; // 上滑
        this.scrollToEnd(e);
      } else if (endY > this.startY) {
        // this.touching = true; // 下拉
        // this.scrollToEnd(e);
      }
      // this.isRefresh = true;
    },
    doLoadMore(total = "") {
      this.isLoading = false;
      this.loadMoreText = "加载中...";
      this.openFullScreen(); //显示遮罩层
      this.onLoadMore(this.loadDone, total);
    },
    loadDone() {
      this.isLoading = true;
      this.loadMoreText = "上拉加载更多";
    },
  },
};
</script>

<style lang="less" scoped>
#LoadMore {
  .load-more-wrapper {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition-duration: 300ms;
    .inner {
      width: 100%;
      height: 100%;
      .load-refresh,
      .load-more,
      .load-end {
        color: #aea699;
        font-size: 0.6rem;
        margin: 0.3rem 0;
        text-align: center;
      }
    }
    .refresh {
      position: absolute;
      top: 44px;
      left: 44%;
      z-index: 9;
      color: #aea699;
      font-size: 0.75rem;
      text-align: center;
    }
  }
}
</style>
