<template>
  <!-- 热门文库组件 -->
  <div v-if="list.length">
    <div class="hot" v-if="show_pc">
      <div>
        <div class="top">
          <div class="left">
            <p>热门文库</p>
          </div>
          <div class="right" @click="ClickHotMore">
            <img src="../assets/icon/更多@2x.png" />
          </div>
        </div>
        <div class="conment">
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in list"
              :key="item.id"
              :timestamp="item.create_time"
            >
              <span
                @click="ClickLibraryDetails(item)"
                style="
                  display: block;
                  width: 98%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                >{{ item.title }}</span
              >
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="bottom">
          <div class="xqbd" @click="ClickReport">
            <div>
              <img src="../assets/image/编组 3@2x.png" alt="" />
            </div>
            <span>寻求报道</span>
          </div>
          <div class="wytg" @click="ClickContribution">
            <div>
              <img src="../assets/image/编组 4@2x.png" alt="" />
            </div>
            <span>我要投稿</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!show_pc">
      <div class="bottom_md">
        <div class="xqbd" @click="ClickReport">
          <img src="../assets/image/编组 3@2x.png" alt="" />
          <span>寻求报道</span>
        </div>
        <div class="wytg" @click="ClickContribution">
          <img src="../assets/image/编组 4@2x.png" alt="" />
          <span>我要投稿</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { log } from "util";
export default {
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 热门文库列表
      list: [],
      path: "",
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 获取热门文库
    this.getHotLibrary();
    this.path = this.$route.path;
  },
  methods: {
    // 获取热门文库
    getHotLibrary() {
      let storageKey =
        "storageKey:/index.php/api/v1_0_0.librarycomser/get_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.list = data.list;
      }

      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/get_list", {
          page: 1,
          page_size: 10,
          cid: "",
          type: "",
          hot: 1,
        })
        .then((res) => {
          this.list = res.data.list;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {});
    },

    // 点击热门文库更多
    ClickHotMore() {
      this.$router.push("/wk");
    },
    // 点击热门文库
    ClickLibraryDetails(item) {
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }
      this.$router.push({ path: "/wk/libraryDetails", query: { id: item.id } });
      // location.reload();
    },
    // 寻求报道
    ClickReport() {
      const user_token = localStorage.getItem("user_token");
      if (user_token) {
        this.$router.push({
          path: this.show_pc ? "/release/library" : "/release/libraryMobile",
          query: { item: "寻求报道" },
        });
        return;
        // this.$router.push("/release/seek");
      }
      this.$router.push("/login");
    },
    // 我要投稿
    ClickContribution() {
      const user_token = localStorage.getItem("user_token");
      if (user_token) {
        this.$http
          .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
          .then((res) => {
            console.log(res);
            // 作者
            var author = res.data.is_writer;
            // 企业
            var enterprise = res.data.is_business;
            console.log(author, enterprise);
            console.log(author == 1 || enterprise == 1);
            console.log(author !== 1 && enterprise !== 1);
            if (author == 1 || enterprise == 1) {
              this.$router.push({
                path: this.show_pc ? "/release/info" : "/release/infoMobile",
                query: { item: "发布资讯" },
              });
              return;
            }

            if (author !== 1 && enterprise !== 1) {
              this.$confirm("用户尚未认证作者或企业", "", {
                confirmButtonText: "去认证",
                cancelButtonText: "取消",
                // type: "warning",
              })
                .then(() => {
                  this.$router.push("/personal");
                })
                .catch(() => {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.hot {
  padding: 5px 20px 5px 20px;
  background-color: #ffffff;
  // margin-top: 10px;
  .top {
    height: 55px;
    line-height: 55px;
    display: flex;
    justify-content: space-between;
    .left {
      p {
        display: inline-block;
        cursor: pointer;
        margin-right: 20px;
        font-size: 16px;
        height: 40px;

        /*font-size: 18px;*/
        font-weight: 600;
        color: #292931;
        border-bottom: 3px solid #1677ff;
      }
      // .textStyle {
      //   font-size: 18px;
      //   font-weight: 600;
      //   color: #292931;
      //   border-bottom: 3px solid #1677ff;
      // }
    }
    .right {
      margin-top: 5px;
      cursor: pointer;
      width: 16px;
      height: 16px;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .conment {
    margin-top: 10px;
    .el-timeline-item {
      /deep/.el-timeline-item__content {
        color: #555c69;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555c69;
        line-height: 14px;
      }
      /deep/.el-timeline-item__timestamp {
        color: #9da2ac;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9da2ac;
        line-height: 17px;
      }
      span {
        cursor: pointer;
      }
    }
  }
  .bottom {
    bottom: 100px;
    width: 100%;
    margin-top: 10px;
    border-top: 1px solid #e7e7e7;
    padding: 20px 10px;
    display: flex;
    // margin-bottom: 150px;

    .xqbd,
    .wytg {
      width: 50%;
      display: flex;
      align-items: center;
      cursor: pointer;
      div {
        width: 54px;
        height: 54px;
        border-radius: 54px;
        overflow: hidden;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        margin-right: 10px;
      }
    }
  }
}
.bottom_md {
  img {
    width: 54px;
  }
  width: 100%;
  // position: fixed;
  // bottom: -2px;
  display: flex;
  background-color: #fff;
  padding: 0px 0px 20px;
  box-sizing: border-box;
  justify-content: center;
  margin-left: 0;
  div {
    display: flex;
    align-items: center;
    margin: 0 20px;
    img {
      width: 54px;
      height: 54px;
    }
    span {
      margin-left: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555c69;
    }
  }
}
</style>
