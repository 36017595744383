<template>
  <div>
    <div v-if="show_pc" class="pc">
      <div class="payment">
        <div class="phone">
          <span>手机号</span
          ><input
            type="number"
            :value="editPasword.phone"
            disabled="disabled"
          />
        </div>
        <div class="code" v-if="is_code">
          <span>验证码</span><input type="number" v-model="editPasword.code" />
          <button>
            <span v-show="code.show" @click="getCode">发送验证码</span>
            <span v-show="!code.show" class="count"
              >{{ code.count }}s后重新发送</span
            >
          </button>
        </div>
        <div class="password">
          <span>新密码</span
          ><input
            type="password"
            v-model="editPasword.password"
            placeholder="请输入密码"
          />
        </div>
        <div class="password">
          <span>重复密码</span
          ><input
            type="password"
            v-model="editPasword.password2"
            placeholder="请重复密码"
          />
        </div>
        <div class="editPsw">
          <span></span>
          <p @click="ClickEditPassword">修改密码</p>
        </div>
      </div>
    </div>
    <div id="mobile" v-if="!show_pc">
      <div style="width: 100%; height: 60px"></div>
      <Header></Header>
      <div class="payment">
        <div class="phone">
          <span>手机号</span
          ><input
            type="number"
            :value="editPasword.phone"
            disabled="disabled"
          />
        </div>
        <div class="code" v-if="is_code" style="position: relative">
          <span>验证码</span><input type="number" v-model="editPasword.code" />
          <button
            style="
              position: absolute;
              right: 0px;
              color: #1677ff;
              top: 4px;
              background-color: #fff;
            "
          >
            <span v-show="code.show" @click="getCode">发送验证码</span>
            <span v-show="!code.show" class="count"
              >{{ code.count }}s后重新发送</span
            >
          </button>
          <!--   <button>
            <span v-show="code.show" @click="getCode">发送验证码</span>
            <span v-show="!code.show" class="count">{{ code.count }}s后重新发送</span>
          </button> -->
        </div>
        <div class="password">
          <span>新密码</span
          ><input
            type="password"
            v-model="editPasword.password"
            placeholder="请输入密码"
          />
        </div>
        <div class="password">
          <span>重复密码</span
          ><input
            type="password"
            v-model="editPasword.password2"
            placeholder="请重复密码"
          />
        </div>

        <div
          style="
            background-color: #1677ff;
            height: 38px;
            color: #fff;
            width: 200px;
            margin: 0 auto;
            border-radius: 5px;
            line-height: 38px;
            margin-top: 30px;
            text-align: center;
          "
        >
          <span></span>
          <p @click="ClickEditPassword">保存密码</p>
        </div>

        <!--  <div class="editPsw">
          <span></span>
          <p @click="ClickEditPassword">修改密码</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      editPasword: {
        phone: "",
        code: "",
        password: "",
        password2: "",
      },
      // 发送验证码,
      code: {
        show: true,
        count: "",
        timer: null,
      },
      // is_code
      is_code: false,
      show_pc: true,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.$parent.setIcon = "修改密码";
    this.editPasword.phone = this.$store.state.user.userInfo.phone;
  },
  methods: {
    ClickEditPassword() {
      if (!this.is_code) {
        this.is_code = true;
        return false;
      }

      if (this.editPasword.code == "") {
        this.$message.error("请输入验证码");
        return;
      }
      if (this.editPasword.password == "" || this.editPasword.password2 == "") {
        this.$message.error("请输入密码");
        return;
      }
      if (this.editPasword.password != this.editPasword.password2) {
        this.$message.error("2次密码输入不一致");
        return;
      }
      this.$http
        .post("/index.php/api/v1_0_0.consumer/password_new", {
          yzm: this.editPasword.code,
          password: this.editPasword.password,
          qrpassword: this.editPasword.password2,
        })
        .then((data) => {
          this.$message.success(data.msg);
          this.editPasword.code = "";
          this.editPasword.password = "";
          this.editPasword.password2 = "";
          this.code.show = true;
          clearInterval(this.code.timer);
        })
        .catch((error) => {});
    },
    // 点击发送短信 倒计时 函数
    getCode() {
      const TIME_COUNT = 60;

      this.$http
        .post("/index.php/api/v1_0_0.sendmsg/do_sendmsg", {
          phone: this.editPasword.phone,
          time_stamp: new Date().getTime(),
          sign: this.$md5(
            this.$md5(this.editPasword.phone + "xinshui" + new Date().getTime())
          ),
        })
        .then((data) => {
          this.$message.success("验证码已发送");
          if (!this.code.timer) {
            this.code.count = TIME_COUNT;
            this.code.show = false;
            this.code.timer = setInterval(() => {
              if (this.code.count > 0 && this.code.count <= TIME_COUNT) {
                this.code.count--;
              } else {
                this.code.show = true;
                clearInterval(this.code.timer);
                this.code.timer = null;
              }
            }, 1000);
          }
        })
        .catch((error) => {});
    },
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f5f5f5");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>

<style scoped lang='less'>
#mobile {
  .payment {
    /* margin-top: 40px; */
    padding: 20px;
    .phone,
    .code,
    .password,
    .editPsw {
      display: flex;
      justify-self: start;
      align-items: center;
      /* padding-left: 20px; */
      margin: 16px 0;

      span {
        display: inline-block;
        width: 62px;
        height: 20px;
        font-size: 14px;
        text-align: right;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #89909d;
        line-height: 20px;
      }

      input {
        width: 250px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #e5e6ea;
        margin-left: 24px;
        padding-left: 10px;
        outline: none;
      }
    }
    .editPsw {
      margin: 40px 0 97px 0;

      p {
        width: 104px;
        height: 34px;
        line-height: 34px;
        background: #1677ff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 24px;
        cursor: pointer;
      }
    }
    .code {
      button {
        width: 90px;
        height: 30px;
        background: #f1f5f6;
        border: 0;

        outline: none;
        margin-left: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          display: inline-block;
          width: 100%;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1677ff;
        }
      }
    }
  }
}

.pc {
  .payment {
    margin-top: 40px;

    .phone,
    .code,
    .password,
    .editPsw {
      display: flex;
      justify-self: start;
      align-items: center;
      padding-left: 30px;
      margin: 16px 0;

      span {
        display: inline-block;
        width: 56px;
        height: 20px;
        font-size: 14px;
        text-align: right;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #89909d;
        line-height: 20px;
      }

      input {
        width: 250px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #e5e6ea;
        margin-left: 24px;
        padding-left: 10px;
        outline: none;
      }
    }
    .editPsw {
      margin: 40px 0 97px 0;

      p {
        width: 104px;
        height: 34px;
        line-height: 34px;
        background: #1677ff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 24px;
        cursor: pointer;
      }
    }
    .code {
      button {
        width: 90px;
        height: 30px;
        background: #f1f5f6;
        border: 0;

        outline: none;
        margin-left: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          display: inline-block;
          width: 100%;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1677ff;
        }
      }
    }
  }
}
</style>
