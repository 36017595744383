<template>
  <!-- 发布活动 -->
  <div>
    <div v-if="show_pc" v-loading.fullscreen.lock="loading">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <!-- 活动名称 -->
        <el-form-item label="活动名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入活动名称"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 活动分类 -->
        <el-form-item label="活动分类" prop="region">
          <el-select
            v-model="ruleForm.region"
            placeholder="请选择"
            style="width: 100%"
            :disabled="editDisabled"
          >
            <el-option
              v-for="(item, index) in classifyList"
              :key="index + 'region'"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
            <!-- <el-option label="时事政治" value="shanghai"></el-option>
                <el-option label="薪税新闻" value="beijing"></el-option> -->
          </el-select>
        </el-form-item>

        <!-- 报名人数 -->
        <el-form-item
          label="报名人数"
          prop="applNum"
          style="position: relative"
        >
          <el-input
            v-model="ruleForm.applNum"
            placeholder="0表示不限制人数"
            type="number"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 是否收费 -->
        <el-form-item
          label="是否收费"
          prop="resource"
          style="position: relative"
        >
          <el-radio-group
            v-model="ruleForm.resource"
            @change="radioChange"
            :disabled="editDisabled"
          >
            <el-radio label="否"></el-radio>
            <el-radio label="是" style="height: 100%"></el-radio>
            <span class="height"> </span>
          </el-radio-group>
          <div
            style="
              display: flex;
              position: absolute;
              top: -1px;
              right: 0px;
              z-index: 9;
              align-items: center;
              display: inline-block;
              text-align: right;
            "
            v-if="ruleForm.resource == '是'"
          >
            <span style="border: 0">请选择支付方式</span>
            <el-select
              v-model="ruleForm.charge"
              placeholder="请选择"
              style="width: 18%"
              :disabled="editDisabled"
            >
              <el-option
                v-for="(item, index) in chargeList"
                :key="index + 'charge'"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <span class="money" style="width: 20%"
              ><input
                style="width: 60%"
                type="numder"
                v-model="ruleForm.money"
                :disabled="editDisabled"
              /><span style="border: 0">{{
                ruleForm.charge == "积分" ? "分" : "元"
              }}</span></span
            >
          </div>
        </el-form-item>

        <el-form-item label="活动省份" prop="HDprovince">
          <el-select
            v-model="ruleForm.HDprovince"
            placeholder="请选择活动省份"
            style="width: 100%"
            :disabled="editDisabled"
          >
            <el-option
              v-for="(item, index) in HDprovinceList"
              :key="index + 'HDprovince'"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 活动地点 -->
        <el-form-item label="活动地点" prop="HDsite">
          <el-input
            v-model="ruleForm.HDsite"
            placeholder="请输入活动地点"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 活动简介 -->
        <el-form-item label="活动简介" prop="HDbrief">
          <el-input
            type="textarea"
            :autosize="true"
            v-model="ruleForm.HDbrief"
            placeholder="请输入活动简介"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 活动详情 -->
        <el-form-item label="活动详情" prop="HDdetails">
          <!-- <el-input
            type="textarea"
            v-model="ruleForm.HDdetails"
            placeholder="请输入活动详情"
            :disabled="editDisabled"
          ></el-input> -->
          <div class="text" style="height: 314px; width: 100%">
            <quill-editor
              class="quill"
              v-model="ruleForm.HDdetails"
              ref="free_myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              :disabled="editDisabled"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
              style="height: 80%"
            >
            </quill-editor>
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :file-list="richImg"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
            >
            </el-upload>
            <!-- 视频上传事件，实现视频上传 -->
            <el-upload
              id="uploadFileVideo"
              :action="uploadUrl"
              :file-list="richImg"
              :show-file-list="false"
              :on-success="videoUploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
            >
            </el-upload>
          </div>
        </el-form-item>

        <!-- 报名时间 -->
        <el-form-item
          label="报名时间"
          prop="date1"
          :rules="[
            { required: true, message: '请选择报名时间', trigger: 'blur' },
          ]"
        >
          <el-date-picker
            style="line-height: 180px"
            align="center"
            v-model="ruleForm.date1"
            type="daterange"
            :disabled="editDisabled"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <!-- 活动时间 -->
        <el-form-item
          label="活动时间"
          prop="date3"
          :rules="[
            { required: true, message: '请选择活动时间', trigger: 'blur' },
          ]"
        >
          <div class="block">
            <el-date-picker
              style="line-height: 180px"
              align="center"
              v-model="ruleForm.date3"
              type="daterange"
              :disabled="editDisabled"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="封面图片" prop="img">
          <el-upload
            :before-upload="beforeAvatarUpload"
            :action="uploadUrl"
            :on-progress="process"
            :on-change="change"
            :show-file-list="false"
            :on-preview="handlePictureCardPreview"
            ref="upload"
            :disabled="editDisabled"
            :on-success="onSuccess"
            :auto-upload="true"
            :on-remove="handleRemove"
            :file-list="coverList"
          >
            <img
              width="100%"
              :src="ruleForm.imgData"
              alt=""
              style="width: 88px; height: 88px"
            />
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <!-- 提交 -->
        <el-form-item>
          <el-button
            type="primary"
            style="
              margin-left: 0px;
              display: inline-block;
              background-color: #1677ff;
              width: 104px;
              border-radius: 0;
              height: 34px;
              line-height: 4px;
            "
            :disabled="editDisabled"
            @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 手机端 -->
    <div v-if="!show_pc" id="mobile">
      <Header></Header>
      <div style="width: 100%; height: 90px"></div>
      <div
        class="release_bg"
        :style="{
          backgroundImage:
            'url(https://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20210204/153945_458907.png)',
        }"
        style="margin-bottom: 30px"
      >
        <div style="width: 100%; height: 50px"></div>
        <div style="padding: 10px" v-loading.fullscreen.lock="loading">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            style="background-color: #fff; padding: 20px 10px"
          >
            <!-- 活动名称 -->
            <el-form-item label="活动名称" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入活动名称"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 活动分类 -->
            <el-form-item label="活动分类" prop="region">
              <el-select
                v-model="ruleForm.region"
                placeholder="请选择"
                style="width: 100%"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="(item, index) in classifyList"
                  :key="index + 'region'"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
                <!-- <el-option label="时事政治" value="shanghai"></el-option>
               <el-option label="薪税新闻" value="beijing"></el-option> -->
              </el-select>
            </el-form-item>

            <!-- 报名人数 -->
            <el-form-item
              label="报名人数"
              prop="applNum"
              style="position: relative"
            >
              <el-input
                v-model="ruleForm.applNum"
                placeholder="请输入报名人数限制"
                type="number"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 是否收费 -->
            <el-form-item
              label="是否收费"
              prop="resource"
              style="position: relative"
            >
              <el-radio-group
                v-model="ruleForm.resource"
                @change="radioChange"
                style="width: 100%; text-align: left"
                :disabled="editDisabled"
              >
                <el-radio label="否"></el-radio>
                <el-radio label="是" style="height: 100%"></el-radio>
                <span class="height"> </span>
              </el-radio-group>
            </el-form-item>

            <div
              style="
                display: flex;
                align-items: center;
                padding-left: 30%;
                margin-top: -7px;
              "
              v-if="ruleForm.resource == '是'"
            >
              <!-- <span style="border: 0;">请选择支付方式</span> -->
              <el-select
                v-model="ruleForm.charge"
                placeholder="请选择支付方式"
                style="margin-top: -8px; width: 60%"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="(item, index) in chargeList"
                  :key="index + 'charge'"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <span
                class="money"
                style="width: 26%; position: relative; margin-left: 10px"
              >
                <input
                  style="width: 70%; text-align: left"
                  type="numder"
                  v-model="ruleForm.money"
                  :disabled="editDisabled"
                />
                <span
                  style="border: 0; position: absolute; top: 0px; right: 3px"
                  >{{ ruleForm.charge == "积分" ? "分" : "元" }}</span
                >
              </span>
            </div>

            <el-form-item label="活动省份" prop="HDprovince">
              <el-select
                v-model="ruleForm.HDprovince"
                placeholder="请选择活动省份"
                style="width: 100%"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="(item, index) in HDprovinceList"
                  :key="index + 'HDprovince'"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!-- 活动地点 -->
            <el-form-item label="活动地点" prop="HDsite">
              <el-input
                v-model="ruleForm.HDsite"
                placeholder="请输入活动地点"
                style="margin-top: 6px"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 活动简介 -->
            <el-form-item label="活动简介" prop="HDbrief">
              <el-input
                type="textarea"
                :autosize="true"
                v-model="ruleForm.HDbrief"
                placeholder="请输入活动简介"
                style="margin: 6px 0"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 活动详情 -->
            <el-form-item label="活动详情" prop="HDdetails">
              <el-input
                type="textarea"
                :autosize="true"
                v-model="ruleForm.HDdetails"
                placeholder="请输入活动详情"
                style="margin: 6px 0"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 报名时间 -->
            <el-form-item
              label="报名时间"
              style="margin: 6px 0"
              prop="date1"
              :rules="[
                { required: true, message: '请选择报名时间', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                style="line-height: 180px; margin-top: 8px"
                align="center"
                v-model="ruleForm.date1"
                type="daterange"
                :disabled="editDisabled"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>

            <!-- 活动时间 -->
            <el-form-item
              label="活动时间"
              prop="date3"
              style="margin: 6px 0"
              :rules="[
                { required: true, message: '请选择活动时间', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                style="line-height: 180px; margin-top: 8px"
                align="center"
                v-model="ruleForm.date3"
                type="daterange"
                :disabled="editDisabled"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="封面图片" prop="img">
              <el-upload
                :before-upload="beforeAvatarUpload"
                :action="uploadUrl"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                ref="upload"
                style="margin-top: 8px"
                :disabled="editDisabled"
                :on-success="onSuccess"
                :auto-upload="true"
                :on-remove="handleRemove"
                :file-list="coverList"
              >
                <img
                  width="100%"
                  :src="ruleForm.imgData"
                  alt=""
                  style="width: 88px; height: 88px"
                />
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>

            <!-- 提交 -->
            <el-form-item>
              <el-button
                type="primary"
                class="btn"
                :disabled="editDisabled"
                @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
          <div style="width: 100%; height: 60px"></div>
        </div>
      </div>
    </div>
    <div id="inputVideoSize">
      <div class="videoWidth">
        <span class="videoText">视频宽度</span>
        <el-input v-model="videoWidth" placeholder="请输入视频宽度"></el-input>
      </div>
      <div class="videoHeight">
        <span class="videoText">视频高度</span>
        <el-input v-model="videoHeight" placeholder="请输入视频高度"></el-input>
      </div>
      <div class="btns">
        <el-button @click="ClickClose">关闭</el-button>
        <el-button type="primary" @click="ClickVideo">确定</el-button>
      </div>
    </div>
    <!-- 视频上传弹框 -->
    <el-dialog
      title="视频上传"
      :visible.sync="dialogVideo"
      width="30%"
      :show-close="false"
    >
      <!-- action必选参数, 上传的地址 -->
      <el-upload
        class="avatar-uploader el-upload--text"
        action
        :http-request="handleUpload"
        :show-file-list="false"
        :on-success="handleVideoSuccess"
        :before-upload="beforeUploadVideo"
        :on-progress="uploadVideoProcess"
      >
        <video
          v-if="video_url != '' && videoFlag == false"
          :src="video_url"
          style="width: 100%; height: 200px"
          controls="controls"
        >
          您的浏览器不支持视频播放
        </video>
        <i
          v-else-if="video_url == '' && videoFlag == false"
          class="el-icon-plus avatar-uploader-icon"
        ></i>
        <el-progress
          v-if="videoFlag == true"
          type="circle"
          :percentage="videoUploadPercent"
          style="margin-top: 30px"
        ></el-progress>
      </el-upload>

      <P class="text" style="margin-top: 20px"
        >请保证视频格式正确，且不超过5M</P
      >

      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px;
            border: 0;
          "
          >视频宽度</span
        ><el-input v-model="video_width" placeholder="视频宽度"></el-input>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px;
            border: 0;
          "
          >视频宽度</span
        >
        <el-input v-model="video_height" placeholder="视频高度"></el-input>
      </div>

      <span slot="footer" class="dialog-footer" style="border: 0">
        <el-button @click="clickCancel">取 消</el-button>
        <el-button type="primary" @click="toUploadVideo">确 定</el-button>
      </span>
    </el-dialog>
    <div id="test" @click="test"></div>
  </div>
</template>
<script>
import Header from "../header.vue";
import { mapState, mapMutations } from "vuex";
import VideoBlot from "../../utils/video";
import * as Quill from "quill";
import { client, getFileNameUUID } from "../../utils/ali-oss";
Quill.register(VideoBlot);
export default {
  components: {
    Header,
  },
  data() {
    //自定义验证
    var charge = (rule, value, callback) => {
      if (this.ruleForm.resource == "是" && this.ruleForm.charge == "") {
        callback(new Error("请选择支付方式"));
        return;
      }
      if (
        this.ruleForm.resource == "是" &&
        (this.ruleForm.money == "" || this.ruleForm.money < 0.1)
      ) {
        callback(new Error("请输入金额"));
        return;
      }
      callback();
    };
    //图片上传验证
    let uploadImg = (rule, value, callback) => {
      if (this.ruleForm.imgData == "") {
        callback(new Error("请上传封面图片"));
      }

      callback();
    };
    return {
      video_width: "100%",
      video_height: "100px",
      video_url: "",
      videoFlag: false,
      videoUploadPercent: 0,
      select_index: 0,
      dialogVideo: false,
      // 视频宽
      videoWidth: "",
      // 视频高
      videoHeight: "",
      HDprovinceList: [],
      coverList: [], //编辑封面预览
      chargeList: ["积分", "余额"], // 支付方式
      //   上传图片路径
      dialogImageUrl: "",
      //   是否显示弹出框
      dialogVisible: false,
      ruleForm: {
        money: "", //活动价格
        charge: "",
        name: "",
        region: "",
        date1: [],
        date2: "",
        date3: "",
        delivery: false,
        resource: "否",
        desc: "",
        applNum: "",
        HDsite: "",
        HDbrief: "",
        HDdetails: "",
        imgData: require("../../assets/image/上传封面图.png"),
        HDprovince: "",
      },
      rules: {
        resource: [
          {
            required: true,
            validator: charge,
            trigger: ["change", "blur"],
          },
        ],
        region: [
          {
            required: true,
            message: "请选择活动分类",
            trigger: "change",
          },
        ],
        HDprovince: [
          {
            required: true,
            message: "请选择活动省份",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
        ],
        // applNum: [
        //   {
        //     required: true,
        //     message: "请输入报名人数限制",
        //     trigger: "blur",
        //   },
        // ],
        HDbrief: [
          {
            required: true,
            message: "请输入活动简介",
            trigger: "blur",
          },
        ],
        HDdetails: [
          {
            required: true,
            message: "请输入活动详情",
            trigger: "blur",
          },
        ],
        HDsite: [
          {
            required: true,
            message: "请输入活动地点",
            trigger: "blur",
          },
        ],

        // date1: [{
        //   // type: "date",
        //   // required: true,
        //   message: "请选择报名时间",
        //   trigger: "change",
        // }, ],
        // date3: [{
        //   // type: "date",
        //   // required: true,
        //   message: "请选择活动时间",
        //   trigger: "change",
        // }, ],
        img: [
          {
            required: true,
            // validator: uploadImg,
            message: "请上传封面图片",
            trigger: "change",
          },
        ],
      },
      //   富文本
      richImg: [],
      editorOption: {
        placeholder: "",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              // ['blockquote', 'code-block'],     //引用，代码块
              [
                { header: 1 },
                {
                  header: 2,
                },
              ], // 标题，键值对的形式；1、2表示字体大小
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ], //列表
              //[{ script: "sub" }, { script: "super" }], // 上下标
              [
                {
                  indent: "-1",
                },
                {
                  indent: "+1",
                },
              ], // 缩进
              [
                {
                  direction: "rtl",
                },
              ], // 文本方向
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
              // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
              // [{ 'align': [] }],    //对齐方式
              ["clean"], //清除字体样式
              ["image", "video"], //上传图片、上传视频
            ],
            handlers: {
              video: function (val) {
                if (val) {
                  document.querySelector("#test").click();

                  // 劫持原来的视频点击按钮事件
                  // document.querySelector("#inputVideoSize").style.display =
                  //   "block";
                } else {
                  this.quill.format("video", false);
                }
              },
              image: function (value) {
                if (value) {
                  // upload点击上传事件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      classifyList: [],
      minb: "",
      user: "",
      hasFmt: false,
      tid: "",
      show_pc: true,
      loading: false,
      editDisabled: false,
    };
  },
  computed: {
    ...mapState(["uploadUrl"]),
  },
  created() {
    // document.querySelector('body').style.backgroundColor = "#f5f5f5"
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 获取活动分类
    this.$http
      .get("/index.php/api/v1_0_0.activity/get_cate_list", {
        notNeed: true,
      })
      .then(({ data }) => {
        this.classifyList = data.list;
        console.log(data, "活动分类");
      })
      .catch((error) => {});

    // 获取省份
    this.$http
      .post("/index.php/api/v1_0_0.activity/get_all_provinces", {})
      .then(({ data }) => {
        this.HDprovinceList = data;
        console.log(data, "111活动分类");
      })
      .catch((error) => {});

    // 获取编辑内容详情
    this.tid = this.$route.query.tid;
    if (this.tid) {
      this.loading = true;
      this.editDisabled = this.$store.state.editDisabled;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/activity_detail", {
          tid: this.tid,
        })
        .then((data) => {
          console.log(data);
          let ruleForm = this.ruleForm;

          ruleForm.name = data.data.title;
          ruleForm.region = data.data.cid;
          ruleForm.desc = data.data.description;
          // ruleForm.charge = data.data.unit;
          ruleForm.charge = data.data.pay_type == 1 ? "积分" : "余额";
          ruleForm.resource = data.data.is_pay == 1 ? "否" : "是";
          ruleForm.money = data.data.price == "￥免费" ? 0 : data.data.price;
          ruleForm.applNum = data.data.people_num ? data.data.people_num : 0;
          ruleForm.HDsite = data.data.address;
          ruleForm.HDdetails = data.data.content;
          ruleForm.HDbrief = data.data.description;
          this.editDisabled = data.data.status == 1 ? true : false;

          ruleForm.date3 = [
            new Date(data.data.start_time),
            new Date(data.data.end_time),
          ];
          ruleForm.date1 = [
            new Date(data.data.sign_start_time),
            new Date(data.data.sign_end_time),
          ];
          ruleForm.imgData = data.data.cover;
          ruleForm.HDprovince = data.data.province;

          //添加图片预览 取消当前上传验证
          this.coverList.push({
            url: data.data.cover,
          });
          this.rules.img = "";
          ruleForm.imgData = data.data.cover;
          this.loading = false;
        })
        .catch((error) => {});
      return;
    }
  },
  methods: {
    /**
     * 自定义上传方法
     */
    handleUpload(option) {
      let that = this;
      async function multipartUpload() {
        const fileName = getFileNameUUID();
        //定义唯一的文件名，打印出来的uid其实就是时间戳
        //client 是第一步中的 client
        client()
          .multipartUpload("xinshui/" + fileName, option.file, {
            progress: function (p) {
              //获取进度条的值
              // console.log(p)
              that.videoFlag = true;
              // console.log(file.percentage)
              var pro = p * 100;
              that.videoUploadPercent = pro.toFixed(0) * 1;
            },
          })
          .then((result) => {
            //下面是如果对返回结果再进行处理，根据项目需要
            // https://xinshuibucket.oss-cn-beijing.aliyuncs.com/1612356926822_9ac83937
            that.videoFlag = false;
            that.videoUploadPercent = 0;
            that.video_url =
              "https://xinshuibucket.oss-cn-beijing.aliyuncs.com/" +
              result.name;
          })
          .catch((err) => {
            console.log("err:", err);
            this.$message.error("视频上传失败，请重新上传！");
          });
      }
      multipartUpload();
    },

    // 视频上传成功
    handleVideoSuccess(res, file) {
      //获取上传图片地址
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      console.log(res);
      if (res.code == 200) {
        this.video_url = res.data.file_url;
      } else {
        this.$message.error("视频上传失败，请重新上传！");
      }
    },

    // 视频上传之前
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 5;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过5MB哦!");
        return false;
      }
    },

    // 视频上传进度
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      // console.log(file.percentage)
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },

    // 确定添加视频
    toUploadVideo() {
      var quill = this.$refs.free_myQuillEditor.quill;

      this.dialogVideo = false;

      // return false;
      console.log(this.select_index);
      quill.insertEmbed(this.select_index, "simpleVideo", {
        url: this.video_url,
        controls: "controls",
        width: this.video_width,
        height: this.video_height,
      });
    },
    // 取消
    clickCancel() {
      this.dialogVideo = false;
      this.video_url = "";
    },
    test() {
      // 记录此时的位置
      var quill = this.$refs.free_myQuillEditor.quill;
      this.select_index = quill.getSelection().index;
      this.video_url = "";

      // 展示视频弹框
      this.dialogVideo = true;
    },
    // 图片处理
    uploadSuccess(res) {
      console.log("成功");
      console.log(res.data.file_url, "res");
      var dt = {};
      dt.url = res.data.file_url;
      let quill = this.$refs.free_myQuillEditor.quill;

      console.log(quill);
      // 如果上传成功
      if (res.code === 200 && dt.url !== null) {
        console.log("插入图片");
        console.log(dt.url);

        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  dt.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", dt.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      // loading加载隐藏
      this.quillUpdateImg = false;
    },
    // 视频上传成功函数
    videoUploadSuccess(res) {
      var dt = {};
      dt.url = res.data.file_url;

      let quill = this.$refs.free_myQuillEditor.quill;
      if (res.code === 200 && dt.url !== null) {
        let length = quill.getSelection().index;
        quill.insertEmbed(length, "simpleVideo", {
          url: dt.url,
          controls: "controls",
          width: this.videoWidth + "px",
          height: this.videoHeight + "px",
        });

        quill.setSelection(length + 1);
      } else {
        this.$message.error("视频插入失败");
      }
    },
    //视频上传事件
    ClickVideo() {
      window.document.querySelector("#uploadFileVideo input").click();
    },
    // 关闭
    ClickClose() {
      document.querySelector("#inputVideoSize").style.display = "none";
    },

    // 上传失败钩子函数
    uploadError(res) {
      console.log("失败");
      console.log(res);
    },
    // 上传前钩子函数
    beforeUpload() {},
    onEditorReady(editor) {}, // 准备编辑器
    onEditorBlur(e) {
      console.log(e, "e");
    }, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {}, // 内容改变事件
    // 图片上传格式
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/png" || "image/jpeg" || "image/bmp" || "image/gif";

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、PNG、bmp、gif格式!");
      }

      return isJPG;
    },
    change() {
      this.loading = false;
    },
    process() {
      this.loading = true;
    },

    radioChange(e) {},
    submitForm(formName) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        console.log(valid);

        if (valid) {
          if (this.ruleForm.charge == "余额") {
            this.ruleForm.money = this.ruleForm.money * 100;
          }
          this.$http
            .post("/index.php/api/v1_0_0.activity/publish_activity", {
              tid: this.$route.query.tid,

              publish_data: {
                cid: this.ruleForm.region,
                title: this.ruleForm.name,
                description: this.ruleForm.HDbrief,
                content: this.ruleForm.HDdetails,
                cover: this.ruleForm.imgData,
                is_pay: this.ruleForm.resource == "是" ? "2" : "1", //是否收费 1免费 2付费
                price: this.ruleFormmoney, //价格 整数  余额支付单位为分
                sign_start_time: this.ruleForm.date1[0], //报名开始时间
                sign_end_time: this.ruleForm.date1[1], //报名结束时间
                start_time: this.ruleForm.date3[0], //活动开始时间
                end_time: this.ruleForm.date3[1],
                address: this.ruleForm.HDsite, //活动地点
                people_num: this.ruleForm.applNum
                  ? parseInt(this.ruleForm.applNum).toFixed(0)
                  : 0, //报名人数限制
                pay_type: this.ruleForm.charge == "余额" ? "2" : "1", //支付方式 1积分 2余额
                price: this.ruleForm.money,
                province: this.ruleForm.HDprovince,
              },
            })
            .then((data) => {
              this.$message.success(data.msg);
              this.$router.push({
                path: "/personal/content",
                query: { tabs: "活动" },
              });
              return;
            })
            .catch((error) => {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    onSuccess(response, file, fileList) {
      this.ruleForm.imgData = response.data.file_url;
      this.$refs.ruleForm.clearValidate("img");
      this.rules.img = "";
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleRemove(file, fileList) {
      if (fileList.length < 1) {
        this.rules.img = [
          {
            required: true,
            message: "请上传封面图片",
            trigger: "change",
          },
        ];
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    openTitle() {
      this.$confirm(
        "用户会员身份不同，所付费价格不同，不同身份会员，收益价格不同",
        "提示",
        {
          confirmButtonText: "知道了",
          cancelButtonText: "取消",
          showCancelButton: false,
          type: "warning",
        }
      )
        .then(() => {})
        .catch(() => {});
    },
  },
  watch: {
    "ruleForm.resource": {
      handler(value) {
        if (value == "是" && !this.editDisabled) {
          this.openTitle();
        }
      },
    },
  },
};
</script>
<style lang='less' scoped>
body {
  background-color: #f5f5f5;
}
#mobile {
  /deep/.el-input__icon {
    line-height: 33px;
  }
  /deep/.el-form-item__error {
    padding-top: 3px;
  }

  /deep/.el-input {
    margin-top: 8px;
  }

  .release_bg {
    width: 100%;
    height: 180px;

    background: #f1f5f6;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    /* padding: 120px 0 50px; */
  }

  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    /* line-height: 44px; */
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

/deep/.el-upload--picture-card {
  width: 88px;
  height: 88px;
  border: 0;
}

.money {
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid #e5e6ea;
  padding: 0 10px;
  margin-left: 20px;

  /* margin-top: 4px; */
  input {
    width: 74px;
    height: 34px;
    background: #ffffff;
    outline: none;
    border: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #4f5764;
  }
}

/deep/ .el-date-editor .el-range-separator {
  line-height: 26px;
}

/deep/.el-date-editor .el-range__icon {
  line-height: 26px;
}

.box {
  display: flex;
  justify-content: space-between;

  input {
    width: 28%;
    height: 34px;
    background: #ffffff;
    border: 1px solid #e5e6ea;

    outline: none;
    padding-left: 10px;
  }
}

span {
  display: inline-block;
  height: 34px;
  line-height: 34px;
  background: #ffffff;
  border: 1px solid #e5e6ea;
  padding: 0 10px;

  font-size: 14px;
  font-weight: 400;
  color: #89909d;
}

.height {
  display: inline-block;
  height: 24px;
  border: 0;
}
#inputVideoSize {
  width: 500px;
  height: 200px;
  background-color: #ccc;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display: none;

  .videoWidth,
  .videoHeight {
    display: flex;
    align-items: center;
    height: 34px;
    .videoText {
      display: inline-block;
      width: 100px;
    }
  }
  .btns {
    width: 100%;
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
