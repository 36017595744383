<template>
  <!-- 文库详情 -->
  <div>
    <Header :indexi='5'></Header>
<!-- PC端 -->
    <div v-if="show_pc" style=" background-color: #f5f5f5;" class="pc">
    <!-- 文库资料背景图片 -->
      <div
        class="igm"
        :style="{
          backgroundImage: 'url(' + headerImg + ')',
        }"
      >
<!--        文库资料-->
      </div>
      <!-- 文库内容 -->
      <div class="library">
        <!-- 文库内容左侧 -->
        <div class="library_left">
          <!-- 输入框 筛选 -->
          <div class="screen">
            <!-- 输入框 -->

            <div class="top">
              <input v-model="keyword" placeholder="搜索文档"/>
              <button @click="toSearch()">搜索</button>
            </div>
            <!-- 筛选区域 -->
            <div class="buttom">
              <ul v-for="(item, index) in search">
                <li>{{ item.name }}</li>
                <li
                  v-for="(item1, index1) in item.list"
                  :key="index1"
                  :class="{ color: item.fields == 'type'
                  ? ((item1.value == type) ? true:false)
                  : (item.fields == 'time' ? ((item1.value == time) ? true:false)
                  :(item.fields == 'cid' ? ((item1.value == cid) ? true:false) :false))  }"
                  @click="ClickFormatList(item.fields, item1.value)"
                >
                  {{ item1.title }}
                </li>
              </ul>
              <p @click="ClickClear">
                <i class="el-icon-delete"></i> 清空所有筛选条件
              </p>
            </div>
          </div>

          <!-- 文档信息 -->
          <div class="document" v-loading="fullscreenLoading">
            <div class="document_text">
              <p>文档信息</p>
              <span></span>
            </div>

            <div class="document_box">
              <ul>
                <li v-if="!libraryInfo.length">
                  没有更多了...
                </li>
                <li v-for="(item, index) in libraryInfo" :key="item.id"
                    @click="ClickFileInfo(item)"
                >
                  <div class="document_list">
                    <!-- 标题 -->
                    <div class="title">
                      <div class="left">
                        <span :style="{color:item.type_color,borderColor:item.type_color}">{{item.type_name}}</span>
                        <span>{{ item.title }}</span>
                      </div>
                      <span :class="{is_pay:1==item.is_pay}" v-if="item.is_pay==1">{{ item.price }}</span>
                      <span :class="{is_pay:1==item.is_pay}" v-if="item.is_pay==2">{{ item.price_str }}</span>
                    </div>
                    <!-- 内容 -->
                    <p>
                      {{ item.description }}
                    </p>
                    <div class="information">
                      <ul>

                        <li>
                          {{item.create_time }}
                          <span></span>
                        </li>
                        <li>
                          {{ item.collect_count+'次收藏' }}
                          <span></span>
                        </li>
                        <li>
                          {{ item.download_count+'次下载' }}
                          <span></span>
                        </li>
                        <li>
                          {{ item.nickname }}
                          <span></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 分页 -->
          <!-- 底部分页 -->
          <div class="page" v-if="total > 10">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="queryInfo.pagenum"
              :page-size="queryInfo.pagesize"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>

        <!-- 热门文章 -->
        <!-- 文库内容右侧 -->
        <div class="library_right">
          <!-- 上传 文库资料 -->
          <div class="upload" @click="ClickUpload">
            <img src="../assets/icon/上传@2x.png" alt=""/>
            <span>上传文库资料</span>
          </div>


          <!-- 文章数量区域 -->
          <div class="hot">
            <div class="text_bottom">
              <div class="text">
                热门文档
                <span></span>
              </div>
            </div>
            <div class="numders">
              <ul>
                <li v-for="(item, index) in numdersList" :key="item.id" @click="ClickHotFile(item)">
                  <p>
                    <span :style="{color:item.type_color,borderColor: item.type_color}">
                      {{item.type_name}}
                    </span>
                    {{ item.title }}
                  </p>
                  <p>
                    {{ item.description }}
                  </p>
                  <p>
                  <ul>
                    <li>
                      {{item.create_time }}
                      <span></span>
                    </li>
                    <li>
                      {{ item.collect_count+'次收藏' }}
                      <span></span>
                    </li>
                    <li>
                      {{ item.download_count+'次下载' }}
                      <span></span>
                    </li>
                    <li>
                      {{ item.nickname }}
                      <span></span>
                    </li>
                  </ul>
                  </p>

                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 移动端 -->
     <div v-if="!show_pc">
      <div class="wk_md">
        <!-- 搜索 筛选 -->
          <div class="screen">
            <!-- 输入框 -->

            <div class="top">
              <input v-model="keyword" placeholder="搜索文档"/>
              <button @click="toSearch()">搜索</button>
            </div>
            <!-- 筛选区域 -->
            <div class="buttom">
              <ul v-for="(item, index) in search">
                <div>
                   <li>{{ item.name }}</li>
                </div>
                <div>
                  <li
                    v-for="(item1, index1) in item.list"
                    :key="index1"
                    :class="{ color: item.fields == 'type'
                    ? ((item1.value == type) ? true:false)
                    : (item.fields == 'time' ? ((item1.value == time) ? true:false)
                    :(item.fields == 'cid' ? ((item1.value == cid) ? true:false) :false))  }"
                    @click="ClickFormatList(item.fields, item1.value)"
                  >
                    {{ item1.title }}
                  </li>
                </div>
              </ul>
              <p @click="ClickClear">
                <i class="el-icon-delete"></i> 清空所有筛选条件
              </p>
            </div>
          </div>

          <!-- 内容 -->
          <div class="document">
            <div class="document_text">
              <span></span>
              <p>文档信息</p>
            </div>

            <div class="document_box">
              <ul>

                <li v-for="(item, index) in libraryInfo" :key="item.id"
                    @click="ClickFileInfo(item)"
                >
                  <div class="document_list">
                    <!-- 标题 -->
                    <div class="title">
                      <div class="left">
                        <span :style="{color:item.type_color,borderColor:item.type_color}">{{item.type_name}}</span>
                        <span>{{ item.title }}</span>
                      </div>
                      <span :class="{is_pay:1==item.is_pay}" v-if="item.is_pay==1">{{ item.price }}</span>
                      <span :class="{is_pay:1==item.is_pay}" v-if="item.is_pay==2">{{ item.price_str }}</span>
                    </div>
                    <!-- 内容 -->
                    <p>
                      <template>
                        <div v-html="item.description">

                      {{ item.description }}
                        </div>
                      </template>
                    </p>
                    <div class="information">
                      <ul>

                        <li>
                          {{item.create_time }}
                          <span></span>
                        </li>
                        <li>
                          {{ item.collect_count+'次收藏' }}
                          <span></span>
                        </li>
                        <li>
                          {{ item.download_count+'次下载' }}
                          <span></span>
                        </li>
                        <li>
                          {{ item.nickname }}
                          <span></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
               <div
            class="more"
            v-if="libraryInfo.length !== total"
            @click="ClickMore"
          >
            加载更多
          </div>
          <!-- <div class="more"  @click="ClickPackUpMore" v-if="libraryInfo.length > 10">收起更多</div> -->

          <div class="more" v-if="libraryInfo.length == total">
            没有更多了...
          </div>
          </div>
      </div>
     </div>


    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";
import { log } from "util";

export default {
  components: {
    Header,
    Footer,
  },
  metaInfo: {
    title: "新税网 - 文库",
    meta: [
      // {
      //   name: "description",
      //   content: "这是文库页面",
      // },
      {
        name: "keywords",
        content: "新税网-文库",
      },
    ],
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 文库背景图片
      headerImg: "",

      // 筛选列表
      search: [],
      // 相关文档列表
      numdersList: "",

      // 文档列表
      libraryInfo: [],

      // 搜索
      cid: 0,
      type: 0,
      time: 0,
      // 输入框内容
      keyword: "",

      // 查询分页表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      // 总条数
      total: 0,
      fullscreenLoading: false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 获取文库分类
    this.getCate();

    // 文库列表数据
    this.libraryList();

    // 热门文档数据
    this.hotFileList();
  },
  methods: {
    // 点击搜索
    toSearch() {
      if (!this.keyword) {
        this.$message.error("请输入内容");
        return false;
      }

      this.libraryList();
    },
    // 获取文库分类
    getCate() {
      let storageKey = "/index.php/api/v1_0_0.librarycomser/get_screen";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.search = data;
      }

      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/get_screen", {})
        .then((res) => {
          this.search = res.data;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {});
    },

    // 点击 格式 选项
    ClickFormatList(type, value) {
      console.log(type);
      console.log(value);
      if (type == "type") {
        this.type = value;
      }
      if (type == "time") {
        this.time = value;
      }
      if (type == "cid") {
        this.cid = value;
      }

      this.libraryList();
    },
    // 清除所有筛选
    ClickClear() {
      this.type = 0;
      this.time = 0;
      this.cid = 0;
      this.keyword = "";
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = 10;
      this.libraryList();
    },

    // 文库文档信息列表数据
    libraryList() {
      let storageKey = "/index.php/api/v1_0_0.librarycomser/get_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.total = data.total_count;
        this.libraryInfo = data.list;
        this.headerImg = data.back_url;
      }

      this.fullscreenLoading = true;
      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          cid: this.cid,
          type: this.type,
          keyword: this.keyword,
          time: this.time,
        })
        .then((res) => {
          this.total = res.data.total_count;
          this.libraryInfo = res.data.list;
          this.headerImg = res.data.back_url;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {})
        .finally(() => {
          this.fullscreenLoading = false;
        });
    },

    // 点击文档信息列表
    ClickFileInfo(item) {
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!?", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }

      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/view_log", {
          id: item.id,
        })
        .then(() => {
          this.$router.push({
            path: "/wk/libraryDetails",
            query: { id: item.id },
          });
        })
        .catch(() => {});
    },

    // 热门文档数据
    hotFileList() {
      let storageKey = "/index.php/api/v1_0_0.librarycomser/get_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.numdersList = data.list;
      }
      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          cid: 0,
          hot: 1,
        })
        .then((res) => {
          this.numdersList = res.data.list;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {});
    },
    // 点击热门文档列表
    ClickHotFile(item) {
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }

      this.$router.push({
        path: "/wk/libraryDetails",
        query: { id: item.id },
      });
    },

    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.libraryList();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      this.libraryList();
    },
    // 点击文件上传资料
    ClickUpload() {
      // 请求用户信息
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
        .then((res) => {
          if (res.data.is_writer || res.data.is_business) {
            // TODO 去发布文章页面
            this.$router.push({
              path: "/release/library",
              query: { item: "发布文库" },
            });
          } else {
            // 去认证作者页面
            this.$confirm("上传资料需要认证企业或者作家", "认证提示", {
              confirmButtonText: "去认证",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                // TODO 去认证作者页面
                // this.$router.push("/attestation/writer");
                this.$router.push("/personal/user");
                // this.$router.push({
                //   path: "/release/library",
                //   query: { i: 4 },
                // });
              })
              .catch(() => {});
          }
        })
        .catch((error) => {});
    },
    // 移动端
    // 点击加载更多

    ClickMore() {
      this.queryInfo.pagenum++;
      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/get_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          cid: this.cid,
          type: this.type,
          keyword: this.keyword,
          time: this.time,
        })
        .then((res) => {
          console.log("文档列表数据");
          console.log(res.data);
          this.total = res.data.total_count;
          res.data.list.forEach((item) => {
            this.libraryInfo.push(item);
          });
        })
        .catch((error) => {});
    },
    // 点击收起
    ClickPackUpMore() {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = 10;
      this.hotFileList();
    },
  },
};
</script>
<style lang='less' scoped>
.pc {
  .igm {
    width: 100%;
    height: 300px;
    line-height: 250px;
    background-size: 100% 100%;
    font-size: 35px;
    font-weight: 500;
    color: #ffffff;
  }

  .library {
    width: 1200px;
    margin: 0 auto;
    // margin: 0 10.45%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    // width: ;

    .library_left {
      width: 66%;
      height: 100%;

      .screen {
        .top {
          display: flex;
          justify-content: start;
          align-items: center;

          input {
            width: 100%;
            height: 52px;
            border: 0;
            outline: none;
            background: #ffffff;
            border-radius: 8px 0px 0px 8px;
            border: 1px solid #fff;
            font-size: 16px;
            padding-left: 30px;
          }

          button {
            width: 140px;
            height: 56px;
            background: #1677ff;
            border-radius: 0px 8px 8px 0px;
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            border: 0;
            cursor: pointer;
          }
        }

        .buttom {
          // height: 205px;
          background: #ffffff;
          border-radius: 5px;
          margin: 20px 0;
          padding: 5px 5px;

          ul {
            display: flex;
            justify-content: start;
            align-items: center;

            .color {
              color: #1677ff !important;
            }

            li {
              text-align: left;
              padding: 15px;
              cursor: pointer;
            }

            li:nth-child(1) {
              font-size: 16px;
              font-weight: 500;
              color: #424b60;
              line-height: 22px;
            }

            li:nth-child(2) {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
            }

            li:nth-child(n + 3) {
              font-size: 14px;
              font-weight: 400;
              color: #787e8f;
              line-height: 20px;
              cursor: pointer;
            }
          }

          p {
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            color: #c5cacf;
            line-height: 17px;
            padding: 5px 15px;
            cursor: pointer;
          }
        }
      }

      .document {
        .document_text {
          display: flex;
          flex-direction: column;

          p {
            text-align: left;
            font-size: 18px;
            font-weight: 600;
            color: #292931;
            line-height: 25px;
            margin-bottom: 4px;
          }

          span {
            display: block;
            text-align: center;
            width: 60px;
            height: 3px;
            background: #1677ff;
            margin-left: 5px;
          }
        }

        .document_box {
          ul {
            li {
              padding: 10px 20px;
              background-color: #fff;
              margin: 10px 0;
              cursor: pointer;

              .document_list {
                .title {
                  display: flex;
                  justify-content: space-between;

                  .is_pay {
                    color: #22c668;
                  }

                  .left {
                    display: flex;

                    span:nth-child(1) {
                      display: inline-block;
                      width: 18px;
                      height: 18px;
                      line-height: 18px;
                      font-weight: 600;
                      font-size: 12px;
                      background: #ffffff;
                      border-radius: 3px;
                      border: 1px solid #fff;
                      text-align: center;
                      margin-right: 6px;
                      cursor: pointer;
                    }

                    span:nth-child(2) {
                      font-size: 16px;
                      font-family: PingFangSC-Semibold, PingFang SC;
                      font-weight: 600;
                      color: #24272f;
                      line-height: 22px;
                      width: 650px;
                      text-align: left;
                      display: inline-block;
                      white-space: nowrap; //强制一行显示
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }

                  span {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ff3c3c;
                    line-height: 17px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ff3c3c;
                  }
                }

                p {
                  font-size: 14px;
                  font-weight: 400;
                  color: #453c3c;
                  line-height: 20px;
                  text-align: left;
                  padding: 10px 0;
                }

                .information {
                  ul {
                    display: flex;

                    li {
                      padding: 0;
                      font-size: 12px;
                      display: flex;
                      align-items: center;
                      font-weight: 400;
                      color: #8a8b9a;
                      margin: 0;

                      span {
                        display: inline-block;
                        width: 1px;
                        height: 65%;
                        background-color: #8a8b9a;
                        margin: 0 10px;
                      }
                    }

                    li:last-child {
                      span {
                        width: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .page {
        text-align: center;
        padding: 25px 0 30px 0;
      }
    }

    .library_right {
      width: 32.5%;
      height: 100%;

      .upload {
        height: 40px;
        background: #1677ff;
        border-radius: 1px;
        border: 1px solid #979797;
        margin: 0 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          display: inline-block;
          width: 16px;
          height: 16px;
          padding-right: 5px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
      }

      .hot {
        width: 100%;
        height: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .text_bottom {
          background-color: #fff;
          padding-top: 20px;

          .text {
            margin: 0 20px;
            // width: 60px;
            text-align: left;

            font-size: 18px;
            font-weight: 600;
            color: #292931;
            line-height: 25px;

            span {
              display: block;
              width: 15%;
              height: 4px;
              background-color: #1677ff;
              margin: 5px 0 10px;
            }
          }
        }

        .numders {
          width: 100%;
          height: auto;
          background-color: #fff;

          ul {
            padding: 20px;

            li {
              // width: 100%;
              text-align: left;
              margin-bottom: 20px;
              box-sizing: content-box;
              cursor: pointer;

              p:nth-child(1) {
                span {
                  display: inline-block;
                  width: 18px;
                  height: 18px;
                  line-height: 18px;
                  background: #ffffff;
                  border-radius: 3px;
                  border: 1px solid #fff;
                  text-align: center;
                  margin-right: 5px;
                  font-size: 12px;
                }

                font-size: 16px;
                font-weight: 600;
                color: #24272f;
                line-height: 22px;
                white-space: nowrap; //强制一行显示
                overflow: hidden;
                text-overflow: ellipsis;
              }

              p:nth-child(2) {
                font-size: 14px;
                font-weight: 400;
                color: #313a4a;
                line-height: 20px;
                margin: 10px 0 5px;
                overflow: hidden; //强制多行显示
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
              }

              p:nth-child(3) {
                ul {
                  display: flex;
                  justify-content: start;
                  padding: 0;

                  li {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    line-height: 20px;

                    span {
                      display: inline-block;
                      width: 1px;
                      height: 65%;
                      background-color: #8a8b9a;
                      margin: 0 10px;
                    }
                  }

                  li:last-child {
                    span {
                      width: 0;
                    }
                  }
                }

                font-size: 12px;
                font-weight: 400;
                color: #8c939f;
                line-height: 17px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 415px) {
  body {
    background-color: #f5f5f5;
  }
}
.wk_md {
  padding-top: 60px;
  .screen {
    margin: 0;
    background: #ffffff;

    .top {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-top: 1px solid #f5f5f5;

      input {
        width: 255px;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #f0f0f0;
        padding: 10px;
        box-sizing: border-box;
        border: 0;
        outline: none;
      }
      button {
        width: 80px;
        height: 40px;
        background: #1677ff;
        border-radius: 0px 4px 4px 0px;
        border: 0;
        outline: none;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .buttom {
      // height: 205px;
      background: #ffffff;
      border-radius: 5px;
      padding: 15px;

      ul {
        display: flex;
        justify-content: start;
        // margin-bottom: 20px;
        background-color: #fff;
        .color {
          color: #1677ff !important;
        }
        div:nth-child(1) {
        }
        div {
          display: flex;
          justify-content: start;
          flex-wrap: wrap;
        }

        li {
          text-align: left;
          margin-right: 20px;

          cursor: pointer;
        }

        li:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 700;
          color: #000000;
          line-height: 20px;
          white-space: nowrap;
        }

        li:nth-child(n + 2) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #787e8f;
          line-height: 17px;
          cursor: pointer;
          margin-bottom: 20px;
        }
      }

      p {
        text-align: left;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c5cacf;
        line-height: 17px;
        cursor: pointer;
      }
    }
  }
  .document {
    background-color: #f5f5f5;
    padding-bottom: 10px;
    .document_text {
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: #fff;
      span {
        display: inline-block;
        width: 2px;
        height: 14px;
        background: #1677ff;
      }
      p {
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 700;
        color: #292931;
        line-height: 21px;
        margin-left: 4px;
      }
    }
    .document_box {
      ul {
        li {
          padding: 10px 17px 14px 10px;
          background-color: #fff;
          margin: 5px 0;
          cursor: pointer;

          .document_list {
            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .is_pay {
                color: #22c668;
              }

              .left {
                display: flex;

                span:nth-child(1) {
                  display: inline-block;
                  width: 18px;
                  height: 18px;
                  line-height: 18px;
                  font-weight: 600;
                  font-size: 12px;
                  background: #ffffff;
                  border-radius: 3px;
                  border: 1px solid #fff;
                  text-align: center;
                  margin-right: 6px;
                }

                span:nth-child(2) {
                  text-align: left;
                  display: inline-block;
                  width: 260px;
                  font-size: 14px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 700;
                  color: #24272f;
                  line-height: 20px;
                  white-space: nowrap; //强制一行显示
                  overflow: hidden;
                  text-overflow: ellipsis;
                  letter-spacing: 0.5px;
                }
              }

              span {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ff3c3c;
                line-height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ff3c3c;
              }
            }

            p {
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #453c3c;
              line-height: 18px;
              text-align: left;
              margin: 10px 0;
              overflow: hidden; //强制多行显示
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            .information {
              ul {
                display: flex;

                li {
                  padding: 0;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  font-weight: 400;
                  color: #8a8b9a;
                  margin: 0;

                  span {
                    display: inline-block;
                    width: 1px;
                    height: 65%;
                    background-color: #8a8b9a;
                    margin: 0 10px;
                  }
                }

                li:last-child {
                  span {
                    width: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .more {
      width: 128px;
      height: 36px;
      line-height: 36px;
      margin: 20px auto 10px;
      border: 2px solid #e5e6ea;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #555c69;
      background-color: #fff;
    }
  }
  /deep/.el-input__inner {
    width: 180px !important;
    border-radius: 5px;
  }
}
</style>
