<template>
  <div>
    <!-- 资讯详情列表内容区域 -->
    <!-- PC端 -->
    <div v-if="show_pc">
      <Header :indexi="1"></Header>

      <div class="details_box">
        <!-- 左侧区域 -->
        <div class="details_left" v-loading="loading">
          <!-- 文章标题区域 -->
          <div class="subject">
            <p>{{ infoDetails.title }}</p>

            <div class="funct">
              <div class="funct_left">
                <ul>
                  <li>
                    <img src="../../assets/image/时间@2x.png" alt="" />
                    <span>{{ infoDetails.create_time }}</span>
                  </li>
                  <li>
                    <img src="../../assets/image/眼睛@2x.png" alt="" />
                    <span>{{ infoDetails.view_count }}</span>
                  </li>
                  <li>
                    <img src="../../assets/image/点赞@2x.png" alt="" />
                    <span>{{ infoDetails.praise_count }}</span>
                  </li>
                  <li>
                    <img src="../../assets/icon/标签@2x.png" alt="" />
                    <span>{{ infoDetails.cate_name }}</span>
                  </li>
                </ul>
              </div>
              <div class="funct_rigth">
                <ul>
                  <li>分享到</li>
                  <li>
                    <vshare :vshareConfig="vshareConfig"> </vshare>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- 文章内容区域 -->
          <div class="content">

            <div class="content_more" id="content_more_img">

              <!-- 相关阅读区域 -->
<!--              <div class="reading" style="padding: 0;">-->
<!--                <div class="text">-->
<!--                  <p>-->
<!--                    资讯简介-->
<!--                    <span></span>-->
<!--                  </p>-->
<!--                </div>-->

<!--                <div class="content" style="padding: 10px 0; margin: 0;">-->
<!--                  <p v-html="infoDetails.description" style="font-size: 16px">-->
<!--                    {{ infoDetails.description }}-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->

              <div class="reading" style="padding: 0;">
<!--                <div class="text">-->
<!--                  <p>-->
<!--                    资讯内容-->
<!--                    <span></span>-->
<!--                  </p>-->
<!--                </div>-->

                <div class="content" style="padding: 10px 0; margin: 0;">
                  <div v-html="infoDetails.content">
                    {{ infoDetails.content }}
                  </div>

                  <div class="seemore" v-if="see_more" @click="ClickMore">
                    <p>
                  <span>({{ infoDetails.price_str }}) 查看更多</span
                  ><img src="../../assets/icon/路径 5@2x.png" alt="" />
                    </p>
                  </div>
                </div>
              </div>


            </div>
            <div class="thumbs">
              <div
                v-if="infoDetails.is_praise == 1"
                @click="ClickThumbs"
                style="
                  background-color: #1677ff;
                  color: #fff;
                  border: 1px solid #1677ff;
                "
              >
                <img src="../../assets/icon/编组3@2x.png" alt="" />
                {{ infoDetails.praise_count }}
              </div>
              <div v-if="infoDetails.is_praise == 2" @click="ClickThumbs">
                点赞
                <img src="../../assets/icon/编组2@2x.png" alt="" />
                {{ infoDetails.praise_count }}
              </div>
              <div
                v-if="infoDetails.is_collect == 1"
                @click="ClickCollection"
                style="border: 1px solid #98c3ff; color: #1677ff"
              >
                <img src="../../assets/icon/编组@2x.png" alt="" />已收藏
              </div>
              <div v-if="infoDetails.is_collect == 2" @click="ClickCollection">
                <img src="../../assets/icon/编组备份@2x(1).png" alt="" />收藏
              </div>
            </div>
          </div>

          <!-- 附件 -->
          <div class="enclosure" v-if="file">
            <p>附件 <span></span></p>
            <p style="margin-top: 10px">
              <span
                @click="ClickDownload"
                style="font-size: 14px; color: #551a8b; cursor: pointer"
                >{{ infoDetails.file_name }} 下载附件</span
              >
              <!-- <a :href="file">{{ infoDetails.file_name }} 下载附件</a> -->
            </p>
          </div>

          <!-- 文章投票区域 -->
          <div class="vote" v-if="vote_data !== 0">
            <p>投票<span></span></p>
            <div class="programme">
              <p>{{ programmeList.title }}</p>
              <ul>
                <li
                  v-for="(item, index) in programmeList.answer"
                  :key="item.id"
                  @click="ClickVote(item.id)"
                  :class="{ voteColor: is_answer == 1 }"
                >
                  <span v-if="is_answer !== 1"> {{ item.title }}</span>

                  <div v-if="is_answer == 1" class="box">
                    <div
                      :style="{
                        width: (item.vote_count / tatolVote) * 100 + '%',
                      }"
                    ></div>
                    <span>{{ item.title }}</span>

                    <span> {{ item.vote_count }}人</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- 相关阅读区域 -->
          <div class="reading">
            <div class="text">
              <p>
                相关阅读
                <span></span>
              </p>
            </div>

            <div class="content">
              <ul>
                <li
                  v-for="(item, index) in relevantList"
                  :key="index"
                  @click="toInfoDetails(item)"
                >
                  <div class="image">
                    <img :src="item.cover" alt="" />
                  </div>
                  <p>{{ item.title }}</p>
                  <div class="see">
                    <img src="../../assets/image/眼睛@2x.png" alt="" />
                    <span>{{ item.view_count }}</span>
                    <span
                      style="float: right; color: red"
                      :class="{ color: item.price_str == '免费' }"
                    >
                      {{
                        item.is_pay == 2
                          ? item.is_owner
                            ? "已购买"
                            : item.price_str
                          : item.price_str
                      }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- 阅读推荐区域 -->
          <div class="reading">
            <div class="text">
              <p>
                阅读推荐
                <span></span>
              </p>
            </div>

            <div class="content">
              <ul>
                <li
                  v-for="(item, index) in readingList"
                  :key="index"
                  @click="toInfoDetails(item)"
                >
                  <div class="image">
                    <img :src="item.cover" alt="" />
                  </div>
                  <p>{{ item.title }}</p>
                  <div class="see">
                    <img src="../../assets/image/眼睛@2x.png" alt="" />
                    <span>{{ item.view_count }}</span>
                    <span
                      style="float: right; color: red"
                      :class="{ color: item.price_str == '免费' }"
                    >
                      {{
                        item.is_pay == 2
                          ? item.is_owner
                            ? "已购买"
                            : item.price_str
                          : item.price_str
                      }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- 评论区域 -->
          <div class="comment">
            <div class="text">
              <p>
                评论({{ totleComtent }})
                <span></span>
              </p>
            </div>
            <!-- 发布评论 -->
            <div class="comment_box">
              <el-input
                type="textarea"
                placeholder="说点什么..."
                v-model="queryInfo.input"
                maxlength="500"
                show-word-limit
              >
              </el-input>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 30px;
                "
              >
                <div style="display: flex">
                  <div style="width: 200px">
                    <el-input
                      placeholder="请输入图形验证码"
                      v-model="imgCodeInput"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div
                    style="
                      width: 80px;
                      heigth: 34px;
                      overflow: hidden;
                      margin-left: 15px;
                    "
                    @click="ClickImgCode"
                    v-loading="loading"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(225, 225, 225, 0.3)"
                  >
                    <img
                      v-if="!loading"
                      width="100%"
                      height="100%"
                      :src="imgCode"
                      alt=""
                    />
                  </div>
                </div>
                <div class="btn">
                  <button @click="ClickComment">评论</button>
                </div>
              </div>
            </div>
            <!-- 评论列表 -->
            <div class="commentlist">
              <div class="img" v-if="!totleComtent" style="margin-top: 20px">
                暂无评论...
              </div>
              <ul v-if="totleComtent > 0">
                <li v-for="(item, index) in commentList" :key="index">
                  <div class="commentlist_top">
                    <div>
                      <img :src="item.headimg" alt="" />
                    </div>
                    <div>
                      <p>{{ item.nickname }}</p>
                      <p>{{ item.create_time }}</p>
                    </div>
                  </div>
                  <div class="commentlist_bottom">
                    {{ item.content }}
                  </div>
                  <el-divider></el-divider>
                </li>
              </ul>
            </div>
            <!-- 展示更多评论 -->
            <div
              class="more_comment"
              v-if="commentList.length < totleComtent"
              @click="CliskMoreComments"
            >
              展示更多评论
            </div>

            <!-- 收起评论 -->
            <div
              class="more_comment"
              v-if="commentList.length > 10"
              @click="ClicKaway"
            >
              收起评论
            </div>
          </div>
        </div>

        <!-- 右侧区域 -->
        <div class="details_rigth">
          <div class="data">
            <!-- 头像 资料区域 -->
            <div class="top">
              <div class="portrait" style="background: #fff">
                <img :src="writer_info.headimg" alt="" />
              </div>
              <div class="infor">
                <p>
                  {{ writer_info.nickname }}
                </p>
                <p>
                  {{ writer_info.description }}
                </p>
              </div>
            </div>
            <!-- 文章数量区域 -->
            <div class="bottom" v-if="writerArticleList.length > 0">
              <div class="text">
                作者文章数 （{{ writer_info.article_count }}）
              </div>
              <div class="numders">
                <ul>
                  <li
                    v-for="(item, index) in writerArticleList"
                    :key="item.id"
                    @click="toInfoDetails(item)"
                  >
                    <p>{{ item.title }}</p>
                    <p>{{ item.create_time }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 专栏作者 -->
          <Author></Author>
          <!-- 企业推荐 -->
          <Enterprise></Enterprise>

          <!-- 最新活动 -->
          <Newactivity></Newactivity>

          <!-- 薪税课堂 -->
          <Classroom></Classroom>
          <!-- 热门文库 -->

          <Library></Library>
        </div>
      </div>
      <Footer></Footer>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <Header :indexi="1"></Header>

      <div class="details_md">
        <!-- 头部 -->
        <div class="subject">
          <p>{{ infoDetails.title }}</p>
          <div class="funct">
            <div class="funct_left">
              <ul>
                <li>
                  <img src="../../assets/image/时间@2x.png" alt="" />
                  <span>{{ infoDetails.create_time }}</span>
                </li>
                <li>
                  <img src="../../assets/image/眼睛@2x.png" alt="" />
                  <span>{{ infoDetails.view_count }}</span>
                </li>
                <li>
                  <img src="../../assets/image/点赞@2x.png" alt="" />
                  <span>{{ infoDetails.praise_count }}</span>
                </li>
                <li>
                  <img src="../../assets/icon/标签@2x.png" alt="" />
                  <span>{{ infoDetails.cate_name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 文章内容区域 点赞收藏 -->
        <div class="content">
          <!-- 文章内容区域 -->
          <div class="content_more">
            <div id="content_more_img">
<!--              <p v-html="infoDetails.description">-->
<!--                {{ infoDetails.description }}-->
<!--              </p>-->
              <div v-html="infoDetails.content" class="info">
                {{ infoDetails.content }}
              </div>
              <div class="seemore" v-if="see_more" @click="ClickMore">
                <p>
                  <span>({{ infoDetails.price_str }}) 查看更多</span
                  ><img src="../../assets/icon/路径 5@2x.png" alt="" />
                </p>
              </div>
            </div>
          </div>
          <!-- 点赞收藏 -->
          <div class="thumbs">
            <div
              v-if="infoDetails.is_praise == 1"
              @click="ClickThumbs"
              style="
                background-color: #1677ff;
                color: #fff;
                border: 1px solid #1677ff;
              "
            >
              <img src="../../assets/icon/编组3@2x.png" alt="" />
              {{ infoDetails.praise_count }}
            </div>
            <div v-if="infoDetails.is_praise == 2" @click="ClickThumbs">
              点赞
              <img src="../../assets/icon/编组2@2x.png" alt="" />
              ({{ infoDetails.praise_count }})
            </div>
            <div
              v-if="infoDetails.is_collect == 1"
              @click="ClickCollection"
              style="border: 1px solid #98c3ff; color: #1677ff"
            >
              <img src="../../assets/icon/编组@2x.png" alt="" />已收藏
            </div>
            <div v-if="infoDetails.is_collect == 2" @click="ClickCollection">
              <img src="../../assets/icon/编组备份@2x(1).png" alt="" />收藏
            </div>
          </div>
        </div>

        <!-- 附件 -->
        <div
          class="enclosure"
          v-if="file"
          style="display: flex; align-items: center; margin: 10px"
        >
          <p>附件 <span></span></p>
          <p style="margin-left: 10px">
            <span
              @click="ClickDownload"
              style="font-size: 14px; color: #551a8b; cursor: pointer"
              >{{ infoDetails.file_name }} 下载附件</span
            >
          </p>
        </div>
        <!-- 文章投票区域 -->
        <div class="vote" v-if="vote_data !== 0">
          <p>投票 <span></span></p>
          <div class="programme">
            <p>{{ programmeList.title }}</p>
            <ul>
              <li
                v-for="(item, index) in programmeList.answer"
                :key="item.id"
                @click="ClickVote(item.id)"
                :class="{ voteColor: is_answer == 1 }"
              >
                <span v-if="is_answer !== 1"> {{ item.title }}</span>

                <div v-if="is_answer == 1" class="box">
                  <div
                    :style="{
                      width: (item.vote_count / tatolVote) * 100 + '%',
                    }"
                  ></div>
                  <span>{{ item.title }}</span>

                  <span> {{ item.vote_count }}人</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- 评论区域 -->
        <div class="comment">
          <div class="text">
            <p>
              评论({{ totleComtent }})
              <span></span>
            </p>
          </div>

          <!-- 评论列表 -->
          <div class="commentlist">
            <div class="img" v-if="!totleComtent">
              <img src="../../assets/image/评论@2x.png" alt="" />
            </div>
            <ul>
              <li v-for="(item, index) in commentList" :key="index">
                <div class="commentlist_top">
                  <div>
                    <img :src="item.headimg" alt="" />
                  </div>
                  <div>
                    <p>{{ item.nickname }}</p>
                    <p>{{ item.create_time }}</p>
                  </div>
                </div>
                <div class="commentlist_bottom">
                  {{ item.content }}
                </div>
              </li>
            </ul>
          </div>
          <!-- 展示更多评论 -->
          <div
            class="more_comment"
            v-if="commentList.length < totleComtent"
            @click="CliskMoreComments"
          >
            展示更多评论
          </div>

          <div
            class="more_comment"
            v-if="commentList.length == totleComtent && commentList.length > 0"
          >
            没有更多了
          </div>

          <!-- 收起评论 -->
          <!-- <div
            class="more_comment"
            v-if="commentList.length > 10"
            @click="ClicKaway"
          >
            收起评论
          </div> -->
        </div>

        <!-- 评论转发区域 -->
        <div class="forward" v-if="!showInput">
          <div class="forward_left">
            <ul>
              <li @click="ClickShow">
                <img src="../../assets/icon/微信@2x.png" alt="" />
                <p @click="ClickShera">微信分享</p>
              </li>
              <!-- <li>
                <img src="" alt="" />
                <p>站内转发</p>
              </li> -->
              <li @click="ClickShowInput">
                <img src="../../assets/icon/评论@2x.png" alt="" />
                <p>评论</p>
              </li>
            </ul>
          </div>
          <div class="forward_right" @click="ClickExtension">
            生成个人推广文章
          </div>
        </div>
        <!-- @blur.native.capture="showInput = false" -->
        <!-- autofocus="true" -->
        <div
          style="position: fixed; bottom: -1px; width: 100%"
          v-if="showInput"
        >
          <div
            style="
              display: flex;
              width: 100%;
              padding: 5px 10px 0;
              box-sizing: border-box;
              background-color: #fff;
            "
          >
            <div style="width: 100%; margin-right: 10px">
              <el-input
                placeholder="请输入图形验证码"
                v-model="imgCodeInput"
                show-word-limit
              >
              </el-input>
            </div>
            <div
              style="width: 80px; heigth: 34px; overflow: hidden"
              @click="ClickImgCode"
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(225, 225, 225, 0.3)"
            >
              <img
                v-if="!loading"
                width="100%"
                height="100%"
                :src="imgCode"
                alt=""
              />
            </div>
          </div>
          <!-- autofocus="true"
              @blur.native.capture="showInput = false" -->
          <div class="input">
            <el-input v-model="queryInfo.input" placeholder="请输入内容">
            </el-input>
            <span @click.stop="ClickComment">发送</span>
          </div>
        </div>

        <el-dialog top="1vh" title="" :visible.sync="dialogVisible" width="30%">
          <div style="width: 100%; margin-left: 15px">
            <img style="width: 100%" src="../../assets/image/分享.png" alt="" />
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../../components/header";
import Footer from "../../components/footer";
import Author from "../../components/author";
import Enterprise from "../../components/enterprise";
import Newactivity from "../../components/newactivity";
import Classroom from "../../components/classroom";
import Library from "../../components/library";
import { Message } from "element-ui";
import { log } from "util";
// 分享
import vshare from "vshare";

import wx from "weixin-js-sdk";
import { isWeixin } from "../../utils";

export default {
  name: "async",
  components: {
    Header,
    Footer,
    Author,
    Enterprise,
    Newactivity,
    Classroom,
    Library,
  },
  metaInfo() {
    return {
      title: this.infoDetails.title
        ? this.infoDetails.title + " | 新税网"
        : window.location.href,

      meta: [
        {
          name: "description",
          content: this.infoDetails.description,
        },
        {
          name: "keywords",
          content: this.infoDetails.title,
        },
        {
          name: "author",
          content: this.writer_info.nickname + " - 新税网",
        },
      ],
    };
  },
  beforeCreate() {
    window._bd_share_main = "";
  },
  data() {
    return {
      dialogVisible: false,
      // 图形验证码加载...
      loading: false,
      // 默认显示 PC端的css
      show_pc: true,

      // 更多转发分享
      vshareConfig: {
        shareList: [
          "weixin",
          "qzone",
          "tsina",
          "sqq",
          // 此处放分享列表（ID）
        ],
        common: {
          //此处放置通用设置
          // bdText: "新税",
          // bdDesc: "新税网站",
          // bdUrl: "http://www.baidu.com",
          bdSign: "off",
        },

        share: [
          {
            //此处放置分享按钮设置
          },
        ],
        slide: [],
        selectShare: [
          //此处放置划词分享设置
          { bdContainerClass: true },
        ],
      },

      // 图片验证码输入框
      imgCodeInput: "",
      // 验证码图片
      imgCode: "",
      // 验证码key
      key: "",

      // 选中
      indexI: 1,
      // 资讯详情id user_id user_token
      id: "",
      // 资讯详情
      infoDetails: {},

      // 附件
      file: "",

      // 是否投票
      is_answer: "",

      // 方案个数列表
      programmeList: "",
      // 投票信息
      voteInfo: "",
      // 投票总条数
      tatolVote: "",
      // 判断有没有投票功能
      vote_data: 0,
      //    相关阅读列表
      relevantList: [],
      // 推荐阅读列表
      readingList: [],

      queryInfo: {
        // 评论输入框
        input: "",
        // 当前页
        pagenum: 1,
        // 每一页总条数
        pagesize: 10,
      },
      // 评论总条数
      totleComtent: "",

      // 评论列表
      commentList: [],
      // 作者信息
      writer_info: "",

      // 作者文章列表
      writerArticleList: [],

      // 移动端
      // input框显示自动聚焦
      showInput: false,
      // loading: false,

      see_more: false,
    };
  },

  created() {
    console.log("地址");
    console.log(window.location.href);
    // 判断是PC端还是移动端
    this.loading = true;
    this.show_pc = this.$store.state.show_pc;

    console.log("进入资讯详情"); // 获取 资讯id
    this.id = this.$route.query.id;
    // 获取资讯详情
    this.getInfoDetails();

    // 获取投票信息
    // this.getVote();
    // 相关阅读
    // this.getReadList();
    // 推荐阅读
    this.getRecommendList();
    // 获取评论列表
    this.getCommentList();

    // 获取图片地址
    this.getImgCode();
  },
  methods: {
    // 下载
    ClickDownload() {
      this.$http
        .post("index.php/api/v1_0_0.article/do_download", { id: this.id })
        .then(({ data }) => {
          window.open(
            data.download_url +
              "&user_id=" +
              localStorage.getItem("user_id") +
              "&user_token=" +
              localStorage.getItem("user_token")
          );
        })
        .catch(() => {});
    },
    ClickShow() {
      this.dialogVisible = true;
    },
    // 点击分享
    ClickShera() {
      this.dialogVisible = true;
    },
    // 获取图片地址
    getImgCode() {
      // 图形验证码加载...
      this.loading = true;
      this.$http
        .post("index.php/api/v1_0_0.ordinary/get_verify", {})
        .then((res) => {
          console.log("图片");
          this.loading = false;

          this.imgCode = res.data.verify;
          this.key = res.data.key;
          console.log(res.data);
        })
        .catch(() => {});
    },
    // 刷新图形验证码
    ClickImgCode() {
      this.getImgCode();
    },
    // 点击更多
    ClickMore() {
      this.$http
        .post("/index.php/api/v1_0_0.article/check_balance", {
          id: this.id,
        })
        .then((res) => {
          console.log(res);
          if (res.data.balance) {
            if (res.data.safe_password) {
              this.$confirm(res.msg, "提示", {
                confirmButtonText: "去设置",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$router.push({
                    path: "/personal/financial",
                    query: {
                      tabs: "支付密码设置",
                    },
                  });
                })
                .catch(() => {});
            } else {
              this.$prompt("请输入密码", res.msg, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPlaceholder: "请输入支付密码",
                inputType: "password",
                inputValidator: function (v) {
                  if (!v) return "请输入支付密码";
                },
              })
                .then(({ value }) => {
                  this.$http
                    .post("/index.php/api/v1_0_0.article/do_pay", {
                      id: this.id,
                      password: value,
                    })
                    .then((res) => {
                      if (res.data.need_password) {
                        //没有设置支付密码
                        this.$confirm(pay.msg, "提示", {
                          confirmButtonText: "设置",
                          cancelButtonText: "取消",
                          type: "warning",
                        })
                          .then(() => {
                            this.$router.push({
                              path: "/personal/financial",
                              query: {
                                tabs: "支付密码设置",
                              },
                            });
                          })
                          .catch(() => {});
                        return;
                      }
                      // 加入成功，进入直播页面
                      // infoDetails.content
                      console.log(res, "加入成功");
                      Message.success("资讯付费成功");
                      this.infoDetails.content = res.data.content;
                      this.see_more = !this.see_more;
                    })
                    .catch((error) => {});
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消支付",
                  });
                });
            }
          } else {
            this.$confirm(
              res.msg,
              this.infoDetails.unit == "元" ? "余额不足" : "获取不足",
              {
                confirmButtonText:
                  this.infoDetails.unit == "元" ? "去充值" : "获取积分",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                // TODO 去充值的跳转
                this.$router.push({
                  path: "/personal/financial",
                  query: {
                    tabs: "我的余额",
                  },
                });
              })
              .catch(() => {});
          }
        })
        .catch((error) => {});
    },

    toInfoDetails(item) {
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {
            this.$router.push({
              path: "/zx/details",
              query: {
                id: item.id,
              },
            });
            location.reload();
          });
        return;
      }

      this.$router.push({
        path: "/zx/details",
        query: {
          id: item.id,
        },
      });
      location.reload();
      // this.id = item.id;
      // this.getInfoDetails();
      // return false;

      // 直接进入详情
      if (item.is_pay == 1 || item.is_owner) {
        this.$router.push({
          path: "/zx/details",
          query: {
            id: item.id,
          },
        });
        location.reload();
        return false;
      }

      // 需要付费
      if (item.is_pay == 2) {
        this.$http
          .post("/index.php/api/v1_0_0.article/check_balance", {
            id: item.id,
          })
          .then((res) => {
            console.log(res);
            if (res.data.balance) {
              if (res.data.safe_password) {
                this.$confirm(res.msg, "提示", {
                  confirmButtonText: "去设置",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.$router.push({
                      path: "/personal/financial",
                      query: {
                        tabs: "支付密码设置",
                      },
                    });
                  })
                  .catch(() => {});
              } else {
                this.$prompt("请输入密码", res.msg, {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  inputPlaceholder: "请输入支付密码",
                  inputType: "password",
                  inputValidator: function (v) {
                    if (!v) return "请输入支付密码";
                  },
                })
                  .then(({ value }) => {
                    this.$http
                      .post("/index.php/api/v1_0_0.article/do_pay", {
                        id: item.id,
                        password: value,
                      })
                      .then((res) => {
                        // 加入成功，进入直播页面
                        console.log(res);
                        Message.success("购买资讯成功");
                        this.$router.push({
                          path: "/zx/details",
                          query: {
                            id: item.id,
                          },
                        });
                        location.reload();
                      })
                      .catch((error) => {});
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消支付",
                    });
                  });
              }
            } else {
              this.$confirm(res.msg, "余额不足", {
                confirmButtonText: "获取积分",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  // TODO 去充值的跳转
                  this.$router.push({
                    path: "/personal/financial",
                    query: { i: 1 },
                  });
                })
                .catch(() => {});
            }
          })
          .catch((error) => {});
      }
    },

    // 获取资讯详情
    getInfoDetails() {
      this.$http
        .post("/index.php/api/v1_0_0.article/get_detail", {
          id: this.id,
        })
        .then((res) => {
          this.loading = false;

          this.is_answer = res.data.is_answer;
          this.infoDetails = res.data;

          this.writer_info = res.data.writer_info;
          this.programmeList = res.data.vote_data
            ? res.data.vote_data.vote
            : [];
          this.vote_data = res.data.vote_data
            ? res.data.vote_data.vote.answer.length
            : 0;
          this.tatolVote = res.data.vote_data
            ? res.data.vote_data.vote.vote_count
            : 0;
          this.writerArticleList = res.data.writer_article_list;
          this.file = res.data.file;

          // 判断是否收费
          this.see_more = !res.data.is_owner;
          // 相关阅读
          this.getReadList();

          if (isWeixin()) {
            var link = window.location.href;
            console.log(link);
            // link = 'http://www.xinshui.com/index.php/api/v1_0_0.article/wxredirect?url='+encodeURIComponent(link);
            var title = res.data.title;
            var desc = res.data.description;
            var imgUrl = res.data.cover;

            this.getWxShareConfig(link, title, desc, imgUrl);
          }
        });
    },

    getWxShareConfig(link, title, desc, imgUrl) {
      this.$http
        .post("index.php/api/v1_0_0.article/do_share", {
          aid: this.id,
          url: window.location.href,
        })
        .then(({ data: res }) => {
          console.log(res);

          let appid = res.share.appId;
          let timestamp = res.share.timestamp;
          let noncestr = res.share.nonceStr;
          let signature = res.share.signature;
          wx.config({
            debug: false, // 是否开启调试模式
            appId: appid, //appid
            timestamp: timestamp, // 时间戳
            nonceStr: noncestr, // 随机字符串
            signature: signature, // 签名
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"], // 需要使用的
          });
          wx.ready(function () {
            // 分享给好友
            wx.onMenuShareAppMessage({
              title: title, // 分享标题
              desc: desc, // 分享描述
              link: link, // 分享链接
              imgUrl: imgUrl, // 分享图标
              success: function () {
                console.log("分享成功");
              },
              cancel: function () {
                console.log("取消了分享");
              },
            });
            // 分享到朋友圈
            wx.onMenuShareTimeline({
              title: title, // 分享标题
              link: link, // 分享链接
              imgUrl: imgUrl, // 分享图标
              success: function () {
                console.log("分享成功");
              },
              cancel: function () {
                console.log("取消了分享");
              },
            });
          });
          wx.error(function (res) {
            console.log("失败");
            console.log(res);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 点赞 或 取消点赞
    ClickThumbs() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_praise", {
          id: this.id,
          type: 1,
        })
        .then((res) => {
          Message.success("操作成功");
          this.infoDetails.praise_count = res.data.praise_count;
          this.infoDetails.is_praise = res.data.is_praise;
          console.log(res.data);
        })
        .catch((error) => {});
    },

    // 收藏 或 取消收藏
    ClickCollection() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_collect", {
          id: this.id,
          type: 1,
        })
        .then((res) => {
          Message.success("操作成功");
          this.infoDetails.is_collect = res.data.is_collect;
          this.infoDetails.collect_count = res.data.collect_count;
          console.log(res);
        })
        .catch((error) => {});
    },
    // 点击投票
    ClickVote(id) {
      if (this.is_answer !== 1) {
        console.log(this.infoDetails.is_answer);
        this.$http
          .post("/index.php/api/v1_0_0.article/do_vote", {
            aid: this.id,
            vid: id,
          })
          .then((res) => {
            Message.success("投票成功");
            this.is_answer = res.data.is_answer;
            this.programmeList.answer = res.data.vote_data.vote.answer;
            this.tatolVote = res.data.vote_data.vote.vote_count;
            this.voteInfo = res.data;
          })
          .catch((error) => {});
      }
    },

    // 相关阅读
    getReadList() {
      this.$http
        .post("/index.php/api/v1_0_0.article/get_list", {
          page: 1,
          page_size: 6,
          cid: this.infoDetails.cid,
        })
        .then((res) => {
          this.relevantList = res.data.list;
        })
        .catch((error) => {});
    },

    // 推荐阅读
    getRecommendList() {
      this.$http
        .post("/index.php/api/v1_0_0.article/get_list", {
          page: 1,
          page_size: 6,
          is_recommend: 1,
        })
        .then((res) => {
          this.readingList = res.data.list;
        })
        .catch((error) => {});
    },
    // 点击评论
    ClickComment() {
      this.showInput = false;

      if (this.queryInfo.input <= 0) {
        Message.error("请输入评论内容");
        return false;
      }
      if (this.queryInfo.input < 2) {
        Message.error("评论过短");
        return false;
      }

      if (!this.imgCodeInput) {
        Message.error("请输入图形验证码");
        return false;
      }

      this.$http
        .post("index.php/api/v1_0_0.ordinary/check", {
          verify_code: this.imgCodeInput,
          key: this.key,
        })
        .then(() => {
          this.showInput = false;
          this.queryInfo.pagenum = 1;
          this.queryInfo.pagesize = 10;
          this.$http
            .post("/index.php/api/v1_0_0.interaction/do_comment", {
              id: this.id,
              content: this.queryInfo.input,
              type: 1,
              verify_code: this.imgCodeInput,
              key: this.key,
            })
            .then((res) => {
              this.queryInfo.input = "";
              this.imgCodeInput = "";
              Message.success("提交成功审核通过后方显示");
              this.getImgCode();
              this.getCommentList();
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    },
    // 获取评论列表
    getCommentList() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/comment_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          id: this.id,
          type: 1,
        })
        .then((res) => {
          this.totleComtent = res.data.total_count;
          this.commentList = res.data.list;
        })
        .catch((error) => {});
    },
    // 点击加载更多评论
    CliskMoreComments() {
      this.queryInfo.pagenum++;
      this.$http
        .post("/index.php/api/v1_0_0.interaction/comment_list", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          id: this.id,
          type: 1,
        })
        .then((res) => {
          this.totleComtent = res.data.total_count;

          console.log(res.data);
          res.data.list.forEach((i) => {
            this.commentList.push(i);
          });
          console.log(this.commentList);
        })
        .catch((error) => {});
    },
    // 收起评论
    ClicKaway() {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = 10;
      this.getCommentList();
    },

    // 点击作者文章
    ClickinfoDetails(id) {
      this.$router.push({ path: "/zx/details", query: { id: id } });
    },
    ClickShowInput() {
      this.showInput = !this.showInput;
    },
    // 点击个人推广文章
    ClickExtension() {
      this.$http
        .post("/index.php/api/v1_0_0.article/popularize_article", {
          aid: this.id,
        })
        .then((data) => {
          console.log(data);
          this.$router.push({
            path: "/zx/details/extension",
            query: { uid: localStorage.getItem("user_id"), aid: this.id },
          });
        })
        .catch((error) => {
          // 400 升级
          if (error.code == 400) {
            // this.$router.push({ path: "personal", query: { tabs: 3 } });
            this.$confirm(error.msg, "提示", {
              confirmButtonText: "去升级",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  path: "/personal/financialMobile",
                });
              })
              .catch(() => {});
          }
          // 401推广
          if (error.code == 401) {
            // this.$router.push({ path: "personal", query: { tabs: 3 } });
            this.$confirm(error.msg, "提示", {
              confirmButtonText: "去完善",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  path: "/personal/userMobile",
                });
              })
              .catch(() => {});
          }
        });
    },
  },
  mounted() {},
};
</script>
<style lang='less' scoped>

/deep/.el-divider--horizontal {
  margin: 10px 0;
}

a {
  text-decoration: none;
  font-size: 15px;
}
a[href$=".pdf"]:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  width: 18px;
  height: 18px;
  background: url(http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201210/201121_517597.png)
    center/20px no-repeat;
  padding: 3px;
}
a[href$=".docx"]:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  width: 18px;
  height: 18px;
  background: url(http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20201210/201121_517597.png)
    center/20px no-repeat;
  padding: 3px;
}

.details_box {
  // margin-top: 50px;
  padding-top: 50px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  // margin: 0 10.45%;
  margin: 0 auto;
  //   height: auto;

  .details_left {
    width: 63.7%;
    height: auto;
    .subject {
      p {
        font-size: 30px;
        font-weight: 600;
        color: #020202;
        line-height: 42px;
        margin-bottom: 25px;
        text-align: left;
        overflow: hidden; //强制多行显示
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .funct {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #d8d8d8;
        padding: 10px 0;
        align-items: center;

        .funct_left {
          ul {
            display: flex;
            justify-content: start;
            align-items: center;
            li {
              padding-right: 20px;
              display: flex;
              align-items: center;

              img {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }
              span {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9a9a9a;
                line-height: 20px;
              }
            }
          }
        }
        .funct_rigth {
          ul {
            display: flex;
            justify-content: start;
            align-items: center;
            li {
              padding-left: 14px;
            }
            li:nth-child(1) {
              font-size: 14px;
              font-weight: 400;
              color: #292931;
              line-height: 20px;
            }
            li:nth-child(n + 1) {
              display: flex;
              align-items: center;
              cursor: pointer;

              img {
                width: 26px;
                height: 26px;
              }
            }
          }
        }
      }
    }
    .content {
      padding-top: 23px;
      overflow: hidden;
      .content_more {
        // height: 120px;
        min-height: 120px;
        position: relative;
        .seemore {
          position: absolute;
          bottom: 0px;

          width: 100%;
          height: 120px;
          line-height: 120px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.9) 15%,
            #ffffff 100%
          );
          border-radius: 5px;

          color: #1677ff;
          p {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          img {
            display: inline-block;
            width: 13px;
            height: 6px;
            margin-left: 5px;
          }
        }
      }
      .thumbs {
        display: flex;
        justify-content: center;
        margin: 30px 0;

        div {
          width: 116px;
          height: 38px;
          background: #eee;
          border-radius: 25px;
          font-weight: 400;
          color: #999;
          margin: 0 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #eee;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          cursor: pointer;
          img {
            display: inline-block;
            width: 16px;
            height: 16px;
            padding: 0 5px;
          }
        }
        div:nth-child(2) {
          border: 1px solid #eee;
          background-color: #fff;
          color: #999;
        }
      }
    }
    .enclosure {
      padding: 20px;
      margin-bottom: 10px;
      // background: #f8f8f9;
      p {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        color: #1a1412;
        line-height: 25px;
      }
      p:nth-child(1) {
        span {
          display: block;
          width: 32px;
          height: 3px;
          background: #1677ff;
          margin-top: 5px;
        }
      }
    }
    .vote {
      padding: 20px;
      margin-bottom: 10px;
      background: #f8f8f9;
      p {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        color: #1a1412;
        line-height: 25px;
      }
      p:nth-child(1) {
        span {
          display: block;
          width: 32px;
          height: 3px;
          background: #1677ff;
          margin-top: 5px;
        }
      }
      p:nth-child(2) {
        padding: 20px 0;
      }
      .programme {
        padding-bottom: 28px;
        p {
          margin: 25px 0;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .voteColor {
            border: 0px solid #eef3f7 !important;
            background-color: #eef3f7 !important;
          }
          li {
            position: relative;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 65%;
            height: 44px;
            line-height: 44px;
            border: 1px solid #b8bec1;
            border-radius: 5px;
            margin-top: 15px;
            font-size: 14px;
            font-weight: 600;
            color: #292931;
            background-color: #fff;

            cursor: pointer;
            .box {
              width: 100%;
              height: 100%;
              div {
                position: absolute;
                left: 0;
                top: 0;
                // padding: 0 14px;
                height: 100%;
                background-color: #1677ff;
                border-radius: 5px;
                display: flex;
                justify-content: space-between;
              }
              span:nth-child(2) {
                display: inline-block;
                height: 34px;
                position: absolute;
                left: 0;
                font-size: 14px;
                // font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 400;
                color: #fff;
                text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
                margin-left: 20px;
              }
              span:nth-child(3) {
                display: inline-block;
                height: 34px;
                position: absolute;
                right: 0;
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
    .reading {
      padding: 20px;
      background-color: #fff;
      margin-bottom: 10px;

      .text {
        p {
          text-align: left;
          font-size: 18px;
          font-weight: 600;
          color: #292931;
          line-height: 25px;
          span {
            display: block;
            width: 64px;
            height: 3px;
            background: #1677ff;
            margin-top: 5px;
          }
        }
      }
      .content {
        ul {
          display: flex;
          // justify-content: space-between;
          flex-wrap: wrap;
          li:nth-child(2) {
            margin: 30px 25px 0;
          }
          li:nth-child(5) {
            margin: 30px 25px 0;
          }

          li {
            margin-top: 30px;
            width: 31%;
            cursor: pointer;
            .image {
              width: 100%;
              height: 150px;
              background-color: pink;
              border-radius: 5px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }
            p {
              height: 40px;
              text-align: left;
              font-size: 14px;
              font-weight: 400;
              color: #292931;
              line-height: 20px;
              margin: 8px 0;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
      }
      .see {
        text-align: left;
        img {
          width: 15px;
          height: 13px;
        }
        span {
          padding: 0 5px;
          font-size: 14px;
          font-weight: 400;
          color: #9a9a9a;
          line-height: 20px;
        }
        .color {
          color: #1677ff !important;
        }
      }
    }
    .comment {
      background-color: #fff;
      padding: 20px;
      .text {
        p {
          text-align: left;
          font-size: 18px;
          font-weight: 600;
          color: #292931;
          line-height: 25px;
          span {
            display: block;
            width: 64px;
            height: 3px;
            background: #1677ff;
            margin-top: 5px;
          }
        }
      }
      .comment_box {
        margin-top: 20px;

        .el-textarea {
          margin: 15px 0;
          border-radius: 5px;
          /deep/.el-textarea__inner {
            border-radius: 5px;
          }
        }
        /deep/.el-loading-spinner {
          margin-top: -7px;
        }
        .btn {
          text-align: right;
          button {
            width: 98px;
            height: 34px;
            line-height: 34px;
            background: #eeeeee;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #9299a3;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }
      }
      .commentlist {
        .img {
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: center;
        }
        ul {
          li {
            /*margin-bottom: 10px;*/
            .commentlist_top {
              display: flex;
              justify-content: start;
              align-items: center;
              div:nth-child(1) {
                width: 58px;
                height: 58px;
                border-radius: 58px;
                overflow: hidden;
                img {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  background-color: pink;
                }
              }
              div:nth-child(2) {
                padding-left: 15px;

                p:nth-child(1) {
                  font-size: 16px;
                  font-weight: 600;
                  color: #292931;
                  line-height: 22px;
                }
                p:nth-child(2) {
                  font-size: 12px;
                  font-weight: 400;
                  color: #9293a0;
                  line-height: 17px;
                  text-align: left;
                }
              }
            }
            .commentlist_bottom {
              text-align: left;
              padding: 0 30px 0 73px;
              font-size: 14px;
              font-weight: 400;
              color: #5a5b63;
              line-height: 22px;
            }
          }
        }
      }
      .more_comment {
        width: 124px;
        height: 32px;
        line-height: 32px;
        background-color: #fff;
        border-radius: 16px;
        border: 1px solid #d1d2da;
        margin: 0 auto;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #8f9099;
        cursor: pointer;
      }
    }
  }

  .details_rigth {
    width: 32.4%;
    height: auto;
    .data {
      width: 100%;
      height: auto;
      .top {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: start;
        margin-bottom: 10px;
        .portrait {
          height: 104px;
          width: 104px;
          background-color: #999;
          box-sizing: border-box;
          overflow: hidden;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }

        .infor {
          width: calc(100% - 104px);
          height: 104px;
          margin-left: 14px;
          p {
            text-align: left;
          }
          p:nth-child(1) {
            font-size: 18px;
            font-weight: 600;
            color: #303030;
            line-height: 25px;
            margin-bottom: 10px;
          }
          p:nth-child(2) {
            font-size: 12px;
            font-weight: 400;
            color: #313a4a;
            line-height: 17px;
            overflow: hidden; //强制多行显示
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
          }
        }
      }
      .bottom {
        width: 100%;
        height: auto;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
          /*width: 72px;*/
          border-bottom: 2px solid #477ae4;
          font-size: 14px;
          font-weight: 400;
          color: #313a4a;
          line-height: 40px;
          margin-bottom: -2px;
          z-index: 11;
        }
        .numders {
          width: 100%;
          height: auto;
          background-color: #fff;
          border: 1px solid #e8e9ec;
          box-sizing: border-box;
          margin-bottom: 10px;
          ul {
            padding: 14px 20px 12px;
            li {
              width: 100%;
              text-align: left;
              margin-bottom: 15px;
              box-sizing: content-box;
              cursor: pointer;
              p:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                color: #313a4a;
                line-height: 20px;
                padding: 10px 0;
              }
              p:nth-child(2) {
                font-size: 12px;
                font-weight: 400;
                color: #8c939f;
                line-height: 17px;
              }
            }
          }
        }
      }
    }
  }
}
.el-button {
  border: 0;
  padding: 0;
}
/deep/.el-button:focus,
.el-button:hover {
  background-color: #fff;
}
/deep/.bdshare-slide-button-box .bdshare-slide-button {
  width: 0;
  // display: none !important;
}

// 移动端

.details_md {
  /deep/.el-dialog {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
    box-shadow: ;
    background-color: transparent;
    .el-dialog__headerbtn {
      display: none;
    }
    .el-dialog__header {
      padding: 0;
    }
  }
  background-color: #fff;
  padding-top: 60px;
  .subject {
    padding: 0 10px;
    p {
      text-align: left;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #020202;
      line-height: 25px;
      margin: 10px 0;
    }
    .funct {
      .funct_left {
        ul {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e8e9ee;
          padding-bottom: 8px;
          li {
            display: flex;
            align-items: center;
            margin-right: 15px;
            img {
              display: inline-block;
              width: 14px;
              height: 15px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9a9a9a;
              line-height: 20px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .content {
    margin-top: 7px;
    .content_more {
      min-height: 120px;
      position: relative;
      .info {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 35px;
        position: relative;
        padding: 0 10px;
      }
      .seemore {
        position: absolute;
        bottom: 0px;

        width: 100%;
        height: 120px;
        line-height: 120px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.7) 15%,
          #ffffff 100%
        );
        border-radius: 5px;

        color: #1677ff;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        img {
          display: inline-block;
          width: 13px;
          height: 6px;
          margin-left: 5px;
        }
      }
    }
    .thumbs {
      display: flex;
      justify-content: center;
      margin: 20px 0;

      div {
        width: 120px;
        height: 32px;
        background: #eee;
        border-radius: 25px;
        font-weight: 400;
        color: #999;
        margin: 0 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #eee;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        cursor: pointer;
        img {
          display: inline-block;
          width: 16px;
          height: 16px;
          padding: 0 5px;
        }
      }
      div:nth-child(2) {
        border: 1px solid #eee;
        background-color: #fff;
        color: #999;
      }
    }
  }
  .vote {
    padding: 10px;
    margin-bottom: 10px;
    background: #f8f8f9;
    p {
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      color: #1a1412;
      line-height: 25px;
    }
    p:nth-child(1) {
      span {
        display: block;
        width: 32px;
        height: 3px;
        background: #1677ff;
        margin-top: 5px;
      }
    }
    p:nth-child(2) {
      padding: 20px 0;
    }
    .programme {
      p {
        margin-top: 20px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1a1412;
        line-height: 21px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 6px;
        .voteColor {
          border: 0px solid #eef3f7 !important;
          background-color: #eef3f7 !important;
        }
        li {
          position: relative;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 300px;
          height: 34px;
          line-height: 34px;
          border: 1px solid #b8bec1;
          border-radius: 5px;
          margin-top: 15px;
          font-size: 14px;
          font-weight: 600;
          color: #292931;
          background-color: #fff;

          cursor: pointer;
          .box {
            width: 100%;
            height: 100%;
            div {
              position: absolute;
              left: 0;
              top: 0;
              // padding: 0 14px;
              height: 100%;
              background-color: #1677ff;
              border-radius: 5px;
              display: flex;
              justify-content: space-between;
            }
            span:nth-child(2) {
              position: absolute;
              left: 0;
              font-size: 14px;
              // font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 400;
              color: #fff;
              text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
              margin-left: 20px;
            }
            span:nth-child(3) {
              position: absolute;
              right: 0;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  .comment {
    background-color: #fff;
    padding: 10px 10px 130px;
    .text {
      p {
        text-align: left;
        font-size: 18px;
        font-weight: 600;
        color: #292931;
        line-height: 25px;
        span {
          display: block;
          width: 64px;
          height: 3px;
          background: #1677ff;
          margin-top: 5px;
        }
      }
    }

    .commentlist {
      margin-top: 20px;
      .img {
        width: 100%;
        // height: 300px;
        display: flex;
        justify-content: center;
        img {
          display: inline-block;
          width: 35%;
          height: 100%;
        }
      }
      ul {
        li {
          margin-bottom: 10px;
          .commentlist_top {
            display: flex;
            justify-content: start;
            align-items: center;
            div:nth-child(1) {
              width: 38px;
              height: 38px;
              border-radius: 58px;
              overflow: hidden;
              img {
                display: inline-block;
                width: 100%;
                height: 100%;
                background-color: pink;
              }
            }
            div:nth-child(2) {
              padding-left: 15px;

              p:nth-child(1) {
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #292931;
                line-height: 22px;
              }
              p:nth-child(2) {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9293a0;
                line-height: 17px;
                text-align: left;
              }
            }
          }
          .commentlist_bottom {
            text-align: left;
            padding: 0 30px 0 55px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #5a5b63;
          }
        }
      }
    }
    .more_comment {
      width: 124px;
      height: 32px;
      line-height: 32px;
      background-color: #fff;
      border-radius: 16px;
      border: 1px solid #d1d2da;
      margin: 0 auto;
      margin-top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: #8f9099;
      cursor: pointer;
    }
  }
  .forward {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 49px;
    padding: 6px 10px;
    background: #ffffff;
    border-top: 1px solid #ecebee;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .forward_left {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: calc(100% - 210px);

      ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        li {
          width: 36px;
          height: 36px;
          // background-color: pink;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          /deep/.bdshare-button-style0-16 a,
          .bdshare-button-style0-16 .bds_more {
            margin-bottom: 2px;
            margin-right: 0;
          }
          img {
            display: inline-block;
            width: 21px;
            height: 21px;
          }
          p {
            font-size: 9px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9d9ea8;
            line-height: 13px;
            white-space: nowrap;
            -webkit-transform: scale(0.8);
            margin-top: 4px;
          }
        }
      }
    }
    .forward_right {
      width: 210px;
      height: 36px;
      background: #1677ff;
      border-radius: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }
  }
  .input {
    border-radius: 16px;
    width: 100%;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: #fff;
    .el-input {
      width: calc(90% - 68px);

      /deep/.el-input__inner {
        width: 100%;
        border: 0;
      }
    }
    span {
      display: inline-block;
      width: 64px;
      height: 28px;
      line-height: 28px;
      background: #1677ff;
      border-radius: 16px;
    }
  }
  /deep/.el-loading-spinner {
    margin-top: -7px;
  }
}
</style>

<style >
img:hover {
  transform: scale(1);
}
</style>
