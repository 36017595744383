import { render, staticRenderFns } from "./zx.vue?vue&type=template&id=949483c0&scoped=true&"
import script from "./zx.vue?vue&type=script&lang=js&"
export * from "./zx.vue?vue&type=script&lang=js&"
import style0 from "./zx.vue?vue&type=style&index=0&id=949483c0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "949483c0",
  null
  
)

export default component.exports