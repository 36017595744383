<template>
  <div>
    <div v-if="show_pc" class="pc">
      <!-- 我的收藏列表 -->
      <div class="top">
        <!-- 收藏活动 -->
        <ul>
          <li
            v-for="(item, index) in collecList"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickMeCollectList(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <!-- 收藏内容 -->
      <div class="bottom">
        <!-- 活动 -->
        <ul v-if="i == 0">
          <li
            v-for="(item, index) in collectActivityList"
            :key="index"
            @click="ClickCollectActivityList(item)"
          >
            <div class="img"><img :src="item.cover" alt="" /></div>
            <div class="content">
              <p>{{ item.title }}</p>
              <p
                class="userContentText"
                style="height: 50px"
                v-html="item.description"
              >
                {{ item.description }}
              </p>
            </div>
            <div
              class="state"
              @click.stop="ClickCancelActivityCollect(item, 2)"
            >
              取消收藏
            </div>
          </li>
        </ul>
        <!-- 课程 -->
        <ul v-if="i == 1">
          <li
            v-for="(item, index) in collectClassList"
            :key="index"
            @click="ClickCollectClassList(item, index)"
          >
            <div class="img"><img :src="item.cover" alt="" /></div>
            <div class="content">
              <p>{{ item.title }}</p>
              <p class="userContentText" style="height: 50px" v-html="item.description">
                {{ item.description }}
              </p>
            </div>
            <div
              class="state"
              @click.stop="ClickCancelActivityCollect(item, 4)"
            >
              取消收藏
            </div>
          </li>
        </ul>
        <!-- 资讯 -->
        <ul v-if="i == 2">
          <li
            v-for="(item, index) in collectInfoList"
            :key="index"
            @click="ClickCancelInfoCollectList(item, index)"
          >
            <div class="img"><img :src="item.cover" alt="" /></div>
            <div class="content" id="content">
              <p>{{ item.title }}</p>
              <p class="userContentText" style="height: 50px; width: 90%" v-html="item.description">
                {{ item.description }}
              </p>
            </div>
            <div
              class="state"
              @click.stop="ClickCancelActivityCollect(item, 1)"
            >
              取消收藏
            </div>
          </li>
        </ul>
        <!-- 文库 -->

        <ul v-if="i == 3">
          <li
            v-for="(item, index) in collectLibraryList"
            :key="index"
            @click="ClickCollectLibraryList(item, index)"
            style="margin-bottom: 0"
          >
            <div style="text-align: left; width: 100%">
              <div style="display: flex; align-items: center; text-align: left">
                <div
                  id="wk_icon"
                  :style="{
                    color: item.type_color,
                    borderColor: item.type_color,
                  }"
                >
                  {{ item.type_name }}
                </div>
                <p
                  style="
                    margin-left: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #292931;
                    font-weight: 500;
                    font-size: 16px;
                    width: 86%;
                  "
                >
                  {{ item.title }}
                </p>
              </div>

              <div
                style="
                  margin-top: 10px;
                  text-align: left;
                  display: inline-block;
                  height: 60px;
                  display: flex;
                  justify-content: space-between;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #808080;
                  line-height: 20px;
                "
              >
                <div class="userContentText" style="width: 88%" v-html="item.description">
                  {{ item.description }}
                </div>
                <div
                  class="state"
                  @click.stop="ClickCancelActivityCollect(item, 3)"
                  style="
                    width: 65px;
                    display: inline-block;
                    text-align: center;
                    margin-left: 20px;
                  "
                >
                  取消收藏
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div
        style="font-size: 14px; color: #9c9cab; padding: 20px"
        v-if="total < 1"
      >
        <img src="../../../assets/image/缺省图.png" style="width: 13%" />
        <p style="margin-top: 15px">暂无列表</p>
      </div>

      <!-- 底部分页 -->
      <div class="page" style="padding: 25px 0 50px 0">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.pagenum"
          :page-size="queryInfo.pagesize"
          layout="prev, pager, next"
          :total="total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="!show_pc" id="mobile">
      <div style="width: 100%; height: 60px"></div>
      <Header></Header>
      <!-- 我的收藏列表 -->
      <div class="top">
        <div
          style="
            text-align: left;
            display: flex;
            align-items: center;
            line-height: 26px;
            padding-left: 20px;
            background-color: #fff;
            font-weight: 700;
          "
        >
          <p
            style="
              width: 3px;
              height: 18px;
              background-color: #1677ff;
              margin-right: 10px;
            "
          ></p>
          <p>我的收藏</p>
        </div>
        <!-- 收藏活动 -->
        <ul>
          <li
            v-for="(item, index) in collecList"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickMeCollectList(item, index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <!-- 收藏内容 -->
      <div class="bottom">
        <LoadMore
          :onLoadMore="onLoadMore"
          :enableLoadMore="enableLoadMore"
          ref="LoadMore"
        >
          <!-- 活动 -->
          <ul v-if="i == 0">
            <li v-for="(item, index) in collectActivityList" :key="index">
              <vue-sideslip
                @click="ClickCancelActivityCollect(item, 2)"
                :operationList="operationList"
                :name="index"
              >
                <div
                  style="display: flex; padding: 10px"
                  @click="ClickCollectActivityList(item)"
                >
                  <div class="img"><img :src="item.cover" alt="" /></div>
                  <div class="content">
                    <p>{{ item.title }}</p>
                    <p
                      class="userContentText"
                      style="margin-top: 5px; -webkit-line-clamp: 2"
                      v-html="item.description"
                    >
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </vue-sideslip>
            </li>
          </ul>

          <!-- 课程 -->

          <ul v-if="i == 1">
            <li v-for="(item, index) in collectClassList" :key="index">
              <vue-sideslip
                @click="ClickCancelActivityCollect(item, 4)"
                :operationList="operationList"
                :name="index"
              >
                <div
                  style="display: flex; padding: 10px"
                  @click="ClickCollectClassList(item)"
                >
                  <div class="img"><img :src="item.cover" alt="" /></div>
                  <div class="content">
                    <p>{{ item.title }}</p>
                    <p
                      class="userContentText"
                      style="margin-top: 5px; -webkit-line-clamp: 2"
                      v-html="item.description"
                    >
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </vue-sideslip>
            </li>
          </ul>

          <!-- 资讯 -->
          <ul v-if="i == 2">
            <li v-for="(item, index) in collectInfoList" :key="index">
              <vue-sideslip
                @click="ClickCancelActivityCollect(item, 1)"
                :operationList="operationList"
                :name="index"
              >
                <div
                  style="display: flex; padding: 10px"
                  @click="ClickCancelInfoCollectList(item)"
                >
                  <div class="img"><img :src="item.cover" alt="" /></div>
                  <div class="content">
                    <p class="userContentText" style="-webkit-line-clamp: 1">
                      {{ item.title }}
                    </p>
                    <p
                      class="userContentText"
                      style="margin-top: 5px; -webkit-line-clamp: 2"
                      v-html="item.description"
                    >
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </vue-sideslip>
            </li>
          </ul>

          <!-- 文库 -->
          <ul class="wk" v-if="i == 3">
            <li
              v-for="(item, index) in collectLibraryList"
              :key="index"
              style="padding-left: 10px"
            >
              <vue-sideslip
                @click="ClickCancelActivityCollect(item, 3)"
                :operationList="operationList"
                :name="index"
              >
                <div>
                  <div
                    class="title"
                    style="width: 100%"
                    @click="ClickCollectLibraryList(item)"
                  >
                    <div
                      id="wk_icon"
                      :style="{
                        color: item.type_color,
                        borderColor: item.type_color,
                      }"
                    >
                      {{ item.type_name }}
                    </div>
                    <p>{{ item.title }}</p>
                    <span
                      class="release_status"
                      v-if="item.status == 2"
                      style="background: #1677ff"
                      >待审核</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 3"
                      style="background: #ff7c7c"
                      >被驳回</span
                    >
                    <span
                      class="release_status"
                      v-if="item.status == 0"
                      style="background: red"
                      >已删除</span
                    >
                  </div>
                  <p class="userContentText" style="-webkit-line-clamp: 2" v-html="v-html="item.description"">
                    {{ item.description }}
                  </p>
                </div>
              </vue-sideslip>
            </li>
          </ul>
        </LoadMore>
      </div>

      <div
        style="font-size: 14px; color: #9c9cab; padding: 20px"
        v-if="total < 1"
      >
        <img src="../../../assets/image/缺省图.png" style="width: 25%" />
        <p style="margin-top: 15px">暂无列表</p>
      </div>
    </div>
  </div>
</template>
<script>
import LoadMore from "../../loadMore/loadMore.vue";
import Header from "../../header.vue";
export default {
  components: {
    Header,
    LoadMore,
  },
  data() {
    return {
      operationList: [
        // 侧滑菜单列表
        {
          name: "取消收藏",
          style:
            "background: #FF6F7B;color: #ffffff;line-height: 88px;width: 60px;text-align: center;font-size: 12px;height: 88px;",
          click: "stop",
          html: (item) => {
            return `<div>${item.name}</div>`;
          },
        },
      ],
      // 总条数
      total: 0,
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      user: "",
      // 我的收藏列表
      collecList: ["活动", "课程", "资讯", "文库"],
      //   默认第一个选中
      i: 0,
      //   收藏活动
      collectActivityList: [],
      //   收藏课程
      collectClassList: [],

      //   收藏资讯
      collectInfoList: [],
      //   收藏文库
      collectLibraryList: [],
      show_pc: true,
      enableLoadMore: true,
    };
  },
  created() {
    // document.querySelector('body').style.backgroundColor='#f5f5f5';
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.$parent.setIcon = "我的收藏";
    if (this.$route.params.tabs) {
      this.i = this.$route.params.tabs;
    } else {
      this.getHDlist();
    }
  },
  methods: {
    //移动端上拉刷新
    onLoadMore(done, total = "") {
      var list = [];
      if (this.i == 0) {
        list = this.collectActivityList;
      }
      if (this.i == 1) {
        list = this.collectClassList;
      }
      if (this.i == 2) {
        list = this.collectInfoList;
      }
      if (this.i == 3) {
        list = this.collectLibraryList;
      }
      if (this.total == list.length) {
        return;
      }
      console.log("下拉");
      console.log(done);
      if (!this.enableLoadMore) {
        return;
      }
      if (total == "") {
        this.queryInfo.pagenum++;
        console.log("页码");
        console.log(this.queryInfo.pagenum);
      }
      if (this.i == 0) {
        this.getHDlist();
        return;
      }
      if (this.i == 1) {
        this.getKClist();
        return;
      }
      if (this.i == 2) {
        this.getZXlist();
        return;
      }
      if (this.i == 3) {
        this.getWKlist();
        return;
      }
      // this.ClickMeCollectList("上拉", this.i);
    },
    // 点击我的收藏列表的函数
    ClickMeCollectList(item, i) {
      this.collectActivityList = [];
      //   收藏课程
      this.collectClassList = [];

      //   收藏资讯
      this.collectInfoList = [];
      //   收藏文库
      this.collectLibraryList = [];
      if (item != "上拉") {
        this.i = i;
        this.queryInfo.pagenum = 1;
      }
      if (this.i == 0) {
        this.getHDlist();
        return;
      }
      if (this.i == 1) {
        this.getKClist();
        return;
      }
      if (this.i == 2) {
        this.getZXlist();
        return;
      }
      if (this.i == 3) {
        this.getWKlist();
        return;
      }
    },
    getHDlist() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/my_collect_activity", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          console.log(data);
          this.fullscreenLoading = false;
          this.total = data.total_count;
          if (!this.show_pc) {
            data.total_count == this.collectActivityList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.collectActivityList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }
          this.collectActivityList = data.list;
        })
        .catch((error) => {});
    },
    getKClist() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/my_collect_course", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          this.fullscreenLoading = false;
          this.total = data.total_count;
          if (!this.show_pc) {
            data.total_count == this.collectClassList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.collectClassList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }
          this.collectClassList = data.list;
        })
        .catch((error) => {});
    },
    getZXlist() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/my_collect_article", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          this.fullscreenLoading = false;
          this.total = data.total_count;
          if (!this.show_pc) {
            data.total_count == this.collectInfoList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.collectInfoList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }
          this.collectInfoList = data.list;
        })
        .catch((error) => {});
    },
    getWKlist() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/my_collect_lib", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then(({ data }) => {
          console.log(data);
          this.fullscreenLoading = false;
          this.total = data.total_count;
          if (!this.show_pc) {
            data.total_count == this.collectLibraryList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.collectLibraryList.push(...data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }
          this.collectLibraryList = data.list;
        })
        .catch((error) => {});
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      console.log(newSize);
      // this.getGoodsList();
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      // this.getGoodsList();
      console.log(newNum, this.i);
      if (this.i == 0) {
        this.getHDlist();
        return;
      }
      if (this.i == 1) {
        this.getKClist();
        return;
      }
      if (this.i == 2) {
        this.getZXlist();
        return;
      }
      if (this.i == 3) {
        this.getWKlist();
        return;
      }
      // this.ClickMeCollectList("item", this.i);
    },

    // 点击取消活动收藏
    ClickCancelActivityCollect(item, v) {
      console.log(item);
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_collect", {
          type: v,
          id: item.id,
        })
        .then((data) => {
          console.log(data);
          this.$message.success(data.msg);
          this.ClickMeCollectList(item, this.i);
        })
        .catch((error) => {});
    },

    // 点击活动列表的函数
    ClickCollectActivityList(item) {
      // if (e.target.innerText == "取消收藏") {
      //   return;
      // }
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }
      console.log(item);
      this.$router.push({
        path: "/hd/activityDetails",
        query: {
          id: item.id,
        },
      });
    },

    // 点击课程列表的函数
    ClickCollectClassList(item) {
      // console.log("e");
      // console.log(e);
      // if (e.target.innerText == "取消收藏") {
      //   return;
      // }
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }
      this.$router.push({
        path: "/xskt/salaryClassroom",
        query: {
          id: item.id,
        },
      });
      console.log("课程-收藏", item);
    },

    // 点击资讯列表的函数
    ClickCancelInfoCollectList(item) {
      console.log(item);
      // if (e.target.innerText == "取消收藏") {
      //   return;
      // }
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }
      this.$router.push({
        path: "/zx/details",
        query: {
          id: item.id,
        },
      });
    },

    // 点击文库列表的函数
    ClickCollectLibraryList(item) {
      // console.log(e, item, index, "e,item, index");
      // if (e.target.innerText == "取消收藏") {
      //   return;
      // }
      console.log(item);
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {});
      }
      this.$router.push({
        path: "/wk/libraryDetails",
        query: {
          id: item.id,
        },
      });
    },
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f5f5f5");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>
<style lang='less' scoped>
#mobile {
  .top {
    ul {
      display: flex;
      justify-content: start;
      padding: 20px 0 20px 20px;
      background-color: #fff;
      margin-bottom: 5px;

      .color {
        color: #1677ff;
      }

      li {
        margin-right: 30px;

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 21px;
        cursor: pointer;
      }
    }
  }

  .bottom {
    ul {
      margin-bottom: 25px;
      overflow: auto;

      li {
        background-color: #fff;
        margin-bottom: 5px;
        display: flex;
        justify-content: start;
        height: 88px;
        /* margin: 20px 20px 40px 27px; */
        cursor: pointer;

        /* padding: 10px; */
        .img {
          /* width: 109px; */
          width: 35%;
          height: 68px;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            background-color: pink;
          }
        }

        .content {
          width: 75%;
          height: 68px;
          text-align: left;
          margin-left: 10px;
          box-sizing: border-box;

          p:nth-child(1) {
            width: 237px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #292931;
            line-height: 20px;
            white-space: nowrap; //强制一行显示
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p:nth-child(2) {
            width: 237px;
            height: 34px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #808080;
            line-height: 17px;
            overflow: hidden; //强制多行显示
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* padding: 10px 24px 10px 0; */
          }
        }

        .state {
          margin: auto 0;
          width: 65px;
          height: 24px;
          line-height: 24px;
          background: #ffffff;
          border-radius: 3px;
          border: 2px solid #1677ff;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1677ff;
        }
      }
    }

    #wk_icon {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 1px solid #292931;
      text-align: center;
      line-height: 18px;
      font-size: 10px;
      font-weight: 600;
    }

    .wk {
      li {
        display: flex;
        justify-content: space-between;
        /* padding: 0 20px 0 30px; */

        // height: 120px;
        div {
          /* width: 80%; */

          .title {
            display: flex;
            align-items: center;
            padding: 10px 0;
            justify-content: start;

            .img {
              width: 18px;
              height: 18px;
              border-radius: 3px;
              border: 1px solid #1677ff;
              margin-left: 0;

              img {
                width: 100%;
                height: 100%;
                background: pink;
              }
            }

            p {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #24272f;
              line-height: 22px;
              padding: 0 10px 0 6px;
            }

            .state {
              background: #ff7c7c !important;
            }

            span {
              display: inline-block;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 44px;
              height: 18px;
              background: #1677ff;
              border-radius: 5px 0px 5px 0px;
              box-sizing: border-box;

              p {
                padding: 0;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                -webkit-transform: scale(0.8);
              }
            }
          }

          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #453c3c;
            line-height: 20px;
            text-align: left;
          }
        }

        .edit {
          width: 18.5%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            display: inline-block;
            width: 48px;
            height: 24px;
            line-height: 24px;
            border-radius: 3px;
            margin-left: 16px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }

          span:nth-child(1) {
            background-color: #feb842;
          }

          span:nth-child(2) {
            background-color: #ff6f7b;
          }
        }
      }
    }
  }
}

.pc {
  .top {
    ul {
      display: flex;
      justify-content: start;
      padding: 20px 0 29px 5px;

      .color {
        color: #1677ff;
      }

      li {
        margin: 0 25px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 22px;
        cursor: pointer;
      }
    }
  }

  .bottom {
    ul {
      margin-bottom: 25px;
      overflow: auto;

      li {
        display: flex;
        justify-content: start;
        margin: 20px 20px 40px 27px;
        cursor: pointer;

        .img {
          width: 17.8%;
          height: 98px;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            background-color: pink;
          }
        }

        .content {
          width: 75%;
          height: 100%;
          text-align: left;
          margin-left: 19px;
          box-sizing: border-box;

          p:nth-child(1) {
            width: 95%;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #292931;
            line-height: 22px;
            white-space: nowrap; //强制一行显示
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #808080;
            line-height: 20px;
            padding: 10px 20px 10px 0;
          }
        }

        .state {
          margin: auto 0;
          width: 65px;
          height: 24px;
          line-height: 24px;
          background: #ffffff;
          border-radius: 3px;
          border: 2px solid #1677ff;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1677ff;
        }
      }
    }

    #wk_icon {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 1px solid #292931;
      text-align: center;
      line-height: 18px;
      font-size: 10px;
      font-weight: 600;
    }

    .wk {
      li {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 0 30px;

        // height: 120px;
        div {
          width: 80%;

          .title {
            display: flex;
            align-items: center;
            padding: 10px 0;
            justify-content: start;

            .img {
              width: 18px;
              height: 18px;
              border-radius: 3px;
              border: 1px solid #1677ff;
              margin-left: 0;

              img {
                width: 100%;
                height: 100%;
                background: pink;
              }
            }

            p {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #24272f;
              line-height: 22px;
              padding: 0 10px 0 6px;
            }

            .state {
              background: #ff7c7c !important;
            }

            span {
              display: inline-block;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 44px;
              height: 18px;
              background: #1677ff;
              border-radius: 5px 0px 5px 0px;
              box-sizing: border-box;

              p {
                padding: 0;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                -webkit-transform: scale(0.8);
              }
            }
          }

          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #453c3c;
            line-height: 20px;
            text-align: left;
          }
        }

        .edit {
          width: 18.5%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            display: inline-block;
            width: 48px;
            height: 24px;
            line-height: 24px;
            border-radius: 3px;
            margin-left: 16px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }

          span:nth-child(1) {
            background-color: #feb842;
          }

          span:nth-child(2) {
            background-color: #ff6f7b;
          }
        }
      }
    }
  }
}
</style>
