<template>
  <div class="bg" v-loading.fullscreen.lock="fullscreenLoading">
    <Header v-if="isRouterAlive"></Header>
    <div v-if="show_pc">
      <div class="consumer">
        <div class="consumer_lfet">
          <div class="top">
            <!-- 头像 -->
            <div class="head" v-if="isRouterAlive">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <div style="width: 88px; height: 88px; overflow: hidden">
                  <img :src="user.userInfo.headimg" class="avatar" />
                </div>
                <p class="updata_head">
                  <span>修改头像</span>
                </p>
              </el-upload>
              <p
                style="
                  position: relative;
                  display: flex;
                  justify-content: center;
                "
              >
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 80px;
                    display: inline-block;
                  "
                  >{{ user.userInfo.nickname }}</span
                >
                <span
                  class="grade"
                  v-if="isIdentity !== '企业'"
                  @click="ClickMemberLevel"
                  style="
                    background-color: #675bfa;
                    display: inline-block;
                    line-height: 18px;
                    color: #fff;
                    font-size: 12px;
                    border-radius: 10px;
                    padding: 2px 8px;
                    right: -45px;
                    top:1px
                    cursor: pointer;
                  "
                >
                  {{
                    userInfo.member_level == 1
                      ? "白领"
                      : userInfo.member_level == 2
                      ? "白银"
                      : userInfo.member_level == 3
                      ? "黄金"
                      : ""
                  }}
                </span>
              </p>
              <span
                style="font-size: 12px; margin-bottom: 10px; color: #bdc0c8"
                v-if="userInfo.member_end_time && userInfo.member_level > 1"
                >到期时间:{{ userInfo.member_end_time }}</span
              >
              <span
                style="font-size: 12px; margin-bottom: 10px; color: #bdc0c8"
                v-if="userInfo.is_business == 1"
                >到期时间:{{ userInfo.business_end_time }}</span
              >
            </div>

            <!-- 认证企业 申请作家专栏 -->
            <div class="prove1" v-if="!identity">
              <ul v-if="audit == 2 || audit == 0">
                <li @click="RZcompany(0)">{{ audit_company_str }}</li>
                <li>可上传招聘、企业、活动、资讯、 课程、文库信息</li>
              </ul>

              <ul v-if="audit == 1 || audit == 0">
                <li @click="RZcompany(1)">{{ audit_writer_str }}</li>
                <li>可上传活动、资讯、课程、文库信息</li>
              </ul>
            </div>

            <!-- 认证企业 -->
            <div
              class="prove2"
              v-if="identity == '认证作者'"
              style="
                background: linear-gradient(270deg, #40ba93 0%, #54dfb3 99%);
                cursor: pointer;
              "
              @click="RZcompany(1)"
            >
              已认证作者
            </div>
            <div
              class="prove2"
              v-if="identity == '认证企业'"
              @click="RZcompany(0)"
              style="cursor: pointer"
            >
              已认证企业
            </div>
          </div>
          <div class="bottom">
            <ul>
              <li
                v-for="(item, index) in consumerListL"
                :key="index"
                :class="{ color: item.color }"
                @click="ClickConsumerListL(item)"
              >
                <img :src="item.img" alt="" />
                <span>{{ item.title }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="consumer_right">
          <!-- 占位符 -->
          <router-view></router-view>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc" id="mobile">
      <div class="consumer_mobile">
        <div class="consumer_lfet_mobile">
          <div class="top">
            <!-- 头像 -->
            <div class="head" v-if="isRouterAlive">
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img :src="user.userInfo.headimg" class="avatar" />
                <p class="updata_head">
                  <span>修改头像</span>
                </p>
              </el-upload>
              <p
                style="
                  position: relative;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                "
              >
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 120px;
                    display: inline-block;
                  "
                  >{{ user.userInfo.nickname }}</span
                >
                <span
                  v-if="isIdentity !== '企业'"
                  @click="ClickMemberLevel"
                  style="
                    background-color: #675bfa;
                    display: inline-block;
                    line-height: 18px;
                    color: #fff;
                    font-size: 12px;
                    border-radius: 10px;
                    padding: 2px 8px;
                    cursor: pointer;
                  "
                  >{{
                    userInfo.member_level == 1
                      ? "白领"
                      : userInfo.member_level == 2
                      ? "白银"
                      : userInfo.member_level == 3
                      ? "黄金"
                      : ""
                  }}</span
                >
                <!-- {{ memberLevel }} -->
              </p>
              <span
                style="font-size: 12px; margin-bottom: 10px; color: #bdc0c8"
                v-if="userInfo.member_end_time && userInfo.member_level > 1"
                >到期时间:{{ userInfo.member_end_time }}</span
              >
            </div>

            <!-- 认证企业 申请作家专栏 -->
            <div class="prove1" v-if="!identity">
              <ul v-if="audit == 2 || audit == 0">
                <li
                  v-if="audit == 2 && audit !== 0"
                  @click="RZcompany(0)"
                  style="width: 120px; margin: 0 auto 5px"
                >
                  {{ audit_company_str }}
                </li>
                <li
                  v-if="audit == 0 && audit !== 2"
                  @click="RZcompany(0)"
                  style="width: 75px; margin: 0 auto 5px"
                >
                  {{ audit_company_str }}
                </li>
                <li>可上传招聘、企业、活动、资讯、 课程、文库信息</li>
              </ul>
              <p id="solid" v-if="audit == 0"></p>
              <ul v-if="audit == 1 || audit == 0">
                <li
                  v-if="audit == 1 && audit !== 0"
                  @click="RZcompany(1)"
                  style="width: 150px; margin: 0 auto 5px"
                >
                  {{ audit_writer_str }}
                </li>
                <li
                  v-if="audit == 0 && audit !== 1"
                  @click="RZcompany(1)"
                  style="width: 100px; margin: 0 auto 5px"
                >
                  {{ audit_writer_str }}
                </li>
                <li>可上传活动、资讯、课程、文库信息</li>
              </ul>
            </div>

            <!-- 认证企业 -->
            <div
              class="prove2"
              v-if="identity == '认证作者'"
              style="
                background: linear-gradient(270deg, #40ba93 0%, #54dfb3 99%);
              "
              @click="RZcompany(1)"
            >
              已认证作者
            </div>
            <div
              class="prove2"
              v-if="identity == '认证企业'"
              @click="RZcompany(0)"
            >
              已认证企业
            </div>
          </div>
          <div
            style="width: 100%; height: 15px; background-color: #f5f5f5"
          ></div>
          <div class="bottom">
            <ul style="width: 100%">
              <li
                v-for="(item, index) in consumerListL"
                :key="index"
                :class="{ color: item.color }"
                @click="ClickConsumerListL(item)"
              >
                <p>
                  <img :src="item.img" alt="" />
                  <span>{{ item.title }}</span>
                </p>

                <i class="el-icon-arrow-right"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../header";
import Footer from "../footer";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      isRouterAlive: true,
      userInfo: "",

      i: 0,
      // 个人中心一下列表模块
      consumerListL: [
        {
          img: require("../../assets/icon/personal/1.png"),
          title: "个人中心",
          color: false,
        },
        {
          img: require("../../assets/icon/personal/9.png"),
          title: "消息通知",
          color: false,
        },
        {
          img: require("../../assets/icon/personal/3.png"),
          title: "我的参与",
          color: false,
        },
        {
          img: require("../../assets/icon/personal/4.png"),
          title: "我的收藏",
          color: false,
        },
        {
          img: require("../../assets/icon/personal/5.png"),
          title: "财务管理",
          color: false,
        },
        {
          img: require("../../assets/icon/personal/6.png"),
          title: "邀请好友",
          color: false,
        },
        {
          img: require("../../assets/icon/personal/7.png"),
          title: "修改密码",
          color: false,
        },
        {
          img: require("../../assets/icon/personal/8.png"),
          title: "退出登录",
          color: false,
        },
      ],
      isContent: false,
      token: "",
      isIdentity: "",
      setIcon: "",
      audit: 0, //1作者待审核 2企业待审核
      audit_writer_str: "申请作家专栏",
      audit_company_str: "认证企业",
      fullscreenLoading: false,
    };
  },

  created() {
    this.$route.path == "/personal/infos" ? (this.setIcon = "消息通知") : "";
    this.$store.state.query.upgradeDialo = false;

    // 判断是PC端还是移动端
    this.fullscreenLoading = true;
    this.show_pc = this.$store.state.show_pc;
    this.getUser();
    if (this.$route.query.tabs) {
      this.ClickConsumerListL({
        title: this.$route.query.tabs,
      });
    }
  },
  computed: {
    ...mapState(["user", "goContent", "uploadUrl"]),
    memberLevel() {
      if (this.user.userInfo.member_level == 1) {
        return "白领";
      }
      if (this.user.userInfo.member_level == 2) {
        return "银牌";
      }
      if (this.user.userInfo.member_level == 3) {
        return "金牌";
      }
    },
    identity() {
      if (
        this.user.userInfo.is_writer == 0 &&
        this.user.userInfo.is_business == 0
      ) {
        return false;
      }
      if (this.user.userInfo.is_writer == 1) {
        return "认证作者";
      }
      if (this.user.userInfo.is_business == 1) {
        return "认证企业";
      }
    },
  },
  methods: {
    // 点击头像下的会员
    ClickMemberLevel() {
      this.$store.state.query.item = "我的余额";
      this.$store.state.query.i = 1;
      // console.log(this.$children[].zps());
      // this.$router.push("/personal/financial");
      // this.$refs["financial"].ClickUpgrade();
      this.$router.push("/personal/financial");
      // this.$store.state.query.upgradeDialog = !this.$store.state.query
      //   .upgradeDialog;
    },
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.$http
        .post("/index.php/api/v1_0_0.consumer/headimg_new", {
          headimg: res.data.file_url,
        })
        .then(({ data }) => {
          console.log(data);
          this.$store.state.user.userInfo.headimg = data;
          this.getUser();
          this.reload();
        })
        .catch((error) => {});
    },
    beforeAvatarUpload() {},

    ClickConsumerListL(v) {
      if (this.isIdentity == "普通用户") {
        this.consumerListL = this.getList(1);
      } else {
        this.consumerListL = this.getList(2);
      }

      this.consumerListL.map((item, index) => {
        // item.color = false

        if (item.title == v.title) {
          item.color = true;
          item.img = require(`../../assets/icon/personal/0${v.title}.png`);
        }
      });
      if (v.title == "个人中心") {
        if (!this.show_pc) {
          this.$router.push("/personal/userMobile");
          return;
        }
        this.$router.push("/personal/user");
      }
      if (v.title == "消息通知") {
        if (!this.show_pc) {
          this.$router.push("/personal/infosMobile");
          return;
        }
        this.$router.push("/personal/infos");
      }
      if (v.title == "内容管理") {
        if (!this.show_pc) {
          this.$router.push("/personal/contentMobile");
          return;
        }
        this.$router.push("/personal/content");
      }
      if (v.title == "我的参与") {
        if (!this.show_pc) {
          this.$router.push("/personal/joinMobile");
          return;
        }
        this.$router.push("/personal/join");
      }
      if (v.title == "我的收藏") {
        if (!this.show_pc) {
          this.$router.push("/personal/collectMobile");
          return;
        }
        this.$router.push("/personal/collect");
      }
      if (v.title == "财务管理") {
        if (!this.show_pc) {
          this.$router.push("/personal/financialMobile");
          return;
        }
        this.$router.push("/personal/financial");
      }
      if (v.title == "邀请好友") {
        if (!this.show_pc) {
          this.$router.push("/personal/inviteMobile");
          return;
        }
        this.$router.push("/personal/invite");
      }
      if (v.title == "修改密码") {
        if (!this.show_pc) {
          this.$router.push("/personal/updatePwdMobile");
          return;
        }
        this.$router.push("/personal/updatePwd");
        return;
      }
      if (v.title == "退出登录") {
        this.$confirm("确定要退出登录吗?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_token");
            this.$router.push("/");
          })
          .catch(() => {});
        return;
      }
    },
    //企业作家认证跳转
    RZcompany(id) {
      let isCad = this.$store.state.user.userInfo;

      if (id == 0) {
        this.$router.push("/attestation/company");
      }
      if (id == 1) {
        this.$router.push("/attestation/writer");
      }
    },
    getList(type) {
      if (type == 1) {
        return [
          {
            img: require("../../assets/icon/personal/1.png"),
            title: "个人中心",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/9.png"),
            title: "消息通知",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/3.png"),
            title: "我的参与",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/4.png"),
            title: "我的收藏",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/5.png"),
            title: "财务管理",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/6.png"),
            title: "邀请好友",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/7.png"),
            title: "修改密码",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/8.png"),
            title: "退出登录",
            color: false,
          },
        ];
      }
      if (type == 2) {
        return [
          {
            img: require("../../assets/icon/personal/1.png"),
            title: "个人中心",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/9.png"),
            title: "消息通知",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/2.png"),
            title: "内容管理",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/3.png"),
            title: "我的参与",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/4.png"),
            title: "我的收藏",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/5.png"),
            title: "财务管理",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/6.png"),
            title: "邀请好友",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/7.png"),
            title: "修改密码",
            color: false,
          },
          {
            img: require("../../assets/icon/personal/8.png"),
            title: "退出登录",
            color: false,
          },
        ];
      }
    },
    reload() {
      // this.isRouterAlive = false; //先关闭，
      // this.$nextTick(function() {
      //   this.isRouterAlive = true; //再打开
      // })
    },
    getUser() {
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
        .then(({ data }) => {
          this.fullscreenLoading = false;
          this.userInfo = data;
          this.$store.state.user.userInfo = data;
          if (data.is_writer != 1 && data.is_business != 1) {
            this.isIdentity = "普通用户";
            this.consumerListL = this.getList(1);
          }
          if (data.is_writer == 1) {
            this.isIdentity = "作者";
            this.consumerListL = this.getList(2);
          }
          if (data.is_business == 1) {
            this.isIdentity = "企业";
            this.consumerListL = this.getList(2);
          }

          if (data.is_writer == 2 || data.is_writer == 3) {
            this.audit = 1;
            if (data.is_writer == 2) {
              this.audit_writer_str = "申请作家专栏(审核中)";
            }
            if (data.is_writer == 3) {
              this.audit_writer_str = "申请作家专栏(审核驳回)";
            }
          }
          if (data.is_business == 2 || data.is_business == 3) {
            console.log("认证企业(审核中)");
            this.audit = 2;
            if (data.is_business == 2) {
              this.audit_company_str = "认证企业(审核中)";
            }
            if (data.is_business == 3) {
              this.audit_company_str = "认证企业(审核驳回)";
            }
          }
          console.log(this.setIcon);
          this.ClickConsumerListL({
            title: this.setIcon,
          });
        })
        .catch((error) => {});
    },
  },
  watch: {
    goContent(a) {
      this.ClickConsumerListL({
        title: a,
      });
    },
    setIcon(a) {
      console.log("路由");
      console.log(this.$route.path);
      this.ClickConsumerListL({
        title: a,
      });
    },
  },
};
</script>
<style lang='less' scoped>
#mobile {
  width: 100%;
  margin-top: 60px;
  .consumer_mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .consumer_lfet_mobile {
    width: 100%;
    height: 100%;
  }

  .top {
    background-color: #fff;

    /* padding: 24px 18px 16px; */
    padding-bottom: 20px;
    .head {
      display: flex;
      flex-direction: column;
      align-items: center;

      /* .updata_head{
          width: 100%;
          background-color: #000;
          height: 18px;
          opacity: 0.5;

        } */

      div {
        width: 88px;
        height: 88px;
        position: relative;
        cursor: pointer;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          /* background-color: pink; */
        }

        p {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 18px;
          display: flex;
          justify-content: center;
          background: #000000;
          border-radius: 1px;
          opacity: 0.5;
          margin: 0;
        }

        span {
          display: flex;
          font-size: 10px;
          font-weight: 400;
          color: #ffffff;
          line-height: 18px;
          -webkit-transform: scale(0.8);
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #2a3343;
        // line-height: 22px;
        margin: 10px 0 5px 0;
      }
    }

    .prove1 {
      position: relative;
      display: flex;
      justify-content: center;
      // align-items: center;

      #solid {
        width: 1px;
        height: 40px;
        background-color: #d8d8d8;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      ul {
        width: 50%;
        margin: 0 20px;
        /* display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center; */
        padding-bottom: 10px;

        li:nth-child(1) {
          padding: 0 12px;
          // height: 20px;
          line-height: 20px;
          background: #ffffff;
          border-radius: 11px;
          border: 1px solid #1677ff;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1677ff;
          margin-bottom: 5px;
          cursor: pointer;
          // width: 75px;
          margin: 0 auto;
          box-sizing: border-box;
        }

        li:nth-child(2) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #bdc0c8;
          line-height: 17px;
        }
      }
    }

    .prove2 {
      width: 72px;
      /* /* height: 20px; */
      background: linear-gradient(270deg, #d5b269 0%, #e7c170 100%);
      border-radius: 11px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      margin: 0 auto 15px;
      text-align: center;
    }
  }
  .bottom {
    padding: 20px 0;
    background-color: #fff;
    /* margin-top: 20px; */
    width: 100%;
    margin-bottom: 20px;
    ul {
      .color {
        span {
          color: #1677ff;
        }
      }

      li:last-child {
        margin-bottom: 0;
      }

      li {
        margin-left: 20px;
        text-align: left;
        height: 44px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;
        justify-content: space-between;
        position: relative;
        .el-icon-arrow-right:before {
          font-size: 16px;
          color: #878e9b;
        }

        i {
          margin-right: 20px;
        }
        img {
          display: inline-block;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 14px;
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;

          color: #878e9b;
          line-height: 20px;
          margin-left: 22px;
        }
      }
    }
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  position: relative;
  overflow: hidden;
  background-color: #1677ff;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 88px;
  height: 88px;
  display: block;
}

.bg {
  background-color: #f5f5f5;
}

.consumer {
  width: 1200px !important;
  margin: 35px auto;

  display: flex;
  justify-content: space-between;
  // margin: 35px 11%;
  width: auto;

  .consumer_lfet {
    width: 19.5%;
    height: 100%;

    // background-color: blue;
    .top {
      background-color: #fff;
      padding: 24px 18px 16px;

      .head {
        display: flex;
        flex-direction: column;
        align-items: center;

        /* .updata_head{
            width: 100%;
            background-color: #000;
            height: 18px;
            opacity: 0.5;

          } */
        .grade {
          cursor: pointer;
        }
        div {
          width: 88px;
          height: 88px;
          position: relative;
          cursor: pointer;

          img {
            display: inline-block;
            width: 100%;
            // height: 100%;
            /* background-color: pink; */
          }

          p {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 18px;
            display: flex;
            justify-content: center;
            background: #000000;
            border-radius: 1px;
            opacity: 0.5;
            margin: 0;
          }

          span {
            display: flex;
            font-size: 10px;
            font-weight: 400;
            color: #ffffff;
            line-height: 18px;
            -webkit-transform: scale(0.8);
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: #2a3343;
          line-height: 22px;
          margin: 13px 0;
          // margin-top: 13px;
        }
      }

      .prove1 {
        ul {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding-bottom: 10px;

          li:nth-child(1) {
            padding: 0 12px;
            height: 20px;
            line-height: 20px;
            background: #ffffff;
            border-radius: 11px;
            border: 1px solid #1677ff;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1677ff;
            margin-bottom: 5px;
            cursor: pointer;
          }

          li:nth-child(2) {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #bdc0c8;
            line-height: 17px;
          }
        }
      }

      .prove2 {
        text-align: center;
        width: 72px;
        height: 20px;
        background: linear-gradient(270deg, #d5b269 0%, #e7c170 100%);
        border-radius: 11px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        margin: 0 auto 15px;
      }
    }

    .bottom {
      background-color: #fff;
      margin-top: 20px;

      ul {
        padding: 20px;

        .color {
          span {
            color: #1677ff;
          }
        }

        li:last-child {
          margin-bottom: 0;
        }

        li {
          text-align: left;
          height: 44px;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          cursor: pointer;

          img {
            display: inline-block;
            width: 15px;
            height: 15px;
          }

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;

            color: #878e9b;
            line-height: 20px;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .consumer_right {
    width: 78.5%;
    height: 100%;
    background-color: #fff;
  }
}
</style>
