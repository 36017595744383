<template>
  <div>
    <!-- 企业推荐 企业招聘 组件 -->
    <div class="enterprise">
      <!-- 头部区域 -->
      <div class="top">
        <div class="left">
          <p
            v-for="(item, index) in enterprise"
            :key="index"
            @click="ClickEnterprises(index)"
            :class="{ textStyle: index == i }"
          >
            {{ item.enterpriseName }}
          </p>
        </div>
        <div class="right" @click="ClickMore">
          <img src="../assets/icon/更多@2x.png" />
        </div>
      </div>
      <!-- 推荐企业 -->
      <div class="bottom" v-if="i == 0">
        <div class="img" v-if="enterpriseTvList.length <= 0">
          <img src="../assets/image/企业@2x.png" alt="" />
        </div>
        <ul v-if="enterpriseTvList.length > 0">
          <li
            v-for="(item, index) in enterpriseTvList"
            :key="index"
            @click.stop="ClickEnterprise(item.business_id)"
          >
            <div class="tv_name">
              <div>
                <img :src="item.logo" alt="" />
              </div>
              <p>{{ item.business_name }}</p>
            </div>
            <div class="tv_conment">{{ item.description }}</div>
          </li>
        </ul>
      </div>
      <!-- 推荐招聘 -->
      <div class="bottom" v-if="i == 1">
        <div class="img" v-if="positionList.length <= 0">
          <img src="../assets/image/招聘@2x.png" alt="" />
        </div>

        <ul v-if="positionList.length > 0">
          <li
            v-for="(item, index) in positionList"
            :key="index"
            @click.stop="ClickPosition(item.id)"
          >
            <div class="tv_name">
              <div>
                <img :src="item.logo" alt="" />
              </div>
              <p>{{ item.business_name }}</p>
            </div>
            <div class="tv_conment">
              <!-- 岗位 待遇 -->
              <div class="post">
                <span>{{ item.title }}</span>
                <span>{{ item.salary }}</span>
              </div>
              <!-- 岗位要求 -->
              <div class="requirements">
                <span>{{ item.work_address }}</span>
                <span>{{ item.left_box.edut_desc }}</span>
                <span>{{ item.left_box.exp_desc }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 企业招聘和推荐
      enterprise: [
        { enterpriseName: "推荐企业", id: 1 },
        { enterpriseName: "企业招聘", id: 2 },
      ],
      // 修改企业文字样式
      i: 0,
      // 推荐企业列表
      enterpriseTvList: [],
      // 企业招聘列表
      positionList: [],
    };
  },
  created() {
    // 推荐企业
    this.gteEnterpriseList();

    // 推荐招聘
    this.getPositionLists();
  },
  methods: {
    // 推荐企业  切换企业
    ClickEnterprises(i) {
      this.i = i;
    },

    // 点击更多
    ClickMore() {
      if (this.i == 0) {
        this.$router.push("/jxqy");
        return;
      } else if (this.i == 1) {
        this.$router.push("/zp/recruitSearch");
        return;
      }
    },

    // 推荐企业列表
    gteEnterpriseList() {
      let storageKey = "storageKey:/index.php/api/v1_0_0.recruit/get_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.enterpriseTvList = data.list;
      }
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_list", {
          page: 1,
          page_size: 6,
          hot: 1,
        })
        .then((res) => {
          console.log(res);
          this.enterpriseTvList = res.data.list;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {});
    },

    // 点击推荐企业列表
    ClickEnterprise(id) {
      this.$router.push({ path: "/index/recruit", query: { id: id } });
    },
    // 推荐职位列表
    getPositionLists() {
      let storageKey =
        "storageKey:/index.php/api/v1_0_0.recruit/get_recruit_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.positionList = data.list;
      }

      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_recruit_list", {
          page: 1,
          page_size: 6,
          is_recommend: 1,
        })
        .then((res) => {
          this.positionList = res.data.list;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {});
    },
    // 点击推荐职位列表
    ClickPosition(id) {
      this.$router.push({ path: "/index/recruitDetails", query: { id: id } });
    },
  },
};
</script>
<style lang='less' scoped>
.enterprise {
  background-color: #ffffff;
  padding: 5px 20px 5px 25px;
  margin-bottom: 10px;
  .top {
    height: 55px;
    line-height: 55px;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    justify-content: space-between;
    .left {
      p {
        display: inline-block;
        cursor: pointer;
        margin-right: 20px;
        font-size: 16px;
        height: 40px;
        line-height: 53px;
        // letter-spacing: 1px;
      }
      .textStyle {
        font-size: 16px;
        font-weight: 600;
        color: #292931;
        border-bottom: 4px solid #1677ff;
      }
    }
    .right {
      cursor: pointer;
      width: 16px;
      height: 16px;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .bottom {
    .img {
      width: 100%;
      height: 354px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: inline-block;
        width: 102px;
        height: 145px;
      }
    }
    li {
      padding: 10px 0;
      text-align: left;
      cursor: pointer;
      .tv_name {
        display: flex;
        align-items: center;
        div {
          width: 63px;
          height: 34px;
          overflow: hidden;
        }
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        p {
          display: inline-block;
          padding: 8px 10px 8px 10px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #453c3c;
          line-height: 22px;
        }
      }
      .tv_conment {
        font-size: 14px;
        font-weight: 400;
        color: #453c3c;
        // line-height: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        padding-top: 2px;
        cursor: pointer;
        overflow: hidden; //强制多行显示
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        letter-spacing: 1px;
        line-height: 20px;
        .post {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span:nth-child(1) {
            color: #453c3c;
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
          }
          span:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            color: #ef774f;
            line-height: 20px;
          }
        }
        .requirements {
          font-size: 14px;
          font-weight: 400;
          color: #292931;
          line-height: 30px;
          span {
            display: inline-block;
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: #292931;
            // padding-right: 15px;
          }
          span:nth-child(1) {
            margin-right: 10px;
          }
          span:nth-child(n + 2) {
            border-left: 1px solid #292931;
            padding: 0 10px;
          }
        }
      }
    }
  }
}
</style>