<template>
  <!-- 头部组件 -->
  <div>
    <!--  pc端  -->
    <div
      class="boxTop"
      :class="show ? 'cd-auto-hide-header' : 'cd-auto-hide-header is-hidden'"
      v-if="show_pc"
    >
      <!--    <div class="boxTop" v-if="show_pc">-->
      <div class="header">
        <!-- 左侧 -->
        <div class="left">
          <div class="img">
            <img :src="logo" alt="" @click="ClickLogo" />
          </div>
        </div>
        <!-- 中间 -->
        <div class="center">
          <ul>
            <li
              v-for="(item, index) in navList"
              :key="index"
              :class="{ colors: index == i }"
            >
              <router-link :to="item.path" @click.native="navSwitch(index)">
                {{ item.name }}</router-link
              >
            </li>
          </ul>
        </div>
        <!-- 右侧 -->
        <div class="right">
          <ul>
            <li>
              <button
                :class="{ logo_color: path == '/login' }"
                v-if="!isLogin"
                @click="ClickLogin"
              >
                登录
              </button>
            </li>
            <li>
              <button
                :class="{ register_color: path == '/register' }"
                v-if="!isLogin"
                @click="ClickRegister"
              >
                注册
              </button>
            </li>

            <li style="cursor: pointer">
              <el-dropdown
                trigger="click"
                :hide-on-click="false"
                @command="ClickPersonalList"
                v-if="isLogin"
              >
                <img :src="user.userInfo.headimg" alt="" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.stop="ClickPersonal">
                    <div>
                      <img :src="user.userInfo.headimg" alt="" />
                    </div>
                    <div>
                      <p>
                        <span>{{ user.userInfo.nickname }}</span
                        ><span v-if="user.userInfo.is_business != 1">{{
                          memberLevel
                        }}</span>
                      </p>
                      <p
                        v-if="
                          user.userInfo.is_writer == 1 ||
                          user.userInfo.is_business == 1
                        "
                      >
                        {{ identity }}
                      </p>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item
                    style="margin: 0 !important"
                    v-for="(item, index) in personalList"
                    :key="index"
                    :command="item"
                  >
                    <span style="line-height: 40px">{{ item.name }}</span
                    ><span style="line-height: 40px">{{
                      item.prove
                    }}</span></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="height: 70px" v-if="show_pc"></div>
    <!--  移动端  -->
    <div v-if="!show_pc">
      <!-- 头部 -->
      <div class="herder_md">
        <div class="logo">
          <img :src="logo" alt="" @click="ClickLogo" />
        </div>
        <!--        <audio controls src="http://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20210106/80test.mp3"></audio>-->
        <div class="img">
          <img
            v-if="!mask"
            src="../assets/icon/mobile/更多@2x.png"
            alt=""
            @click="Clickmask"
          />
          <img
            v-if="mask"
            src="../assets/icon/mobile/关闭@2x.png"
            alt=""
            @click="Clickmask"
          />
        </div>
        <!-- 遮罩层 -->
        <div class="popContainer" v-if="mask">
          <!-- 中间 -->
          <div class="center">
            <ul>
              <li
                v-for="(item, index) in navList"
                :key="index"
                :class="{ colors: index == i }"
              >
                <router-link :to="item.path" @click.native="navSwitch(index)">
                  {{ item.name }}</router-link
                >
              </li>
            </ul>
          </div>

          <!-- 按钮 -->
          <div class="box">
            <div class="btns" v-if="ISlogin.length <= 0">
              <div
                :class="path == '/login' ? 'logo_color' : ''"
                class="login"
                @click="ClickLogin"
              >
                登录
              </div>
              <div
                :class="path == '/register' ? 'register_color' : ''"
                class="register"
                @click="ClickRegister"
              >
                注册
              </div>
            </div>
            <div
              class="outLogin"
              v-if="ISlogin.length > 0"
              @click="ClickOutLogin"
            >
              退出登录
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isWeixin, isPc } from "../utils";
import { log } from "util";
import { stringify } from "querystring";
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    indexi: Number,
  },
  data() {
    return {
      path: "",

      // 默认显示 PC端的css
      show_pc: true,
      // 网站logo
      logo: "",
      centerDialogVisible: true,
      // 登录提示框 默认隐藏
      dialogVisible: false,
      isLogin: false,
      // 导航列表
      navList: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "资讯",
          path: "/zx",
        },
        {
          name: "专栏",
          path: "/zl",
        },
        {
          name: "新税课堂",
          path: "/xskt",
        },
        {
          name: "活动",
          path: "/hd",
        },
        {
          name: "文库",
          path: "/wk",
        },
        {
          name: "招聘",
          path: "/zp",
        },
        {
          name: "精选企业",
          path: "/jxqy",
        },
        {
          name: "专题",
          path: "/zt",
        },
      ],

      userTx: require("../assets/image/user@2x.png"),
      // 个人中心列表
      personalList: [
        // {
        //   name: "内容管理",
        //   prove: "已认证",
        // },
        {
          name: "个人中心",
          prove: "",
        },
        {
          name: "消息通知",
          prove: "",
        },
        {
          name: "我的参与",
          prove: "",
        },
        {
          name: "我的收藏",
          prove: "",
        },
        {
          name: "财务管理",
          prove: "",
        },
        {
          name: "邀请好友",
          prove: "",
        },
        {
          name: "修改密码",
          prove: "",
        },
        {
          name: "退出登录",
          prove: "",
        },
      ],
      //  导航列表默认选中第一个
      i: "",
      isIdentity: "",

      // 移动端
      // 遮罩层默认隐藏
      mask: false,
      // 是否登录
      ISlogin: "",
      h: 1,
      show: true,
    };
  },

  computed: {
    ...mapState(["uploadUrl", "user"]),
    memberLevel() {
      if (this.user.userInfo.member_level == 1) {
        return "白领";
      }
      if (this.user.userInfo.member_level == 2) {
        return "银牌";
      }
      if (this.user.userInfo.member_level == 3) {
        return "金牌";
      }
    },
    identity() {
      if (this.user.userInfo.is_writer == 1) {
        return "认证作者";
      }
      if (this.user.userInfo.is_business == 1) {
        return "认证企业";
      }
    },
  },
  created() {
    this.path = this.$route.path;

    console.log("当前路径");
    console.log(this.path);
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 判断是否微信
    // console.log(isWeixin())
    // 判断是否pc端
    // console.log(isPc())
    let user = localStorage.getItem("user_token")
      ? localStorage.getItem("user_token")
      : "";
    if (!localStorage.getItem("img")) {
      this.$http
        .get("/index.php/api/v1_0_0.ordinary/get_web_info", {
          notNeed: true,
        })
        .then((data) => {
          console.log("头部");
          console.log(data.data);
          const img = data.data.logo;
          // console.log();
          localStorage.setItem("img", img);
          this.logo = localStorage.getItem("img");
        })
        .catch((error) => {});
    }

    this.logo = localStorage.getItem("img");
    if (user) {
      this.isLogin = true;
      //获取用户信息
      this.$http
        .post("/index.php/api/v1_0_0.consumer/get_userinfo", {})
        .then(({ data }) => {
          this.$store.state.user.userInfo = data;
          this.$store.state.get_userinfo = data;
          if (data.is_writer != 1 && data.is_business != 1) {
            this.isIdentity = "普通用户";
          }
          if (data.is_writer == 1 && data.is_business != 1) {
            this.isIdentity = "作者";
            this.personalList.unshift({
              name: "内容管理",
              prove: "已认证",
            });
          }
          if (data.is_writer != 1 && data.is_business == 1) {
            this.isIdentity = "企业";
            this.personalList.unshift({
              name: "内容管理",
              prove: "已认证",
            });
          }
          //移动端 添加个人中心
          if (!this.show_pc) {
            this.navList.push({
              name: "个人中心",
              path: "/personal/user",
            });
          }
          this.$store.state.isIdentity = this.isIdentity;
        })
        .catch((error) => {});
    }
  },
  methods: {
    ...mapMutations(["setHeaderIndex"]),
    // 样式切换函数
    navSwitch(i) {
      this.i = i;
      // this.$router.go(0);
      this.mask = false;
    },
    Show() {
      this.dialogVisible = true;
    },
    // 点击logo 跳转到首页
    ClickLogo() {
      this.$router.push("/");
    },

    // 点击登录按钮 跳转登录页面
    ClickLogin() {
      this.$router.push("/login");
    },
    // 点击注册按钮 跳转注册
    ClickRegister() {
      this.$router.push("/register");
    },

    //  点击头像跳转个人信息中心
    ClickPersonal() {
      this.$router.push("/personal/user");
      this.$store.state.goContent = "个人中心";
    },

    // 个人中心函数
    ClickPersonalList(command) {
      if (command == undefined) {
        this.$router.push("/personal/user");
        this.$store.state.goContent = "个人中心";
        return;
      }

      console.log(command.name);
      this.$store.state.goContent = command.name;

      if (command.name == "个人中心") {
        this.$router.push("/personal/user");
      }
      if (command.name == "消息通知") {
        this.$router.push("/personal/infos");
      }

      if (command.name == "内容管理") {
        this.$router.push("/personal/content");
      }

      if (command.name == "我的参与") {
        this.$router.push("/personal/join");
      }

      if (command.name == "我的收藏") {
        this.$router.push("/personal/collect");
      }

      if (command.name == "财务管理") {
        this.$router.push("/personal/financial");
      }

      if (command.name == "邀请好友") {
        this.$router.push("/personal/invite");
      }
      if (command.name == "修改密码") {
        this.$router.push("/personal/updatePwd");
      }

      if (command.name == "退出登录") {
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_token");
        this.isLogin = false;
        // this.$router.replace("/");
        this.$router.push("/");
      }
      // console.log(command);
    },
    // 移动端
    // 显示遮罩层
    Clickmask() {
      this.mask = !this.mask;
      this.ISlogin = localStorage.getItem("user_token")
        ? localStorage.getItem("user_token")
        : "";
      console.log(this.ISlogin);
    },
    ClickOutLogin() {
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_token");
      this.$router.replace("/");
      location.reload();
    },

    handleScroll() {
      // 页面滚动距顶部距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop,'顶部距离')
      if (scrollTop < 70) {
        return;
      }

      var scroll = scrollTop - this.h;
      this.h = scrollTop;
      // console.log(scroll)
      if (scroll < 0) {
        // console.log('up')
        this.show = true;
        //添加你想要的事件
      } else {
        //添加你想要的事件
        // console.log('down')
        this.show = false;
      }
    },
  },

  mounted() {
    this.i = this.indexi;

    if (this.$store.state.show_pc) {
      window.addEventListener("scroll", this.handleScroll, true);
    }
    // 监听（绑定）滚轮 滚动事件
  },
};
</script>
<style lang='less' scoped>
.cd-auto-hide-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.5s;
  z-index: 50;
}
.is-hidden {
  transform: translateY(-100%);
}

.logo_color {
  background-color: #1677ff !important;
  color: #fff !important;
}

.register_color {
  background-color: #1677ff !important;
  color: #fff !important;
}

.boxTop {
  // border-bottom: 1px solid blue;
  background-color: #fff;
  .header {
    height: 70px;
    width: 1200px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .left {
      box-sizing: border-box;
      width: 15%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: start;

      // margin-left: 30px;
      .img {
        width: 150px;
        height: 70px;
        overflow: hidden;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: #fff;
          border: 0;
          cursor: pointer;
        }
      }
    }

    .center {
      box-sizing: border-box;
      width: 60%;
      height: 100%;

      ul {
        width: 100%;
        height: 100%;
        line-height: 100%;
        display: flex;
        justify-content: space-around;
        text-align: center;
        box-sizing: border-box;
        li:hover {
          // border-top: 5px solid #1677ff !important;
        }
        a:hover {
          color: #1677ff;
        }
        li {
          padding: 0 2.5%;
          font-size: 16px;
          height: 100%;
          line-height: 100%;
          text-align: center;
          border-top: 5px solid #fff;
          border-bottom: 5px solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;

          a {
            display: flex;
            font-weight: 600;
            color: #393a44;
            text-decoration: none;
            white-space: nowrap;
            cursor: pointer;
          }
        }

        .colors {
          border-top: 5px solid #1677ff !important;

          a {
            color: #1677ff !important;
          }
        }

        li:nth-child(1) {
          padding-left: 2%;
        }
      }
    }
  }

  .right {
    width: 25%;
    height: 100%;

    ul {
      width: 100%;
      height: 100%;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    li {
      width: 88px;
      height: 34px;
      display: inline-block;
      padding-right: 20px;
      font-size: 14px;

      button {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        background-color: #fff;
        border-radius: 18px;
        color: #1677ff;
        border: 1px solid #1677ff;
      }
    }

    li:nth-child(2) {
      .register_color {
        background-color: #1677ff !important;
        color: #fff;
      }

      button {
        background-color: #fff;
        color: #1677ff;
        border: 1px solid #1677ff;
      }
    }

    li:nth-child(3) {
      width: 50px;
      height: 50px;
      padding-right: 0;
      border-radius: 50%;
      overflow: hidden;
      background-size: 100% 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        outline: none;
      }
    }
  }
}

/* // 头像下拉更多 */
.el-dropdown {
  width: 100%;
  height: 100%;
}

.el-dropdown-menu {
  width: 247px;
  padding: 0;
  border-radius: 8px;
  border: 0;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.el-dropdown-menu__item {
  /* line-height: 20px; */
  padding: 0 10px 0 14px;
}

.el-dropdown-menu__item:nth-child(1) {
  height: 88px;
  background: linear-gradient(309deg, #1677ff 0%, #31afff 100%);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;

  div:nth-child(1) {
    width: 54px;
    height: 54px;
    border-radius: 54px;
    overflow: hidden;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      // background-color: pink;
    }
  }

  div:nth-child(2) {
    margin-left: 10px;

    p {
      margin: 5px 0;
    }

    p:nth-child(1) {
      display: flex;
      align-items: center;

      span:nth-child(1) {
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
      }

      span:nth-child(2) {
        -webkit-transform: scale(0.8);
        display: block;
        width: 34px;
        height: 16px;
        line-height: 16px;
        background: #675bfa;
        border-radius: 9px;
        font-size: 8px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        padding: 2px 3px;
      }
    }

    p:nth-child(2) {
      -webkit-transform: scale(0.8);
      display: block;
      width: 54px;
      height: 18px;
      line-height: 18px;
      padding: 2px 7px;
      background: linear-gradient(270deg, #d5b269 0%, #e7c170 100%);
      border-radius: 2px;
      font-size: 10px;
      font-weight: 400;
      color: #ffffff;
      margin-left: -5px;
    }
  }
}

.el-dropdown-menu__item:nth-child(n + 2) {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  span:nth-child(1) {
    font-size: 15px;
    font-weight: 400;
    color: #5f6671;
    line-height: 21px;
  }

  span:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    color: #a1a8b2;
    line-height: 17px;
  }
}

.el-popper .popper__arrow::after {
  width: 3px;
  height: 0;
}

.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: red !important;
}

.herder_md {
  position: fixed !important;
  top: 0;
  display: flex;
  width: 100%;
  z-index: 22;
  background-color: #fff;
  justify-content: space-between;

  .logo {
    width: 100px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .img {
    width: 72px;
    height: 60px;
    background: #ffffff;

    img {
      display: inline-block;
      /*width: 100%;*/
      height: 100%;
    }
  }

  .popContainer {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2222;

    .center {
      ul {
        .colors {
          a {
            color: #1677ff !important;
          }
        }

        li {
          margin-top: 8%;

          a {
            text-decoration: none;
            color: #fff;

            font-size: 14px;
          }
        }
      }
    }

    .box {
      position: absolute;
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%);

      .btns {
        display: flex;
        justify-content: center;

        .login {
          width: 88px;
          height: 34px;
          line-height: 34px;
          background: #ffffff;
          border: 1px solid #1677ff;
          border-radius: 18px;
          margin: 0 10px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1677ff;
        }

        .register {
          width: 88px;
          height: 34px;
          line-height: 34px;
          background: #ffffff;
          border-radius: 18px;
          border: 1px solid #1677ff;
          margin: 0 10px;
          box-sizing: border-box;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1677ff;
        }
      }
    }

    .outLogin {
      width: 335px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      background: #1677ff;
      border-radius: 22px;
      margin: 0 auto;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
