<template>

<!-- 文库详情页面 -->
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- PC端 -->
    <div v-if="show_pc" style=" background-color: #f5f5f5" class="pc">
      <Header :indexi='5' ></Header>

      <!-- 文库详情内容区域 -->
      <div class="details_box">
        <!-- 文库详情左侧 -->
        <div class="details_left">
          <!-- 文章标题区域 -->
          <div class="subject">
            <p><span :style="{color:detailsInfo.type_color,borderColor:detailsInfo.type_color}">{{detailsInfo.type_name}}</span><span>{{detailsInfo.title}}</span></p>
            <div class="funct">
              <div class="funct_left">
                <ul>
                  <li>
                    <img src="../../assets/image/时间@2x.png" alt="" />
                    <span>{{ detailsInfo.create_time }}</span>
                  </li>
                  <li>
                    <img src="../../assets/image/眼睛@2x.png" alt="" />
                    <span>{{ detailsInfo.view_count }}</span>
                  </li> <li>
                    <img src="../../assets/icon/下载@2x.png" alt="" />
                    <span>{{ detailsInfo.download_count }}</span>
                  </li>
                </ul>
              </div>
              <div class="funct_rigth">

              <!-- 下载 -->
                <div v-if="1==detailsInfo.is_pay" @click="freeClickDownload">免费下载</div>
                <div v-if="2==detailsInfo.is_pay && detailsInfo.is_owner" @click="moneyDownload">（已付费）免费下载</div>
                <div v-if="2==detailsInfo.is_pay && !detailsInfo.is_owner" @click="ClickDownload" >¥{{detailsInfo.price}}{{detailsInfo.unit}} 下载文档</div>
              <!-- 点赞 -->
              <div v-if="1==detailsInfo.is_praise" @click="ClickThumbs">
                  <img src="../../assets/icon/点赞@2x.png" alt=""  />
                  <span style="color:#1677FF">{{detailsInfo.praise_count}}</span>
                </div>
                <!-- 收藏 -->
                <div v-if="2==detailsInfo.is_praise" @click="ClickThumbs" >
                  <img src="../../assets/icon/点赞@2x(1).png" alt=""  />
                  <span>{{detailsInfo.praise_count}}</span>
                </div>

                <div v-if="1==detailsInfo.is_collect" @click="ClickCollection">
                  <img src="../../assets/icon/编组@2x.png" alt=""  />
                  <span style="color:#1677FF">已收藏</span>
                </div>
                <div v-if="2==detailsInfo.is_collect" @click="ClickCollection">
                  <img src="../../assets/icon/编组备份@2x(1).png" alt=""  />
                <span>收藏</span>
                </div>

            </div>
            </div>

          </div>

          <!-- 文章内容区域 -->
          <div class="content">
            <div><template>
              <div v-html="detailsInfo.description">
                {{detailsInfo.description}}
              </div>
            </template></div>
          </div>

          <!-- 评论区域 -->
          <div class="comment">
            <div class="text">
              <p>
                评论({{totle}})
                <span></span>
              </p>
            </div>
            <div class="comment_box">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="content"
                maxlength="500"
                show-word-limit
              >
              </el-input>
              <div style="display: flex;justify-content: space-between">


              <div style="display: flex">
                <div style="width: 200px">
                  <el-input
                    placeholder="请输入图形验证码"
                    v-model="imgCodeInput"
                    show-word-limit
                  >
                  </el-input>
                </div>


                <div
                  style="width: 80px; heigth: 34px; overflow: hidden ;  margin-left: 15px;"
                  @click="ClickImgCode"
                  v-loading="loading"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(225, 225, 225, 0.3)"
                >
                  <img v-if="!loading" width="100%" height="100%" :src="imgCode" alt="" />
                </div>
              </div>
              <div class="btn">
                <button @click="ClickCommont">评论</button>
              </div>
              </div>


            </div>
            <div class="commentlist" style="margin-top: 20px">
              <div class="img" v-if="!totle" style="width:20%;margin:0 auto" >
                <img src="../../assets/image/评论@2x.png" alt="" />
              </div>
              <ul>
                <li v-for="(item, index) in commentList" :key="index">
                  <div class="commentlist_top">
                    <div>
                      <img :src="item.headimg" alt="" />
                    </div>
                    <div>
                      <p>{{ item.nickname }}</p>
                      <p>{{ item.create_time }}</p>
                    </div>
                  </div>
                  <div class="commentlist_bottom">
                    {{ item.content }}
                  </div>
                </li>
              </ul>
            </div>


            <div v-if="commentList.length<totle" class="more_comment" @click="CliskMoreComments">
              加载更多评论
            </div>
              <div v-if="commentList.length>10" class="more_comment" @click="CliskNoMore">
              收起评论
            </div>

          </div>
        </div>
        <!-- 文库详情右侧 -->
        <div class="details_rigth">
          <div class="data">
            <!-- 头像 资料区域 -->
            <div class="top">
              <div class="portrait">
                <img :src="writerInfo.headimg" alt="">
              </div>
              <div class="infor">
                <p>{{writerInfo.nickname}}</p>
                <p>{{writerInfo.description}}</p>
              </div>
            </div>
            <!-- 文章数量区域 -->
            <div class="bottom">
              <div class="text_bottom">
                <div class="text">
                  {{writerInfo.nickname}}的文档
                  <span></span>
                </div>
              </div>
              <div class="numders">
                <ul>
                  <li v-for="(item, index) in numdersList" :key="item.id" @click="ClickHotFile(item)">
                    <p><span :style="{color:item.type_color,borderColor: item.type_color}">{{item.type_name}}</span>{{ item.title }}</p>
                    <p>{{ item.description }}</p>
                    <p>
                        <ul>
                        <li>
                          {{item.create_time }}
                          <span></span>
                        </li>
                        <li>
                          {{ item.collect_count+'次收藏' }}
                          <span></span>
                        </li>
                        <li>
                          {{ item.download_count+'次下载' }}
                          <span></span>
                        </li>
                        <li>
                          {{ item.nickname }}
                          <span></span>
                        </li>
                      </ul>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
<!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="libraryDetails_mb">

     <Header :indexi='5' ></Header>

        <!-- 文库详情内容区域 -->
        <div class="details_box">
          <!-- 文库详情左侧 -->
          <div class="details_left">
            <!-- 文章标题区域 -->
            <div class="subject">
              <p><span :style="{color:detailsInfo.type_color,borderColor:detailsInfo.type_color}">{{detailsInfo.type_name}}</span><span>{{detailsInfo.title}}</span></p>
              <div class="funct">
                <div class="funct_left">
                  <ul>
                    <li>
                      <img src="../../assets/image/时间@2x.png" alt="" />
                      <span>{{ detailsInfo.create_time }}</span>
                    </li>
                    <li>
                      <img src="../../assets/image/眼睛@2x.png" alt="" />
                      <span>{{ detailsInfo.view_count }}</span>
                    </li> <li>
                      <img src="../../assets/icon/下载@2x.png" alt="" />
                      <span>{{ detailsInfo.download_count }}</span>
                    </li>
                  </ul>
                </div>

              </div>

            </div>

            <!-- 文章内容区域 -->
            <div class="content">
              <template>
                <div v-html="detailsInfo.description">

              <div>{{detailsInfo.description}}</div>
                </div>
              </template>
            </div>

            <!-- 评论区域 -->
            <div class="comment">
              <div class="text">
                <p>
                  评论({{totle}})
                  <span></span>
                </p>
              </div>
              <!-- <div class="comment_box">
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="content"
                  maxlength="500"
                  show-word-limit
                >
                </el-input>


              <div style="display: flex;margin-bottom:10px">
                <div  style="width: 100%;" >
                  <el-input
                    placeholder="请输入图形验证码"
                    v-model="imgCodeInput"
                    show-word-limit
                  >
                  </el-input>
                </div>


                <div
                  style="width: 80px; heigth: 34px; overflow: hidden ;  margin-left: 15px;"
                  @click="ClickImgCode"
                    v-loading="loading"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(225, 225, 225, 0.3)"
                >
                  <img v-if="!loading"  width="100%" height="100%" :src="imgCode" alt="" />
                </div>
              </div>
              <div class="btn">
                <button @click="ClickCommont">评论</button>
              </div>

              </div> -->
              <div class="commentlist" >
                <ul>
                  <li v-for="(item, index) in commentList" :key="index">
                    <div class="commentlist_top">
                      <div>
                        <img :src="item.headimg" alt="" />
                      </div>
                      <div>
                        <p>{{ item.nickname }}</p>
                        <p>{{ item.create_time }}</p>
                      </div>
                    </div>
                    <div class="commentlist_bottom">
                      {{ item.content }}
                    </div>
                  </li>
                </ul>
              </div>


              <div v-if="commentList.length<totle" class="more_comment" @click="CliskMoreComments">
                点击查看更多
              </div>
                <div v-if="commentList.length==totle" class="more_comment" style="height:140px">
                没有更多了
              </div>

            </div>
          </div>

        </div>
         <div class="footer">

     <Footer></Footer>
    </div>
      <div
          style="position: fixed; bottom: 37px; width: 100% ;background-color:#fff"
          v-if="showInput"
        >
          <div
            style="
              display: flex;
              width: 100%;
              padding: 0 10px;
              box-sizing: border-box;
              background-color: #fff;
              padding-top:5px;
            "
          >
            <div style="width: 100%; margin-right: 10px">
              <el-input
                placeholder="请输入图形验证码"
                v-model="imgCodeInput"
                show-word-limit
              >
              </el-input>
            </div>
            <div
              style="width: 80px; heigth: 34px; overflow: hidden"
              @click="ClickImgCode"
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(225, 225, 225, 0.3)"
            >
              <img
                v-if="!loading"
                width="100%"
                height="100%"
                :src="imgCode"
                alt=""
              />
            </div>
          </div>
          <!-- autofocus="true"
              @blur.native.capture="showInput = false" -->
          <div class="input">
            <el-input v-model="content" placeholder="请输入内容">
            </el-input>
            <span @click.stop="ClickCommont">发送</span>
          </div>
        </div>
    <div class="funct_rigth">

      <!-- 下载 -->

      <!-- 点赞 -->
      <div v-if="1==detailsInfo.is_praise" @click="ClickThumbs">
          <img src="../../assets/icon/点赞@2x.png" alt=""  />
          <span style="color:#1677FF">{{detailsInfo.praise_count}}</span>
        </div>
        <!-- 收藏 -->
        <div v-if="2==detailsInfo.is_praise" @click="ClickThumbs" >
          <img src="../../assets/icon/点赞@2x(1).png" alt=""  />
          <span>{{detailsInfo.praise_count}}</span>
        </div>

        <div v-if="1==detailsInfo.is_collect" @click="ClickCollection">
          <img src="../../assets/icon/编组@2x.png" alt=""  />
          <span style="color:#1677FF">已收藏</span>
        </div>
        <div v-if="2==detailsInfo.is_collect" @click="ClickCollection">
          <img src="../../assets/icon/编组备份@2x(1).png" alt=""  />
        <span>收藏</span>
        </div>

        <div  @click="ClickShowInput">  <img src="../../assets/icon/评论@2x.png" alt=""><span>评论</span></div>
          <!-- 下载 -->
                <div v-if="1==detailsInfo.is_pay" @click="freeClickDownload">免费下载</div>
                <div v-if="2==detailsInfo.is_pay && detailsInfo.is_owner" @click="moneyDownload">（已付费）免费下载</div>
                <div v-if="2==detailsInfo.is_pay && !detailsInfo.is_owner" @click="ClickDownload" >¥{{detailsInfo.price}}{{detailsInfo.unit}} 下载文档</div>


    </div>
    </div>

  </div>


  </div>
</template>
<script>
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";
import { log } from "util";
import { Message } from "element-ui";

export default {
  components: { Header, Footer },
  metaInfo() {
    return {
      title: this.detailsInfo.title
        ? this.detailsInfo.title + " | 新税网"
        : window.location.href,

      meta: [
        {
          name: "description",
          content: this.detailsInfo.description_meta,
        },
        {
          name: "keywords",
          content: this.detailsInfo.title,
        },
        {
          name: "author",
          content: this.writerInfo.nickname + " - 新税网",
        },
      ],
    };
  },
  data() {
    return {
      showInput: false,
      // 图形验证码加载...
      loading: false,
      // 默认显示 PC端的css
      show_pc: true,
      // 图片验证码输入框
      imgCodeInput: "",
      // 验证码图片
      imgCode: "",
      // 验证码key
      key: "",
      // 文库详情id
      id: "",
      // 文库详情数据
      detailsInfo: "",
      // 作者信息
      writerInfo: "",

      // 相关文档列表
      numdersList: [],
      // 评论输入框
      content: "",
      // 查询分页表参数
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      totle: 0,
      // 评论数量的列表
      commentList: [],
      fullscreenLoading: false,
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    this.id = this.$route.query.id;
    this.fullscreenLoading = true;
    // 文库详情数据
    this.libraryDetails();

    // 热门文档数据
    this.hotLibraryDetails();
    // 获取评论列表
    this.getCommentList();
    // 获取图片地址
    this.getImgCode();
  },
  methods: {
    ClickShowInput() {
      this.showInput = !this.showInput;
    },
    // 文库详情数据
    libraryDetails() {
      this.fullscreenLoading = true;

      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/get_detail", {
          id: this.id,
        })
        .then((res) => {
          this.fullscreenLoading = false;
          console.log("文档详情数据");
          console.log(res);
          this.detailsInfo = res.data;
          this.writerInfo = res.data.writer_info;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取图片地址
    getImgCode() {
      this.loading = true;
      this.$http
        .post("index.php/api/v1_0_0.ordinary/get_verify", {})
        .then((res) => {
          console.log("图片");
          this.loading = false;
          this.imgCode = res.data.verify;
          this.key = res.data.key;
          console.log(res.data);
        })
        .catch(() => {});
    },
    // 刷新图形验证码
    ClickImgCode() {
      this.getImgCode();
    },
    // 获取评论列表
    getCommentList() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/comment_list", {
          id: this.id,
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          type: 3,
        })
        .then((res) => {
          this.commentList = res.data.list;
          this.totle = res.data.total_count;
        })
        .catch((error) => {});
    },
    // 点击评论
    ClickCommont() {
      if (this.content.length <= 0) {
        Message.error("请输入评论内容");
        return false;
      }
      if (this.content.length < 2) {
        Message.error("评论过短");
        return false;
      }

      if (!this.imgCodeInput) {
        Message.error("请输入图形验证码");
        return false;
      }
      this.$http
        .post("index.php/api/v1_0_0.ordinary/check", {
          verify_code: this.imgCodeInput,
          key: this.key,
        })
        .then(() => {
          this.$http
            .post("/index.php/api/v1_0_0.interaction/do_comment", {
              id: this.id,
              content: this.content,
              type: 3,
              verify_code: this.imgCodeInput,
              key: this.key,
            })
            .then((res) => {
              this.content = "";
              this.imgCodeInput = "";

              Message.success(res.msg);
              this.getCommentList();
              this.getImgCode();
              console.log("发表评论", res);
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    // 点击更多评论
    CliskMoreComments() {
      this.queryInfo.pagenum++;
      this.$http
        .post("/index.php/api/v1_0_0.interaction/comment_list", {
          id: this.id,
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
          type: 3,
        })
        .then((res) => {
          res.data.list.forEach((item) => {
            this.commentList.push(item);
          });
          this.totle = res.data.total_count;
        })
        .catch((error) => {});
    },
    // 收起评论
    CliskNoMore() {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = 10;
      this.getCommentList();
    },

    // 热门文档数据
    hotLibraryDetails() {
      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/get_detail_list", {
          id: this.id,
        })
        .then((res) => {
          console.log("推荐文档数据");
          console.log(res);
          this.numdersList = res.data.list;
        })
        .catch((error) => {});
    },

    // 免费下载
    freeClickDownload() {
      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/do_download_free", {
          id: this.id,
        })
        .then((res) => {
          this.detailsInfo.download_count = this.detailsInfo.download_count + 1;
          window.open(
            res.data.download_url +
              "&user_id=" +
              localStorage.getItem("user_id") +
              "&user_token=" +
              localStorage.getItem("user_token")
          );
        })
        .catch((error) => {});
    },

    // 已付费，下载
    moneyDownload() {
      this.$http
        .post("/index.php/api/v1_0_0.librarycomser/do_download", {
          id: this.id,
        })
        .then((res) => {
          this.detailsInfo.download_count = this.detailsInfo.download_count + 1;
          window.open(
            res.data.download_url +
              "&user_id=" +
              localStorage.getItem("user_id") +
              "&user_token=" +
              localStorage.getItem("user_token")
          );
        })
        .catch((error) => {});
    },
    // 点击下载
    ClickDownload() {
      // 需要付费
      if (this.detailsInfo.is_pay == 2) {
        this.$http
          .post("/index.php/api/v1_0_0.librarycomser/check_balance", {
            id: this.id,
          })
          .then((res) => {
            console.log(res);
            if (res.data.balance) {
              if (res.data.safe_password) {
                this.$confirm(res.msg, "提示", {
                  confirmButtonText: "去设置",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.$router.push({
                      path: "/personal/financial",
                      query: {
                        tabs: "支付密码设置",
                      },
                    });
                  })
                  .catch(() => {});
              } else {
                this.$prompt("请输入密码", res.msg, {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  inputPlaceholder: "请输入支付密码",
                  inputType: "password",
                  inputValidator: function (v) {
                    if (!v) return "请输入支付密码";
                  },
                })
                  .then(({ value }) => {
                    this.$http
                      .post("/index.php/api/v1_0_0.librarycomser/do_pay", {
                        id: this.id,
                        password: value,
                      })
                      .then((res) => {
                        if (res.data.need_password) {
                          //没有设置支付密码
                          this.$confirm(pay.msg, "提示", {
                            confirmButtonText: "设置",
                            cancelButtonText: "取消",
                            type: "warning",
                          })
                            .then(() => {
                              this.$router.push({
                                path: "/personal/financial",
                                query: {
                                  tabs: "支付密码设置",
                                },
                              });
                            })
                            .catch(() => {});
                          return;
                        }

                        Message.success("支付成功");
                        this.libraryDetails();

                        this.detailsInfo.download_count =
                          this.detailsInfo.download_count + 1;

                        // 执行下载
                        window.open(
                          res.data.download_url +
                            "&user_id=" +
                            localStorage.getItem("user_id") +
                            "&user_token=" +
                            localStorage.getItem("user_token")
                        );
                      })
                      .catch((error) => {});
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消支付",
                    });
                  });
              }
            } else {
              this.$confirm(res.msg, "余额不足", {
                confirmButtonText: "获取积分",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  // TODO 去充值的跳转
                  this.$router.push({
                    path: "/personal/financial",
                    query: { i: 1 },
                  });
                })
                .catch(() => {});
            }
          })
          .catch((error) => {});
      } else {
        this.freeClickDownload();
      }
    },

    // 点赞 或 取消点赞
    ClickThumbs() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_praise", {
          type: 3,
          id: this.id,
        })
        .then((res) => {
          this.detailsInfo.is_praise = res.data.is_praise;
          this.detailsInfo.praise_count = res.data.praise_count;
          this.$message.success("操作成功").onClose(() => {});
        })
        .catch((error) => {});
    },

    // 收藏 或 取消收藏
    ClickCollection() {
      this.$http
        .post("/index.php/api/v1_0_0.interaction/do_collect", {
          type: 3,
          id: this.id,
        })
        .then((res) => {
          this.detailsInfo.is_collect = res.data.is_collect;
          // this.detailsInfo.praise_count = res.data.collect_count;
          console.log(res);
          this.$message.success("操作成功").onClose(() => {});
        })
        .catch((error) => {});
    },

    // 点击相关文档列表
    ClickHotFile(item) {
      const need_guide = this.$store.state.get_userinfo.need_guide;
      if (item.is_pay == 2 && need_guide) {
        this.$confirm("升级会员可享受全站折扣优惠!", "提示", {
          confirmButtonText: "去升级",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$router.push("/personal/financial");
            console.log("去升级");
          })
          .catch(() => {
            this.$router.push({
              path: "/wk/libraryDetails",
              query: { id: item.id },
            });
            location.reload();
          });
        return;
      }
      this.$router.push({ path: "/wk/libraryDetails", query: { id: item.id } });
      location.reload();

      // this.id = item.id;
      // this.$router.go(0);
      // this.libraryDetails();
    },
  },
};
</script>
<style lang='less' scoped>
.pc {
  .details_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // padding: 0 10.45%;
    // margin-top: 20px;
    background-color: #f5f5f5;
    //   height: auto;
    .details_left {
      width: 66.7%;
      height: auto;

      .subject {
        padding: 20px 16px 18px 16px;
        margin: 10px 0 18px 0;
        background-color: #fff;

        border-radius: 8px;
        flex-direction: column;

        p {
          padding-bottom: 10px;
          text-align: left;
          display: flex;
          // align-items: center;

          span:nth-child(1) {
            display: inline-block;
            width: 18px;
            height: 18px;
            line-height: 18px;
            font-size: 12px;
            background: #ffffff;
            font-weight: 600;
            border-radius: 3px;
            border: 1px solid #fff;
            color: #1677ff;
            text-align: center;
            margin: 10px 7px 0 0;
          }
          span:nth-child(2) {
            display: inline-block;
            width: 95%;
            font-size: 30px;
            font-weight: 600;
            color: #020202;
            line-height: 42px;
            overflow: hidden; //强制多行显示
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .funct {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .funct_left {
            ul {
              display: flex;
              justify-content: start;
              align-items: center;
              border-bottom: 1px solid #f1f1f1;
              padding-bottom: 10px;
              li {
                padding-right: 10px;
                display: flex;
                align-items: center;
                img {
                  width: 16px;
                  height: 16px;
                }
                span {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #9a9a9a;
                  line-height: 20px;
                  margin-left: 5px;
                }
              }
            }
          }
          .funct_rigth {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-top: 18px;

            div {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-right: 20px;
              cursor: pointer;

              img {
                display: inline-block;
                width: 16px;
                height: 16px;
              }
              span {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #979797;
                line-height: 20px;
              }
            }
            div:nth-child(1) {
              /*width: 128px;*/
              height: 40px;
              background: #1677ff;
              border-radius: 5px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              color: #ffffff;
              line-height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              padding: 0 10px;
              span {
              }
              img {
                width: 30px;
                height: 30px;
                padding: 0 5px;
              }
            }
          }
        }
      }
      .content {
        padding: 40px 20px 20px 20px;
        border-radius: 8px;
        background-color: #fff;

        .content_top {
          p {
            text-align: left;
            padding: 22px 0;
            font-size: 16px;
            font-weight: 400;
            color: #303030;
            line-height: 35px;
          }
        }
        .content_img {
          width: 100%;
          height: 513px;
          margin-bottom: 22px;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: pink;
          }
        }
        .content_bottom {
          text-align: left;
          p {
            padding: 22px 0;
            font-size: 16px;
            font-weight: 400;
            color: #303030;
            line-height: 35px;
          }
        }
        .thumbs {
          display: flex;
          justify-content: center;
          margin-bottom: 22px;

          div {
            width: 116px;
            height: 38px;
            line-height: 38px;
            background: #1677ff;
            border-radius: 25px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            margin: 0 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 30px;
              height: 30px;
              padding: 0 5px;
            }
          }
          div:nth-child(2) {
            border: 1px solid #1677ff;
            background-color: #fff;
            color: #1677ff;
          }
        }
      }
      .comment {
        background-color: #fff;
        padding: 20px;
        margin: 20px 0 50px;
        .text {
          p {
            text-align: left;
            font-size: 18px;
            font-weight: 600;
            color: #292931;
            line-height: 25px;
            span {
              display: block;
              width: 64px;
              height: 3px;
              background: #1677ff;
              margin-top: 5px;
            }
          }
        }
        .comment_box {
          /deep/.el-loading-spinner {
            margin-top: -7px;
          }
          margin-top: 20px;

          .el-textarea {
            margin: 15px 0;
          }
          .btn {
            text-align: right;
            button {
              width: 98px;
              height: 34px;
              background: #eeeeee;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 500;
              color: #9299a3;
              line-height: 20px;
              border: none;
              outline: none;
            }
          }
        }
        .commentlist {
          ul {
            li {
              margin-bottom: 10px;
              .commentlist_top {
                display: flex;
                justify-content: start;
                align-items: center;
                div:nth-child(1) {
                  width: 58px;
                  height: 58px;
                  border-radius: 58px;
                  overflow: hidden;
                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background-color: pink;
                  }
                }
                div:nth-child(2) {
                  padding-left: 15px;

                  p:nth-child(1) {
                    font-size: 16px;
                    font-weight: 600;
                    color: #292931;
                    line-height: 22px;
                  }
                  p:nth-child(2) {
                    text-align: left;
                    font-size: 12px;
                    font-weight: 400;
                    color: #9293a0;
                    line-height: 17px;
                  }
                }
              }
              .commentlist_bottom {
                text-align: left;
                padding: 0 30px 0 73px;
                font-size: 14px;
                font-weight: 400;
                color: #5a5b63;
                line-height: 22px;
              }
            }
          }
        }
        .more_comment {
          width: 124px;
          height: 32px;
          line-height: 32px;
          background-color: #fff;
          border-radius: 16px;
          border: 1px solid #d1d2da;
          margin: 0 auto;
          margin-top: 15px;
          font-size: 14px;
          font-weight: 400;
          color: #8f9099;
          cursor: pointer;
        }
      }
    }
    .details_rigth {
      width: 32.4%;
      height: auto;
      margin-top: 20px;
      .data {
        width: 100%;
        height: auto;
        .top {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: start;
          background-color: #fff;
          padding: 4px 20px 4px 20px;
          box-sizing: border-box;
          .portrait {
            height: 104px;
            width: 104px;
            // background-color: #999;
            box-sizing: border-box;
            overflow: hidden;
            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }

          .infor {
            width: calc(100% - 104px);
            height: 104px;
            margin-left: 14px;
            p {
              text-align: left;
            }
            p:nth-child(1) {
              font-size: 18px;
              font-weight: 600;
              color: #303030;
              line-height: 25px;
              margin-bottom: 10px;
            }
            p:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
              color: #313a4a;
              line-height: 17px;
              overflow: hidden; //强制多行显示
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
            }
          }
        }
        .bottom {
          width: 100%;
          height: auto;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          .text_bottom {
            background-color: #fff;
            padding-top: 10px;

            .text {
              margin: 0 20px;
              text-align: left;
              font-size: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #292931;
              line-height: 25px;
              border-bottom: 1px solid #f5f5f5;

              span {
                display: block;
                width: 15%;
                height: 3px;
                background-color: #1677ff;
                margin: 4px 0 14px 0;
              }
            }
          }
          .numders {
            width: 100%;
            height: auto;
            background-color: #fff;
            ul {
              padding: 14px 20px 12px;
              li {
                // width: 100%;
                text-align: left;
                margin-bottom: 15px;
                box-sizing: content-box;
                cursor: pointer;
                p:nth-child(1) {
                  font-size: 16px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #24272f;
                  line-height: 22px;
                  white-space: nowrap; //强制一行显示
                  overflow: hidden;
                  text-overflow: ellipsis;

                  span {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    background: #ffffff;
                    border-radius: 3px;
                    border: 1px solid #1677ff;
                    color: #1677ff;
                    text-align: center;
                    margin-right: 5px;
                    font-size: 12px;
                  }
                  font-weight: 600;
                  color: #24272f;
                  line-height: 22px;
                }
                p:nth-child(2) {
                  font-size: 14px;
                  font-weight: 400;
                  color: #313a4a;
                  line-height: 20px;
                  margin: 9px 0 6px 0;
                  overflow: hidden; //强制多行显示
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                }
                p:nth-child(3) {
                  ul {
                    display: flex;
                    justify-content: start;
                    padding: 0;
                    li {
                      display: flex;
                      align-items: center;
                      margin: 0;
                      font-size: 12px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #8a8b9a;
                      line-height: 17px;
                      span {
                        display: inline-block;
                        width: 1px;
                        height: 65%;
                        background-color: #8a8b9a;
                        margin: 0 10px;
                      }
                    }
                    li:last-child {
                      span {
                        width: 0;
                      }
                    }
                  }

                  font-size: 12px;
                  font-weight: 400;
                  color: #8c939f;
                  line-height: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.libraryDetails_mb {
  padding: 60px 0 37px 0;
  .details_box {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f5f5f5;
    //   height: auto;
    .details_left {
      width: 100%;
      height: auto;

      .subject {
        padding: 12px 10px;
        margin: 0;
        background-color: #fff;
        border-radius: 8px;

        p {
          padding-bottom: 10px;
          text-align: left;
          display: flex;

          span:nth-child(1) {
            display: inline-block;
            width: 18px;
            height: 18px;
            line-height: 16px;
            font-size: 12px;
            background: #ffffff;
            font-weight: 600;
            border-radius: 3px;
            border: 1px solid #fff;
            color: #1677ff;
            text-align: center;
            margin-right: 7px;
            box-sizing: border-box;
          }
          span:nth-child(2) {
            font-size: 17px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 700;
            color: #020202;
            line-height: 22px;
            width: 307px;
          }
        }
        .funct {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .funct_left {
            ul {
              display: flex;
              justify-content: start;
              align-items: center;
              border-bottom: 1px solid #f1f1f1;
              padding-bottom: 10px;
              li {
                padding-right: 10px;
                display: flex;
                align-items: center;
                img {
                  width: 16px;
                  height: 16px;
                }
                span {
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #9a9a9a;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
      .content {
        min-height: 25vh;
        padding: 20px 5px;
        margin-top: 5px;
        border-radius: 8px;
        background-color: #fff;

        /deep/.lemma-picture {
          width: 100%;
          img {
            display: inline-block;
            width: 100% !important;
          }
        }

        .content_top {
          p {
            text-align: left;
            padding: 22px 0;
            font-size: 16px;
            font-weight: 400;
            color: #4d0f0f;
            line-height: 35px;
          }
        }
        .content_img {
          width: 100%;
          height: 513px;
          margin-bottom: 22px;
          img {
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: pink;
          }
        }
        .content_bottom {
          text-align: left;
          p {
            padding: 22px 0;
            font-size: 16px;
            font-weight: 400;
            color: #303030;
            line-height: 35px;
          }
        }
        .thumbs {
          display: flex;
          justify-content: center;
          margin-bottom: 22px;

          div {
            width: 116px;
            height: 38px;
            line-height: 38px;
            background: #1677ff;
            border-radius: 25px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            margin: 0 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 30px;
              height: 30px;
              padding: 0 5px;
            }
          }
          div:nth-child(2) {
            border: 1px solid #1677ff;
            background-color: #fff;
            color: #1677ff;
          }
        }
      }
      .comment {
        background-color: #f5f5f5;
        padding: 0;
        margin-top: 0px;
        .text {
          margin-top: 15px;

          p {
            text-align: left;
            font-size: 18px;
            font-weight: 600;
            color: #292931;
            line-height: 25px;
            span {
              display: block;
              width: 64px;
              height: 3px;
              background: #1677ff;
              margin-top: 5px;
            }
          }
        }
        .comment_box {
          /deep/.el-loading-spinner {
            margin-top: -7px;
          }
          margin-top: 20px;

          .el-textarea {
            margin: 15px 0;
          }
          .btn {
            text-align: right;
            button {
              width: 98px;
              height: 34px;
              background: #eeeeee;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 500;
              color: #9299a3;
              line-height: 20px;
              border: none;
              outline: none;
            }
          }
        }
        .commentlist {
          ul {
            li {
              margin-bottom: 10px;
              .commentlist_top {
                display: flex;
                justify-content: start;
                align-items: center;
                div:nth-child(1) {
                  width: 49px;
                  height: 49px;
                  border-radius: 49px;
                  overflow: hidden;
                  img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background-color: pink;
                  }
                }
                div:nth-child(2) {
                  padding-left: 15px;

                  p:nth-child(1) {
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #292931;
                    line-height: 20px;
                  }
                  p:nth-child(2) {
                    text-align: left;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #5a5b63;
                    line-height: 17px;
                  }
                }
              }
              .commentlist_bottom {
                // width: calc(100% - 65px);
                text-align: left;
                padding: 0 10px 0 64px;
                font-size: 12px;
                font-weight: 400;
                color: #5a5b63;
                line-height: 22px;
                white-space: wrap;
              }
            }
          }
        }
        .more_comment {
          border-radius: 16px;
          // border: 1px solid #d1d2da;
          margin: 12px auto 20px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #42464c;
          letter-spacing: 2px;
          cursor: pointer;
        }
      }
    }
  }
  .footer /deep/.footer_mb {
    position: relative;
  }
  .input {
    width: 100%;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: #fff;
    .el-input {
      width: calc(90% - 68px);

      /deep/.el-input__inner {
        width: 100%;
        border: 0;
      }
    }
    span {
      display: inline-block;
      width: 64px;
      height: 28px;
      line-height: 28px;
      background: #1677ff;
      border-radius: 16px;
    }
  }
  .funct_rigth {
    height: 40px;
    position: fixed;
    bottom: -2px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 0px;
    width: 100%;
    background-color: #fff;
    padding-left: 20px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 20px;
      cursor: pointer;

      img {
        display: inline-block;
        width: 16px;
        height: 16px;
      }
      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #979797;
        line-height: 20px;
      }
    }
    div:nth-child(4) {
      // width: 110px;
      padding: 0 22px;
      box-sizing: border-box;
      // margin-left: 150px;
      position: absolute;
      right: 0;
      height: 40px;
      background: #1677ff;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
      }
      img {
        width: 30px;
        height: 30px;
        padding: 0 5px;
      }
    }
  }
}
</style>
