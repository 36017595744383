<template>
  <div>
    <Header :indexi="6"></Header>

    <!-- PC端 -->
    <div v-if="show_pc" style="background-color: #f5f5f5" class="pc">
      <div class="search">
        <div class="top">
          <input v-model="keyword" placeholder="搜索职位、公司" />
          <button @click="ClickSearch">搜索</button>
        </div>
      </div>

      <!-- 内容区域 -->
      <div class="conten_box">
        <!-- 推荐职业 -->
        <div class="recommend_post">
          <div class="text">
            <span></span>
            <p>推荐职位</p>
            <span></span>
          </div>
          <div v-if="positionList.length > 0">
            <ul>
              <li
                v-for="(item, index) in positionList"
                :key="index"
                @click="ClickPosition(item.id)"
              >
                <div class="top">
                  <div>
                    <p>{{ item.title }}</p>
                    <p>{{ item.salary }}</p>
                  </div>

                  <div v-if="item.left_box">{{ item.left_box.exp_desc }}</div>
                </div>
                <div class="bottom">
                  <div>
                    <div style="width: 30px; height: 28px">
                      <img :src="item.logo" alt="" />
                    </div>
                    <span>{{ item.business_name }}</span>
                  </div>
                  <div>{{ item.work_address }}</div>
                </div>
              </li>
            </ul>
            <div class="ckgd" @click="ClickMore">查看更多</div>
          </div>
          <div v-if="positionList.length <= 0">没有更多了...</div>
        </div>

        <!-- 热门企业 -->
        <div class="hot_enterprise">
          <div class="text">
            <span></span>
            <p>热门企业</p>
            <span></span>
          </div>
          <div v-if="hotEnterpriseList.length > 0">
            <ul>
              <li
                v-for="(item, index) in hotEnterpriseList"
                :key="index"
                @click="ClickEnterprise(item.business_id)"
              >
                <div>
                  <img :src="item.logo" alt="" />
                </div>
                <div>
                  <span>{{ item.market }}</span>
                  <span v-show="item.type_name !== ''"></span
                  ><span>{{ item.type_name }}</span>
                </div>
                <div>
                  <span>{{ item.recruit_count }}</span>
                  <span>{{ item.text }}</span>
                </div>
              </li>
            </ul>
            <div class="ckgd" @click="ClickHotMore">查看更多</div>
          </div>
          <div v-if="hotEnterpriseList.length <= 0">没有更多了...</div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-if="!show_pc">
      <div class="zp_md">
        <!-- 搜索 -->
        <div class="search">
          <div class="top">
            <input
              v-model="keyword"
              placeholder="搜索职位、公司"
              @input="ChangeInput"
            />
            <button @click="ClickSearch">搜索</button>
          </div>
        </div>

        <!-- 内容 -->
        <div class="conten_box">
          <!-- 推荐职业 -->
          <div class="recommend_post">
            <div class="text">
              <p>推荐职位</p>
            </div>
            <div v-if="positionList.length > 0">
              <ul>
                <li
                  v-for="(item, index) in positionList"
                  :key="index"
                  @click="ClickPosition(item.id)"
                >
                  <div class="top">
                    <div>
                      <p>{{ item.title }}</p>
                      <p>{{ item.salary }}</p>
                    </div>

                    <div v-if="item.left_box">{{ item.left_box.exp_desc }}</div>
                  </div>
                  <div class="bottom">
                    <div>
                      <img :src="item.logo" alt="" /><span>{{
                        item.business_name
                      }}</span>
                    </div>
                    <div>{{ item.work_address }}</div>
                  </div>
                </li>
              </ul>
              <div class="ckgd" @click="ClickMore">查看更多</div>
            </div>
            <div v-if="positionList.length <= 0">没有更多了...</div>
          </div>

          <!-- 热门企业 -->
          <div class="hot_enterprise">
            <div class="text">
              <p>热门企业</p>
            </div>
            <div v-if="hotEnterpriseList.length > 0">
              <ul>
                <li
                  v-for="(item, index) in hotEnterpriseList"
                  :key="index"
                  @click="ClickEnterprise(item.business_id)"
                >
                  <div>
                    <img :src="item.logo" alt="" />
                  </div>
                  <div>
                    <span>{{ item.market }}</span
                    ><span v-show="item.type_name !== ''"></span
                    ><span>{{ item.type_name }}</span>
                  </div>
                  <div>
                    <span>{{ item.recruit_count }}</span
                    ><span>{{ item.text }}</span>
                  </div>
                </li>
              </ul>
              <div class="ckgd" @click="ClickHotMore">查看更多</div>
            </div>

            <div v-if="hotEnterpriseList.length <= 0">没有更多了...</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";
export default {
  components: {
    Header,
    Footer,
  },
  metaInfo: {
    title: "新税网 - 招聘",
    meta: [
      // {
      //   name: "description",
      //   content: "这是招聘页面",
      // },
      {
        name: "keywords",
        content: "新税网-招聘",
      },
    ],
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
      // 职位搜索输入框
      keyword: "",

      // 推荐职位列表
      positionList: [],
      // 热门企业
      hotEnterpriseList: [],
    };
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    // 获取推荐职位列表
    this.getPositionLists();

    // 获取热门企业列表
    this.gteHotEnterpriseList();
  },
  methods: {
    // 推荐职位列表
    getPositionLists() {
      let storageKey = "/index.php/api/v1_0_0.recruit/get_recruit_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.positionList = data.list;
      }

      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_recruit_list", {
          page: 1,
          page_size: this.show_pc ? 9 : 4,
          is_recommend: 1,
          keyword: this.keyword,
        })
        .then((res) => {
          this.positionList = res.data.list;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {})
        .finally(() => {});
    },
    // 搜索
    ClickSearch() {
      // if (!this.keyword) {
      //   this.$message.error("请输搜索入内容");
      //   return false;
      // }

      this.getPositionLists();
      this.gteHotEnterpriseList();
    },

    // 点击每一项职位
    ClickPosition(id) {
      this.$router.push({ path: "/zp/recruitDetails", query: { id: id } });
    },
    // 点击职位推荐 查看更多
    ClickMore() {
      this.$router.push("/zp/recruitSearch");
    },

    // 热门企业列表
    gteHotEnterpriseList() {
      let storageKey = "/index.php/api/v1_0_0.recruit/get_list";
      let data = this.$storage.get(storageKey);
      if (data) {
        this.hotEnterpriseList = data.list;
      }
      this.$http
        .post("/index.php/api/v1_0_0.recruit/get_list", {
          page: 1,
          page_size: this.show_pc ? 8 : 3,
          hot: 1,
          keyword: this.keyword,
        })
        .then((res) => {
          this.hotEnterpriseList = res.data.list;
          this.$storage.set(storageKey, res.data);
        })
        .catch((error) => {});
    },

    // 点击 每项企业
    ClickEnterprise(id) {
      this.$router.push({ path: "/zp/recruit", query: { id: id } });
      console.log(id);
    },
    // 点击热门企业更多
    ClickHotMore() {
      this.$router.push("/jxqy");
    },

    // 移动端
    ChangeInput() {
      console.log(this.keyword);
    },
  },
};
</script>
<style lang='less' scoped>
.pc {
  .search {
    height: 190px;
    background: linear-gradient(270deg, #414a5d 0%, #424b61 100%);
    padding: 0 19%;
    display: flex;

    .top {
      width: 930px;
      margin: 0 auto;
      display: flex;
      justify-content: start;
      align-items: center;
      // width: 100%;
      input {
        width: 100%;
        height: 52px;
        border: 0;
        outline: none;
        background: #ffffff;
        border-radius: 8px 0px 0px 8px;
        border: 1px solid #f0f0f0;
        font-size: 14px;
        padding-left: 30px;
      }
      button {
        width: 140px;
        height: 54px;
        background: #1677ff;
        border-radius: 0px 8px 8px 0px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        border: 0;
        outline: none;
        cursor: pointer;
      }
    }
  }
  .conten_box {
    background: #f5f5f5;
    padding: 0 11%;
    width: 1200px;
    margin: 0 auto;
    .recommend_post {
      .text {
        padding: 30px 0;
        p {
          margin: 0 10px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 400;
        color: #424b60;
        line-height: 33px;
        span {
          display: inline-block;
          width: 52px;
          height: 1px;
          background: #d1d4db;
          border-radius: 4px;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        li {
          width: 32.22%;
          height: 130px;
          background-color: #fff;
          margin-right: 1.11%;
          box-sizing: border-box;
          margin-bottom: 16px;
          border-radius: 1px;
          border: 1px solid #f0f0f0;
          padding: 15px 20px;
          cursor: pointer;
          .top {
            border-bottom: 1px solid #eef0f5;
            div:nth-child(1) {
              display: flex;
              justify-content: space-between;
              p:nth-child(1) {
                font-size: 16px;
                font-weight: 400;
                color: #606779;
                line-height: 22px;
              }
              p:nth-child(2) {
                font-size: 16px;
                font-weight: 400;
                color: #fd7340;
                line-height: 22px;
              }
            }
            div:nth-child(2) {
              text-align: left;
              font-size: 13px;
              font-weight: 400;
              color: #9398a7;
              line-height: 18px;
              margin: 5px 0 10px 0;
            }
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            div:nth-child(1) {
              display: flex;
              align-items: center;
              img {
                display: block;
                /*width: 30px;*/
                height: 28px;
                background-color: #fff;
              }
              span {
                font-size: 16px;
                font-weight: 400;
                color: #787e8f;
                margin-left: 10px;
              }
            }
            div:nth-child(2) {
              font-size: 14px;
              font-weight: 400;
              color: #9398a7;
            }
          }
        }
      }
      .ckgd {
        width: 218px;
        height: 38px;
        line-height: 38px;
        background: #1677ff;
        border-radius: 5px;
        border: 1px solid #979797;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        margin: auto;
        margin-top: 18px;
        cursor: pointer;
      }
    }
    .hot_enterprise {
      padding: 10px 0;
      .text {
        padding: 30px 0;
        p {
          margin: 0 10px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 400;
        color: #424b60;
        line-height: 33px;
        span {
          display: inline-block;
          width: 52px;
          height: 1px;
          background: #d1d4db;
          border-radius: 4px;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 23.86%;
          background-color: #fff;
          margin: 0 1.1% 1.1% 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          div:nth-child(1) {
            width: 62px;
            /*height: 62px;*/
            margin: 36px 0;
            display: flex;
            align-items: center;
            overflow: hidden;
            border-radius: 5px;

            img {
              display: block;
              /*width: 100%;*/
              height: 62px;
              // background: pink;
              border: 1px solid #f3f5fb;
            }
          }
          div:nth-child(2) {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #969aa9;
              line-height: 17px;
            }
            span:nth-child(2) {
              display: inline-block;
              width: 1px;
              height: 65%;
              margin: 0 10px;
              background: #969aa9;
            }
          }

          div:nth-child(3) {
            width: 182px;
            height: 36px;
            line-height: 36px;
            background: #ffffff;
            border: 1px solid #e3e7ed;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 26px;
            span:nth-child(1) {
              color: #1677ff;
            }
            span:nth-child(2) {
              color: #969aa9;
            }
          }
        }
      }
      .ckgd {
        width: 218px;
        height: 38px;
        line-height: 38px;
        background: #1677ff;
        border-radius: 5px;
        border: 1px solid #979797;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        margin: auto;
        margin-top: 15px;
        margin-bottom: 40px;
        cursor: pointer;
      }
    }
  }
}
.zp_md {
  background-color: #f5f5f5;
  // padding: 90px 0 150px 0;
  padding-top: 60px;
  // height: 100vh;
  box-sizing: border-box;
  .search {
    height: 110px;
    background: linear-gradient(270deg, #414a5d 0%, #424b61 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    .top {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
      height: 40px;
      margin: auto;

      input {
        padding: 10px;
        width: 255px;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(42, 44, 47, 0.1);
        border-radius: 4px 0px 0px 4px;
        box-sizing: border-box;
        border: 0;
        outline: none;
      }
      button {
        width: 80px;
        height: 40px;
        background: #1677ff;
        border-radius: 0px 4px 4px 0px;
        color: #ffffff;
        border: 0;
        outline: none;
        cursor: pointer;
      }
    }
  }
  .conten_box {
    background: #f5f5f5;
    padding: 0 0 10px 0;
    .recommend_post {
      .text {
        padding: 20px 0 12px 0;
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #424b60;
          line-height: 22px;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        margin-bottom: 20px;
        li {
          width: 100%;
          height: 115px;
          background-color: #fff;
          box-sizing: border-box;
          border-radius: 1px;
          border: 1px solid #f0f0f0;
          padding: 16px 10px 11px 10px;
          margin: 5px 10px 0;
          cursor: pointer;
          .top {
            border-bottom: 1px solid #eef0f5;
            div:nth-child(1) {
              display: flex;
              justify-content: space-between;
              p:nth-child(1) {
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #606779;
                line-height: 21px;
              }
              p:nth-child(2) {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #fd7340;
                line-height: 20px;
              }
            }
            div:nth-child(2) {
              text-align: left;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9398a7;
              line-height: 18px;
              margin: 4px 0 10px 0;
            }
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            div:nth-child(1) {
              display: flex;
              align-items: center;
              img {
                display: block;
                width: 24px;
                height: 24px;
                background-color: #fff;
              }
              span {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #787e8f;
                line-height: 17px;
                margin-left: 10px;
              }
            }
            div:nth-child(2) {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9398a7;
              line-height: 17px;
            }
          }
        }
      }
      .ckgd {
        width: 140px;
        height: 38px;
        background: #1677ff;
        border-radius: 5px;

        margin: 0 auto;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 38px;
        cursor: pointer;
      }
    }
    .hot_enterprise {
      .text {
        padding: 25px 0 15px 0;
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #424b60;
          line-height: 22px;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 100%;
          background-color: #fff;
          margin: 5px 10px;
          display: flex;
          border-radius: 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          div:nth-child(1) {
            height: 134px;
            display: flex;
            align-items: center;
            img {
              display: block;
              /*width: 60px;*/
              height: 60px;
              // background: pink;
              border-radius: 5px;
              border: 1px solid #f3f5fb;
            }
          }
          div:nth-child(2) {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #969aa9;
              line-height: 17px;
            }
            span:nth-child(2) {
              display: inline-block;
              width: 1px;
              height: 80%;
              margin: 0 10px;
              background: #969aa9;
            }
          }

          div:nth-child(3) {
            width: 182px;
            height: 36px;
            line-height: 36px;
            background: #ffffff;
            border: 1px solid #e3e7ed;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 26px;
            span:nth-child(1) {
              color: #1677ff;
            }
            span:nth-child(2) {
              color: #969aa9;
            }
          }
        }
      }
      .ckgd {
        width: 140px;
        height: 38px;
        background: #1677ff;
        border-radius: 5px;
        margin: 20px auto 10px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 38px;
        cursor: pointer;
      }
    }
  }
}
</style>
