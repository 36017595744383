<template>
  <!-- 发布文库 -->
  <div>
    <div v-if="show_pc" v-loading.fullscreen.lock="loading" class="pc">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <!-- 文件名称 -->
        <el-form-item label="文件名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入文件名称"
            :disabled="editDisabled"
          ></el-input>
        </el-form-item>

        <!-- 文档分类 -->

        <el-form-item label="文档分类" prop="region">
          <el-select
            v-model="ruleForm.region"
            placeholder="请选择文档分类"
            style="width: 100%"
            :disabled="editDisabled"
          >
            <el-option
              v-for="item in classifyList"
              :key="item.value"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="文档类型" prop="type">
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择文档类型"
            style="width: 100%"
            :disabled="editDisabled"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 是否收费 -->
        <el-form-item
          label="是否收费"
          prop="resource"
          style="position: relative"
        >
          <el-radio-group v-model="ruleForm.resource" :disabled="editDisabled">
            <el-radio label="否"></el-radio>
            <el-radio label="是"></el-radio>
            <span></span>
            <div
              style="
                display: flex;
                position: absolute;
                top: 0px;
                right: 0;
                z-index: 9;
                align-items: center;
              "
              v-if="ruleForm.resource == '是'"
            >
              <span
                style="
                  border: 0;
                  margin-right: 15px;
                  line-height: 28px;
                  font-size: 14px;
                "
                >请选择支付方式</span
              >
              <el-select
                v-model="ruleForm.charge"
                placeholder="请选择"
                style="width: 100px; margin-right: 15px"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="item in chargeList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <span class="money" style="font-size: 14px"
                ><input
                  type="numder"
                  v-model="ruleForm.money"
                  :disabled="editDisabled"
                /><span style="border: 0">{{
                  ruleForm.charge == "积分" ? "分" : "元"
                }}</span></span
              >
            </div>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="文档详情" prop="description">
          <!-- <el-input
            type="textarea"
            v-model="ruleForm.description"
            placeholder="请输入文档详情"
            :disabled="editDisabled"
          ></el-input> -->
          <div class="text" style="height: 314px; width: 100%">
            <quill-editor
              class="quill"
              v-model="ruleForm.description"
              ref="free_myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              :disabled="editDisabled"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
              style="height: 80%"
            >
            </quill-editor>
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :file-list="richImg"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
            >
            </el-upload>
            <!-- 视频上传事件，实现视频上传 -->
            <!-- <el-upload
              id="uploadFileVideo"
              :action="uploadUrl"
              :file-list="richImg"
              :show-file-list="false"
              :on-success="videoUploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
            >
            </el-upload> -->
          </div>
        </el-form-item>

        <!-- 上传文档 -->

        <el-form-item label="上传文档" prop="upload">
          <div style="width: 100%" class="uploadFile">
            <el-upload
              action="https://www.xinshui.com/index.php/api/common.upload/do_upload_file"
              :on-preview="handlePictureCardPreview"
              :on-success="onSuccess"
              class="upload-demo"
              :disabled="editDisabled"
              :before-upload="beforeAvatarUpload"
              :limit="1"
              drag
              :file-list="fileList"
              :on-remove="handleRemove"
            >
              <div style="line-height: 30px" class="uploadText">
                <i class="el-icon-plus"></i>
                <p>上传文件</p>
                <p style="margin-bottom: 35px">
                  支持 PDF，TEX，PPT(ppt，pptx，pps)，WORD(doc，docx)，
                  EXCEL(xlsx，xls，csv) 格式
                </p>
              </div>
            </el-upload>
          </div>
        </el-form-item>

        <!-- 提交 -->
        <el-form-item>
          <el-button
            type="primary"
            style="
              margin: 20px 0 0 0px;
              display: inline-block;
              background-color: #1677ff;
              width: 104px;
              border-radius: 0;
              height: 34px;
              line-height: 4px;
            "
            :disabled="editDisabled"
            @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div v-if="!show_pc" id="mobile">
      <Header></Header>
      <div style="width: 100%; height: 90px"></div>
      <div
        class="release_bg"
        :style="{
          backgroundImage:
            'url(https://xinshuibucket.oss-cn-beijing.aliyuncs.com/新税/20210204/153945_458907.png)',
        }"
        style="margin-bottom: 30px"
      >
        <div style="width: 100%; height: 50px"></div>
        <div style="padding: 10px" v-loading.fullscreen.lock="loading">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="88px"
            style="background-color: #fff; padding: 20px 10px"
          >
            <!-- 文件名称 -->
            <el-form-item label="文件名称" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入文件名称"
                :disabled="editDisabled"
              ></el-input>
            </el-form-item>

            <!-- 文档分类 -->

            <el-form-item label="文档分类" prop="region">
              <el-select
                v-model="ruleForm.region"
                placeholder="请选择文档分类"
                style="width: 100%"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="item in classifyList"
                  :key="item.value"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="文档类型" prop="type">
              <el-select
                v-model="ruleForm.type"
                placeholder="请选择文档类型"
                style="width: 100%"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!-- 是否收费 -->
            <el-form-item
              label="是否收费"
              prop="resource"
              style="position: relative"
            >
              <el-radio-group
                v-model="ruleForm.resource"
                :disabled="editDisabled"
              >
                <el-radio label="否"></el-radio>
                <el-radio label="是"></el-radio>
                <span></span>
              </el-radio-group>
            </el-form-item>

            <div
              style="
                display: flex;
                align-items: center;
                padding-left: 26.7%;
                margin-top: -4px;
              "
              v-if="ruleForm.resource == '是'"
            >
              <!-- <span style="border: 0;">请选择支付方式</span> -->
              <el-select
                v-model="ruleForm.charge"
                placeholder="请选择支付方式"
                style="margin-top: -8px; width: 60%"
                :disabled="editDisabled"
              >
                <el-option
                  v-for="(item, index) in chargeList"
                  :key="index + 'charge'"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <span
                class="money"
                style="width: 30%; position: relative; margin-left: 10px"
              >
                <input
                  style="width: 70%; text-align: left"
                  type="numder"
                  v-model="ruleForm.money"
                />
                <span
                  style="
                    border: 0;
                    position: absolute;
                    top: 0px;
                    right: 8px;
                    font-size: 14px;
                  "
                  >{{ ruleForm.charge == "积分" ? "分" : "元" }}</span
                >
              </span>
            </div>

            <el-form-item label="文档详情" prop="description">
              <div class="text" style="height: 314px; width: 100%">
                <quill-editor
                  class="quill"
                  v-model="ruleForm.description"
                  ref="free_myQuillEditor"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  :disabled="editDisabled"
                  @focus="onEditorFocus($event)"
                  @change="onEditorChange($event)"
                  style="height: 80%"
                >
                </quill-editor>
                <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :file-list="richImg"
                  :show-file-list="false"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                >
                </el-upload>
                <!-- 视频上传事件，实现视频上传 -->
                <el-upload
                  id="uploadFileVideo"
                  :action="uploadUrl"
                  :file-list="richImg"
                  :show-file-list="false"
                  :on-success="videoUploadSuccess"
                  :on-error="uploadError"
                  :before-upload="beforeUpload"
                >
                </el-upload>
              </div>
            </el-form-item>

            <!-- 上传文档 -->
            <el-form-item label="上传文档" prop="upload">
              <div style="width: 100%; margin-top: 12px">
                <el-upload
                  :action="uploadUrl"
                  :on-preview="handlePictureCardPreview"
                  :on-success="onSuccess"
                  class="upload-demo"
                  :disabled="editDisabled"
                  :before-upload="beforeAvatarUpload"
                  :limit="1"
                  drag
                  :file-list="fileList"
                  :on-remove="handleRemove"
                >
                  <div style="line-height: 30px" class="uploadText">
                    <i class="el-icon-plus"></i>
                    <p>上传文件</p>
                    <p style="margin-bottom: 35px">
                      支持 ppt， pptx， pps， pdf， doc， docx， xls， xlsx，
                      csv， txt格式
                    </p>
                  </div>
                </el-upload>
              </div>
            </el-form-item>

            <!-- 提交 -->
            <el-form-item>
              <el-button
                type="primary"
                class="btn"
                :disabled="editDisabled"
                @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div id="inputVideoSize">
      <div class="videoWidth">
        <span class="videoText">视频宽度</span>
        <el-input v-model="videoWidth" placeholder="请输入视频宽度"></el-input>
      </div>
      <div class="videoHeight">
        <span class="videoText">视频高度</span>
        <el-input v-model="videoHeight" placeholder="请输入视频高度"></el-input>
      </div>
      <div class="btns">
        <el-button @click="ClickClose">关闭</el-button>
        <el-button type="primary" @click="ClickVideo">确定</el-button>
      </div>
    </div>

    <!-- 视频上传弹框 -->
    <el-dialog
      title="视频上传"
      :visible.sync="dialogVideo"
      width="30%"
      :show-close="false"
    >
      <!-- action必选参数, 上传的地址 -->
      <el-upload
        class="avatar-uploader el-upload--text"
        action
        :http-request="handleUpload"
        :show-file-list="false"
        :on-success="handleVideoSuccess"
        :before-upload="beforeUploadVideo"
        :on-progress="uploadVideoProcess"
      >
        <video
          v-if="video_url != '' && videoFlag == false"
          :src="video_url"
          style="width: 100%; height: 200px"
          controls="controls"
        >
          您的浏览器不支持视频播放
        </video>
        <i
          v-else-if="video_url == '' && videoFlag == false"
          class="el-icon-plus avatar-uploader-icon"
        ></i>
        <el-progress
          v-if="videoFlag == true"
          type="circle"
          :percentage="videoUploadPercent"
          style="margin-top: 30px"
        ></el-progress>
      </el-upload>

      <P class="text" style="margin-top: 20px"
        >请保证视频格式正确，且不超过5M</P
      >

      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px;
            border: 0;
          "
          >视频宽度</span
        ><el-input v-model="video_width" placeholder="视频宽度"></el-input>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          height: 34px;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: inline-block;
            width: 90px;
            height: 34px;
            line-height: 34px;
            border: 0;
          "
          >视频宽度</span
        >
        <el-input v-model="video_height" placeholder="视频高度"></el-input>
      </div>

      <span slot="footer" class="dialog-footer" style="border: 0">
        <el-button @click="clickCancel">取 消</el-button>
        <el-button type="primary" @click="toUploadVideo">确 定</el-button>
      </span>
    </el-dialog>
    <div id="test" @click="test"></div>
  </div>
</template>
<script>
import Header from "../header.vue";
import { mapState, mapMutations } from "vuex";
import VideoBlot from "../../utils/video";
import * as Quill from "quill";
import { client, getFileNameUUID } from "../../utils/ali-oss";
Quill.register(VideoBlot);
export default {
  components: {
    Header,
  },
  data() {
    var charge = (rule, value, callback) => {
      if (this.ruleForm.resource == "是" && this.ruleForm.charge == "") {
        callback(new Error("请选择支付方式"));
        return;
      }
      if (
        this.ruleForm.resource == "是" &&
        (this.ruleForm.money == "" || this.ruleForm.money < 0.1)
      ) {
        callback(new Error("请输入金额"));
        return;
      }
      callback();
    };
    return {
      video_width: "100%",
      video_height: "100px",
      video_url: "",
      videoFlag: false,
      videoUploadPercent: 0,
      select_index: 0,
      dialogVideo: false,

      // 视频宽
      videoWidth: "",
      // 视频高
      videoHeight: "",
      fileList: [], //编辑文档预览
      chargeList: ["积分", "余额"], // 支付方式
      classifyList: [], //文档分类
      //   上传图片路径
      dialogImageUrl: "",
      //   是否显示弹出框
      dialogVisible: false,
      typeList: [], //文档类型
      // 表单数据
      ruleForm: {
        name: "",
        region: "",
        resource: "否",
        upload: "",
        money: "",
        charge: "",
        description: "",
        type: "",
      },
      rules: {
        type: [
          {
            required: true,
            message: "请选择文档类型",
            trigger: "change",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择文档分类",
            trigger: "change",
          },
        ],
        resource: [
          {
            required: true,
            message: "请选择活动资源",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入文件名称",
            trigger: "blur",
          },
          {
            min: 1,
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请输入文档详情",
            trigger: "blur",
          },
          {
            min: 1,
            trigger: "blur",
          },
        ],
        resource: [
          {
            required: true,
            validator: charge,
            trigger: "change",
          },
        ],
        upload: [
          {
            required: true,
            message: "请上传文档",
            trigger: "change",
          },
        ],
      },
      users: "",
      tid: "",
      show_pc: true,
      loading: false,
      editDisabled: false,
      richImg: [],
      editorOption: {
        placeholder: "",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              // ['blockquote', 'code-block'],     //引用，代码块
              [
                { header: 1 },
                {
                  header: 2,
                },
              ], // 标题，键值对的形式；1、2表示字体大小
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ], //列表
              //[{ script: "sub" }, { script: "super" }], // 上下标
              [
                {
                  indent: "-1",
                },
                {
                  indent: "+1",
                },
              ], // 缩进
              [
                {
                  direction: "rtl",
                },
              ], // 文本方向
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
              // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
              // [{ 'align': [] }],    //对齐方式
              ["clean"], //清除字体样式
              ["image", "video"], //上传图片、上传视频
            ],
            handlers: {
              video: function (val) {
                if (val) {
                  document.querySelector("#test").click();

                  // 劫持原来的视频点击按钮事件
                  // document.querySelector("#inputVideoSize").style.display =
                  //   "block";
                } else {
                  this.quill.format("video", false);
                }
              },
              image: function (value) {
                if (value) {
                  // upload点击上传事件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState(["uploadUrl"]),
  },
  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
    //获取文库类型
    this.$http
      .get("/index.php/api/v1_0_0.librarycomser/get_type_list", {
        notNeed: true,
      })
      .then(({ data }) => {
        this.typeList = data.list;
        console.log(data, "文库类型");
      })
      .catch((error) => {});

    //获取文库分类
    this.$http
      .get("/index.php/api/v1_0_0.librarycomser/get_cate_list", {
        notNeed: true,
      })
      .then(({ data }) => {
        this.classifyList = data.list;

        console.log(data, "文库分类");
      })
      .catch((error) => {});

    //获取编辑内容详情
    this.tid = this.$route.query.tid;

    if (this.tid) {
      this.loading = true;
      this.editDisabled = this.$store.state.editDisabled;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/library_detail", {
          tid: this.tid,
        })
        .then((data) => {
          if (data.code == 200) {
            console.log(data);
            let ruleForm = this.ruleForm;
            ruleForm.name = data.data.title;
            ruleForm.region = data.data.cid;
            ruleForm.description = data.data.description;
            ruleForm.charge = data.data.unit == "元" ? "余额" : data.data.unit;
            ruleForm.resource = data.data.is_pay == 1 ? "否" : "是";
            ruleForm.money =
              data.data.price == "￥免费"
                ? 0
                : data.data.unit == "元"
                ? data.data.price.split("￥")[1]
                : data.data.price;
            ruleForm.type = data.data.type;
            this.editDisabled = data.data.status == 1 ? true : false;

            //添加图片预览 取消当前上传验证

            this.fileList.push({
              url: data.data.file,
              name: data.data.file.toString(),
            });
            this.rules.upload = "";
            ruleForm.upload = data.data.file;
            this.loading = false;
          }
        })
        .catch((error) => {});
      return;
    }
  },
  methods: {
    /**
     * 自定义上传方法
     */
    handleUpload(option) {
      let that = this;
      async function multipartUpload() {
        const fileName = getFileNameUUID();
        //定义唯一的文件名，打印出来的uid其实就是时间戳
        //client 是第一步中的 client
        client()
          .multipartUpload("xinshui/" + fileName, option.file, {
            progress: function (p) {
              //获取进度条的值
              // console.log(p)
              that.videoFlag = true;
              // console.log(file.percentage)
              var pro = p * 100;
              that.videoUploadPercent = pro.toFixed(0) * 1;
            },
          })
          .then((result) => {
            //下面是如果对返回结果再进行处理，根据项目需要
            // https://xinshuibucket.oss-cn-beijing.aliyuncs.com/1612356926822_9ac83937
            that.videoFlag = false;
            that.videoUploadPercent = 0;
            that.video_url =
              "https://xinshuibucket.oss-cn-beijing.aliyuncs.com/" +
              result.name;
          })
          .catch((err) => {
            console.log("err:", err);
            this.$message.error("视频上传失败，请重新上传！");
          });
      }
      multipartUpload();
    },
    // 视频上传成功
    handleVideoSuccess(res, file) {
      //获取上传图片地址
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      console.log(res);
      if (res.code == 200) {
        this.video_url = res.data.file_url;
      } else {
        this.$message.error("视频上传失败，请重新上传！");
      }
    },

    // 视频上传之前
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 5;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过5MB哦!");
        return false;
      }
    },

    // 视频上传进度
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      // console.log(file.percentage)
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },

    // 确定添加视频
    toUploadVideo() {
      var quill = this.$refs.free_myQuillEditor.quill;

      this.dialogVideo = false;

      // return false;
      console.log(this.select_index);
      quill.insertEmbed(this.select_index, "simpleVideo", {
        url: this.video_url,
        controls: "controls",
        width: this.video_width,
        height: this.video_height,
      });
    },
    // 取消
    clickCancel() {
      this.dialogVideo = false;
      this.video_url = "";
    },
    test() {
      // 记录此时的位置
      var quill = this.$refs.free_myQuillEditor.quill;
      this.select_index = quill.getSelection().index;
      this.video_url = "";

      // 展示视频弹框
      this.dialogVideo = true;
    },
    // 图片处理
    uploadSuccess(res) {
      console.log("成功");
      console.log(res.data.file_url, "res");
      var dt = {};
      dt.url = res.data.file_url;
      let quill = this.$refs.free_myQuillEditor.quill;

      console.log(quill);
      // 如果上传成功
      if (res.code === 200 && dt.url !== null) {
        console.log("插入图片");
        console.log(dt.url);

        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  dt.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", dt.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
      // loading加载隐藏
      this.quillUpdateImg = false;
    },
    // 视频上传成功函数
    videoUploadSuccess(res) {
      var dt = {};
      dt.url = res.data.file_url;

      let quill = this.$refs.free_myQuillEditor.quill;
      if (res.code === 200 && dt.url !== null) {
        let length = quill.getSelection().index;
        quill.insertEmbed(length, "simpleVideo", {
          url: dt.url,
          controls: "controls",
          width: this.videoWidth + "px",
          height: this.videoHeight + "px",
        });

        quill.setSelection(length + 1);
      } else {
        this.$message.error("视频插入失败");
      }
    },
    //视频上传事件
    ClickVideo() {
      window.document.querySelector("#uploadFileVideo input").click();
    },
    // 关闭
    ClickClose() {
      document.querySelector("#inputVideoSize").style.display = "none";
    },

    // 上传失败钩子函数
    uploadError(res) {
      console.log("失败");
      console.log(res);
    },
    // 上传前钩子函数
    beforeUpload() {},
    onEditorReady(editor) {}, // 准备编辑器
    onEditorBlur(e) {
      console.log(e, "e");
    }, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {}, // 内容改变事件
    //文件类型限制
    beforeAvatarUpload(file) {
      console.log("上传之前的文件限制");
      console.log(this.ruleForm.type);
      console.log(file["name"]);

      return new Promise((resolve, reject) => {
        // 判断上传格式***************** reject() resolve()
        const isLt2M = file.size / 1024 / 1024 < 10;

        if (!isLt2M) {
          this.$message.error("上传文件大小不能超过 10MB!");
          reject();
          return false;
        }

        if (!this.ruleForm.type) {
          this.$message({
            message: "请选择上传文件格式",
            type: "warning",
          });
          reject();
          return false;
        }
        console.log(666);
        // 去后台检查是否可以验证通过
        this.$http
          .post("/index.php/api/v1_0_0.librarycomser/check_file", {
            type_id: this.ruleForm.type,
            file_name: file["name"],
          })
          .then((data) => {
            console.log(data);
            if (data.code == 200) {
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject();
          });
      });

      // var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      // const extension = testmsg === "xls";
      // const extension2 = testmsg === "xlsx";
      // const extension3 = testmsg === "txt";
      // const extension4 = testmsg === "doc";
      // const extension5 = testmsg === "docx";
      // const extension6 = testmsg === "ppt";
      // const extension7 = testmsg === "pptx";
      // const extension8 = testmsg === "rtf";
      // const extension9 = testmsg === "pdf";
      // const isLt2M = file.size / 1024 / 1024 < 10;
      // if (
      //   !extension &&
      //   !extension2 &&
      //   !extension3 &&
      //   !extension4 &&
      //   !extension5 &&
      //   !extension6 &&
      //   !extension7 &&
      //   !extension8 &&
      //   !extension9
      // ) {
      //   this.$message({
      //     message: "不支持的文件格式!",
      //     type: "warning",
      //   });
      // }
      // if (!isLt2M) {
      //   this.$message({
      //     message: "上传文件大小不能超过 10MB!",
      //     type: "warning",
      //   });
      // }
      // return (
      //   extension ||
      //   extension2 ||
      //   extension3 ||
      //   extension4 ||
      //   extension5 ||
      //   extension6 ||
      //   extension7 ||
      //   extension8 ||
      //   (extension9 && isLt2M)
      // );
    },
    submitForm(formName) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.charge == "余额") {
            this.ruleForm.money = this.ruleForm.money * 100;
          }
          this.$http
            .post("/index.php/api/v1_0_0.librarycomser/publish_lib", {
              tid: this.tid,
              publish_data: {
                cid: this.ruleForm.region,
                type: this.ruleForm.type,
                title: this.ruleForm.name,
                description: this.ruleForm.description,
                file: this.ruleForm.upload,
                is_pay: this.ruleForm.resource == "是" ? "2" : "1", //是否收费 1免费 2付费
                pay_type: this.ruleForm.charge == "余额" ? "2" : "1", //付费方式 1积分 2余额
                price: this.ruleForm.money, //价格
              },
            })
            .then((data) => {
              console.log(data);
              this.$message.success(data.msg);
              this.$router.push({
                path: "/personal/content",
                query: {
                  tabs: "文库",
                },
              });
              return;
            })
            .catch((error) => {});
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onSuccess(response, file, fileList) {
      console.log("文件上传成功");

      console.log(this.ruleForm.type);

      console.log(response);
      if (response.code == 200) {
        this.ruleForm.upload = response.data.file_url;
        this.$refs.ruleForm.clearValidate("upload");
        this.rules.upload = "";
      } else {
        this.$message.error(response.msg);
        return false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.upload = "";
      if (fileList.length < 1) {
        this.rules.upload = [
          {
            required: true,
            message: "请上传文档",
            trigger: "change",
          },
        ];
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // clearInput(){
    //   this.ruleForm={
    //     name: "",
    //     region: "",
    //     resource: "否",
    //     upload: "",
    //     money: '',
    //     charge: '',
    //     description:''
    //   }
    // }
    openTitle() {
      this.$confirm(
        "用户会员身份不同，所付费价格不同，不同身份会员，收益价格不同",
        "提示",
        {
          confirmButtonText: "知道了",
          cancelButtonText: "取消",
          showCancelButton: false,
          type: "warning",
        }
      )
        .then(() => {})
        .catch(() => {});
    },
  },
  watch: {
    "ruleForm.resource": {
      handler(value) {
        if (value == "是" && !this.editDisabled) {
          this.openTitle();
        }
      },
    },
  },
};
</script>
<style lang='less' scoped>
body {
  background-color: #f5f5f5;
}
.text {
  /deep/.el-upload--text {
    border: 0;
  }
}
.pc {
  .uploadFile {
    /deep/ .el-upload--text {
      width: 100% !important;
      height: 100% !important;
      background-color: #fafafa;
      border: 1px solid #e5e6ea;
    }
  }
}

#mobile {
  /deep/.el-input__icon {
    line-height: 33px;
  }
  /deep/.el-form-item__error {
    padding-top: 4px;
  }

  /deep/.el-input {
    margin-top: 8px;
  }

  .release_bg {
    width: 100%;
    height: 180px;

    background: #f1f5f6;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    /* padding: 120px 0 50px; */
  }

  .btn {
    margin: 10px 0 0 0px;
    display: inline-block;
    background-color: #1677ff;
    color: #fff;
    width: 100%;
    border-radius: 0;
    height: 44px;
    /* line-height: 44px; */
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .box {
    /* display: flex;
   flex: 1;
    justify-content: space-between;
    align-items: center; */

    input {
      width: 100%;
      height: 34px;
      background: #ffffff;
      border: 1px solid #e5e6ea;
      display: block;
      outline: none;
      padding-left: 10px;
      margin-bottom: 8px;
    }
  }
}

/deep/.el-upload-dragger {
  border: 0;
  /* width: 600px; */
  width: 100%;
  height: 100%;
  background: #fafafa;
}

/deep/.el-upload--picture-card {
  /* width: 508px; */

  height: 150px;
  /* border: 1px solid #E5E6EA; */
  background: #fafafa;
}

.uploadText {
  i {
    color: #e5e6ea;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  P {
    font-size: 12px;
    color: #89909d;
    line-height: 16px;
  }
}

span {
  display: inline-block;
  height: 24px;
}

.money {
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid #e5e6ea;
  padding: 0 10px;
  line-height: 32px;
  height: 32px;

  input {
    width: 76px;
    /* height: 34px; */
    background: #ffffff;
    outline: none;
    border: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #4f5764;
  }
}
#inputVideoSize {
  width: 500px;
  height: 200px;
  background-color: #ccc;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display: none;

  .videoWidth,
  .videoHeight {
    display: flex;
    align-items: center;
    height: 34px;
    .videoText {
      display: inline-block;
      width: 100px;
    }
  }
  .btns {
    width: 100%;
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
