<template>
  <!-- 首页 -->
  <div>
    <!-- 头部导航栏 -->
    <Header :indexi="0"></Header>

    <!-- 广告组件 -->
    <Banner></Banner>

    <!-- 文章列表   文章推荐以下 底部页面以上-->
    <News></News>

    <!-- 底部内容 -->
    <Footer></Footer>
  </div>
</template>



<script>
import { log } from "util";
// 头部组件
import Header from "../components/header";
// 轮播广告组件
import Banner from "../components/banner";
// 资讯组件
import Footer from "../components/footer";
// 底部组件
import News from "../components/news";
export default {
  components: {
    Header,
    Banner,
    News,
    Footer,
  },
  metaInfo: {
    title: "新税网 - 首页",
    meta: [
      // {
      //   name: "description",
      //   content: "这是首页页面",
      // },
      {
        name: "keywords",
        content: "新税网-首页",
      },
    ],
  },
  data() {
    return {
      // 默认显示 PC端的css
      show_pc: true,
    };
  },

  created() {
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;
  },
  methods: {},
};
</script>



<style lang='less' scoped>
</style>
