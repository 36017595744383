<template>
  <div>
    <div
      v-loading.fullscreen.lock="fullscreenLoading"
      v-if="show_pc"
      class="pc"
    >
      <!-- 我的参与列表 -->
      <div class="top">
        <ul>
          <li
            v-for="(item, index) in joinList"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickMeJoinList(index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <!-- 内容区域 -->
      <div class="bottom">
        <!-- 我的参与 -->
        <ul v-if="i == 0">
          <li
            v-for="(item, index) in joinActivityList"
            :key="index"
            @click="ClickJoinList(item, index)"
          >
            <div class="img"><img :src="item.cover" alt="" /></div>
            <div class="content">
              <p>{{ item.title }}</p>
              <p>
                {{ item.description }}
              </p>
              <p>
                <span> 活动时间: {{ item.activity_time }}</span>
                <span> 活动地点: {{ item.address }} </span>
              </p>
            </div>
            <div class="state">
              <span v-if="item.status_name == '未开始'" style="color: #1677ff"
                >未开始</span
              >
              <span v-if="item.status_name == '进行中'" style="color: #1bc72f"
                >进行中</span
              >
              <span v-if="item.status_name == '已结束'" style="color: #9ca2ab"
                >已结束</span
              >
            </div>
          </li>
        </ul>
        <!-- 我的课程 -->
        <ul v-if="i == 1">
          <li
            v-for="(item, index) in joinClassList"
            :key="index"
            @click="ClickClassList(item, index)"
          >
            <div class="img"><img :src="item.cover" alt="" /></div>
            <div class="content">
              <p>{{ item.title }}</p>
              <p style="" class="userContentText">
                {{ item.description }}
              </p>
              <p>
                <span @click.stop="ClickClassTime(index)"
                  >课程时间: {{ item.online_time }} ~ {{ item.end_time }}</span
                >
              </p>
            </div>
            <div class="state">
              <span v-if="item.status_name == '未开始'" style="color: #1677ff"
                >未开始</span
              >
              <span v-if="item.status_name == '进行中'" style="color: #1bc72f"
                >进行中</span
              >
              <span v-if="item.status_name == '已结束'" style="color: #9ca2ab"
                >已结束</span
              >
            </div>
          </li>
        </ul>
      </div>

      <div
        style="font-size: 14px; color: #9c9cab; padding: 20px"
        v-if="total < 1"
      >
        <img src="../../../assets/image/缺省图.png" style="width: 13%" />
        <p style="margin-top: 15px">暂无列表</p>
      </div>

      <!-- 底部分页 -->
      <div class="page" style="padding: 25px 0 50px 0">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.pagenum"
          :page-size="10"
          layout="prev, pager, next"
          :total="total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 移动端 -->
    <div
      v-if="!show_pc"
      v-loading.fullscreen.lock="fullscreenLoading"
      id="mobile"
    >
      <Header></Header>
      <div style="width: 100%; height: 60px"></div>
      <div class="top">
        <div
          style="
            text-align: left;
            display: flex;
            align-items: center;
            line-height: 26px;
            padding-left: 15px;
            background-color: #fff;
            font-weight: 700;
          "
        >
          <p
            style="
              width: 3px;
              height: 18px;
              background-color: #1677ff;
              margin-right: 10px;
            "
          ></p>
          <p
            style="font-family: PingFangSC-Regular, PingFang SC; color: #292931"
          >
            我的参与
          </p>
        </div>
        <ul>
          <li
            v-for="(item, index) in joinList"
            :key="index"
            :class="{ color: i == index }"
            @click="ClickMeJoinList(index)"
            class=""
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <!-- 内容区域 -->
      <div class="bottom">
        <LoadMore
          :onLoadMore="onLoadMore"
          :enableLoadMore="enableLoadMore"
          ref="LoadMore"
        >
          <!-- 我的参与 -->
          <ul v-if="i == 0">
            <li
              v-for="(item, index) in joinActivityList"
              :key="index"
              @click="ClickJoinList(item, index)"
            >
              <div class="kcli">
                <div class="img"><img :src="item.cover" alt="" /></div>
                <div class="content">
                  <p
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    {{ item.title }}
                    <span
                      v-if="item.status_name == '未开始'"
                      style="color: #1677ff"
                      class="state"
                      >未开始</span
                    >
                    <span
                      v-if="item.status_name == '进行中'"
                      style="color: #1bc72f"
                      class="state"
                      >进行中</span
                    >
                    <span
                      v-if="item.status_name == '已结束'"
                      style="color: #9ca2ab"
                      class="state"
                      >已结束</span
                    >
                  </p>
                  <p
                    style="height: 40px; -webkit-line-clamp: 2"
                    class="userContentText"
                  >
                    {{ item.description }}
                  </p>
                </div>
              </div>
              <p
                style="
                  color: #1677ff;
                  font-size: 12px;
                  text-align: left;
                  margin-top: 12px;
                "
              >
                <span style="margin-right: 5px">
                  活动时间: {{ item.activity_time }}</span
                >
                <span> 活动地点: {{ item.address }} </span>
              </p>
            </li>
          </ul>
          <!-- 我的课程 -->
          <ul v-if="i == 1">
            <li
              v-for="(item, index) in joinClassList"
              :key="index"
              @click="ClickClassList(item, index)"
            >
              <div class="kcli">
                <div class="img"><img :src="item.cover" alt="" /></div>
                <div class="content">
                  <p
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    {{ item.title }}
                    <span
                      v-if="item.status_name == '未开始'"
                      style="color: #1677ff"
                      class="state"
                      >未开始</span
                    >
                    <span
                      v-if="item.status_name == '进行中'"
                      style="color: #1bc72f"
                      class="state"
                      >进行中</span
                    >
                    <span
                      v-if="item.status_name == '已结束'"
                      style="color: #9ca2ab"
                      class="state"
                      >已结束</span
                    >
                  </p>
                  <p
                    style="height: 40px; -webkit-line-clamp: 2"
                    class="userContentText"
                  >
                    {{ item.description }}
                  </p>
                </div>
              </div>
              <p
                style="
                  color: #1677ff;
                  font-size: 12px;
                  text-align: left;
                  margin-top: 12px;
                "
              >
                <span @click.stop="ClickClassTime(index)"
                  >课程时间: {{ item.online_time }} ~ {{ item.end_time }}</span
                >
              </p>
            </li>
          </ul>
          <div style="width: 100%; height: 40px"></div>
        </LoadMore>
      </div>

      <div
        style="font-size: 14px; color: #9c9cab; padding: 20px"
        v-if="total < 1"
      >
        <img src="../../../assets/image/缺省图.png" style="width: 25%" />
        <p style="margin-top: 15px">暂无列表</p>
      </div>
    </div>
  </div>
</template>
<script>
import LoadMore from "../../loadMore/loadMore.vue";
import Header from "../../header.vue";
export default {
  components: {
    Header,
    LoadMore,
  },
  data() {
    return {
      // 总条数
      total: 0,
      queryInfo: {
        // 查询相关参数文章
        query: "",
        // 默认显示第一页
        pagenum: 1,
        // 默认每一页显示10条
        pagesize: 10,
      },
      user: "",
      // 我的参与列表
      joinList: ["参与活动", "参与课程"],
      //   默认第一个选中
      i: 0,
      //   参与活动列表
      joinActivityList: [],

      //   参与课程列表
      joinClassList: [],
      fullscreenLoading: false,
      show_pc: true,
      enableLoadMore: true,
    };
  },
  created() {
    // document.querySelector('body').style.backgroundColor='#f5f5f5';
    // 判断是PC端还是移动端
    this.show_pc = this.$store.state.show_pc;

    this.show_pc
      ? (this.queryInfo.pagesize = 10)
      : (this.queryInfo.pagesize = 6);
    this.$parent.setIcon = "我的参与";
    if (this.$route.query.tabs) {
      this.i = this.$route.query.tabs;
    } else {
      this.getHDlist();
    }
  },
  methods: {
    //移动端上拉刷新
    onLoadMore(done, total = "") {
      if (!this.enableLoadMore) {
        return;
      }
      if (total == "") {
        this.queryInfo.pagenum++;
      }
      if (this.i == 0) {
        this.getHDlist();
      }
      if (this.i == 1) {
        this.getKClist();
      }
    },

    getHDlist() {
      this.fullscreenLoading = true;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/my_activity", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then((data) => {
          console.log(data);
          this.fullscreenLoading = false;
          this.total = data.data.total_count;
          if (!this.show_pc) {
            data.total_count == this.joinActivityList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.joinActivityList.push(...data.data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }

          this.joinActivityList = data.data.list;
        })
        .catch((error) => {});
    },
    getKClist() {
      this.fullscreenLoading = true;
      let that = this;
      this.$http
        .post("/index.php/api/v1_0_0.consumer/my_course", {
          page: this.queryInfo.pagenum,
          page_size: this.queryInfo.pagesize,
        })
        .then((data) => {
          console.log(data);
          this.fullscreenLoading = false;
          this.total = data.data.total_count;
          if (!this.show_pc) {
            data.total_count == this.joinClassList.length
              ? (this.enableLoadMore = false)
              : (this.enableLoadMore = true);
            this.joinClassList.push(...data.data.list);
            this.$refs.LoadMore.closeloading();
            return;
          }

          this.joinClassList = data.data.list;
        })
        .catch((error) => {});
    },
    // 每页显示的条数发生的变化会触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      console.log(newSize);
    },
    // 当前页发生变化 触发的函数
    handleCurrentChange(newNum) {
      this.queryInfo.pagenum = newNum;
      console.log(newNum);
      if (this.i == 0) {
        this.getHDlist();
      }
      if (this.i == 1) {
        this.getKClist();
      }
    },

    // 点击我的参与列表的函数
    ClickMeJoinList(i) {
      this.i = i;
      this.queryInfo.pagenum = 1;
      this.joinActivityList = [];
      this.joinClassList = [];
      if (this.i == 0) {
        this.getHDlist();
      }
      if (this.i == 1) {
        this.getKClist();
      }
    },
    // 点击参与列表
    ClickJoinList(item, index) {
      // const need_guide = this.$store.state.get_userinfo.need_guide;
      // if (item.is_pay == 2 && need_guide) {
      //   this.$confirm("升级会员可享受全站折扣优惠!?", "提示", {
      //     confirmButtonText: "去升级",
      //     cancelButtonText: "取消",
      //   })
      //     .then(() => {
      //       this.$router.push("/personal/financial");
      //       console.log("去升级");
      //     })
      //     .catch(() => {});
      // }
      console.log("参与活动详情", item);
      this.$router.push({
        path: "/hd/activityDetails",
        query: {
          id: item.id,
        },
      });
    },
    // 点击活动时间
    ClickJoinTime(i) {
      console.log("活动时间", i);
    },
    // 点击活动地址
    ClickJoinAddr(i) {
      console.log("活动地址", i);
    },

    // 点击参与课程列表
    ClickClassList(item, index) {
      console.log(item, "参与课程列表");
      // const need_guide = this.$store.state.get_userinfo.need_guide;
      // if (item.is_pay == 2 && need_guide) {
      //   this.$confirm("升级会员可享受全站折扣优惠!?", "提示", {
      //     confirmButtonText: "去升级",
      //     cancelButtonText: "取消",
      //   })
      //     .then(() => {
      //       this.$router.push("/personal/financial");
      //       console.log("去升级");
      //     })
      //     .catch(() => {});
      // }

      this.$router.push({
        path: "/xskt/salaryClassroom",
        query: {
          id: item.id,
        },
      });
    },
    // 点击课程时间
    ClickClassTime(i) {
      console.log("课程时间", i);
    },
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f5f5f5");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>
<style lang='less' scoped>
#mobile {
  .top {
    background-color: #fff;
    margin-bottom: 5px;
    ul {
      display: flex;
      justify-content: start;
      padding: 14px 0 14px 15px;
      /* padding: 10px; */

      .color {
        color: #1677ff;
      }

      li {
        margin-right: 30px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        // line-height: 22px;
        cursor: pointer;
      }
    }
  }

  .bottom {
    /* margin-bottom: 25px; */
    li {
      padding: 10px;
      background-color: #fff;
      margin-bottom: 5px;
    }
    .kcli {
      display: flex;
      justify-content: start;
      cursor: pointer;
      // height: 75px;
      .img {
        /* width: 17.8%; */
        width: 108px;
        min-width: 108px;
        height: 68px;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 108px;
          min-width: 108px;
          height: 100%;
          background-color: pink;
        }
      }

      .content {
        width: 75%;
        height: 100%;
        text-align: left;
        margin-left: 10px;
        box-sizing: border-box;

        p:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #292931;
          line-height: 20px;
          span {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1bc72f;
          }
        }

        p:nth-child(2) {
          // width: 225px;
          height: 33px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #808080;
          line-height: 17px;
          padding-top: 10px;
          margin-right: 15px;
        }

        p:nth-child(3) {
          margin-top: 12px;
          span {
            display: inline-block;
            font-size: 12px;
            height: 12px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1677ff;
            line-height: 16px;
            border-right: 1px solid #1677ff;
            padding: 0 15px;
            cursor: pointer;
          }

          span:nth-child(1) {
            padding-left: 0;
          }

          span:last-child {
            border: 0;
          }
        }
      }

      .state {
        width: 42px;
        height: 20px;

        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1677ff;
        line-height: 20px;
      }
    }
  }
}

.pc {
  .top {
    ul {
      display: flex;
      justify-content: start;
      padding: 20px 0 29px 5px;

      .color {
        color: #1677ff;
      }

      li {
        margin: 0 25px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292931;
        line-height: 22px;
        cursor: pointer;
      }
    }
  }

  .bottom {
    ul {
      margin-bottom: 25px;

      li {
        display: flex;
        justify-content: start;
        margin: 20px 20px 40px 27px;

        cursor: pointer;

        .img {
          width: 17.8%;
          height: 98px;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            background-color: pink;
          }
        }

        .content {
          width: 75%;
          height: 100%;
          text-align: left;
          margin-left: 19px;
          box-sizing: border-box;

          p:nth-child(1) {
            width: 95%;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #292931;
            line-height: 22px;
            white-space: nowrap; //强制一行显示
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p:nth-child(2) {
            width: 95%;

            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #808080;
            line-height: 20px;
            margin: 8px 0;
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          p:nth-child(3) {
            span {
              display: inline-block;
              font-size: 12px;
              height: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #1677ff;
              line-height: 12px;
              border-right: 1px solid #1677ff;
              padding: 0 15px;
              cursor: pointer;
            }

            span:nth-child(1) {
              padding-left: 0;
            }

            span:last-child {
              border: 0;
            }
          }
        }

        .state {
          width: 42px;
          height: 20px;

          span {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1677ff;
            line-height: 20px;
          }
        }
      }
    }

    .page {
      text-align: center;
      padding: 25px 0 50px 0;
    }
  }
}
</style>
